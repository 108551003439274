.dialogRoot {
  outline: none;
  position: absolute;
}
.data {
  flex-grow: 1;
  border-top: 1px solid rgb(217, 217, 217);
  overflow: auto;
  position: relative;
  & > .dataWrapper {
    display: inline-block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 9px;
    bottom: 30px;
  }
}
.bottomButton {
  margin: 0px -31px 0px;
  padding: 15px 0 15px 0;
  background-color: #f7f7f7;
  text-align: center;

  &.noneButton {
    padding: 0 0 30px 0;
  }
  & > div:first-child {
    margin: 0;
  }

  & > div {
    margin-left: 4px;
  }
}
.useImageUrl {
  max-width: 18px;
  max-height: 18px;
  margin-top: 2px !important;
}
.buttonWrapper {
  & > div {
    display: inline-block !important;
  }
}
