.root {
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;

    .wrapper {
        height: 18px;
        display: flex;
        align-items: center;

        &.disabled {
            cursor: not-allowed;
        }

        .checkIcon {
            position: relative;
            display: inline-block;
            margin-right: 5px;
            width: 14px;
            height: 14px;
            background: url("../Images/checkbox2x.png") no-repeat center / 100%;

            &.defaultChecked {
                background: url("../Images/checkbox_cehcked2x.png") no-repeat center / 100%;
            }

            &.defaultRequired {
                background: url("../Images/checkbox_r_over2x.png") no-repeat center / 100%;
            }

            &.requiredChecked {
                background: url("../Images/checkbox_r_cehcked2x.png") no-repeat center / 100%;
            }

            &.defaultDisabled {
                background: url("../Images/checkbox_disable2x.png") no-repeat center / 100%;
            }

            &.disableChecked {
                background: url("../Images/checkbox_checked_disable2x.png") no-repeat center / 100%;
            }

            &.radioDefaultUnchecked {
                background: url("../Images/radio2x.png") no-repeat center / 100%;
            }

            &.radioDefaultChecked {
                background: url("../Images/radio_checked2x.png") no-repeat center / 100%;
            }

            &.radioDefaultRequired {
                background: url("../Images/radio_r2x.png") no-repeat center / 100%;
            }

            &.radioRequiredChecked {
                background: url("../Images/radio_r_checked2x.png") no-repeat center / 100%;
            }

            &.radioDisabled {
                background: url("../Images/radio_disable2x.png") no-repeat center / 100%;
            }

            input[type="checkbox"] {
                position: absolute;
                left: 0;
                opacity: 0;
            }

            .focused {
                box-sizing: border-box;
                position: absolute;
                left: 0;
                width: 14px;
                height: 14px;
                background: rgba(28, 144, 251, 0.08);
                border: 1px solid #1c90fb;

                &.radioFocused {
                    border-radius: 100%;
                }
            }
        }

        &:focus {
            outline: none;
        }

        .labelText {
            user-select: none;
        }
    }
}

.root+.root {
    margin-left: 20px;
}