.root{
    box-sizing: border-box;
    .container{
        display: flex;
        height: 33px;
        margin-bottom:4px;
        align-items: center;

        .labelTextField {        
            .labelText{
                line-height:27px;
                font-weight: 700;
                font-size: 14px;
            }

            .bullet {
                display: inline-block;
                width:3px;
                height:3px;
                margin:4px 5px 4px 0;
                background: #333;
            }
        }

        .subLabelTextField {
            flex: 1 1 auto;
            margin:2px 5px 0 0;
            line-height:27px;
        }

        .elementsField {
            display: flex;
            align-items: center;

            .element{
                margin-right:5px;
            }
            .lastChild{
                margin-right:0px;
            }
        }
    }
}
