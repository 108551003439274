.root {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    // overflow: scroll;

    & > .dimmed {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }

    & > .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        & > .dialogWrapper {
            display: flex;
            // overflow: scroll;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 1100;
            width: 270px;
            min-height: 100px;
            border: solid 1px #a6a6a6;
            background: #ffffff;
            padding: 30px 30px 30px 30px;

            & > .buttons {
                margin-top: 16px;

                & > div:first-child {
                    margin: 0;
                }

                & > div {
                    margin-left: 3px;
                }
            }

            & > .title {
                margin: 0;
                font-size: 16px;
                line-height: 1.28;
                height: 27px;
                letter-spacing: -.9px;
                font-weight: bolder;
            }

            & > .subTitle {
                margin-top: 3px;
                margin-bottom: 20px;
            }

            & > .percentageText {
                color: rgb(0,135,244);
                line-height: 1.28;
                font-weight: bold;
                margin-bottom: 7px;

                &.disabled {
                    color: rgb(198,198,198) !important; 
                }
            }

            & > .itemText {
                color: rgb(178,178,178);
                font-size: 10px;
                margin-bottom: 12px;
                // text-align: left !important;
                width: 100% !important;
                display: flex !important;

                &.singleLoading {
                    text-align: left !important;
                }

                & > .itemLabelText {
                    text-align: left !important;
                    width: 70%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;

                    &.singleLoading {
                        width: 100% !important;
                    }
                }

                & > .itemStatus {
                    text-align: right !important;
                    width: 30%;
                }
            }
        }
    }

    &.closed {
        display: none;
    }
}

.linearProgress {
    position: relative;
    height: 12px;
    width: 100%;

    & > .linearWrapper {
        position: relative;
        height: 10px;
        display: block;
        width: 100%;
        background-color: rgb(189, 189, 189);
        border-radius: 5px;
        margin: 0px;
        overflow: hidden;
        background-color: #eaeaea !important;
        float: left !important;


        & > .linearLoading {
            height: 100%;
            background-color: rgb(0, 135, 244);
            // transition: width 0.1s linear 0ms;
            width: 0%;

            &.disabled {
                background-color: rgb(198, 198, 198);
            }

            &.isEntireLoading {
                transition: width 0.5s linear 0ms;
            }

            &.error {
                background-color: #fc5356; 
            }

            &.warning {
                background-color: #febc2c; 
            }

            &.success {
                background-color: #3fa684;
            }
        }
    }
}

.tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.12s ease-out;
    transform: scale(1);
    transition: all 5s;
    flex-direction: column;
    position: absolute;
    z-index: 1200;
    left: 28px;
    top: 85px;
    //   flex-direction: column-reverse;

    &.hide {
        display: none;
        transition: all 5s;
    }

    & > .contents {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.12) 0px 1px 2px;
        font-size: 10px;
        line-height: 12px;
        padding: 5px 10px;
        border-radius: 3px;
        background-color: #ffffff;
        border: 1px solid #a6a6a6;
        color: #000000;
        max-width: 250px;
    }

    & > .arrowWrapper {
        position: relative;
        box-sizing: border-box;
        width: 10px;
        height: 10px;

        & > .arrow {
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            border-top-color: #a6a6a6;
            border-top:5px solid #a6a6a6;
            border-bottom:5px solid none;
            border-right: 5px solid transparent;
            border-left: 5px solid  transparent;
        }

        & > .arrowBackground {
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            border: 5px solid transparent;
            margin-top: -1px;
            border-top-color: #fff;
        }
    }
}