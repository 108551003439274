.obtTextField,
.obtTextField * {
    box-sizing: content-box;
    line-height: normal;
}

.obtTextField {
    display: inline-block;

    &.useCustomCodePickerIcon {
        position: relative;
        & > div:first-child > div > button > * {
            display: none !important;
        }
    }

    & > .customCodePickerIcon {
        pointer-events: none;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 16px;

        & > * {
            width: 100% !important;
        }
    }

    &.hideInformationText > div:first-child > p {
        display: none;
    }

    & input[type="password"] {
        &::-ms-clear,
        &::-ms-reveal {
            display: none;
        }
        &::-webkit-contacts-auto-fill-button,
        &::-webkit-credentials-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            height: 0;
            width: 0;
            margin: 0;
        }
    }
}

.multiLangTextField {
    position: relative;
}

.multiLangButtonWrap {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 27px;
    height: 27px;
    .multiLangButton {
        box-sizing: border-box;
    }
}

.multiLangPanelRoot {
    margin: 5px 0px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 10px;
    .multiLangPanelTable {
        tr {
            td:first-child {
                width: 1%;
                white-space: nowrap;
                font-weight: bold;
                text-align: right;
                padding-right: 5px;
            }
            &:not(:last-child) {
                td {
                    padding-bottom: 5px;
                }
            }
        }
    }
}
