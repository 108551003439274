.previousNextSelector {

  & > div > div > div > div > span {
    background: transparent !important;
  }

  & > div > div > select {
    appearance:none;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    &::-ms-expand {
      display: none;
    }
  }

  & > div > div > div:nth-child(1) > div > select {
    appearance:none;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    &::-ms-expand {
      display: none;
    }

    &>option {
      font-family: inherit !important;
      line-height: 13px;
    }

  }
}
