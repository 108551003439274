.calendar {
    position: relative;
    transition: opacity 0.3s ease-out;
    opacity: 1;

    &:focus {
        outline: none;
    }

    &:not(.visible) {
        opacity: 0;
    }
}

.actionBar {
    clear: both;
    padding: 10px;
    border: 1px solid rgb(163, 163, 163);
    border-top-color: rgb(230, 230, 230);
    white-space: nowrap;
    outline: none;
    background-color: #ffffff;
}

.selectedMonthDisplayBar {
    z-index: 2;
    box-sizing: border-box;
    background: rgb(250, 250, 250);
    width: 80px;
    height: 39px;
    position: absolute;
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
    font-family: tahoma, sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    &>strong {
        margin: 0 3px;
    }

    .dateDropDownIcon {
        transition: transform 0.2s ease;
        transform-origin: center;
        border-radius: 30px;
        width: 16px;
        height: 16px;

        &:hover {
            background: #e9f5ff;
        }

        &>img {
            width: 100%;
            transition: transform 0.2s ease;
            transform-origin: center;
            -webkit-user-drag: none;
        }

        .arrowDown {
            transform: rotate(0.5turn);
        }
    }
}

.dropDownWrapper {
    z-index: 1;
    box-sizing: border-box;
    width: 216px;
    height: 248px;
    position: absolute;
    top: 1px;
    left: 1px;

    .titleBar {
        width: 100%;
        height: 39px;
        background: rgb(250, 250, 250);
    }

    .monthListWrapper {
        box-sizing: border-box;
        height: 209px;
        background: #fff;
        overflow: hidden;

        .monthListScrollWrapper {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding: 0 10px;

            &>ul {
                box-sizing: border-box;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                &>li {
                    box-sizing: border-box;
                    height: 42px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: calc(100% / 3);
                    cursor: pointer;
                    border: 1px solid #fff;

                    &:hover {
                        border: 1px solid #c9c7c7;
                    }
                }

                .selected {
                    border: 1px solid #1c90fb;
                    background: #1c90fb;
                    color: #fff;

                    &:hover {
                        border: 1px solid #1c90fb;
                        background: #1c90fb;
                    }
                }

                .disabled {
                    color: #a6a6a6;
                    cursor: not-allowed;

                    &:hover {
                        border: 1px solid #fff;
                    }
                }
            }
        }
    }
}