.fullFixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height:48px;
  // overflow: hidden;
  z-index: 3000;

  & > .relative {
    position: relative;
    width: 100%;
    height: 100%;

    & > .absolute {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      height: auto;
    }
    & > .closed {
      bottom: -48px;
      transition: bottom 0.5s ease-out;
    }

    & > .open {
      bottom: 0px;
      transition: bottom 0.25s ease-out;
    }
  }
}

.sectionRoot {
  // position: relative;
  // display: inherit;
  // height: 100%;
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;

  & > .sectionWrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;

    & > .sectionContents {
      position: relative;
      width: 100%;
      height: 100%;

      & > .absolute {
        width:fit-content;
        max-width:100%;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
      }

      & > .closed {
        bottom: -48px;
        transition: bottom 0.5s ease-out;
      }

      & > .open {
        bottom: 0px;
        transition: bottom 0.25s ease-out;
      }
    }
  }
}

.snackRoot {
  line-height:21px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 15px 12px 15px;
  display: flex;

  & > span {
    display: flex;
    align-items: center;
    vertical-align: top;

    & > img, & > div:first-child {
      display: inline-block;
      user-select: none;
      width: 21px;
      height: 21px;
      margin-right: 10px;
    }

    & > span {
      font-size: 16px;
      line-height: 23px;
      margin-right: 5px;
      // font-family: NotoSansCJKkr;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
}

.success {
  background-color: #3fa684;
}

.warning {
  background-color: #faa733;
}

.error {
  background-color: #e85e30;
}

.info {
  background-color: #3e82ef;
}

.iconStyle {
  display: inline-block;
  user-select: none;
  vertical-align: top;
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.closed {
  bottom: -48px;
  transition: bottom 0.5s ease-out;
}

.open {
  bottom: 0px;
  transition: bottom 0.25s ease-out;
}

.invisible {
  display: none;
}
