$background: #fafafa;
$topBorderColor: #666666;
$borderColor: #e6e6e6;

.table {
  border: none;
  border-spacing: 0px;
  border-collapse: separate;
  table-layout: fixed;
  border-top: 2px solid $topBorderColor;
  border-bottom: 1px solid $borderColor;
  padding: 0px 0px;
  box-sizing: content-box;
  position: relative;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: -2px;
  //   left: 50%;
  //   right: 0px;
  //   height: 2px;
  //   width: 0%;
  //   background-color: #1C90FB;
  //   pointer-events: none;
  //   transition: left, width;
  //   transition-duration: 200ms;
  //   transition-timing-function: ease-in;
  // } 

  // &:focus-within {
  //     &::after {
  //         left: 0%;
  //         width: 100%;
  //     }
  // }

  &>tbody {
    box-sizing: content-box;

    &>tr {
      padding: 0px;
      margin: 0px;
      box-sizing: content-box;
      height: 40px;

      &>th,
      &>td {
        padding: 6px 0px;
        margin: 0px;
        box-sizing: content-box;

        &>.contentsWrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }

      &>th {
        background-color: $background;
        // padding-left: 6px;
        // padding-right: 6px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 7px;
        color: #000000;
        font-size: 12px;

        &>.contentsWrapper {
          justify-content: flex-end;
        }
      }

      &>td {
        // padding-left: 6px;
        // padding-right: 6px;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 6px;
        padding-bottom: 7px;
        font-size: 12px;
      }

      &.breakLine {
        height: 2px !important;

        &>td {
          padding: 0px !important;
          background-color: $topBorderColor;
        }
      }

      & .categories {
        position: absolute;
        inset: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 3px;
        user-select: none;
        pointer-events: none;
        font-size: 10px;
        font-weight: normal;
      }

      & .referenceWrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        &>.contentsWrapper {
          height: auto;
          flex: 0 0 auto;
        }
        & .referenceContent {
          display: flex;
          flex-direction: row;
          gap: 5px;
        }
      }
    }

    &>tr:not(:last-child):not(.breakLine):not(.breakLineAfter) {

      &>th,
      &>td {
        border-bottom: 1px solid $borderColor;
      }
    }
  }
}

.label {
  width: 174px;
}

.disabled {
  cursor: not-allowed;
}

.tooltip {
  margin-right: 4px;
  text-align: left;
}