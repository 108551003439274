@charset "utf-8";
/***************************** 
    기본 레이아웃
******************************/
/* 포탈 컨테이너  */
.newPortalContainer{position: relative;width: 100%;margin: 50px 0 0 0;overflow:hidden;}
.newPortalContainer > div:first-child{scroll-behavior:smooth;}
.newPortalContainer > div:last-child{width: 15px !important;z-index:2 !important;}
.newPortalContainer > div:last-child div{width: 10px !important;border-radius: 50px !important;background-color: rgba(0,0,0, 0.3) !important;}
.newPortalContainer.scrollHide > div:last-child{display:none;}
.newPortalContainer.scrollHide {overflow:hidden}
.newPortalHeaderWrap{position: fixed;top: 0;height: 50px;box-sizing: border-box;z-index:1001;}
.newPortalSearchWrap{position: relative;width:100%;height:100%;}
/* 포털 세팅 버튼 */ 
.newPortalSettingBtn{display:block;position: fixed;right:20px;bottom:84px;width: 54px;height: 54px;border-radius:54px;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);background: #ffffff url('../imgs/main/ic_quick_setting_normal@2x.png') no-repeat center;background-size:54px;z-index:10;cursor: pointer; 
    &:hover{background: #ffffff url('../imgs/main/ic_quick_setting_over@2x.png') no-repeat center;background-size:54px;} 
    &.disabled{background: #ffffff url('../imgs/main/ic_quick_setting_normal@2x.png') no-repeat center;background-size:54px;} 
} 
/* 포털 퀵 탑이동 버튼 */ 
.newPortalQuickTopBtn{display:block;position: fixed;right:20px;bottom:20px;width: 54px;height: 54px;border-radius:54px;box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);background: rgba(48,60,82,0.8) url('../imgs/main/ic_quick_top_normal@2x.png') no-repeat center;background-size:54px;z-index:10;cursor: pointer;box-sizing: border-box;font-size:0px; 
    &:hover{background: rgba(48,60,82,0.95) url('../imgs/main/ic_quick_top_normal@2x.png') no-repeat center;background-size:54px;} 
}
/* 포털 알림뷰 */
@media screen and (max-width:1279px) {
    .newPortalAlertView{display: none !important;}
}
@media screen and (max-width:1530px) {
    .newPortalAlertView{height: calc(100% - 200px) !important;}
}
@media screen and (-webkit-min-device-pixel-ratio:0) { 
    .newPortalAlertView{position: sticky !important;}
}
.newPortalAlertView{position: relative;top: 50px;left: calc(100% - 310px);margin: 0;width: 280px;height: calc(100% - 100px);box-sizing: border-box;overflow: hidden;  
    & > div:first-child{height:100%;}  
    .line{width: 280px;height:1px;margin:0 0 10px 0;background-color: rgba(255, 255, 255, 0.3);}  
} 
.dDayList{  
    &::before{content: 'D-day';display: inline-block;width: 67px;height: 24px;line-height:1.5;text-align: center;color: #ffffff;margin: 0 0 10px 0;padding: 2px 0;opacity: 0.9;border-radius: 15px;box-sizing: border-box;background-image: linear-gradient(290deg, #2e2e2e, #505050);}  
    li{width: 100%;min-height: 60px;margin: 0 0 10px 0;border-radius: 5px;box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);background-color: rgba(255, 255, 255, 0.9);overflow: hidden;  
        // 이전소스(고도화 개발 후 제거)  
        .notiBox{  
            .noti-unit{padding:15px 24px;  
                dt{font-size:12px;font-weight:normal;line-height:18px;margin:0 0 2px 0;  
                    .dday{display: inline-block;width: 28px;height: 16px;opacity: 0.5;font-size:10px;font-weight:normal;line-height:16px;color: #ffffff;text-align: center;border-radius: 2px;background-image: linear-gradient(300deg, #2e2e2e 100%, #505050);}  
                    .date{font-size:11px;font-weight:normal;color:#8e8e8e;margin:0 0 0 12px;}  
                }  
                dd{font-size:11px;font-weight:normal;line-height:15px;opacity: 0.5;letter-spacing: -0.5px;  
                    &.twinText{margin:5px 0 0 0;  
                        .line{width: 1px;height: 12px;background-color: rgba(0, 0, 0, 0.3);margin: 6px 10px;}  
                        dt{font-size:10px;font-weight:normal;line-height:24px;opacity: 0.5;}  
                        dd{font-size:12px;font-weight:normal;line-height:20px;opacity: 1;margin:0;  
                            b{font-size:20px;font-weight:normal;line-height:20px;}  
                        }  
                    }  
                }  
                &.myWork{padding:15px 10px 15px 72px;background: rgba(241, 241, 241, 0.3) url('../imgs/main/alertView/ico_noti_mywork.png') no-repeat left 24px center; background-size: 36px 36px;}                  
                &.workEnd{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_workend.png') no-repeat left 24px center; background-size: 36px 36px;}  
                &.plan{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_plan.png') no-repeat left 24px center; background-size: 36px 36px;}  
                &.teamWork{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_teamwork.png') no-repeat left 24px center; background-size: 36px 36px;}  
                &.acc{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_accounting@2x.png') no-repeat left 24px center; background-size: 36px 36px;} 
                &.dday{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_dday@2x.png') no-repeat left 24px center; background-size: 36px 36px;} 
                &.gsm{padding:15px 24px 15px 72px;background: url('../imgs/main/ecoPlace/ic_buy_write@2x.png') no-repeat left 24px center; background-size: 36px 36px;}                      
            }  
            .noti-details{padding:15px 24px;  
                .myWork-date{width:36px;  
                    .badge{display: block;width: 34px;height: 16px;font-size:10px;font-weight:normal;line-height:16px;text-align: center;color: #666666;border-radius: 12px;-webkit-backdrop-filter: blur(30px);backdrop-filter: blur(30px);border: solid 1px rgba(64, 64, 64, 0.5);background-color: rgba(255, 255, 255, 0.5);}  
                    .date{display: block;font-size:12px;font-weight:normal;line-height:20px;text-align: center;opacity: 0.5;}  
                }  
                .myWork-time{margin:0 0 0 10px;  
                    &:after{content:"";display:block;clear:both;height:0;visibility:hidden;}  
                    dl{display:block;height:18px;  
                        dt{float:left;font-size:12px;font-weight:normal;line-height:18px;margin:0 5px 0 0;}  
                        dd{float:left;font-size:12px;font-weight:normal;line-height:18px;opacity: 0.8;}  
                    }  
                }  
                .myWork-etc{text-align: right;  
                    .state-done{font-size:11px;font-weight:normal;line-height:30px;padding:0 0 0 15px;color:#009d79;background: url('../imgs/main/alertView/ico_confirm_03.png') no-repeat left;}  
                    .btn{display: inline-block;width: 72px;height: 26px;margin:5px 0;padding: 0 10px 0 0;font-size:11px;font-weight:normal;line-height:24px;text-align: center;box-sizing: border-box;border-radius: 100px;background: #ffffff url('../imgs/main/alertView/ic_arrow_right_s.png') no-repeat right 8px center;background-size: 6px;cursor: pointer;}  
                    .btn-done{display: inline-block;width: 72px;height: 26px;margin:5px 0;font-size:11px;font-weight:normal;line-height:24px;text-align: center;box-sizing: border-box;border-radius: 100px;background: #20c997;color:#ffffff;}  
                }  
                &.eng{  
                    .myWork-date{width:50px;  
                        .badge{display: block;width: 50px;height: 16px;font-size:10px;font-weight:normal;line-height:16px;text-align: center;color: #666666;border-radius: 12px;-webkit-backdrop-filter: blur(30px);backdrop-filter: blur(30px);border: solid 1px rgba(64, 64, 64, 0.5);background-color: rgba(255, 255, 255, 0.5);}   
                        .date{display: block;font-size:10px;font-weight:normal;line-height:20px;text-align: center;opacity: 0.5;}  
                    }   
                }   
            }  
            .progressBox{padding:15px 24px;  
                .title{font-size:12px;font-weight:normal;line-height:18px;  
                    .dday{display: inline-block;width: 28px;height: 16px;opacity: 0.5;font-size:10px;font-weight:normal;line-height:16px;color: #ffffff;text-align: center;border-radius: 2px;background-image: linear-gradient(300deg, #2e2e2e 100%, #505050);}  
                }  
                .progress{width:230px;height:10px;border-radius: 10px;background-color: #ebebeb;margin:5px 0;  
                    .bar{height: 10px;border-radius: 8px;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);background-image: linear-gradient(to left, #4dabf7, #228be6);}  
                }  
            }  
        }  
        // 신규소스 (고도화) 
        .ddayBox{ 
            img.dDayIcon{width:36px; height:36px;}
            // type : 기본 
            &.type-default{ 
                .noti-unit{padding:15px 24px;  
                    dt{font-size:12px;font-weight:bold;line-height:18px;margin:0 0 2px 0;  
                        .dday{display: inline-block;margin: 0 4px 0 0;padding: 0 5px;min-width: 28px;height: 16px;opacity: 0.5;font-size:10px;font-weight:bold;line-height:16px;color: #ffffff;text-align: center;border-radius: 2px;background-image: linear-gradient(300deg, #2e2e2e 100%, #505050);box-sizing:border-box;}  
                        .date{font-size:11px;font-weight:normal;color:#8e8e8e;margin:0 0 0 12px;}  
                    }  
                    dd{font-size:11px;font-weight:normal;line-height:15px;opacity: 0.5; 
                        &.twinText{margin:5px 0 0 0;  
                            .line{width: 1px;height: 12px;background-color: rgba(0, 0, 0, 0.3);margin: 6px 10px;}  
                            dt{font-size:10px;font-weight:normal;line-height:24px;opacity: 0.5;}  
                            dd{font-size:12px;font-weight:normal;line-height:20px;opacity: 1;margin:0;  
                                b{font-size:20px;font-weight:normal;line-height:20px;}  
                            }  
                        } 
                        .boldText{font-weight:bold;} 
                    }  
                    .ddayBtn{display: inline-block;min-width: 72px;height: 26px;margin: 8px 0 0 0;padding: 0 20px 0 10px;font-size:11px;font-weight:bold;line-height:24px;text-align: center;box-sizing: border-box;border-radius: 100px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background: #ffffff url('../imgs/main/alertView/ic_arrow_right_s.png') no-repeat right 8px center;background-size: 6px;cursor: pointer;}  
                    .gsmText{font-size:11px;font-weight:bold;color:#000;line-height:16px;margin:6px 0 0 0; 
                        .cnt{color:#3692de;font-weight:normal; 
                            span{font-size:16px;font-weight:bold;margin:0 0 0 6px;} 
                        } 
                    }  
                    &.workEnd{padding:15px 24px 15px 24px;}  
                    &.plan{padding:15px 24px 15px 24px;}  
                    &.hr{padding:15px 24px 15px 24px;}  
                    &.crm{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_crm@2x.png') no-repeat top 15px left 24px; background-size: 36px;}  
                    &.gsm{padding:15px 24px 15px 24px;}                      
                    &.teamWork{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_teamwork.png') no-repeat top 15px left 24px; background-size:36px;}  
                    &.acc{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_accounting@2x.png') no-repeat top 15px left 24px; background-size:36px;} 
                    &.dday{padding:15px 24px 15px 72px;background: url('../imgs/main/alertView/ico_noti_dday@2x.png') no-repeat top 15px left 24px; background-size:36px;} 
                } 
            } 
            // type : kiss 
            &.type-kiss{ 
                .progressBox{padding:15px 24px 15px 24px;  
                    dt{font-size:12px;font-weight:bold;line-height:18px;margin:0 0 2px 0; 
                        .dday{display: inline-block;margin: 0 4px 0 0;padding: 0 5px;min-width: 28px;height: 16px;opacity: 0.5;font-size:10px;font-weight:bold;line-height:16px;color: #ffffff;text-align: center;border-radius: 2px;background-image: linear-gradient(300deg, #2e2e2e 100%, #505050);box-sizing:border-box;}  
                    }  
                    dd{font-size:11px;font-weight:normal;line-height:15px;opacity: 0.5;}  
                    .progress{color: #9893a5;
                        .barBg{position: relative; top: 10px; background:rgba(0, 0, 0, 0.2);height: 6px;border-radius: 12px; 
                            .bar{transition: all 1s ease-in-out;width:0%;height: 6px;border-radius:12px;} 
                            .dragBar{position: absolute;top: -7px;width: 8px;height: 20px;margin: 0 0 0 -4px;background: rgba(0, 0, 0, 0.2);border-radius: 8px;cursor: pointer;
                                &:hover{background: rgba(0, 0, 0, 0.5);} 
                            } 
                        }  
                        .per {min-width:35px;font-size: 16px;font-weight:bold;line-height: 24px;margin: 0 0 0 10px;text-align:right; 
                            .dan {font-size: 12px;font-weight:normal;}  
                        }  
                        .state {width: 34px;height: 18px;line-height: 17px;font-size: 11px;font-weight: normal;text-align: center;color: #fff;border-radius: 20px;box-sizing: border-box;margin: 3px 0 0 6px;}  
                        &.color1{/*예정*/ 
                            .barBg{ 
                                .bar{background:#f0c325;} 
                            }  
                            .per{color:#f0c325; 
                                .dan {color:#f0c325;} 
                            }  
                            .state {background:#f0c325;} 
                        } 
                        &.color2{/*진행*/ 
                            .barBg{ 
                                .bar{background:#46a3f0;} 
                            }  
                            .per {color:#46a3f0; 
                                .dan {color:#46a3f0;} 
                            }  
                            .state {background:#46a3f0;} 
                        } 
                        &.color3{/*지연*/ 
                            .barBg{ 
                                .bar{background:#ff8787;} 
                            }  
                            .per {color:#ff8787; 
                                .dan {color:#ff8787;} 
                            }  
                            .state {background:#ff8787;} 
                        } 
                        &.color4{/*보류*/ 
                            .barBg{ 
                                .bar{background:#f8a457;} 
                            }  
                            .per {color:#f8a457; 
                                .dan {color:#f8a457;} 
                            }  
                            .state {background:#f8a457;} 
                        } 
                        &.color5{/*완료*/ 
                            .barBg{ 
                                .bar{background:#20c997;} 
                            }  
                            .per {color:#20c997; 
                                .dan {color:#20c997;} 
                            }  
                            .state {background:#20c997;} 
                        } 
                        &.color6{/*취소*/ 
                            .barBg{ 
                                .bar{background:#9da3aa;} 
                            }  
                            .per {color:#9da3aa; 
                                .dan {color:#9da3aa;} 
                            }  
                            .state {background:#9da3aa;} 
                        } 
                        .checkbox{width: 18px;line-height: 18px;margin: 0 0 0 6px;background: url('../imgs/uc/check_off.png') no-repeat top 3px center;  
                            &.checked{width: 18px;line-height: 18px;margin: 0 0 0 6px;background: url('../imgs/uc/check_on.png') no-repeat top 3px center;}  
                            svg{background:transparent !important;}  
                            svg path{visibility: hidden;}  
                            svg polygon{visibility: hidden;}  
                         }  
                    }  
                } 
            } 
            // type : custom 
            &.type-custom{ 
                // 나의 근무시간 관리 
                .myWorkTime{ 
                    .noti-unit{ 
                        dt{font-size:12px;font-weight:bold;line-height:18px;margin:0 0 2px 0; 
                            .dday{display: inline-block;width: 28px;height: 16px;opacity: 0.5;font-size:10px;font-weight:normal;line-height:16px;color: #ffffff;text-align: center;border-radius: 2px;background-image: linear-gradient(300deg, #2e2e2e 100%, #505050);}  
                            .date{font-size:11px;font-weight:normal;color:#8e8e8e;margin:0 0 0 12px;}  
                        }  
                        dd{font-size:11px;font-weight:normal;line-height:15px;opacity: 0.5; 
                            &.twinText{margin:5px 0 0 0;  
                                .line{width: 1px;height: 12px;background-color: rgba(0, 0, 0, 0.3);margin: 6px 10px;}  
                                dt{font-size:10px;font-weight:normal;line-height:24px;opacity: 0.5;}  
                                dd{font-size:12px;font-weight:normal;line-height:20px;opacity: 1;margin:0;  
                                    b{font-size:20px;font-weight:normal;line-height:20px;}  
                                }  
                            }  
                        }  
                        &.myWork{padding:15px 10px 14px 24px; background: rgba(241, 241, 241, 0.3);} 
                    }  
                    .noti-details{padding:15px 24px;  
                        .myWork-date{width:36px;  
                            .badge{display: block;width: 34px;height: 16px;font-size:10px;font-weight:normal;line-height:16px;text-align: center;color: #666666;border-radius: 12px;-webkit-backdrop-filter: blur(30px);backdrop-filter: blur(30px);border: solid 1px rgba(64, 64, 64, 0.5);background-color: rgba(255, 255, 255, 0.5);}  
                            .date{display: block;font-size:12px;font-weight:normal;line-height:20px;text-align: center;opacity: 0.5;}  
                        }  
                        .myWork-time{margin:0 0 0 10px;  
                            &:after{content:"";display:block;clear:both;height:0;visibility:hidden;}  
                            dl{display:block;height:18px;  
                                dt{float:left;font-size:12px;font-weight:normal;line-height:18px;margin:0 5px 0 0;}  
                                dd{float:left;font-size:12px;font-weight:normal;line-height:18px;opacity: 0.8;}  
                            }  
                        }  
                        .myWork-etc{text-align: right;  
                            .state-done{font-size:11px;font-weight:normal;line-height:30px;padding:0 0 0 15px;color:#009d79;background: url('../imgs/main/alertView/ico_confirm_03.png') no-repeat left;}  
                            .ddayBtn{display: inline-block;width: 72px;height: 26px;margin:5px 0;padding: 0 10px 0 0;font-size:11px;font-weight:bold;line-height:24px;text-align: center;box-sizing: border-box;border-radius: 100px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background: #ffffff url('../imgs/main/alertView/ic_arrow_right_s.png') no-repeat right 8px center;background-size: 6px;cursor: pointer;}  
                            .ddayBtn-done{display: inline-block;width: 72px;height: 26px;margin:5px 0;font-size:11px;font-weight:normal;line-height:24px;text-align: center;box-sizing: border-box;border-radius: 100px;background: #20c997;color:#ffffff;}  
                        }  
                        &.eng{  
                            .myWork-date{width:50px;  
                                .badge{display: block;width: 50px;height: 16px;font-size:10px;font-weight:normal;line-height:16px;text-align: center;color: #666666;border-radius: 12px;-webkit-backdrop-filter: blur(30px);backdrop-filter: blur(30px);border: solid 1px rgba(64, 64, 64, 0.5);background-color: rgba(255, 255, 255, 0.5);}   
                                .date{display: block;font-size:10px;font-weight:normal;line-height:20px;text-align: center;opacity: 0.5;}  
                            }   
                        }   
                    } 
                } 
            } 
        }  
    }  
}

.loginConfirmAlert{position:fixed; left:80px; bottom:30px; width:280px; min-height:105px; padding: 20px 24px; border-radius:5px; background-color:#303c52; box-shadow: 0px 1px 4px 0 rgba(36, 36, 36, 0.2); transition:all 0.5s; z-index: 1111; box-sizing: border-box; cursor: move;
    .ico{width:36px; height:36px; margin: 0 12px 0 0; background:url('../imgs/main/alertView/ico_remote_login@2x.png') no-repeat center center; background-size: 36px;}
    .topUnitBox{
        .txt{font-size: 11px; letter-spacing: -0.28px; color: #ffffff;
            p{margin: 4px 0 0 0; font-size: 10px; letter-spacing: -0.25px; color: rgba(255, 255, 255, 0.5);}
        }
    }
    .btnUnitBox{margin: 8px 0 0 0;
        .btn{min-width: 91px; height: 26px; line-height: 24px; border-radius: 13px; border: 1px solid rgba(255, 255, 255, 0.5); text-align: center; font-size: 11px; letter-spacing: -0.55px; color: #ffffff; box-sizing: border-box; cursor: pointer;}
    }
}

/***************************** 
    모드에 따른 스타일
******************************/
// mode-SD
.newPortalWrap.mode-SD .newPortalHeaderWrap{top: 0;left: 48px;right: 0px;}
.newPortalWrap.mode-SD .newPortalSearchResultWrap{height:0;visibility: hidden;-webkit-transition: all .3s;transition: all .3s;}

// mode-ST
.newPortalWrap.mode-ST .newPortalHeaderWrap{top: 0;left: 48px;right: 0px;}
.newPortalWrap.mode-ST .newPortalSearchWrap .searchBar{visibility: hidden;}
.newPortalWrap.mode-ST .newPortalSearchWrap .newPortalLatelyKeyword{visibility: hidden;}
.newPortalWrap.mode-ST .newPortalSearchResultWrap{height:0;visibility: hidden;-webkit-transition: all .3s;transition: all .3s;}
.newPortalWrap.mode-ST .newPortalAlertView{display: none;}

// mode-SR
.newPortalWrap.mode-SR .newPortalSideGnb{display:none;}
.newPortalWrap.mode-SR .newPortalHeaderWrap{top: 0;left: 48px;right: 0px;}
.newPortalWrap.mode-SR .newPortalSearchWrap{height:154px;transition: 0.5s;}
.newPortalWrap.mode-SR .newPortalSearchWrap .newPortalSearchBox{position: absolute;bottom:0 !important;}
.newPortalWrap.mode-SR .newPortalSearchWrap .sloganBox{display:none;}
.newPortalWrap.mode-SR .newPortalSearchWrap .tangoMenu {display:none;}
.newPortalWrap.mode-SR .newPortalSearchWrap .newPortalScrollSignBtn {display:none;}
.newPortalWrap.mode-SR .newPortalSettingBtn {display:none;}
.newPortalWrap.mode-SR .newPortalPortletWrap {display:none;}
.newPortalWrap.mode-SR .newPortalSearchResultWrap{visibility:visible;}
.newPortalWrap.mode-SR .newPortalAlertView{display: none;}

// mode-SRT
.newPortalWrap.mode-SRT .newPortalSearchWrap .searchBar{visibility: hidden;}
.newPortalWrap.mode-SRT .newPortalSearchWrap .newPortalLatelyKeyword{visibility: hidden;}
.newPortalWrap.mode-SRT .newPortalAlertView{display: none;}
.newPortalWrap.mode-SRT .newPortalSearchResultWrap .searchCategory{position:fixed;top:50px;z-index:3;background:#1c90fb;}
.newPortalWrap.mode-SRT .newPortalSearchResultWrap .searchCategory .searchCategory-in dl dd{color:#ffffff;opacity: 0.5;}
.newPortalWrap.mode-SRT .newPortalSearchResultWrap .searchCategory .searchCategory-in dl dd.on{color:#ffffff;opacity:1;}
.newPortalWrap.mode-SRT .newPortalSearchResultWrap .searchCategory .searchCategory-in dl dd.on:after{background-color: #ffffff;}
.newPortalWrap.mode-SRT .newPortalSearchResultWrap .searchCategory .searchCategory-in .more{color:#ffffff;background: url('../imgs/main/ico_searchCategory_more_arrow_down_w.png') no-repeat right;}
.newPortalWrap.mode-SRT .newPortalSearchResultWrap .relationKeyword{position:fixed;top:102px;z-index:2;}
.newPortalWrap.mode-SRT .newPortalSearchResultWrap .searchContents{margin:95px auto 0 auto;}

// mode-SE
.newPortalWrap.mode-SE .newPortalContainer{margin: 72px 0 120px 0;transition: 0.3s;}
.newPortalWrap.mode-SE .newPortalContainer.scrollHide {overflow:hidden;overflow-y: auto;}
.newPortalWrap.mode-SE .newPortalHeaderWrap{display:none;}
.newPortalWrap.mode-SE .newPortalSearchWrap{height:152px;}
.newPortalWrap.mode-SE .newPortalSearchWrap .newPortalSearchBox{display:none;}
// .newPortalWrap.mode-SE .newPortalPortletWrap {background-color: transparent;}
.newPortalWrap.mode-SE .newPortalSettingBtn {display:none;}
.newPortalWrap.mode-SE .newPortalQuickTopBtn {display:none;}
.newPortalWrap.mode-SE .newPortalAlertView{display: none;}
.newPortalWrap.mode-SE .newPortalSearchResultWrap{height:0;visibility: hidden;-webkit-transition: all .3s;transition: all .3s;}
.newPortalWrap.mode-SE .newPortalSearchWrap .tangoMenu{position: absolute;left: 50%;bottom: 0;width: 896px;height: 102px;border-radius: 10px 10px 0 0;-webkit-backdrop-filter:saturate(180%) blur(30px);backdrop-filter:saturate(180%) blur(30px);background-color: rgba(0, 0, 0, 0.26);z-index:2;transform: translateX(-50%);
    &.floatMode{position: absolute;left: 50%;bottom: 0;width: 1060px;height: 132px;border-radius: 10px;background-color: rgba(0, 0, 0, 0.3);overflow: hidden;transform: translateX(-50%);}
}

/***************************** 
    포탈 & SIDE
******************************/
/* 포탈 */
.newPortalWrap{position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;min-width: 1024px;min-height: 768px;background-color: #333948;background-repeat: no-repeat;background-position: top;background-size: 1920px 1080px;z-index: 1000;}
@media screen and (min-width:1921px), screen and (min-height:1081px) {
    .newPortalWrap{background-size: cover;}
}
// .newPortalWrap .newPortalPortletWrap {background-image: linear-gradient(to bottom, rgba(242, 242, 242, 0), #f2f2f2);}
.newPortalWrap .newPortalPortletWrap {margin:40px 0 0 0;padding:15px 0 0 0;background-color: #f2f2f2;}

/***************************** 
    포탈 헤더
******************************/
// 기본버전
.newPortalHeader{position: relative;height: 50px;margin: 0;padding: 0;border-bottom: 1px solid rgba(255, 255, 255, 0.2);box-sizing: border-box;background-color: #ffffff;
    .header-ci{position: relative;width: 200px;height: 50px;margin: 0;padding: 5px 20px;box-sizing: border-box;z-index: 101;
        h2{overflow: hidden; margin: 0; font-size: 1px; height:40px; line-height:40px;
            img{max-width: 150px;max-height: 38px;vertical-align: middle;cursor: pointer;}
        }
    }
    #headFnc{position: relative;width: 100%;height: 50px;z-index: 1;
        .headfnc{margin:0 20px 0 20px;height: 50px;
            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
            // 자동 로그아웃 타이머
            .logoutTimer{position: relative;align-items:center;
                &::after{content:'';margin:1px 5px 0 10px;width:1px;height:16px;background-color:#e6e6e6;}
                .clockIcon{margin:0 5px 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_time_s_normal.png') no-repeat center;}
                .timeText{margin:0 5px 0 0;font-size:12px;line-height:18px;color:#000000;}
            }
            .head-misc{position:relative;height: 50px;
                ul li{position: relative;margin: 10px 0 0 6px;min-width:30px;min-height:30px;
                    span.btn{display: block;width: 30px;height: 30px;background: transparent no-repeat center;cursor: pointer;}
                    span.btnCnt{position: absolute;top: 0;right: 0;min-width: 14px;height: 14px;line-height: 1;padding: 1px 3px 0 3px;font-family: '돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif;font-size: 11px;color: #ffffff;text-align: center;border-radius: 10px;background: #fc5356;box-sizing: border-box;cursor: pointer;}
                    span.alert{background-image: url('../imgs/main/ico_misc_alert.png');}
                    span.mail{background-image: url('../imgs/main/ico_misc_mail.png');}
                    span.sign{background-image: url('../imgs/main/ico_misc_sign.png');}
                    span.org{background-image: url('../imgs/main/ico_misc_org.png');}
                    span.search{background-image: url('../imgs/main/ico_misc_search.png');}
                    span.more{background-image: url('../imgs/main/ico_misc_more.png');}
                    span.manual{background-image: url('../imgs/main/ic_misc_manual.png');}                    
                    span.notice{background-image: url('../imgs/main/ic_misc_notice.png');}                    
                }
                .directSearchWrap{position:absolute;top:10px;right:0;z-index: 1;width:0%;transition: all 0.1s; display: none;
                    &.on{width:calc(100% - 45px); display: block;
                        .searchBar{position: relative;z-index: 1;margin-left:0px;
                            .searchInput {width: 100%;border: solid 1px #e6e6e6;padding:0 60px 0 10px;
                                &:focus{border: solid 1px #1c90fb;background-color: #ffffff;box-sizing: border-box;outline: none;}
                                &::placeholder {font-weight:normal !important;color:#a6a6a6;}
                            }
                        }
                    }
                    .searchBar{position: relative;z-index: 1;
                        .searchInput{width:0px;height: 32px;padding: 0 10px 0 10px;border-radius: 16px;background-color: #fff;box-sizing: border-box;outline: none;transition:all 0.2s;border: solid 1px #fff;}
                        .deleteBtn{position: absolute;top:7px;right:75px;width:18px;height:18px;background: url('../imgs/ico/ic_close_01_s_normal.png') no-repeat center;cursor: pointer;}
                        .searchBtn{position: absolute;top:7px;right:50px;width:18px;height:18px;background: url('../imgs/ico/ico_misc_search.png') no-repeat center;cursor: pointer;}
                        .closeBtn{width:40px;font-size:12px;font-weight:normal;line-height:32px;color:#000000;text-align: center;cursor: pointer;}
                        .aiBtn{display:none;}
                        &.oneAi{
                            .deleteBtn{position: absolute;top:7px;right:115px;width:18px;height:18px;background: url('../imgs/ico/ic_close_01_s_normal.png') no-repeat center;cursor: pointer;}
                            .searchBtn{position: absolute;top:7px;right:90px;width:18px;height:18px;background: url('../imgs/ico/ico_misc_search.png') no-repeat center;cursor: pointer;}
                            .aiBtn{display:block;margin:-3px 0 0 0;width:40px;height:40px;background: url('../imgs/oneAi/img_oneai_40_normal@2x.png') no-repeat center;background-size:40px;cursor: pointer;
                                &:hover{background: url('../imgs/oneAi/img_oneai_40_over@2x.png') no-repeat center;background-size:40px;}
                            }  
                        }
                    }
                    .autoText{display: none;position:absolute;top: 35px;right: 5px;margin: 0;box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);background: #ffffff;border-radius: 15px;overflow: hidden;z-index: 10;
                        .autoTextIn{max-width: 500px;height: auto;max-height: 460px;padding: 0 0 20px 0;overflow: auto;
                            dl{padding: 20px 20px 0px 0px;
                                dt{font-size:14px;font-weight:bold;color: #000000;padding: 0 0 7px 15px;background: url('../imgs/main/ico_autotext_arrow.png') no-repeat left 10px;}
                                dd{padding: 0 15px;line-height: 22px;white-space: nowrap;
                                    .link{position: relative;display: inline-block;font-size:12px;font-weight:normal;color: #000000;outline:none;cursor: pointer;
                                        &::after{content:"";position: absolute;left: 0;bottom: 0;width: 0%;height: 1px;background: #000000;transition: all 0.5s;}
                                        &:hover:after{width:100%;transition: all 0.5s;}
                                        &:focus:after{width:100%;transition: all 0.5s;}
                                        .match{color:#1c90fb;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .user-info{float: left;position: relative;cursor: pointer;padding:0 14px 0 0;
                &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
                &::before {content:"";position:absolute;top:8px;right:0;width:1px;height:16px;background-color: #e6e6e6;}
                &.on::before {display: none;}
                .divi_pic{float:left;border-radius: 50%;width: 32px;height: 32px;background: transparent no-repeat center;cursor: pointer;overflow: hidden;
                    img{width: 100%;vertical-align: top;}
                }
                .name_txt{position: relative;display: inline-block;margin: 1px 0 0 10px;line-height: 14px;font-size: 11px;color: #000000;letter-spacing: -0.5px;min-width:52px;box-sizing:border-box;}
                .divi_txt{position: relative;display: inline-block;padding-right:18px;margin: 0 0 0 10px;line-height: 14px;font-size: 10px;color: #000000;background: url('../imgs/main/ico_diviTxt_arrow_tabOn.png') no-repeat right center;letter-spacing: -0.5px;min-width:52px;box-sizing:border-box;}
            }
        }
    }
    #headTabs{position: relative;width:100%;height: 50px;overflow:hidden;
        .tabs{margin:10px 30px 0 0;font-size: 0;white-space: nowrap;overflow:hidden;
            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
            .tab-item{position: relative;display: inline-block;width: 138px;height: 32px;padding: 0 23px 0 0px;border-style: solid;border-radius: 3px;border: solid 1px #1c90fb;box-sizing: border-box;vertical-align: top;margin-right:2px;
                .react-contextmenu-wrapper {padding-left: 34px;}
                .new-window{position: absolute;top:0;left: 8px;width: 18px;height: 30px;background: url('../imgs/ico/icon_tabon_new_select@2x.png') no-repeat center;background-size:11px;cursor: pointer;}
                .module-name{display: block;font-size: 12px;color: #1c90fb;line-height: 30px;letter-spacing: -0.5px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;
                    &.toolTipOn + .module-name-clone + .pubTooltip {visibility: visible;}
                }
                .item-close{position: absolute;top:0;right: 8px;width: 18px;height: 30px;background: url('../imgs/ico/icon_tabon_close_select@2x.png') no-repeat center;background-size:11px;cursor: pointer;}
            }
        }
        .tab-more-out {position: absolute;top: 10px;right: 0;bottom: 0;width: 26px;height: 32px;box-sizing: border-box;}
        .tab-more{width: 26px;height: 32px;box-sizing: border-box;background:#fff url('../imgs/ico/dot_more_over@2x.png') no-repeat center;background-size:12px 4px;cursor: pointer;border: solid 1px #e6e6e6;  border-radius: 3px;
            &:hover{border: solid 1px #8c8c8c;background-color:#eff7ff;transition:all 0.3s;}
        }
    }
    .module-name-clone {position: fixed;left:-50000px; display: block; font-size: 12px; font-weight: 200; color: #8c8c8c; line-height: 2; letter-spacing: -0.5px; padding: 3px 0;}
}

// 투명버전
.newPortalHeader.w_mode{position: relative;height: 50px;margin: 0;padding: 0;border-bottom: 1px solid rgba(255, 255, 255, 0.2);box-sizing: border-box;background-color: transparent;
    .header-ci{position: relative;width: 200px;height: 50px;margin: 0;padding: 5px 20px;box-sizing: border-box;z-index: 101;
        h2{overflow: hidden; margin: 0; font-size: 1px; height:40px; line-height:40px;
            img{max-width: 150px;max-height: 38px;vertical-align: middle;cursor: pointer;}
        }
    }
    #headFnc{position: relative;width: 100%;height: 50px;z-index: 1;
        .headfnc{margin:0 20px 0 20px;height: 50px;
            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
            // 자동 로그아웃 타이머
            .logoutTimer{position: relative;align-items:center;
                &::after{content:'';margin:0 5px 0 10px;width:1px;height:16px;background-color:rgba(255, 255, 255, 0.3);}
                .clockIcon{margin:1px 5px 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_time_s_w_normal.png') no-repeat center;}
                .timeText{margin:0 5px 0 0;font-size:12px;line-height:18px;color:#ffffff;}
                .timeExtensionBtn{background-image: none !important;color: #ffffff !important;
                    &:hover{border:1px solid #ffffff !important;}
                    &:active{border:1px solid #ffffff !important;background-color:rgba(255, 255, 255, 0.2) !important;}
                }
            }
            .head-misc{height: 50px;
                ul li{position: relative;margin: 10px 0 0 6px;min-width:30px;min-height:30px;
                    span.btn{display: block;width: 30px;height: 30px;background: transparent no-repeat center;cursor: pointer;}
                    span.btnCnt{position: absolute;top: 0;right: 0;min-width: 14px;height: 14px;line-height:1;padding: 1px 3px 0 3px;font-family: '돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif;font-size: 11px;color: #ffffff;text-align: center;border-radius: 10px;background: #fc5356;box-sizing: border-box;cursor: pointer;}
                    span.alert{background-image: url('../imgs/main/icon_misc_alert@2x.png');background-size:30px;}
                    span.org{background-image: url('../imgs/main/icon_misc_org@2x.png');background-size:30px;}
                    span.search{background-image: url('../imgs/main/icon_misc_search@2x.png');background-size:30px;}
                    span.more{background-image: url('../imgs/main/icon_misc_more@2x.png');background-size:30px;}
                    span.manual{background-image: url('../imgs/main/ic_misc_manual_w@2x.png');background-size:30px;}
                    span.notice{background-image: url('../imgs/main/ic_misc_notice_w@2x.png');background-size:30px;}
                }
                .directSearchWrap{position:absolute;top:10px;right:0;z-index: 1;width:0%;transition: all 0.1s; display: none;
                    &.on{width:calc(100% - 45px); display: block;
                        .searchBar{position: relative;z-index: 1;margin-left:0px;
                            .searchInput {width: 200px;border: solid 1px rgba(255, 255, 255, 0.5);padding:0 60px 0 10px;color:#ffffff;
                                &:focus{border: solid 1px #ffffff;background-color: transparent;box-sizing: border-box;outline: none;}
                                &::placeholder {font-weight:normal !important;color:rgba(255, 255, 255, 0.5);}
                            }
                        }
                    }
                    .searchBar{position: relative;z-index: 1;
                        .searchInput{width:0px;height: 32px;padding: 0 10px 0 10px;border-radius: 16px;background-color: transparent;box-sizing: border-box;outline: none;transition:all 0.2s;border: solid 1px #fff;}
                        .deleteBtn{position: absolute;top:6px;right:75px;width:18px;height:18px;background: url('../imgs/main/icon_tabon_close_w_select@2x.png') no-repeat center;background-size:14px;cursor: pointer;}
                        .searchBtn{position: absolute;top:6px;right:50px;width:18px;height:18px;background: url('../imgs/main/icon_misc_search@2x.png') no-repeat center;background-size:30px;cursor: pointer;}
                        .closeBtn{width:40px;font-size:12px;font-weight:normal;line-height:32px;color:rgba(255, 255, 255, 0.5);text-align: center;cursor: pointer;}
                        .aiBtn{display:none;}
                        &.oneAi {
                            .deleteBtn{position: absolute;top:6px;right:115px;width:18px;height:18px;background: url('../imgs/main/icon_tabon_close_w_select@2x.png') no-repeat center;background-size:14px;cursor: pointer;}
                            .searchBtn{position: absolute;top:6px;right:90px;width:18px;height:18px;background: url('../imgs/main/icon_misc_search@2x.png') no-repeat center;background-size:30px;cursor: pointer;}
                            .aiBtn{display:block;margin:-3px 0 0 0;width:40px;height:40px;background: url('../imgs/oneAi/img_oneai_40_normal@2x.png') no-repeat center;background-size:40px;cursor: pointer;
                                &:hover{background: url('../imgs/oneAi/img_oneai_40_over@2x.png') no-repeat center;background-size:40px;}
                            }  
                        }
                    }
                    .autoText{display: none;position: absolute;top: 35px;right:5px;margin: 0;box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);background: #ffffff;border-radius: 15px;overflow: hidden;z-index: 10;
                        .autoTextIn{max-width: 500px;height: auto;max-height: 460px;padding: 0 0 20px 0;overflow: auto;
                            dl{padding: 20px 20px 0px 0px;
                                dt{font-size:14px;font-weight:bold;color: #000000;padding: 0 0 7px 15px;background: url('../imgs/main/ico_autotext_arrow.png') no-repeat left 10px;}
                                dd{padding: 0 15px;line-height: 22px;white-space: nowrap;
                                    .link{position: relative;display: inline-block;font-size:12px;font-weight:normal;color: #000000;outline:none;cursor: pointer;
                                        &::after{content:"";position: absolute;left: 0;bottom: 0;width: 0%;height: 1px;background: #000000;transition: all 0.5s;}
                                        &:hover:after{width:100%;transition: all 0.5s;}
                                        &:focus:after{width:100%;transition: all 0.5s;}
                                        .match{color:#1c90fb;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .user-info{float: left;position: relative;cursor: pointer;padding:0 14px 0 0;
                &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
                &::before {content:"";position:absolute;top:8px;right:0;width:1px;height:16px;background-color: rgba(255, 255, 255, 0.2);}
                &.on::before {display: none;}
                .divi_pic{float:left;border-radius: 50%;width: 32px;height: 32px;background: transparent no-repeat center;cursor: pointer;overflow: hidden;
                    img{width: 100%;vertical-align: top;}
                }
                .name_txt{position: relative;display: inline-block;margin: 1px 0 0 10px;line-height: 14px;font-size: 11px;color: #ffffff;letter-spacing: -0.5px;min-width:52px;box-sizing:border-box;}
                .divi_txt{position: relative;display: inline-block;padding-right:18px;margin: 0 0 0 10px;line-height: 14px;font-size: 10px;color: #ffffff;background: url('../imgs/main/ico_diviTxt_arrow.png') no-repeat right center;letter-spacing: -0.5px;min-width:52px;box-sizing:border-box;}
            }
        }
    }
    #headTabs{position: relative;width:100%;height: 50px;overflow:hidden;
        .tabs{margin:10px 30px 0 0;font-size: 0;white-space: nowrap;overflow:hidden;
            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
            .tab-item{position: relative;display: inline-block;width: 138px;height: 32px;padding: 0 23px 0 0px;border-style: solid;border-radius: 3px;border: solid 1px rgba(255, 255, 255, 0.5);box-sizing: border-box;vertical-align: top;margin-right:2px;
                &:hover{border: solid 1px #ffffff;}
                &:active{border: solid 1px #ffffff;background-color: rgba(255, 255, 255, 0.2);}
                .react-contextmenu-wrapper {padding-left: 34px;}
                .new-window{position: absolute;top:0;left: 8px;width: 18px;height: 30px;background: url('../imgs/main/icon_tabon_new_w_select@2x.png') no-repeat center;background-size:11px;cursor: pointer;}
                .module-name{display: block;font-size: 12px;font-weight: 200;color: #ffffff;line-height: 2;letter-spacing: -0.5px;padding: 3px 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;
                    &.toolTipOn + .module-name-clone + .pubTooltip {visibility: visible;}
                }
                .item-close{position: absolute;top:0;right: 8px;width: 18px;height: 30px;background: url('../imgs/main/icon_tabon_close_w_select@2x.png') no-repeat center;background-size:11px;cursor: pointer;}
            }
        }
        .tab-more-out {position: absolute;top: 10px;right: 0;bottom: 0;width: 26px;height: 32px;box-sizing: border-box;}
        .tab-more{width: 26px;height: 32px;box-sizing: border-box;background:url('../imgs/main/dot_more_normal_w@2x.png') no-repeat center;background-size:12px 4px;cursor: pointer;border: solid 1px rgba(255, 255, 255, 0.5);  border-radius: 3px;
            &:hover{border: solid 1px #ffffff;}
            &:active{border: solid 1px #ffffff;background-color: rgba(255, 255, 255, 0.2);}
        }
    }
    .module-name-clone {position: fixed;left:-50000px; display: block; font-size: 12px; font-weight: 200; color: #8c8c8c; line-height: 2; letter-spacing: -0.5px; padding: 3px 0;}
}




/***************************** 
    포탈 검색
******************************/
/* 검색 */
.newPortalSearchWrap .newPortalSearchBox{position: fixed;bottom: 40%;left: 0;right:0;width: 640px;min-width: 640px;margin: 0 auto;z-index: 3;}
// 슬로건 문구
.newPortalSearchWrap .newPortalSearchBox .sloganBox{position: absolute;bottom: 130px;left: 50%;width: 640px;margin: 0 0 0 -320px;text-align: center;}
.newPortalSearchWrap .newPortalSearchBox .sloganBox .text01{min-height: 50px;font-weight:bold;font-size: 32px;font-stretch: normal;font-style: normal;color: #ffffff;line-height: 1;letter-spacing: normal;text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);word-break: break-all;}
.newPortalSearchWrap .newPortalSearchBox .sloganBox .text02{min-height: 30px;font-weight:normal;font-size: 26px;font-stretch: normal;font-style: normal;color: #ffffff;line-height: 1;letter-spacing: normal;text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);word-break: break-all;}
.newPortalSearchWrap .newPortalSearchBox .sloganBox .bar{width: 18px;height: 2px;margin:22px auto 26px auto;opacity: 0.5;background-color: #ffffff;}
// 검색바
.newPortalSearchWrap .newPortalSearchBox .searchBar{position: relative;width:500px;height:56px;margin:0 auto;z-index:1;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .inpBox{width: 100%;height: inherit;font-size:18px;font-weight:normal;letter-spacing: -0.5px;color: #000000;text-indent: 0px !important;padding: 0 80px 1px 22px;border:2px solid transparent;outline: none;border-radius: 56px;box-sizing: border-box;-webkit-backdrop-filter: blur(50px);backdrop-filter: blur(50px);box-shadow: 0px 5px 40px 0 rgba(0, 0, 0, 0.2);background-color: rgba(255, 255, 255, 0.85);}
.newPortalSearchWrap .newPortalSearchBox .searchBar .inpBox:focus{border:2px solid #1c90fb;-webkit-backdrop-filter: none;backdrop-filter: none;box-shadow: none;background-color: rgba(255, 255, 255, 1);}
.newPortalSearchWrap .newPortalSearchBox .searchBar .search-btn{display: block;position: absolute;top: 3px;right: 17px;width: 30px;height: 50px;background: url('../imgs/main/ico_search_btn_normal.png') no-repeat center;cursor: pointer;z-index: 1;transition: 0.3s;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .search-btn:hover{display: block;position: absolute;top: 3px;right: 17px;width: 30px;height: 50px;background: url('../imgs/main/ico_search_btn_over.png') no-repeat center;cursor: pointer;z-index: 1;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .reset-btn{display:none;position: absolute;top: 3px;right: 47px;width: 30px;height: 50px;background: url('../imgs/ico/ic_close_01_l_disable.png') no-repeat center;cursor: pointer;z-index: 1;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .reset-btn:hover{display:none;position: absolute;top: 3px;right: 47px;width: 30px;height: 50px;background: url('../imgs/ico/ic_close_01_l_over.png') no-repeat center;cursor: pointer;z-index: 1;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .cancel-btn{display:none;position: absolute;top: 13px;right: -56px;font-size:18px;font-weight:normal;line-height:27px;color:#ffffff;cursor: pointer;z-index: 1;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .ai-btn{display:block;position: absolute;top: -1px;right: -118px;width: 108px;height: 58px;cursor: pointer;z-index: 1;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .ai-btn:hover{background: url('../imgs/oneAi/btn_oneai_search_over@2x.png') no-repeat center;background-size:108px 58px;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .ai-btn:hover .oneAiLottie{display:none;}

.newPortalSearchWrap .newPortalSearchBox .searchBar .ai-btn-s{display:block;position: absolute;top: 4px;right: -53px;width:48px;height: 48px;cursor: pointer;z-index: 1; background: url('../imgs/oneAi/btn_oneai_search_s_normal@2x.png') no-repeat center;background-size:48px 48px;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .ai-btn-s:hover{background: url('../imgs/oneAi/btn_oneai_search_s_over@2x.png') no-repeat center;background-size:48px 48px;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .ai-btn-s:hover img{display:none;}

// 검색어 자동완성
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText{display: none;position: absolute;top: calc(100% + 5px);left: 50%;width: 500px;background: #ffffff;border-radius: 28px;overflow: hidden;transform: translateX(-250px);box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);z-index: 10;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn{height: auto;max-height: 460px;margin:20px 0;overflow: auto;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl{padding: 10px 20px;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl dt{font-size:16px;font-weight:bold;color: #000000;padding: 0 0 7px 15px;background: url('../imgs/main/ico_autotext_arrow.png') no-repeat left 10px;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl dd{padding: 0 15px;line-height: 22px;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl dd .link{position: relative;display: inline-block;font-size:14px;font-weight:normal;color: #000000;outline:none;cursor: pointer;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl dd .link:after{content:"";position: absolute;left: 0;bottom: 0;width: 0%;height: 1px;background: #000000;transition: all 0.5s;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl dd .link:hover:after{width:100%;transition: all 0.5s;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl dd .link:focus:after{width:100%;transition: all 0.5s;}
.newPortalSearchWrap .newPortalSearchBox .searchBar .newPortalAutoText .autoTextIn dl dd .match{color:#1c90fb;}
// 최근검색어
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword{position: relative;width: 100%;max-width: 500px;height: 50px;margin: 0 auto 20px auto;overflow: hidden;text-align: center;animation-fill-mode: none;}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dl:after {content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dl{display: inline-block;}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dt{display:inline-block;font-size:12px;font-weight:bold;height: 24px;line-height: 25px;margin: 14px 8px 0 0;color: #ffffff;}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dd{position: relative;display:inline-block;font-size: 0;margin:14px 3px;padding: 0 22px 0 7px;border: solid 1px rgba(255, 255, 255, 0.5);border-radius: 24px;box-sizing: border-box;background-color: rgba(255, 255, 255, 0.2);cursor: pointer;}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dd:hover{border: 1px solid #ffffff;background-color: rgba(255, 255, 255, 0.3);transition:all 0.3s}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dd span:first-child{display: inline-block;padding: 1px 0 2px 0;font-size:13px;font-weight:bold;color: #ffffff;}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dd .delete_btn{position: absolute;top: 4px;right: 5px;width: 14px;height: 14px;background: url('../imgs/main/ico_lately_x.png') no-repeat center;opacity: 0.5;}
.newPortalSearchWrap .newPortalSearchBox .newPortalLatelyKeyword dd .delete_btn:hover{opacity: 1;}
// 스크롤 사인버튼
.newPortalSearchWrap .newPortalSearchBox .newPortalScrollSignBtn{position: absolute;left: 50%;width: 40px;height: 32px;margin: 20% 0 0 -20px;box-sizing: border-box;animation-name: rubberBand;animation-duration: 1s;animation-delay: 0.2s;background: url('../imgs/main/ico_scroll_sign_normal.png') no-repeat bottom center;cursor: pointer;transition: 0.3s;}
.newPortalSearchWrap .newPortalSearchBox .newPortalScrollSignBtn:hover{animation-name: float;animation-duration: 2s;animation-iteration-count: infinite;animation-delay: 0s;background: url('../imgs/main/ico_scroll_sign_over.png') no-repeat bottom 5px center;}
.newPortalSearchWrap .newPortalSearchBox .newPortalScrollSignBtn:after{content:'Scroll';display:block;font-size:12px;font-weight:normal;line-height: 1.25;letter-spacing: normal;text-align: center;color: #ffffff;text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);}
/* 탱고메뉴 모듈박스 */
.tangoMenu{position: absolute;left: 50%;bottom: 0;max-width: 896px;width:max-content;height: 102px;border-radius: 10px 10px 0 0;-webkit-backdrop-filter:saturate(180%) blur(20px);backdrop-filter:saturate(180%) blur(20px);background-color: rgba(0, 0, 0, 0.26);z-index:2;transform: translateX(-50%);animation-fill-mode: none;
    &:hover{overflow:unset;}
    ul{font-size: 0;padding: 16px 28px;
        li{position: relative;display: inline-block;width: 70px;height: 82px;margin: 0;padding: 0;text-align: center;vertical-align: top;transition: 0.2s;
            &:hover{background-size: 70px !important;background-position: top 0 center !important;cursor: pointer;}
            .tooltipDiv{position:relative;width:70px;height:82px;
                ~ div{position: absolute !important;top: -35px !important;left: 50% !important;transform: translateX(-50%);}
                ~ div > div:nth-child(1) > div:nth-child(1){background-color: #2b2b2b;border-color: rgba(255, 255, 255, 0.35);color: #fff;}
                ~ div > div:nth-child(1) > div:nth-child(1) ~ div > div:nth-child(1){border-top-color: rgba(255, 255, 255, 0.35);}
            }
            .itemName{display:none;}
            .itemCnt{position: absolute;top: 5px;right: 4px;min-width: 16px;height: 16px;padding: 2px 3px 0 3px;font-family: '돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif;font-size: 11px;color: #ffffff;text-align: center;border-radius: 10px;background: #fc5356;box-sizing: border-box;}
            &.addIcon{display:none !important;}
            &.selected{border-radius: 10px;outline: 1px solid #1c90fb;background-color: rgba(0,0,0,0.3);}
        }
        
    }
    // 인하우스 아이콘 그룹(메인) //
    .inHouse_ecoIcon{background:url('../imgs/main/ecoPlace/ic_default@2x.png') no-repeat top 6px center;background-size: 58px;}             // 기본아이콘
    // 메일 그룹
    .ML_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_mail@2x.png') no-repeat top 6px center;background-size: 58px;}                   // 메일
    .ML_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_mail_write@2x.png') no-repeat top 6px center;background-size: 58px;}             // 메일쓰기
    .ML_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_mail_all@2x.png') no-repeat top 6px center;background-size: 58px;}               // 전체메일함
    .ML_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_mail_send@2x.png') no-repeat top 6px center;background-size: 58px;}              // 보낸메일함
    .ML_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_mail_receive@2x.png') no-repeat top 6px center;background-size: 58px;}           // 받은메일함
    // 문자 그룹
    .SMS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sms@2x.png') no-repeat top 6px center;background-size: 58px;}                   // 문자
    .SMS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_sms_send@2x.png') no-repeat top 6px center;background-size: 58px;}              // 문자보내기
    // 업무관리 그룹
    .KS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_kiss@2x.png') no-repeat top 6px center;background-size: 58px;}                   // 업무관리
    .KS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_mywork@2x.png') no-repeat top 6px center;background-size: 58px;}                 // 나의업무
    .KS_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_project_star@2x.png') no-repeat top 6px center;background-size: 58px;}           // 나의프로젝트
    // 일정 그룹
    .CL_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_calendar@2x.png') no-repeat top 6px center;background-size: 58px;}               // 일정
    .CL_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_rm@2x.png') no-repeat top 6px center;background-size: 58px;}                     // 자원
    // 임직원업무관리 그룹
    .HR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_hr@2x.png') no-repeat top 6px center;background-size: 58px;}                     // 임직원업무관리
    .HR_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_attendance_write@2x.png') no-repeat top 6px center;background-size: 58px;}       // 근태신청
    .HR_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_attendance_cancel@2x.png') no-repeat top 6px center;background-size: 58px;}      // 근태신청취소
    .HR_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_paystub@2x.png') no-repeat top 6px center;background-size: 58px;}                // 급여명세서
    .HR_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_business_hours_agree@2x.png') no-repeat top 6px center;background-size: 58px;}   // 개인근무시간동의
    .HR_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_address_book@2x.png') no-repeat top 6px center;background-size: 58px;}           // 전체주소록
    .HR_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_report_send@2x.png') no-repeat top 6px center;background-size: 58px;}            // 보낸보고함
    .HR_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_report_receive@2x.png') no-repeat top 6px center;background-size: 58px;}         // 받은보고함
    .HR_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_memo@2x.png') no-repeat top 6px center;background-size: 58px;}                   // 노트
    .HR_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_expense@2x.png') no-repeat top 6px center;background-size: 58px;}                // 경비취합
    .HR_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_expense_write@2x.png') no-repeat top 6px center;background-size: 58px;}          // 경비청구작성
    .HR_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_sign_hr_write@2x.png') no-repeat top 6px center;background-size: 58px;}          // 지출결의서작성
    .HR_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_approval_write@2x.png') no-repeat top 6px center;background-size: 58px;}         // 품의서작성
    .HR_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat top 6px center;background-size: 58px;}                 // 개인인사정보조회
    // 전자결재 그룹
    .EA_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sign@2x.png') no-repeat top 6px center;background-size: 58px;}                   // 전자결재
    .EA_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_signhome@2x.png') no-repeat top 6px center;background-size: 58px;}               // 결재홈
    .EA_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_sign_write@2x.png') no-repeat top 6px center;background-size: 58px;}             // 결재작성
    // 팩스 그룹
    .FAX_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_fax@2x.png') no-repeat top 6px center;background-size: 58px;}                   // 팩스
    .FAX_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_fax_send@2x.png') no-repeat top 6px center;background-size: 58px;}              // 팩스보내기
    .FAX_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_fax_receive@2x.png') no-repeat top 6px center;background-size: 58px;}           // 받은팩스함
    // 원피스 그룹
    .OF_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_oneffice@2x.png') no-repeat top 6px center;background-size: 58px;}               // 원피스
    // 원챔버 그룹
    .OC_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_onechamber@2x.png') no-repeat top 6px center;background-size: 58px;}             // 원챔버
    .OC_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_my@2x.png') no-repeat top 6px center;background-size: 58px;}                     // 내문서함
    .OC_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_docuflow@2x.png') no-repeat top 6px center;background-size: 58px;}               // 도큐플로우
    // 회계 그룹
    .FI_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_account@2x.png') no-repeat top 6px center;background-size: 58px;}                // 회계관리
    .FI_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_nonprofit@2x.png') no-repeat top 6px center;background-size: 58px;}              // 예산관리
    // 인사근태 그룹
    .HM_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_human@2x.png') no-repeat top 6px center;background-size: 58px;}                  // 인사관리
    // 게시판 그룹
    .BD_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_board@2x.png') no-repeat top 6px center;background-size: 58px;}                  // 게시판
    // 확장기능 그룹
    .EX_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_user_plus@2x.png') no-repeat top 6px center;background-size: 58px;}              // 방문객등록
    // 총무구매 그룹 
    .GSM_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_supplies_write.png') no-repeat top 6px center;background-size: 58px;}           // 사무용품신청
    .GSM_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_supplies_manage.png') no-repeat top 6px center;background-size: 58px;}          // 사무용품신청관리
    .GSM_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_supplies_order.png') no-repeat top 6px center;background-size: 58px;}           // 사무용품주문관리
    .GSM_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_supplies_calculate.png') no-repeat top 6px center;background-size: 58px;}       // 사무용품정산관리
    // 통합주소록 그룹
    .ADDR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_total_address@2x.png') no-repeat top 6px center;background-size: 58px;}       // 통합주소록
    // HIS 그룹
    .HIS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_inhouse01@2x.png') no-repeat top 6px center;background-size: 58px;}        // 원무(HIS전용)
    .HIS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_inhouse02@2x.png') no-repeat top 6px center;background-size: 58px;}        // 진료(HIS전용)
    .HIS_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_inhouse03@2x.png') no-repeat top 6px center;background-size: 58px;}        // 진료지원(HIS전용)
    .HIS_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_inhouse04@2x.png') no-repeat top 6px center;background-size: 58px;}        // 보험심사(HIS전용)
    .HIS_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_inhouse05@2x.png') no-repeat top 6px center;background-size: 58px;}        // 통계(HIS전용)
    .HIS_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_inhouse07@2x.png') no-repeat top 6px center;background-size: 58px;}        // 기초코드(HIS전용)
    .HIS_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_inhouse08@2x.png') no-repeat top 6px center;background-size: 58px;}        // 서식(HIS전용)
    .HIS_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_inhouse09@2x.png') no-repeat top 6px center;background-size: 58px;}        // 재고관리(HIS전용)
    .HIS_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_inhouse10@2x.png') no-repeat top 6px center;background-size: 58px;}        // 병원정보설정(HIS전용)
    .HIS_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_inhouse11@2x.png') no-repeat top 6px center;background-size: 58px;}        // 간편 세무 회계(HIS전용)
    .HIS_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_education@3x.png') no-repeat top 6px center;background-size: 58px;}        // 법정교육(HIS전용)
    .HIS_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_labor@3x.png') no-repeat top 6px center;background-size: 58px;}            // 법무노무(HIS전용)
    .HIS_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_theporterzone@3x.png') no-repeat top 6px center;background-size: 58px;}    // 더포터존(HIS전용)
    .HIS_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_industrial@2x.png') no-repeat top 6px center;background-size: 58px;}       // 공단검진(HIS전용)
    .HIS_ecoIcon15{background:url('../imgs/main/ecoPlace/ic_policy@2x.png') no-repeat top 6px center;background-size: 58px;}           // 정책고시(HIS전용)
    .HIS_ecoIcon16{background:url('../imgs/main/ecoPlace/ic_notice@2x.png') no-repeat top 6px center;background-size: 58px;}           // 공지사항(HIS전용)
    // 로테크에디션
    .UR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_total_lawtech@2x.png') no-repeat top 6px center;background-size: 58px;}     // 로테크

    // 커스텀 그룹 (패키지 X )
    .Custom_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sales@2x.png') no-repeat top 6px center;background-size: 58px;}              // 매출관리
    .Custom_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_manage_account@2x.png') no-repeat top 6px center;background-size: 58px;}     // 계좌관리
    .Custom_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_salary@2x.png') no-repeat top 6px center;background-size: 58px;}             // 급여관리
    .Custom_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_fund@2x.png') no-repeat top 6px center;background-size: 58px;}               // 자금관리
    .Custom_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_corporation_card@2x.png') no-repeat top 6px center;background-size: 58px;}   // 법인카드
    .Custom_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_spend@2x.png') no-repeat top 6px center;background-size: 58px;}              // 지출결의
    .Custom_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat top 6px center;background-size: 58px;}             // 마이페이지
    .Custom_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_work_report@2x.png') no-repeat top 6px center;background-size: 58px;}        // 업무보고
    .Custom_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_keep@2x.png') no-repeat top 6px center;background-size: 58px;}               // KEEP
    .Custom_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_attendance@2x.png') no-repeat top 6px center;background-size: 58px;}         // 근태관리
    .Custom_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_crm@2x.png') no-repeat top 6px center;background-size: 58px;}                // CRM
    .Custom_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_business_hours@2x.png') no-repeat top 6px center;background-size: 58px;}     // 근무시간현황
    .Custom_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_distri@2x.png') no-repeat top 6px center;background-size: 58px;}             // 물류
    .Custom_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_produce@2x.png') no-repeat top 6px center;background-size: 58px;}            // 생산관리
    .Custom_ecoIcon15{background:url('../imgs/main/ecoPlace/ic_buy_write@2x.png') no-repeat top 6px center;background-size: 58px;}          // 구매신청
    .Custom_ecoIcon16{background:url('../imgs/main/ecoPlace/ic_portal@2x.png') no-repeat top 6px center;background-size: 58px;}             // 포털
    .Custom_ecoIcon17{background:url('../imgs/main/ecoPlace/ic_ex@2x.png') no-repeat top 6px center;background-size: 58px;}                 // 확장기능
    .Custom_ecoIcon18{background:url('../imgs/main/ecoPlace/ic_video@2x.png') no-repeat top 6px center;background-size: 58px;}              // 동영상보기    
    .Custom_ecoIcon19{background:url('../imgs/main/ecoPlace/ic_online_experience@2x.png') no-repeat top 6px center;background-size: 58px;}  // 온라인체험관
    .Custom_ecoIcon20{background:url('../imgs/main/ecoPlace/ic_system_setting@2x.png') no-repeat top 6px center;background-size: 58px;}     // 시스템환경설정
    .Custom_ecoIcon21{background:url('../imgs/main/ecoPlace/ic_process_management@2x.png') no-repeat top 6px center;background-size: 58px;} // 프로세스관리
    .Custom_ecoIcon22{background:url('../imgs/main/ecoPlace/ic_wehago@2x.png') no-repeat top 6px center;background-size: 58px;}             // 위하고
    .Custom_ecoIcon23{background:url('../imgs/main/ecoPlace/ic_wiki@2x.png') no-repeat top 6px center;background-size: 58px;}               // 위키
    .Custom_ecoIcon24{background:url('../imgs/main/ecoPlace/ic_jira@2x.png') no-repeat top 6px center;background-size: 58px;}               // 지라
    .Custom_ecoIcon25{background:url('../imgs/main/ecoPlace/ic_gcms@2x.png') no-repeat top 6px center;background-size: 58px;}               // GCMS
    .Custom_ecoIcon26{background:url('../imgs/main/ecoPlace/ic_skt_meetus@2x.png') no-repeat top 6px center;background-size: 58px;}         // SKT MeetUs
    .Custom_ecoIcon27{background:url('../imgs/main/ecoPlace/ic_nsm_10@2x.png') no-repeat top 6px center;background-size: 58px;}             // NSM10
    .Custom_ecoIcon28{background:url('../imgs/main/ecoPlace/ic_erp_10@2x.png') no-repeat top 6px center;background-size: 58px;}             // ERP10
    .Custom_ecoIcon29{background:url('../imgs/main/ecoPlace/ic_amaranth_10@2x.png') no-repeat top 6px center;background-size: 58px;}        // Amaranth10
    .Custom_ecoIcon30{background:url('../imgs/main/ecoPlace/ic_icube@2x.png') no-repeat top 6px center;background-size: 58px;}              // iCUBE
    .Custom_ecoIcon31{background:url('../imgs/main/ecoPlace/ic_cor_sol@2x.png') no-repeat top 6px center;background-size: 58px;}            // 기업금융관리
    .Custom_ecoIcon32{background:url('../imgs/main/ecoPlace/ic_sol@2x.png') no-repeat top 6px center;background-size: 58px;}                // 개인금융관리
    .Custom_ecoIcon33{background:url('../imgs/main/ecoPlace/ic_support@2x.png') no-repeat top 6px center;background-size: 58px;}            // 서포트
    .Custom_ecoIcon34{background:url('../imgs/main/ecoPlace/ic_smartlink.png') no-repeat top 6px center;background-size: 58px;}             // 스마트링크
    .Custom_ecoIcon35{background:url('../imgs/main/ecoPlace/ic_whistlenote@2x.png') no-repeat top 6px center;background-size: 58px;}        // 휘슬노트
    .Custom_ecoIcon36{background:url('../imgs/main/ecoPlace/ic_redcaptour@2x.png') no-repeat top 6px center;background-size: 58px;}         // 레드캡투어
    .Custom_ecoIcon37{background:url('../imgs/main/ecoPlace/ic_logispot@2x.png') no-repeat top 6px center;background-size: 58px;}           // 로지스팟

    // 탱고메뉴 모드변경
    &.floatMode{position: absolute;left: 50%;bottom: 0;max-width: 1060px;width:max-content;height: 132px;border-radius: 10px;background-color: rgba(0, 0, 0, 0.3);overflow: hidden;transform: translateX(-50%);
        ul{font-size: 0;padding: 16px 50px;
            li{position: relative;display: inline-block;width: 80px;height: 30px;margin: 0;padding: 80px 0 6px 0;text-align: center;transition: 0.2s;background-position: top 11px center !important;
                &:hover{background-size: 70px !important;background-position: top 5px center !important;}
                .itemName{display: block;font-size:12px;font-weight:normal;line-height:1.25;color: #ffffff;text-align: center;text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);animation-name: fadeIn;animation-duration: 0.5s;}
                .itemCnt{position: absolute;top: 10px;right: 10px;min-width: 16px;height: 16px;padding: 2px 3px 0 3px;font-family: '돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif;font-size: 11px;color: #ffffff;text-align: center;border-radius: 10px;background: #fc5356;box-sizing: border-box;}
            }
        }
    }
    // 탱고메뉴 세팅모드
    &.setMode{background-color: rgba(0, 0, 0, 0.5);
        ul{
            li{cursor:default;
                &[class*="ecoIcon"]{cursor: move;}
                &::before{content:'';position:absolute;top:11px;left:11px;width:58px;height:58px;border-radius: 12px;background-color: rgba(255, 255, 255, 0.12);box-sizing: border-box;}
                &:hover{background-size: 58px !important;background-position: top 11px center !important;}
                &.addIcon{display:inline-block !important;position:relative;cursor: pointer;
                    &:hover{
                        &::before{content:'';position:absolute;top:11px;left:11px;width:58px;height:58px;border-radius: 12px;border:1px dashed #1c90fb;background: rgba(255, 255, 255, 0.12) url('../imgs/ico/ic_add_01_l_over.png') no-repeat center;box-sizing: border-box;}
                    }
                    &::before{content:'';position:absolute;top:11px;left:11px;width:58px;height:58px;border-radius: 12px;border:1px dashed #ffffff;background: rgba(255, 255, 255, 0.12) url('../imgs/ico/ic_add_01_l_disable.png') no-repeat center;box-sizing: border-box;}
                }
                .itemCnt{display: none;}
                .itemDelete{display:none;
                    &.on{display:block;position: absolute;top:6px;left:6px;width:22px;height:22px;border-radius:22px;background:#ffffff url('../imgs/ico/ic_dock_delete@2x.png') no-repeat center;background-size:22px;box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);cursor: pointer;}
                }
                .itemEdit{display:none;
                    &.on{display:block;position: absolute;top:6px;right:6px;width:22px;height:22px;border-radius:22px;background:#ffffff url('../imgs/ico/ic_dock_modify@2x.png') no-repeat center;background-size:22px;box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);cursor: pointer;}
                }
                .itemLock{display:none;
                    &.on{display:block;position: absolute;top:6px;right:6px;width:22px;height:22px;border-radius:22px;background:#ffffff url('../imgs/ico/ic_dock_lock@2x.png') no-repeat center;background-size:22px;box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.4);}
                }
            }
        }
    }
    // 접고 펼치기 버튼
    .dockBtn{display: block;position: absolute;left: 50%;bottom: 0;width: 44px;height: 12px;margin: 0 0 0 -22px;background:url('../imgs/main/ico_dock_open_normal.png') no-repeat center center;cursor: pointer;
        &:hover{display: block;position: absolute;left: 50%;bottom: 0;width: 44px;height: 12px;margin: 0 0 0 -22px;background:url('../imgs/main/ico_dock_open_over.png') no-repeat center center;cursor: pointer;}
        &.open{display: block;position: absolute;left: 50%;bottom: 0;width: 44px;height: 12px;margin: 0 0 0 -22px;background:url('../imgs/main/ico_dock_close_normal.png') no-repeat center center;cursor: pointer;
            &:hover{display: block;position: absolute;left: 50%;bottom: 0;width: 44px;height: 12px;margin: 0 0 0 -22px;background:url('../imgs/main/ico_dock_close_over.png') no-repeat center center;cursor: pointer;}
        }
    }
    .userService{min-height:172px;margin:0 60px;padding:20px 0 33px 0;border-top:1px solid rgba(255, 255, 255, 0.17);box-sizing: border-box;
        .titleBox{
            .tit{font-size:14px;font-weight:bold;line-height:20px;color:#ffffff;margin-right:8px;}
            .arrLeft{width:20px;height:20px;background:url('../imgs/ico/ic_arrow_left_01_s_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;
                &:hover{
                    filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                }
                &.disabled{background:url('../imgs/ico/ic_arrow_left_01_s_disable@2x.png') no-repeat center;background-size:14px;}
            }
            .arrRight{width:20px;height:20px;background:url('../imgs/ico/ic_arrow_right_01_s_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;
                &:hover{
                    filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                }
                &.disabled{background:url('../imgs/ico/ic_arrow_right_01_s_disable@2x.png') no-repeat center;background-size:14px;}
            }
        }
        .serviceBox{margin:10px 0 0 0;overflow: hidden;scroll-behavior:smooth;
            .serviceItem{min-width:300px;min-height:88px;border-radius:10px;margin:0 0 0 18px;padding:0 16px;background-color: rgba(0, 0, 0, 0.3);box-sizing: border-box;
                &:first-child{margin:0;}
                .ico{width:58px;height:70px;margin:9px 10px 0 0;cursor: default;}
                .tit{font-size:12px;font-weight:bold;line-height:18px;color:#ffffff;margin:12px 0 0 0;}
                .txt{font-size:10px;font-weight:normal;line-height:16px;color:#c4c4c4;letter-spacing:-0.3px;}
                .btn{font-size:11px;font-weight:normal;line-height:18px;color:#ffffff;width:fit-content;margin:10px 0 0 0;padding:0 10px;border-radius:18px;background-color:rgba(255, 255, 255, 0.2);cursor: pointer;}
            }
        }
    }
}



/***************************** 
    포탈 포틀릿
******************************/
// 좌표블럭 그리드 세팅
.newPortalPortletWrap.grid12 .newPortalPortletGrid{position: relative;width: 1100px;margin:0 auto;overflow:hidden}
.newPortalPortletWrap.grid14 .newPortalPortletGrid{position: relative;width: 1280px;margin:0 auto;overflow:hidden}
.newPortalPortletWrap.grid16 .newPortalPortletGrid{position: relative;width: 1460px;margin:0 auto;overflow:hidden}
.newPortalPortletWrap.grid18 .newPortalPortletGrid{position: relative;width: 1640px;margin:0 auto;overflow:hidden}
.newPortalPortletWrap.grid20 .newPortalPortletGrid{position: relative;width: 1820px;margin:0 auto;overflow:hidden}

// 좌표블럭 그리드
.newPortalPortletGrid{position:relative;
    &::after{content:"";position:absolute;top:15px;bottom:0;left:50%;margin:0 0 0 -1px;border:1px dashed #ff5722;z-index:4;}
    &:before{content: "CENTER";position: absolute;top: 0;left: 50%;padding: 0px 20px;border-radius: 10px;background: #ff5722;color: #ffffff;z-index: 4;transform: translateX(-50%);}
    > table{table-layout: auto;border-collapse: separate;border-spacing: 20px;
        td{width: 70px;height: 70px;border-radius: 10px;border: solid 1px rgba(255, 255, 255, 0.2);background-color: rgba(175, 175, 175, 0.15);box-sizing: border-box;}
        td.setGrid{border:1px dashed #2196f3;background:#e6f4ff;}
        td.nowGrid{border:1px solid #dcdcdc;background:#eaeaea;}
        td.nowGrid.setGrid{border:1px dashed #2196f3;background:#e6f4ff;}
    }
}
// 좌표블럭 그리드 숨김처리
.newPortalPortletGrid.nonGrid{position:relative;
    &::after , &:before , & > table , .setting {display:none;}
}

// 포틀릿
.newPortalPortletGrid .newPortletDiv{
    // 포틀릿 타입별 박스정의
    .portletBox{position: absolute;border-radius: 10px;box-sizing: border-box;box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);background-color: #f9f9f9;z-index: auto;
        .ptl_content{position:relative;border-radius: 10px;overflow:hidden;z-index:1;
            .nodata{position: absolute;top: 50%;left: 0;right: 0;width: 250px;margin: 0 auto;padding: 40px 0 0 0;font-size:12px;font-weight:normal;color: rgba(0,0,0,0.25);text-align: center;border-radius: 10px;transform: translateY(-50%);box-sizing: border-box;background:#f9f9f9 url('../imgs/main/img_portlet_empty.png') no-repeat top center;}
        }
    }
    .iframeBox{position: absolute;border-radius: 10px;box-sizing: border-box;box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);background-color: #f9f9f9;z-index: auto;
        .ptl_content{position:relative;border-radius: 10px;overflow:hidden;z-index:1;
            .nodata{position: absolute;top: 50%;left: 0;right: 0;width: 250px;margin: 0 auto;padding: 40px 0 0 0;font-size:12px;font-weight:normal;color: rgba(0,0,0,0.25);text-align: center;border-radius: 10px;transform: translateY(-50%);box-sizing: border-box;background:#f9f9f9 url('../imgs/main/img_portlet_empty.png') no-repeat top center;}
        }
    }
    .titleBox{position: absolute;border-radius: 10px;box-sizing: border-box;box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);background-color: #f9f9f9;z-index: auto;
        .ptl_content{position:relative;border-radius: 10px;overflow:hidden;z-index:1;
            .ptl_graph{width:100%;height:100%;}
            .nodata{position: absolute;top: 40%;left: 0;right: 0;width: 250px;margin: 0 auto;padding: 50px 0 0 0;font-size:12px;font-weight:normal;color: rgba(0,0,0,0.25);text-align: center;border-radius: 10px;transform: translateY(-50%);box-sizing: border-box;background:#f9f9f9 url('../imgs/empty/img_empty_data_s.png') no-repeat top center;}
            .signFormNodata{position: absolute;top: 40%;left: 0;right: 0;width: 250px;margin: 0 auto;padding: 50px 0 0 0;font-size:12px;font-weight:normal;color: rgba(0,0,0,0.25);text-align: center;border-radius: 10px;transform: translateY(-50%);box-sizing: border-box;background:#f9f9f9 url('../imgs/empty/img_empty_data_s.png') no-repeat top center;}
            .boardNodata{position: absolute;top: 40%;left: 0;right: 0;width: 250px;margin: 0 auto;padding: 50px 0 0 0;font-size:12px;font-weight:normal;color: rgba(0,0,0,0.25);text-align: center;border-radius: 10px;transform: translateY(-50%);box-sizing: border-box;background:#f9f9f9 url('../imgs/empty/img_empty_memo_s.png') no-repeat top center;}
            .mailNodata{position: absolute;top: 40%;left: 0;right: 0;width: 250px;margin: 0 auto;padding: 50px 0 0 0;font-size:12px;font-weight:normal;color: rgba(0,0,0,0.25);text-align: center;border-radius: 10px;transform: translateY(-50%);box-sizing: border-box;background:#f9f9f9 url('../imgs/empty/img_empty_memo_s.png') no-repeat top center;}
            .documentNodata{position: absolute;top: 40%;left: 0;right: 0;width: 250px;margin: 0 auto;padding: 50px 0 0 0;font-size:12px;font-weight:normal;color: rgba(0,0,0,0.25);text-align: center;border-radius: 10px;transform: translateY(-50%);box-sizing: border-box;background:#f9f9f9 url('../imgs/empty/img_empty_data_s.png') no-repeat top center;}
        }
        h2{position: relative;color: #000000;font-size: 0px;height: 30px;margin: 0;padding: 20px 70px 0 25px;font-weight:normal;
            & > span{display: flex;font-size:14px;font-weight:bold;line-height:30px;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;
                &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}
            }
            span.date{margin:0 0 0 10px;font-size:12px;font-weight:normal;line-height:30px;color:#8e8e8e;}
            span.barline{float:left;color:#f1f1f1;margin:0 6px 0 0;}
            span.barLine{color:rgba(0, 0, 0, 0.1);margin:0 10px;line-height: 27px;}
            .refresh{display:block;position:absolute;top:26px;right:44px;width:18px;height:18px;margin:0;background:url('../imgs/ico/ic_reset_m_normal.png') no-repeat center;cursor: pointer;
                &:hover{background:url('../imgs/ico/ic_reset_m_over.png') no-repeat center;}
                &.disabled{background:url('../imgs/ico/ic_reset_m_disable.png') no-repeat center;cursor:not-allowed;pointer-events:none;}
            }
            .more{display:block;position:absolute;top:26px;right:26px;width:18px;height:18px;margin:0;background:url('../imgs/ico/ic_arrow_right_01_m_normal.png') no-repeat right -5px center;cursor: pointer;
                &:hover{background:url('../imgs/ico/ic_arrow_right_01_m_over.png') no-repeat right -5px center;}
            }
            .msg{display:block;position:absolute;top:26px;right:26px;width:18px;height:18px;margin:0;background:url('../imgs/ico/ic_msg_m_normal.png') no-repeat right;cursor: pointer; 
                &:hover{background:url('../imgs/ico/ic_msg_m_over.png') no-repeat right;} 
            }
            .msgBtn{width:18px;height:18px;margin:0;background:url('../imgs/ico/ic_msg_m_normal.png') no-repeat right;cursor: pointer; 
                &:hover{background:url('../imgs/ico/ic_msg_m_over.png') no-repeat right;} 
            }
            .line{margin:0 6px;width:1px;height:10px;background:rgba(0, 0, 0, 0.1);}
        }
    }

    // portlet formTable
    .portletFormStyle{margin:10px 25px 20px 25px;
        >  table {width:100%;height:100%;border-collapse: collapse !important;box-sizing:border-box;border-top:2px solid #cccccc;
            tbody {border-collapse: collapse;
                tr {height:auto;}
                th+th {border-left:1px solid #e6e6e6;}
                th,td {border-top:1px solid #e6e6e6;border-collapse: collapse;border-spacing: 0;}
                th {height:30px;padding:0 8px;font-size:13px;color:#4a4a4a;text-align: center !important;border-left:1px solid #e6e6e6;background:#f0f0f0;
                    &.le {text-align: left !important;
                        > div {justify-content:flex-start !important}
                    }
                    &:first-child {border-left:none;}    
                    &[rowspan] {border-right:1px solid #e6e6e6;}        
                    > div {justify-content: center !important;}
                }
                td {padding:2px 8px;text-align: center; border-left:1px solid #e6e6e6;
                    &:first-child {border-left:none;}
                    &[rowspan] {border-right:1px solid #e6e6e6;}        
                    > div {justify-content: center !important;}
                }
            }
        }
        .le{text-align:left !important; padding-left:8px;
            > div {justify-content: flex-start !important;}
        }
        .ri{text-align:right !important; padding-right:8px;
            > div {justify-content: flex-end !important;}
        }
        .cen {padding:0 !important; text-align: center !important;
            > div {justify-content: center !important;}
        }
        .total{background-color: #f0f2f6;}
        .text_red{color:#fa5252;}      
        .text_blue{color:#1c90fb;}      
    }

    // 포틀릿 로딩
    .refreshLoadingWrap{position: absolute;top: 0;width: 100%;height: 100%;border-radius: 10px;overflow: hidden;
        .refreshLoading{position: absolute; z-index: 100; left:50%; transform: translateX(-50%); top:50%; transform: translateY(-50%); margin-left: -10px;
            > div > div > div > div {border:none; background:none;}
        }
        .dim{position: absolute;top:0;left:0;right:0;bottom:0;background-color: rgba(0, 0, 0, 0.3);z-index:1;} 
    }

}

/***************************** 
    사용자 포틀릿
******************************/
/* 내정보 */
.newPortalPortletGrid .userinfo {position:relative;border-radius: 10px;background:#f9f9f9 url('../imgs/main/img_profile_bg.png') no-repeat;background-size: cover;}
.newPortalPortletGrid .userinfo .user{height:250px;}
.newPortalPortletGrid .userinfo .user .title{position: absolute;top: 23px;left: 25px;right: 0;font-size:14px;font-weight:bold;}
.newPortalPortletGrid .userinfo .user .user_pic{position: absolute;top: 50px;left: 0;right: 0;width: 60px;height: 60px;margin: 0 auto;border-radius: 30px;box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);overflow: hidden;}
.newPortalPortletGrid .userinfo .user .user_pic .img_pic{position: absolute;top: 0;width: 60px;}
.newPortalPortletGrid .userinfo .user .user_pic .img_pic img{width:100%;min-height:60px;}
.newPortalPortletGrid .userinfo .name{position: absolute;top: 126px;left: 0;right: 0;text-align: center;font-size: 14px;color: #000000;font-weight:normal;}
.newPortalPortletGrid .userinfo .Scon_ts{position: absolute;top: 151px;left: 20px;right: 20px;height:20px;text-align: center;font-size:12px;font-weight:normal;line-height:20px;color:#000000;}
.newPortalPortletGrid .userinfo .info_content{position: absolute;top: 175px;left: 0;right: 0;text-align: center;font-size:11px;color:#828282;font-weight:normal;}
.newPortalPortletGrid .userinfo .info_content::before{content: '';display: inline-block;width: 14px;height: 18px;font-size: 0;vertical-align: top;margin: 0 5px 0 0;background: url('../imgs/ico/ic_time_s_normal.png') no-repeat left;}
.newPortalPortletGrid .worktime{position: absolute;left: 0;right: 0;bottom: 0;height: 46px;border-radius: 0 0 10px 10px;background: #f9f9f9;}
.newPortalPortletGrid .worktime #container{padding:16px 0;}
.newPortalPortletGrid .worktime #container ul.btns {width: 100%; height:14px;}
.newPortalPortletGrid .worktime #container ul.btns:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .worktime #container ul.btns li {position: relative;float: left;width: 50%;font-size:13px;font-weight:normal;line-height:14px;text-align: center;border-right: 1px solid rgba(0,0,0,0.1);box-sizing: border-box;color: rgba(0,0,0,0.26);cursor: pointer;}
.newPortalPortletGrid .worktime #container ul.btns li.active {font-size:13px;font-weight:bold;line-height:14px;color:#1c90fb;}
.newPortalPortletGrid .worktime #container ul.btns li:last-child {border-right:none;}
.newPortalPortletGrid .userinfo-hr {position: absolute;top: 0;left: 0;right: 0;bottom: 0;border-radius: 10px;}


// 내정보 - 프로필만 사용
.newPortalPortletGrid .userinfo.profile .user .user_pic{position: absolute;top: 70px;left: 0;right: 0;width: 60px;height: 60px;margin: 0 auto;border-radius: 30px;box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.12);overflow: hidden;}
.newPortalPortletGrid .userinfo.profile .user .user_pic .img_pic{position: absolute;top: 0;width: 60px;}
.newPortalPortletGrid .userinfo.profile .user .user_pic .img_pic img{width:100%;min-height:60px;}
.newPortalPortletGrid .userinfo.profile .name{position: absolute;top: 150px;left: 0;right: 0;text-align: center;font-size: 14px;color: #000000;font-weight:normal;}
.newPortalPortletGrid .userinfo.profile .Scon_ts{position: absolute;top: 175px;left: 0;right: 0;text-align: center;font-size:12px;color:#000000;font-weight:normal;}
.newPortalPortletGrid .userinfo.profile .info_content{display: none !important;}
.newPortalPortletGrid .userinfo.profile .worktime{display: none;}

// 내정보 - 출퇴근만 사용
.newPortalPortletGrid .userinfo.work .user .user_pic{display: none;}
.newPortalPortletGrid .userinfo.work .name{position: absolute;top: 80px;left: 0;right: 0;text-align: center;font-size: 14px;color: #000000;font-weight:normal;}
.newPortalPortletGrid .userinfo.work .Scon_ts{position: absolute;top: 105px;left: 0;right: 0;text-align: center;font-size:12px;color:#000000;font-weight:normal;}
.newPortalPortletGrid .userinfo.work .info_content{position: absolute;top: 130px;left: 0;right: 0;text-align: center;font-size:11px;color:#828282;font-weight:normal;}
.newPortalPortletGrid .userinfo.work .info_content::before{content: '';display: inline-block;width: 14px;height: 18px;font-size: 0;vertical-align: top;margin: 0 5px 0 0;background: url('../imgs/ico/ic_time_s_normal.png') no-repeat left;}

/* 배너(가변형 이미지배너) */
.i_banner{width:100%;height:100%;}
.i_banner * div{width:100%;height:100%;}
.i_banner > div{width:100%;height:100%;}
.i_banner > div > div > div > div{overflow:hidden !important}
.i_banner div img{width:100%;height:100%;cursor: pointer;}

/* 통합알림 + 알파멘션 */
.newPortalPortletGrid .ptl_mention{position: absolute;top: 0;left: 0;right: 0;bottom: 0;background: #f9f9f9;border-radius: 10px;
    .alert_tab{border-radius:10px 10px 0 0;background: rgba(0,0,0,0.08);
        li{float:left;width:50%;height:45px;padding:13px 0;text-align:center;cursor:pointer;border-radius: 10px 10px 0 0;box-sizing:border-box;
            span{position:relative;font-size:13px;font-weight:normal;color:#8e8e8e;
                .txt{display: inline-block;padding: 0;line-height: 20px;}
            }
            .badge{display: inline-block;margin-left: 5px;line-height: 16px;
                .text{display: inline-block;min-width: 8px;padding:0 4px;font-size:11px;color: rgb(255, 255, 255);text-align: center;vertical-align: top;white-space: nowrap;border-radius: 10px;background: #fc5356;}
            }
        }
        li.on{background-color: #f9f9f9;
            span .txt{font-size:13px;font-weight:bold;color:#000000;}
        }
    }
    .mentionCon{position: absolute;top: 46px;left: 0;right: 0;bottom: 0;overflow: hidden;        
        .categoryFn {position: relative;margin: 20px 0 0 0;padding: 0 20px;height: 36px;background-color: #f9f9f9;box-sizing: border-box;z-index: 1;
            .itemDiv{white-space:nowrap;overflow:hidden;scroll-behavior: smooth;
                .item{margin:0 0 0 4px;padding:0 8px;height:24px;border:1px solid #e6e6e6;border-radius:24px;font-size:12px;font-weight:normal;line-height:22px;color:#000000;background-color:#ffffff;box-sizing:border-box;cursor: pointer;
                    &:first-child{margin:0;}
                    &.on{color:#1c90fb;border:1px solid #1c90fb;background-color:#eff7ff;}
                }
            }
            .arrDiv{margin:5px 0 0 10px;
                .prevBtn{margin:0 2px 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_arrow_left_01_s_normal.png') no-repeat center;background-size: cover;cursor: pointer;
                    &:hover{background:url('../imgs/ico/ic_arrow_left_01_s_over.png') no-repeat center;background-size: cover;}
                    &.disabled{background:url('../imgs/ico/ic_arrow_left_01_s_disable.png') no-repeat center;background-size: cover;cursor: not-allowed;}
                }
                .nextBtn{margin:0 0 0 2px;width:14px;height:14px;background:url('../imgs/ico/ic_arrow_right_01_s_normal.png') no-repeat center;background-size: cover;cursor: pointer;
                    &:hover{background:url('../imgs/ico/ic_arrow_right_01_s_over.png') no-repeat center;background-size: cover;}
                    &.disabled{background:url('../imgs/ico/ic_arrow_right_01_s_disable.png') no-repeat center;background-size: cover;cursor: not-allowed;}
                }
            }
        }
        .tabCon{position:relative;animation-fill-mode:backwards; height:100%;
            &._tab02{margin-top: 15px; height:calc(100% - 15px);}
            &::before{content:'';position: absolute;top:29px;left:40px;bottom:0;width:1px; background-color:#e0e0e0;}
            ul{margin:0;
                li{position:relative; color:#aaaaaa;
                    &::before{content:'';position: absolute;top:29px;left:40px;bottom:0;width:1px; background-color:#e0e0e0;}
                    &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}
                    &:last-child{
                        .list_con{padding-bottom:0px;}
                    }
                    .list_con{float:left;margin:-4px 0 0 0;padding:0 0 20px 0;
                        span.title{display:inline-block;position: relative;font-size:12px;font-weight:normal;color:#000000;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;cursor: pointer;}
                        dt{position: relative;font-size:11px;font-weight:normal;line-height:20px;color:#000000;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;
                            .ea{float:left;width:16px;height:20px;background:url("../imgs/main/mention_alert_s.png") no-repeat 0px 4px;margin:0 3px 0 0;}
                            .msg{float:left;width:16px;height:20px;background:url("../imgs/main/mention_alert_s.png") no-repeat -16px 4px;margin:0 3px 0 0;}
                            .promsg{float:left;width:16px;height:20px;background:url("../imgs/main/mention_alert_s.png") no-repeat -32px 4px;margin:0 3px 0 0;}
                        }
                        dd.sub_detail{
                            .name{font-size:11px;font-weight:normal;line-height:20px;color:#8e8e8e;word-break:break-all;
                                &::after{content:"";display:inline-block;width: 1px;height:8px;margin:0 5px;background:rgba(0,0,0, 0.1);}
                            }
                            .date{font-size:11px;font-weight:normal;line-height:20px;color:#8e8e8e;word-break:break-all;}
                        }
                        dd.mention_detail{
                            .name{font-size:11px;font-weight:normal;line-height:20px;color:#8e8e8e;word-break:break-all;
                                &::after{content:"";display:inline-block;width: 1px;height:8px;margin:0 5px;background:rgba(0,0,0, 0.1);}
                            }
                            .date{font-size:11px;font-weight:normal;line-height:20px;color:#8e8e8e;word-break:break-all;}
                        }
                        .mt_marking{font-size:11px;line-height:20px;font-weight:bold;color:#008aff;} // 삭제예정
                        .mentionGreen{font-size:11px;line-height:20px;font-weight:bold;color:#09b481;}  
                        .mentionBlue{font-size:11px;line-height:20px;font-weight:bold;color:#1c90fb;} 
                    }
                    .icon{float:left;width:30px;height:30px;margin:0 9px 0 25px;}
                    .icon.of{background:url('../imgs/main/ic_notice_oneffice_read.png') no-repeat center;}      // 원피스
                    .icon.sc{background:url('../imgs/main/ic_notice_schedule_read.png') no-repeat center;}      // 일정
                    .icon.an{background:url('../imgs/main/ic_notice_announcement_read.png') no-repeat center;}  // 공지
                    .icon.ea{background:url('../imgs/main/ic_notice_approval_read.png') no-repeat center;}      // 결재
                    .icon.ma{background:url('../imgs/main/ic_notice_mail_read.png') no-repeat center;}          // 메일
                    .icon.mg{background:url('../imgs/main/ic_notice_message_read.png') no-repeat center;}       // 쪽지
                    .icon.fx{background:url('../imgs/main/ic_notice_fax_read.png') no-repeat center;}           // 팩스
                    .icon.ks{background:url('../imgs/main/ic_notice_kiss_read.png') no-repeat center;}          // kiss
                    .icon.bd{background:url('../imgs/main/ic_notice_board_read.png') no-repeat center;}         // 게시판
                    .icon.dc{background:url('../imgs/main/ic_notice_doc_read.png') no-repeat center;}           // 문서
                    .icon.wr{background:url('../imgs/main/ic_notice_projectreport_read.png') no-repeat center;} // 업무보고
                    .icon.nt{background:url('../imgs/main/ic_notice_note_read.png') no-repeat center;}          // 노트
                    .icon.fl{background:url('../imgs/main/ic_notice_file_read.png') no-repeat center;}          // 첨부파일
                    .icon.hr{background:url('../imgs/main/ic_notice_hr_read.png') no-repeat center;}            // 인사
                    .icon.ac{background:url('../imgs/main/ic_notice_accounting_read.png') no-repeat center;}    // 회계
                    .icon.rs{background:url('../imgs/main/ic_notice_resource_read.png') no-repeat center;}      // 자원
                    .icon.oc{background:url('../imgs/main/ic_notice_oc_read.png') no-repeat center;}            // 원챔버
                    .icon.st{background:url('../imgs/main/ic_notice_sign_read.png') no-repeat center;}          // 사인
                    .icon.pw{background:url('../imgs/main/ic-notice-pw-reset-read.png') no-repeat center;}      // 비밀번호
                    .icon.crm{background:url('../imgs/main/ic-notice-crm-read.png') no-repeat center;}          // CRM
                    .icon.gsm{background:url('../imgs/main/ic_notice_management_read.png') no-repeat center;}   // 총무관리
                    .icon.vote{background:url('../imgs/main/ic_notice_vote_read.png') no-repeat center;}        // 투표
                    .icon.clinic{background:url('../imgs/main/ic_notice_clinic_read.png') no-repeat center;}    // 헬스케어
                    .icon.taddr{background:url('../imgs/main/ic_notice_total_address_read.png') no-repeat center;}    // 통합주소록
                    .icon.fundingPrevent{background:url('../imgs/main/ic_notice_funding_prevent_read.png') no-repeat center;}    // 자금사고예방
                    .icon.vc{background:url('../imgs/main/ic_notice_video_conference_read.png') no-repeat center;}    // 화상회의
                    .icon.set{background:url('../imgs/main/ic_notice_setting_read.png') no-repeat center;}    // 시스템설정
                    .icon img{width:30px; height:30px; border-radius:50% 50%}
                    
                    .pic_wrap{position:relative;float:left;width:30px;height:30px;margin:0 9px 0 25px;border-radius: 30px;overflow: hidden;
                        .div_img {width:30px;height:30px;background:url('../imgs/main/ic_notice_profile.png') no-repeat top;background-size: 30px;}
                        .div_img img{width:100%;}
                    }
                }
                li.unread .list_con span.title{color:#000000;}
                li.unread .list_con dt{color:#000000;}
                li.unread .icon.of{background:url('../imgs/main/ic_notice_oneffice.png') no-repeat center;}      // 원피스
                li.unread .icon.sc{background:url('../imgs/main/ic_notice_schedule.png') no-repeat center;}      // 일정
                li.unread .icon.an{background:url('../imgs/main/ic_notice_announcement.png') no-repeat center;}  // 공지
                li.unread .icon.ea{background:url('../imgs/main/ic_notice_approval.png') no-repeat center;}      // 결재
                li.unread .icon.ma{background:url('../imgs/main/ic_notice_mail.png') no-repeat center;}          // 메일
                li.unread .icon.mg{background:url('../imgs/main/ic_notice_message.png') no-repeat center;}       // 쪽지
                li.unread .icon.fx{background:url('../imgs/main/ic_notice_fax.png') no-repeat center;}           // 팩스
                li.unread .icon.ks{background:url('../imgs/main/ic_notice_kiss.png') no-repeat center;}          // kiss
                li.unread .icon.bd{background:url('../imgs/main/ic_notice_board.png') no-repeat center;}         // 게시판
                li.unread .icon.dc{background:url('../imgs/main/ic_notice_doc.png') no-repeat center;}           // 문서
                li.unread .icon.wr{background:url('../imgs/main/ic_notice_projectreport.png') no-repeat center;} // 업무보고
                li.unread .icon.nt{background:url('../imgs/main/ic_notice_note.png') no-repeat center;}          // 노트
                li.unread .icon.fl{background:url('../imgs/main/ic_notice_file.png') no-repeat center;}          // 첨부파일
                li.unread .icon.hr{background:url('../imgs/main/ic_notice_hr.png') no-repeat center;}            // 인사
                li.unread .icon.ac{background:url('../imgs/main/ic_notice_accounting.png') no-repeat center;}    // 회계
                li.unread .icon.rs{background:url('../imgs/main/ic_notice_resource.png') no-repeat center;}      // 자원
                li.unread .icon.oc{background:url('../imgs/main/ic_notice_oc.png') no-repeat center;}            // 원챔버
                li.unread .icon.st{background:url('../imgs/main/ic_notice_sign.png') no-repeat center;}          // 사인
                li.unread .icon.pw{background:url('../imgs/main/ic-notice-pw-reset.png') no-repeat center;}      // 비밀번호
                li.unread .icon.crm{background:url('../imgs/main/ic-notice-crm.png') no-repeat center;}          // CRM
                li.unread .icon.gsm{background:url('../imgs/main/ic_notice_management.png') no-repeat center;}   // 총무관리
                li.unread .icon.vote{background:url('../imgs/main/ic_notice_vote.png') no-repeat center;}        // 투표
                li.unread .icon.clinic{background:url('../imgs/main/ic_notice_clinic.png') no-repeat center;}    // 헬스케어
                li.unread .icon.taddr{background:url('../imgs/main/ic_notice_total_address.png') no-repeat center;}    // 통합주소록
                li.unread .icon.fundingPrevent{background:url('../imgs/main/ic_notice_funding_prevent.png') no-repeat center;}    // 자금사고예방
                li.unread .icon.vc{background:url('../imgs/main/ic_notice_video_conference.png') no-repeat center;}    // 화상회의
                li.unread .icon.set{background:url('../imgs/main/ic_notice_setting.png') no-repeat center;}    // 시스템설정
            }
            .dayline{height: 28px;padding: 0px 30px 10px 30px;font-size:12px;font-weight:bold;line-height:28px;color: #000000;background-color: #f9f9f9;
                &::before{background-color:#f9f9f9 !important;}
            }
            .dayline span{display: inline-block;width: 37px;height: 20px;line-height: 18px;margin: 4px 0 0 8px;text-align: center;border-radius: 10px;box-sizing: border-box;}
            .dayline.today{height: 28px;padding: 0px 30px 10px 30px;font-size:12px;font-weight:bold;line-height:28px;color: #000000;background-color: #f9f9f9;}
            .dayline.today span{font-size: 11px;color: #319df6;border: 1px solid #319df6;background: #f9f9f9;}

            .no_data{
                .text{width: 100%; height: 100%; background-color: #f9f9f9 !important; z-index: 10; display: flex; align-items: center; justify-content: center; margin-top: -80px;}
            }
        }
    }
}

/* 일정 , 자원캘린더 */
.newPortalPortletGrid .ptl_calendar{overflow:hidden;}
.newPortalPortletGrid .ptl_calendar:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_calendar .calendar_div {padding: 0px 10px 0 10px;}
.newPortalPortletGrid .ptl_calendar .calendar_div.resource {padding: 5px 10px 0 10px;}
.newPortalPortletGrid .ptl_calendar .calendar_div h3 {border-bottom: 1px solid rgba(0,0,0,0.1);padding-bottom: 6px;font-size:12px;font-weight:bold;color: #222222;}
.newPortalPortletGrid .row3 .ptl_calendar .calendar_wrap{padding:0 0 15px 0;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap{float:left;width:250px;padding:15px 0 0px 0; height:200px; overflow: hidden;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap button{width:20px !important;height:20px !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div{padding:0 10px !important;background: #f9f9f9 !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(1) button:nth-child(1){left:25px !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(1) button:nth-child(2){left:50px !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(1) button:nth-child(5){right:25px !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(1) button:nth-child(4){right:50px !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(1) strong{color:#565656 !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(2) thead th{font-size:11px !important;font-weight:bold !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(2) tbody th button{font-size:11px !important;font-weight:bold !important;}
.newPortalPortletGrid .ptl_calendar .calendar_wrap > div > div > div:nth-child(2) tbody td button{font-size:11px !important;font-weight:bold !important;}
.newPortalPortletGrid .ptl_calendar .calendar_select{margin:0 0 10px 0;padding:10px 8px 0 8px;}
.newPortalPortletGrid .ptl_calendar .calendar_div .calendar_list li {position: relative;margin: 10px 0 0 0;}
.newPortalPortletGrid .ptl_calendar .calendar_div .calendar_list li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_calendar .calendar_div .calendar_list li .time {display: block;font-size:11px;font-weight:normal;line-height:17px;color: #8e8e8e;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.newPortalPortletGrid .ptl_calendar .calendar_div .calendar_list li .sign{position: absolute;top: 5px;right: 0;width: 38px;height: 20px;margin: 0;padding: 0;font-size:11px;font-weight:normal;line-height:20px;color: #ffffff;text-align: center;border-radius: 45px;}
.newPortalPortletGrid .ptl_calendar .calendar_div.st1 .calendar_list li .txt {display: inline-block;font-size:12px;font-weight:normal;line-height:17px;margin:0 45px 0 0;box-sizing:border-box;color: #222222;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.newPortalPortletGrid .ptl_calendar .calendar_div .calendar_list li .txt {display: inline-block;font-size:12px;font-weight:normal;line-height:17px;margin:0;box-sizing:border-box;color: #222222;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.newPortalPortletGrid .ptl_calendar .calendar_div .calendar_list li.special_day .txt {color:#ee6362;}
.newPortalPortletGrid .ptl_calendar .calendar_list li .colr-1{background:#20c997;}
.newPortalPortletGrid .ptl_calendar .calendar_list li .colr-2{background:#46a3f0;}
.newPortalPortletGrid .ptl_calendar .calendar_list li .colr-3{background:#c28ed7;}
.newPortalPortletGrid .ptl_calendar .calendar_list li .colr-4{background:#789af2;}

// 캘린더선택
.calendarSelect{border:1px solid #e6e6e6;background: #ffffff;
    .acodiList{position: relative;line-height: 21px;padding:10px 70px 10px 20px;border-top:1px solid #e6e6e6;background-color:#edf4fb;
        &:first-child{border-top:none;}
        .arr{display:block;position: absolute;top:10px;right:10px;width:20px;height:20px;cursor: pointer;
            &.up{background:url("../imgs/ico/ic_arrow_up_01_s_normal.png") no-repeat center;}
            &.down{background:url("../imgs/ico/ic_arrow_down_01_s_normal.png") no-repeat center;}
        }
    }
    ul{padding:5px 0;border-top:1px solid #e6e6e6;
        li{line-height: 21px;padding:2px 0 2px 40px;}
    }
}

/* 편지함 */
.newPortalPortletGrid .ptl_mail {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_mail ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_mail ul li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_mail ul li.unread .title span.link{font-weight:bold;}
.newPortalPortletGrid .ptl_mail ul dl{display: block;width:100%;margin:0 0 9px 0;padding:0;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.newPortalPortletGrid .ptl_mail ul dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_mail ul dl, 
.newPortalPortletGrid .ptl_mail ul dt, 
.newPortalPortletGrid .ptl_mail ul dd{float:left;position: relative;}
.newPortalPortletGrid .ptl_mail ul li.unread dt.title{background:url('../imgs/ico/ic_mail_m_over.png') no-repeat left;}
.newPortalPortletGrid .ptl_mail ul dt.title{font-size:12px;font-weight:normal;padding:0 0 0 24px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;background:url('../imgs/ico/ic_mail_read_m_normal.png') no-repeat left top -1px;}
.newPortalPortletGrid .ptl_mail ul dd.from_info{display:block;overflow:hidden;width:100%;text-overflow:ellipsis;white-space:nowrap;clear:both;font-size:11px;font-weight:normal;color:#8e8e8e;} 
.newPortalPortletGrid .ptl_mail ul dd.date{position: absolute;top:0;right: 0;width: 50px;text-align:right;font-size:11px;font-weight:normal;color:#8e8e8e;margin:0;}

/* 게시판 */
.newPortalPortletGrid .ptl_board {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_board ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_board ul dl{position: relative;width:100%;padding:7px 0;font-size:13px;font-weight:normal;line-height:18px;}
.newPortalPortletGrid .ptl_board ul dt .number{margin:0 6px 0 0;padding:0 7px;min-width:24px;height:18px;font-size:11px;font-weight:normal;line-height:18px;color:#494949;text-align:center;border:1px solid #656565;border-radius:18px;}
.newPortalPortletGrid .ptl_board ul dt .title{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.newPortalPortletGrid .ptl_board ul dd{position:relative;padding:0 0 0 15px;max-width:65px;font-size:11px;font-weight:normal;line-height:18px;color:#8e8e8e;letter-spacing: -0.2px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.newPortalPortletGrid .ptl_board ul dd::before{content:'';position:absolute;top:5px;left:7px;width:1px;height:8px;background-color:rgba(0, 0, 0, 0.1);}
.newPortalPortletGrid .ptl_board ul dt + dd::before{display:none;}
.newPortalPortletGrid .ptl_board ul dd.name{width:60px;}
.newPortalPortletGrid .ptl_board ul dd.name::before{display:none;}
.newPortalPortletGrid .ptl_board ul dd.company{width:60px;}
.newPortalPortletGrid .ptl_board ul dd.workplace{width:60px;}
.newPortalPortletGrid .ptl_board ul dd.department{width:60px;}
.newPortalPortletGrid .ptl_board ul dd.date{width:auto;}

.col3, .col4, .col5{
    .ptl_board ul dd.name{display:none;}
    .ptl_board ul dd.company{display:none;}
    .ptl_board ul dd.workplace{display:none;}
    .ptl_board ul dd.department{display:none;}
    .ptl_board ul dd.date::before{display:none;}
}
.col6, .col7, .col8, .col9{
    .ptl_board ul dd.company{display:none;}
    .ptl_board ul dd.workplace{display:none;}
}

/* 노트 */
.newPortalPortletGrid .ptl_note {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_note ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_note ul li{background:url('../imgs/ico/ic_note_m_normal.png') no-repeat left;}
.newPortalPortletGrid .ptl_note ul li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_note ul li.unread .title span.link{font-weight:bold;}
.newPortalPortletGrid .ptl_note ul dl{position: relative;display: block;width:100%;overflow: hidden;font-size:13px;font-weight:normal;line-height:34px;text-overflow: ellipsis;white-space: nowrap;}
.newPortalPortletGrid .ptl_note ul dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_note ul dl, 
.newPortalPortletGrid .ptl_note ul dt, 
.newPortalPortletGrid .ptl_note ul dd{float:left;}
.newPortalPortletGrid .ptl_note ul dt.title{padding:0 0 0 24px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-size:13px;font-weight:normal;line-height:34px;}
.newPortalPortletGrid .ptl_note ul dd.date{position: absolute;top:0;right: 0;width: 50px;text-align:right;font-size:11px;font-weight:normal;line-height:34px;color:#8e8e8e;margin:0;}
.newPortalPortletGrid .ptl_note ul dd .badge{display: inline-block;margin-left: 5px;line-height: 16px;
    .text{display: inline-block;min-width: 8px;padding:0 4px;font-size:11px;color: rgb(255, 255, 255);text-align: center;vertical-align: top;white-space: nowrap;border-radius: 10px;background: #fc5356;}
}

/* 설문조사 */
.newPortalPortletGrid .ptl_survey{width:100%;height:100%;background: #f9f9f9;
    .btn_cen {margin-top: 10px; text-align: center;}
}	
.newPortalPortletGrid .ptl_survey .question{padding:10px 25px 0 25px;}
.newPortalPortletGrid .ptl_survey .question .surveyRadio {width:100%;
    div > span{line-height:normal !important;margin-bottom: 0px !important;} 
    label{display:block !important;font-size:13px !important;line-height:34px !important;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
}

// 노트, 설문 없을때
.main_nocon {width:100%; text-align:center; color:#8d8d8d; height:100%; vertical-align:middle; min-height:100px;}
.main_nocon table{width:100%;height:100%;text-align:center; color:#8d8d8d;background-color: #f9f9f9;}
.main_nocon table td {vertical-align:middle;height:100%;}
.main_nocon table td span.txt {font-size: 12px;margin:-20px 0 10px 0;padding: 50px 0 0 0;display:inline-block;background:#f9f9f9 url('../imgs/empty/img_empty_data_s.png') no-repeat top center;}

/* 문서 */
.newPortalPortletGrid .ptl_doc {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_doc ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_doc ul li{background:url('../imgs/ico/ic_docu_m_normal.png') no-repeat left;}
.newPortalPortletGrid .ptl_doc ul li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_doc ul li.unread .title span.link{font-weight:bold;}
.newPortalPortletGrid .ptl_doc ul dl{position: relative;display: block;width:100%;font-size:13px;font-weight:normal;line-height:34px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.newPortalPortletGrid .ptl_doc ul dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_doc ul dl, 
.newPortalPortletGrid .ptl_doc ul dt, 
.newPortalPortletGrid .ptl_doc ul dd{float:left;}
.newPortalPortletGrid .ptl_doc ul dt.title{padding:0 0 0 24px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.newPortalPortletGrid .ptl_doc ul dd.date{position: absolute;top:0;right: 0;width: 50px;text-align:right;font-size:11px;font-weight:normal;line-height:34px;color:#8e8e8e;margin:0;}
.newPortalPortletGrid .ptl_doc ul dd .badge{display: inline-block;margin-left: 5px;line-height: 16px;
    .text{display: inline-block;min-width: 8px;padding:0 4px;font-size:11px;color: rgb(255, 255, 255);text-align: center;vertical-align: top;white-space: nowrap;border-radius: 10px;background: #fc5356;}
}

/* 결재양식 */
.newPortalPortletGrid .ptl_list{position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;border-radius: 0 0 10px 10px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_list ul{height:100%;padding:0px 0px 0px 25px;}
.newPortalPortletGrid .ptl_list ul li{display: block;font-size:13px;font-weight:normal;line-height:34px;margin:0 25px 0 0;padding: 0 0 0 24px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;background:url('../imgs/ico/ic_survey_m_normal.png') no-repeat left;}

/* 결재함 */
.newPortalPortletGrid .ptl_approval {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_approval ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_approval ul li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_approval ul dl{position: relative;display: block;width:100%;font-size:13px;font-weight:normal;line-height:34px;padding:0 0 0 44px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing:border-box;}
.newPortalPortletGrid .ptl_approval ul dl.nosign{padding:0;}
.newPortalPortletGrid .ptl_approval ul dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_approval ul dl, 
.newPortalPortletGrid .ptl_approval ul dt, 
.newPortalPortletGrid .ptl_approval ul dd{float:left;}
.newPortalPortletGrid .ptl_approval ul dt.title{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.newPortalPortletGrid .ptl_approval ul li.new dt.title span.link{font-weight:bold;}
.newPortalPortletGrid .ptl_approval ul dd .badge{display: inline-block;margin-left: 5px;line-height: 16px;
    .text{display: inline-block;min-width: 8px;padding:0 4px;font-size:11px;color: rgb(255, 255, 255);text-align: center;vertical-align: top;white-space: nowrap;border-radius: 10px;background: #fc5356;}
}
.newPortalPortletGrid .ptl_approval ul dd.sign{position: absolute;top: 8px;left: 0;width: 38px;height: 20px;margin: 0;padding: 0;font-size:11px;font-weight:normal;line-height:20px;color: #ffffff;text-align: center;border-radius: 45px;}
.newPortalPortletGrid .ptl_approval ul dd.name{position: absolute;top: 0;right: 50px;text-align: right;font-size:11px;font-weight:normal;line-height:34px;color: #8e8e8e;margin: 0;padding: 0 8px 0 0;}
.newPortalPortletGrid .ptl_approval ul dd.name:after{content:'';position: absolute;top:13px;right:0;width: 1px;height:8px;background:rgba(0,0,0,0.1);}
.newPortalPortletGrid .col3 .ptl_approval ul dd.name{display: none;}
.newPortalPortletGrid .ptl_approval ul dd.date{position: absolute;top:0;right: 0;width: 50px;text-align:right;font-size:11px;font-weight:normal;line-height:34px;color:#8e8e8e;margin:0;}
.newPortalPortletGrid .ptl_approval .colr-1{background:#f0c325;} // 상신(비영리) // 미결, 후결(영리)
.newPortalPortletGrid .ptl_approval .colr-2{background:#46A3F0;} // 진행,참조,수신,시행,회람(비영리) // 수신,시행(영리)
.newPortalPortletGrid .ptl_approval .colr-3{background:#2DBCB5;} // 미결(비영리)
.newPortalPortletGrid .ptl_approval .colr-4{background:#20C997;} // 전결,종결,기결,예결,후결 (비영리) // 기결(영리)
.newPortalPortletGrid .ptl_approval .colr-5{background:#F8A457;} // 보류 (비영리)
.newPortalPortletGrid .ptl_approval .colr-6{background:#FF8787;} // 반려 (비영리, 영리)
.newPortalPortletGrid .ptl_approval .colr-7{color: #9DA3AA !important; border: 1px solid #9DA3AA; background:#FFFFFF;} // 상신 (영리)
.newPortalPortletGrid .ptl_approval .colr-8{background:#39B0D2;} // 예결 (영리)
.newPortalPortletGrid .ptl_approval .colr-9{background:#9DA3AA;} // 참조 (영리)

/* 결재현황 */
.newPortalPortletGrid .ptl_status {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_status ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_status ul li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_status ul dl{position: relative;display: block;width:100%;font-size:13px;font-weight:normal;line-height:34px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.newPortalPortletGrid .ptl_status ul dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_status ul dt{float:left;padding:0 0 0 8px;}
.newPortalPortletGrid .ptl_status ul dt:before{content: '';position: absolute;top: 16px;left: 0;width: 2px;height: 2px;background-color: #656565;}
.newPortalPortletGrid .ptl_status ul dd{float:right;text-align:right;}
.newPortalPortletGrid .ptl_status ul dd .red{color:#fa5252;}
.newPortalPortletGrid .ptl_status ul dd .blue{color:#1c90fb;}

/* 메일현황 */
.newPortalPortletGrid .mdst{width:100%;height:100%;background:#f9f9f9;display: table;}
.newPortalPortletGrid .mdst ul {padding:0 25px;display: table-cell;vertical-align: middle;}
.newPortalPortletGrid .mdst ul li{overflow:hidden;margin:0;font-size:13px;font-weight:normal;}
.newPortalPortletGrid .mdst ul li:first-child{margin:0 0 16px 0;}
.newPortalPortletGrid .mdst ul li dl{position: relative;display: block;width:100%;}
.newPortalPortletGrid .mdst ul li dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .mdst ul li dt{float:left;padding:0 0 0 8px;}
.newPortalPortletGrid .mdst ul li dt:before{content: '';position: absolute;top: 8px;left: 0;width: 2px;height: 2px;background-color: #656565;}
.newPortalPortletGrid .mdst ul li dd{float:right;}
.newPortalPortletGrid .mdst ul li dd.resourceText{width:100%;margin:3px 0 0 0;text-align: right;font-size:11px;font-weight:normal;color:#8e8e8e;}
.newPortalPortletGrid .mdst ul li dd.resourceText span{color:#000000;}
.newPortalPortletGrid .mdst ul li dd .red{color:#fa5252;}
.newPortalPortletGrid .mdst ul li dd .blue{color:#1c90fb;}

/* 날씨 */
.newPortalPortletGrid .ptl_weather {display: table;overflow: hidden;width: 100%;height: 100%;border-radius: 10px;background: #f9f9f9;}
.newPortalPortletGrid .ptl_weather #weather {display: table-cell;vertical-align: middle;padding: 0 25px 0 15px;}
.newPortalPortletGrid .ptl_weather .ptl_weather_ico {float:left;width:54px;}
.newPortalPortletGrid .ptl_weather .ptl_weather_ico img{width:100%;}
.newPortalPortletGrid .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 54px);margin: 5px 0 0 0;}
.newPortalPortletGrid .ptl_weather .ptl_weather_info .weather_location {float: left;width: 60%;overflow: hidden;padding: 0;}
.newPortalPortletGrid .ptl_weather .ptl_weather_info .weather_location span {display:block;}
.newPortalPortletGrid .ptl_weather .ptl_weather_info .weather_location .loc_txt01{font-size:11px;font-weight:normal;color: #828282;letter-spacing: -1px;margin: 0 0 3px 0;padding: 0;}
.newPortalPortletGrid .ptl_weather .ptl_weather_info .weather_location .loc_txt02 {font-size:14px;font-weight:normal;color: #222222;padding: 0;}
.newPortalPortletGrid .ptl_weather .ptl_weather_info .weather_celsius {float: right;width: 40%;font-size: 0;text-align: right;}
.newPortalPortletGrid .ptl_weather .ptl_weather_info .weather_celsius span {display: block;font-size:24px;font-weight:bold;line-height:30px;color: #222222;padding: 0 12px 0 0;box-sizing: border-box;background:url('../imgs/main/wearther_source/np_weather_celsius_bg.png') no-repeat bottom 6px right;background-size: 10px;}

/* 업무관리 */
.newPortalPortletGrid .ptl_wklist {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_wklist ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_wklist ul li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_wklist ul dl{position: relative;display: block;width: 100%;font-size:13px;font-weight:normal;line-height:34px;padding: 0 0 0 44px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing: border-box;}
.newPortalPortletGrid .ptl_wklist ul dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_wklist ul dl, 
.newPortalPortletGrid .ptl_wklist ul dt, 
.newPortalPortletGrid .ptl_wklist ul dd{float:left;}
.newPortalPortletGrid .ptl_wklist ul dt.title{display:flex;flex-direction:row;line-height: 18px;margin: 8px 0;}
.newPortalPortletGrid .ptl_wklist ul dt.title span{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.newPortalPortletGrid .ptl_wklist ul dt.title span:last-child{flex:1 1 auto;}
.newPortalPortletGrid .ptl_wklist ul dt.title span.gt{width:30px;text-align:center;}
.newPortalPortletGrid .ptl_wklist ul dd.sign{position: absolute;top: 8px;left: 0;width: 38px;height: 20px;margin: 0;padding: 0;font-size:11px;font-weight:normal;line-height:20px;color: #ffffff;text-align: center;border-radius: 45px;}
.newPortalPortletGrid .ptl_wklist ul dd.date{position: absolute;top: 0;right: 60px;text-align: right;font-size:11px;font-weight:normal;line-height:34px;color: #8e8e8e;margin: 0;padding: 0 8px 0 0;}
.newPortalPortletGrid .ptl_wklist ul dd.date:after{content:'';position: absolute;top:13px;right:0;width: 1px;height:8px;background:rgba(0,0,0,0.1);}
.newPortalPortletGrid .ptl_wklist ul dd.name{position: absolute;top:0;right: 0;width: 60px;text-align:right;font-size:11px;font-weight:normal;line-height:34px;color:#8e8e8e;margin:0;}
.newPortalPortletGrid .ptl_wklist ul dd.name span{color:#1c90fb;margin-right:5px;}
.newPortalPortletGrid .ptl_wklist .colr-1{background:#f8b639;}
.newPortalPortletGrid .ptl_wklist .colr-2{background:#46a3f0;}
.newPortalPortletGrid .ptl_wklist .colr-3{background:#ff8787;}
.newPortalPortletGrid .ptl_wklist .colr-4{background:#9da3aa;}

/* 퀵링크 */
.newPortalPortletGrid .i_quick{position:relative;width:100%;height:100%;}
.newPortalPortletGrid .i_quick .quick-container{font-size: 0;margin:10px;background: #f9f9f9;overflow: hidden;text-align: center;white-space: nowrap;scroll-behavior: smooth;}
.newPortalPortletGrid .i_quick .quick-container .quick-item{display: inline-block;position: relative;width: 165px;height: 140px;border: solid 1px rgba(0, 0, 0, 0.08);background-color: #f0f5f9;cursor: pointer;box-sizing: border-box;overflow: hidden;margin:0 0 0 10px;}
.newPortalPortletGrid .i_quick .quick-container .quick-item:first-child{margin:0;}
.newPortalPortletGrid .i_quick .quick-container .quick-item img{width:100%;height:100%;}
.newPortalPortletGrid .i_quick .arrLeft{display:none;position: absolute;top: 50%;left: 5px;width: 22px;height: 22px;margin-top: -22px;border-radius: 22px;background: rgba(0,0,0,0.2) url('../imgs/main/ico_quickArrow_normal_prev.png') no-repeat left 7px center;cursor: pointer;}
.newPortalPortletGrid .i_quick:hover .arrLeft{display:block;}
.newPortalPortletGrid .i_quick .arrLeft:hover{background: rgba(0,0,0,0.3) url('../imgs/main/ico_quickArrow_normal_prev.png') no-repeat left 7px center;}
.newPortalPortletGrid .i_quick .arrRight{display:none;position: absolute;top: 50%;right: 5px;width: 22px;height: 22px;margin-top: -22px;border-radius: 22px;background: rgba(0,0,0,0.2) url('../imgs/main/ico_quickArrow_normal_next.png') no-repeat right 7px center;cursor: pointer;}
.newPortalPortletGrid .i_quick:hover .arrRight{display:block;}
.newPortalPortletGrid .i_quick .arrRight:hover{background: rgba(0,0,0,0.3) url('../imgs/main/ico_quickArrow_normal_next.png') no-repeat right 7px center;}

/* 미결전표현황 */
.newPortalPortletGrid .ptl_slipsStatus {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_slipsStatus .div_slipsStatus{padding:0px 25px;}
.newPortalPortletGrid .ptl_slipsStatus .div_slipsStatus .desText{color:#8e8e8e;}
.newPortalPortletGrid .ptl_slipsStatus ul{height:calc(100% - 59px);padding:0px 25px;}
.newPortalPortletGrid .ptl_slipsStatus ul li:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_slipsStatus ul dl{position: relative;display: block;width:100%;font-size:12px;font-weight:normal;line-height:34px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.newPortalPortletGrid .ptl_slipsStatus ul dl:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.newPortalPortletGrid .ptl_slipsStatus ul dt{float:left;padding:0 0 0 7px;}
.newPortalPortletGrid .ptl_slipsStatus ul dt:before{content: '';position: absolute;top: 16px;left: 0;width: 2px;height: 2px;background-color: #000000;}
.newPortalPortletGrid .ptl_slipsStatus ul dd{float:right;text-align:right;}
.newPortalPortletGrid .ptl_slipsStatus ul dd .red{color:#fa5252;}
.newPortalPortletGrid .ptl_slipsStatus ul dd .blue{color:#1c90fb;}

/* 근무시간동의 */
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree {padding:0 25px;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .pubDatePickerStyle > div {width: 198px !important;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .topUnitBox{height: 67px;margin: 10px 0 0 0;padding: 16px 15px 0 15px;border-radius: 4px 4px 0 0;background-color: #f0f2f6;box-sizing: border-box;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .topUnitBox > div:first-child{position:relative;margin:0 12px 0 0;padding:0 12px 0 0;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .topUnitBox > div:first-child::after{content:'';position: absolute;top:4px;right:0;width:1px;height:26px;background-color: rgba(0, 0, 0, 0.1);}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .topUnitBox .ico01{font-size:11px;font-weight:normal;line-height:17px;color:#222222;text-align:left;padding:0 0 0 16px;background: url('../imgs/ico/ic_room_enter_s_normal.png') no-repeat left;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .topUnitBox .ico02{font-size:11px;font-weight:normal;line-height:17px;color:#222222;text-align:left;padding:0 0 0 16px;background: url('../imgs/ico/ic_room_leave_s_normal.png') no-repeat left;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .topUnitBox .timeText{font-size:11px;font-weight:bold;line-height:17px;color:#000000;text-align:left;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .botUnitBox{height:38px;padding:0 15px;border-radius:0 0 4px 4px;background-color: #ffffff;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .botUnitBox .ico{font-size:11px;font-weight:normal;line-height:38px;color:#222222;text-align:left;padding:0 0 0 16px;background: url('../imgs/ico/ic_time_s_normal.png') no-repeat left;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .botUnitBox .timeText{font-size:11px;font-weight:normal;line-height:38px;color:#000000;text-align:right;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx{margin:12px 0 0 0;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .title{position: relative;font-size:12px;font-weight:normal;line-height:20px;color:#222222;text-align:left;padding:0 0 0 8px;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .title::before{content: '';position: absolute;top: 10px;left: 0;width: 2px;height: 2px;background-color: #000000;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .text{font-size:12px;font-weight:normal;line-height:20px;padding:0 6px 0 0;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .text.text_blue{color:#1c90fb;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .text.text_red{color:#fa5252;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .state{display:inline-block;width: 38px;height: 20px;margin: 0;padding: 0;font-size:11px;font-weight:normal;line-height:20px;color: #ffffff;text-align: center;border-radius: 45px;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .state.colr-1{background-color: #20c997;}
.newPortalPortletGrid .ptl_PersonalWorkingTimeAgree .resultBpx .state.colr-2{background-color: #ff8787;}

/* 근무시간승인 */
.newPortalPortletGrid .ptl_WorkingTimeApproval {padding:0 25px;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .pubDatePickerStyle > div {width: 198px !important;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn01{height:42px;padding:0 12px 0 34px;border:1px solid #e6e6e6;border-radius: 4px;background: #f0f2f6 url('../imgs/uc/ic_unitBtn01.png') no-repeat left 12px center;cursor: pointer;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn01:hover{border:1px solid #1c90fb;transition: 0.5s;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn01 .title{font-size:12px;font-weight:normal;line-height:42px;color:#222222;text-align:left;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn01 .cnt{font-size:12px;font-weight:normal;line-height:42px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn01 .cnt b{font-weight:bold;color:#7c8db1;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn02{height:45px;margin:0 2px 0 0;padding:0 12px 0 34px;border:1px solid #e6e6e6;border-radius: 4px;background: #ffffff url('../imgs/uc/ic_unitBtn02.png') no-repeat left 12px center;cursor: pointer;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn02:hover{border:1px solid #1c90fb;transition: 0.5s;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn02 .title{margin:6px 0 0 0;font-size:12px;font-weight:normal;line-height:17px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn02 .cnt{font-size:12px;font-weight:normal;line-height:15px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn02 .cnt b{font-weight:bold;color:#ff8787;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn03{height:45px;margin:0 0 0 2px;padding:0 12px 0 34px;border:1px solid #e6e6e6;border-radius: 4px;background: #ffffff url('../imgs/uc/ic_unitBtn03.png') no-repeat left 12px center;cursor: pointer;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn03:hover{border:1px solid #1c90fb;transition: 0.5s;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn03 .title{margin:6px 0 0 0;font-size:12px;font-weight:normal;line-height:17px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn03 .cnt{font-size:12px;font-weight:normal;line-height:15px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn03 .cnt b{font-weight:bold;color:#f8a457;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn04{height:45px;margin:0 2px 0 0;padding:0 12px 0 34px;border:1px solid #e6e6e6;border-radius: 4px;background: #ffffff url('../imgs/uc/ic_unitBtn04.png') no-repeat left 12px center;cursor: pointer;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn04:hover{border:1px solid #1c90fb;transition: 0.5s;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn04 .title{margin:6px 0 0 0;font-size:12px;font-weight:normal;line-height:17px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn04 .cnt{font-size:12px;font-weight:normal;line-height:15px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn04 .cnt b{font-weight:bold;color:#46a3f0;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn05{height:45px;margin:0 0 0 2px;padding:0 12px 0 34px;border:1px solid #e6e6e6;border-radius: 4px;background: #ffffff url('../imgs/uc/ic_unitBtn05.png') no-repeat left 12px center;cursor: pointer;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn05:hover{border:1px solid #1c90fb;transition: 0.5s;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn05 .title{margin:6px 0 0 0;font-size:12px;font-weight:normal;line-height:17px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn05 .cnt{font-size:12px;font-weight:normal;line-height:15px;color:#222222;text-align:right;}
.newPortalPortletGrid .ptl_WorkingTimeApproval .unitBtn05 .cnt b{font-weight:bold;color:#20c997;}

/* 업무보고현황 */
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap{margin:8px 0 0 0;padding:0 25px;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxL{width:126px;height:126px;margin:0 10px 0 0;border:1px solid #e6e6e6;border-radius:4px;background:#f5faff url('../imgs/ico/ic_tab_report_send_blue.png') no-repeat top 20px center;box-sizing: border-box;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxL .cnt{margin:58px 0 0 0;font-size:18px;font-weight:bold;line-height:27px;color:#000000;text-align:center;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxL .text{font-size:11px;font-weight:normal;line-height:17px;color:#4a4a4a;text-align:center;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR{height:126px;border:1px solid #e6e6e6;border-radius:4px;background:#ffffff;box-sizing: border-box;overflow:hidden;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .receiveTotal{width:124px;border-radius:4px 0 4px 0;background:#f5faff url('../imgs/ico/ic_tab_report_receive_blue.png') no-repeat top 20px center;box-sizing: border-box;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .receiveTotal .receiveCnt{margin:58px 0 0 0;font-size:11px;font-weight:normal;line-height:27px;color:#8e8e8e;text-align:center;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .receiveTotal .receiveCnt .cnt{font-size:18px;font-weight:bold;line-height:27px;color:#000000;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .receiveTotal .text{font-size:11px;font-weight:normal;line-height:17px;color:#4a4a4a;text-align:center;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .reportCnt{padding:15px;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .reportCnt .cntUnit{position: relative;height:24px;padding:0 0 0 8px;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .reportCnt .cntUnit::before{content: '';position: absolute;top: 12px;left: 0;width: 2px;height: 2px;background-color: #000000;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .reportCnt .cntUnit .tit{font-size:12px;font-weight:normal;line-height:24px;color:#222222;text-align: left;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .reportCnt .cntUnit .receiveCnt{margin:0 0 0 0;font-size:12px;font-weight:normal;line-height:24px;color:#8e8e8e;text-align:right;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .reportCnt .cntUnit .receiveCnt .cnt{font-size:12px;font-weight:bold;line-height:24px;color:#000000;}
.newPortalPortletGrid .ptl_WorkReportStatus .unitBoxWrap .unitBoxR .reportCnt .cntUnit .receiveCnt .cnt.text_blue{color:#1c90fb;}
.newPortalPortletGrid .ptl_WorkReportStatus .btnBox{height: 46px;border-radius: 0 0 10px 10px;background: #ffffff;}
.newPortalPortletGrid .ptl_WorkReportStatus .btnBox li{position: relative;text-align:center;}
.newPortalPortletGrid .ptl_WorkReportStatus .btnBox li:first-child:before{display:none;}
.newPortalPortletGrid .ptl_WorkReportStatus .btnBox li::before{content:'';position: absolute;top:16px;left:0;width:1px;height:14px;background-color: rgba(0, 0, 0, 0.1);}
.newPortalPortletGrid .ptl_WorkReportStatus .btnBox li span{display:inline-block;padding:0 0 0 24px;font-size:15px;font-weight:normal;line-height:46px;color:#000000;text-align:center;cursor: pointer;}
.newPortalPortletGrid .ptl_WorkReportStatus .btnBox li .btnUnit01{background: url('../imgs/ico/ic_write_m_over.png') no-repeat left 0px center;}
.newPortalPortletGrid .ptl_WorkReportStatus .btnBox li .btnUnit02{background: url('../imgs/ico/ic_oneffice_m_over.png') no-repeat left 0px center;}

/* 받은보고서 */
.newPortalPortletGrid .ptl_ReportsReceived{margin:8px 0 0 0;padding:0 25px;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox{width:146px;height:calc(100% - 20px);margin:0 20px 20px 0;border:1px solid #e6e6e6;border-radius:4px;background:#ffffff;box-sizing: border-box;overflow:hidden;}

.newPortalPortletGrid .ptl_ReportsReceived .leftBox .receiveTotal{padding:14px 14px 0 14px;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .receiveTotal .text{font-size:11px;font-weight:normal;line-height:17px;color:#4a4a4a;text-align:left;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .receiveTotal .receiveCnt{font-size:11px;font-weight:normal;line-height:27px;color:#8e8e8e;text-align:left;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .receiveTotal .receiveCnt .cnt{font-size:18px;font-weight:bold;line-height:27px;color:#1c90fb;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .reportCnt{padding:4px 14px 9px 14px;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .reportCnt .cntUnit{position: relative;height:24px;padding:0 0 0 8px;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .reportCnt .cntUnit::before{content: '';position: absolute;top: 12px;left: 0;width: 2px;height: 2px;background-color: #000000;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .reportCnt .cntUnit .tit{font-size:12px;font-weight:normal;line-height:24px;color:#222222;text-align: left;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .reportCnt .cntUnit .receiveCnt{margin:0 0 0 0;font-size:12px;font-weight:normal;line-height:24px;color:#8e8e8e;text-align:right;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .reportCnt .cntUnit .receiveCnt .cnt{font-size:12px;font-weight:bold;line-height:24px;color:#000000;}
.newPortalPortletGrid .ptl_ReportsReceived .leftBox .reportCnt .cntUnit .receiveCnt .cnt.text_blue{color:#1c90fb;}

.newPortalPortletGrid .ptl_ReportsReceived .rightBox{height:calc(100% - 20px);margin:0 0 20px 0;}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li dl{position: relative;display: flex;width:100%;font-size:13px;font-weight:normal;line-height:34px;padding:0 0 0 44px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing:border-box;}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li dl .title{flex:1 1;margin:0 10px 0 0;font-size:13px;font-weight:normal;line-height:34px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;color:#a6a6a6;}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li dl dd.sign{position: absolute;top: 8px;left: 0;width: 36px;height: 18px;margin: 0;padding: 0;font-size:11px;font-weight:normal;line-height:18px;color: #a6a6a6;text-align: center;border:1px solid #a6a6a6;border-radius: 45px;}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li dl dd.name{position: relative;text-align: right;font-size:11px;font-weight:normal;line-height:34px;color: #8e8e8e;margin: 0;padding: 0 8px 0 0;}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li dl dd.name:after{content:'';position: absolute;top:13px;right:0;width: 1px;height:8px;background:rgba(0,0,0,0.1);}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li dl dd.date{width: 50px;text-align:right;font-size:11px;font-weight:normal;line-height:34px;color:#8e8e8e;margin:0;}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li.new dl .title{color:#222222;}
.newPortalPortletGrid .ptl_ReportsReceived .rightBox ul li.new dl dd.sign{color:#222222;border:1px solid #656565;}
.newPortalPortletGrid .col3 .ptl_ReportsReceived .leftBox{display:none;}
.newPortalPortletGrid .col4 .ptl_ReportsReceived .leftBox{display:none;}
.newPortalPortletGrid .col5 .ptl_ReportsReceived .leftBox{display:none;}
.newPortalPortletGrid .col3 .ptl_ReportsReceived .rightBox ul li dl dd.name{display:none;}

/* ONEFFICE */
.newPortalPortletGrid .ptl_oneform{position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;border-radius: 0 0 10px 10px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_oneform ul{height:100%;padding:0px 0px 0px 25px;}
.newPortalPortletGrid .ptl_oneform ul li{display: block;font-size:13px;font-weight:normal;line-height:34px;margin:0 25px 0 0;padding: 0 0 0 24px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;
    &.oneForm{background:url('../imgs/ico/ic_docu_form_m_normal.png') no-repeat left;}
}

.newPortalPortletGrid .ptl_onedocu {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden;}
.newPortalPortletGrid .ptl_onedocu ul{height:100%;padding:0px 25px;}
.newPortalPortletGrid .ptl_onedocu ul dl{position: relative;width:100%;font-size:13px;font-weight:normal;line-height:34px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.newPortalPortletGrid .ptl_onedocu ul dt.title{padding:0 0 0 24px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;
    &.shareIn{background:url('../imgs/ico/ic_notice_sharein.png') no-repeat left;background-size:18px;}
    &.shareOut{background:url('../imgs/ico/ic_notice_shareout.png') no-repeat left;background-size:18px;}
    &.shareAgain{background:url('../imgs/ico/ic_notice_shareagain.png') no-repeat left;background-size:18px;}
    &.oneDocu{background:url('../imgs/file_icon/icon_board_oneffice_small.png') no-repeat left;}
}
.newPortalPortletGrid .ptl_onedocu ul dd.name{width: 50px;text-align:right;font-size:11px;font-weight:normal;line-height:34px;color:#8e8e8e;margin:0;}

/* 영업기회 */ 
.newPortalPortletGrid .ptl_crmSales {position: absolute;top: 10px;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden; 
    ul{height:100%;padding:0px 25px; 
        li{position: relative;width:100%;font-size:13px;font-weight:normal;line-height:34px;padding:0 0 0 66px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;box-sizing:border-box; 
            .title{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;} 
            .state{position: absolute;top: 8px;left: 0;width: 60px;height: 20px;margin:0;padding: 0;font-size:11px;font-weight:normal;line-height:20px;color: #ffffff;text-align: center;border-radius: 45px; 
                &.colr-1{background:#f0c325;} // 고객발굴 
                &.colr-2{background:#46a3f0;} // 영업기회 
                &.colr-3{background:#2DBCB5;} // 영업성공 
                &.colr-4{background:#ff8787;} // 영업실패 
                &.colr-5{background:#f8a457;} // 기회할당 
                &.colr-6{background:#9da3aa;} // 기회포기 
            } 
            .comp{margin:0 0 0 10px;font-size:11px;font-weight:normal;line-height:34px;color:#8e8e8e;} 
        } 
    } 
} 
 
// 수주현황 
.newPortalPortletGrid .ptl_crmOrderStatus {position: absolute;top: 0;left: 0;right: 0;bottom: 20px;background: #f9f9f9;overflow: hidden; 
    .graphBox{width:100%;height:100%;} 
    .dataBox{margin:0 20px;align-items: center; 
        .title{border-bottom: 1px solid rgba(0, 0, 0, 0.1);padding-bottom: 2px;line-height:20px;font-weight:normal; 
            .year{font-size:12px;text-align: left;} 
            .unit{font-size:11px;text-align: right;color:#8e8e8e;} 
        } 
        .dataLine{border-top: 1px solid rgba(0, 0, 0, 0.05);padding:10px 0;box-sizing: border-box;color:#8e8e8e; 
            dt{width:40px;font-size:11px;align-items: center;} 
            dd{font-size:11px; 
                .tit{width:40px;} 
                .blue{color:#46a3f0;text-align:right;} 
                .red{color:#ff8787;text-align:right;} 
            } 
        } 
    } 
} 
.newPortalPortletGrid .row3 .ptl_crmOrderStatus{.dataLine{padding:2px 0;}}

// 생일자 
.newPortalPortletGrid .ptl_birthday {margin:5px 0 20px 0; 
    .pubDatePickerStyle > div {width: 200px !important;padding:0 25px;} 
    .birthdayList{margin:0 25px;
        .dateLine{margin:10px 0 0 0;font-size:12px;font-weight:bold;line-height:18px; 
            .today{margin:0 0 0 10px;padding:0 8px;font-size:11px;font-weight:bold;line-height:18px;color:#ffffff;text-align:center;border-radius:18px;background-color:#1c90fb;} 
        } 
        ul{ 
            li{margin:10px 0 0 0;height:28px;cursor: pointer; 
                .chkBox{width:14px;height:28px;line-height:28px;} 
                .userPic{margin:0 0 0 8px;width:28px;height:28px;border-radius:28px;overflow:hidden;background:url('../imgs/temp/img_empty_profile_52@2x.png') no-repeat top center;background-size:28px; 
                    img{width:100%;} 
                } 
                .dataBox{width:100%;height:28px;line-height:28px;margin:0 0 0 6px; 
                    // .txt{font-size:12px;font-weight:normal;color:#000000;text-overflow:ellipsis;white-space:nowrap;overflow:hidden; 
                    //     &:last-child{flex: 1 1;min-width:0;} 
                    // }                     
                    .txt{max-width: fit-content !important;flex: 1;font-size: 12px;font-weight: 400;color: #000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}                     
                    .line{margin:9px 6px 0 6px;width:1px;height:11px;background-color:#000000;opacity:0.1;} 
                } 
                [class*="OBTTooltip_root"] > div:first-child{flex:1;}
            } 
        } 
    } 
}

// 매출실적현황
.newPortalPortletGrid .ptl_salesPerformanceState {
    .graphBox{margin:0 10%;
        .graph {position: relative;transform: rotate(-90deg);width: 140px;height: 140px;
            .textBox{position: absolute;top: 40px;left: 10px;width: 110px;height: 60px;animation: chart-info 2s forwards;transform: rotate(90deg);opacity: 0;
                .text{height: 20px;font-size: 12px;line-height: 20px;color: #4a4a4a;text-align: center;letter-spacing: -0.9px;}    
                .per{height: 40px;font-size: 18px;line-height: 2.7;color: #474747;letter-spacing: -1.35px;justify-content: center;
                    b{margin: 0 2px 0 0;height: 36px;max-width: 60px;font-size: 36px;font-weight: bold;line-height: 36px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
                }    
            }    
            .back {stroke:#ebebeb;}    
            .fill {animation:chart-fill 2s reverse;transform-origin:center;}    
        }    
        @keyframes chart-fill {    
            to {stroke-dasharray:0 100;}    
        }    
        @keyframes chart-info {    
            to {opacity:1;}    
        }    
    }
    .dataValue{margin:0 10% 0 0;
        dl{position:relative;height:38px;padding:0 0 0 6px;border-top:1px dashed #e6e6e6;box-sizing:border-box;    
            &:first-child{border:none;}    
            &::before{content:'';position:absolute;top:19px;left:0;width:2px;height:2px;border-radius: 2px;background-color:#818181 ;}    
            dt{font-size:12px;line-height:38px;color:#a6a6a6;letter-spacing:-0.32px;}    
            dd{font-size:16px;font-weight:bold;line-height:38px;color:#000000;text-align:right;letter-spacing:-0.4px;
                &.t1{color:#000000;}
                &.t2{color:#1c90fb;}
                &.t3{color:#ff0000;}
            }    
        }    
    }  
}

// 자금관리 포틀릿 공통
.newPortalPortletGrid .ptl_Funds{height: 100%; padding: 0 25px 15px; box-sizing: border-box;
    [class*="OBTDatePickerRebuild_root"]{width: 198px !important;}
    .txt{margin-top: 4px; font-size: 12px; letter-spacing: -0.3px; color: #8c8c8c;}
    .fundBoxWrap{margin-top: 4px; border-radius: 4px; border: 1px solid #e6e6e6; background-color: #ffffff; box-sizing: border-box;
        .fundBox{border-bottom: 1px solid #e6e6e6; box-sizing: border-box;
            &:last-child{border-bottom: none;}
            p{font-size: 12px; letter-spacing: -0.3px;}
            .value{font-size: 12px; font-weight: bold; letter-spacing: -0.3px;
                &.red{color: #ff0000;}
                &.blue{color: #1c90fb;}
            }
            .category p{position: relative; font-weight: bold; color: #000000;
                &::before{content: ''; position: absolute; top: 50%; left: -5px; transform: translateY(-50%); width: 2px; height: 2px; background-color: #818181;}
            }
            .con p{color: #8c8c8c;}
        }
    }
}

// 자금계획대비실적현황
.newPortalPortletGrid .ptl_FundPerformanceState{
    .fundBoxWrap{
        .fundBox{padding: 5px 15px 5px 20px;}
    }
}

// 자금현황
.newPortalPortletGrid .ptl_FundStatus{
    .fundBoxWrap{
        .fundBox{padding: 10px 14px 10px 20px;}
        .con{margin: 5px 0 0 0;}
    }
}

// 자금일보
.newPortalPortletGrid .ptl_FundDaily{
    .fundBoxWrap{margin: 0 9px 0 0;
        &:last-child{margin: 0;}
        .fundBox{padding: 14px;
            .tit{padding: 0 0 0 22px; font-size: 12px; font-weight: bold; letter-spacing: -0.3px; color: #000000;
                &.finance{background: url('../imgs/ico/ico_finance_info_m_normal.png') no-repeat left center;}
                &.funds{background: url('../imgs/ico/ico_funds_info_m_normal.png') no-repeat left center;}
            }
            ul{margin: 5px 0 0 0; justify-content: space-between;
                li{font-size: 11px; letter-spacing: -0.28px; color: #8c8c8c;
                    .cnt{font-weight: bold; color: #000000;}
                }
            }
        }
    }
}

// 손익계산서(프로젝트)
.newPortalPortletGrid .ptl_IncomeProject{padding:16px 26px 45px 26px;
    [class*="OBTDatePickerRebuild_root"]{width: 100% !important;}  
    ul{padding:20px 0 0 0;
        li{padding:0 0 17px 0;      
            dl{position: relative;display: flex;width:100%;
                dt{float:left;padding:0 0 0 7px; color:#8c8c8c; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis;
                    &::before{content: '';position: absolute; top:9px;left: 0;width: 2px;height: 2px;background-color: #8c8c8c;}
                }
                dd{float:right;text-align:right;}
            }
        }        
    }
}

// 뉴스
.newPortalPortletGrid .ptl_news{
    .newsTab{padding: 0 20px;
        ul li{display: flex; align-items: center;
            > p{font-size: 14px; font-weight: normal; letter-spacing: -0.7px; color: #4a4a4a; cursor: pointer;
                &::after{content: attr(title); display: block; font-weight: bold; height: 0; visibility: hidden;}
            }
            &::after{content: ''; display: flex; width: 1px; height: 11px; margin: 0 10px; background-color: #e6e6e6;}
            &:last-child::after{display: none;}
            &.on > p{font-weight: bold; color: #1c90fb;}
        }
    }
    .newsCon{padding: 8px 25px 22px;
        .newsImg{position: relative; display: flex; align-items: center; justify-content: center; width: 190px; height: 170px; margin-right: 12px; border-radius: 4px; background-color: #ffffff; overflow: hidden; cursor: pointer;
            &:hover .imgTitle > p{text-decoration: underline;}
            img{width: 100%; height: 100%;}
            .imgTitle{position: absolute; max-height: 56px; bottom: 0; left: 0; right: 0; padding: 10px; color: #ffffff; background-color: rgba(0, 0, 0, 0.54); box-sizing: border-box;}
        }
        .newsList{display: flex; flex-direction: column; flex: 1 1; padding: 5px 0; gap: 16px; max-width: calc(100% - 202px); overflow: hidden;
            li{max-width: fit-content; font-size: 13px; color: #222222; word-break: break-all; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; cursor: pointer;}
        }
        .newsTt{display: flex; line-height: normal;}
    }
    .nodata{top: 45%;}
}

/***************************** 
    사용자 포틀릿설정
******************************/
.userSet {
    .userTangoThumbnail { display:none;position: fixed;left: 48px;right: 0;bottom: 0px;height: 124px;background: #31353c;transition:all 0.2s;overflow:hidden;z-index:5; }
    .userPortletThumbnail { display:none;position: fixed;left: 48px;right: 0;bottom: 0px;height: 120px;background: #31353c;transition:all 0.2s;overflow:hidden;z-index:5; }
    .newPortalPortletGrid  {
        .portletBox{z-index: 2;}
        .iframeBox{z-index: 2;}
        .titleBox{z-index: 2;}
        .setting { position: absolute; top: 0; left: 0; right: 0; bottom: 0; border-radius: 10px; background: rgba(0,0,0,0.65); z-index: 10; cursor: move;
            &:hover { background: url('../imgs/main/ic_move.png') no-repeat center, rgba(0,0,0,0.65);}
            &.lock { cursor: not-allowed; background: url('../imgs/main/ic_lock.png') no-repeat center, rgba(0,0,0,0.65);}
            &.nonResize { background: rgba(0,0,0,0.65);
                &:hover { background: url('../imgs/main/ic_move.png') no-repeat center, rgba(0,0,0,0.65);}
            }
            .del_btn { position: absolute; top: 24px; right: 26px; width: 20px; height: 20px; background: url('../imgs/main/ic_delete.png') no-repeat center center;cursor: pointer;
                &:hover{
                    filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                }
            }
            .resize_btn { position: absolute; bottom: 10px; right: 10px; width: 20px; height: 20px; background: url('../imgs/main/ic_scale.png') no-repeat center center;cursor: pointer;}
            .ptl_info { position: absolute;top: 24px;left: 26px;text-align: left;max-width:calc(100% - 80px);
                .name { display: block;padding: 0 28px 0 0;clear: both;font-size: 14px;color: #fff;font-weight:normal;line-height:20px;box-sizing: border-box;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
                .set_btn { position: absolute; top: 2px; right: 0; width: 20px; height: 20px; background: url('../imgs/main/ic_setting_s.png') no-repeat center center;cursor: pointer;
                    &:hover{
                        filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                    }
                }
            }
        }
        &.nonGrid {
            > table, &::after, &:before, .setting { display: none; }
        }
    }
}

// 사용자 포털세팅 컨트롤영역
.userPortletControll {display:none;position: fixed;top: 0;left: 48px;right: 0;height: 72px;overflow: hidden;background: #31353c;
    // 로고
    .header-ci{position: absolute;top:0;left: 30px;width: 200px;height: 70px;margin: 0;padding: 0;box-sizing: border-box;
        h2{overflow: hidden; margin: 0;height:70px; line-height:75px;
            img{max-width: 150px;max-height: 30px;vertical-align: middle;}
        }
    }
    // 컨트롤
    .userPortletControllIn {position: relative;width: 1060px;margin: 21px auto 0 auto;text-align: right;
        &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}
    }
    &.open{display:block;}
}

// 사용자 포털세팅 썸네일리스트
.userPortletThumbnail {display:none;position: fixed;left: 0;right: 0;bottom: 0px;height: 120px;background: #31353c;transition:all 0.2s;overflow:hidden;
    &.open{display:block;}
  
    .userPortletList {position: absolute;top: 0;left: 0;right: 0;bottom: 0;margin: 0 auto;padding: 0 50px;
        &.insize {margin: 0 auto;padding: 0 100px 0 16px;}
        ul {white-space: nowrap;font-size: 0;text-align: center;overflow-x: auto;overflow-y:hidden;scroll-behavior: smooth;
            &::after {content: "";display: block;clear: both;height: 0;visibility: hidden;}
        }
        li {display: inline-block;padding: 18px 4px;cursor: move;
            span {display: table-cell;max-width:84px;width: 84px;height: 84px;padding: 40px 0 0 0;text-align: center;font-size:11px;font-family:sans-serif;color: #ffffff;background: #464a50;border-radius: 4px;border: 2px solid #464a50;vertical-align: middle;text-overflow:ellipsis;white-space: nowrap;overflow:hidden;box-sizing: border-box;
                em {display: block;margin: 3px 0 0 0;font-size:10px;font-family:sans-serif;color: #868788;transition: all 0.3s ease-in 0s;-webkit-transition: all 0.3s ease-in 0s;}
            }
            &:hover span {border: 2px solid #1a66c2;transition: all 0.2s ease-in 0.2s;-webkit-transition: all 0.2s ease-in 0.2s;-webkit-animation: float 0.3s;animation: float 0.3s;}        
            &:hover span em {color: #1a66c2;}
            &.use {position: relative;
                &:hover span {border: 2px solid #323232;-webkit-animation: none;animation: none;}
                &:hover span em {color: #696969;}        
                &::after {content: '등록불가';position: absolute;left: 0;right: 0;top: 6px;bottom: 16px;border-radius: 4px;font-size: 12px;color: #fff;text-align: center;padding: 50px 0 0 0;background: rgba(200, 0, 0, 0.5);cursor: not-allowed;}
            }
        }
        li.UserInfo span{background:#464a50 url('../imgs/main/icon_portlet_info.png') no-repeat center 12px;}
        li.ImageBanner span{background:#464a50 url('../imgs/main/icon_portlet_banner.png') no-repeat center 12px;}
        li.MentionAlert span{background:#464a50 url('../imgs/main/icon_portlet_noti.png') no-repeat center 12px;}
        li.SignForm span{background:#464a50 url('../imgs/main/icon_portlet_form.png') no-repeat center 12px;}
        li.Calendar span{background:#464a50 url('../imgs/main/icon_portlet_cal.png') no-repeat center 12px;}
        li.Board span{background:#464a50 url('../imgs/main/icon_portlet_board.png') no-repeat center 12px;}
        li.Survey span{background:#464a50 url('../imgs/main/icon_portlet_survey.png') no-repeat center 12px;}
        li.Approval span{background:#464a50 url('../imgs/main/icon_portlet_elecpay.png') no-repeat center 12px;}
        li.Mail span{background:#464a50 url('../imgs/main/icon_portlet_email_receive.png') no-repeat center 12px;}
        li.Note span{background:#464a50 url('../imgs/main/icon_portlet_note.png') no-repeat center 12px;}
        li.Iframe span{background:#464a50 url('../imgs/main/icon_portlet_iframe.png') no-repeat center 12px;}
        li.ApprovalStatus span{background:#464a50 url('../imgs/main/icon_portlet_pay.png') no-repeat center 12px;}
        li.MailStatus span{background:#464a50 url('../imgs/main/icon_portlet_email.png') no-repeat center 12px;}
        li.Weather span{background:#464a50 url('../imgs/main/icon_portlet_weather.png') no-repeat center 12px;}
        li.QuickLink span{background:#464a50 url('../imgs/main/icon_portlet_quicklink.png') no-repeat center 12px;}
        li.WorkReport span{background:#464a50 url('../imgs/main/icon_portlet_work.png') no-repeat center 12px;}
        li.Resource span{background:#464a50 url('../imgs/main/icon_portlet_resource.png') no-repeat center 12px;}
        li.Document span{background:#464a50 url('../imgs/main/icon_portlet_doc.png') no-repeat center 12px;}
        li.SalesStatus span{background:#464a50 url('../imgs/main/icon_portlet_sales.png') no-repeat center 12px;}
        li.ExpenseClaimStatus span{background:#464a50 url('../imgs/main/icon_portlet_expense.png') no-repeat center 12px;}
        li.ExpenseUsageStatus span{background:#464a50 url('../imgs/main/icon_portlet_expense_use.png') no-repeat center 12px;}
        li.OpenSlipsStatus span{background:#464a50 url('../imgs/main/icon_portlet_pendency.png') no-repeat center 12px;}
        li.SalesTaxStatus span{background:#464a50 url('../imgs/main/icon_portlet_tax_bill.png') no-repeat center 12px;}
        li.TaxStatus span{background:#464a50 url('../imgs/main/icon_portlet_tax.png') no-repeat center 12px;}
        li.WorkingTimeAgreement span{background:#464a50 url('../imgs/main/icon_portlet_timecheck.png') no-repeat center 12px;}
        li.PromoteAnnualUse span{background:#464a50 url('../imgs/main/icon_portlet_holiday_alarm.png') no-repeat center 12px;}
        li.AnnualPlan span{background:#464a50 url('../imgs/main/icon_portlet_holiday_plan.png') no-repeat center 12px;}
        li.CertificateIssuanceStatus span{background:#464a50 url('../imgs/main/icon_portlet_certificate.png') no-repeat center 12px;}
        li.AttendanceApplicationStatus span{background:#464a50 url('../imgs/main/icon_portlet_work_time.png') no-repeat center 12px;}
        li.OvertimeApplicationStatus span{background:#464a50 url('../imgs/main/icon_portlet_work_overtime.png') no-repeat center 12px;}
        li.PersonalWorkingTimeAgree span{background:#464a50 url('../imgs/main/icon_portlet_timecheck.png') no-repeat center 12px;}
        li.WorkingTimeApproval span{background:#464a50 url('../imgs/main/icon_portlet_timecheck_approval.png') no-repeat center 12px;}
        li.WorkReportStatus span{background:#464a50 url('../imgs/main/icon_portlet_workreport.png') no-repeat center 12px;}
        li.ReportsReceived span{background:#464a50 url('../imgs/main/icon_portlet_workreport_recieve.png') no-repeat center 12px;}
        li.OnefficeForm span{background:#464a50 url('../imgs/main/icon_portlet_oneffice_doc.png') no-repeat center 12px;}
        li.OnefficeDocu span{background:#464a50 url('../imgs/main/icon_portlet_oneffice.png') no-repeat center 12px;}
        li.SalesChance span{background:#464a50 url('../imgs/main/icon_Portlet_sales_opportunity.png') no-repeat center 12px;} 
        li.OrderStatus span{background:#464a50 url('../imgs/main/icon_Portlet_order_status.png') no-repeat center 12px;}        
        li.Birthday span{background:#464a50 url('../imgs/main/icon_portlet_birthday.png') no-repeat center 12px;}   
        li.SalesPerformanceStatus span{background:#464a50 url('../imgs/main/icon_portlet_performance_sales.png') no-repeat center 12px;}   
        li.FundPerformanceStatus span{background:#464a50 url('../imgs/main/icon_portlet_fundplan.png') no-repeat center 12px;}   
        li.FundStatus span{background:#464a50 url('../imgs/main/icon_portlet_funds.png') no-repeat center 12px;}   
        li.FundDaily span{background:#464a50 url('../imgs/main/icon_portlet_fundreport.png') no-repeat center 12px;}   
        li.IncomeProject span{background:#464a50 url('../imgs/main/icon_Portlet_income_project.png') no-repeat center 12px;} 
        li.News span{background:#464a50 url('../imgs/main/icon_portlet_news@2x.png') no-repeat center 12px; background-size: 22px;} 

        .arrLeft{position: absolute;top:0px;left:0px;width:30px;height:120px;background:url('../imgs/main/icon_arrow_left_normal.png') no-repeat center center;cursor: pointer;
            &:hover{background:url('../imgs/main/icon_arrow_left_over.png') no-repeat center center;}
        }
        .arrRight{position: absolute;top:0px;right:0px;width:50px;height:120px;background:url('../imgs/main/icon_arrow_right_normal.png') no-repeat center center;cursor: pointer;
            &:hover{background:url('../imgs/main/icon_arrow_right_over.png') no-repeat center center;}
        }
    }
}

// 사용자 인하우스 세팅 썸네일리스트
.userTangoThumbnail {display:none;position: fixed;left: 0;right: 0;bottom: 0px;height: 120px;background: #31353c;transition:all 0.2s;overflow:hidden;
    &.open{display:block;}

    .userTangoFn{height:20px;margin:14px 0 0 20px;
        .txt{position:relative;font-size:14px;line-height: 20px;font-weight:normal;letter-spacing: -0.7px;color:#a6a6a6;cursor: pointer;
            &.on{font-weight:bold;color:#ffffff;
                .dropdownList{position:absolute;top:0;left:0;min-width:114px !important;width:fit-content !important;
                    & > div:first-child > div{display: flex !important;}
                    & > div:first-child > div > span{padding:0 !important;border:none !important;background: transparent !important;line-height: 20px !important;
                        span{font-size:14px !important;line-height: 20px !important;font-weight:normal !important;letter-spacing: -0.7px !important;color:#ffffff !important;cursor: pointer !important;}
                    }
                    & > div:first-child > div > button{position:static !important;width: 20px !important;height: 20px !important;margin: 0 !important;top: 0 !important;
                        span{width:20px !important;height:20px !important;background: url('../imgs/ico/ic_arrow_down_02_s_normal_white@2x.png') no-repeat center !important;background-size:14px !important;}
                    }
                }
            }
            .dropdownList{position:absolute;top:0;left:0;min-width:114px !important;width:fit-content !important;
                & > div:first-child > div{display: flex !important;}
                & > div:first-child > div > span{display:inline-flex !important;padding:0 !important;border:none !important;background: transparent !important;line-height: 20px !important;
                    span{font-size:14px !important;line-height: 20px !important;font-weight:normal !important;letter-spacing: -0.7px !important;color:#a6a6a6 !important;cursor: pointer !important;}
                }
                & > div:first-child > div > button{position:static !important;width: 20px !important;height: 20px !important;margin: 0 !important;top: 0 !important;
                    span{width:20px !important;height:20px !important;background: url('../imgs/ico/ic_arrow_down_02_s_disable.png') no-repeat center !important;background-size:14px !important;}
                }
            }
        }
        .line{width:1px;height:11px;margin:5px 10px 0 10px;background-color: rgba(255, 255, 255, 0.1);}
    }

    .userTangoList{position: absolute;top: 34px;left: 0;right: 0;bottom: 0;margin: 0 auto;padding: 0 40px;
        ul{white-space: nowrap;font-size: 0;text-align: center;overflow-x: auto;overflow-y:hidden;scroll-behavior: smooth;min-height:105px;
            li{min-width:76px;min-height:76px;cursor: move;
                span{padding: 60px 0 0 0;text-align: center;font-size:11px;line-height:16px;font-family:sans-serif;color: #ffffff;
                    // 인하우스 아이콘 그룹(설정) //
                    &.inHouse_ecoIcon{background:url('../imgs/main/ecoPlace/ic_default@2x.png') no-repeat top 15px center;background-size: 34px;}             // 기본아이콘
                    // 메일 그룹
                    &.ML_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_mail@2x.png') no-repeat top 15px center;background-size: 34px;}                   // 메일
                    &.ML_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_mail_write@2x.png') no-repeat top 15px center;background-size: 34px;}             // 메일쓰기
                    &.ML_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_mail_all@2x.png') no-repeat top 15px center;background-size: 34px;}               // 전체메일함
                    &.ML_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_mail_send@2x.png') no-repeat top 15px center;background-size: 34px;}              // 보낸메일함
                    &.ML_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_mail_receive@2x.png') no-repeat top 15px center;background-size: 34px;}           // 받은메일함
                    // 문자 그룹
                    &.SMS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sms@2x.png') no-repeat top 15px center;background-size: 34px;}                   // 문자
                    &.SMS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_sms_send@2x.png') no-repeat top 15px center;background-size: 34px;}              // 문자보내기
                    // 업무관리 그룹
                    &.KS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_kiss@2x.png') no-repeat top 15px center;background-size: 34px;}                   // 업무관리
                    &.KS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_mywork@2x.png') no-repeat top 15px center;background-size: 34px;}                 // 나의업무
                    &.KS_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_project_star@2x.png') no-repeat top 15px center;background-size: 34px;}           // 나의프로젝트
                    // 일정 그룹
                    &.CL_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_calendar@2x.png') no-repeat top 15px center;background-size: 34px;}               // 일정
                    &.CL_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_rm@2x.png') no-repeat top 15px center;background-size: 34px;}                     // 자원
                    // 임직원업무관리 그룹
                    &.HR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_hr@2x.png') no-repeat top 15px center;background-size: 34px;}                     // 임직원업무관리
                    &.HR_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_attendance_write@2x.png') no-repeat top 15px center;background-size: 34px;}       // 근태신청
                    &.HR_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_attendance_cancel@2x.png') no-repeat top 15px center;background-size: 34px;}      // 근태신청취소
                    &.HR_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_paystub@2x.png') no-repeat top 15px center;background-size: 34px;}                // 급여명세서
                    &.HR_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_business_hours_agree@2x.png') no-repeat top 15px center;background-size: 34px;}   // 개인근무시간동의
                    &.HR_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_address_book@2x.png') no-repeat top 15px center;background-size: 34px;}           // 전체주소록
                    &.HR_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_report_send@2x.png') no-repeat top 15px center;background-size: 34px;}            // 보낸보고함
                    &.HR_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_report_receive@2x.png') no-repeat top 15px center;background-size: 34px;}         // 받은보고함
                    &.HR_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_memo@2x.png') no-repeat top 15px center;background-size: 34px;}                   // 노트
                    &.HR_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_expense@2x.png') no-repeat top 15px center;background-size: 34px;}                // 경비취합
                    &.HR_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_expense_write@2x.png') no-repeat top 15px center;background-size: 34px;}          // 경비청구작성
                    &.HR_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_sign_hr_write@2x.png') no-repeat top 15px center;background-size: 34px;}          // 지출결의서작성
                    &.HR_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_approval_write@2x.png') no-repeat top 15px center;background-size: 34px;}         // 품의서작성
                    &.HR_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat top 15px center;background-size: 34px;}                 // 개인인사정보조회
                    // 전자결재 그룹
                    &.EA_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sign@2x.png') no-repeat top 15px center;background-size: 34px;}                   // 전자결재
                    &.EA_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_signhome@2x.png') no-repeat top 15px center;background-size: 34px;}               // 결재홈
                    &.EA_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_sign_write@2x.png') no-repeat top 15px center;background-size: 34px;}             // 결재작성
                    // 팩스 그룹
                    &.FAX_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_fax@2x.png') no-repeat top 15px center;background-size: 34px;}                   // 팩스
                    &.FAX_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_fax_send@2x.png') no-repeat top 15px center;background-size: 34px;}              // 팩스보내기
                    &.FAX_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_fax_receive@2x.png') no-repeat top 15px center;background-size: 34px;}           // 받은팩스함
                    // 원피스 그룹
                    &.OF_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_oneffice@2x.png') no-repeat top 15px center;background-size: 34px;}               // 원피스
                    // 원챔버 그룹
                    &.OC_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_onechamber@2x.png') no-repeat top 15px center;background-size: 34px;}             // 원챔버
                    &.OC_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_my@2x.png') no-repeat top 15px center;background-size: 34px;}                     // 내문서함
                    &.OC_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_docuflow@2x.png') no-repeat top 15px center;background-size: 34px;}               // 도큐플로우
                    // 회계 그룹
                    &.FI_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_account@2x.png') no-repeat top 15px center;background-size: 34px;}                // 회계관리
                    &.FI_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_nonprofit@2x.png') no-repeat top 15px center;background-size: 34px;}              // 예산관리
                    // 인사근태 그룹
                    &.HM_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_human@2x.png') no-repeat top 15px center;background-size: 34px;}                  // 인사관리
                    // 게시판 그룹
                    &.BD_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_board@2x.png') no-repeat top 15px center;background-size: 34px;}                  // 게시판
                    // 확장기능 그룹
                    &.EX_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_user_plus@2x.png') no-repeat top 15px center;background-size: 34px;}              // 방문객등록
                    // 총무구매 그룹 
                    &.GSM_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_supplies_write.png') no-repeat top 15px center;background-size: 34px;}           // 사무용품신청
                    &.GSM_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_supplies_manage.png') no-repeat top 15px center;background-size: 34px;}          // 사무용품신청관리
                    &.GSM_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_supplies_order.png') no-repeat top 15px center;background-size: 34px;}           // 사무용품주문관리
                    &.GSM_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_supplies_calculate.png') no-repeat top 15px center;background-size: 34px;}       // 사무용품정산관리
                    // 통합주소록 그룹
                    &.ADDR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_total_address@2x.png') no-repeat top 15px center;background-size: 34px;}       // 사무용품정산관리
                    // HIS 그룹
                    &.HIS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_inhouse01@2x.png') no-repeat top 15px center;background-size: 34px;}        // 원무(HIS전용)
                    &.HIS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_inhouse02@2x.png') no-repeat top 15px center;background-size: 34px;}        // 진료(HIS전용)
                    &.HIS_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_inhouse03@2x.png') no-repeat top 15px center;background-size: 34px;}        // 진료지원(HIS전용)
                    &.HIS_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_inhouse04@2x.png') no-repeat top 15px center;background-size: 34px;}        // 보험심사(HIS전용)
                    &.HIS_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_inhouse05@2x.png') no-repeat top 15px center;background-size: 34px;}        // 통계(HIS전용)
                    &.HIS_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_inhouse07@2x.png') no-repeat top 15px center;background-size: 34px;}        // 기초코드(HIS전용)
                    &.HIS_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_inhouse08@2x.png') no-repeat top 15px center;background-size: 34px;}        // 서식(HIS전용)
                    &.HIS_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_inhouse09@2x.png') no-repeat top 15px center;background-size: 34px;}        // 재고관리(HIS전용)
                    &.HIS_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_inhouse10@2x.png') no-repeat top 15px center;background-size: 34px;}        // 병원정보설정(HIS전용)
                    &.HIS_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_inhouse11@2x.png') no-repeat top 15px center;background-size: 34px;}        // 간편 세무 회계(HIS전용)
                    &.HIS_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_education@3x.png') no-repeat top 15px center;background-size: 34px;}        // 법정교육(HIS전용)
                    &.HIS_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_labor@3x.png') no-repeat top 15px center;background-size: 34px;}            // 법무노무(HIS전용)
                    &.HIS_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_theporterzone@3x.png') no-repeat top 15px center;background-size: 34px;}    // 더포터존(HIS전용)
                    &.HIS_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_industrial@2x.png') no-repeat top 15px center;background-size: 34px;}       // 공단검진(HIS전용)
                    &.HIS_ecoIcon15{background:url('../imgs/main/ecoPlace/ic_policy@2x.png') no-repeat top 15px center;background-size: 34px;}           // 정책고시(HIS전용)
                    &.HIS_ecoIcon16{background:url('../imgs/main/ecoPlace/ic_notice@2x.png') no-repeat top 15px center;background-size: 34px;}           // 공지사항(HIS전용)
                    // 로테크 그룹
                    &.UR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_total_lawtech@2x.png') no-repeat top 15px center;background-size: 34px;}     // 로테크
                    
                    // 커스텀 그룹 (패키지 X )
                    &.Custom_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sales@2x.png') no-repeat top 15px center;background-size: 34px;}              // 매출관리
                    &.Custom_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_manage_account@2x.png') no-repeat top 15px center;background-size: 34px;}     // 계좌관리
                    &.Custom_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_salary@2x.png') no-repeat top 15px center;background-size: 34px;}             // 급여관리
                    &.Custom_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_fund@2x.png') no-repeat top 15px center;background-size: 34px;}               // 자금관리
                    &.Custom_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_corporation_card@2x.png') no-repeat top 15px center;background-size: 34px;}   // 법인카드
                    &.Custom_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_spend@2x.png') no-repeat top 15px center;background-size: 34px;}              // 지출결의
                    &.Custom_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat top 15px center;background-size: 34px;}             // 마이페이지
                    &.Custom_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_work_report@2x.png') no-repeat top 15px center;background-size: 34px;}        // 업무보고
                    &.Custom_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_keep@2x.png') no-repeat top 15px center;background-size: 34px;}               // KEEP
                    &.Custom_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_attendance@2x.png') no-repeat top 15px center;background-size: 34px;}         // 근태관리
                    &.Custom_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_crm@2x.png') no-repeat top 15px center;background-size: 34px;}                // CRM
                    &.Custom_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_business_hours@2x.png') no-repeat top 15px center;background-size: 34px;}     // 근무시간현황
                    &.Custom_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_distri@2x.png') no-repeat top 15px center;background-size: 34px;}             // 물류
                    &.Custom_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_produce@2x.png') no-repeat top 15px center;background-size: 34px;}            // 생산관리
                    &.Custom_ecoIcon15{background:url('../imgs/main/ecoPlace/ic_buy_write@2x.png') no-repeat top 15px center;background-size: 34px;}          // 구매신청
                    &.Custom_ecoIcon16{background:url('../imgs/main/ecoPlace/ic_portal@2x.png') no-repeat top 15px center;background-size: 34px;}             // 포털
                    &.Custom_ecoIcon17{background:url('../imgs/main/ecoPlace/ic_ex@2x.png') no-repeat top 15px center;background-size: 34px;}                 // 확장기능
                    &.Custom_ecoIcon18{background:url('../imgs/main/ecoPlace/ic_video@2x.png') no-repeat top 15px center;background-size: 34px;}              // 동영상보기    
                    &.Custom_ecoIcon19{background:url('../imgs/main/ecoPlace/ic_online_experience@2x.png') no-repeat top 15px center;background-size: 34px;}  // 온라인체험관
                    &.Custom_ecoIcon20{background:url('../imgs/main/ecoPlace/ic_system_setting@2x.png') no-repeat top 15px center;background-size: 34px;}     // 시스템환경설정
                    &.Custom_ecoIcon21{background:url('../imgs/main/ecoPlace/ic_process_management@2x.png') no-repeat top 15px center;background-size: 34px;} // 프로세스관리
                    &.Custom_ecoIcon22{background:url('../imgs/main/ecoPlace/ic_wehago@2x.png') no-repeat top 15px center;background-size: 34px;}             // 위하고
                    &.Custom_ecoIcon23{background:url('../imgs/main/ecoPlace/ic_wiki@2x.png') no-repeat top 15px center;background-size: 34px;}               // 위키
                    &.Custom_ecoIcon24{background:url('../imgs/main/ecoPlace/ic_jira@2x.png') no-repeat top 15px center;background-size: 34px;}               // 지라
                    &.Custom_ecoIcon25{background:url('../imgs/main/ecoPlace/ic_gcms@2x.png') no-repeat top 15px center;background-size: 34px;}               // GCMS
                    &.Custom_ecoIcon26{background:url('../imgs/main/ecoPlace/ic_skt_meetus@2x.png') no-repeat top 15px center;background-size: 34px;}         // SKT MeetUs
                    &.Custom_ecoIcon27{background:url('../imgs/main/ecoPlace/ic_nsm_10@2x.png') no-repeat top 15px center;background-size: 34px;}             // NSM10
                    &.Custom_ecoIcon28{background:url('../imgs/main/ecoPlace/ic_erp_10@2x.png') no-repeat top 15px center;background-size: 34px;}             // ERP10
                    &.Custom_ecoIcon29{background:url('../imgs/main/ecoPlace/ic_amaranth_10@2x.png') no-repeat top 15px center;background-size: 34px;}        // Amaranth10
                    &.Custom_ecoIcon30{background:url('../imgs/main/ecoPlace/ic_icube@2x.png') no-repeat top 15px center;background-size: 34px;}              // iCUBE
                    &.Custom_ecoIcon31{background:url('../imgs/main/ecoPlace/ic_cor_sol@2x.png') no-repeat top 15px center;background-size: 34px;}            // 기업금융관리
                    &.Custom_ecoIcon32{background:url('../imgs/main/ecoPlace/ic_sol@2x.png') no-repeat top 15px center;background-size: 34px;}                // 개인금융관리
                    &.Custom_ecoIcon33{background:url('../imgs/main/ecoPlace/ic_support@2x.png') no-repeat top 15px center;background-size: 34px;}            // 서포트
                    &.Custom_ecoIcon34{background:url('../imgs/main/ecoPlace/ic_smartlink.png') no-repeat top 15px center;background-size: 34px;}             // 스마트링크
                    &.Custom_ecoIcon35{background:url('../imgs/main/ecoPlace/ic_whistlenote@2x.png') no-repeat top 15px center;background-size: 34px;}        // 휘슬노트
                    &.Custom_ecoIcon36{background:url('../imgs/main/ecoPlace/ic_redcaptour@2x.png') no-repeat top 15px center;background-size: 34px;}         // 레드캡투어
                    &.Custom_ecoIcon37{background:url('../imgs/main/ecoPlace/ic_logispot@2x.png') no-repeat top 15px center;background-size: 34px;}           // 로지스팟
                }
            }
        }
    }

    .arrLeft{position: absolute;top:0px;left:0px;width:50px;height:76px;background:url('../imgs/main/icon_arrow_left_normal.png') no-repeat center center;cursor: pointer;
        &:hover{background:url('../imgs/main/icon_arrow_left_over.png') no-repeat center center;}
    }
    .arrRight{position: absolute;top:0px;right:0px;width:50px;height:76px;background:url('../imgs/main/icon_arrow_right_normal.png') no-repeat center center;cursor: pointer;
        &:hover{background:url('../imgs/main/icon_arrow_right_over.png') no-repeat center center;}
    }

    .filter_no{
        .text{padding: 44px 0 0 0;text-align: center;font-size:11px;line-height:16px;font-family:sans-serif;color: #ffffff;background:url('../imgs/main/ic_inhouse_emptyset@2x.png') no-repeat top 0px center;background-size: 38px 42px;}
    }
}

/*----------------------------*
	GRID COL ROW
*----------------------------*/
.newPortalPortletGrid{
    .col1{width:70px;}
    .col2{width:160px;}
    .col3{width:250px;}
    .col4{width:340px;}
    .col5{width:430px;}
    .col6{width:520px;}
    .col7{width:610px;}
    .col8{width:700px;}
    .col9{width:790px;}
    .col10{width:880px;}
    .col11{width:970px;}
    .col12{width:1060px;}
    .col13{width:1150px;}
    .col14{width:1240px;}
    .col15{width:1330px;}
    .col16{width:1420px;}
    .col17{width:1510px;}
    .col18{width:1600px;}
    .col19{width:1690px;}
    .col20{width:1780px;}

    .row1{height:70px;}
    .row2{height:160px;}
    .row3{height:250px;}
    .row4{height:340px;}
    .row5{height:430px;}
    .row6{height:520px;}
    .row7{height:610px;}
    .row8{height:700px;}
    .row9{height:790px;}
    .row10{height:880px;}
    .row11{height:970px;}
    .row12{height:1060px;}
    .row13{height:1150px;}
    .row14{height:1240px;}
    .row15{height:1330px;}
    .row16{height:1420px;}
    .row17{height:1510px;}
    .row18{height:1600px;}
    .row19{height:1690px;}
    .row20{height:1780px;}

    .portletBox{
        // 가로
        &.col1 .ptl_content{width:70px;}
        &.col2 .ptl_content{width:160px;}
        &.col3 .ptl_content{width:250px;}
        &.col4 .ptl_content{width:340px;}
        &.col5 .ptl_content{width:430px;}
        &.col6 .ptl_content{width:520px;}
        &.col7 .ptl_content{width:610px;}
        &.col8 .ptl_content{width:700px;}
        &.col9 .ptl_content{width:790px;}
        &.col10 .ptl_content{width:880px;}
        &.col11 .ptl_content{width:970px;}
        &.col12 .ptl_content{width:1060px;}
        &.col13 .ptl_content{width:1150px;}
        &.col14 .ptl_content{width:1240px;}
        &.col15 .ptl_content{width:1330px;}
        &.col16 .ptl_content{width:1420px;}
        &.col17 .ptl_content{width:1510px;}
        &.col18 .ptl_content{width:1600px;}
        &.col19 .ptl_content{width:1690px;}
        &.col20 .ptl_content{width:1780px;}
        // 세로
        &.row1 .ptl_content{height:70px;}
        &.row2 .ptl_content{height:160px;}
        &.row3 .ptl_content{height:250px;}
        &.row4 .ptl_content{height:340px;}
        &.row5 .ptl_content{height:430px;}
        &.row6 .ptl_content{height:520px;}
        &.row7 .ptl_content{height:610px;}
        &.row8 .ptl_content{height:700px;}
        &.row9 .ptl_content{height:790px;}
        &.row10 .ptl_content{height:880px;}
        &.row11 .ptl_content{height:970px;}
        &.row12 .ptl_content{height:1060px;}
        &.row13 .ptl_content{height:1150px;}
        &.row14 .ptl_content{height:1240px;}
        &.row15 .ptl_content{height:1330px;}
        &.row16 .ptl_content{height:1420px;}
        &.row17 .ptl_content{height:1510px;}
        &.row18 .ptl_content{height:1600px;}
        &.row19 .ptl_content{height:1690px;}
        &.row20 .ptl_content{height:1780px;}
    }
    .iframeBox{
        // 가로
        &.col1 .ptl_content{width:70px;}
        &.col2 .ptl_content{width:160px;}
        &.col3 .ptl_content{width:250px;}
        &.col4 .ptl_content{width:340px;}
        &.col5 .ptl_content{width:430px;}
        &.col6 .ptl_content{width:520px;}
        &.col7 .ptl_content{width:610px;}
        &.col8 .ptl_content{width:700px;}
        &.col9 .ptl_content{width:790px;}
        &.col10 .ptl_content{width:880px;}
        &.col11 .ptl_content{width:970px;}
        &.col12 .ptl_content{width:1060px;}
        &.col13 .ptl_content{width:1150px;}
        &.col14 .ptl_content{width:1240px;}
        &.col15 .ptl_content{width:1330px;}
        &.col16 .ptl_content{width:1420px;}
        &.col17 .ptl_content{width:1510px;}
        &.col18 .ptl_content{width:1600px;}
        &.col19 .ptl_content{width:1690px;}
        &.col20 .ptl_content{width:1780px;}
        // 세로
        &.row1 .ptl_content{height:70px;}
        &.row2 .ptl_content{height:160px;}
        &.row3 .ptl_content{height:250px;}
        &.row4 .ptl_content{height:340px;}
        &.row5 .ptl_content{height:430px;}
        &.row6 .ptl_content{height:520px;}
        &.row7 .ptl_content{height:610px;}
        &.row8 .ptl_content{height:700px;}
        &.row9 .ptl_content{height:790px;}
        &.row10 .ptl_content{height:880px;}
        &.row11 .ptl_content{height:970px;}
        &.row12 .ptl_content{height:1060px;}
        &.row13 .ptl_content{height:1150px;}
        &.row14 .ptl_content{height:1240px;}
        &.row15 .ptl_content{height:1330px;}
        &.row16 .ptl_content{height:1420px;}
        &.row17 .ptl_content{height:1510px;}
        &.row18 .ptl_content{height:1600px;}
        &.row19 .ptl_content{height:1690px;}
        &.row20 .ptl_content{height:1780px;}
    }
    .titleBox{
        // 가로
        &.col1 .ptl_content{width:70px;}
        &.col2 .ptl_content{width:160px;}
        &.col3 .ptl_content{width:250px;}
        &.col4 .ptl_content{width:340px;}
        &.col5 .ptl_content{width:430px;}
        &.col6 .ptl_content{width:520px;}
        &.col7 .ptl_content{width:610px;}
        &.col8 .ptl_content{width:700px;}
        &.col9 .ptl_content{width:790px;}
        &.col10 .ptl_content{width:880px;}
        &.col11 .ptl_content{width:970px;}
        &.col12 .ptl_content{width:1060px;}
        &.col13 .ptl_content{width:1150px;}
        &.col14 .ptl_content{width:1240px;}
        &.col15 .ptl_content{width:1330px;}
        &.col16 .ptl_content{width:1420px;}
        &.col17 .ptl_content{width:1510px;}
        &.col18 .ptl_content{width:1600px;}
        &.col19 .ptl_content{width:1690px;}
        &.col20 .ptl_content{width:1780px;}
        // 세로
        &.row1 .ptl_content{height:20px;}
        &.row2 .ptl_content{height:110px;}
        &.row3 .ptl_content{height:200px;}
        &.row4 .ptl_content{height:290px;}
        &.row5 .ptl_content{height:380px;}
        &.row6 .ptl_content{height:470px;}
        &.row7 .ptl_content{height:560px;}
        &.row8 .ptl_content{height:650px;}
        &.row9 .ptl_content{height:740px;}
        &.row10 .ptl_content{height:830px;}
        &.row11 .ptl_content{height:920px;}
        &.row12 .ptl_content{height:1010px;}
        &.row13 .ptl_content{height:1100px;}
        &.row14 .ptl_content{height:1190px;}
        &.row15 .ptl_content{height:1280px;}
        &.row16 .ptl_content{height:1370px;}
        &.row17 .ptl_content{height:1460px;}
        &.row18 .ptl_content{height:1550px;}
        &.row19 .ptl_content{height:1640px;}
        &.row20 .ptl_content{height:1730px;}
    }
    // mentionCon
    .row6 .ptl_mention .mentionCon{height:475px;} 
    .row7 .ptl_mention .mentionCon{height:565px;} 
    .row8 .ptl_mention .mentionCon{height:655px;} 
    .row9 .ptl_mention .mentionCon{height:745px;}
    .row10 .ptl_mention .mentionCon{height:835px;}
    .row11 .ptl_mention .mentionCon{height:925px;}
    .row12 .ptl_mention .mentionCon{height:1015px;}
    .row13 .ptl_mention .mentionCon{height:1105px;}
    .row14 .ptl_mention .mentionCon{height:1195px;}
    .row15 .ptl_mention .mentionCon{height:1285px;}
    .row16 .ptl_mention .mentionCon{height:1375px;}
    .row17 .ptl_mention .mentionCon{height:1465px;}
    .row18 .ptl_mention .mentionCon{height:1555px;}
    .row19 .ptl_mention .mentionCon{height:1645px;}
    .row20 .ptl_mention .mentionCon{height:1735px;}
    // mentionCon > list_con
    .col3 .ptl_mention .mentionCon ul li .list_con{width: 156px;}
    .col4 .ptl_mention .mentionCon ul li .list_con{width: 246px;}
    .col5 .ptl_mention .mentionCon ul li .list_con{width: 336px;}
    .col6 .ptl_mention .mentionCon ul li .list_con{width: 426px;}
    .col7 .ptl_mention .mentionCon ul li .list_con{width: 516px;}
    .col8 .ptl_mention .mentionCon ul li .list_con{width: 606px;}
    .col9 .ptl_mention .mentionCon ul li .list_con{width: 696px;}
    .col10 .ptl_mention .mentionCon ul li .list_con{width: 786px;}
    .col11 .ptl_mention .mentionCon ul li .list_con{width: 876px;}
    .col12 .ptl_mention .mentionCon ul li .list_con{width: 966px;}
    .col13 .ptl_mention .mentionCon ul li .list_con{width: 1056px;}
    .col14 .ptl_mention .mentionCon ul li .list_con{width: 1146px;}
    .col15 .ptl_mention .mentionCon ul li .list_con{width: 1236px;}
    .col16 .ptl_mention .mentionCon ul li .list_con{width: 1326px;}
    .col17 .ptl_mention .mentionCon ul li .list_con{width: 1416px;}
    .col18 .ptl_mention .mentionCon ul li .list_con{width: 1506px;}
    .col19 .ptl_mention .mentionCon ul li .list_con{width: 1596px;}
    .col20 .ptl_mention .mentionCon ul li .list_con{width: 1686px;}
    
    // 일정 ,자원 가로형
    .col3 .ptl_calendar .horizon_right{float:left;width: 220px;margin:0 15px;}
    .col3.row3 .ptl_calendar .horizon_right{display:none;}
    .col4.row3 .ptl_calendar .horizon_right{display:none;}
    .col5.row3 .ptl_calendar .horizon_right{display:none;}
    .col6 .ptl_calendar .horizon_right{float:left;width: 255px;}
    .col7 .ptl_calendar .horizon_right{float:left;width: 345px;}
    .col8 .ptl_calendar .horizon_right{float:left;width: 435px;}
    .col9 .ptl_calendar .horizon_right{float:left;width: 525px;}
    .col10 .ptl_calendar .horizon_right{float:left;width: 615px;}
    .col11 .ptl_calendar .horizon_right{float:left;width: 705px;}
    .col12 .ptl_calendar .horizon_right{float:left;width: 795px;}
    .col13 .ptl_calendar .horizon_right{float:left;width: 885px;}
    .col14 .ptl_calendar .horizon_right{float:left;width: 975px;}
    .col15 .ptl_calendar .horizon_right{float:left;width: 1065px;}
    .col16 .ptl_calendar .horizon_right{float:left;width: 1155px;}
    .col17 .ptl_calendar .horizon_right{float:left;width: 1245px;}
    .col18 .ptl_calendar .horizon_right{float:left;width: 1335px;}
    .col19 .ptl_calendar .horizon_right{float:left;width: 1425px;}
    .col20 .ptl_calendar .horizon_right{float:left;width: 1515px;}

    // 일정 ,자원 세로
    .row3 .ptl_calendar .horizon_right{height:180px;}
    .row3.col3 .ptl_calendar .horizon_right{display:none;}
    .row4.col3 .ptl_calendar .horizon_right{display:none;}
    .row5 .ptl_calendar .horizon_right{height:135px;}
    .row6 .ptl_calendar .horizon_right{height:240px;}
    .row7 .ptl_calendar .horizon_right{height:330px;}
    .row8 .ptl_calendar .horizon_right{height:420px;}
    .row9 .ptl_calendar .horizon_right{height:510px;}
    .row10 .ptl_calendar .horizon_right{height:600px;}
    .row11 .ptl_calendar .horizon_right{height:690px;}
    .row12 .ptl_calendar .horizon_right{height:780px;}
    .row13 .ptl_calendar .horizon_right{height:870px;}
    .row14 .ptl_calendar .horizon_right{height:960px;}
    .row15 .ptl_calendar .horizon_right{height:1050px;}
    .row16 .ptl_calendar .horizon_right{height:1140px;}
    .row17 .ptl_calendar .horizon_right{height:1230px;}
    .row18 .ptl_calendar .horizon_right{height:1320px;}
    .row19 .ptl_calendar .horizon_right{height:1410px;}
    .row20 .ptl_calendar .horizon_right{height:1500px;}

    // 일정 ,자원 글자공간 st1
    .col3 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 150px;}
    .col6 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 190px;}
    .col7 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 280px;}
    .col8 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 370px;}
    .col9 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 460px;}
    .col10 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 550px;}
    .col11 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 640px;}
    .col12 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 730px;}
    .col13 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 820px;}
    .col14 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 910px;}
    .col15 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 1000px;}
    .col16 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 1090px;}
    .col17 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 1180px;}
    .col18 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 1270px;}
    .col19 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 1360px;}
    .col20 .ptl_calendar .calendar_div.st1 .calendar_list li .txt{max-width: 1450px;}
    // 일정 ,자원 글자공간
    .col3 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 200px;}
    .col6 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 235px;}
    .col7 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 325px;}
    .col8 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 415px;}
    .col9 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 505px;}
    .col10 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 595px;}
    .col11 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 685px;}
    .col12 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 775px;}
    .col13 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 865px;}
    .col14 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 955px;}
    .col15 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 1045px;}
    .col16 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 1135px;}
    .col17 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 1225px;}
    .col18 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 1315px;}
    .col19 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 1405px;}
    .col20 .ptl_calendar .calendar_div .calendar_list li .txt{max-width: 1495px;}
    // 편지함
    .col3 .ptl_mail ul dt.title{max-width:125px;}
    .col4 .ptl_mail ul dt.title{max-width:215px;}
    .col5 .ptl_mail ul dt.title{max-width:305px;}
    .col6 .ptl_mail ul dt.title{max-width:395px;}
    .col7 .ptl_mail ul dt.title{max-width:485px;}
    .col8 .ptl_mail ul dt.title{max-width:575px;}
    .col9 .ptl_mail ul dt.title{max-width:665px;}
    .col10 .ptl_mail ul dt.title{max-width:755px;}
    .col11 .ptl_mail ul dt.title{max-width:845px;}
    .col12 .ptl_mail ul dt.title{max-width:935px;}
    .col13 .ptl_mail ul dt.title{max-width:1025px;}
    .col14 .ptl_mail ul dt.title{max-width:1115px;}
    .col15 .ptl_mail ul dt.title{max-width:1205px;}
    .col16 .ptl_mail ul dt.title{max-width:1295px;}
    .col17 .ptl_mail ul dt.title{max-width:1385px;}
    .col18 .ptl_mail ul dt.title{max-width:1475px;}
    .col19 .ptl_mail ul dt.title{max-width:1565px;}
    .col20 .ptl_mail ul dt.title{max-width:1655px;}
    // 게시판
    .col3 .ptl_board ul dt.title{max-width:120px;}
    .col4 .ptl_board ul dt.title{max-width:210px;}
    .col5 .ptl_board ul dt.title{max-width:300px;}
    .col6 .ptl_board ul dt.title{max-width:390px;}
    .col7 .ptl_board ul dt.title{max-width:480px;}
    .col8 .ptl_board ul dt.title{max-width:570px;}
    .col9 .ptl_board ul dt.title{max-width:660px;}
    .col10 .ptl_board ul dt.title{max-width:750px;}
    .col11 .ptl_board ul dt.title{max-width:840px;}
    .col12 .ptl_board ul dt.title{max-width:930px;}
    .col13 .ptl_board ul dt.title{max-width:1020px;}
    .col14 .ptl_board ul dt.title{max-width:1110px;}
    .col15 .ptl_board ul dt.title{max-width:1200px;}
    .col16 .ptl_board ul dt.title{max-width:1290px;}
    .col17 .ptl_board ul dt.title{max-width:1380px;}
    .col18 .ptl_board ul dt.title{max-width:1470px;}
    .col19 .ptl_board ul dt.title{max-width:1560px;}
    .col20 .ptl_board ul dt.title{max-width:1650px;}
    // 전자결재 텍스트 (태그있을때)
    .col3 .ptl_approval ul dt.title{max-width:82px;} 
    .col4 .ptl_approval ul dt.title{max-width:126px;} 
    .col5 .ptl_approval ul dt.title{max-width:216px;}  
    .col6 .ptl_approval ul dt.title{max-width:306px;}
    .col7 .ptl_approval ul dt.title{max-width:396px;}  
    .col8 .ptl_approval ul dt.title{max-width:486px;}  
    .col9 .ptl_approval ul dt.title{max-width:576px;}  
    .col10 .ptl_approval ul dt.title{max-width:666px;}
    .col11 .ptl_approval ul dt.title{max-width:756px;}
    .col12 .ptl_approval ul dt.title{max-width:846px;}
    .col13 .ptl_approval ul dt.title{max-width:936px;}
    .col14 .ptl_approval ul dt.title{max-width:1026px;}
    .col15 .ptl_approval ul dt.title{max-width:1116px;}
    .col16 .ptl_approval ul dt.title{max-width:1206px;}
    .col17 .ptl_approval ul dt.title{max-width:1296px;}
    .col18 .ptl_approval ul dt.title{max-width:1386px;}
    .col19 .ptl_approval ul dt.title{max-width:1476px;}
    .col20 .ptl_approval ul dt.title{max-width:1566px;}
    // 전자결재 텍스트 (일반)
    .col3 .ptl_approval ul .nosign dt.title{max-width:125px;}
    .col4 .ptl_approval ul .nosign dt.title{max-width:170px;}
    .col5 .ptl_approval ul .nosign dt.title{max-width:260px;}
    .col6 .ptl_approval ul .nosign dt.title{max-width:350px;}
    .col7 .ptl_approval ul .nosign dt.title{max-width:440px;}
    .col8 .ptl_approval ul .nosign dt.title{max-width:530px;}
    .col9 .ptl_approval ul .nosign dt.title{max-width:620px;}
    .col10 .ptl_approval ul .nosign dt.title{max-width:710px;}
    .col11 .ptl_approval ul .nosign dt.title{max-width:800px;}
    .col12 .ptl_approval ul .nosign dt.title{max-width:890px;}
    .col13 .ptl_approval ul .nosign dt.title{max-width:980px;}
    .col14 .ptl_approval ul .nosign dt.title{max-width:1070px;}
    .col15 .ptl_approval ul .nosign dt.title{max-width:1160px;}
    .col16 .ptl_approval ul .nosign dt.title{max-width:1250px;}
    .col17 .ptl_approval ul .nosign dt.title{max-width:1340px;}
    .col18 .ptl_approval ul .nosign dt.title{max-width:1430px;}
    .col19 .ptl_approval ul .nosign dt.title{max-width:1520px;}
    .col20 .ptl_approval ul .nosign dt.title{max-width:1610px;}
    // 날씨
    .row1 .ptl_weather .ptl_weather_ico {float:left;width:54px;}
    .row2 .ptl_weather .ptl_weather_ico {float:left;width:74px;}
    .row3 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row4 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row5 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row6 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row7 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row8 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row9 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row10 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row11 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row12 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row13 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row14 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row15 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row16 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row17 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row18 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row19 .ptl_weather .ptl_weather_ico {float:left;width:94px;}
    .row20 .ptl_weather .ptl_weather_ico {float:left;width:94px;}

    .row1 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 54px);margin: 7px 0 0 0;}
    .row2 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 74px);margin: 15px 0 0 0;}
    .row3 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row4 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row5 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row6 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row7 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row8 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row9 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row10 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row11 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row12 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row13 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row14 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row15 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row16 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row17 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row18 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row19 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    .row20 .ptl_weather .ptl_weather_info {float:left;width:calc(100% - 94px);margin: 22px 0 0 0;}
    // 노트
    .col3 .ptl_note ul dt.title{max-width:105px;}
    .col4 .ptl_note ul dt.title{max-width:195px;}
    .col5 .ptl_note ul dt.title{max-width:285px;}
    .col6 .ptl_note ul dt.title{max-width:375px;}
    .col7 .ptl_note ul dt.title{max-width:465px;}
    .col8 .ptl_note ul dt.title{max-width:555px;}
    .col9 .ptl_note ul dt.title{max-width:645px;}
    .col10 .ptl_note ul dt.title{max-width:735px;}
    .col11 .ptl_note ul dt.title{max-width:825px;}
    .col12 .ptl_note ul dt.title{max-width:915px;}
    .col13 .ptl_note ul dt.title{max-width:1005px;}
    .col14 .ptl_note ul dt.title{max-width:1095px;}
    .col15 .ptl_note ul dt.title{max-width:1185px;}
    .col16 .ptl_note ul dt.title{max-width:1275px;}
    .col17 .ptl_note ul dt.title{max-width:1365px;}
    .col18 .ptl_note ul dt.title{max-width:1455px;}
    .col19 .ptl_note ul dt.title{max-width:1545px;}
    .col20 .ptl_note ul dt.title{max-width:1635px;}
    // 문서
    .col3 .ptl_doc ul dt.title{max-width:105px;}
    .col4 .ptl_doc ul dt.title{max-width:195px;}
    .col5 .ptl_doc ul dt.title{max-width:285px;}
    .col6 .ptl_doc ul dt.title{max-width:375px;}
    .col7 .ptl_doc ul dt.title{max-width:465px;}
    .col8 .ptl_doc ul dt.title{max-width:555px;}
    .col9 .ptl_doc ul dt.title{max-width:645px;}
    .col10 .ptl_doc ul dt.title{max-width:735px;}
    .col11 .ptl_doc ul dt.title{max-width:825px;}
    .col12 .ptl_doc ul dt.title{max-width:915px;}
    .col13 .ptl_doc ul dt.title{max-width:1005px;}
    .col14 .ptl_doc ul dt.title{max-width:1095px;}
    .col15 .ptl_doc ul dt.title{max-width:1185px;}
    .col16 .ptl_doc ul dt.title{max-width:1275px;}
    .col17 .ptl_doc ul dt.title{max-width:1365px;}
    .col18 .ptl_doc ul dt.title{max-width:1455px;}
    .col19 .ptl_doc ul dt.title{max-width:1545px;}
    .col20 .ptl_doc ul dt.title{max-width:1635px;}
    // 설문조사
    .row3 .ptl_survey .question{height:133px;}
    .row4 .ptl_survey .question{height:223px;}
    .row5 .ptl_survey .question{height:313px;}
    .row6 .ptl_survey .question{height:403px;}
    .row7 .ptl_survey .question{height:493px;}
    .row8 .ptl_survey .question{height:583px;}
    .row9 .ptl_survey .question{height:673px;}
    .row10 .ptl_survey .question{height:763px;}
    .row11 .ptl_survey .question{height:853px;}
    .row12 .ptl_survey .question{height:943px;}
    .row13 .ptl_survey .question{height:1033px;}
    .row14 .ptl_survey .question{height:1123px;}
    .row15 .ptl_survey .question{height:1213px;}
    .row16 .ptl_survey .question{height:1303px;}
    .row17 .ptl_survey .question{height:1393px;}
    .row18 .ptl_survey .question{height:1483px;}
    .row19 .ptl_survey .question{height:1573px;}
    .row20 .ptl_survey .question{height:1663px;}
    // 업무관리
    .col6 .ptl_wklist ul dt.title{width:240px;} 
    .col7 .ptl_wklist ul dt.title{width:330px;}  
    .col8 .ptl_wklist ul dt.title{width:420px;}  
    .col9 .ptl_wklist ul dt.title{width:510px;}  
    .col10 .ptl_wklist ul dt.title{width:600px;}
    .col11 .ptl_wklist ul dt.title{width:690px;}
    .col12 .ptl_wklist ul dt.title{width:780px;}
    .col13 .ptl_wklist ul dt.title{width:870px;}
    .col14 .ptl_wklist ul dt.title{width:960px;}
    .col15 .ptl_wklist ul dt.title{width:1050px;}
    .col16 .ptl_wklist ul dt.title{width:1140px;}
    .col17 .ptl_wklist ul dt.title{width:1230px;}
    .col18 .ptl_wklist ul dt.title{width:1320px;}
    .col19 .ptl_wklist ul dt.title{width:1410px;}
    .col20 .ptl_wklist ul dt.title{width:1500px;}
} 

/***************************** 
    검색 결과
******************************/
.newPortalSearchResultWrap{position: relative;width:100%;background: #ffffff;transition: top 0.5s cubic-bezier(0, 0, 0.2, 1);overflow: hidden;min-width: 1280px;
    .searchCategory{width:100%;height: 52px;background:#ffffff;border-bottom: 1px solid #e7e7e7;box-sizing:border-box;
        .searchCategory-in{position: relative;width: 1160px;margin:0 auto;padding: 0 80px 0 0;box-sizing: border-box;
            dl{white-space: nowrap;overflow: hidden;
                dd{display: inline-block;font-size:14px;font-weight:normal;height: 24px;line-height: 25px;margin: 14px 0 14px 16px;padding: 0;color: #000000;cursor: pointer;position: relative;transition:all 0.3s;
                    &:hover{color:#1c90fb}
                    &:first-child{margin:14px 0;}
                    &::after{content:"";position: absolute;left: 0;bottom: -14px;width: 0%;height: 3px;background: #1c90fb;transition:width 0.3s;}
                    &.on{color:#1c90fb}
                    &.on:after{content:"";position: absolute;left: 0;bottom: -14px;width: 100%;height: 3px;background: #1c90fb;transition:width 0.3s}
                }
            }
            .more{position: absolute;top: 14px;right: 0;font-size:14px;font-weight:normal;width: 50px;height: 24px;line-height: 22px;margin: 0;padding: 0 0 0 12px;color: #000000;cursor: pointer;background: url('../imgs/main/ico_searchCategory_more_arrow_down.png') no-repeat right;}
            .more_pop{position: absolute;bottom: 0;right: 0;width: 116px;height: auto;border: 1px solid #91969e;background: #ffffff;transform: translateY(100%);opacity: 0;transition: all 0.3s;z-index: 10;
                ul{
                    li{display: block;font-size:12px;font-weight:normal;padding: 11px 12px;cursor:pointer;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
                        &:hover{background:#f2f9ff}
                        &.on{background:#f2f9ff}
                    }                    
                }
            }
            &.on .more{background: url('../imgs/main/ico_searchCategory_more_arrow_up.png') no-repeat right;}
        }
        &.on .more_pop{opacity: 1;}
    }
    .relationKeyword{width:100%;height: 45px;overflow: hidden;background: #ffffff;border-bottom: 1px solid #e7e7e7;box-sizing: border-box;
        &.on{height:auto}
        .relationKeyword-in{position: relative;width: 1160px;margin: 0 auto;padding: 0 60px 0 0;box-sizing: border-box;overflow: hidden;
            dl{font-size: 0;width: 100%;padding: 0 0 0 80px;box-sizing: border-box;
                &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
                dt{position: absolute;top: 0;left: 0;font-size:14px;font-weight:bold;height: 24px;line-height: 25px;margin: 10px 12px 0 0;color: #000000;
                    &::after{content:"";position: absolute;top: 5px;right: -12px;width: 1px;height: 16px;background: #d7d7d7;}
                }
                dd{display: inline-block;font-size:14px;font-weight:normal;height: 24px;line-height: 25px;margin: 10px 0;padding: 0 4px;color: #1c90fb;cursor: pointer;position: relative;
                    &::after{content:",";margin: 0 5px 0 0;}
                    &:last-child:after{content:"";}
                    .link{position: relative;display: inline-block;outline:none;
                        &::after{content:"";position: absolute;left: 0;bottom: 0;width: 0%;height: 1px;background: #000000;transition: all 0.3s;}
                        &:hover:after{width:100%;transition: all 0.3s;}
                        &:focus:after{width:100%;transition: all 0.5s;}
                    }
                }
            }
            .more{position: absolute;top: 10px;right: 0;font-size:12px;font-weight:normal;letter-spacing: -1px;width: 56px;height: 24px;line-height: 22px;margin: 0;padding: 0 13px 0 6px;border: 1px solid #e1e1e1;border-radius: 3px;box-sizing: border-box;color: #000000;cursor: pointer;background: url('../imgs/main/ico_relationKeyword_more_arrow.png') no-repeat 41px 9px;transition: all 0.3s;
                &:hover{background: #ffffff url('../imgs/main/ico_relationKeyword_more_arrow.png') no-repeat 41px 9px;}
            }
        }
    }
    .searchContents{width: 1160px;height:100%;margin:0 auto;
        .groupBox{padding: 20px 0px 27px 0px;border-bottom: 1px solid #e7e7e7;box-sizing: border-box;overflow: hidden;
            &:last-child{border:none;padding: 20px 0 27px 0px;}
            .eadocList{margin:10px 0 0 0;
                .eventLink{position: relative;display: block;width: 369px;height: 40px;border-radius: 4px;border: solid 1px #ececec;margin: 4px 0 0 0;padding: 0 0 0 36px;box-sizing: border-box;font-size:14px;font-weight:normal;line-height:40px;background:#f7fafc url('../imgs/ico/ic_docu_m_normal.png') no-repeat top 11px left 15px;cursor:pointer;
                    &::after{content:'';position: absolute;top:9px;right:10px;width:18px;height:21px;background:url('../imgs/ico/ic_arrow_right_01_m_normal.png') no-repeat right 0px center;}
                    &:hover:after{content:'';position: absolute;top:9px;right:10px;width:18px;height:21px;background:url('../imgs/ico/ic_arrow_right_01_m_over.png') no-repeat right 0px center;}
                }
            }
            .boxList{margin:10px 0 0 0;
                .eventLink{position: relative;display: block;width: 369px;height: 40px;border-radius: 4px;border: solid 1px #ececec;margin: 4px 0 0 0;padding: 0 0 0 18px;box-sizing: border-box;font-size:14px;font-weight:normal;line-height:40px;background:#f7fafc;cursor:pointer;
                    &::after{content:'';position: absolute;top:9px;right:10px;width:18px;height:21px;background:url('../imgs/ico/ic_arrow_right_01_m_normal.png') no-repeat right 0px center;}
                    &:hover:after{content:'';position: absolute;top:9px;right:10px;width:18px;height:21px;background:url('../imgs/ico/ic_arrow_right_01_m_over.png') no-repeat right 0px center;}
                    b{margin:0 10px 0 0;}
                }
            }
        }
        &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}
        .wingTemp{float: left;width: 390px;min-height:1000px;height: 100%;margin: 0 0 0 -1px;padding: 0 0 0 20px;border-left: 1px solid #e7e7e7;box-sizing: border-box;
            .groupTitle{width: 100%;min-height: 20px;font-size: 0;
                b{position: relative;display: inline-block;font-size:14px;font-weight:bold;height: 20px;line-height: 20px;padding: 0 5px 0 0;
                    &.menu{padding: 0 5px 0 24px;background: url('../imgs/main/ico_wingTemp_menu.png') no-repeat left;}    
                    &.relation{padding: 0 5px 0 24px;background: url('../imgs/main/ico_wingTemp_relation.png') no-repeat left;}
                    &.eadoc{padding: 0 5px 0 24px;background: url('../imgs/main/ico_wingTemp_eadoc.png') no-repeat left;}
                    &.groupware{padding: 0 5px 0 24px;background: url('../imgs/main/ico_wingTemp_groupware.png') no-repeat left;}
                    &.recent{padding: 0 5px 0 24px;background: url('../imgs/main/ico_wingTemp_recent.png') no-repeat left;}
                }
                .cnt{display: inline-block;font-size:14px;font-weight:normal;height: 20px;line-height: 20px;color: #1c90fb;}
            }
            .link-mulien{display: block;width: 369px;height: 89px;margin: 20px 0 0 0;background: url('../imgs/temp/temp_mulien.png') no-repeat center;cursor: pointer;}
            .link-mulien.jpoc{display: block;width: 369px;height: 89px;margin: 20px 0 0 0;background: url('../imgs/temp/temp_mulien_jpoc.png') no-repeat center;cursor: pointer;}
            dl{padding: 20px 0 0 0;
                dt{font-size:13px;font-weight:bold;color: #000000;padding: 0 0 6px 0;
                    .cnt{display: inline-block;font-size:13px;font-weight:bold;height: 20px;line-height: 20px;color: #1c90fb;}
                }
                dd{padding: 0;line-height: 21px;
                    .link{position: relative;display: inline-block;font-size:13px;font-weight:normal;color: #000000;outline:none;cursor: pointer;
                        &::after{content:"";position: absolute;left: 0;bottom: 0;width: 0%;height: 1px;background: #000000;transition: all 0.5s;}
                        &:hover:after{width:100%;transition: all 0.5s;}
                        &:focus:after{width:100%;transition: all 0.5s;}
                    }
                    .match{color: #1c90fb;}
                }
            }
            ul{position: relative;margin: 0;overflow: hidden;text-align: left;font-size: 0;
                &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
                li{position: relative;display: inline-block;width: 58px;margin: 20px 19px 0 0;vertical-align: top;transition: all 0.5s;
                    &:nth-child(5n){margin:20px 0 0 0;}
                    &:hover{transform: translateY(-5px);margin: 20px 19px 0 0;padding: 0;transition:all 0.5s;}
                    &:nth-child(5n):hover{transform: translateY(-5px);margin: 20px 0 0 0;padding:0;transition:all 0.5s;}

                    // 인하우스 아이콘 그룹(검색) //
                    &.inHouse_ecoIcon{background:url('../imgs/main/ecoPlace/ic_default@2x.png') no-repeat center 0;background-size: 58px;}             // 기본아이콘
                    // 메일 그룹
                    &.ML_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_mail@2x.png') no-repeat center 0;background-size: 58px;}                   // 메일
                    &.ML_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_mail_write@2x.png') no-repeat center 0;background-size: 58px;}             // 메일쓰기
                    &.ML_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_mail_all@2x.png') no-repeat center 0;background-size: 58px;}               // 전체메일함
                    &.ML_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_mail_send@2x.png') no-repeat center 0;background-size: 58px;}              // 보낸메일함
                    &.ML_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_mail_receive@2x.png') no-repeat center 0;background-size: 58px;}           // 받은메일함
                    // 문자 그룹
                    &.SMS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sms@2x.png') no-repeat center 0;background-size: 58px;}                   // 문자
                    &.SMS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_sms_send@2x.png') no-repeat center 0;background-size: 58px;}              // 문자보내기
                    // 업무관리 그룹
                    &.KS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_kiss@2x.png') no-repeat center 0;background-size: 58px;}                   // 업무관리
                    &.KS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_mywork@2x.png') no-repeat center 0;background-size: 58px;}                 // 나의업무
                    &.KS_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_project_star@2x.png') no-repeat center 0;background-size: 58px;}           // 나의프로젝트
                    // 일정 그룹
                    &.CL_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_calendar@2x.png') no-repeat center 0;background-size: 58px;}               // 일정
                    &.CL_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_rm@2x.png') no-repeat center 0;background-size: 58px;}                     // 자원
                    // 임직원업무관리 그룹
                    &.HR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_hr@2x.png') no-repeat center 0;background-size: 58px;}                     // 임직원업무관리
                    &.HR_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_attendance_write@2x.png') no-repeat center 0;background-size: 58px;}       // 근태신청
                    &.HR_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_attendance_cancel@2x.png') no-repeat center 0;background-size: 58px;}      // 근태신청취소
                    &.HR_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_paystub@2x.png') no-repeat center 0;background-size: 58px;}                // 급여명세서
                    &.HR_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_business_hours_agree@2x.png') no-repeat center 0;background-size: 58px;}   // 개인근무시간동의
                    &.HR_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_address_book@2x.png') no-repeat center 0;background-size: 58px;}           // 전체주소록
                    &.HR_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_report_send@2x.png') no-repeat center 0;background-size: 58px;}            // 보낸보고함
                    &.HR_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_report_receive@2x.png') no-repeat center 0;background-size: 58px;}         // 받은보고함
                    &.HR_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_memo@2x.png') no-repeat center 0;background-size: 58px;}                   // 노트
                    &.HR_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_expense@2x.png') no-repeat center 0;background-size: 58px;}                // 경비취합
                    &.HR_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_expense_write@2x.png') no-repeat center 0;background-size: 58px;}          // 경비청구작성
                    &.HR_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_sign_hr_write@2x.png') no-repeat center 0;background-size: 58px;}          // 지출결의서작성
                    &.HR_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_approval_write@2x.png') no-repeat center 0;background-size: 58px;}         // 품의서작성
                    &.HR_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat center 0;background-size: 58px;}                 // 개인인사정보조회
                    
                    // 전자결재 그룹
                    &.EA_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sign@2x.png') no-repeat center 0;background-size: 58px;}                   // 전자결재
                    &.EA_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_signhome@2x.png') no-repeat center 0;background-size: 58px;}               // 결재홈
                    &.EA_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_sign_write@2x.png') no-repeat center 0;background-size: 58px;}             // 결재작성
                    // 팩스 그룹
                    &.FAX_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_fax@2x.png') no-repeat center 0;background-size: 58px;}                   // 팩스
                    &.FAX_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_fax_send@2x.png') no-repeat center 0;background-size: 58px;}              // 팩스보내기
                    &.FAX_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_fax_receive@2x.png') no-repeat center 0;background-size: 58px;}           // 받은팩스함
                    // 원피스 그룹
                    &.OF_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_oneffice@2x.png') no-repeat center 0;background-size: 58px;}               // 원피스
                    // 원챔버 그룹
                    &.OC_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_onechamber@2x.png') no-repeat center 0;background-size: 58px;}             // 원챔버
                    &.OC_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_my@2x.png') no-repeat center 0;background-size: 58px;}                     // 내문서함
                    &.OC_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_docuflow@2x.png') no-repeat center 0;background-size: 58px;}               // 도큐플로우
                    // 회계 그룹
                    &.FI_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_account@2x.png') no-repeat center 0;background-size: 58px;}                // 회계관리
                    &.FI_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_nonprofit@2x.png') no-repeat center 0;background-size: 58px;}              // 예산관리
                    // 인사근태 그룹
                    &.HM_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_human@2x.png') no-repeat center 0;background-size: 58px;}                  // 인사관리
                    // 게시판 그룹
                    &.BD_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_board@2x.png') no-repeat center 0;background-size: 58px;}                  // 게시판
                    // 확장기능 그룹
                    &.EX_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_user_plus@2x.png') no-repeat center 0;background-size: 58px;}              // 방문객등록
                    // 총무구매 그룹 
                    &.GSM_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_supplies_write.png') no-repeat center 0;background-size: 58px;}           // 사무용품신청
                    &.GSM_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_supplies_manage.png') no-repeat center 0;background-size: 58px;}          // 사무용품신청관리
                    &.GSM_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_supplies_order.png') no-repeat center 0;background-size: 58px;}           // 사무용품주문관리
                    &.GSM_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_supplies_calculate.png') no-repeat center 0;background-size: 58px;}       // 사무용품정산관리
                    // 통합주소록 그룹
                    &.ADDR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_total_address@2x.png') no-repeat center 0;background-size: 58px;}       // 통합주소록
                    // HIS 그룹
                    &.HIS_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_inhouse01@2x.png') no-repeat center 0;background-size: 58px;}        // 원무(HIS전용)
                    &.HIS_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_inhouse02@2x.png') no-repeat center 0;background-size: 58px;}        // 진료(HIS전용)
                    &.HIS_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_inhouse03@2x.png') no-repeat center 0;background-size: 58px;}        // 진료지원(HIS전용)
                    &.HIS_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_inhouse04@2x.png') no-repeat center 0;background-size: 58px;}        // 보험심사(HIS전용)
                    &.HIS_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_inhouse05@2x.png') no-repeat center 0;background-size: 58px;}        // 통계(HIS전용)
                    &.HIS_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_inhouse07@2x.png') no-repeat center 0;background-size: 58px;}        // 기초코드(HIS전용)
                    &.HIS_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_inhouse08@2x.png') no-repeat center 0;background-size: 58px;}        // 서식(HIS전용)
                    &.HIS_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_inhouse09@2x.png') no-repeat center 0;background-size: 58px;}        // 재고관리(HIS전용)
                    &.HIS_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_inhouse10@2x.png') no-repeat center 0;background-size: 58px;}        // 병원정보설정(HIS전용)
                    &.HIS_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_inhouse11@2x.png') no-repeat center 0;background-size: 58px;}        // 간편 세무 회계(HIS전용)
                    &.HIS_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_education@3x.png') no-repeat center 0;background-size: 58px;}        // 법정교육(HIS전용)
                    &.HIS_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_labor@3x.png') no-repeat center 0;background-size: 58px;}            // 법무노무(HIS전용)
                    &.HIS_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_theporterzone@3x.png') no-repeat center 0;background-size: 58px;}    // 더포터존(HIS전용)
                    &.HIS_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_industrial@2x.png') no-repeat center 0;background-size: 58px;}       // 공단검진(HIS전용)
                    &.HIS_ecoIcon15{background:url('../imgs/main/ecoPlace/ic_policy@2x.png') no-repeat center 0;background-size: 58px;}           // 정책고시(HIS전용)
                    &.HIS_ecoIcon16{background:url('../imgs/main/ecoPlace/ic_notice@2x.png') no-repeat center 0;background-size: 58px;}           // 공지사항(HIS전용)
                    // 로테크 그룹
                    &.UR_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_total_lawtech@2x.png') no-repeat center 0;background-size: 58px;}     // 로테크
                    // 커스텀 그룹 (패키지 X )
                    &.Custom_ecoIcon01{background:url('../imgs/main/ecoPlace/ic_sales@2x.png') no-repeat center 0;background-size: 58px;}              // 매출관리
                    &.Custom_ecoIcon02{background:url('../imgs/main/ecoPlace/ic_manage_account@2x.png') no-repeat center 0;background-size: 58px;}     // 계좌관리
                    &.Custom_ecoIcon03{background:url('../imgs/main/ecoPlace/ic_salary@2x.png') no-repeat center 0;background-size: 58px;}             // 급여관리
                    &.Custom_ecoIcon04{background:url('../imgs/main/ecoPlace/ic_fund@2x.png') no-repeat center 0;background-size: 58px;}               // 자금관리
                    &.Custom_ecoIcon05{background:url('../imgs/main/ecoPlace/ic_corporation_card@2x.png') no-repeat center 0;background-size: 58px;}   // 법인카드
                    &.Custom_ecoIcon06{background:url('../imgs/main/ecoPlace/ic_spend@2x.png') no-repeat center 0;background-size: 58px;}              // 지출결의
                    &.Custom_ecoIcon07{background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat center 0;background-size: 58px;}             // 마이페이지
                    &.Custom_ecoIcon08{background:url('../imgs/main/ecoPlace/ic_work_report@2x.png') no-repeat center 0;background-size: 58px;}        // 업무보고
                    &.Custom_ecoIcon09{background:url('../imgs/main/ecoPlace/ic_keep@2x.png') no-repeat center 0;background-size: 58px;}               // KEEP
                    &.Custom_ecoIcon10{background:url('../imgs/main/ecoPlace/ic_attendance@2x.png') no-repeat center 0;background-size: 58px;}         // 근태관리
                    &.Custom_ecoIcon11{background:url('../imgs/main/ecoPlace/ic_crm@2x.png') no-repeat center 0;background-size: 58px;}                // CRM
                    &.Custom_ecoIcon12{background:url('../imgs/main/ecoPlace/ic_business_hours@2x.png') no-repeat center 0;background-size: 58px;}     // 근무시간현황
                    &.Custom_ecoIcon13{background:url('../imgs/main/ecoPlace/ic_distri@2x.png') no-repeat center 0;background-size: 58px;}             // 물류
                    &.Custom_ecoIcon14{background:url('../imgs/main/ecoPlace/ic_produce@2x.png') no-repeat center 0;background-size: 58px;}            // 생산관리
                    &.Custom_ecoIcon15{background:url('../imgs/main/ecoPlace/ic_buy_write@2x.png') no-repeat center 0;background-size: 58px;}          // 구매신청
                    &.Custom_ecoIcon16{background:url('../imgs/main/ecoPlace/ic_portal@2x.png') no-repeat center 0;background-size: 58px;}             // 포털
                    &.Custom_ecoIcon17{background:url('../imgs/main/ecoPlace/ic_ex@2x.png') no-repeat center 0;background-size: 58px;}                 // 확장기능
                    &.Custom_ecoIcon18{background:url('../imgs/main/ecoPlace/ic_video@2x.png') no-repeat center 0;background-size: 58px;}              // 동영상보기    
                    &.Custom_ecoIcon19{background:url('../imgs/main/ecoPlace/ic_online_experience@2x.png') no-repeat center 0;background-size: 58px;}  // 온라인체험관
                    &.Custom_ecoIcon20{background:url('../imgs/main/ecoPlace/ic_system_setting@2x.png') no-repeat center 0;background-size: 58px;}     // 시스템환경설정
                    &.Custom_ecoIcon21{background:url('../imgs/main/ecoPlace/ic_process_management@2x.png') no-repeat center 0;background-size: 58px;} // 프로세스관리
                    &.Custom_ecoIcon22{background:url('../imgs/main/ecoPlace/ic_wehago@2x.png') no-repeat center 0;background-size: 58px;}             // 위하고
                    &.Custom_ecoIcon23{background:url('../imgs/main/ecoPlace/ic_wiki@2x.png') no-repeat center 0;background-size: 58px;}               // 위키
                    &.Custom_ecoIcon24{background:url('../imgs/main/ecoPlace/ic_jira@2x.png') no-repeat center 0;background-size: 58px;}               // 지라
                    &.Custom_ecoIcon25{background:url('../imgs/main/ecoPlace/ic_gcms@2x.png') no-repeat center 0;background-size: 58px;}               // GCMS
                    &.Custom_ecoIcon26{background:url('../imgs/main/ecoPlace/ic_skt_meetus@2x.png') no-repeat center 0;background-size: 58px;}         // SKT MeetUs
                    &.Custom_ecoIcon27{background:url('../imgs/main/ecoPlace/ic_nsm_10@2x.png') no-repeat center 0;background-size: 58px;}             // NSM10
                    &.Custom_ecoIcon28{background:url('../imgs/main/ecoPlace/ic_erp_10@2x.png') no-repeat center 0;background-size: 58px;}             // ERP10
                    &.Custom_ecoIcon29{background:url('../imgs/main/ecoPlace/ic_amaranth_10@2x.png') no-repeat center 0;background-size: 58px;}        // Amaranth10
                    &.Custom_ecoIcon30{background:url('../imgs/main/ecoPlace/ic_icube@2x.png') no-repeat center 0;background-size: 58px;}              // iCUBE
                    &.Custom_ecoIcon31{background:url('../imgs/main/ecoPlace/ic_cor_sol@2x.png') no-repeat center 0;background-size: 58px;}            // 기업금융관리
                    &.Custom_ecoIcon32{background:url('../imgs/main/ecoPlace/ic_sol@2x.png') no-repeat center 0;background-size: 58px;}                // 개인금융관리
                    &.Custom_ecoIcon33{background:url('../imgs/main/ecoPlace/ic_support@2x.png') no-repeat center 0;background-size: 58px;}            // 서포트
                    &.Custom_ecoIcon34{background:url('../imgs/main/ecoPlace/ic_smartlink.png') no-repeat center 0;background-size: 58px;}             // 스마트링크
                    &.Custom_ecoIcon35{background:url('../imgs/main/ecoPlace/ic_whistlenote@2x.png') no-repeat center 0;background-size: 58px;}        // 휘슬노트
                    &.Custom_ecoIcon36{background:url('../imgs/main/ecoPlace/ic_redcaptour@2x.png') no-repeat center 0;background-size: 58px;}         // 레드캡투어
                    &.Custom_ecoIcon37{background:url('../imgs/main/ecoPlace/ic_logispot@2x.png') no-repeat center 0;background-size: 58px;}           // 로지스팟

                    .link{display: block;width: 58px;padding: 68px 0 20px;font-size:12px;font-weight:normal;line-height:16px;cursor: pointer;color: #000;text-align: center;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                    .link::after{display: none;}

                    .itemCnt{position: absolute;top: -3px;right: -3px;min-width: 16px;height: 16px;padding: 2px 3px 0 3px;font-family: '돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif;font-size: 11px;color: #ffffff;text-align: center;border-radius: 10px;background: #fc5356;box-sizing: border-box;}
                }
            }
        }
        .searchTemp{float: left;width: 770px;height: 100%;padding: 0 20px 120px 0;box-sizing: border-box;border-right: 1px solid #e7e7e7;
            .groupTitle{width: 100%;min-height: 20px;font-size: 0;padding: 20px 0;
                b{position: relative;display: inline-block;font-size:15px;font-weight:bold;line-height:20px;height: 20px;padding: 0 15px 0 0;
                    &::after{content:"";position: absolute;top: 3px;right: 7px;width: 1px;height: 15px;background: #d9d9d9;}
                }
                .textResult{font-size:15px;font-weight:bold;line-height:20px;color: #000000;
                    .cnt{display: inline-block;font-size:15px;font-weight:bold;line-height:20px;height: 20px;color: #1c90fb;}
                    .matching{display: inline-block;font-size:15px;font-weight:bold;line-height:20px;height: 20px;color: #1d90fb;
                        *{vertical-align: top;}
                        .ellipsis{display: inline-block;max-width: 680px;}
                    }
                    .matching + .cnt{display: inline-block;font-size:15px;font-weight:bold;line-height:20px;height: 20px;color: #1c90fb;}                    
                }
            }
            // 통합검색 타이틀 공통
            .result-title{position: relative;font-size:14px;font-weight:bold;line-height:28px;color:#2e3645;border-bottom:1px solid #ececec;
                .cnt{color:#1c90fb;margin:0 0 0 5px;}
                .result-moreBtn{position: absolute;top:0;right:0;width:18px;height:28px;background:url('../imgs/ico/ic_arrow_right_01_m_normal.png') no-repeat right -5px center;cursor: pointer;
                    &:hover{background:url('../imgs/ico/ic_arrow_right_01_m_over.png') no-repeat right -5px center;}
                }
                .result-prevBtn{position: absolute;top:0;right:0;width:18px;height:28px;background:url('../imgs/ico/ic_arrow_left_01_m_normal.png') no-repeat right -5px center;cursor: pointer;
                    &:hover{background:url('../imgs/ico/ic_arrow_left_01_m_over.png') no-repeat right -5px center;}
                }
            }
            .match{color:#1c90fb;}

            // 전자결재
            .result-ea{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_approval.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .title{display:inline-block;max-width:650px;font-weight:bold;font-size:13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #eff8f9;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{padding:0 0 0 18px;background:url('../imgs/ico/ic_proval_s_normal.png') no-repeat left 1px center;}
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                }
            }
            // 메일
            .result-ml{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_mail.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .tag{display: inline-block;min-width: 60px;height: 20px;margin: 0 5px 0 0;padding:1px 6px 2px 6px;border: 1px solid #8c919a;border-radius: 20px;box-sizing: border-box;font-size: 11px;color: rgba(0,0,0,0.6);vertical-align: top;text-align: center;}
                                .title{display: inline-block;max-width: 580px;font-weight: bold;font-size: 13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                                .date{position: absolute;top: 1px;right: 0;font-size:13px;color: rgba(0, 0, 0, 0.5);text-align: right;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #f2f3fe;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{padding:0;}
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }
            // 일정
            .result-cl{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_schedule.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .tag{display: inline-block;min-width: 50px;height: 20px;margin: 0 5px 0 0;padding:1px 6px 2px 6px;border: 1px solid #8c919a;border-radius: 20px;box-sizing: border-box;font-size: 11px;color: rgba(0,0,0,0.6);vertical-align: top;text-align: center;}
                                .title{display: inline-block;max-width: 600px;font-weight: bold;font-size: 13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #fdf2ea;}
                            }
                            .listBot{
                                .infoMisc{font-size:11px;color:rgba(0,0,0,0.6);}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }            
            // 업무관리
            .result-wk{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_project.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .title{display:inline-block;max-width:730px;font-weight:bold;font-size:13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #fcf8eb;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{
                                    &.wk{padding:0 0 0 18px;background:url('../imgs/ico/ic_work_s_normal.png') no-repeat left 1px center;}
                                    &.td{padding:0 0 0 18px;background:url('../imgs/ico/ic_work_check_s_normal.png') no-repeat left 1px center;}
                                    &.pj{padding:0 0 0 18px;background:url('../imgs/ico/ic_folder_s_normal.png') no-repeat left 1px center;}
                                }
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }
            // 게시판
            .result-bd{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_board.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .title{display:inline-block;max-width:730px;font-weight:bold;font-size:13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #f3f5f9;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{padding:0 0 0 18px;background:url('../imgs/ico/ic_survey_s_normal.png') no-repeat left 1px center;}
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }
            // 업무보고
            .result-wr{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_projectreport.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0;
                                .tag{display: inline-block;min-width: 30px;height: 20px;margin: 0 5px 0 0;padding:1px 6px 2px 6px;border: 1px solid #8c919a;border-radius: 20px;box-sizing: border-box;font-size: 11px;color: rgba(0,0,0,0.6);vertical-align: top;text-align: center;}
                                .title{display:inline-block;max-width:700px;font-weight:bold;font-size:13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{padding:0;}
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }
            // 노트
            .result-nt{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_note.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .title{display:inline-block;max-width:730px;font-weight:bold;font-size:13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #f2f3fe;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{padding:0 0 0 18px;background:url('../imgs/ico/ic_note_s_normal.png') no-repeat left 1px center;}
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }
            // 인물
            .result-us{margin:30px 0 0 0; 
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_user.png') no-repeat left top;} 
                .result-wrap{padding-top: 10px; box-sizing: border-box; 
                    .result-box{width: 100%; position: relative; 
                        // 뱃지 
                        .result_badge {max-width: 60px; height: 18px; margin: 3px 0 0 0; padding: 0 10px;border-radius: 10px;font-size:11px;font-weight:normal;line-height:18px;color: #ffffff;background: #9da3aa;} 
 
                        &::after {content:"";display:block;clear:both;height:0;visibility:hidden;} 
                        li.item{position: relative; float: left;width:370px;height: 194px; border:1px solid #dcdcdc;border-radius: 3px;box-sizing:border-box;background: #ffffff;margin:5px 9px 5px 0;box-shadow:0 5px 3px 0 rgba(217, 215, 231, 0.13); 
                            .user-user{margin:0 20px; width: 94px;justify-content:center;align-items:center;cursor: pointer; 
                                .user-pic{width:46px;height:46px;border-radius:46px;margin: 0 auto; background:url('../imgs/main/pic_no_img.png') no-repeat top center;background-size: 46px;overflow:hidden; 
                                    img{width:100%;vertical-align: top;} 
                                } 
                                .nameBox{width:100%;margin-top: 6px;text-align: center; 
                                    .user-name{font-size: 13px; font-weight:bold;color: #000;} 
                                    .user-position{font-size: 11px; font-weight:normal;color: #000;} 
                                    .user-group{font-size: 11px; font-weight:normal;color: #000;opacity: 0.5;} 
                                } 
                            } 
                            .user-infor{font-size:11px;color:#4a4a4a;text-align:left; vertical-align: middle; padding: 10px 0;line-height:18px; 
                                .user-number{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_phone_01_s_normal@2x.png') no-repeat left 0px center;background-size: 14px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;} 
                                .user-phone{margin:5px 0; padding:0 0 0 20px;background:url("../imgs/ico/ic_mobile_s_normal@2x.png") no-repeat left 0px center;background-size: 14px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;} 
                                .user-email{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_mail_s_normal@2x.png') no-repeat left 0px center;background-size: 14px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;color: #1c90fb;} 
                                .user-info01{margin:5px 0; padding:0 0 0 20px;background:url("../imgs/ico/ic_pc_s_normal@2x.png") no-repeat left 0px center;background-size: 14px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;} 
                                .user-info02{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_career_m_normal@2x.png') no-repeat left 0px center;background-size: 14px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden; 
                                    .linkText{color: #1c90fb;text-decoration:underline;cursor: pointer;} 
                                } 
                            } 
                            .user-detailBtn{ 
                                display: block;height: 32px;line-height: 32px;margin:0;padding: 0 10px 0 0;border-top: 1px solid #ececec;border-radius: 0 0 3px 3px;box-sizing: border-box; 
                                font-size: 11px;color: #000000;text-align: center; 
                                background:#ffffff url('../imgs/main/ico_searchCategory_more_arrow_down.png') no-repeat right 158px center;cursor: pointer; 
                            } 
                        } 
                        li.item:nth-child(2n){margin:5px 0 5px 0;} 
                        li.item.on{border:1px solid #1c90fb;box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);background:#f7fafc; 
                            .user-detailBtn{ 
                                display: block;height: 32px;line-height: 32px;margin:0;padding: 0 10px 0 0;border-top: 1px solid #ececec;border-radius: 0 0 3px 3px;box-sizing: border-box; 
                                font-size: 11px;color: #000000;text-align: center; 
                                background:#ffffff url('../imgs/main/ico_searchCategory_more_arrow_up.png') no-repeat right 158px center;cursor: pointer; 
                            } 
                        } 
                    } 
                    .result-details{position: relative; margin:5px 0 0 0;padding: 10px 20px 15px 20px;box-sizing: border-box;border: 1px solid #ececec;border-radius: 3px;background-color: #fafafa; 
                        .result-details-title{position: relative;font-size:15px;font-weight:bold;line-height:21px;color:#2e3645;margin:10px 0; 
                            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;} 
                            .cnt{color:#1c90fb;} 
                        } 
                        .result-details-tab{position: relative;z-index:0; 
                            .tab > div > div > div{white-space: nowrap;} 
                            .tab ul div > li > button{padding:0 15px !important;min-width:80px !important;} 
                            .tab ul div:first-child > li > button span:first-child{background: transparent !important;} 
                            .tab ul div > li > button span:first-child{top:8px !important;height:10px;background: #cdcdd7 !important;} 
                            .tab ul div > li > button span:last-child{font-size:13px !important;} 
 
                            .more{position: absolute;top: 4px;right: 0px;font-size:13px;font-weight:normal;width: 48px;height: 24px;line-height: 22px;margin: 0;padding: 0 0 0 12px;color: #000000;cursor: pointer;background: url('../imgs/main/ico_searchCategory_more_arrow_down.png') no-repeat right 8px;} 
                            .more_pop{position: absolute;top: 0;right: 0;width: 100px;height: auto;border: 1px solid #91969e;background: #ffffff;transform: translateY(60%);opacity: 0;transition: all 0.3s;z-index: 10; 
                                ul{ 
                                    li{display: block;font-size:12px;font-weight:normal;padding: 5px 12px;cursor:pointer;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; 
                                        &:hover{background:#f2f9ff} 
                                        &.on{background:#f2f9ff} 
                                    }                     
                                } 
                            } 
                            &.on .more{background: url('../imgs/main/ico_searchCategory_more_arrow_up.png') no-repeat right 8px;} 
                            &.on .more_pop{opacity: 1;} 
                            .result-title{margin:0; 
                                .result-moreBtn{display: none;} 
                            } 
                            .result-box{margin:15px 0;padding:0;background: transparent; 
                                .text{white-space: normal;} 
                            } 
 
                            // 전자결재 
                            .result-ea{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 메일 
                            .result-ml{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 일정 
                            .result-cl{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 업무 
                            .result-wk{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 게시판 
                            .result-bd{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 업무보고 
                            .result-wr{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 노트 
                            .result-nt{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 원피스 
                            .result-of{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                                .result-box{ 
                                    li.item:nth-child(5n){margin:5px 9px 5px 0;} 
                                } 
                            } 
                            // 첨부파일 
                            .result-at{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                                .result-box-wrap{margin:10px 0 0 0; 
                                    .result-box{margin:5px 0 15px 0; 
                                        li.item:nth-child(5n){margin:5px 9px 5px 0;} 
                                    } 
                                } 
                            } 
                            // kiss 
                            .result-ks{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 자원 
                            .result-rm{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
                            // 원챔버 
                            .result-oc{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                                .result-box-wrap{margin:10px 0 0 0; 
                                    .result-box{margin:5px 0 15px 0; 
                                        li.item:nth-child(5n){margin:5px 9px 5px 0;} 
                                    } 
                                } 
                                .result-box{ 
                                    li.item:nth-child(5n){margin:5px 9px 5px 0;} 
                                } 
                            } 
                            // crm 
                            .result-crm{margin:0; 
                                .result-title{display: none;} 
                                .result-cnt{display:block} 
                            } 
 
                            // 데이터없을때 
                            .no_data{min-height:200px;} 
                        } 
                        .result-details-pager{border-top:1px solid rgba(0,0,0,0.1);padding:20px 0 10px 0;} 
                    } 
                }
            }
            // 원피스
            .result-of{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_oneffice.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:10px 0 0 0;box-sizing: border-box;
                    &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
                    li.item{position: relative;float:left;width:142px;height:196px;border:1px solid #dcdcdc;border-radius: 3px;box-sizing:border-box;background: #ffffff;margin:5px 9px 5px 0;box-shadow:0 5px 3px 0 rgba(217, 215, 231, 0.13);
                        .document-pic{width: 124px;height: 176px;margin: 10px 8px;border: 1px solid #f1f1f1;overflow: hidden;box-sizing: border-box;
                            img{width:100%;height:100%;}
                        }
                        .infoBox{position: absolute;bottom: 0;width: 100%;height: 72px;border-top: 1px solid #dbe6f1;box-sizing: border-box;background:#f0f5fa;border-radius:0 0 4px 4px;
                            .file{height:45px;padding:10px 8px 0 8px;box-sizing:border-box;overflow:hidden;
                                .text{font-size:12px;font-weight:normal;line-height:16px;color: #696b83;word-break: break-all;
                                    .tag{display:inline-block;margin:0 4px 0 0;padding: 0 3px;border-radius: 9px;border: solid 1px #42a1ff;font-size:9px;font-weight:normal;line-height:14px;color: #42a1ff;}
                                    &:hover{text-decoration: underline;cursor: pointer;}
                                }
                            }
                            .date{padding: 3px 10px 2px 10px;font-size:11px;font-weight:normal;line-height:20px;color: rgba(0,0,0,0.5);}
                            .date.receive{background: url('../imgs/ico/icon_person_recieve.png') no-repeat top 3px right 10px;}
                            .date.sent{background: url('../imgs/ico/icon_person_send.png') no-repeat top 3px right 10px;}
                        }
                    }
                    li.item:nth-child(5n){margin:5px 0 5px 0;}
                }
            }
            // 첨부파일
            .result-at{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_file.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }                
            }
            // KISS
            .result-ks{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_kiss.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .tag{display: inline-block;min-width: 50px;height: 20px;margin: 0 5px 0 0;padding:1px 6px 2px 6px;border: 1px solid #8c919a;border-radius: 20px;box-sizing: border-box;font-size: 11px;color: rgba(0,0,0,0.6);vertical-align: top;text-align: center;}
                                .title{display:inline-block;max-width:730px;font-weight:bold;font-size:13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #fcf8eb;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{padding:0;}
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }
            // 자원
            .result-rm{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_resource.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
                .result-box{padding:15px 0 0 0;box-sizing: border-box;
                    li{padding:20px 0 0 0;
                        &:first-child{padding:0;}
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center;
                            img{height:100%;}
                        }
                        .listBox{
                            .listTop{position: relative;padding:0 0 5px 0;
                                .tag{display: inline-block;min-width: 50px;height: 20px;margin: 0 5px 0 0;padding:1px 6px 2px 6px;border: 1px solid #8c919a;border-radius: 20px;box-sizing: border-box;font-size: 11px;color: rgba(0,0,0,0.6);vertical-align: top;text-align: center;}
                                .title{display: inline-block;max-width: 600px;font-weight: bold;font-size: 13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .listCon{position: relative;padding:0 0 5px 0;
                                .text{font-size:13px;word-break: break-all;}
                                .line-clamp:after{width:15px; background : #fdf2ea;}
                            }
                            .listBot{
                                .infoMisc{position: relative;display:inline-block;font-size:11px;color:rgba(0,0,0,0.6);padding:0 0 0 10px;}
                                .infoMisc:first-child{padding:0;}
                                .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                .infoMisc:first-child::before{display:none;}
                            }
                        }
                    }
                    li:first-child{border:none;}
                }
            }
            // 원챔버
            .result-oc{margin:30px 0 0 0;
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_oc.png') no-repeat left top;}
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    .cnt{color:#1c90fb;}
                }
            }
            // 첨부파일, 원챔버 공통 (썸네일 형식 탭)
            .result-box-wrap{position: relative;box-sizing: border-box;z-index: 0;
                .attachTab{position: relative;margin: 16px 0 0 0;background: #ffffff;overflow: hidden;}
                .attachTab .attachTab_in{position: relative;height: 25px;border-bottom: 1px solid #b2b2b2;box-sizing: border-box;}
                .attachTab .attachTab_in ul{padding:0;}
                .attachTab .attachTab_in ul li{position: relative;min-width: 80px;text-align: center;vertical-align: top;box-sizing: border-box;}
                .attachTab .attachTab_in ul li.on{border: 1px solid #b2b2b2;border-bottom: 1px solid #ffffff;}
                .attachTab .attachTab_in ul li span.txt{position:relative;display: block;height: 24px;font-size:12px;font-weight:normal;line-height:24px;color: rgba(0,0,0,0.5);box-sizing: border-box;cursor: pointer;}
                .attachTab .attachTab_in ul li.on span.txt{color:#000000;background:#ffffff;}
                .attachTab .attachTab_in ul li span.txt:after{content:'';position: absolute;top:7px;right:-1px;width:1px;height:10px;background-color: rgba(0, 0, 0, 0.1);}
                .attachTab .attachTab_in ul li.on span.txt:after{display:none;}
                .result-box{padding:7px 0 0 0;
                    &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
                    li.item{position: relative;float:left;width:142px;height:196px;border:1px solid #dcdcdc;border-radius: 3px;box-sizing:border-box;background: #ffffff;margin:5px 9px 5px 0;box-shadow:0 5px 3px 0 rgba(217, 215, 231, 0.13);
                        .title-box{width:100%;height:38px;padding:10px 8px;box-sizing: border-box;
                            .title{display: block;max-width: 124px;font-size:12px;font-weight:bold;line-height:18px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                        }
                        .file-box{position: relative;width: 122px;height: 156px;margin: 0 auto;overflow: hidden;
                            .file-thumb{width: 100%;height: 86px;border: 1px solid #e6e6e6;background-color: #fafafa;box-sizing: border-box;overflow: hidden;
                                .file-view{
                                    &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}
                                    .file-icon{width: 30px;height: 84px;margin: 0 auto;padding: 24px 0;box-sizing: border-box;}
                                    .file-image{position: relative;width: 120px;height: 86px;margin: 0 auto;overflow: hidden;
                                        .play{position: absolute;top:0;left:0;right:0;bottom:0;background:url('../imgs/main/bg_searchResult_playDim.png') no-repeat center;background-size: cover;cursor: pointer;}
                                        img{width:100%;}
                                    }
                                }
                            }
                            .file-info{position: relative;
                                .file{height:45px;padding:10px 0 0 0;box-sizing:border-box;overflow:hidden;
                                    .text{font-size:12px;font-weight:normal;line-height:16px;color: #696b83;word-break: break-all;
                                        &:hover{text-decoration: underline;cursor: pointer;}
                                        .tag{display:inline-block;margin:0 4px 0 0;padding: 0 3px;border-radius: 9px;font-size:9px;font-weight:normal;line-height:14px;
                                            &.pdf{color: #dc3e45;border: solid 1px #dc3e45;}
                                            &.ppt{color: #eb5e41;border: solid 1px #eb5e41;}
                                            &.pptx{color: #eb5e41;border: solid 1px #eb5e41;}
                                            &.xls{color: #32964f;border: solid 1px #32964f;}
                                            &.xlsx{color: #32964f;border: solid 1px #32964f;}
                                            &.one{color: #2a9cfd;border: solid 1px #2a9cfd;}
                                            &.doc{color: #2a9cfd;border: solid 1px #2a9cfd;}
                                            &.word{color: #2a9cfd;border: solid 1px #2a9cfd;}
                                            &.docx{color: #2a9cfd;border: solid 1px #2a9cfd;}
                                            &.hwp{color: #2a9cfd;border: solid 1px #2a9cfd;}
                                            &.gul{color: #2a9cfd;border: solid 1px #2a9cfd;}
                                            &.jpg{color: #f2ae00;border: solid 1px #f2ae00;}
                                            &.gif{color: #f2ae00;border: solid 1px #f2ae00;}
                                            &.png{color: #f2ae00;border: solid 1px #f2ae00;}
                                            &.img{color: #f2ae00;border: solid 1px #f2ae00;}
                                            &.tif{color: #f2ae00;border: solid 1px #f2ae00;}
                                            &.bmp{color: #f2ae00;border: solid 1px #f2ae00;}
                                            &.mp4{color: #624aa6;border: solid 1px #624aa6;}
                                            &.mov{color: #624aa6;border: solid 1px #624aa6;}
                                            &.mpg{color: #624aa6;border: solid 1px #624aa6;}
                                            &.mpeg{color: #624aa6;border: solid 1px #624aa6;}
                                            &.asf{color: #624aa6;border: solid 1px #624aa6;}
                                            &.wmv{color: #624aa6;border: solid 1px #624aa6;}
                                            &.avi{color: #624aa6;border: solid 1px #624aa6;}
                                            &.zip{color: #8f8680;border: solid 1px #8f8680;}
                                            &.html{color: #4ebfbd;border: solid 1px #4ebfbd;}
                                            &.htm{color: #4ebfbd;border: solid 1px #4ebfbd;}
                                            &.txt{color: #919191;border: solid 1px #919191;}
                                            &.etc{color: #7c8ca3;border: solid 1px #7c8ca3;}
                                            &.psd{color: #3688fa;border: solid 1px #3688fa;}
                                            &.ai{color: #df7e27;border: solid 1px #df7e27;}
                                            &.flv{color: #d32b34;border: solid 1px #d32b34;}
                                            &.mp3{color: #9b54bb;border: solid 1px #9b54bb;}
                                            &.wav{color: #9b54bb;border: solid 1px #9b54bb;}
                                            &.wma{color: #9b54bb;border: solid 1px #9b54bb;}
                                        }
                                    }
                                }
                                .nameDate{margin:3px 0 2px 0;
                                    .infoMisc{position: relative;display:inline-block;font-size:11px;font-weight:normal;line-height:20px;color: rgba(0,0,0,0.5);padding:0 0 0 10px;}
                                    .infoMisc:first-child{padding:0;}
                                    .infoMisc::before{position: absolute;top: 4px;left: 4px;content: '';width: 1px;height: 10px;background: rgba(0,0,0,0.1);}
                                    .infoMisc:first-child::before{display:none;}
                                }
                            }
                        }
                    }
                    li.item:nth-child(5n){margin:5px 0 5px 0;}
                }
                // 파일뷰어 선택팝업
                .file-view_pop{display:none;position: absolute;top: 0;right: 0;width: 70px;height: auto;border: 1px solid #91969e;background: #ffffff;transition: all 0.3s;z-index: 10;
                    &.on{display:block;}
                    ul{
                        li{display: block;font-size:12px;font-weight:normal;padding: 5px 12px;cursor:pointer;
                            &:hover{background:#f2f9ff}
                        }                    
                    }
                }
                .tab ul div > li > button{padding:0 15px !important;min-width:80px !important;}
                .tab ul div:first-child > li > button span:first-child{background: transparent !important;}
                .tab ul div > li > button span:first-child{height:13px;background: #cdcdd7 !important;}
                .tab ul div > li > button span:last-child{font-size:11px !important;}
            }

            // CRM 
            .result-crm{margin:30px 0 0 0; 
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic-notice-crm.png') no-repeat left top;} 
                .result-cnt{display: none;position: relative;font-size:15px;font-weight:bold;line-height:21px;color: #2e3645;padding: 5px 0 10px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
                    .cnt{color:#1c90fb;} 
                } 
                .result-box{padding:15px 0 0 0;box-sizing: border-box; 
                    li{padding:20px 0 0 0; 
                        &:first-child{padding:0;} 
                        .listImg{width:80px;height:80px;border:1px solid #ececec;margin:0 10px 0 0;box-sizing: border-box;text-align:center; 
                            img{height:100%;} 
                        } 
                        .listBox{ 
                            .listTop{position: relative;padding:0 0 5px 0; 
                                .tag{display: inline-block;min-width: 50px;height: 20px;margin: 0 5px 0 0;padding:1px 6px 2px 6px;border: 1px solid #8c919a;border-radius: 20px;box-sizing: border-box;font-size: 11px;color: rgba(0,0,0,0.6);vertical-align: top;text-align: center;} 
                                .title{display: inline-block;max-width: 600px;font-weight: bold;font-size: 13px;color: #000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;} 
                            } 
                            .listCon{position: relative;padding:0 0 5px 0; 
                                .text{font-size:13px;word-break: break-all;} 
                                .line-clamp:after{width:15px; background : #fdf2ea;} 
                            } 
                            .listBot{ 
                                .infoMisc{font-size:11px;color:rgba(0,0,0,0.6);} 
                            } 
                        } 
                    } 
                    li:first-child{border:none;} 
                } 
            } 
            // 통합주소록 
            .result-taddr{margin:30px 0 0 0;  
                .result-title{padding:0 0 8px 34px;background:url('../imgs/main/ic_notice_total_address.png') no-repeat left top;}  
                .result-wrap{padding-top: 10px; box-sizing: border-box;  
                    .result-box{width: 100%; position: relative;flex-wrap:wrap;  
                        li.item{min-width:370px;width:370px;height: 194px; border:1px solid #dcdcdc;border-radius: 3px;box-sizing:border-box;background: #ffffff;margin:5px 9px 5px 0;box-shadow:0 5px 3px 0 rgba(217, 215, 231, 0.13);  
                            .user-user{margin:0 20px; width: 94px;justify-content:center;align-items:center;cursor: pointer;  
                                .user-pic{width:52px;height:52px;border-radius:52px;margin: 0 auto; background:url('../imgs/temp/img_empty_profile_52@2x.png') no-repeat top center;background-size: 52px;overflow:hidden;  
                                    img{width:100%;vertical-align: top;}  
                                }  
                                .nameBox{width:100%;margin-top: 6px;text-align: center;  
                                    .user-name{font-size: 13px; font-weight:bold;color: #000;}  
                                }  
                            }  
                            .user-infor{font-size:11px;color:#4a4a4a;text-align:left; vertical-align: middle; padding:10px 10px 10px 0;line-height:18px;  
                                .user-comp{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_comp_02_m_normal@2x.png') no-repeat left 0px center;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}  
                                .user-org{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_organization_m_normal@2x.png') no-repeat left 0px center;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}  
                                .user-phone{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_mobile_m_normal@2x.png') no-repeat left 0px center;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}  
                                .user-mail{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_mail_m_normal@2x.png') no-repeat left top 1px;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}  
                                .user-number{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_phone_01_m_normal@2x.png') no-repeat left 0px center;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}  
                                .user-addr{margin:5px 0; padding:0 0 0 20px;background:url('../imgs/ico/ic_address_m_normal@2x.png') no-repeat left 0px center;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}  
                                .user-department{margin:5px 0; padding:0 0 0 20px;background:url("../imgs/ico/ic_pc_m_normal@2x.png") no-repeat left 0px center;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}   
                                .user-companyRank{margin:5px 0; padding:0 0 0 20px;background:url("../imgs/ico/ic_employee_num_m_normal@2x.png") no-repeat left 0px center;background-size: 18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}      
                            }  
                        }  
                        li.item:nth-child(2n){margin:5px 0 5px 0;}  
                    }  
                } 
            }
            
            // 연관컨텐츠
            .result-rk{margin:15px 0 0 0;
                .result-title{padding:0 0 0 25px;background:url('../imgs/main/ico_searchResult_rk.png') no-repeat left 0px center;
                    .cnt{color:#1c90fb;}
                }
            }
            // 연관컨텐츠 (기본)
            .result-relation{position:relative;margin: 15px 0 0 0;width: 100%;min-height: 200px;border-radius: 5px;border: solid 1px #ececec;background-color: #ffffff;
                .relationKeyword-title{position: absolute;top: 0;left: 0;width: 100%;height: auto;margin: 23px 0 0 0;padding: 0 26px;box-sizing: border-box;z-index:1;
                    .title{font-size:14px;font-weight:bold;line-height:20px;}
                    .result-moreBtn{width:18px;height:18px;background:url('../imgs/ico/ic_arrow_right_01_m_normal.png') no-repeat right -5px center;cursor: pointer;
                        &:hover{background:url('../imgs/ico/ic_arrow_right_01_m_over.png') no-repeat right -5px center;}
                    }
                }
                // 연차사용현황
                &.holiday{height:250px;background: url('../imgs/main/img_related_hoiiday.png') no-repeat center;background-size:cover;border:none;
                    .dataArea{width: 400px;height: 174px;margin: 56px 0 0 26px;box-sizing: border-box;
                        > .info {position:relative;display:flex;flex-direction:column;justify-content:center;width:125px;min-width:125px;margin-left:30px;
                            > ul {display:flex;flex-direction:column;justify-content:center;
                                > li {padding:2px 0 6px;font-size:13px;font-weight:normal;line-height:30px;color:#a6a6a6;
                                    &:before {content:'';display:inline-block;width:2px;height:2px;margin-right:5px;background:#9e9e9f;vertical-align:middle;}
                                    &:not(:first-child) {border-top:1px dashed #e6e6e6;}
                                    > span {display:inline-block;width:calc(66% - 7px);vertical-align:middle;}
                                    > strong {display:inline-block;width:34%;font-size:16px;color:#474747;text-align:right;vertical-align:middle;}
                                }
                            }
                        }
                        //그래프(진행률)
                        .graphWrap{margin:auto 30px auto 50px;
                            > .graph {align-self:center;position:relative;transform:rotate(-90deg);width:140px;height:140px;
                                .back {stroke:#e9e9e9;}
                                //&.on .back {stroke:#fff;}
                                .fill {stroke:#4eabfa;animation:chart-fill 2s reverse;transform-origin:center;}
                                .info {position:absolute;right:50%;top:40px;width:140px;margin-right:-68px;font-size:36px;line-height:1.17;color:#474747;letter-spacing:-1.44px;text-align:center;animation:chart-info 2s forwards;transform:rotate(90deg);opacity:0;
                                    > i {font-size:18px;}
                                    > span {display:block;margin-top:4px;font-size:12px;letter-spacing:-0.9px;color:#4a4a4a;font-weight:normal;text-align:center;}
                                }
                                @keyframes chart-fill {
                                    to {stroke-dasharray:0 100;}
                                }
                                @keyframes chart-info {
                                    to {opacity:1;}
                                }
                            }
                        }
                    }                
                }
                // 결재문서현황
                &.approval{height:250px;background: url('../imgs/main/img_related_approval.png') no-repeat center;background-size:cover;border:none;
                    .dataArea{width: 400px;height: 156px;margin: 66px 0 0 26px;border-radius: 6px;background: rgba(255, 255, 255, 0.8);-webkit-backdrop-filter: blur(30px);backdrop-filter: blur(30px);box-shadow: 0 3px 10px 0 #dbe4eb;box-sizing: border-box;
                        .flexUnit{position: relative;padding:50px 0;
                            &::before{content:'';position: absolute;top:54px;left:0;width:1px;height:48px;background-color: rgba(0, 0, 0, 0.1);}
                            &:first-child:before{display:none;}
                            .tit{font-size:13px;font-weight:normal;line-height:20px;text-align: center;color:#a6a6a6;}
                            .cnt{font-size:16px;font-weight:normal;text-align: center;color:#474747;
                                strong{font-size:26px;line-height:38px;font-weight:bold;margin:0 3px 0 0;}
                            }
                        }
                    }
                }
            }
            // 연관컨텐츠 (단어)
            .result-relationKeyword{position:relative;margin: 15px 0 0 0;width: 100%;min-height: 110px;border-radius: 5px;border: solid 1px #ececec;background-color: #ffffff;
                .relationKeyword-title{width: 100%;height: 53px;padding:0 20px;background-color: #f5f8fa;box-sizing:border-box;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
                    &.campaign{background-color: #f5f5fc;}
                    .badge{display: inline-block;width: 38px;height: 20px;margin: 16px 0;font-size:11px;font-weight:normal;line-height:20px;color: #ffffff;text-align: center;border-radius: 100px;background-color: #46a3f0;}
                    .searching-text{font-size:14px;font-weight:bold;line-height:53px;margin:0 0 0 10px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                    .btn{display: inline-block;height: 26px;margin: 13px 0;padding: 0 20px 0 10px;font-size:11px;font-weight:normal;line-height:24px;text-align: center;box-sizing: border-box;border-radius: 100px;border: solid 1px rgba(0, 0, 0, 0.2);background:url('../imgs/ico/ic_arrow_right_02_s_disable.png') no-repeat right 5px center;}

                }
                .relationKeyword-contents{padding:20px 20px 10px 20px;
                    dl{font-size:14px;font-weight:normal;line-height:20px;
                        dt{padding: 0 5px 0 20px;background:url('../imgs/ico/ic_docu_s_normal.png') no-repeat top 4px left;}
                        dt.campaign{padding: 0 5px 0 20px;background:url('../imgs/ico/ic_notice_s_normal.png') no-repeat top 4px left;}
                        dt.doc{padding: 0 5px 0 20px;background:url('../imgs/ico/ic_docu_s_normal.png') no-repeat top 4px left;}
                        dd{
                            ul{text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
                                li{display:inline-block;line-height: 20px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
                            }
                            .btn{display: inline-block;width: 110px;height: 26px;margin:0;padding: 0 10px 0 0;font-size:11px;font-weight:normal;line-height:24px;text-align: center;box-sizing: border-box;border-radius: 100px;border: solid 1px rgba(0, 0, 0, 0.2);background:url('../imgs/ico/ic_arrow_right_02_s_disable.png') no-repeat right 5px center;}
                            .btn-vacation{display: inline-block;width: 80px;height: 26px;margin:0;padding:0;box-sizing: border-box;border-radius: 2px;border: solid 1px rgba(0, 0, 0, 0.2);background:url('../imgs/main/btn_vacation.png') no-repeat center;}
                            .badge-done{display: inline-block;height: 18px;margin: 0;padding:0 10px;font-size:11px;font-weight:normal;line-height:18px;color: #ffffff;text-align: center;border-radius: 100px;background-color: #9da3aa;}
                        }
                    }
                }
                .relationKeyword-contents.campaign{padding:10px 20px;
                    dl{font-size:14px;font-weight:normal;line-height:20px;padding: 20px 0;border-bottom: 1px solid #ececec;
                        &:last-child{border-bottom:none;}
                        dt{padding: 0 5px 0 25px;background:url('../imgs/ico/ic_docu_s_normal.png') no-repeat top 4px left;}
                        dt.campaign{padding: 0 5px 0 20px;background:url('../imgs/ico/ic_notice_s_normal.png') no-repeat top 4px left;}
                        dd{
                            ul{text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
                                li{display:inline-block;line-height: 20px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
                                    .badge{display: inline-block;width: 38px;height: 20px;font-size:11px;font-weight:normal;line-height:20px;color: #ffffff;text-align: center;border-radius: 100px;background-color: #46a3f0;}
                                    .line{display: inline-block;vertical-align: middle;width: 1px;height: 10px;margin: 0 10px;opacity: 0.1;background-color: #000000;}
                                }
                            }
                        }
                    }
                }               

            }
            // 연관컨텐츠 (배너이미지)
            .result-relationImg{position:relative;margin: 15px 0 0 0;width: 100%;min-height: 100px;border-radius: 5px;border: solid 1px #ececec;background-color: #ffffff;overflow:hidden;
                .relationKeyword-title{position: absolute;top: 0;left: 0;width: 100%;height: auto;margin: 23px 0 0 0;padding: 0 26px;box-sizing: border-box;z-index:1;
                    .title{font-size:14px;font-weight:bold;line-height:20px;}
                    .result-moreBtn{width:18px;height:18px;background:url('../imgs/ico/ic_arrow_right_01_m_normal.png') no-repeat right -5px center;cursor: pointer;
                        &:hover{background:url('../imgs/ico/ic_arrow_right_01_m_over.png') no-repeat right -5px center;}
                    }
                }
                img.link{width:100%;cursor: pointer;}
            }

            // 연관컨텐츠 (고객사정보, 연락처정보)
            .result-relationInfo{position:relative;margin: 15px 0 0 0;width: 100%;min-height:284px;border-radius: 5px;border: solid 1px #ececec;background-color: #ffffff;overflow:hidden;
                table{table-layout: fixed;}
                .relationKeyword-title{margin: 23px 0 0 0;padding: 0 26px;box-sizing: border-box;z-index:1;
                    .title{font-size:14px;font-weight:bold;line-height:20px;}
                    .result-moreBtn{width:18px;height:18px;background:url('../imgs/ico/ic_arrow_right_01_m_normal.png') no-repeat right center;cursor: pointer;
                        &:hover{background:url('../imgs/ico/ic_arrow_right_01_m_over.png') no-repeat right center;}
                    }
                }
                .relationKeyword-contents{height: 203px;margin: 12px 26px 26px 26px; padding:30px 0; border-radius: 2px; border: solid 1px #ececec; background-color: #f7fafc; box-sizing: border-box;
                    .result_img{padding:0 34px; width:100px; align-items: center; justify-content: center; display: flex;
                        &.company{background:url('../imgs/bg/img_building_user.png') no-repeat center;}
                        &.user{background:url('../imgs/bg/img_user_building.png') no-repeat center;}
                    }
                    .result_info{line-height:24px;
                        th{font-size: 11px;text-align: left; color: #a6a6a6;letter-spacing: -0.55px;}
                        td{font-size: 12px;letter-spacing: -0.6px; text-align: left; color: #4a4a4a; color:#4a4a4a;}
                    }
                }  
                .addContact{margin-top:12px;padding: 0 26px 26px 26px;
                    dl{width: 167px; height: 94px; border-radius: 3px; border: solid 1px #ececec; margin-right:10px; text-align: center; box-sizing: border-box; cursor: pointer;
                        dt{ font-size: 13px; padding:16px 10px 0 10px; font-weight:bold; justify-content: center; align-items: center;
                            .line{margin:0 0 0 6px; padding:0 6px; background: url('../imgs/bg/bg_line_bar01.png') no-repeat left center; background-size: 1px 10px;}
                            .rank{min-width:30px; font-size: 11px; color:#8c8c8c; font-weight:normal;}
                        }
                        dd{font-size: 11px; color:#8c8c8c; padding:0 10px; justify-content: center;
                            .companyText{color:#1c90fb; padding-top:8px; line-height:16px;}
                        }
                    }
                }
                [data-orbit-component=OBTTooltip] {line-height:inherit;}
            }

            // 나의 영업현황
            .result-relationBusiness{position:relative;margin: 15px 0 0 0;width: 100%;border-radius: 5px;border: solid 1px #ececec;background-color:#fafafa;overflow:hidden;      
                .relationKeyword-title{margin: 23px 0 0 0;padding: 0 26px;box-sizing: border-box;z-index:1; 
                    .title{font-size:14px;font-weight:bold;line-height:20px;}
                }
                .relationKeyword-contents{margin: 12px 26px 26px 26px;
                    //영업기회
                    .title{border-radius: 3px; background-color: #f0f0f0; height:32px; line-height:32px; font-size: 13px; text-align: center;color: #4a4a4a; margin-bottom:12px}
                    .conBox{height: 46px; line-height: 46px; border-radius: 3px; border: solid 1px #e0e0e0; background-color: #fff; box-sizing: border-box; padding:0 17px; margin:0 0 6px 0;
                        .proTitle{font-size: 13px;color: #000; background: url('../imgs/bg/bg_line_bar01.png') no-repeat right center; background-size: 1px 10px; padding:0 6px 0 0; margin:0 6px 0 0;}
                        .company{color:#8c8c8c; margin-left:6px; background: url('../imgs/bg/bg_line_bar01.png') no-repeat left center; background-size: 1px 10px; padding:0 0 0 6px; /*min-width: 110px;*/}                        
                        .est{color:#8c8c8c; min-width: max-content;}
                        .radius{height: 18px; line-height: 18px; padding: 0 6px; border-radius: 10px; margin-top:13px; color:#fff; font-size:11px; min-width: max-content;
                            &.blue{background-color: #46a3f0;}
                            &.yel{background-color: #f0c325;}
                        }                        
                    }

                    //타임라인                    
                    .timeLine{
                        ul{position: relative;
                            &:before{content: '';position: absolute;top: 20px; left:13px;bottom:-8px;width: 1px;background-color: rgba(0, 0, 0, 0.1);}
                            li{position: relative; margin-bottom:20px;
                                .list_con{overflow: hidden;                         
                                    .detail{border-radius: 4px; border: solid 1px #e0e0e0; background-color: #fff; padding:8px; font-size: 11px; margin-top:4px;}  
                                    .company{color:#a6a6a6;font-size: 11px; background: url('../imgs/bg/bg_line_bar01.png') no-repeat left center; margin-left:6px; padding-left:6px; min-width:100px; margin-right:10px;}
                                    .date{color:#a6a6a6; font-size: 11px; min-width: max-content;}
                                }
                                &:last-child{margin-bottom:0px;}                  
        
                                .icon{width: 28px;height: 28px; margin: 4px 9px 0 0; border-radius: 100px; overflow: hidden;}
                                .icon.call{background:url('../imgs/ico/ic_notice_call.png') no-repeat center; background-size: 28px 28px;}           // 전화
                                .icon.visit{background:url('../imgs/ico/ic_notice_visit.png') no-repeat center; background-size: 28px 28px;}         // 방문
                                .icon.fax{background:url('../imgs/ico/ic_notice_fax.png') no-repeat center; background-size: 28px 28px;}             // 팩스
                                .icon.mail{background:url('../imgs/ico/ic_notice_mail.png') no-repeat center; background-size: 28px 28px;}           // 메일
                                .icon.estimate{background:url('../imgs/ico/ic_notice_estimate.png') no-repeat center; background-size: 28px 28px;}   // 견적
                                .icon.order{background:url('../imgs/ico/ic_notice_order.png') no-repeat center; background-size: 28px 28px;}         // 주문
                                .icon.system{background:url('../imgs/ico/ic_notice_notificatiom.png') no-repeat center; background-size: 28px 28px;} // 시스템

                            }   
                        }                     
                    }                    
                }  

                &.horizon{max-height:469px;  
                    .contentLine{background: url('../imgs/bg/ico_page_bar.png') repeat-y right center; margin:0 20px 0 0; padding: 0 20px 0 0;
                        .title{width:328px;}
                    }
                    .conBox{height: 64px; justify-content: center; line-height: 20px; align-items: center;
                        .proTitle{background:none;}
                        .badge{display: flex; justify-content: center; align-items: center;
                            .radius{margin-top:0px;}
                        }
                        .company{margin-right: 6px;}
                    }                      
                    .timeLine{max-height: 343px; height:100%;                    
                        .list_con{
                            .company{min-width: auto;}
                        }                  
                    }
                }
                [data-orbit-component=OBTTooltip] {line-height:inherit;}
            }


            // 검색결과 없을때
            .resultEmptyWrap{position:relative;
                .matchingEmptyText{width: 100%;min-height: 20px;font-size: 0;padding: 80px 0 40px 0;text-align:center;
                    .textResult{font-size:15px;font-weight:bold;line-height:20px;color: #000000;
                        .matching{display: inline-block;font-size:15px;font-weight:bold;line-height:20px;height: 20px;color: #1d90fb;
                            *{vertical-align: top;}
                            .ellipsis{display: inline-block;max-width: 680px;}
                        }
                    }
                }
                .description{padding:0 0 280px 0;background:url('../imgs/main/img_search_empty.png') no-repeat bottom 0 center;overflow:hidden;
                    .txt{text-align:center;margin:0 0 10px 0;font-size:13px;font-weight:normal;line-height:20px;color:rgba(0, 0, 0, 0.5);}
                    .txt span{position:relative;padding:0 0 0 8px;}
                    .txt span::before{content: '';position: absolute;top: 10px;left: 0;width: 2px;height: 2px;background-color: rgba(0, 0, 0, 0.5);}
                }
                // 링크영역 (미사용)
                .bottomLinkWrap{padding:37px 0 60px 0;
                    .txt{text-align:center;margin:0 0 20px 0;font-size:13px;font-weight:normal;line-height:20px;color:rgba(0, 0, 0, 0.5);}
                    ul{justify-content: center;
                        li{position: relative;padding:0 10px;
                            &::after{content:"";position: absolute;top:5px;left:0;width:1px;height:10px;background-color: rgba(0,0,0, 0.1);}
                            &:first-child::after{display: none;}
                            span{font-size:12px;font-weight:normal;line-height:20px;color:#000000;}
                        }
                    }
                }
            }
        }
    }
}

/***************************** 
    관리자/마스터 포틀릿설정
******************************/
.adminSet{position: absolute;width: 100%;min-width: 1425px;height: 100%;overflow-x: auto;overflow-y: hidden;}
.adminSet .titleFnc{position: absolute;top:0;left:0;width:100%;height:42px;background-color: #1385db;}
.adminSet .titleFnc:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.adminSet .titleFnc .leftDiv{float:left;font-size:12px;font-weight:bold;line-height:42px;font-family:'Dotum';margin:0 0 0 20px;color:#ffffff;}
.adminSet .titleFnc .rightDiv{float:right;margin:7px 20px 0 0;}
.adminSet .titleFnc .rightDiv .topBtn{color: #ffffff;border: 1px solid #5aaae6;background-color: #1385db;background-image: none !important;margin-left: 4px;}
.adminSet .titleFnc .rightDiv .topBtn span:after{display: none;}
.adminSet .titleFnc .rightDiv .topBtn:hover{border:1px solid #ffffff;background-color: #0876c9;}
.adminSet .setContents{position: absolute;top:42px;left:0;right:420px;bottom:120px;background-color: #f0f3f3;overflow:auto;}
.adminSet .setLists{position: absolute;left: 0;right: 420px;bottom: 0px;height:120px;background: #4a4a4a;z-index:1000;transition:all 0.2s;overflow:hidden;}

.adminSet .setOptions{position: absolute;top: 42px;right: 0;bottom: 0;width: 420px;padding: 10px;border-left: 1px solid #dcdcdc;box-sizing: border-box;background:#ffffff url('../imgs/main/bg_portal_set.png') no-repeat right 25px bottom 35px;;overflow: hidden;}
.adminSet .setOptions .setItem{display:none;position: absolute;top:0;left:0;right:0;bottom:0;background-color: #ffffff;overflow-y:auto;overflow-x: hidden;}
.adminSet .setOptions .setItem .setItemHead{position: relative;overflow: hidden;margin: 0 15px;padding: 15px 0px 8px;border-bottom: 1px solid #000;}
.adminSet .setOptions .setItem .setItemHead .setItemHeadTitle{margin-left: 10px;padding-left: 0px;font-size:18px;font-weight:bold;letter-spacing: -0.5px;color: #000000;background: none;}
.adminSet .setOptions .setItem .setItemHead .setItemHeadTitle span{font-size:14px;}
.adminSet .setOptions .setItem .setItemHead .setItemClose{position: absolute;top: 18px;right: 0px;width: 21px;height: 21px;cursor: pointer;background: url('../imgs/main/btn_pop_clo02.png') no-repeat center center;}
.adminSet .setOptions .setItem .setItemForm{padding:0 15px;}
.adminSet .setOptions .setItem .setItemForm .setBanner{position:relative;width:100%;height:100%;}
.adminSet .setOptions .setItem .setItemForm .setBannerImage{float:left;width:108px;height:108px;}
.adminSet .setOptions .setItem .setItemForm .setBannerImage img{width:100%;height:100%;}
.adminSet .setOptions .setItem .setItemForm .setBannerBtn{position: absolute;right:0;bottom:0;display: inline-block;width: 32px;height: 32px;font-size:0;background: url('../imgs/ico/ic_settine_01_m_normal.png') no-repeat center center;cursor: pointer;
    &:hover{background: url('../imgs/ico/ic_settine_01_m_over.png') no-repeat center center;}
}
.adminSet .setOptions .setItem .setItemForm .alertText{margin-top:10px; background: url('../imgs/ico/icon_input_error.png') no-repeat left center; padding-left: 20px;}
.adminSet .setOptions .setItem .setItemForm .alertText em{padding-right:5px; color:#ff0000;}

.setUserItemForm:after{content:"";display:block;clear:both;height:0;visibility:hidden;}
.setUserItemForm .setBanner{position:relative;width:100%;height:100%;}
.setUserItemForm .setBannerImage{float:left;width:108px;height:108px;}
.setUserItemForm .setBannerImage img{width:100%;height:100%;}
.setUserItemForm .setBannerBtn{position: absolute;right:0;bottom:0;display: inline-block;width: 32px;height: 32px;font-size:0;background: url('../imgs/ico/ic_settine_01_m_normal.png') no-repeat center center;cursor: pointer;
    &:hover{background: url('../imgs/ico/ic_settine_01_m_over.png') no-repeat center center;}
}
.setUserItemForm .alertText{margin-top:10px; background: url('../imgs/ico/icon_input_error.png') no-repeat left center; padding-left: 20px;}
.setUserItemForm .alertText em{padding-right:5px; color:#ff0000;}

.helpContents dt{font-size:14px;font-weight:normal;line-height:20px;color:#058df5;margin:10px 0 0 0;}
.helpContents dt:first-child{margin:0;}
.helpContents dd{font-size:12px;font-weight:normal;line-height:20px;color:#000000;}
.helpContents dd.sub{padding:0 0 0 10px}

// 사용자설정팝업 (커스텀팝업)
.adminSet .setUserPortletPopup{display: inline-block;position: absolute;top: 50%;left: 50%;padding: 0px 30px;background: white;border: 1px solid #e7e7e7;z-index: 1200;
outline: none;width: 480px;height: 500px;transform: translate(-95%, -58%);box-sizing: border-box;}
.adminSet .setUserPortletPopup .popInWrap{text-align: left;display: flex;flex-direction: column;height: 500px;}
.adminSet .setUserPortletPopup .popInWrap .popTitle{overflow:hidden;}
.adminSet .setUserPortletPopup .popInWrap .popTitle h1{float: left;margin: 0px;padding: 23px 0px 8px;font-size:18px;font-weight:normal;line-height:22px;font-family: "NotoSans", 돋움, Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif;}
.adminSet .setUserPortletPopup .popInWrap .popTitle .close{position: absolute;top: 19px;right: 23px;width: 30px;height: 30px;margin: 0 0 0 5px;padding: 0px;border: none;background: none !important;}
.adminSet .setUserPortletPopup .popInWrap .popContents{-ms-flex-positive: 1;flex-grow: 1;border-top: 1px solid black;padding: 10px 0;overflow: auto;
    .portletThumbList {position: relative;width: 100%;height: 100%;
        &.insize {margin: 0 auto;padding: 0 100px 0 16px;}
        ul {font-size: 0;
            &::after {content: "";display: block;clear: both;height: 0;visibility: hidden;}
        }
        li {position: relative;display: inline-block;padding: 0 4px 10px 4px;
            span {display: table-cell;width: 70px;max-width: 70px;height: 70px;padding: 0;text-align: center;font-size:12px;font-weight:bold;font-family:sans-serif;color: #ffffff;background: #323232;border-radius: 4px;border: 2px solid #323232;vertical-align: middle;white-space: normal;box-sizing: border-box;
                em {display: block;font-size:10px;font-weight:bold;font-family:sans-serif;color: #696969;transition: all 0.3s ease-in 0s;-webkit-transition: all 0.3s ease-in 0s;}
            }
            .delete{position: absolute;top: 7px;right: 10px;width: 9px;height: 9px;cursor: pointer;background: url('../imgs/main/btn_pop_clo02.png') no-repeat center;}
        }
    }
}
.adminSet .setUserPortletPopup .popInWrap .popBottom{margin: 0px -31px 0px;padding: 7px 0px;background: #f2f2f2;text-align: center;}

// 관리자 인하우스존 UI 추가
.adminSet .tangoArea{position: absolute;top: 42px;width: calc(100% - 420px);height: 200px;border-bottom: 1px solid #dcdcdc;box-sizing: border-box;
    .tangoMenu{
        // &.floatMode{position: absolute; top: 33px;left: 50%;bottom: auto !important;min-width: 980px;max-width: 980px;width: 100%;height: 132px;border-radius: 10px;background-color: rgba(0,0,0,.6);overflow: hidden;transform: translateX(-50%)}
        &.floatMode{position: relative;left: unset;bottom: unset !important;margin: 30px auto;width: 980px;height: 132px;border-radius: 10px;background-color: rgba(0,0,0,.6);overflow: hidden;transform: none;}
        ul{padding:16px 10px !important;
            li.selected{outline:1px solid #1d90fb;border-radius:10px;}
        }
    }
}
.adminSet .userTangoThumbnail{position: absolute;width: 100% !important;height: 120px!important;left: 0;right: 0;top: 100%;z-index: 1000;}
.adminSet .userPortletThumbnail{position: absolute;width: 100% !important;height: 120px!important;left: 0;right: 0;top: 100%;z-index: 1000;}

// 포털가져오기
.portalThumbList01{position: relative;width: 100%;height: 100%;font-size:0;}
.portalThumbList01 li{position: relative;display: inline-block;margin: 0 8px 8px 0;cursor: pointer;}
.portalThumbList01 li:nth-child(3n){margin:0 0 8px 0;}
.portalThumbList01 li .thumbImgBox{position: relative;width: 217px;height: 170px;border: 1px solid #e6e6e6;box-sizing: border-box;}
.portalThumbList01 li .thumbImgBox-in{width: 185px;height: 138px;margin: 15px;background: #dde8ee;}
.portalThumbList01 li .thumbImgBox img{width:185px;height:138px;}
.portalThumbList01 li .thumbNameBox{display:block;font-size:13px;font-weight:normal;line-height:24px;color:#000000;text-align: center;}
.portalThumbList01 li.on .thumbImgBox::before{content: '';position: absolute;top: 0;left: 0;right: 0;bottom: 0;border: 2px solid #1c90fb;background: rgba(0, 0, 0, 0);box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.18);}
.portalThumbList01 li.on .thumbImgBox::after{content:'';position: absolute;top:50%;left:50%;width:38px;height:38px;background:url('../imgs/main/ico_main_tempSelect.png') no-repeat center;transform: translate(-50%,-50%);}

.portalThumbList02{position: relative;width: 100%;height: 100%;font-size:0;}
.portalThumbList02 li{position: relative;display: inline-block;margin: 0 8px 8px 0;cursor: pointer;}
.portalThumbList02 li:nth-child(3n){margin:0 0 8px 0;}
.portalThumbList02 li .thumbImgBox{position: relative;width: 223px;height: 183px;border: 1px solid #e6e6e6;box-sizing: border-box;}
.portalThumbList02 li .thumbImgBox-in{width: 191px;height: 151px;margin: 15px;}
.portalThumbList02 li .thumbImgBox img{width:191px;height:151px;}
.portalThumbList02 li.on .thumbImgBox::before{content: '';position: absolute;top: 0;left: 0;right: 0;bottom: 0;border: 2px solid #1c90fb;background: rgba(0, 0, 0, 0);box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.18);}
.portalThumbList02 li.on .thumbImgBox::after{content:'';position: absolute;top:50%;left:50%;width:38px;height:38px;background:url('../imgs/main/ico_main_tempSelect.png') no-repeat center;transform: translate(-50%,-50%);}

// 포털 서비스준비중 안내
.popupGuideAlert{padding:202px 0 0 0;background:url('../imgs/main/img_service_prepare.png') no-repeat top 40px center;
    strong{font-size:20px;font-weight:normal;line-height:20px;text-align: center;color: #000000;letter-spacing: -0.5px;}
    .text{margin:16px;font-size:15px;font-weight:normal;line-height:20px;text-align: center;color: #000000;}
}

// 인하우스 추가 팝업
.SetInhouse{
    [class*="OBTReferencePanel_nochildreferbox"]{border-radius:3px !important;}
    .inhouseRadio{
        & > div:first-child span{margin:0 !important;}
        & > div:first-child span svg{margin-top:-6px !important;}
    }
    .borderBox{margin:10px 0 0 0;padding:17px;
        .inhouseRadio{
            & > div:first-child span:first-child{margin:0 0 5px 0 !important;}
        }
    }
    .iconSelectBox{border:1px solid #e6e6e6;
        .iconSelectList{border-top:1px solid #e6e6e6;
            &:first-child{border-top:none;}
            .iconBox{position:relative;min-width:60px;min-height:60px;width:60px;height:60px;border-left:1px solid #e6e6e6;box-sizing: border-box;cursor: pointer;z-index: 2;
                &:first-child{border-left:none;}
                &.icoNone:hover{cursor: default;}
                &.icoNone:hover::before{content:'';position:absolute;width:100%;height:100%;border:none;background-color:transparent;box-sizing:border-box;z-index: 1;}
                &:hover::before{content:'';position:absolute;width:100%;height:100%;border:1px solid #1c90fb;background-color:#eff7ff;box-sizing:border-box;z-index: 1;}
                &.on::before{content:'';position:absolute;width:100%;height:100%;border:1px solid #1c90fb;background-color:#eff7ff;box-sizing:border-box;z-index: 1;}
                &.disabled{cursor: not-allowed !important;opacity: 0.5;}
                &.disabled::before{content: "";position: absolute;width: 100%;height: 100%;border: none !important;background-color:#ffffff;box-sizing: border-box;z-index: 1;}
                // 인하우스 아이콘 그룹(설정) //
                &.inHouse_ecoIcon::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_default@2x.png') no-repeat center;background-size: 34px;}             // 기본아이콘
                // 메일 그룹
                &.ML_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mail@2x.png') no-repeat center;background-size: 34px;}                   // 메일
                &.ML_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mail_write@2x.png') no-repeat center;background-size: 34px;}             // 메일쓰기
                &.ML_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mail_all@2x.png') no-repeat center;background-size: 34px;}               // 전체메일함
                &.ML_ecoIcon04::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mail_send@2x.png') no-repeat center;background-size: 34px;}              // 보낸메일함
                &.ML_ecoIcon05::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mail_receive@2x.png') no-repeat center;background-size: 34px;}           // 받은메일함
                // 문자 그룹
                &.SMS_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_sms@2x.png') no-repeat center;background-size: 34px;}                   // 문자
                &.SMS_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_sms_send@2x.png') no-repeat center;background-size: 34px;}              // 문자보내기
                // 업무관리 그룹
                &.KS_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_kiss@2x.png') no-repeat center;background-size: 34px;}                   // 업무관리
                &.KS_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mywork@2x.png') no-repeat center;background-size: 34px;}                 // 나의업무
                &.KS_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_project_star@2x.png') no-repeat center;background-size: 34px;}           // 나의프로젝트
                // 일정 그룹
                &.CL_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_calendar@2x.png') no-repeat center;background-size: 34px;}               // 일정
                &.CL_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_rm@2x.png') no-repeat center;background-size: 34px;}                     // 자원
                // 임직원업무관리 그룹
                &.HR_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_hr@2x.png') no-repeat center;background-size: 34px;}                     // 임직원업무관리
                &.HR_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_attendance_write@2x.png') no-repeat center;background-size: 34px;}       // 근태신청
                &.HR_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_attendance_cancel@2x.png') no-repeat center;background-size: 34px;}      // 근태신청취소
                &.HR_ecoIcon04::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_paystub@2x.png') no-repeat center;background-size: 34px;}                // 급여명세서
                &.HR_ecoIcon05::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_business_hours_agree@2x.png') no-repeat center;background-size: 34px;}   // 개인근무시간동의
                &.HR_ecoIcon06::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_address_book@2x.png') no-repeat center;background-size: 34px;}           // 전체주소록
                &.HR_ecoIcon07::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_report_send@2x.png') no-repeat center;background-size: 34px;}            // 보낸보고함
                &.HR_ecoIcon08::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_report_receive@2x.png') no-repeat center;background-size: 34px;}         // 받은보고함
                &.HR_ecoIcon09::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_memo@2x.png') no-repeat center;background-size: 34px;}                   // 노트
                &.HR_ecoIcon10::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_expense@2x.png') no-repeat center;background-size: 34px;}                // 경비취합
                &.HR_ecoIcon11::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_expense_write@2x.png') no-repeat center;background-size: 34px;}          // 경비청구작성
                &.HR_ecoIcon12::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_sign_hr_write@2x.png') no-repeat center;background-size: 34px;}          // 지출결의서작성
                &.HR_ecoIcon13::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_approval_write@2x.png') no-repeat center;background-size: 34px;}         // 품의서작성
                &.HR_ecoIcon14::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat center;background-size: 34px;}                 // 개인인사정보조회
                // 전자결재 그룹
                &.EA_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_sign@2x.png') no-repeat center;background-size: 34px;}                   // 전자결재
                &.EA_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_signhome@2x.png') no-repeat center;background-size: 34px;}               // 결재홈
                &.EA_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_sign_write@2x.png') no-repeat center;background-size: 34px;}             // 결재작성
                // 팩스 그룹
                &.FAX_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_fax@2x.png') no-repeat center;background-size: 34px;}                   // 팩스
                &.FAX_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_fax_send@2x.png') no-repeat center;background-size: 34px;}              // 팩스보내기
                &.FAX_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_fax_receive@2x.png') no-repeat center;background-size: 34px;}           // 받은팩스함
                // 원피스 그룹
                &.OF_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_oneffice@2x.png') no-repeat center;background-size: 34px;}               // 원피스
                // 원챔버 그룹
                &.OC_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_onechamber@2x.png') no-repeat center;background-size: 34px;}             // 원챔버
                &.OC_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_my@2x.png') no-repeat center;background-size: 34px;}                     // 내문서함
                &.OC_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_docuflow@2x.png') no-repeat center;background-size: 34px;}               // 도큐플로우
                // 회계 그룹
                &.FI_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_account@2x.png') no-repeat center;background-size: 34px;}                // 회계관리
                &.FI_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_nonprofit@2x.png') no-repeat center;background-size: 34px;}              // 예산관리
                // 인사근태 그룹
                &.HM_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_human@2x.png') no-repeat center;background-size: 34px;}                  // 인사관리
                // 게시판 그룹
                &.BD_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_board@2x.png') no-repeat center;background-size: 34px;}                  // 게시판
                // 확장기능 그룹
                &.EX_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_user_plus@2x.png') no-repeat center;background-size: 34px;}              // 방문객등록
                // 총무구매 그룹 
                &.GSM_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_supplies_write.png') no-repeat center;background-size: 34px;}           // 사무용품신청
                &.GSM_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_supplies_manage.png') no-repeat center;background-size: 34px;}          // 사무용품신청관리
                &.GSM_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_supplies_order.png') no-repeat center;background-size: 34px;}           // 사무용품주문관리
                &.GSM_ecoIcon04::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_supplies_calculate.png') no-repeat center;background-size: 34px;}       // 사무용품정산관리
                // 통합주소록 그룹
                &.ADDR_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_total_address@2x.png') no-repeat center;background-size: 34px;}       // 통합주소록
                // HIS 그룹
                &.HIS_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse01@2x.png') no-repeat center;background-size: 34px;}        // 원무(HIS전용)
                &.HIS_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse02@2x.png') no-repeat center;background-size: 34px;}        // 진료(HIS전용)
                &.HIS_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse03@2x.png') no-repeat center;background-size: 34px;}        // 진료지원(HIS전용)
                &.HIS_ecoIcon04::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse04@2x.png') no-repeat center;background-size: 34px;}        // 보험심사(HIS전용)
                &.HIS_ecoIcon05::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse05@2x.png') no-repeat center;background-size: 34px;}        // 통계(HIS전용)
                &.HIS_ecoIcon06::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse07@2x.png') no-repeat center;background-size: 34px;}        // 기초코드(HIS전용)
                &.HIS_ecoIcon07::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse08@2x.png') no-repeat center;background-size: 34px;}        // 서식(HIS전용)
                &.HIS_ecoIcon08::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse09@2x.png') no-repeat center;background-size: 34px;}        // 재고관리(HIS전용)
                &.HIS_ecoIcon09::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse10@2x.png') no-repeat center;background-size: 34px;}        // 병원정보설정(HIS전용)
                &.HIS_ecoIcon10::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_inhouse11@2x.png') no-repeat center;background-size: 34px;}        // 간편 세무 회계(HIS전용)
                &.HIS_ecoIcon11::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_education@3x.png') no-repeat center;background-size: 34px;}        // 법정교육(HIS전용)
                &.HIS_ecoIcon12::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_labor@3x.png') no-repeat center;background-size: 34px;}            // 법무노무(HIS전용)
                &.HIS_ecoIcon13::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_theporterzone@3x.png') no-repeat center;background-size: 34px;}    // 더포터존(HIS전용)
                &.HIS_ecoIcon14::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_industrial@2x.png') no-repeat center;background-size: 34px;}       // 공단검진(HIS전용)
                &.HIS_ecoIcon15::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_policy@2x.png') no-repeat center;background-size: 34px;}           // 정책고시(HIS전용)
                &.HIS_ecoIcon16::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_notice@2x.png') no-repeat center;background-size: 34px;}           // 공지사항(HIS전용)
                // 로테크 그룹
                &.UR_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_total_lawtech@2x.png') no-repeat center;background-size: 34px;}     // 로테크
                // 커스텀 그룹 (패키지 X )
                &.Custom_ecoIcon01::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_sales@2x.png') no-repeat center;background-size: 34px;}              // 매출관리
                &.Custom_ecoIcon02::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_manage_account@2x.png') no-repeat center;background-size: 34px;}     // 계좌관리
                &.Custom_ecoIcon03::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_salary@2x.png') no-repeat center;background-size: 34px;}             // 급여관리
                &.Custom_ecoIcon04::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_fund@2x.png') no-repeat center;background-size: 34px;}               // 자금관리
                &.Custom_ecoIcon05::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_corporation_card@2x.png') no-repeat center;background-size: 34px;}   // 법인카드
                &.Custom_ecoIcon06::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_spend@2x.png') no-repeat center;background-size: 34px;}              // 지출결의
                &.Custom_ecoIcon07::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_mypage@2x.png') no-repeat center;background-size: 34px;}             // 마이페이지
                &.Custom_ecoIcon08::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_work_report@2x.png') no-repeat center;background-size: 34px;}        // 업무보고
                &.Custom_ecoIcon09::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_keep@2x.png') no-repeat center;background-size: 34px;}               // KEEP
                &.Custom_ecoIcon10::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_attendance@2x.png') no-repeat center;background-size: 34px;}         // 근태관리
                &.Custom_ecoIcon11::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_crm@2x.png') no-repeat center;background-size: 34px;}                // CRM
                &.Custom_ecoIcon12::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_business_hours@2x.png') no-repeat center;background-size: 34px;}     // 근무시간현황
                &.Custom_ecoIcon13::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_distri@2x.png') no-repeat center;background-size: 34px;}             // 물류
                &.Custom_ecoIcon14::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_produce@2x.png') no-repeat center;background-size: 34px;}            // 생산관리
                &.Custom_ecoIcon15::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_buy_write@2x.png') no-repeat center;background-size: 34px;}          // 구매신청
                &.Custom_ecoIcon16::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_portal@2x.png') no-repeat center;background-size: 34px;}             // 포털
                &.Custom_ecoIcon17::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_ex@2x.png') no-repeat center;background-size: 34px;}                 // 확장기능
                &.Custom_ecoIcon18::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_video@2x.png') no-repeat center;background-size: 34px;}              // 동영상보기    
                &.Custom_ecoIcon19::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_online_experience@2x.png') no-repeat center;background-size: 34px;}  // 온라인체험관
                &.Custom_ecoIcon20::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_system_setting@2x.png') no-repeat center;background-size: 34px;}     // 시스템환경설정
                &.Custom_ecoIcon21::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_process_management@2x.png') no-repeat center;background-size: 34px;} // 프로세스관리
                &.Custom_ecoIcon22::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_wehago@2x.png') no-repeat center;background-size: 34px;}             // 위하고
                &.Custom_ecoIcon23::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_wiki@2x.png') no-repeat center;background-size: 34px;}               // 위키
                &.Custom_ecoIcon24::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_jira@2x.png') no-repeat center;background-size: 34px;}               // 지라
                &.Custom_ecoIcon25::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_gcms@2x.png') no-repeat center;background-size: 34px;}               // GCMS
                &.Custom_ecoIcon26::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_skt_meetus@2x.png') no-repeat center;background-size: 34px;}         // SKT MeetUs
                &.Custom_ecoIcon27::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_nsm_10@2x.png') no-repeat center;background-size: 34px;}             // NSM10
                &.Custom_ecoIcon28::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_erp_10@2x.png') no-repeat center;background-size: 34px;}             // ERP10
                &.Custom_ecoIcon29::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_amaranth_10@2x.png') no-repeat center;background-size: 34px;}        // Amaranth10
                &.Custom_ecoIcon30::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_icube@2x.png') no-repeat center;background-size: 34px;}              // iCUBE
                &.Custom_ecoIcon31::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_cor_sol@2x.png') no-repeat center;background-size: 34px;}            // 기업금융관리
                &.Custom_ecoIcon32::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_sol@2x.png') no-repeat center;background-size: 34px;}                // 개인금융관리
                &.Custom_ecoIcon33::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_support@2x.png') no-repeat center;background-size: 34px;}            // 서포트
                &.Custom_ecoIcon34::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_smartlink.png') no-repeat center;background-size: 34px;}             // 스마트링크
                &.Custom_ecoIcon35::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_whistlenote@2x.png') no-repeat center;background-size: 34px;}        // 휘슬노트
                &.Custom_ecoIcon36::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_redcaptour@2x.png') no-repeat center;background-size: 34px;}         // 레드캡투어
                &.Custom_ecoIcon37::after{content:'';position:absolute;width:100%;height:100%;z-index:2;background:url('../imgs/main/ecoPlace/ic_logispot@2x.png') no-repeat center;background-size: 34px;}           // 로지스팟
            }
        }
    }
    .imageAttach{
        .imageBox{position:relative;width:137px;height:137px;border:1px solid #cccccc;box-sizing: border-box;margin:0 8px 0 0;
            &::before{content:'NO IMAGE';position:absolute;top:0;display:block;font-size:13px;font-weight:bold;line-height:18px;color: #a6a6a6;text-align: center;width:100%;height:100%;padding:58px 0;box-sizing:border-box;z-index:0;}
            img{position:relative;width:100%;z-index:1;}
        }
        .text{font-size:12px;font-weight:normal;line-height:18px;color: #a6a6a6;margin:0 0 5px 0;justify-content: end;}
    }
    .info_btn{background:url("../imgs/ico/ic_info_01_s_normal.png") no-repeat 0px 7px;width: 15px;height: 27px;margin: 0 5px 0 0;cursor: pointer;}
}

// 게시판 설정 
.SetTemp03Popup{height: 100%; 
    [class*="OBTConditionItem_item"]{width: 100% !important;} 
    .GridType01{ 
        .gridHeader{ 
            th{padding: 5px 0;} 
        } 
        .gridBody{ 
            tr{ 
                &:hover td{background-color:#ffffff;} 
                &:active td{background-color:#ffffff;} 
                &.on{background-color:#ffffff;} 
                td{padding: 0; border: none; 
                    div{ 
                        .treeBox{border: none;} 
                    } 
                } 
            } 
        } 
    } 
}

/***************************** 
    상단 알림바
******************************/
.bannerPop_wrap{width: 100%; min-height: 70px; padding: 0 40px; background-image: linear-gradient(to right, #d1edff, #b2d5ff); box-sizing: border-box; overflow: hidden;
    .alarmImg{width: 90px; height: 70px; background: url('../imgs/ico/img_notice@2x.png') no-repeat center center; background-size: 126px 70px;}
    .alarmText{margin: 0 20px 0 0; font-size: 15px; font-weight: bold; color: #0c2b65;}
    .bannerAlarmBox{margin: 0 12px 0 0;
        .statusLabel{height: 20px; line-height: 20px; margin: 0 4px 0 0; padding: 0 8px; font-size: 11px; font-weight: bold; border-radius: 2px; box-sizing: border-box;
            &:last-child{margin: 0;}
        }
    }
    .notiTitle{margin: 0 10px 0 0; font-size: 13px; color: #0c2b65;
        p{cursor: pointer;}
    }
    .closeBtn{width: 18px; height: 18px; background: url('../imgs/ico/ic_close_01_m_normal.png') no-repeat center center; background-size: 18px; cursor: pointer;}
}

/***************************** 
    뉴스
******************************/
.newPortalNewsWrap{position: relative;width:100%;height:100%;padding:30px 10px 0 30px;border-top:1px solid #e6e6e6;background-color:#f0f5fa;box-sizing: border-box;
    .newsContents{
        .main{box-sizing: border-box;
            .newsMain{height:250px;
                .newsImg{position: relative; width:360px;height:210px;border-radius:4px;overflow:hidden;cursor: pointer;
                    img{width:100%;transition:transform .2s cubic-bezier(.165,.84,.44,1);transform:scale(1);} 
                    &:hover img{transition:transform .2s cubic-bezier(.165,.84,.44,1);transform:scale(1.05);} 
                    .imgTitle{position: absolute; max-height: 58px; bottom: 0; left: 0; right: 0; padding: 10px; color: #ffffff; background-color: rgba(0, 0, 0, 0.54); box-sizing: border-box;
                        > p {font-size:13px;}
                    }
                }
                .newsList{margin:0 0 0 20px;
                    ul{margin:-12px 0 0 0;
                        li{height: 46px;border-top: 1px solid #e6e6e6;font-size: 13px;color: #000000;cursor: pointer;
                            .txt{line-height: 46px;}
                            &:first-child{border:none;}
                            &:hover{text-decoration:underline;}
                        }
                    }
                }
            }
            .categoryNews{position: relative;padding:11px 20px 20px 20px;
                .btnViewer{position:absolute;top:11px;right:20px;}
                .newsTitle{height:39px;padding:4px 0 15px 0;border-bottom:1px solid #e6e6e6;box-sizing: border-box;
                    .tit{font-size:14px;font-weight:bold;letter-spacing:-0.35px;color:#000000;}
                    .arrowBtn{margin:2px 0 0 4px;width:14px;height:14px;background:url('../imgs/ico/ic_arrow_right_01_s_normal.png') no-repeat center;cursor: pointer;}
                }
                .thumbView .newsContents{padding:18px 10px;gap:20px;box-sizing: border-box;flex-wrap: wrap;}
                .listView .newsContents{padding:0px 10px;gap:0px;box-sizing: border-box;}
                .newsContents{
                    .newsCard{min-width: 152px;max-width:152px;height: 150px;padding: 11px 11px 8px 11px;border-radius: 4px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);border: solid 1px #e6e6e6;background-color: #ffffff;box-sizing: border-box;
                        .newsImg{width:100%;height:88px;border-radius:3px;overflow:hidden;cursor: pointer;
                            img{width:100%;transition:transform .2s cubic-bezier(.165,.84,.44,1);transform:scale(1);} 
                            &:hover img{transition:transform .2s cubic-bezier(.165,.84,.44,1);transform:scale(1.05);} 
                        }
                        .newsTxt{margin:5px 0 0 0;font-size:13px;line-height:18px;letter-spacing:-0.65px;color:#000000;cursor: pointer;
                            &:hover{text-decoration:underline;}
                        }
                    }
                    .newsCardList{padding:18px 0;border-top: solid 1px #e6e6e6;
                        &:first-child{border:none;}
                        .newsImg{width:146px;height:100px;border-radius:4px;overflow:hidden;cursor: pointer;
                            img{width:100%;transition:transform .2s cubic-bezier(.165,.84,.44,1);transform:scale(1);} 
                            &:hover img{transition:transform .2s cubic-bezier(.165,.84,.44,1);transform:scale(1.05);} 
                        }
                        .newsTit{margin:0 0 10px 0;font-size:13px;font-weight:bold;letter-spacing:-0.33px;color:#000000;cursor: pointer;
                            &:hover{text-decoration:underline;}
                        }
                        .newsTxt{margin:0 0 17px 0;font-size:12px;line-height:20px;color:#000000;cursor: pointer;
                            &:hover{text-decoration:underline;}
                        }
                        .name{font-size:11px;color: #8c8c8c;}
                        .date{font-size:11px;color: #8c8c8c;}
                        .line{margin:0 4px;width:1px;height:8px;background-color:#e6e6e6;}
                    }
                }
            }
        }
        .sub{box-sizing: border-box;
            .newPortalNewsDetails{
                .titleWrap{padding: 0 0 16px 10px; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;
                    .imgBox{display: flex; align-items: center; justify-content: center; width: 140px; height: 56px; padding: 0 20px 0 10px; margin-right: 20px; border-right: 1px solid #e6e6e6; overflow: hidden; box-sizing: border-box;
                        img{width: 100%;}
                    }
                    .titleBox{
                        .top{font-size: 20px; font-weight: bold; letter-spacing: -1px; color: #000000; word-break: break-all; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
                        .btm{
                            .name{font-size: 12px; color: #8c8c8c;
                                > span::before{content: ''; display: inline-block; width: 1px; height: 10px; margin: 0 10px; background-color: #e6e6e6;}
                            }
                            .linkBtn{display: flex; justify-content: center; align-items: center; height: 24px; padding: 0 8px; margin-left: 8px; font-size: 12px; color: #000000; border: 1px solid #e6e6e6; border-radius: 12px; box-sizing: border-box; cursor: pointer;}
                        }
                    }
                    .btnImg img{width: 18px; height: 18px;}
                }
                .articleWrap{padding: 30px 10px;
                    h3{margin: 0 0 20px 0; color: #000; font-size: 18px; }
                    p{font-size: 15px; line-height: 26px;}
                    img{max-width: 600px; padding:20px 0 10px 0;}
                    figure{margin: 0px; padding:0 0 30px 0;}
                    figcaption{font-size: 14px; line-height: 26px;}
                }
                .listWrap{padding: 30px 10px 0; border-top: 1px solid #e6e6e6; box-sizing: border-box;
                    p{font-size: 12px; font-weight: bold; color: #8c8c8c;}
                    ul{margin-top: 20px; gap: 6px;
                        li{padding-left: 24px; font-size: 12px; color: #8c8c8c; word-break: break-all; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; cursor: pointer;
                            &:hover{text-decoration: underline;}
                            &.arrUpTwin{background: url('../imgs/ico/ic_arrow_up_twin_01_s_normal@2x.png') no-repeat left center; background-size: 14px;}
                            &.arrUp{background: url('../imgs/ico/ic_arrow_up_01_s_normal@2x.png') no-repeat left center; background-size: 14px;}
                            &.on{color: #1c90fb; background: url('../imgs/ico/ic_minus_s_over@2x.png') no-repeat left center; background-size: 14px;}
                            &.arrDown{background: url('../imgs/ico/ic_arrow_down_01_s_normal@2x.png') no-repeat left center; background-size: 14px;}
                            &.arrDownTwin{background: url('../imgs/ico/ic_arrow_down_twin_01_s_normal@2x.png') no-repeat left center; background-size: 14px;}
                        }
                    }
                }
            }
        }
    }
    .newsSide{position:relative;width:252px;margin:0 20px 0 20px;box-sizing: border-box;
        .side{
            .navBox{gap:4px; margin:0 0 20px 0;min-height:100px;padding-top: 4px;border-radius: 4px;box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);background-image:linear-gradient(144deg, #3dc8d9 -1%, #4791f5 99%);box-sizing: border-box;
                .listTop{height: 44px; padding: 0 20px 0 42px; font-size: 14px; font-weight: bold; color: #ffffff; background: url('../imgs/ico/ic_view_list_02_w_m_normal@2x.png') no-repeat left 20px center; background-size: 18px;
                    .setting{width: 18px; height: 18px; font-weight: normal; background: url('../imgs/ico/ic_settine_01_w_m_normal@2x.png') no-repeat center center; background-size: 18px; cursor: pointer;}
                }
                .list{gap: 4px; padding-bottom: 4px;
                    li{display: flex; align-items: center; height: 44px; line-height: 44px; padding: 0 20px; font-size: 15px; color: #ffffff; cursor: pointer;
                        &.on{font-weight: bold; background-color: rgba(0, 44, 95, 0.5);}
                    }
                }
                .settingWrap{position: relative; margin-top: 2px; border: 1px solid #a6a6a6; background-color: #ffffff; box-sizing: border-box;
                    &::after{content: ''; z-index: 1; position: absolute; top: -7px; right: 22px; display: block; width: 0; border-color: #ffffff transparent; border-style: solid; border-width: 0 6px 8px 6.5px;}
                    &::before {content: ''; z-index: 0; position: absolute; top: -8px; right: 22px; display: block; width: 0; border-color: #a6a6a6 transparent; border-style: solid; border-width: 0 6px 8px 6.5px;}
                    .titleBox{margin: 22px 21px 15px;
                        .mainT{font-size: 14px; font-weight: bold; color: #000000}
                        .subT{margin-top: 2px; font-size: 11px; color: #a6a6a6}
                    }
                    ul{margin: 0 21px; border-top: 1px solid #e6e6e6;
                        li{padding: 13px 18px; font-size: 13px; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;}
                    }
                    .btnBox{gap: 3px; height: 62px; margin-top: 20px; background-color: #f7f7f7;}
                }
            }
            .topView{padding:15px 20px;
                .title{padding:0 0 14px 0;font-size:14px;font-weight:bold;letter-spacing:-0.35px;color:#000000;border-bottom:1px solid #e6e6e6;}
                ul{padding:5px 0;
                    li{margin:15px 0 0 0;cursor: pointer;
                        .txt{font-size:13px;color:#000000;}
                        &:hover > div:last-child{text-decoration:underline;}
                    }
                }
            }
        }
    }
    // 공통 박스
    .unitBox{margin:0 0 20px 0;padding:20px;border-radius: 4px;box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);background-color: #fff;box-sizing: border-box;}
    // 공통 툴팁
    .newsTt{max-width: 100%; line-height: normal; font-weight: normal;}
}

/***************************** 
    Footer (개인정보처리방침)
******************************/
.newPortalFooterWrap{min-height: 115px;
    .newPortalFooter{width: 1100px; padding: 0 20px; margin: 0 auto; box-sizing: border-box;
        .corpArea{
            ul{
                li{display: flex; flex-direction: row; align-items: center; 
                    .txt{font-size: 12px; letter-spacing: -0.3px; color: #828282; cursor: pointer;}
                    &::after{content: ''; display: inline-block; width: 1px; height: 10px; margin: 0 10px; background-color: rgba(0, 0, 0, 0.12);}
                    &:last-child::after{display: none;}
                }
            }
        }
        .line{width: 100%; height: 1px; margin: 8px 0; background-color: #e6e6e6;}
        .copy{font-size: 12px; letter-spacing: -0.3px; color: #828282;}
    }
}