.conditionPanelWrapper {
    margin-bottom: 10px;
}

.functionPanelWrapper {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .buttonsContainer {
        flex: 1 0 auto;
        display: flex;
        justify-content: flex-end;
        gap: 5px;

        &>.funcButtonsWrapper {
            display: flex;
            gap: 5px;
        }
    }
}

.referencePanelWrapper {
    margin-top: 10px;
}