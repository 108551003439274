// $labelWidth: 120px;
// $contentWidth: 170px;
$labelWidth: 102px;
$contentWidth: 178px;

.item {
  display: flex;
  flex-direction: row;
  //width: calc(#{$labelWidth} + #{$contentWidth});
  margin: 4px;
  position: relative;

  .label {
    line-height: 27px;
    color: #000000;
    font-size: 12px;
    min-width: $labelWidth;
    text-align: right;
    margin-right: 6px;
    // max-width: $labelWidth;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;

    &.showTooltip {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      &>.tooltip {
        margin-right: 5px;
        text-align: left;
      }
    }

    &.overflow {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: $labelWidth;

      &>.tooltip {
        margin-right: 5px;
        text-align: left;
      }
    }
  }

  &:not(.optional)>.label {
    font-weight: bold;
  }

  .editing {
    position: absolute;
    inset: -2px;
    border: 1px dashed #46A3F0;
    background-color: rgba($color: #46A3F0, $alpha: 0.1);
    border-radius: 2px;
    cursor: move;
    user-select: none;
  }

  &.moving {
    .editing {
      border-color: #f0c325;
      background-color: rgba($color: #f0c325, $alpha: 0.1);
    }
  }

  &:first-child:last-child {
    .editing {
      border-color: #9DA3AA;
      background-color: rgba($color: #9DA3AA, $alpha: 0.1);
      cursor: not-allowed;
    }
  }
}


.tooltipWrapper {
  position: relative;
  width: inherit;
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.spaceBetweenChildren {
    &>*:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.requiredTooltip {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;

  &>div:first-child {
    width: 100%;
    height: 100%;
  }
}

.overflowTooltip {
  line-height: 27px;
  height: 100%;
  max-width: calc(100% - 23px);
  text-align: left;

  &>.oveflowText {
    display: inline-block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.labelTextTooltip {
  line-height: 27px;
  height: 100%;
}

.noInfoTooltip {
  max-width: 100%;
}