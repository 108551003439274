.lineThrough {
    content: "";
    position: absolute;
    top: 50%;
    left: 5px;
    right: 5px;
    height: 1px;
    background-color: #2b333b;
}

.arrowBtn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px;
    border:0 none;
    background-image: none !important;
    cursor: pointer;
}