.topDiv {
    // width: calc(100% - 24px);
    width:100%;
    height: 23px;
    margin: 16px 0 8px 0;
    // padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > .leftTopDiv {
        flex:14.5;
        // font-family: NotoSansCJKkr;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: #010101;
    }

    & > .middleTopDiv {
        flex:1;
    }

    & > .rightTopDiv {
        flex:14;
        & > .rightTopLeftSubDiv {
            float: left;
            width: 30%;
            height: 44px;

            display: flex;
            flex-direction: row;
            align-items: center;

            // font-family: NotoSansCJKkr;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: right;
            color: #010101;
        }
        & > .rightTopRightSubDiv {
            float: left;
            width: 70%;
            height: 44px;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .arrowBtn {
                margin: 0px 0px 0px 4px;
                padding: 0px;
            }
        }
    }
}

.topButtonDiv {
    margin: 0px 0px 5px 0px;
    display: flex;
    flex-direction: row-reverse;
}

.fillDiv {
    margin: 5px 0px 5px 0px;
    width: 100%;
    height: calc(100% - 69px);
    & > .leftDiv {
        float: left;
        width: calc(50% - 23.5px);
        height: 100%;
        & > .leftFillDiv {
            width: 100%;
            height: 100%;
        }
    }

    & > .middleDiv {
        float: left;
        width: 47px;
        height: 100%;
        display: table;
        text-align: center;

        & > .middleSubDiv {
            display:table-cell;
            vertical-align:middle;

            & > * {
                margin: 0px 0px 4px 0px;
            }
        }
    }

    & > .rightDiv {
        float: left;
        width: calc(50% - 23.5px);
        height: 100%;
        & > .rightFillDiv {
            width: 100%;
            height: 100%;
        }
    }
}

.alertPre {
    // font-family: NotoSansCJKkr;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.bullet{
    border-radius: 50%;
    margin-right:4px;
    width:3px;
    height:3px;
    background-color: #000000;
}

.saveButton{
    margin-right:4px;
}

.optionalButton{
    margin-right:4px;
}