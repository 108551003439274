@charset "utf-8";
/**************************************************
   reset CSS
**************************************************/
html,body{width:100%;height:100%;font-size:12px;-webkit-text-size-adjust:none;}
body,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,th,td,form,fieldset,legend,input,textarea,img,button,select,pre{margin:0;padding:0}
body,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,button,pre{font-family:'NotoSans','MS PGothic','돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif}
img,fieldset{border:0;vertical-align:top}
table{border-collapse: collapse;border-spacing: 0;}
a{color:#1a1a1a}
em,address{font-style:normal}
ul,ol,li{list-style:none}
label,button{cursor:pointer}
input::-ms-clear{display:none}
input:disabled{-webkit-text-fill-color:#8d8d8d;opacity:1}
iframe{overflow:hidden;margin:0;padding:0;border:0}

*{-webkit-overflow-scrolling:touch;} /* 모바일에서 터치 부드러운 효과 적용 */

#editorContent ul, #editorContent ol, #editorContent li {list-style: inherit;}
#editorContent ol{list-style-type: decimal;}

// 임시유지 (제거대상)
@font-face {
  font-family: 'NSKR';
  src:
   // url('../font/NotoSans/NotoSans-Regular.eot'),
   url('../font/NotoSans/NotoSans-Regular.woff2') format('woff2'),
   url('../font/NotoSans/NotoSans-Regular.woff') format('woff'),
   url('../font/NotoSans/NotoSans-Regular.otf') format('opentype'),
}
@font-face {
  font-family: 'NSKB';
  src:
   // url('../font/NotoSans/NotoSans-Bold.eot'),
   url('../font/NotoSans/NotoSans-Bold.woff2') format('woff2'),
   url('../font/NotoSans/NotoSans-Bold.woff') format('woff'),
   url('../font/NotoSans/NotoSans-Bold.otf') format('opentype'),
}

// 공통 font-face (2023-03-08 수정) 
@font-face {  
   font-family: 'NotoSans';  
   font-weight: 400;  
   src:
   // url('../font/NotoSans/NotoSans-Regular.eot'),  
   url('../font/NotoSans/NotoSans-Regular.woff2') format('woff2'),  
   url('../font/NotoSans/NotoSans-Regular.woff') format('woff'),  
   url('../font/NotoSans/NotoSans-Regular.otf') format('opentype'),  
}  
@font-face {  
   font-family: 'NotoSans';  
   font-weight: 700;  
   src:
   // url('../font/NotoSans/NotoSans-Bold.eot'),  
   url('../font/NotoSans/NotoSans-Bold.woff2') format('woff2'),  
   url('../font/NotoSans/NotoSans-Bold.woff') format('woff'),  
   url('../font/NotoSans/NotoSans-Bold.otf') format('opentype'),  
}

// 중문 대응
@font-face {
   font-family: 'MS PGothic';   
   src:url('../font/MSPGothic/msgothic.ttc') format('truetype'),
}

/**************************************************
    Webfont :: '월인석보체(낙관,직인,인장)'
**************************************************/
@font-face {
   font-family: 'stamp';
   src: url('../font/Wolin/Wolin.woff') format('woff');
}


/* 크롬 input 자동완성 배경 초기화 */
input:-webkit-autofill {box-shadow:0 0 0 30px #fff inset;-webkit-text-fill-color:#000;}
input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active {transition:background-color 5000s ease-in-out 0s;}

/* PlaceHolder 예외 처리 */
::-webkit-input-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
:-moz-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
::-moz-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
:-ms-input-placeholder{text-align:left !important;color:#999 !important;padding-top:0px !important;font-family: 'NotoSans' !important;}
::-ms-input-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
::placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
input::-webkit-input-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
input:-moz-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
input::-moz-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
input:-ms-input-placeholder{text-align:left !important;color:#999 !important;padding-top:0px !important;font-family: 'NotoSans' !important;}
input::-ms-input-placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}
input::placeholder{text-align:left !important;color:#999;padding-top:0px !important;font-family: 'NotoSans' !important;}

/**************************************************
    Common
**************************************************/
.accessibility {overflow:hidden;position:absolute;left:-1000px;top:0;width:0;height:0;font-size:0;line-height:0;visibility:hidden;text-indent:-9999px}
.hidden {overflow:hidden;position:absolute;top:-10000px;left:-10000px;width:1px;height:1px}
.blind {overflow:hidden;position:absolute;left:0;top:0;width:0;height:0;margin:0;padding:0;font-size:0;line-height:0;visibility:hidden;}

.skip {display:none;}
.ellipsis{overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis}
.ellipsis2{overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis}
.ellipsis3{overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis}
.ellipsis4{overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	/*말둘임 두줄 크롬만 가능하기때문에 크롬만 마춤*/
	.ellipsis2{display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;text-overflow: ellipsis;white-space:normal;}
   .ellipsis3{display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;text-overflow: ellipsis;white-space:normal;}
   .ellipsis4{display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden;text-overflow: ellipsis;white-space:normal;}
}


.no_ellipsis{white-space:normal !important}
.td_ellipsis{text-overflow:ellipsis;white-space:nowrap;overflow:hidden;padding:0 10px !important;}
.mtImg{vertical-align:middle; margin-top:-2px;}

.ovh{overflow:hidden !important;}
.ovv{overflow:visible !important;}

.eventLink{cursor: pointer;}
.link{position: relative;cursor: pointer;
   &:after{
      content:"";position: absolute;left: 0;bottom:0;width: 0%;height: 1px;background:#000000;transition: all 0.5s;}
   &:hover:after{width:100%;transition: all 0.5s;}
}
.spBar10 {color:#e6e6e6;font-size:10px;display:inline-block;}


/* flex */
.h-box{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;min-width:0;}
.v-box{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;min-width:0;}
.flex-1{-webkit-flex:1;flex:1;min-width:0;}
.flex-2{-webkit-flex:2;flex:2;min-width:0;}
.flex-3{-webkit-flex:3;flex:3;min-width:0;}
.h-align-start{justify-content:flex-start;} 
.h-align-center{justify-content:center;} 
.h-align-end{justify-content:flex-end;} 
.v-align-start{align-items:flex-start;} 
.v-align-center{align-items:center;} 
.v-align-end{align-items:flex-end;} 
.flex-center{justify-content:center;align-items:center;}

/*scroll*/
.scroll_on{overflow:auto !important;}
.scroll_off{overflow:hidden !important;}
.scroll_y_on{overflow-x:hidden !important; overflow-y:auto !important;}
.scroll_x_on{overflow-y:hidden !important; overflow-x:auto !important;}
.scroll_fix{overflow:scroll !important;}
.scroll_y_fix{overflow-x:hidden !important; overflow-y:scroll !important;}
.scroll_x_fix{overflow-y:hidden !important; overflow-x:scroll !important;}

/*position*/
.posi_re {position:relative !important;}
.posi_ab {position:absolute !important;}
.posi_fix {position:fixed !important;}
.posi_inh {position:inherit !important;}

/* Floating */
.fl {float:left !important;}
.fr {float:right !important;}
.cl {clear:both !important;}

/*각 자식요소들 floating 지정으로 높이 인식못하는거 해결*/
.clear:after{content:"";display:block;clear:both;height:0;visibility:hidden;}

/* display */
.dp_ib {display:inline-block !important;}
.dp_i {display:inline !important;}
.dp_b {display:block !important;}
.dp_n {display:none !important;}
.dp_g {display:grid !important;}

/* visibility */
.vv {visibility:visible;}
.vh {visibility:hidden;}

/* Align */
.al {text-align:left !important;}
.ac {text-align:center !important;}
.ar {text-align:right !important;}

.vt {vertical-align:top !important;}
.vm {vertical-align:middle !important;}
.vb {vertical-align:bottom !important;}

.alvm{align-items: center; display: flex;}
.acvm{text-align: center; display: table-cell; vertical-align: middle; padding:0px;}
.lh1{line-height:1 !important;}
.lh12{line-height:12px !important;}
.lh13{line-height:13px !important;}
.lh14{line-height:14px !important;}
.lh15{line-height:15px !important;}
.lh16{line-height:16px !important;}
.lh17{line-height:17px !important;}
.lh18{line-height:18px !important;}
.lh19{line-height:19px !important;}
.lh20{line-height:20px !important;}
.lh21{line-height:21px !important;}
.lh22{line-height:22px !important;}
.lh23{line-height:23px !important;}
.lh24{line-height:24px !important;}
.lh25{line-height:25px !important;}
.lh26{line-height:26px !important;}
.lh27{line-height:27px !important;}
.lh28{line-height:28px !important;}
.lh29{line-height:29px !important;}
.lh30{line-height:30px !important;}

.text_line {text-decoration:underline;}
.delLine{text-decoration:line-through; color:red;}
.text_ho:hover{color: #1c90fb;text-decoration: underline;}

/* text-indent */
.ti0 {text-indent:0px !important;}
.ti4 {text-indent:4px !important;}
.ti7 {text-indent:7px !important;}

/* Font color */
.text_white{color:#fff !important;}
.text_blue{color:#1c90fb !important;}
.text_green{color:#3fa684 !important;}
.text_orange{color:#ff4d00 !important;}
.text_red{color:#ff0000 !important;}
.text_redline{color:#e85e30 !important; text-decoration:line-through !important;}
.text_gray{color:#a6a6a6 !important;}
.text_yellow{color:#f09618 !important;}
.colB {color:#1c90fb !important;font-weight:bold !important;}
.colB td {color:#1c90fb !important;font-weight:bold !important;}
.k-disabled{color:#cececc !important;}

.text_blue input {color:#1c90fb !important;}
.text_red input {color:#ff0000 !important;}

/* border color */
.brn {border:none !important;}
.brrn {border-right:none !important;}
.brln {border-left:none !important;}
.brtn {border-top:none !important;}
.brbn {border-bottom:none !important;}

.Pop_border{border:1px solid #dcdcdc;}
.borderT{border-top:1px solid #dcdcdc;}
.borderB{border-bottom:1px solid #dcdcdc;}
.borderR{border-right:1px solid #eaeaea !important;}
.borderR_black{border-right:1px solid #000;}
.borderL{border-left:1px solid #eaeaea !important;}
.tb_borderT{border-top:1px solid #eaeaea !important;}
.tb_borderB{border-bottom:1px solid #eaeaea !important;}
.bd_blue {border:1px solid #54b5ff !important;}
.borderT2{border-top:2px solid #666;}

/* background */
.bgn{background:none !important;}
.bg_white{background:#ffffff !important;}
.bg_gray{background:#f5f5f5 !important;}
.bg_blue{background:#56a8f4 !important;}
.bg_red{background:#f33e51 !important;}
.bg_total{background:#f1f1f1 !important;}

/* auto */
.hei_auto {height:auto !important;}
.wid_auto {width:auto !important;}

/*letter-spacing*/
.let0 {letter-spacing:0px !important;}
.let1 {letter-spacing:-1px;}
.let1n5 {letter-spacing:-1.5px;}
.let2 {letter-spacing:-2px;}
@media screen and (-webkit-min-device-pixel-ratio:0) {.let-1 {letter-spacing:-1px;}}

/* Font size */
.f11 {font-size:11px !important;}
.f12 {font-size:12px !important;}
.f13 {font-size:13px !important;}
.f14 {font-size:14px !important;}
.f15 {font-size:15px !important;}
.f16 {font-size:16px !important;}
.f17 {font-size:17px !important;}
.f18 {font-size:18px !important;}
.f19 {font-size:19px !important;}
.f20 {font-size:20px !important;}
.f23 {font-size:23px !important;}
.f24 {font-size:24px !important;}
.f30 {font-size:30px !important;}

/* Font weight */
.fwb {font-weight:bold !important;}
.fwn {font-weight:normal !important;}

/* word-break */
.word_b{word-break:break-all;}

.boxBorder {box-sizing: border-box;}
.boxContent {box-sizing: content-box;}

/* 100per */
.w100per {width:100% !important;}
.h100per {height:100% !important;}

/* Padding, Margin style */ 
/* 특별한 case외에 사용 자제 */
.p0 {padding:0 !important;}
.p10 {padding:10px !important;}
.p15 {padding:15px !important;}
.p20 {padding:20px !important;}

.pt0 {padding-top:0 !important;}
.pt1 {padding-top:1px !important;}
.pt2 {padding-top:2px !important;}
.pt3 {padding-top:3px !important;}
.pt4 {padding-top:4px !important;}
.pt5 {padding-top:5px !important;}
.pt6 {padding-top:6px !important;}
.pt10 {padding-top:10px !important;}
.pt13 {padding-top:13px !important;}
.pt15 {padding-top:15px !important;}
.pt17 {padding-top:15px !important;}
.pt20 {padding-top:20px !important;}
.pt25 {padding-top:25px !important;}
.pt30 {padding-top:30px !important;}
.pt35 {padding-top:35px !important;}
.pt40 {padding-top:40px !important;}
.pt45 {padding-top:45px !important;}
.pt50 {padding-top:50px !important;}

.pb0 {padding-bottom:0 !important;}
.pb1 {padding-bottom:1px !important;}
.pb2 {padding-bottom:2px !important;}
.pb3 {padding-bottom:3px !important;}
.pb4 {padding-bottom:4px !important;}
.pb5 {padding-bottom:5px !important;}
.pb10 {padding-bottom:10px !important;}
.pb12 {padding-bottom:12px !important;}
.pb13 {padding-bottom:13px !important;}
.pb15 {padding-bottom:15px !important;}
.pb20 {padding-bottom:20px !important;}
.pb25 {padding-bottom:25px !important;}
.pb30 {padding-bottom:30px !important;}
.pb35 {padding-bottom:35px !important;}
.pb40 {padding-bottom:40px !important;}
.pb45 {padding-bottom:45px !important;}
.pb50 {padding-bottom:50px !important;}

.pl0 {padding-left:0px !important;}
.pl5 {padding-left:5px !important;}
.pl9 {padding-left:9px !important;}
.pl10 {padding-left:10px !important;}
.pl12 {padding-left:12px !important;}
.pl13 {padding-left:13px !important;}
.pl15 {padding-left:15px !important;}
.pl19 {padding-left:19px !important;}
.pl20 {padding-left:20px !important;}
.pl25 {padding-left:25px !important;}
.pl30 {padding-left:30px !important;}
.pl35 {padding-left:35px !important;}
.pl40 {padding-left:40px !important;}
.pl45 {padding-left:45px !important;}
.pl50 {padding-left:50px !important;}
.pl55 {padding-left:55px !important;}
.pl60 {padding-left:60px !important;}
.pl70 {padding-left:70px !important;}
.pl80 {padding-left:80px !important;}
.pl120 {padding-left:120px !important;}

.pr0 {padding-right:0px !important;}
.pr4 {padding-right:4px !important;}
.pr5 {padding-right:5px !important;}
.pr7 {padding-right:7px !important;}
.pr10 {padding-right:10px !important;}
.pr15 {padding-right:15px !important;}
.pr17 {padding-right:17px !important;}
.pr20 {padding-right:20px !important;}
.pr24 {padding-right:24px !important;}
.pr25 {padding-right:25px !important;}
.pr30 {padding-right:30px !important;}
.pr35 {padding-right:35px !important;}
.pr40 {padding-right:40px !important;}
.pr45 {padding-right:45px !important;}
.pr50 {padding-right:50px !important;}
.pr70 {padding-right:70px !important;}

.m0 {margin:0px !important;}
.m5 {margin:5px !important;}

.mt-1 {margin-top:-1px !important;}
.mt-5 {margin-top:-5px !important;}
.mt-15 {margin-top:-15px !important;}
.mt-20 {margin-top:-20px !important;}
.mt-44 {margin-top:-44px !important;}
.mt0 {margin-top:0px !important;}
.mt1 {margin-top:1px !important;}
.mt2 {margin-top:2px !important;}
.mt3 {margin-top:3px !important;}
.mt4 {margin-top:4px !important;}
.mt5 {margin-top:5px !important;}
.mt6 {margin-top:6px !important;}
.mt7 {margin-top:7px !important;}
.mt8 {margin-top:8px !important;}
.mt9 {margin-top:9px !important;}
.mt10 {margin-top:10px !important;}
.mt12 {margin-top:12px !important;}
.mt14 {margin-top:14px !important;}
.mt15 {margin-top:15px !important;}
.mt18 {margin-top:18px !important;}
.mt20 {margin-top:20px !important;}
.mt23 {margin-top:23px !important;}
.mt24 {margin-top:24px !important;}
.mt25 {margin-top:25px !important;}
.mt27 {margin-top:27px !important;}
.mt28 {margin-top:28px !important;}
.mt30 {margin-top:30px !important;}
.mt35 {margin-top:35px !important;}
.mt40 {margin-top:40px !important;}
.mt45 {margin-top:45px !important;}
.mt50 {margin-top:50px !important;}
.mt70 {margin-top:70px !important;}

.mb0 {margin-bottom:0px !important;}
.mb1 {margin-bottom:1px !important;}
.mb2 {margin-bottom:2px !important;}
.mb3 {margin-bottom:3px !important;}
.mb4 {margin-bottom:4px !important;}
.mb5 {margin-bottom:5px !important;}
.mb6 {margin-bottom:6px !important;}
.mb7 {margin-bottom:7px !important;}
.mb8 {margin-bottom:8px !important;}
.mb9 {margin-bottom:9px !important;}
.mb10 {margin-bottom:10px !important;}
.mb13 {margin-bottom:13px !important;}
.mb14 {margin-bottom:14px !important;}
.mb15 {margin-bottom:15px !important;}
.mb17 {margin-bottom:17px !important;}
.mb20 {margin-bottom:20px !important;}
.mb21 {margin-bottom:21px !important;}
.mb25 {margin-bottom:25px !important;}
.mb30 {margin-bottom:30px !important;}
.mb35 {margin-bottom:35px !important;}
.mb40 {margin-bottom:40px !important;}
.mb45 {margin-bottom:45px !important;}
.mb50 {margin-bottom:50px !important;}
.mb100 {margin-bottom:100px !important;}

.ml-1 {margin-left:-1px !important;}
.ml-14 {margin-left:-14px !important;}
.ml0 {margin-left:0px !important;}
.ml1 {margin-left:1px !important;}
.ml2 {margin-left:2px !important;}
.ml3 {margin-left:3px !important;}
.ml4 {margin-left:4px !important;}
.ml5 {margin-left:5px !important;}
.ml6 {margin-left:6px !important;}
.ml7 {margin-left:7px !important;}
.ml8 {margin-left:8px !important;}
.ml9 {margin-left:9px !important;}
.ml10 {margin-left:10px !important;}
.ml13 {margin-left:13px !important;}
.ml15 {margin-left:15px !important;}
.ml17 {margin-left:17px !important;}
.ml20 {margin-left:20px !important;}
.ml22 {margin-left:22px !important;}
.ml25 {margin-left:25px !important;}
.ml30 {margin-left:30px !important;}
.ml35 {margin-left:35px !important;}
.ml40 {margin-left:40px !important;}
.ml45 {margin-left:45px !important;}
.ml50 {margin-left:50px !important;}
.ml70 {margin-left:70px !important;}

.mr0 {margin-right:0px !important;}
.mr1 {margin-right:1px !important;}
.mr2 {margin-right:2px !important;}
.mr3 {margin-right:3px !important;}
.mr4 {margin-right:4px !important;}
.mr5 {margin-right:5px !important;}
.mr6 {margin-right:6px !important;}
.mr7 {margin-right:7px !important;}
.mr8 {margin-right:8px !important;}
.mr9 {margin-right:9px !important;}
.mr10 {margin-right:10px !important;}
.mr15 {margin-right:15px !important;}
.mr17 {margin-right:17px !important;}
.mr20 {margin-right:20px !important;}
.mr25 {margin-right:25px !important;}
.mr26 {margin-right:26px !important;}
.mr30 {margin-right:30px !important;}
.mr35 {margin-right:35px !important;}
.mr40 {margin-right:40px !important;}
.mr45 {margin-right:45px !important;}
.mr50 {margin-right:50px !important;}
.mr70 {margin-right:70px !important;}