.root {
  line-height: 0px;
  text-align: center;
  padding-top: 10px;
}

.drop {
  padding-left: 5px;

  div {
    div>span:first-child {
      padding: 0px 24px 0px 7px !important;
      height: 19px !important;
      line-height: 19px !important;
    }

    div>button {
      margin-top: -12px !important;
      height: 21px !important;
    }
  }
}

.numberButton {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;

  & button {
    // 왜 important 지정했는지 잘 모르겠음 숫자 자리수마다 너비 동적으로 지정되도록 OBTPageButton.tsx에서 처리
    // width: 21px !important;
    padding: 0px !important;
  }
}

.button {
  position: relative;
  border: none;
  width: 21px;
  height: 21px;
  background-color: transparent;
  outline: none;
}

.buttonFirst {
  position: relative;
  border: none;
  width: 21px;
  height: 21px;
  background-color: transparent;
  outline: none;
  margin-right: 3px;
}

.img {
  vertical-align: baseline;
}

.page {
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 10px;
  line-height: 1.8;
  letter-spacing: -0.65px;
}

.total {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pageLeft {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  line-height: 14px;
  cursor: default;

  &> {
    .pagetext {
      display: inline-block;
      height: 14px;
      font-weight: bold;
      color: transparent;
    }

    .totalpagetext {
      display: inline-block;
      height: 14px;
      color: transparent;
    }
  }
}

.pageCenter {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pageRight {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  line-height: 14px;

  &> {
    .pagetext {
      display: inline-block;
      height: 14px;
      // font-weight: bold;
      color: #000000;
    }

    .totalpagetext {
      display: inline-block;
      height: 14px;
      color: #8c8c8c;
    }
  }
}