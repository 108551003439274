.root {
    margin: 0;
    padding: 0;
}

.subDiv {
    padding: 3px 6px 1px;
    margin: 0 10px;
    line-height: 28px;
    clear: both;
    overflow: hidden;
}

.span {
    font-size: 12px;
    font-family: inherit;
    float: right;
}

.em {
    color: #1c90fb;
    font-style: normal;
}

.arrowBtn {
    position: absolute;
    top: 0;
    width: 25px;
    // left: calc(100% - 48px);
    cursor: pointer;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    &>img {
        width: 12px;
        transition: transform 0.2s ease;
        transform-origin: center;
    }

    .arrow {
        transform: rotate(0.5turn);
    }
}

img.userProfile {
    display: inline-block;
    position: absolute;
    top: 5px;
    left: calc(100% - 45px);
    cursor: pointer;
}

.dropDownWrapper {
    position: relative;
    transition: height .2s ease-out;
    height: 0;
    max-height: 209px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
    overflow: hidden;

    &.dropDownWrapperAnimate {
        height: 209px;
    }

    .dropdown {
        height: 177px;
        padding-bottom: 32px;
        box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1);
        border-left: solid 1px #a6a6a6;
        border-right: solid 1px #a6a6a6;
        background-color: #ffffff;
        overflow: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        .dropdownItem {
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            word-break: break-all;
            text-overflow: ellipsis;
            line-height: 22px;
            cursor: pointer;

            &:hover {
                background-color: #e9f5ff;
            }

            .deleteIcon {
                position: absolute;
                top: 2px;
                right: 4px;
                cursor: pointer;
                &:hover {
                    background-color: #e9f5ff;
                }
            }

            .userProfileIcon {
                cursor: pointer;
                position: absolute;
                top: 2px;
                right: 23px;
            }
        }
    }

    .downDownBottom {
        background-color: #f2f2f2;
        box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1);
        border-left: solid 1px #a6a6a6;
        border-right: solid 1px #a6a6a6;
        border-bottom: solid 1px #a6a6a6;
        padding: 5px;
        text-align: right;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        .exceptItemToggle {
            margin-right: 2px;
        }
    }
}

.itemOverFlowTooltip {
    display: inherit;
}

.smallLoading {
    &>div>div {
        border: 1.3px solid #d9d9d9 !important;
    }
}

.obtTextField {
    display: inline-block;
    position: relative;
}

.smallLoading {
    &>div>div {
        border: 1.3px solid #d9d9d9 !important;
    }
}