@charset "utf-8";   
   
/*****************************    
    공통   
******************************/   
/* Begin required CSS */   
.line-clamp{   
    display            : block;   
    display            : -webkit-box;       
    -webkit-box-orient : vertical;   
	position           : relative;   
	line-height        : 1.2;   
	overflow           : hidden;   
	text-overflow      : ellipsis;   
	padding            : 0 !important;   
}   
.line-clamp::after{   
	content    : '...';   
	text-align : right;   
	bottom     : 0;   
	right      : 0;   
	width      : 15px;   
	display    : block;   
	position   : absolute;   
	max-height     : calc(1em * 1.2);   
	background : #ffffff   
}   
@supports (-webkit-line-clamp: 1){.line-clamp:after{display : none !important;}}   
.line-clamp-1{-webkit-line-clamp : 1; max-height: calc(1em * 1.2 * 1);}   
.line-clamp-2{-webkit-line-clamp : 2;	max-height: calc(1em * 1.2 * 2);}   
.line-clamp-3{-webkit-line-clamp : 3;	max-height: calc(1em * 1.2 * 3);}   
.line-clamp-4{-webkit-line-clamp : 4;	max-height: calc(1em * 1.2 * 4);}   
.line-clamp-5{-webkit-line-clamp : 5;	max-height: calc(1em * 1.2 * 5);}   
   
/* 모달처리 */   
.modalCSS {background: black; opacity: 0.5; top: 0px; left: 0px; z-index: 2000; width: 100%; height: 100%; position: absolute; filter:alpha(opacity=50);text-align: center;}   
.modalText {position: absolute; z-index: 2000; color: white; font-size: 21.5px; top:50%; margin-top:-11px;}   
.modalFixed {background: black; opacity: 0.4; top: 0px; left: 0px; z-index: 1000; width: 100%; height: 100%; position: fixed; filter:alpha(opacity=40);text-align: center;}   
   
/* 스탬프 (마이페이지 + 전자결재 장용)*/   
.stamp_default{width: 49px; height: 49px; background: url("../imgs/bg/EA_confirm_bg.gif") no-repeat center center; margin: 0 auto; overflow: hidden; word-break: break-all;line-height: 49px;white-space: normal;}   
.stamp{margin: 0px auto; width: 33px; height: 35px; padding:2px 4px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ea4949; border: 5px solid #ea4949; border-radius: 50%; line-height: 16px; letter-spacing: -2px; overflow:hidden;word-break: break-all;white-space: normal;}   
.stamp2{margin: 0px auto; width: 33px; height: 35px; padding:2px 4px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ffffff; background:#ea4949; border: 5px solid #ea4949; border-radius: 50%; line-height: 16px; letter-spacing: -2px;overflow:hidden;word-break: break-all;white-space: normal;}   
.stamp3{margin: 0px auto; width: 33px; height: 35px; padding:2px 4px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ea4949; border: 5px solid #ea4949; border-radius: 20%; line-height: 16px; letter-spacing: -2px;overflow:hidden;word-break: break-all;white-space: normal;}   
.stamp4{margin: 0px auto; width: 33px; height: 35px; padding:2px 4px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ffffff; background:#ea4949; border: 5px solid #ea4949; border-radius: 20%; line-height: 16px; letter-spacing: -2px;overflow:hidden;word-break: break-all;white-space: normal;}   

// 부드러운 스크롤 (OBTScroll컴포넌트에 class로 부여)
.smoothScroll > div:first-child{scroll-behavior:smooth;}
   
/*****************************   
    location   
******************************/   
.location_wrap {   
    position: relative;   
    overflow: hidden;   
    .location_info {overflow: hidden;   
        ul {margin: 17px 0;   
            li { display: inline-block; padding: 0 5px 0 10px; background: url("../imgs/ico/ic_arrow_right_01_s_normal.png") no-repeat left center;    
                &:first-child { background: none; padding: 0 7px 0 1px;}   
                a { font-size: 11px; color: #8d8d8d; text-decoration: none; vertical-align: middle;    
                &:hover {text-decoration: underline;}   
                }   
                img {vertical-align: baseline;}   
            }   
            li.on {   
                a {font-weight: bold;color: #058df5;}   
            }   
        }   
    }   
    .btn_manual { cursor: pointer; position: absolute; top: 20px; right: 0; font-size: 12px; color: #008ef7; background: url("../imgs/ico/ic_question_01_s_over.png") no-repeat 0 0; padding-left: 17px; }   
}   
   
/*****************************   
    컨텐츠 - 공통 타이틀   
******************************/   
.btn_div .tit {font-weight:bold;font-size:14px;line-height: 27px;margin-right: 5px;}   
.tit_p {float:left;background:url("../imgs/ico/ico_dot01.png") no-repeat left center; font-weight:bold;padding-left:8px;font-size:14px;line-height: 27px;margin-right: 5px;}   
.tit_a_down {background:url("../imgs/ico/ic_arrow_down_01_s_normal.png") no-repeat left 5px; font-weight:bold;padding-left:16px;font-size:14px; cursor:pointer; line-height: 27px;margin-right: 5px;}   
.tit_a_up{background:url("../imgs/ico/ic_arrow_up_01_s_normal.png") no-repeat left 5px; font-weight:bold;padding-left:16px;font-size:14px; cursor:pointer; line-height: 27px;margin-right: 5px;}   
.tit_check{font-weight:bold;font-size:14px; cursor:pointer; line-height: 27px;margin-right: 5px;}   
.help_btn{float:left;background:url("../imgs/ico/ic_question_01_s_normal.png") no-repeat 0px 5px;width: 15px;height: 27px;margin: 5px 0 0 5px;cursor: pointer;}   
   
/*****************************   
    폼테이블(기본)   
******************************/   
.com_ta table {width: 100%;   
    th,td {height: 26px; color: #4a4a4a; border: 1px solid #eaeaea; padding: 5px 0;}   
    th {background: #f9f9f9; font-weight: normal; text-align: right; padding-right: 15px;}   
    td {border-right: none; padding-left: 13px; padding-right: 13px; word-break: break-all;}   
    .cen {text-align: center !important; padding-left: 0px; padding-right: 0px;}   
    .ri {text-align: right !important; padding-right: 10px;}   
    .le {text-align: left !important; padding-left: 10px;}   
}   
   
/*****************************   
    상단검색영역 tobBox   
******************************/   
.topBox {position:relative; background:#fff;   
    .searchBox {border:2px solid #e0e0e0;overflow: hidden;/*padding-right: 100px;*/   
        dl{margin-top:9px;margin-bottom:8px;overflow:hidden;float: left;   
            dt{line-height:27px;color:#4a4a4a;box-sizing:border-box;text-align: right;padding-right:6px;font-size:13px;letter-spacing: -0.05em;float:left;padding-left:20px;vertical-align: middle;}   
            dt.btnDt {text-align: left;padding-left: 8px;}       
            dd{float:left;vertical-align: middle;height:27px;
                &.posi_re [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:40px !important;
                    &.notFind [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:0px !important;}
                }
                .posi_re [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:40px !important;
                    &.notFind [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:0px !important;}
                }
            }   
        }   
        .nextDiv{   
            dl{margin-top:0;}   
        }   
        .searBtnSp{position:absolute!important;right:11px;top:9px;}   
        .detailBtnSp {position:absolute!important;right:8px;top:9px;   
            .detailBtn{border:1px solid #a6a6a6 !important;   
                &.down {background:url("../imgs/ico/detail_arr_down01.png") no-repeat center center !important;}   
                &.up {background:url("../imgs/ico/detail_arr_up01.png") no-repeat center center !important;}   
            }   
        }   
        .find_sp {position:absolute;right:1px; top:50%;margin-top:-10px; line-height:1;color:#1c90fb;font-size:12px;height: 20px; line-height: 20px; padding-right: 5px; padding-left: 5px; background: #fff;}               
    }   
    .searchDetail {width:calc( 100% - 2px); background:#fff; position: absolute;left:0px; top:0px;border:1px solid #8d8d8d;display:none;z-index: 12;   
        .searchDetailIn{ margin:16px 0px;overflow:hidden;   
            .sd_div{width:calc( 50% - 60px);padding:0 30px;overflow:hidden;float: left;   
                dl{ float:left;width:100%;   
                    &.sd_dl_half {width:50%;}   
                    dt{text-align: right;font-weight: bold;float: left;min-width:90px;padding:4px 0;padding-right:6px;line-height:27px;letter-spacing: -1px;font-size:12px;}       
                    dd{padding:4px 0;float: left;vertical-align: middle;}   
                    &.sd_dl_half + &.sd_dl_half dt {padding-left:10px;}   
                }   
            }   
        }   
        .btn_area{clear:both;text-align: center;padding:8px 0;overflow: hidden;background:#f8f8f8;}   
        &.show {display:block;top:47px;}   
    }   
    &.topBox_half .searchDetail .sd_div {width:calc( 100% - 60px);}   
    &.mline {   
        .searchBox { padding:9px 0;   
            dl {margin:0;}   
        }   
    }   
    &.grayStyle {   
        background: #f2f2f2;   
        .searchBox {border:1px solid #e0e0e0;}   
    }   
    &.mline.grayStyle {   
        .searchBox { padding:16px 0 6px 10px;   
            dl dd {height:31px;}   
        }   
    }   
}   
.input100 {   
    input[type="text"] {width:100% !important;}   
}   
   
.searBtnStyle{background:url("../imgs/ico/ic_search_s_normal.png") no-repeat right 6px center,url("../imgs/bg/bg_btn01.png") repeat-x center center !important;color:#000 !important;border:1px solid #a6a6a6 !important;padding-right:24px !important;color:#000;font-weight: bold;}   
   
@-webkit-keyframes detailfadeInDown {   
    from { opacity: 0; -webkit-transform: translate3d(0, -40%, 0); transform: translate3d(0, -40%, 0); }   
    to { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }   
  }   
@keyframes detailfadeInDown {   
    from { opacity: 0; -webkit-transform: translate3d(0, -40%, 0); transform: translate3d(0, -40%, 0); }   
    to { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }   
}   
.detailfadeInDown { -webkit-animation-name: detailfadeInDown; animation-name: detailfadeInDown; }   
   
/*****************************   
    pubAvbStyle   
******************************/   
.termStyle {background:url("../imgs/bg/bg_btn01.png") repeat-x center center !important;border:1px solid #a6a6a6 !important;border-right:none !important;   
    &.on{background:url("../imgs/bg/bg_btn02.png") repeat-x center center !important;border:1px solid #1c90fb !important;}   
}   
.termStyle.on + .termStyle {border-left:none !important;border-right:1px solid #a6a6a6 !important;}   
.pubAvbStyle {   
    .avb_set {width:100%;display:flex;   
        .avb_td {display: inline-flex;flex:1;   
            >div {width:100%}   
        }   
        .avb_tit {text-align: right;line-height: 27px;;width:94px;padding-right:6px;display:inline-block;font-size:12px; font-weight: bold;}   
    }      
    &.border1 {   
        [class*="OBTConditionPanel_root"] > [class*="OBTConditionPanel_primaryRoot"] {border:1px solid #e6e6e6;}   
    }   
    &.border2{padding: 4px; border: 2px solid #e6e6e6;}   
}   
.cenMid {vertical-align: middle; text-align: center;display:inline-block; width:20px;}   
.smallTox { width: auto !important;   
    >span {min-width: 50px;}   
}   
.noSearchCondi {   
    [class*="OBTConditionPanel_searchButton"] {display:none;}   
}   
   
/*****************************   
    grid   
******************************/   
.gridPaging { position:relative;text-align:center; padding:9px 0 10px 0;   
    > div {display:inline-block;vertical-align: middle;}   
}   
.gridPaging .page_info {position: absolute;top: 10px;right: 10px;height: 21px;line-height: 21px;}   
.gridPaging div + div > div >span {padding-top:0 !important; padding-bottom:0 !important;height:19px !important;line-height:19px !important;}   
.gridPaging .page_info span {font-size:13px;color:#8c8c8c;word-spacing: -2px;}   
.gridPaging .page_info span.fwb {color:#000;}   
   
/*****************************   
    formTable   
******************************/   
.pubFormStyle{   
    &.borderTopNone {    
        > table {border-top:none;}   
    }   
    >  table {width:100%;border-collapse: collapse !important;box-sizing:border-box;     
        thead{   
            th {border-top:1px solid #e6e6e6;border-collapse: collapse;border-spacing: 0;padding:5px 8px;background: #fafafa; color:#000000;height:29px; font-weight:bold; box-sizing: border-box;}   
        }        
        tbody {border-collapse: collapse;   
            th,td {border-top:1px solid #e6e6e6;border-collapse: collapse;border-spacing: 0;}   
            th {text-align: right; padding:5px 8px 5px;   
                &[rowspan] {border-right:1px solid #e6e6e6;}    
                &[rowspan].noLine {border-right:none}      
            }   
            .infoIcon{display: inline-block; width:14px; height:14px; margin:2px 4px 0 0;background:url('../imgs/ico/ic_info_01_s_normal@2x.png') no-repeat center center; background-size: 14px;}   
            th+th { border-left:1px solid #e6e6e6;}   
            td {text-align: left;padding:5px 8px; word-break: break-all;
                .fullPicker{   
                    > div {width:100% !important;}   
                }   
                .inputPlaceholderRight{   
                    & > div > div > input[type="text"]::placeholder {text-align: right !important;}   
                    & > div > div > input[type="text"]::-ms-input-placeholder{text-align: right !important;}   
                }   
            }   
        }   
        // 필수값처리    
        [class*="OBTAutoCompleteChips_wrapper"].required{background-color:rgb(254, 244, 244) !important;    
           [class*="OBTAutoCompleteChips_inputWrapper"] > input{background-color:rgb(254, 244, 244) !important;}    
        }
        .LS_saoinput {   
            table{   
                th,td {border-top:none;padding:auto !important;}   
                th+th { border-left:none;}   
                td {text-align: left;padding:0px 0px !important;}   
            }   
        }   
        .mline{position:relative;   
            > div { display:block !important;}   
            .engAdd{position:absolute; float:right; right:8px;}   
        }   
    }   
    &.vertical {   
        table{       
            thead{   
                th {text-align: center !important; border-left:1px solid #e6e6e6;   
                    &:first-child {border-left:none;}   
                    &[rowspan] {border-right:1px solid #e6e6e6;}       
                    > div {justify-content: center !important;}   
                    &.le {text-align: left !important;   
                        > div {justify-content:flex-start !important}   
                    }   
                }            
            }          
            tbody{   
                th {text-align: center !important; border-left:1px solid #e6e6e6;   
                    &:first-child {border-left:none;}   
                    &[rowspan] {border-right:1px solid #e6e6e6;}       
                    > div {justify-content: center !important;}   
                    &.le {text-align: left !important;   
                        > div {justify-content:flex-start !important}   
                    }   
                    &.ri {text-align: right !important;   
                        > div {justify-content:flex-end !important}   
                    }   
                }   
                td {height:29px;text-align: center; border-left:1px solid #e6e6e6; background-color:#fff;
                    &:first-child {border-left:none;}   
                    &[rowspan] {border-right:1px solid #e6e6e6;}   
                    > div {justify-content: center !important;}   
                    &.le {text-align: left !important;   
                        > div {justify-content:flex-start !important}   
                    }   
                    &.ri {text-align: right !important;   
                        > div {justify-content:flex-end !important}   
                    }   
                    &.bg{background-color: #f2f6f8;}   
                }       
                tr.on td{background:#e9f5ff;}               
            }   
        }   
        &.h22{   
            tr {height:32px;}    
            td {height:22px;}    
        }   
    }   
   &.BTN{margin-top: -2px;   
        table{border-top:none; width: 100%;}   
    }      
   
    .le{text-align: left !important; padding-left:8px;   
        > div {justify-content: flex-start !important;}   
        }   
    .ri{text-align: right !important; padding-right:8px;   
        > div {justify-content: flex-end !important;}   
        }   
    .cen {padding:0 !important; text-align: center !important;   
        > div {justify-content: center !important;}   
        }         
    .impo {color:#ff3838; line-height:27px;vertical-align: middle;margin-right: 4px;font-size: 13px; height: 20px;}   
    .pubRadioGroupStyle > div > span{line-height: 27px !important;}   
    .byte { color: #8c8c8c;vertical-align: bottom; font-size:12px;}       
   
    .hoverTd {position: relative;   
        .hoverBtn {display:none;position:absolute; width:100%; height:100%;text-align: center; top:0;left:0;background:rgba(0,0,0,0.3);   
            button {position: absolute;top: 50%; margin-top: -14px;left:50%;margin-left:-21px;}   
        }   
    }   
    .hoverTd:hover {   
        .hoverBtn {display:block;}   
    }   
   
    &.hei100per{ height:100%;   
       > table{height:100%;   
            tbody {height:100%;}   
        }   
   
    }   
    &.hei50per{ height:50%;   
        > table{height:100%;   
             tbody {height:100%;}   
        }    
     }   
   
    &.bgLine{background:#fafafa; border:solid #e6e6e6; border-width: 0 1px 1px 1px; box-sizing: border-box; margin-top: 0px;}   
   
    .left_div {float: left;}   
    .right_div {float: right;}   
    .cusSp {cursor:pointer; }   
    .cusSp:hover {text-decoration:underline; }       
    .td_bottom > div {align-items: flex-end !important;}   
     [class*="OBTDatePicker"] {   
        table {   
            thead{   
                th {border-top:none;background: none;}   
                th+th { border-left:none;}   
            }        
            tbody {   
                th,td {border-top:none;}   
                th {text-align: initial; }   
                th+th { border-left:none;}   
                td {text-align: initial;}   
            }   
        }   
    }   
    .total{background-color:#fff1d6;}
}   
   
.svgDiv {width:16px;height:16px;transform-origin: 8px 8px;float:left;animation: rotation 1s forwards infinite;animation-timing-function: linear;   
    .circleBar {stroke-linecap: round; animation: loading 1s infinite;animation-timing-function: linear;}   
}   
   
@keyframes loading {   
    to {stroke-dasharray: 50 100;}   
    50% {stroke-dasharray: 90 100;}   
    from {stroke-dasharray: 50 100;}   
}   
@keyframes rotation {   
  to {transform: rotate(360deg);}   
}   
   
/* OBT컴포넌트 리스트 못쓰는 경우, 리스트형태 폼스타일*/   
.pubFormListStyle{   
    .BTN{margin-top:0px;}   
    .lineOver {padding-right:1px; overflow:hidden;}   
    .pubFormStyle tr:hover td{border-bottom:1px solid #008ef7 !important; border-top:1px solid #008ef7 !important;}   
    tr{position:relative;   
        &:first-child td{border-top:none;}   
        td:first-child{border-left:none;}    
        &:first-child:hover td{border-top:1px solid #008ef7;}             
        &:hover{box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.18); outline:1px solid #008ef7; box-sizing: border-box; border:1px solid #008ef7;                  
            td:first-child{border-left:1px solid #008ef7;}   
            td{border-bottom:1px solid #008ef7;}   
        }           
    }     
    &.drop{   
         tr{   
            &:hover{background:url('../imgs/ico/icon_con_change.png') no-repeat left center;}   
            &:active{background:#e9f5ff url('../imgs/ico/icon_con_change.png') no-repeat left center;}        
         }   
    }   
    &.borT{border-top:2px solid #666; border-left: 1px solid #e6e6e6; border-right: 1px solid #e6e6e6; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;   
        .pubFormStyle > table {border-top:none; border-left:none;   
            tr{   
                td:first-child{border-left:none;}   
                &:first-child:hover{border-top:none;}   
                &:hover td{border-top:none !important;}   
            }   
        }   
    }   
}   
   
/*****************************   
    input text   
******************************/   
.textButton { position:relative;   
    // 카운트 적용 시
    &.typeCount{
        .countInput{
            > div > div > input {padding-right:35px !important;}
        }
        .countNum{position: absolute; right: 2px; top: 3px; color: #1c90fb; font-size: 12px; height: 20px; line-height: 20px; padding: 0 27px 0 5px; background-color: #ffffff;}
    }

    // 툴팁 적용 시 사용
    .inputIconButton{position: absolute; top: 0; right: 0;
        > [class*='OBTTooltip_root']{height: 27px;}
        button{position: relative !important;}
    }

    // hover 안쓸 때
    .noOver{pointer-events: none;}
    
    // 아이콘
    .btnOrgan {position:absolute; right:0;top:0;background:url("../imgs/ico/ic_organization_s_normal.png") no-repeat center center !important;width:27px !important;border:none !important;border-radius: 0;
        &:hover{background:url("../imgs/ico/ic_organization_s_over.png") no-repeat center center !important;}
    }   
    .btnSearch {position:absolute; right:0;top:0;background:url("../imgs/ico/ic_search_s_normal@2x.png") no-repeat center center !important;width:27px !important;border:none !important;border-radius: 0; background-size: 14px !important;
        &:hover{background:url("../imgs/ico/ic_search_s_over@2x.png") no-repeat center center !important; background-size: 14px !important;}
    }       
    .btnRepeat {position:absolute; right:0;top:0;background:url("../imgs/ico/ic_repeat_s_normal.png") no-repeat center center !important;width:27px !important;border:none !important;border-radius: 0;
        &:hover{background:url("../imgs/ico/ic_repeat_s_over.png") no-repeat center center !important;}
    }   
    .btnCode {position:absolute; right:0;top:0;background:url("../imgs/ico/ic_code_m_normal@2x.png") no-repeat center center !important;width:27px !important;border:none !important;border-radius: 0; background-size: 18px !important;
        &:hover{background:url("../imgs/ico/ic_code_m_over@2x.png") no-repeat center center !important; background-size: 18px !important;}
    }   
    .btnAdd{position: absolute; right:0;top:0;background: url("../imgs/ico/ic_add_02_s_normal.png") no-repeat center center !important;width: 27px !important; border: none !important; border-radius: 0;
        &:hover{background: url("../imgs/ico/ic_add_02_s_over.png") no-repeat center center !important;} 
    } 
    .btnDelete{position: absolute; right:0;top:0;background: url("../imgs/ico/ic_close_01_s_normal.png") no-repeat center center !important;width: 27px !important; border: none !important; border-radius: 0;
        &:hover{background: url("../imgs/ico/ic_close_01_s_over.png") no-repeat center center !important;} 
    }
    .btnCal{position: absolute; right:0;top:0;background: url("../imgs/ico/ic_calendar_m_normal.png") no-repeat center center !important;width: 27px !important; border: none !important; border-radius: 0;
        &:hover{background: url("../imgs/ico/ic_calendar_m_over.png") no-repeat center center !important;} 
    }
    
    &.repeatInput {   
        >div > div > div {background: #fff !important;}   
    }   
    [class*="OBTTextField"] > div > div{justify-content: start !important;}   
    [class*="OBTTextField"] > div > div > input {width: calc(100% - 20px)!important;text-overflow: ellipsis !important;}   
    .positionBadge{position: absolute;top: 4px;right: 30px;display: inline-block;width: auto;height: 16px;padding:0 5px;font-size:10px;line-height:16px;border-radius: 2px;text-align: center; 
        &.todo{border: solid 1px #33b6c3;background-color: #eff9fa;color: #33b6c3;} 
        &.project{border: solid 1px #9994f4;background-color: #f7f7ff;color: #9994f4;} 
     }
}   
.pubTextStyle {display:inline-block;vertical-align: middle; }   
.pubTextSuccess{position:relative;   
    & > div > div {border:1px solid #8fe1bd !important;}   
    & + .ico{position: absolute;top:50%;right:8px;margin:-8px 0 0 0 !important;}   
}    
.pubTextError{position:relative;   
    & > div > div {border:1px solid #fc5356 !important;}   
    & + .ico{position: absolute;top:50%;right:8px;margin:-8px 0 0 0 !important;}   
}    
   
/*****************************   
    Button   
******************************/   
// 버튼 그룹일 때 활성화
[class*="OBTButtonGroup_focus"].btnGroupImg,  
[class*="OBTButtonGroup_focus"].btnGroupImg:active, 
[class*="OBTButtonGroup_buttonGroup"].btnGroupImg > * > [class*="OBTButtonGroup_button"][class*="OBTButtonGroup_focus"]{  
    &.btn_list{background: url("../imgs/ico/ic_view_list_02_m_over.png") no-repeat center center, linear-gradient(to top, #d0ebff, #edf6ff) !important;}  
    &.btn_thumb{background: url("../imgs/ico/ic_view_thumbnail_02_m_over.png") no-repeat center center, linear-gradient(to top, #d0ebff, #edf6ff) !important;}  
    &.btn_cal{background: url("../imgs/ico/ic_inputcal_m_over.png") no-repeat center center, linear-gradient(to top, #d0ebff, #e6f6ff) !important;} 
    &.btn_lr{background: url("../imgs/ico/ic_view_left_right_m_over.png") no-repeat center center, linear-gradient(to top, #d0ebff, #edf6ff) !important;}  
    &.btn_ud{background: url("../imgs/ico/ic_view_up_down_m_over.png") no-repeat center center, linear-gradient(to top, #d0ebff, #edf6ff) !important;}  
    &.btn_vp{background: url("../imgs/ico/ic_view_pre_m_over.png") no-repeat center center, linear-gradient(to top, #d0ebff, #edf6ff) !important;}  
}

.pubButtonStyle {display:inline-block;vertical-align: middle;}   
.btnImg {padding:0 !important; width:27px;vertical-align: middle;}   
.btnImgSmall {padding:0 !important; width:21px;vertical-align: middle;}   
.btnNemo {padding:0 !important; width:27px !important;height:27px !important;vertical-align: middle;outline: none;cursor: pointer;}   
[class*="OBTButton_disabled"].btnImg {border-color:#cccccc !important;background-color:#f5f5f5 !important;}   
[class*="OBTButton_disabled"].btnImgSmall {border-color:#cccccc !important;background-color:#f5f5f5 !important;}   
.btnImgTxt {padding-left:24px !important;}   

/*썸네일*/   
.btn_thumb {background:url("../imgs/ico/ic_view_thumbnail_02_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}    
.btn_thumb:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_thumbnail_02_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_thumb {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_thumbnail_02_m_disable.png") no-repeat center center !important;}       
.btn_thumb.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_view_thumbnail_02_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_thumb {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_thumb:hover {border: solid 1px #a6a6a6 !important;}   
   
/*목록*/   
.btn_list { background:url("../imgs/ico/ic_view_list_02_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_list:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_list_02_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_list {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_list_02_m_disable.png") no-repeat center center !important;}             
.btn_list.on {background:url("../imgs/ico/ic_view_list_02_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_list {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_list:hover {border: solid 1px #a6a6a6 !important;}   
   
/*자세히*/   
.btn_detail {background:url("../imgs/ico/ic_view_detail_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_detail:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_detail_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_detail {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_detail_m_disable.png") no-repeat center center !important;}                
.btn_detail.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_view_detail_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_detail {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_detail:hover {border: solid 1px #a6a6a6 !important;}   
   
/*혼합형*/   
.btn_mixed {background: url("../imgs/ico/ic_view_mixed_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_mixed:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_mixed_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
.btn_mixed.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_view_mixed_select.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_mixed {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_mixed:hover {border: solid 1px #a6a6a6 !important;}   

// 가로분할보기
.btn_lr{background:url("../imgs/ico/ic_view_left_right_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_lr:active{border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_left_right_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_lr{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_left_right_m_disable.png") no-repeat center center !important;}             
.btn_lr.on{background:url("../imgs/ico/ic_view_left_right_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   

// 세로분할보기
.btn_ud{background:url("../imgs/ico/ic_view_up_down_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_ud:active{border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_up_down_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_ud{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_up_down_m_disable.png") no-repeat center center !important;}             
.btn_ud.on{background:url("../imgs/ico/ic_view_up_down_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   

// 미리보기
.btn_vp{background:url("../imgs/ico/ic_view_pre_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_vp:active{border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_pre_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
.btn_vp.on{background:url("../imgs/ico/ic_view_pre_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   

/*다운로더*/   
.btn_down {background:url("../imgs/ico/ic_download_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_down:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_download_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}    
[class*="OBTButton_disabled"].btn_down {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_download_m_disable.png") no-repeat center center !important;}   
.btn_down.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_download_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}     
input.btn_down {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_down:hover {border: solid 1px #a6a6a6 !important;}   
   
/*초기화*/   
.btn_reset {background:url("../imgs/ico/ic_reset_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_reset:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_reset_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}    
.btn_reset.big{height:27px !important; vertical-align:middle;}   
[class*="OBTButton_disabled"].btn_reset {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_reset_m_disable.png") no-repeat center center !important;}   
.btn_reset.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_reset_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}     
input.btn_reset {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_reset:hover {border: solid 1px #a6a6a6 !important;}   
   
/*삭제*/   
.btn_delete { background:url("../imgs/ico/ic_trashcan_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_delete:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_trashcan_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_delete{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_trashcan_m_disable.png") no-repeat center center !important;}    
.btn_delete.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_trashcan_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}     
input.btn_delete {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_delete:hover {border: solid 1px #a6a6a6 !important;}   
   
/*미리보기*/   
.btn_viewer { background:url("../imgs/ico/ic_viewer_open_m_normal@2x.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important; background-size: 18px !important;}                  
.btn_viewer:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_viewer_open_m_normal@2x.png") no-repeat left 4px center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important; background-size: 18px !important;}    
[class*="OBTButton_disabled"].btn_viewer{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_viewer_open_m_disable@2x.png") no-repeat center center !important; background-size: 18px !important;}   
.btn_viewer.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_viewer_open_m_over@2x.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important; background-size: 18px !important;}     
input.btn_viewer {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_viewer:hover {border: solid 1px #a6a6a6 !important;}   
   
/*파일찾기*/   
.btn_fileAdd {background:url("../imgs/ico/ic_attach_m_normal.png") no-repeat left 4px center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_fileAdd:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_attach_m_normal.png") no-repeat left 4px center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}    
[class*="OBTButton_disabled"].btn_fileAdd{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_attach_m_disable.png") no-repeat center center !important;}   
.btn_fileAdd.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_attach_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}    
input.btn_fileAdd {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_fileAdd:hover {border: solid 1px #a6a6a6 !important;}   
   
/*닫기*/   
.btn_close {background:url("../imgs/ico/ic_close_01_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_close:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_close_01_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}    
[class*="OBTButton_disabled"].btn_close{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_close_01_m_disable.png") no-repeat center center !important;}   
.btn_close.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_close_01_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}    
input.btn_close {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_close:hover {border: solid 1px #a6a6a6 !important;}   
   
/*검색*/   
.btnImgTxt.btn_search {background-position: left 5px center !important;}   
.btnImgTxt.btn_search:active {background-position: left 5px center !important;}   
.btn_search {background:url("../imgs/ico/ic_search_m_normal.png") no-repeat, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-position: center center !important;}               
.btn_search:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_search_m_normal.png") no-repeat, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;background-position: center center !important;}    
[class*="OBTButton_disabled"].btn_search{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_search_m_disable.png") no-repeat  !important;background-position: center center !important;}   
.btn_search.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_search_m_over.png") no-repeat , linear-gradient(to top, #a0d6fe, #e6f5ff) !important;background-position: center center !important;}    
input.btn_search {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_search:hover {border: solid 1px #a6a6a6 !important;}   
   
/*플러스, 마이나스*/   
.btn_plus {background:url("../imgs/ico/ic_add_01_m_normal.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
[class*="OBTButton_disabled"].btn_plus{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_add_01_m_disable.png") no-repeat left 4px top 4px !important;}    
.btn_plus.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_add_01_m_over.png") no-repeat left 4px top 4px, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
.btn_plus:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_add_01_m_normal.png") no-repeat left 4px top 4px, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
input.btn_plus {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_plus:hover {border: solid 1px #a6a6a6 !important;}   
   
.btn_minus {background:url("../imgs/ico/ic_minus_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_minus.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_minus_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
.btn_minus:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_minus_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}               
[class*="OBTButton_disabled"].btn_minus{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_minus_m_disable.png") no-repeat center center !important;}    
input.btn_minus {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_minus:hover {border: solid 1px #a6a6a6 !important;}   
   
/*방향*/   
.btn_arrUp {background:url("../imgs/ico/ic_arrow_up_01_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_arrUp:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_arrow_up_01_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_arrUp{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_arrow_up_01_m_disable.png") no-repeat center center !important;}    
.btn_arrUp.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_arrow_up_01_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_arrUp {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_arrUp:hover {border: solid 1px #a6a6a6 !important;}   
   
.btn_arrDown {background:url("../imgs/ico/ic_arrow_down_01_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_arrDown:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_arrow_down_01_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_arrDown{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_arrow_down_01_m_disable.png") no-repeat center center !important;}    
.btn_arrDown.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_arrow_down_01_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_arrDown {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_arrDown:hover {border: solid 1px #a6a6a6 !important;}   
   
.btn_arrLeft {background:url("../imgs/ico/ic_arrow_left_01_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}         
.btn_arrLeft:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_arrow_left_01_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_arrLeft{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_arrow_left_01_m_disable.png") no-repeat center center !important;}    
.btn_arrLeft.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_arrow_left_01_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_arrLeft {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_arrLeft:hover {border: solid 1px #a6a6a6 !important;}   
   
.btn_arrRight {background:url("../imgs/ico/ic_arrow_right_01_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}               
.btn_arrRight:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_arrow_right_01_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
[class*="OBTButton_disabled"].btn_arrRight{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_arrow_right_01_m_disable.png") no-repeat center center !important;}    
.btn_arrRight.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_arrow_right_01_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
input.btn_arrRight {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_arrRight:hover {border: solid 1px #a6a6a6 !important;}   

// 일정, 달력
.btn_cal{background:url("../imgs/ico/ic_inputcal_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_cal:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_inputcal_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}                      
.btn_cal.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_inputcal_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
[class*="OBTButton_disabled"].btn_cal{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_inputcal_m_disable.png") no-repeat center center !important;}   
input.btn_cal {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_cal:hover {border: solid 1px #a6a6a6 !important;}   
   
// 새창
.btn_newPage{background:url("../imgs/ico/ic_write_newpage_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
.btn_newPage:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_write_newpage_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}                      
.btn_newPage.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_write_newpage_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
[class*="OBTButton_disabled"].btn_newPage{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_write_newpage_m_disable.png") no-repeat center center !important;}   
input.btn_newPage {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_newPage:hover {border: solid 1px #a6a6a6 !important;}   

// 엑셀다운로드
.btn_excel_down_nobg{border: none !important; background:url("../imgs/ico/ic_excel_save_m_normal.png") no-repeat center center!important;}     
.btn_excel_down_nobg:hover {background:url("../imgs/ico/ic_excel_save_m_over.png") no-repeat center center!important;}   
   
// 메뉴이동
.newOpen { background: url("../imgs/ico/ic_newpage_s_normal.png") no-repeat center center !important; border: none !important; width: 14px !important; height: 14px !important; margin-right: 4px; padding: 0; }   
.newOpen:hover { background: url("../imgs/ico/ic_newpage_s_over.png") no-repeat center center !important;}   

// 회사, 거래처정보, 부서
.btn_comp {background:url("../imgs/ico/ic_comp02_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}    
.btn_comp:hover {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_comp02_m_over.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;} 
.btn_comp:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_comp02_m_over.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;} 
[class*="OBTButton_disabled"].btn_comp {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_comp02_m_disable.png") no-repeat center center !important;}       
   
/*게시판 담당자 버튼*/   
.btn_board_hu {border:none !important;background:url("../imgs/ico/ic_user_m_normal@2x.png") no-repeat center center  !important; background-size: 18px !important;
    &:hover{background:url("../imgs/ico/ic_user_m_over@2x.png") no-repeat center center  !important; background-size: 18px !important;}
}   
.btn_board_add {border:none !important;background:url("../imgs/ico/ic_add_02_m_normal.png") no-repeat center center  !important;
    &:hover{background:url("../imgs/ico/ic_add_02_m_over.png") no-repeat center center  !important;}
}   
.btn_board_cata {border:none !important;background:url("../imgs/ico/ic_board_classify_m_normal.png") no-repeat center center  !important;
    &:hover{
        filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
    }
}   
.btn_board_clo {border:none !important;background:url("../imgs/ico/ic_close_01_s_normal@2x.png") no-repeat center center  !important; background-size: 14px !important;
    &:hover{
        filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
    }
}   
.btn_board_org {border:none !important;background:url("../imgs/ico/ic_organization_m_normal.png") no-repeat center center  !important;
    &:hover{background:url("../imgs/ico/ic_organization_m_over.png") no-repeat center center  !important;}
}  
[class*=OBTButton_disabled].btnImg.btn_board_org{ 
    border:none !important;background:transparent url("../imgs/ico/ic_organization_m_disable.png") no-repeat center center  !important;cursor:not-allowed !important; 
} 
.btn_board_newPage{border: none !important; background: url('../imgs/ico/ic_write_newpage_s_normal.png') no-repeat center center !important; background-size: 14px !important;
    &:hover{background: url('../imgs/ico/ic_write_newpage_s_over.png') no-repeat center center !important; background-size: 14px !important;}
}
.btn_onechamber {border:none !important;background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat center center  !important;
    &:hover{background:url("../imgs/ico/ic_onechamber_m_over.png") no-repeat center center  !important;}
}   
.btnNemo{   
    &.btn_onechamber  {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important; background: auto auto;height:27px !important;}   
    &.btn_onechamber:hover {background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat center center, linear-gradient(to top, #d9d9d9, #fafafa) !important;background: auto auto;}   
}   
   
/* 색상 btn */   
.btn_color {border-radius: 2px;background: #fff !important;height:27px;   
    [class*="OBTButton_wrapper"] {display:block;}   
    &.on {border:none !important;font-weight: bold; color:#fff;   
        span {color:#fff !important;}   
    }   
    &.gray {border:1px solid #929fbc !important;color:#929fbc;   
        span {color:#929fbc;}   
    }   
    &.gray.on {background: #929fbc !important;}   
   
    &.lightGray {border:1px solid #9da3aa !important;color:#9da3aa;   
        span {color:#9da3aa;}   
    }   
    &.lightGray.on {background: #9da3aa !important;}   
   
    &.blue {border:1px solid #46a3f0 !important;color:#46a3f0;   
        span {color:#46a3f0;}   
    }   
    &.blue.on {background: #46a3f0 !important;}   
   
    &.green {border:1px solid #20c997 !important;color:#20c997;   
        span {color:#20c997;}   
    }   
    &.green.on {background: #20c997 !important;}   
   
    &.yellow {border:1px solid #f0c325 !important;color:#f0c325;   
        span {color:#f0c325;}   
    }   
    &.yellow.on {background: #f0c325 !important;}   
   
    &.red {border:1px solid #FF8787 !important;color:#FF8787;   
        span {color:#FF8787;}   
    }   
    &.red.on {background: #FF8787 !important;}   
}   
   
/*****************************   
    textarea   
******************************/   
.pubTextareaStyle { display:inline-block;vertical-align: middle; width: 100%;height:100%;   
    div {height:100%;   
        div { height:100%;   
            textarea {height:100% !important;}   
        }   
    }
    &.readOnly{
        textarea {color:#1a1a1a !important;background:#ffffff !important;}   
    }   
}   
   
/*****************************   
    twinBox   
******************************/   
.twinBox{   
    > table{width:100%;height:100%;border:1px solid #e6e6e6;table-layout: fixed;   
        td{border:solid #e6e6e6;border-width: 0 0 0 1px;   
            &:first-child{border:none;}   
            &.twinBox_td{vertical-align:top;height:100%;padding:20px;box-sizing: border-box;}   
            &.twinBox_cen{vertical-align:middle;text-align: center;}   
        }   
    }   
}   
   
.cen_arr_div {width:100%; height:100%; text-align: center;position: relative;   
    .btn_set {position: absolute;top:50%;margin-top:-28px;   
        > div:first-child {margin-bottom: 2px;}   
        button{border:1px solid #a6a6a6;background-color:linear-gradient(to top, #f0f0f0, #ffffff);width:27px !important;padding:0 !important;}   
        button > div {width:100%; height:100%;}   
        button:hover { background-color: linear-gradient(to top, #d9d9d9, #fafafa);}   
        button:active {background-color: linear-gradient(to top, #cccccc, #f5f5f5);}   
        button.arrBtnNext {margin-bottom: 2px;}   
        button.arrBtnNext > div {background:url("../imgs/ico/ic_arrow_right_01_m_normal.png") no-repeat center center !important;}   
        button.arrBtnPrev > div {background:url("../imgs/ico/ic_arrow_left_01_m_normal.png") no-repeat center center !important;}   
    }   
}   
   
.mid_arr_div {width:100%; padding:10px 0;text-align: center;   
    .btn_set {margin: 0 auto;   
        > div:first-child {margin-right: 2px;}   
        button{border:1px solid #a6a6a6 !important;background-color:linear-gradient(to top, #f0f0f0, #ffffff);width:27px !important;padding:0 !important;}   
        button > div {width:100%; height:100%;}   
        button:hover { background-color: linear-gradient(to top, #d9d9d9, #fafafa);}   
        button:active {background-color: linear-gradient(to top, #cccccc, #f5f5f5);}   
        button.arrBtnUp > div {background:url("../imgs/ico/ic_arrow_up_01_m_normal.png") no-repeat center center !important;}   
        button.arrBtnDown > div{background:url("../imgs/ico/ic_arrow_down_01_m_normal.png") no-repeat center center !important;}   
   
    }   
}   
   
/*****************************   
    flexBox   
******************************/   
.flexBox { display:flex;-ms-display:flex; height:100%;   
    .fb_div {display:inline-flex;-ms-display:inline-flex;height:100%;flex-direction: column;vertical-align: top;   
        &.treeViewBox > div:first-child{border:1px solid #e6e6e6;}   
    }   
    &.borderBox {box-sizing:border-box;   
        .fb_div {border:1px solid #e6e6e6;box-sizing:border-box;}   
        .fb_div:first-child {border-right:none;}   
    }   
    .high_div {display:none;}   
    &.highlight {position: absolute;z-index: 10001;background:#fff;width:100%;   
        >.fb_div {width: 100%;}   
        .high_div {position:fixed;top:185px;right:30px;display:block;   
            .txt {color:#fff; float: left;font-size:20px;line-height: 27px;margin-right: 20px;}   
            .btn_high_clo {background:url("../imgs/ico/btn_highlight_clo01.png") no-repeat center center !important;border:none !important;float: right;}   
        }   
    }       
}   
   
/*****************************   
    grid   
******************************/   
.pubGridStyle {height: 100%;width: 100%;position:relative;   
    [class*="OBTPagination_drop"] {vertical-align: top;}   
    > div > div{border-top-color: #666666;}   
}   
.btn_div + .pubGridStyle {height: calc(100% - 41px);}   
   
/*****************************   
    paging   
******************************/   
.pubSelectStyle {   
    div>div>span {padding-top:0 !important;padding-bottom:0 !important;height:19px !important;line-height:19px  !important;}   
}   
   
/*****************************   
    tab   
******************************/   
.luxTapStyle {   
        > div:first-child {text-align: left !important;margin-top: 0;   
            > div ul li button{min-width:100px;}   
        }   
        > div {margin-top: 15px;}   
}   
   
.pubTabStyle {   
    > div > div > div > div > ul > div:first-child li > button > span:first-child{display:none;}   
    &.tabCusButton {   
        > div > div:first-child  > div > div:first-child {margin-right:120px !important;}   
        [class*="OBTTabs_buttonGroup"] {right:72px;}   
    }   
    &.conPt20 {   
    > div > div > div:nth-child(2) {padding-top:20px !important; box-sizing: border-box;}    
    }   
    &.conPt15 {   
        > div > div > div:nth-child(2) {padding-top:15px !important;box-sizing: border-box;}    
    }   
}   
.tabW135 {   
    > div > div> div> div>ul li > button{width: 135px !important;}   
}   
.tabW184 {   
    > div > div> div> div>ul li > button{width: 184px !important;}   
}   
.btnCusTab {position: absolute;top:0px; right:0px; z-index:2;}   
   
/*****************************   
    btn_div   
******************************/   
.btn_div {padding-bottom: 5px;height:31px;box-sizing: border-box;}   
.btn_div.h27 {padding-bottom: 0px;height:27px;}   
.btn_div .left_div{float:left;}   
.btn_div .left_div_in > div {vertical-align: middle;}   
.btn_div .left_div > div:first-child {float:left;}   
.btn_div .left_div > span:first-child {float:left;}   
.btn_div .left_div .left_div_in {float:left;}   
.btn_div .right_div{float:right;}   
.btn_div .right_div > div {vertical-align: middle;}   
.btn_div .cen_div{text-align: center;}   
.btn_div .cen_div > div {vertical-align: middle;}   
   
.btn_div button {vertical-align: top; margin-left: 4px;min-width:27px;}   
.btn_div [data-orbit-component="OBTButtonGroup"] button {margin-left:0;}   
.btn_div .left_div button {margin-left: 0;margin-right: 4px;}   
.btn_div input{height:22px; /*padding:0 12px;*/ padding:0 0 0 6px; border:1px solid #c9cac9;background:#fff;color:#4a4a4a;}   
.btn_div input:hover {border:1px solid #1088e3;}   
.btn_div button.btn_clo {background:url("../imgs/ico/ic_close_01_m_normal.png") no-repeat center center !important;border:none !important;}   
.btn_div .btn_list {margin-left: -1px !important;}   
.btn_div .txt {line-height:27px; color:#666;margin-left: 5px;}   
.btn_div .left_div .txt {margin-left: 0;margin-right: 5px;}   
   
/*****************************   
    treeBox   
******************************/   
.treeBox{height:100%; border:1px solid #e6e6e6;box-sizing: border-box;overflow-y:auto;}   
.treeBox > div {display:block; border:none;height:100%;width:100%;box-sizing: border-box;}   
   
/*****************************   
    AccodionTable   
******************************/   
.pubAccordionStyle {   
    .tit_sp {font-size:13px; color: #4a4a4a;}
    .so_txt {color:#a6a6a6;margin-left: 6px;}
}   
   
.checkTable {   
    table {width:100%;table-layout:fixed;   
        th,td {height:38px; border:1px solid #e6e6e6;}   
        th {font-size:12px;font-weight: bold;background:#fafafa;}   
        td{text-align: center; font-size:12px; color: #000000;}   
        .imgQue {background:url("../imgs/ico/ic_question_01_s_normal.png") no-repeat center center;width:14px;height:14px; display:inline-block;vertical-align: top;}   
    }   
}   
   
/*****************************   
    빈데이터 화면   
******************************/   
/*큰 이미지*/   
.con_normal{height:100%; border:1px solid #e6e6e6; box-sizing:border-box; text-align:center; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #8c8c8c; letter-spacing:-0.8px;   
    .text{background:url("../imgs/empty/img_empty_write.png") no-repeat center center; padding-top: 120px; margin-top: -30px;   
        &.nolist{background:url("../imgs/empty/img_empty_list.png") no-repeat center center;}   
        &.nocompany{background:url("../imgs/empty/img_empty_company.png") no-repeat center center;} 
    }   
    &.small{font-size: 12px;   
        .text{padding-top: 80px; background-size:42px 42px;   
            &.nolist{background:url("../imgs/empty/img_empty_list_s.png") no-repeat center center;}  
            &.nowrite{background:url("../imgs/empty/img_empty_write_s.png") no-repeat center center;}  
        }   
    }   
}   
   
/*****************************   
    no_data 로 재정의   
******************************/   
.no_data {height:100%; box-sizing:border-box; text-align:center; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #8c8c8c; letter-spacing:-0.8px;   
    &.border { border:1px solid #e6e6e6;}   
    .text{background:url("../imgs/empty/img_empty_data.png") no-repeat center center; padding-top: 120px; margin-top: -45px;   
        &.nowrite{background:url("../imgs/empty/img_empty_write.png") no-repeat center center;}   
        &.nosearch{background:url("../imgs/empty/img_empty_search.png") no-repeat center center;}   
        &.nolist{background:url("../imgs/empty/img_empty_list.png") no-repeat center center;}   
        &.nodept{background:url("../imgs/empty/img_empty_dept.png") no-repeat center center;}   
        &.novideo{background:url("../imgs/empty/img_empty_video.png") no-repeat center center;}   
        &.nomemo{background:url("../imgs/empty/img_empty_memo.png") no-repeat center center;}   
        &.noevent{background:url("../imgs/empty/img_empty_event.png") no-repeat center center;}   
        &.nocompany{background:url("../imgs/empty/img_empty_company.png") no-repeat center center;}   
        &.noform{background:url("../imgs/empty/img_empty_form.png") no-repeat center center;}   
        &.nocomment{background:url("../imgs/empty/img_empty_comment.png") no-repeat center center;}   
        &.noproduct{background:url("../imgs/empty/img_empty_product.png") no-repeat center center;}   
        &.nouser{background:url("../imgs/empty/img_empty_user.png") no-repeat center center;}   
        &.analysis{background:url("../imgs/empty/img_empty_analysis.png") no-repeat center center;}   
        &.userAdd{background:url("../imgs/empty/img_empty_user_add.png") no-repeat center center;}   
        &.noalert{background:url("../imgs/empty/img_empty_noti.png") no-repeat center center;}   
        &.nomail{background:url("../imgs/empty/img_empty_mail.png") no-repeat center center;}   
        &.nomailselect{background:url("../imgs/empty/img_select_mail.png") no-repeat center center;}   
        &.notrash {background:url("../imgs/empty/img_empty_trash.png") no-repeat center center;}   
        &.norecent {background:url("../imgs/empty/img_empty_recent.png") no-repeat center center;}   
        &.nosignhistory {background:url("../imgs/empty/img_empty_sign_history.png") no-repeat center center;}   
        &.noDday {background:url("../imgs/empty/img_empty_dday.png") no-repeat center center;}   
        &.noshare {background:url("../imgs/empty/img_empty_share.png") no-repeat center center;}   
        &.noauthority {background:url("../imgs/empty/img_empty_authority.png") no-repeat center center;}   
        &.nodocuflow {background:url("../imgs/empty/img_empty_docuflow.png") no-repeat center center;}   
        &.nodeptdocu {background:url("../imgs/empty/img_empty_dept_docu.png") no-repeat center center;}   
        &.nolock {background:url("../imgs/empty/img_empty_lock.png") no-repeat center center;}   
        &.noonefilp {background:url("../imgs/empty/img_empty_onefilp.png") no-repeat center center;}   
        &.noimportant {background:url("../imgs/empty/img_empty_important.png") no-repeat center center;}   
        &.noprocess {background:url("../imgs/empty/img_empty_process.png") no-repeat center center;}   
        &.noclass {background:url("../imgs/empty/img_empty_class.png") no-repeat center center;}   
        &.noattachfile {background:url("../imgs/empty/img_empty_attachfile.png") no-repeat center center;} 
        &.noviewer {background:url("../imgs/empty/img_empty_viewer.png") no-repeat center center;} 
        &.nocarryout {background:url("../imgs/empty/img_empty_carryout.png") no-repeat center center;} 
        &.notification {background:url("../imgs/empty/img_empty_notification.png") no-repeat center center;} 
    }   
    &.small{font-size: 12px;   
        .text{padding-top: 70px; background:url("../imgs/empty/img_empty_data_s.png") no-repeat center center;background-size:42px 42px; margin-top: -35px;   
            &.nowrite{background:url("../imgs/empty/img_empty_write_s.png") no-repeat center center;}   
            &.nosearch{background:url("../imgs/empty/img_empty_search_s.png") no-repeat center center;}   
            &.nolist{background:url("../imgs/empty/img_empty_list_s.png") no-repeat center center;}   
            &.nodept{background:url("../imgs/empty/img_empty_dept_s.png") no-repeat center center;}   
            &.novideo{background:url("../imgs/empty/img_empty_video_s.png") no-repeat center center;}   
            &.nomemo{background:url("../imgs/empty/img_empty_memo_s.png") no-repeat center center;}   
            &.noevent{background:url("../imgs/empty/img_empty_event_s.png") no-repeat center center;}   
            &.nocompany{background:url("../imgs/empty/img_empty_company_s.png") no-repeat center center;}   
            &.nocomment{background:url("../imgs/empty/img_empty_comment_s.png") no-repeat center center;}   
            &.noalert{background:url("../imgs/empty/img_empty_noti_s.png") no-repeat center center;}   
            &.nomail{background:url("../imgs/empty/img_empty_mail_s.png") no-repeat center center;}   
            &.nomailselect{background:url("../imgs/empty/img_select_mail_s.png") no-repeat center center;}   
            &.notime{background:url("../imgs/empty/img_empty_time_s.png") no-repeat center center;}   
            &.nosetting{background:url("../imgs/empty/img_empty_setting_s.png") no-repeat center center;}   
            &.noscreen{background:url("../imgs/empty/img_empty_screen_s.png") no-repeat center center;}   
            &.nouser{background:url("../imgs/empty/img_empty_user_s.png") no-repeat center center;}   
            &.userAdd{background:url("../imgs/empty/img_empty_user_add_s.png") no-repeat center center;}            
            &.nosignhistory {background:url("../imgs/empty/img_empty_sign_history_s.png") no-repeat center center;}   
            &.noSchedule{background:url("../imgs/empty/img_empty_schedule_s@2x.png") no-repeat center center;background-size:42px 42px;}   
            &.noSelectSchedule{background:url("../imgs/empty/img_select_schedule_s.png") no-repeat center center;}   
            &.noNameCard{background:url("../imgs/empty/img_empty_businesscard_s@2x.png") no-repeat center center;background-size:42px 42px;} 
            &.nohistory {background:url("../imgs/empty/img_empty_history_s.png") no-repeat center center;}
            &.noshare {background:url("../imgs/empty/img_empty_share_s.png") no-repeat center center;}
            &.noimportant {background:url("../imgs/empty/img_empty_important_s.png") no-repeat center center;}   
            &.noauthority {background:url("../imgs/empty/img_empty_authority_s.png") no-repeat center center;}   
            &.nodocuflow {background:url("../imgs/empty/img_empty_docuflow_s.png") no-repeat center center;}   
            &.nochart {background:url("../imgs/empty/img_empty_chart_s.png") no-repeat center center;}   
            &.nodeptdocu {background:url("../imgs/empty/img_empty_dept_docu_s.png") no-repeat center center;}   
            &.nokeep {background:url("../imgs/empty/img_empty_keep_s.png") no-repeat center center;}   
            &.nodocconn {background:url("../imgs/empty/img_empty_doc_conn_s.png") no-repeat center center;}   
            &.noclass {background:url("../imgs/empty/img_empty_class_s.png") no-repeat center center;}
            &.noprocess {background:url("../imgs/empty/img_empty_process_s.png") no-repeat center center;}      
            &.noprofile {background:url("../imgs/empty/img_empty_profile_s.png") no-repeat center center;}      
            &.nomydocu {background:url("../imgs/empty/img_empty_mydocu_s.png") no-repeat center center;}      
            &.noattachfile {background:url("../imgs/empty/img_empty_attachfile_s.png") no-repeat center center;}      
            &.noviewer {background:url("../imgs/empty/img_empty_viewer_s.png") no-repeat center center;}      
            &.nocarryout {background:url("../imgs/empty/img_empty_carryout_s.png") no-repeat center center;}      
            &.noequipment {background:url("../imgs/empty/img_empty_equipment_s.png") no-repeat center center;}      
        }   
    }   
    div {text-align: center;   
        img {text-align: center;}   
    }   
    .txt {font-size: 15.5px; line-height: 1.16; letter-spacing: -0.78px; text-align: center; color: #8c8c8c;margin-top: 7px;}   
    .subTxt {font-size: 12px; line-height: 18px; letter-spacing: -0.6px; text-align: center; color: #8c8c8c;margin-top: 8px;}   
}   
   
.emptySet {overflow: hidden;   
    .tit {height: 29px; font-size: 20px; line-height: 1.45; color: #1c90fb;clear: both;}   
    ul { margin-top: 30px;flex-wrap:wrap;gap:10px;   
        li {margin-bottom: 50px;position: relative;
            .txt {font-size: 16px; line-height: 24px; clear: both;color: #000000;padding-bottom: 10px;display:block;}   
            .box {height:240px; width:280px;}   
            .small_box {height:200px; width:264px;}   
            .codePreDiv {position:absolute;z-index:2;left:0;top:32px;   
                pre{background:#f2f2f2;padding:20px;border:1px solid #e6e6e6;}   
                .btn_board_clo {position: absolute; right:0px;top:10px;}   
            }   
        }    
    }   
}   
   
/*****************************   
    layer_wrap   
******************************/   
.layer_set {height: 100%;   
    .layer_wrap { height: 100%; position: absolute; top: 0px; right: 0px; display: none; z-index: 100;
        &.z99{z-index: 99;}
    }   
   
    // 타이틀영역 커스텀   
    .titleSearchWrap{box-sizing:border-box;   
        .titleSearch{position: absolute;top: -70px;left: -20px;height: 49px;padding:0 10px 0 20px;box-sizing: border-box;background-color: #ffffff;   
            .titleDivUnit{   
                .title{font-size:18px;line-height:49px;letter-spacing:-0.8px;color:#000000;}   
                .cnt{margin:0 0 0 10px;font-size:16px;font-weight:bold;line-height:49px;letter-spacing:-0.8px;color:#1c90fb;}   
                .refreshBtn{margin:18px 0 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_refresh_s_normal@2x.png') no-repeat center center; background-size: 14px; cursor: pointer;   
                    &:hover{background:url('../imgs/ico/ic_refresh_s_over@2x.png') no-repeat center center; background-size: 14px;}   
                }   
            }   
        }   
    }   
}   
.layer_wrap {   
    height: 100%; position: relative; overflow: hidden;   
    .layer_div { position: absolute; width: 0%; height: 100%; top: 0px; display: none; background: #fff; border: 1px solid #fff; border-top: none; box-sizing: border-box;animation-fill-mode: none;   
        .layer_div_in {  height: 100%;position: relative;   
            img{vertical-align: middle;}
            [class*="OBTDropDownList2"] { img{vertical-align:top;} }   
        }           
        .layer_sc { overflow: auto; height: 100%;   
           > div> div:nth-child(2) {display:none;}   
            .layer_sc_in {height:100%;}   
        }   
        .layer_foot {position: absolute;bottom: 0;left: 0;right:0; width: 100%; height: 50px; background-color: #f2f2f2;text-align: center;   
            .cen_div {margin-top: 11px;   
   
                > div {margin-left: 4px;}   
                > div:first-child{margin-left: 0;}   
            }    
            &.dat_foot {height:62px; background: #50596c;   
                .cen_div {margin-top:15px;   
                    .dat {font-size:18px;color:#fff; cursor:pointer;display:inline-block;background:url("../imgs/ico/ic_comment_m_disable.png") no-repeat left center; padding-left: 24px;   
                        .num{color:#1ae0ed;}   
                    }      
                    .chk {font-size:18px;color:#fff; cursor:pointer;display:inline-block;background:url("../imgs/ico/ic_check_02_m_disable.png") no-repeat left center; padding-left: 24px;   
                        &.on{background:url("../imgs/ico/ic_check_02_m_over.png") no-repeat left center; padding-left: 24px;  } 
                    } 
                    .off {font-size:18px;color:#fff; cursor:not-allowed;display:inline-block;background:url("../imgs/ico/ic_close_02_m_disable.png") no-repeat left center; padding-left: 24px;} 
 
                }   
            }   
        }   
    }   
   
    &.active { display: block;   
        .layer_div { left: 0px; width: 100%; display: block; }   
    }   
    &.pdStyle {   
        .layer_div { border: 1px solid #666666;   
            .layer_div_in { padding: 20px; padding-right:0px;   
                .layer_sc_in {height:auto;padding-bottom: 20px;padding-right: 14px;box-sizing: border-box;}   
            }   
            .layer_sc {margin-right: 6px;}   
        }   
    }   
    &.pdStyle30 {   
        .layer_div { border: 1px solid #666666;   
            .layer_div_in { padding: 30px; padding-right:0px;padding-top: 24px;   
                .layer_title {margin-right: 30px;font-size:20px;}   
                .layer_sc_in {height:auto;padding-bottom: 20px;padding-right: 24px;box-sizing: border-box;}                   
            }               
            .layer_sc {margin-right: 6px;}   
        }   
    }   
    &.pdStyle13 {   
        .layer_div { border: 1px solid #a6a6a6;   
            .layer_div_in { padding: 10px 13px;   
                .layer_title { border-bottom: 1px solid #d9d9d9;   
                    .layer_tit {font-size:13px;font-weight: bold;}   
                }   
                .layer_sc_in {height:auto;padding-bottom: 20px;padding-right: 24px;box-sizing: border-box;}                   
            }               
            .layer_sc {margin-right: 6px;}   
        }   
    }   
    &.npdStyle {   
        .layer_div { border: 1px solid #666666;   
        }   
    }   
   
    &.pubLayerSlide .layer_div .layer_div_in {box-sizing: border-box;}   
    &.pubLayerSlide .layer_title {margin-right:0px;}   
    &.pubLayerSlide.pdStyle .layer_title {margin-right:20px;}   
    &.pubLayerSlide.btnBoxStyle .layer_title .left_div .layer_tit{font-weight: 700; margin-right: 5px;background:url("../imgs/ico/ico_dot01.png") no-repeat left center; font-weight:bold;padding-left:8px;font-size:14px;line-height: 27px;}   
    &.pubLayerSlide.btnBoxStyle .layer_title .left_div .left_div_in {margin-left:0px;color:#666;}   
   
    &.noBorderTitle .layer_title { border-bottom:none;}   
    &.noBorderTitle.btnBoxStyle .layer_title { height:31px;}   
}   
   
.layer_wrap_comment {   
    height: 100%; position: absolute; overflow: hidden; bottom: 0px; display: none;   
    .layer_div {  position: absolute; width: 0%; height: 100%; bottom: 0px; display: none; background: #fff; border: 1px solid #fff; border-top: none; box-sizing: border-box;   
        .layer_div_in {  height: 100%;position: relative;}   
        .layer_sc { overflow: auto; height: 100%;   
           > div> div:nth-child(2) {display:none;}   
            .layer_sc_in {height:100%;}   
        }   
        .layer_foot {position: absolute;bottom: 0;left: 0;right:0; width: 100%; height: 50px; background-color: #f2f2f2;text-align: center;   
            .cen_div {margin-top: 11px;   
   
                > div {margin-left: 4px;}   
                > div:first-child{margin-left: 0;}   
            }    
        }   
    }   
    &.active { display: block;   
        .layer_div { left: 0px; width: 100%; display: block; }   
    }   
    &.pdStyle {   
        .layer_div { border: 1px solid #666666;   
            .layer_div_in { padding: 20px; padding-right:0px;   
                .layer_sc_in {height:auto;padding-bottom: 20px;padding-right: 14px;}   
            }   
            .layer_sc {margin-right: 6px;}   
        }   
    }   
    &.npdStyle {   
        .layer_div { border: 1px solid #666666;   
        }   
    }   
}   
   
.small_tit { font-family: NotoSansCJKkr; font-size: 13px; font-weight: bold; font-stretch: normal; font-style: normal; line-height: 2; letter-spacing: -0.65px; color: #000000; }   

.line_ta {   
    table { width: 100%; table-layout: fixed; border-top: 1px solid #cccccc; border-bottom: 1px solid #cccccc;   
        th, td { height: 39px; font-size: 13px; }   
        th {font-weight: normal;text-align: left; padding-left: 10px;}   
        td { font-weight: bold; }   
        .cen { text-align: center !important; padding-left: 0px;padding-right: 0px;}   
        .ri { text-align: right !important;  padding-right: 10px;}   
        .le { text-align: left !important; padding-left: 10px; }   
        .entry_img { background: url("../imgs/ico/ic_room_enter_m_normal.png") no-repeat center center; width: 18px; height: 18px; display: inline-block; vertical-align: top; padding-right: 6px;}   
        .out_img { background: url("../imgs/ico/ic_room_leave_m_normal.png") no-repeat center center; width: 18px; height: 18px; display: inline-block; vertical-align: top; padding-right: 6px;}   
        .inout_th { font-weight: bold; text-align: center; padding-top: 5px;}   
        .inout_td { font-size: 30px; font-weight: 300; text-align: center; padding-left: 24px; color: #000000; height: 49px; padding-bottom: 5px;   
            &.on { opacity: 0.3;  color: rgba(0, 0, 0, 0.3); }   
        }   
    }   
    &.bar { background: url("../imgs/bg/line_ta_bar.png") no-repeat center center;}   
}   
   
.pub_ta {   
    table { width: 100%; table-layout: fixed; border-top: 2px solid #666;   
        th, td { height: 39px; font-size: 13px; border: 1px solid #e6e6e6;}   
        th {font-weight: normal;background:#fafafa;}   
        td { font-weight: bold; }   
        .cen { text-align: center !important; padding-left: 0px;padding-right: 0px;}   
        .ri { text-align: right !important;  padding-right: 10px;}   
        .le { text-align: left !important; padding-left: 10px; }   
    }   
    &.vertical {   
        th, td {text-align: center;}   
    }   
    &.small {   
        th, td {height: 25px; font-size: 12px;letter-spacing: -1px;}   
    }   
}   
   
.mdt-7{   
    > div {margin-top:-7px;}   
}   
   
.boardAd_list { border:1px solid #ccc; width: 100%;height: 129px;overflow-y:auto;   
    ul li {line-height: 32px;padding-left: 9px;padding-right: 10px;clear: both;   
        .sp {float:right; margin-top: 5px;height:20px;}   
    }       
    .btn_board_clo {float: right;height:20px;}   
}   
   
/*레이어 타이틀*/   
.layer_title {height:36px; box-sizing: border-box; border-bottom:1px solid #666666;margin-right:20px;}   
.layer_title .left_div{float:left;}   
.layer_title .left_div .layer_tit {float:left; font-weight:bold; font-size:18px; line-height: 27px;}   
.layer_title .left_div .left_div_in {float: left;margin-left: 5px;line-height: 27px;}   
.layer_title .right_div{float:right;}   
.layer_title .right_div > div {vertical-align: top; margin-left: 4px;}   
.layer_title button.btn_clo {background:url("../imgs/ico/ic_close_01_m_normal@2x.png") no-repeat center center !important;border:none !important; background-size: 18px !important;
    &:hover{background:url("../imgs/ico/ic_close_01_m_over@2x.png") no-repeat center center !important;border:none !important; background-size: 18px !important;}
}   
.layer_title button.btn_del {background:url("../imgs/ico/ic_trashcan_m_normal.png") no-repeat center center !important;border:none !important;
    &:hover{background:url("../imgs/ico/ic_trashcan_m_over.png") no-repeat center center !important;border:none !important;}
}   
.layer_title button.btn_cal {background:url("../imgs/ico/ic_inputcal_m_normal.png") no-repeat center center !important;border:none !important;
    &:hover{background:url("../imgs/ico/ic_inputcal_m_over.png") no-repeat center center !important;border:none !important;}
}   
   
/*****************************   
    pubDownList   
******************************/   
.PubDropDownList {vertical-align: middle; background-color: beige;}   

.pubDropDownListWrap{position: relative; display: flex; flex-direction: column; min-width: 175px; height: auto;
    &.required .inbox{background-color: #fef4f4;
        &.on{background-color: #fef4f4;}
    }
    .inbox{display: flex; flex-direction: row; align-items: center; height: 27px; padding: 4px 24px 5px 7px; border: 1px solid #cccccc; color: #000000; background: #ffffff url('../imgs/ico/ic_arrow_down_01_s_normal@2x.png') no-repeat right 5px center; background-size: 14px; box-sizing: border-box; cursor: pointer;
        &.on{border: 1px solid #1c90fb; background: #ffffff url('../imgs/ico/ic_arrow_up_01_s_over@2x.png') no-repeat right 5px center; background-size: 14px;}
    }
    .list{z-index: 99; position: absolute; top: 27px; left: 0; display: flex; flex-direction: column; width: 100%; max-height: 156px; border: 1px solid #a6a6a6; background-color: #ffffff; box-shadow: 0 1px 1px 0 rgba(6, 6, 6, 0.1); box-sizing: border-box;
        &.top{top: auto; bottom: 27px;}
        ul{display: flex; flex-direction: column; flex: 1 1; overflow-y: auto;
            li{display: flex; flex-direction: row; align-items: center; padding: 6px 10px 7px; cursor: pointer;
                &:hover{background-color: #eff7ff;}
                &.on{background-color: #eff7ff;}
            }
        }
    }
}
   
/*****************************   
    pubRfpStyle   
******************************/   
// 타이틀만 작성시 박스 하단 radius처리 안 되므로 아래 클래스 사용   
.pubRfpStyle {   
    [class*="OBTReferencePanel_nochildreferbox"]{border-radius: 3px;}   
}   
   
.rfpLine2 {   
    > [class*="OBTReferencePanel_nochildreferbox"] {height:59px;       
        > [class*="OBTReferencePanel_icon"] {margin-top:-17px;}   
    }   
}   
   
.refBox{background:url('../imgs/ico/ic_info_03_m_normal@2x.png') no-repeat top 1px left 0px; background-size: 18px 18px;   
    .infoTxt{margin:0 0 0 22px;font-size:12px;line-height:18px;color:#4a4a4a;}   
}   
   
.lc_tip_rfp{   
    .lc_list {   
        &.st4{   
            ul {   
                li {width:25%}   
            }   
        }   
        &.st6{   
            ul {   
                li {width:16.6%}   
            }   
        }   
        ul { margin-right: 20px;   
            li {float: left;position: relative;text-align: center;   
                &:after{content:"|";position: absolute;right:0px;top:0px;color:#a6a6a6;}   
                &:last-child:after {display:none;}   
                .txt {margin-right: 10px;overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis;display: inline-block;vertical-align: middle; line-height:18px; width: 100%;}   
                .num {display: inline-block;vertical-align: middle;letter-spacing: 2;}   
                [class*="OBTTooltip_root"] {max-width:calc(100% - 90px);}   
                   
            }               
        }   
    }   
    [class*="OBTReferencePanel_content"] {padding:12px 0;    
        .lc_list {margin-left: 36px; clear: both; padding-top: 10px;   
            &:first-child {padding-top: 0;}   
        }   
    }   
}   
   
.z101 {   
    [class*="OBTReferencePanel_content"] {z-index: 101 !important;}   
}   
   
/*****************************   
    pubDatePicker   
******************************/   
.pubDatePickerStyle {vertical-align:middle;}   
.pubDatePickerStyle > div {display:block;}   
   
/*****************************   
    pubCheckbox   
******************************/   
.pubCheckboxStyle.cen svg{left:50% !important;margin-left:-7px !important;}   
   
/*****************************   
    첨부파일-업다운로더   
******************************/   
.UpDownLoader, .UpDownLoader_s, .UpDownLoader_th{   
    /* input */   
    input[type="checkbox"] {position: absolute; z-index: -10; visibility:hidden;height:14px; width:14px;top:0;}   
    input[type="checkbox"]:before{padding-right:10px;}   
    input[type="checkbox"] + label {box-sizing: border-box; display: inline-block; overflow: hidden;vertical-align: middle; width: 14px; height: 14px; border: solid 1px #a6a6a6; background-color: #ffffff; margin-top:-3px;}   
    input[type="checkbox"]:checked + label{border: solid 1px #1e91fb; background-color: #eff7ff; background:url('../imgs/ico/ico_mail_filter_chk.png') no-repeat center center; background-size: 10px 8px;}   
    input[type="checkbox"]:disabled + label{border: solid 1px #cccccc; background-color: #f5f5f5;}   
    
    // 버튼 탭 on일 경우 line 추가
    .btn_UD_lineOn{position: relative;
        &::after{content: ""; position: absolute; top: 0; width: 1px; z-index: 10; background: #1c90fb; height: 27px; right: 0;} 
    }
    // 버튼 탭 hover일 경우 line 추가
    .btn_UD_line:hover{position: relative;
        &::after{content: ""; position: absolute; top: 0; width: 1px; z-index: 1; background: #a6a6a6; height: 27px; right: 0;} 
    }

    /* 썸네일 (업다운로더 전용) */  
    .btn_UD_thumb {position: relative; background:url("../imgs/ico/ic_view_thumbnail_02_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}    
    .btn_UD_thumb:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_thumbnail_02_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
    [class*="OBTButton_disabled"].btn_UD_thumb {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_thumbnail_02_m_disable.png") no-repeat center center !important;}       
    .btn_UD_thumb.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_view_thumbnail_02_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
    input.btn_UD_thumb {border:1px solid #ccc !important; border-radius: 2px 0 0 2px;}   
    input.btn_UD_thumb:hover {border: solid 1px #a6a6a6 !important;}    
    input.btn_UD_thumb.on:hover {border: solid 1px #1c90fb !important;}  
    
    /* 목록 (업다운로더 전용) */    
    .btn_UD_list {position: relative; background:url("../imgs/ico/ic_view_detail_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
    .btn_UD_list:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_detail_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
    [class*="OBTButton_disabled"].btn_UD_list {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_detail_m_disable.png") no-repeat center center !important;}                
    .btn_UD_list.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_view_detail_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
    input.btn_UD_list {border:1px solid #ccc !important;}   
    input.btn_UD_list:hover {border: solid 1px #a6a6a6 !important;}    
    input.btn_UD_list.on:hover {border: solid 1px #1c90fb !important;}

    /* 자세히 (업다운로더 전용) */   
    .btn_UD_detail {position: relative; background:url("../imgs/ico/ic_view_list_02_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
    .btn_UD_detail:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_view_list_02_m_normal.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important;}   
    [class*="OBTButton_disabled"].btn_UD_detail {border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_view_list_02_m_disable.png") no-repeat center center !important;}             
    .btn_UD_detail.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_view_list_02_m_over.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important;}   
    input.btn_UD_detail {border:1px solid #ccc !important; border-radius: 0 2px 2px 0;}   
    input.btn_UD_detail:hover {border: solid 1px #a6a6a6 !important;}    
    input.btn_UD_detail.on:hover {border: solid 1px #1c90fb !important;}  

    /* 전자결재 전용 커스텀 */
    .clickInfoFlag{
        &:hover{color: #1c90fb; cursor:pointer;
           > p, > span {color: #1c90fb; cursor:pointer;}
        }
    }
    .textBtnBox{position: relative; float: left; margin: 0 20px 0 0;
        &::after{content: ''; position: absolute; top: 50%; right: -10px; transform: translateY(-50%); display: block; width: 1px; height: 16px; background-color: #e6e6e6;}
        .btn_text{display: block; padding: 0 10px !important; font-size: 12px; border: 1px solid #cccccc; border-radius: 2px; background-image: linear-gradient(to top, #f0f0f0, #ffffff);
            &:active{border: solid 1px #a6a6a6; background-image: linear-gradient(to bottom, #dddddd, #f5f5f5);}
            &:hover{border: solid 1px #a6a6a6;}
            &.on{border: solid 1px #1c90fb; background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);}
        }
    }
    .watchIco{width: 14px; height: 14px; margin: 0 1px 0 0; background: url('../imgs/ico/ic_watch_s_over.png') no-repeat center center;
        &.cancel{background: url('../imgs/ico/ic_watch_cancel_s_disable.png') no-repeat center center;}
    }
}   
   
.UpDownLoader{border: solid 1px #e6e6e6; background-color: #ffffff; clear: both;box-sizing:border-box; font-size: 12px !important;
    &.dragbg{border-color:#1c90fb;}
    .header{height:37px; line-height:37px; background-color: #fafafa;   
        .title{float:left; padding-left: 10px;   
            .check{float:left; padding:0 10px;}   
            .size{color:#b4b4b4; padding:0 0 0 5px;}   
            .ico_file{height:14px;padding:0 0 0 12px;background: url('../imgs/ico/ic_attach_s_normal.png') no-repeat left center; }   
        }   
        .btnBox{float:right; height:33px; padding:4px 10px 0 0; position:relative;      
            span{float:left; 
                &:last-child .fileTooltip{float:right; right:0px; margin-left:0px;   
                    .arrow{margin-left: -30px; left: 100%;}   
                }   
                .btn{min-width:27px;height:27px;padding:0; cursor:pointer; margin-left:-1px;outline:none; display: block;}   
                .btnSingle{width:27px !important; height:27px; padding:0px !important; cursor:pointer; outline:none; vertical-align: middle;   
                    &.btn_fileAdd{background-position: center center !important; margin-left:0px !important;}   
                }   
                .btn_fileAdd{width:auto; padding:0 7px 0 25px; float:left; margin-left:4px;}   
                .fileTooltip{margin-top:-5px;position:absolute; text-align:left;   
                    .arrow{left: 0%; margin-left: 9px;}   
                    .box{position:relative;}   
                }    
            }        
        }    
        .sp_ocm {line-height: initial  !important;margin-left: 5px;cursor:pointer;   
            .btn_ocm {width:auto !important; padding-left:24px;padding-right:4px;height:27px;border:1px solid #a6a6a6;background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat left 5px center, linear-gradient(to top, #f0f0f0, #ffffff) !important;outline:none;cursor:pointer;   
            &:hover {background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat left 5px center,linear-gradient(to top, #d9d9d9, #fafafa) !important;}   
            }               
        }      
    }   
    .conBox{position:relative; height:105px; background:#fff;           
        /*첨부파일 있는 경우*/   
        ul li{position:relative; border-bottom:1px solid #e4e4e4; height:30px; line-height:30px; padding-right: 10px;box-sizing:border-box;white-space: nowrap;   
            &:hover{background-color: #e9f5ff;}   
            .check{float:left; padding: 0 0 0 10px; width: 24px;   
                img{vertical-align: middle;}   
            }   
            .ico_new img{vertical-align: middle; margin-left: 3px; margin-top: -1px;}   
            .ellipsis{float:left; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis; padding-left:22px; margin:0px;}   
            .ocm {background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat center center;background-size:12px 12px;width: 12px;height: 12px; margin-left: 4px;margin-top: 1px;float:left;}   
        }   
   
        /*첨부파일 없는 경우*/   
        .noFile{width:100%; height:100%; display: table; text-align:center; position: relative; 
            .text{display: table-cell; vertical-align: middle;}   
            .ico{display: inline-block; margin-top: -2px; vertical-align: middle; width:15px; height:16px; background: url("../imgs/ico/ic_docu_addfile_m_normal.png") no-repeat left center; margin-right:3px;} 
        }   

        /* 드래그앤드롭 기능*/   
        .dragDropCon{background-color:rgba(#eff7ff, 0.7); width: 100%; height: 100%; z-index: 10; position: absolute;
            .dragDropOn{display: inline-flex; justify-content: center; align-items: center; position: absolute; top: 0; left: 0;
                img{width:26px; height:24px; box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2); margin:0 0 7px 0;}
                .textBox{width: max-content; color:#fff; border-radius: 9px; padding: 0 7px 1px; background-color:#1c90fb; cursor:default;}
            }
        }
   
        /*원문공개*/    
        .ico_eapFile_on{float:left; width:14px;height:14px;background:url("../imgs/ico/ic_open_s_normal.png") no-repeat center center; }                
        .ico_eapFile_off{float:left; width:14px;height:14px;background:url("../imgs/ico/ic_private_s_disable.png") no-repeat center center;}       
    }   
          
   
    /*썸네일*/   
    .flexBox.thumb{width:100%; overflow-y:auto; overflow-x:hidden;   
        .icon{background-size:36px 42px;}   
        .fb_div{   
            ul{padding:0px 10px;   
                li{border:none; float:left; width:74px; height:90px; margin:8px 10px 0 0; position: relative; padding-right: 0px;   
                    &:last-child{margin-right:0px;}   
                    &:hover{background:#fff;}   
                    .ellipsis{width: 100%; cursor:default; line-height:13px; box-sizing: border-box; padding:42px 0 0 2px;}   
                    .fileBox{width:inherit; height:100%; border: solid 1px #e4e4e4; box-sizing: border-box; background-color: #fff; margin-bottom:7px;}   
                    .text{float:left; width:inherit; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis; font-size:11px;}   
                    .size{float:left; font-size:11px;}   
                    .check{float:right; line-height: 0; padding: 8px 0 0 0; height:11px; margin-right:-5px;    
                        img{margin-top:-4px;}   
                    }   
                }   
            }   
            .eapFile{position:absolute;}   
            .ico_eapFile_on, .ico_eapFile_off{margin: 5px 0 0 2px;}   
            .prvIco {margin: 5px 0 0 5px;}   
            .downIco, .delIco, .historyIco, .linkIco {margin: 5px 0 0 2px;}
        }   
    }   
   
    /*목록*/   
    .flexBox.list{width:100%; overflow-y:hidden; overflow-x:auto;           
        .icon{overflow:hidden; float: left;background-size:16px 16px;max-width: calc(100% - 200px);text-overflow: ellipsis;padding-left: 20px;}   
        .fb_div{border-right:1px solid #e4e4e4; box-sizing: border-box;display: block;
            &:last-child{border-right:none;}   
        }         
        .eapFile{float: left;}    
        .ocm {vertical-align:middle;margin-top: -1px;float:inherit;display: inline-block;}   
   
        ul {display: flex !important;flex-flow: column wrap;   
            li {width: auto;border-right: 1px solid #e4e4e4; display:flex; cursor:default; min-width:220px;}   
        }   
        ul li.last .fileTooltip{margin-top:-60px !important;          
            .arrow{top: auto !important;bottom: -6px !important; background:url("../imgs/bg/bg_tooltip_arrow_down.png") left bottom 1px no-repeat !important;}   
        }      
        ul li.last .LayerSel{bottom:0px;}    
   
        .ico_eapFile_on, .ico_eapFile_off{margin: 8px 4px 0 0px;}   
        .ext {float: left;}   
        .prvIco, .downIco, .delIco, .historyIco, .linkIco {margin: 8px 0 0 3px;}
        .watchIco{margin: 8px 4px 0 0;}
        .clickInfoFlag{display: contents;}
    }   
   
    /*자세히*/   
    .flexBox.detail{width:100%; overflow:auto;   
        .icon{float:left; padding-left:20px; overflow:hidden; background-size:16px 16px;max-width: calc(100% - 183px);text-overflow: ellipsis;}          
        .eapFile{float: left;}     
        ul li.last .fileTooltip{margin-top:-60px !important;          
            .arrow{top: auto !important;bottom: -6px !important; background:url("../imgs/bg/bg_tooltip_arrow_down.png") left bottom 1px no-repeat !important;}   
        }      
        ul li.last .LayerSel{bottom:0px;}    
        .ocm {vertical-align:middle;margin-top: -1px;float: inherit;display: inline-block;}   
        .ico_eapFile_on, .ico_eapFile_off{margin: 8px 4px 0 0px;}   
        .ext {float: left;}   
        .prvIco, .downIco, .delIco, .historyIco, .linkIco {margin: 8px 0 0 3px;}
        .watchIco{float: left; margin: 8px 4px 0 0;}
    }   
    .flexBox .fb_div{width:100%;}   
       
    //작은사이즈버전   
    &.small { border:none; background: none;   
        .UpDownLoader_in {  clear: both;  border: solid 1px #e4e4e4;background: #fff;}   
        .header {height:24px; line-height:24px;}   
        .conBox ul li {height:24px; line-height:24px;text-overflow: ellipsis; overflow: hidden;padding-right: 0;}   
        .flexBox.detail .ico_eapFile_on, .flexBox.detail .ico_eapFile_off {margin:5px 4px 0 0;}   
        .flexBox.detail .icon {max-width: calc(100% - 60px);}   
        .flexBox.detail li.eapFileIcons .icon {max-width: calc(100% - 78px);}    
        .flexBox.detail .pd_btns {position: absolute;right:0;top:-1px;height:24px; box-shadow: -6px 0px 5px #fff;background: #fff;}    
   
        .flexBox.detail .pd_btns{
            .prvIco, .downIco, .delIco, .historyIco, .linkIco { margin: 5px 0px 0 3px;}   
        } 
         
        .subj_div {   
            .subj {float: left;line-height: 21px;}   
            .btns {float:right;   
                .btn {width:21px; height:21px; outline:none;cursor: pointer; border-radius: 2px;}   
                .btn_fileAdd{background-position: center center !important;margin-left: 2px;}   
            }   
        }   
        .header .title .size {padding: 0px 0 0 3px;}           
    }  
}     
   
/*퀵버튼*/   
.QuickButtonLayer{position: absolute; right:0px; top:0px; background:#fff; height: 29px;   
    ul{ margin:0 5px;   
        li{float:left; padding:0px;   
            input{border: none; background: none;}               
            .btnCustom{width:25px;height:25px;padding:0px; margin-top: 2px; cursor: pointer;outline:none;   
                &.pcSave{background:url("../imgs/ico/ic_download_m_normal.png") no-repeat center center;}   
                &.viewer{background:url("../imgs/ico/ic_search_m_normal.png") no-repeat center center;}   
                &:hover{background-color: #e5e5e5 !important; border-radius: 50%;}      
            }    
            &:hover{background-color: #fff;}    
        }   
    }   
}   
   
/*첨부파일 - 툴팁*/   
.fileTooltip{z-index:100; margin-top:3px;   
    .box{position: absolute; border-radius: 3px; border: solid 1px #a6a6a6; background-color: #ffffff; font-size: 12px; line-height: 16px; padding: 7px 10px; color:#000; box-sizing: border-box; text-align: left; white-space: pre-line;}   
    .arrow{position: relative; top:1px; width:11px; height:7px; left: 10px; background:url("../imgs/bg/bg_tooltip_arrow.png") left top 1px no-repeat; z-index: 10;}   
}   
   
.fileTooltipFix{z-index:100; margin-top:3px;position: fixed;   
    .box{border-radius: 3px; border: solid 1px #a6a6a6; background-color: #ffffff; font-size: 12px; line-height: 16px; padding: 7px 10px; color:#000; box-sizing: border-box; text-align: left; word-break: break-all;}   
    .arrow{position: relative; top:1px; width:11px; height:7px; left: 10px; background:url("../imgs/bg/bg_tooltip_arrow.png") left top 1px no-repeat; z-index: 10;}   
}   
   
/*첨부파일 - pc저장,뷰어열기*/   
.LayerSel{position: fixed; z-index:10;   
    ul{box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1); border: solid 1px #a6a6a6; background-color: #ffffff;   
        li{min-width:140px; height: 27px !important; line-height: 27px !important; padding:0 10px;   
            &:hover{background: #e6f5ff;}   
            &:last-child{border-bottom:none !important;}   
            &.pc{background:url("../imgs/ico/ic_download_m_normal.png") left 10px center no-repeat; padding-left: 30px;}   
            &.view{background:url("../imgs/ico/ic_viewer_open_m_normal.png") left 10px center no-repeat;padding-left: 30px;}   
            &.fax{background:url("../imgs/ico/ic_fax_m_normal.png") left 10px center no-repeat;padding-left: 30px;}   
        }   
    }      
}   
   
/*첨부파일 - 썸네일 pc저장,뷰어열기*/   
.ThumbLayerSel{position: absolute; width:inherit; top:6px; z-index:10; text-align: center;white-space:normal;   
    .btn{width:70px; height: 27px; border-radius: 2px; border: solid 1px #a6a6a6; background: linear-gradient(to top, #f0f0f0, #ffffff); font-size: 12px;   
        &:hover{border: solid 1px #036ddd; background: linear-gradient(to top, #1c90fb, #5eb5fd); color:#fff;}   
    }   
}   
.samNemo {border:1px solid #666;background: #fff;height:16px; width:50px;display:inline-block;    vertical-align: top;}   
   
/*첨부파일 이미지 - 썸네일*/   
.thumb{   
    .icon_ae{background:url("../imgs/file_icon/icon_board_ae_medium.png") left 14px top 22px no-repeat;}   
    .icon_ai{background:url("../imgs/file_icon/icon_board_ai_medium.png") left 14px top 22px no-repeat;}   
    .icon_asf{background:url("../imgs/file_icon/icon_board_asf_medium.png") left 14px top 22px no-repeat;}   
    .icon_avi{background:url("../imgs/file_icon/icon_board_avi_medium.png") left 14px top 22px no-repeat;}   
    .icon_bmp{background:url("../imgs/file_icon/icon_board_bmp_medium.png") left 14px top 22px no-repeat;}   
    .icon_doc{background:url("../imgs/file_icon/icon_board_doc_medium.png") left 14px top 22px no-repeat;}   
    .icon_docx{background:url("../imgs/file_icon/icon_board_docx_medium.png") left 14px top 22px no-repeat;}   
    .icon_dwg{background:url("../imgs/file_icon/icon_board_dwg_medium.png") left 14px top 22px no-repeat;}   
    .icon_etc{background:url("../imgs/file_icon/icon_board_etc_medium.png") left 14px top 22px no-repeat;}   
    .icon_flv{background:url("../imgs/file_icon/icon_board_flv_medium.png") left 14px top 22px no-repeat;}   
    .icon_gif{background:url("../imgs/file_icon/icon_board_gif_medium.png") left 14px top 22px no-repeat;}   
    .icon_gul{background:url("../imgs/file_icon/icon_board_gul_medium.png") left 14px top 22px no-repeat;}   
    .icon_htm{background:url("../imgs/file_icon/icon_board_htm_medium.png") left 14px top 22px no-repeat;}   
    .icon_html{background:url("../imgs/file_icon/icon_board_html_medium.png") left 14px top 22px no-repeat;}   
    .icon_eml{background:url("../imgs/file_icon/icon_board_eml_medium.png") left 14px top 22px no-repeat;}   
    .icon_hwp{background:url("../imgs/file_icon/icon_board_hwp_medium.png") left 14px top 22px no-repeat;}   
    .icon_hwtx{background:url("../imgs/file_icon/ic_board_hwtx_medium.png") left 14px top 22px no-repeat;}   
    .icon_id{background:url("../imgs/file_icon/icon_board_id_medium.png") left 14px top 22px no-repeat;}   
    .icon_img{background:url("../imgs/file_icon/icon_board_img_medium.png") left 14px top 22px no-repeat;}   
    .icon_jpg{background:url("../imgs/file_icon/icon_board_jpg_medium.png") left 14px top 22px no-repeat;} 
    .icon_jpeg{background:url("../imgs/file_icon/icon_board_jpeg_medium.png") left 14px top 22px no-repeat;}   
    .icon_tiff{background:url("../imgs/file_icon/icon_board_tiff_medium.png") left 14px top 22px no-repeat;}   
    .icon_mov{background:url("../imgs/file_icon/icon_board_mov_medium.png") left 14px top 22px no-repeat;}   
    .icon_mp3{background:url("../imgs/file_icon/icon_board_mp_3_medium.png") left 14px top 22px no-repeat;}   
    .icon_mp4{background:url("../imgs/file_icon/icon_board_mp_4_medium.png") left 14px top 22px no-repeat;}   
    .icon_mpeg{background:url("../imgs/file_icon/icon_board_mpeg_medium.png") left 14px top 22px no-repeat;}   
    .icon_mpg{background:url("../imgs/file_icon/icon_board_mpg_medium.png") left 14px top 22px no-repeat;}   
    .icon_oneffice{background:url("../imgs/file_icon/icon_board_oneffice_medium.png") left 14px top 22px no-repeat;}   
    .icon_onefficeSlide{background:url("../imgs/file_icon/ic_board_oneffice_slide_medium.png") left 14px top 22px no-repeat;}   
    .icon_pdf{background:url("../imgs/file_icon/icon_board_pdf_medium.png") left 14px top 22px no-repeat;}   
    .icon_png{background:url("../imgs/file_icon/icon_board_png_medium.png") left 14px top 22px no-repeat;}   
    .icon_ppt{background:url("../imgs/file_icon/icon_board_ppt_medium.png") left 14px top 22px no-repeat;}   
    .icon_pptx{background:url("../imgs/file_icon/icon_board_pptx_medium.png") left 14px top 22px no-repeat;}   
    .icon_psd{background:url("../imgs/file_icon/icon_board_psd_medium.png") left 14px top 22px no-repeat;}   
    .icon_pr{background:url("../imgs/file_icon/icon_board_pr_medium.png") left 14px top 22px no-repeat;}   
    .icon_tif{background:url("../imgs/file_icon/icon_board_tif_medium.png") left 14px top 22px no-repeat;}   
    .icon_txt{background:url("../imgs/file_icon/icon_board_txt_medium.png") left 14px top 22px no-repeat;}   
    .icon_wav{background:url("../imgs/file_icon/icon_board_wav_medium.png") left 14px top 22px no-repeat;}   
    .icon_wma{background:url("../imgs/file_icon/icon_board_wma_medium.png") left 14px top 22px no-repeat;}   
    .icon_wmv{background:url("../imgs/file_icon/icon_board_wmv_medium.png") left 14px top 22px no-repeat;}   
    .icon_word{background:url("../imgs/file_icon/icon_board_word_medium.png") left 14px top 22px no-repeat;}   
    .icon_xd{background:url("../imgs/file_icon/icon_board_xd_medium.png") left 14px top 22px no-repeat;}   
    .icon_xls{background:url("../imgs/file_icon/icon_board_xls_medium.png") left 14px top 22px no-repeat;}   
    .icon_xlsx{background:url("../imgs/file_icon/icon_board_xlsx_medium.png") left 14px top 22px no-repeat;}  
    .icon_xlsm{background:url("../imgs/file_icon/icon_board_xlsm_medium.png") left 14px top 22px no-repeat;}   
    .icon_zip{background:url("../imgs/file_icon/icon_board_zip_medium.png") left 14px top 22px no-repeat;}   
}   
   
/*첨부파일 이미지 - 목록형, 자세히형*/   
.icon_ae{background:url("../imgs/file_icon/icon_board_ae_small.png") left center no-repeat;}   
.icon_ai{background:url("../imgs/file_icon/icon_board_ai_small.png") left center no-repeat;}   
.icon_asf{background:url("../imgs/file_icon/icon_board_mov_small.png") left center no-repeat;}   
.icon_avi{background:url("../imgs/file_icon/icon_board_mov_small.png") left center no-repeat;}   
.icon_mov{background:url("../imgs/file_icon/icon_board_mov_small.png") left center no-repeat;}   
.icon_mp4{background:url("../imgs/file_icon/icon_board_mov_small.png") left center no-repeat;}   
.icon_mpeg{background:url("../imgs/file_icon/icon_board_mov_small.png") left center no-repeat;}   
.icon_mpg{background:url("../imgs/file_icon/icon_board_mov_small.png") left center no-repeat;}   
.icon_wmv{background:url("../imgs/file_icon/icon_board_mov_small.png") left center no-repeat;}   
.icon_doc{background:url("../imgs/file_icon/icon_board_doc_small.png") left center no-repeat;}   
.icon_docx{background:url("../imgs/file_icon/icon_board_doc_small.png") left center no-repeat;}   
.icon_word{background:url("../imgs/file_icon/icon_board_doc_small.png") left center no-repeat;}   
.icon_dwg{background:url("../imgs/file_icon/icon_board_dwg_small.png") left center no-repeat;}   
.icon_etc{background:url("../imgs/file_icon/icon_board_etc_small.png") left center no-repeat;}   
.icon_flv{background:url("../imgs/file_icon/icon_board_flv_small.png") left center no-repeat;}   
.icon_gif{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}   
.icon_gul{background:url("../imgs/file_icon/icon_board_hwp_small.png") left center no-repeat;}   
.icon_hwtx{background:url("../imgs/file_icon/icon_board_hwp_small.png") left center no-repeat;}   
.icon_hwp{background:url("../imgs/file_icon/icon_board_hwp_small.png") left center no-repeat;}   
.icon_htm{background:url("../imgs/file_icon/icon_board_html_small.png") left center no-repeat;}   
.icon_html{background:url("../imgs/file_icon/icon_board_html_small.png") left center no-repeat;}   
.icon_eml{background:url("../imgs/file_icon/icon_board_html_small.png") left center no-repeat;}   
.icon_id{background:url("../imgs/file_icon/icon_board_id_small.png") left center no-repeat;}   
.icon_bmp{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}   
.icon_img{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}   
.icon_jpg{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}  
.icon_jpeg{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}  
.icon_tiff{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}
.icon_png{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}   
.icon_tif{background:url("../imgs/file_icon/icon_board_img_small.png") left center no-repeat;}   
.icon_mp3{background:url("../imgs/file_icon/icon_board_mp_3_small.png") left center no-repeat;}   
.icon_oneffice{background:url("../imgs/file_icon/icon_board_oneffice_small.png") left center no-repeat;}   
.icon_onefficeSlide{background:url("../imgs/file_icon/ic_board_oneffice_slide_small.png") left center no-repeat;}   
.icon_pdf{background:url("../imgs/file_icon/icon_board_pdf_small.png") left center no-repeat;}   
.icon_ppt{background:url("../imgs/file_icon/icon_board_ppt_small.png") left center no-repeat;}   
.icon_pptx{background:url("../imgs/file_icon/icon_board_ppt_small.png") left center no-repeat;}   
.icon_psd{background:url("../imgs/file_icon/icon_board_psd_small.png") left center no-repeat;}   
.icon_pr{background:url("../imgs/file_icon/icon_board_pr_small.png") left center no-repeat;}   
.icon_txt{background:url("../imgs/file_icon/icon_board_txt_small.png") left center no-repeat;}   
.icon_wav{background:url("../imgs/file_icon/icon_board_mp_3_small.png") left center no-repeat;}   
.icon_wma{background:url("../imgs/file_icon/icon_board_mp_3_small.png") left center no-repeat;}   
.icon_xd{background:url("../imgs/file_icon/icon_board_xd_small.png") left center no-repeat;}   
.icon_xls{background:url("../imgs/file_icon/icon_board_xls_small.png") left center no-repeat;}   
.icon_xlsx{background:url("../imgs/file_icon/icon_board_xls_small.png") left center no-repeat;}   
.icon_xlsm{background:url("../imgs/file_icon/icon_board_xls_small.png") left center no-repeat;}   
.icon_zip{background:url("../imgs/file_icon/icon_board_zip_small.png") left center no-repeat;}   
   
/*****************************   
    첨부파일-업다운로더 (단일, 심플)   
******************************/   
.UpDownLoader_s{ clear:both; overflow: hidden;   
    > table {table-layout: fixed;}   
    .btn{width:20px; height:27px;padding:0; cursor:pointer; outline:none; vertical-align: middle;}   
    .btnSingle{width:27px !important; height:27px; padding:0px !important; cursor:pointer; outline:none; vertical-align: middle;   
        &.btn_fileAdd{background-position: center center !important;}   
    }   
   
    .ocm {background:url("../imgs/ico/ic_onechamber_s_normal.png") no-repeat center center;width: 12px;height: 12px; margin-left: 2px;margin-top: 2px;float:left;}   
    .btn_ocm {background:url("../imgs/ico/ic_onechamber_s_normal.png") no-repeat center center;width: 12px;height:17px !important; border:none;}   
    .btn_download {background:url("../imgs/ico/ic_download_m_normal.png") no-repeat center center; width:13px;height:17px !important; border:none;}   
    .btn_preView {background:url("../imgs/ico/ico_misc_search_SR.png") no-repeat center center;background-size:22px 22px;width:16px;height: 12px; height:17px !important; border:none;}   
   
    .btn_fileAdd{width:auto; padding:0 7px 0 25px; float:left;}       
    .btn_delete{height:14px; border: none !important; background:url("../imgs/ico/ic_trashcan_s_normal.png") no-repeat center center, linear-gradient(to top, #fff, #fff) !important;margin-top: 5px;vertical-align: top;}   
    .btn_delete:hover{border: none !important; background:url("../imgs/ico/ic_trashcan_s_over.png") no-repeat center center, linear-gradient(to top, #fff, #fff) !important;}   
    .text{color:#b4b4b4;text-align: center; cursor:pointer;}   
    .fileDiv{border: solid 1px #e4e4e4; height: 27px;padding: 0 6px;line-height: 27px; box-sizing: border-box; margin-right:4px;position:relative; overflow: hidden;cursor: default;}   
    .fileName{float:left;overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis;max-width: calc(100% - 170px);}   
   
    .fileList{float: left; clear:both; margin:5px 0 0 0; cursor: default; width: 100%;   
        ul li{padding:5px 0 0 0; display:flex; position: relative;   
            &:first-child{padding:0px;}   
            &:hover{text-decoration: underline; cursor: default;}   
            .QuickButtonLayer{   
                ul{    
                    li{ padding:0;      
                        .btnCustom{width:18px;height:18px;margin-top:5px;   
                            &.pcSave{background:url("../imgs/ico/ic_download_m_normal.png") no-repeat center center;}   
                            &.viewer{background:url("../imgs/ico/ic_search_m_normal.png") no-repeat center center;}   
                        }   
                    }   
                }   
            }   
        }   
        .ico_file{background:url("../imgs/ico/ic_attach_s_normal.png") no-repeat left 3px center;}   
        .fileName{padding:0 0 0 22px;}   
        .prvIco, .downIco, .delIco, .historyIco, .linkIco { margin: 2px 0px 0 3px;}   
        .btn_delete {margin-top:2px}   
    }   
    table {   
        td {padding:0px !important;border:none !important;vertical-align: top;   
   
            [class*="OBTTooltip_root"] {vertical-align: top;}   
        }   
    }   
   
    .QuickButtonLayer{height: 25px;   
        ul{    
            li{ height:25px;              
                .btnCustom{width:23px;height:23px;padding:0px; margin-top: 1px; cursor: pointer;outline:none; }    
            }   
        }   
    }   
    .ext {float: left;}   
    .prvIco, .downIco, .historyIco, .linkIco { margin: 6px 0px 0 3px;}   
}   
   
.UpDownLoader_th{position: relative;   
    .tf_con{float:left; width:164px; height:116px; border: solid 1px #e4e4e4; box-sizing:border-box;   
        .pic{position: relative; width:inherit; height:inherit; position:relative;display: table-cell; vertical-align: middle;background: #fff;   
            img {width:100%;height:100%;}   
        }               
    }   
    .btnDel{position: absolute; height: 21px; background-color: rgba( 0, 0, 0, 0.3 ); width: 100%; left:0px; text-align: center; bottom: 0px;   
        .btn_file_del_w { background: url("../imgs/ico/ic_trashcan_m_normal.png") no-repeat center top 2px !important; border: none !important; width: 17px !important; height: 17px !important; cursor: pointer;outline:none; background-size: 17px !important;}           
    }   
       
    .ThumbLayerSel{top:30px;   
        .btn{display: block; margin: 2px auto 0;}   
    }   
   
    .ocm {background:#fff url("../imgs/ico/ic_onechamber_s_normal.png") no-repeat center center;width: 14px;height: 14px; margin-left: 4px;margin-top: 1px;float:left;position: absolute;left:5px; top:5px; box-shadow: 0px 1px 7px 0 rgba(3, 14, 27, 0.2);}   
   
    .btnSingle{width:27px !important; height:27px; padding:0px !important; cursor:pointer; outline:none; vertical-align: middle;   
        &.btn_fileAdd{background-position: center center !important;}   
    }   
    .btn_fileAdd{width:auto; padding:0 7px 0 25px; float:left;}   
   
    .prvIco {float:none; display:inline-block; width:14px;height:14px;background:url("../imgs/ico/icon_open_normal_w.png") no-repeat center center; cursor:pointer;margin:3px 0 0 0px;   
        &:hover {background:url("../imgs/ico/icon_open_normal_w.png") no-repeat center center; }   
    }       
    .downIco {float:none; display:inline-block; width:14px;height:14px;background:url("../imgs/ico/icon_savepc_normal_w.png") no-repeat center center; cursor:pointer;margin:3px 0 0 5px;   
        &:hover {background:url("../imgs/ico/icon_savepc_normal_w.png") no-repeat center center;}   
    }       
    .delIco {float:none; display:inline-block; width:14px;height:14px;background:url("../imgs/ico/icon_trashcan_normal_w.png") no-repeat center center; cursor:pointer;margin:3px 0 0 5px; background-size: 14px; 
        &:hover {background:url("../imgs/ico/icon_trashcan_normal_w.png") no-repeat center center; background-size: 14px;}   
    }      
    .linkIco {float:none; display:inline-block; width:14px;height:14px;background:url("../imgs/ico/ic_link_s_normal@2x.png") no-repeat center center; cursor:pointer; margin:3px 0 0 5px; background-size: 14px; filter: brightness(0) saturate(100%) invert(100%);
        &:hover {background:url("../imgs/ico/ic_link_s_over@2x.png") no-repeat center center; filter: brightness(0) saturate(100%) invert(100%); background-size: 14px;}   
    } 
}   
   
.file_btn_set { clear: both; margin-bottom: 5px;overflow: hidden;   
    .btn{width:27px; height:27px;padding:0; cursor:pointer; outline:none; vertical-align: middle;}   
    .btnSingle{width:27px !important; height:27px; padding:0px !important; cursor:pointer; outline:none; vertical-align: middle;   
        &.btn_fileAdd{background-position: center center !important;}   
    }   
    .btn_fileAdd{width:auto; padding:0 7px 0 25px; float:left;}       
    .btn_delete{height:17px; border: none !important; background:url("../imgs/ico/ic_trashcan_m_normal.png") no-repeat center center, linear-gradient(to top, #fff, #fff) !important;}   
}   
     
.fileDownLayer { border:1px solid #a6a6a6; width: 160px;box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1);box-sizing:border-box;background: #fff;position:fixed;z-index: 11;font-weight: normal;
    ul {padding:0 !important;   
        li{ height:27px !important; padding-left:29px !important;line-height: 27px !important;cursor: pointer;width:auto !important;float: inherit !important;margin:0 !important; min-width:auto !important;
            &.pc{background:url("../imgs/ico/ic_pc_save_s_normal.png") no-repeat left 9px center;}   
            &.oc{background:url("../imgs/ico/ic_onechamber_s_normal@2x.png") no-repeat left 9px center; background-size: 14px;}   
            &.vi{background:url("../imgs/ico/ic_viewer_open_s_normal.png") no-repeat left 9px center;}   
            &.fax{background:url("../imgs/ico/ic_fax_s_normal.png") no-repeat left 9px center;}   
            &.doc{background:url("../imgs/ico/ic_docu_s_normal.png") no-repeat left 9px center;}
            &.of{background:url("../imgs/ico/ic_oneffice_s_normal.png") no-repeat left 9px center;}
            &.ocLink{background:url("../imgs/ico/ic_link_popup_s_normal@2x.png") no-repeat left 9px center; background-size: 14px;}
            &:hover {   
                &.pc{background:#e6f5ff url("../imgs/ico/ic_pc_save_s_normal.png") no-repeat left 9px center !important;}   
                &.oc{background:#e6f5ff url("../imgs/ico/ic_onechamber_s_normal@2x.png") no-repeat left 9px center !important; background-size: 14px !important;}   
                &.vi{background:#e6f5ff url("../imgs/ico/ic_viewer_open_s_normal.png") no-repeat left 9px center !important;}   
                &.fax{background:#e6f5ff url("../imgs/ico/ic_fax_s_normal.png") no-repeat left 9px center !important;}   
                &.doc{background:url("../imgs/ico/ic_docu_s_normal.png") no-repeat left 9px center !important;}
                &.of{background:url("../imgs/ico/ic_oneffice_s_normal.png") no-repeat left 9px center !important;}
                &.ocLink{background-color:#e6f5ff;}
            }   
        }   
    }   
}   
   
.prvIco {float:left; width:14px;height:14px;background:url("../imgs/ico/ic_viewer_open_s_normal.png") no-repeat center center; cursor:pointer;   
    &:hover {background:url("../imgs/ico/ic_viewer_open_s_over.png") no-repeat center center; }   
}   
.downIco {float:left; width:14px;height:14px;background:url("../imgs/ico/ic_download_s_normal.png") no-repeat center center; cursor:pointer;   
    &:hover {background:url("../imgs/ico/ic_download_s_over.png") no-repeat center center;}   
}   
.delIco {float:left; width:14px;height:14px;background:url("../imgs/ico/ic_trashcan_s_normal.png") no-repeat center center; cursor:pointer;   
    &:hover {background:url("../imgs/ico/ic_trashcan_s_over.png") no-repeat center center;}   
}   
.historyIco {float:left; width:14px;height:14px;background:url("../imgs/ico/ic_time_history_s_normal.png") no-repeat center center; cursor:pointer;   
    &:hover {background:url("../imgs/ico/ic_time_history_s_over.png") no-repeat center center;}   
} 
.linkIco {float:left; width:14px;height:14px;background:url("../imgs/ico/ic_link_s_normal@2x.png") no-repeat center center; cursor:pointer; background-size: 14px; 
    &:hover {background:url("../imgs/ico/ic_link_s_over@2x.png") no-repeat center center; background-size: 14px;}   
}   
   
/*****************************   
    pubThumbStyle   
******************************/   
.pubThumbStyle {border:1px solid #e6e6e6;height:100%;box-sizing: border-box;}   
   
/*****************************   
    pubChipsStyle   
******************************/   
.pubChipsStyle {height: 100%;box-sizing: border-box;vertical-align: top;   
    &.required {background-color: rgb(254, 244, 244); }   
    &.disabled {background-color: #f5f5f5;}  
}   

/*****************************   
    pubConfirmStyle   
******************************/
.pubConfirmStyle{
    &.dontLookAgain{
        [class*="OBTConfirm_wrapper"] > [class*="OBTConfirm_confirmBoxStyle"] {padding:30px 30px 68px 30px !important;}
        .dontLookBottom{position: absolute;bottom: 0;width: 100%;height: 38px;padding: 0 14px;font-size: 12px;color: #8c8c8c;box-sizing: border-box;background-color: #f7f7f7;}
    }
}
   
/*****************************   
    transition   
******************************/   
.transitionBasic{transition: all 0.5s ease;   
    //&.open {}   
    &.close {height:0px !important;}   
}   

/*****************************   
    공통검색   
******************************/ 
// 돋보기 검색
.pubSearchBox{position:relative;
    .searchBtn{position: absolute;top:4px;right:0;width:30px;height:20px;background: url("../imgs/ico/ic_search_m_normal.png") no-repeat center;cursor: pointer;
        &:hover{background: url("../imgs/ico/ic_search_m_over.png") no-repeat center;}
        &.disabled{background: url("../imgs/ico/ic_search_m_disable.png") no-repeat center;}
    }
}
   
/*****************************   
    공통댓글   
******************************/   
.btn_attach {border-radius: 2px;border: solid 1px #a6a6a6;width: 27px; height:27px;background:url("../imgs/ico/ic_attach_m_normal.png") no-repeat center center,linear-gradient(to top, #f0f0f0, #ffffff) !important;outline:none; vertical-align: middle;   
    &:hover {background:url("../imgs/ico/ic_attach_m_normal.png") no-repeat center center, linear-gradient(to top, #d9d9d9, #fafafa) !important;}   
    &:active {background:url("../imgs/ico/ic_attach_m_over.png") no-repeat center center, linear-gradient(to top, #d9d9d9, #f5f5f5) !important;}   
}   
   
.btn_attach_ocm {border-radius: 2px;border: solid 1px #a6a6a6;width: 27px; height:27px;background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat center center,linear-gradient(to top, #f0f0f0, #ffffff) !important;outline:none; vertical-align: middle;   
    &:hover {background:url("../imgs/ico/ic_onechamber_m_normal.png") no-repeat center center, linear-gradient(to top, #d9d9d9, #fafafa) !important;}   
    &:active {background:url("../imgs/ico/ic_onechamber_m_over.png") no-repeat center center, linear-gradient(to top, #d9d9d9, #f5f5f5) !important;}   
}   
   
.rpWrite {   
    .rpWrite_ta{   
        table { width: 100%;table-layout:fixed;   
            .anTit { margin-bottom: 10px;;   
                input[type="text"] {vertical-align: middle; padding-right: 70px; box-sizing: border-box; height: 27px; outline: none; padding-left: 5px; line-height: 26px; border: 1px solid #cccccc; font-size: 13px; width: 180px; word-break: break-all; background: #fff;}   
                .txt {margin-left: 10px; color:#999}   
            }   
            .textSet{position: relative;border:1px solid #cccccc;background:#ffffff;overflow: hidden;   
                &:focus-within {border: 1px solid #1c90fb;}   
                &.fix100 {   
                    .rpText{height:100px; overflow-y:auto;}   
                }   
                &.smallSet{   
                    .rpText{width:100%;height: 78px;padding:12px;outline: none;font-size: 12px;line-height: 1.8;vertical-align: middle;word-break: break-all;background-color: #ffffff;overflow-y: auto;box-sizing:border-box;}   
                    .rpFnc{width:100%;height:34px;border-top:1px solid #e6e6e6;background-color: #ffffff;   
                        .rpIconBtn{position: relative; margin:10px 0 10px 12px;   
                            .icoBtn{margin:0 0 0 8px;cursor: pointer;   
                                &:first-child{margin:0;}   
                                .icoAttach{width:14px;height:14px;background:url('../imgs/ico/ic_attach_s_normal.png') no-repeat center;
                                    &:hover{background:url('../imgs/ico/ic_attach_s_over.png') no-repeat center;}
                                }   
                                .icoLink{width:14px;height:14px;background:url('../imgs/ico/ic_link_s_normal.png') no-repeat center;
                                    &:hover{background:url('../imgs/ico/ic_link_s_over.png') no-repeat center;}
                                }   
                                .icoEmoticon{width:14px;height:14px;background:url('../imgs/ico/ic_emoticon_s_normal.png') no-repeat center;
                                    &:hover{background:url('../imgs/ico/ic_emoticon_s_over.png') no-repeat left 1px top 1px;}
                                }   
                                .icoVoice{width:14px;height:14px;background:url('../imgs/ico/ic_voice_s_normal.png') no-repeat center;
                                    &:hover{background:url('../imgs/ico/ic_voice_s_over.png') no-repeat center;}
                                }   
                            }   
                        }   
                        .reCompBtn{margin:7px;
                            
                        }   
                        .mentionListBox{left: -13px; right: 0; bottom: -11px; max-height: 136px;}
                    }   
                }   
                .rpText{width:100%;height: 82px;padding: 15px 13px;outline: none;font-size: 13px;line-height: 1.8;vertical-align: middle;word-break: break-all;background-color: #ffffff;overflow-y: auto;box-sizing:border-box;}   
                .rpFnc{width:100%;height:47px;border-top:1px solid #e6e6e6;background-color: #ffffff;   
                    .rpIconBtn{position: relative; margin:15px 0 15px 13px;   
                        .icoBtn{margin:0 0 0 10px;cursor: pointer;   
                            &:first-child{margin:0;}   
                            .icoAttach{width:18px;height:18px;background:url('../imgs/ico/ic_attach_m_normal.png') no-repeat center;
                                &:hover{background:url('../imgs/ico/ic_attach_m_over.png') no-repeat center;}
                            }   
                            .icoLink{width:18px;height:18px;background:url('../imgs/ico/ic_link_m_normal.png') no-repeat center;
                                &:hover{background:url('../imgs/ico/ic_link_m_over.png') no-repeat center;}
                            }   
                            .icoEmoticon{width:18px;height:18px;background:url('../imgs/ico/ic_emoticon_m_normal.png') no-repeat center;
                                &:hover{background:url('../imgs/ico/ic_emoticon_m_over.png') no-repeat center;}
                            }   
                            .icoVoice{width:18px;height:18px;background:url('../imgs/ico/ic_voice_m_normal.png') no-repeat center;
                                &:hover{background:url('../imgs/ico/ic_voice_m_over.png') no-repeat center;}
                            }   
                        }   
                    }   
                    .reCompBtn{margin:7px 8px;}   
                }   
   
                input[type="text"].cloneHiddenText{position:absolute; left:-1000000px;top:-10000000px; visibility: hidden;}   
                // .keepLink{position: relative;display: inline-block;height: 18px;margin: 0 5px 0 0;padding: 0 0 0 20px;cursor: pointer;max-width: 200px;vertical-align: middle;    
                //     img{position: absolute;top: 0;left: 0;width: 18px;height: 18px;}    
                //     .text{width:100%;font-size:12px;font-family:auto;line-height:19px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
                //     &.small{position: relative;display: inline-block;height: 15px;margin: 0 5px 0 0;padding: 0 0 0 15px;cursor: pointer;max-width: 180px;vertical-align: middle;    
                //         img{position: absolute;top: 2px;left: 0;width: 11px;height: 11px;}    
                //         .text{width:100%;font-size:11px;font-family:auto;line-height:15px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
                //     }   
                // }  
            }   
        }   
    }   
   
    .mentionListBox{display:none;position: absolute;bottom:-15px;left: -13px;right: 0;max-height: 158px;border: 1px solid #eaeaea;background: #ffffff;overflow-y: auto;z-index:11;   
        li {position:relative;padding: 5px 0;border-top: 1px solid #eaeaea;cursor:pointer;   
            &.on{background:#f2f2f2;}          
            &:hover {background:#f2f2f2;}   
            &:first-child{border-top:0;}      
            .mentionImg{position:absolute;top: 5px;left:0;width:36px;height:36px;margin: 0 0 0 4px;overflow:hidden;border-radius:50% 50%;background:url("../imgs/empty/profile_noimg_large.png") no-repeat center center ;background-size:36px 36px;   
                img{width:100%;height:100%;border-radius:50% 50%;}   
            }   
            .mentionInfo{margin:0px 0 0 44px;line-height:18px;   
                dt{font-size: 12px;font-weight: bold;}   
                dd{color:#b1b1b1;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}   
            }   
        }   
    }   
}   
   
.naSed {font-size: 13px;margin-right:4px;color:#1c90fb;display:inline;border:none;outline:none;background:transparent;   
    &.on {background: #000;}   
}   
   
.write_area {   
    .rpWrite_ta{padding:20px 20px 0px 20px;}   
}   
   
.reply_set {   
    .replyFile{width: 100%;margin: 10px 0 0 0;} // 첨부파일 공통   
   
    &.photo_type{   
        .rp_head{    
            .rp_photo { display:block !important;}   
            .rp_info {float:left;width:calc(100% - 48px);padding-top:9px;}   
        }   
        .rp_contents {float:right;width:calc(100% - 48px);padding-top: 0px !important;}   
        .re{    
            .rp_head{   
                .rp_info {padding-top:2px;}   
            }   
            .rp_contents {width:calc(100% - 66px);padding-left: 0px !important;}   
   
            .rp_head + .rpWrite{    
                .rpWrite_ta{margin-left: 66px !important;}   
            }   
        }   
    }   
    &.chat_type {   
        .rp_head{   
            .rp_photo { display:block !important;}   
            .rp_info {float:left;width:calc(100% - 48px);padding-top:9px;}   
        }   
        .rp_contents {float:right;width:calc(100% - 48px);padding:10px !important; background: #eeeff1;border-radius: 8px;   
        }   
        .re{    
            .rp_head{   
                .rp_info {padding-top:2px;}   
            }   
            .rp_contents {width:calc(100% - 66px);padding-left: 10px !important;margin-top: 5px;}   
   
            .rp_head + .rpWrite{    
                .rpWrite_ta{margin-left: 66px !important;}   
            }   
        }   
        .semo {display:inline-block !important;}       
    }   
           
    .reply_box{ border-bottom:1px solid #e6e6e6;   
        &::after { content: ''; display: table; clear: both }   
        .reply_div{padding:0px 20px 18px 20px;   
            &::after { content: ''; display: table; clear: both }   
            &.re {padding-left: 20px;background: url("../imgs/ico/icon_reply_bracket.png") no-repeat 26px 26px;   
                .rp_head{padding-left: 28px;   
                    .rp_photo{display:none;width: 38px;   
                        .pic {width:30px;height: 30px;   
                            background:url("../imgs/empty/profile_noimg_large.png") no-repeat center center ;background-size:30px 30px;   
                            img {width:30px; height:30px;}   
                        }   
                    }   
                }   
                .rp_contents {padding-left:28px;}   
                .rpWrite{ border-top: 1px solid #e6e6e6;padding-top:20px;   
                    .rpWrite_ta{margin-left: 28px;;}   
                }   
                .rp_head + .rpWrite{ border-top: none; clear:both;padding-top:7px;}   
            }   
   
            &.deleted{   
                .rp_head{   
                    .rp_info {   
                        .left_div{   
                            .na {display:none;}   
                            .date {padding-left:0;margin-left:0;background: none;}   
                        }   
                    }   
                }   
                .rp_contents{   
                    .rpText {color:#797979;background:url("../imgs/ico/ic_caution_01_s_normal_red.png") no-repeat left center; padding-left:17px;word-break: break-all;}   
                }   
            }   
   
            .rp_head{padding-top:18px;   
                &::after { content: ''; display: table; clear: both }   
                .rp_photo{display:none;width: 48px;text-align: left;float: left;   
                    .pic {width:40px;height: 40px;border-radius: 50% 50%;   
                        background:url("../imgs/empty/profile_noimg_large.png") no-repeat center center ;background-size:40px 40px;   
                        img {width:40px; height:40px; border-radius: 50% 50%;}   
                    }   
                }   
                .rp_info {   
                    .left_div {   
                        float: left;line-height: 24px;   
                        .na {font-weight: bold; font-size:13px;letter-spacing:-0.65px;}   
                        .date {font-size:12px; margin-left:6px;padding-left:7px;color:#8c8c8c;line-height: 12px;background:url("../imgs/bg/bg_line_bar01.png") no-repeat left center;}   
                    }   
                    .right_div {   
                        float: right;   
                        > span { float: left;position:relative;margin: 2px 0 0 10px;   
                            > input[type="button"] {width:18px;height:18px;cursor: pointer;}   
                            .btn_reply {border:none;outline: none;background:url("../imgs/ico/ic_comment_m_normal.png") no-repeat center center;
                                &:hover{background:url("../imgs/ico/ic_comment_m_over.png") no-repeat center center;}
                            }   
                            .btn_modify {border:none;outline: none;background:url("../imgs/ico/ic_edit_m_normal.png") no-repeat center center;
                                &:hover{background:url("../imgs/ico/ic_edit_m_over.png") no-repeat center center;}
                            }   
                            .btn_delete {border:none !important;outline:none;background:url("../imgs/ico/ic_trashcan_m_normal.png") no-repeat center center !important;
                                &:hover{background:url("../imgs/ico/ic_trashcan_m_over.png") no-repeat center center !important;}
                            }   
                            .fileTooltip {left:-8px;top:17px; position:absolute;}   
                            .fileTooltip .box {white-space: nowrap;}   
                        }               
                    }   
                }   
            }   
            .rp_contents {clear:both;font-size:13px;box-sizing:border-box;padding-top: 5px;position:relative; word-break: break-all;   
                .rpSed {color:#1c90fb;font-weight: bold;margin-right:5px;letter-spacing:-0.65px;}   
                // .keepLink{position: relative;display: inline-block;height: 18px;margin: 0 5px 0 0;padding: 0 0 0 20px;cursor: pointer;max-width: 200px;vertical-align: middle;    
                //     img{position: absolute;top: 0;left: 0;width: 18px;height: 18px;}    
                //     .text{width:100%;font-size:12px;font-family:auto;line-height:19px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
                //     &.small{position: relative;display: inline-block;height: 15px;margin: 0 5px 0 0;padding: 0 0 0 15px;cursor: pointer;max-width: 180px;vertical-align: middle;    
                //         img{position: absolute;top: 2px;left: 0;width: 11px;height: 11px;}    
                //         .text{width:100%;font-size:11px;font-family:auto;line-height:15px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
                //     }   
                // }  
                .semo{width: 12px;height: 12px;display:none; position: absolute;background:url("../imgs/bg/img_reply_bubble.png") no-repeat center center;top:1px;left:-11px;}   
            }     
        }   
   
        .reply_div + .reply_div.re{border-top:1px dashed #e6e6e6;}   
        .reply_div.re + .reply_div.re{border-top:none;   
            .rp_head{       
            border-top:1px solid #e6e6e6;   
            }           
        }   
    }   
   
    .rpFile{   
        border-top:1px solid #e6e6e6;padding-top:10px;margin-top:10px;   
        li {line-height:24px;   
            p{float: left;cursor: pointer;padding-left: 22px;max-width: calc(100% - 100px);    
                &:hover {text-decoration: underline;}   
            }   
        }   
    }       
    .rpText {white-space:pre-wrap;}   
}   
   
/******************   
다이얼로그 -> alret 스타일   
******************/   
.pubDiaAlertStyle {display:inline;   
    .dialog_content {   
        .dialog_data {   
            > div:first-child {display:none;}   
            > div:nth-child(2) {border-top:none;}   
            > div:nth-child(3) {background: #fff;padding: 10px 0 20px 0;}   
        }   
    }   
}   
   
/*비밀번호 입력*/   
.passDiv{text-align: center;   
    .ico {width:38px; height:38px;background:url("../imgs/ico/icon_alert_password.png") no-repeat center center;margin:0 auto;}   
    .txt {font-size:15px;}   
}   
   
.footNo [class*="OBTDialog_bottomButton"]{display:none;}   
.scrollNo [class*="OBTDialog_data"]{overflow:hidden;}

// 다이얼로그 팝업에서 헤더와 버튼영역을 안쓸때
.pubDialogOnlyContents {
    ._isDialog{  
        > div:nth-child(2) {padding:0 !important;border:none !important}  
     }
    .dialog_content .dialog_data {  
        > div:first-child {display:none;}  
        > div:nth-child(2) {border-top:none;  
           [class*="OBTDialog_dataWrapper"]{top:0;left:0;right:0;bottom:0;}  
        }  
        > div:nth-child(3) {display:none;}  
    }  
    // 버튼영역 필요 시 
    &.btnTrue{
        .dialog_content .dialog_data {
            > div:nth-child(3) {display:block; margin: 0 !important;}
        }
    }
}

// 다이얼로그 호출로만 사용할때 (다이얼로그 디자인 초기화)
.pubDialogContentsNone{
    ._isDialog{  
        > div:nth-child(2) {
            width: 0 !important;
            min-width: 0 !important;
            height: 0 !important;
            min-height: 0 !important;
            margin: 0 !important;
            padding: 0 !important;
            border: none !important;
            background: transparent !important;
        }  
    }
    .dialog_content .dialog_data {height:0 !important;  
        > div:first-child {display:none;}  
        > div:nth-child(2) {border-top:none;  
           [class*="OBTDialog_dataWrapper"]{top:0;left:0;right:0;bottom:0;
            > .popupWrap > div {position:fixed;width:fit-content;height:fit-content;}
        }  
        }  
        > div:nth-child(3) {display:none;}  
    }

    //다이얼로그 팝업
    .popupWrap{justify-content:center;align-items:center;    
        .dialogBox{padding:0 30px;background-color: #ffffff;box-sizing: border-box;
            //타이틀
            .title{position:relative; align-items:center;
                .tit{margin:0px;padding:22px 0px 8px;font-size:18px; font-family: inherit;line-height: 1.28;height: 27px;letter-spacing: -0.9px;}                
                .btnBox{margin:19px 0px 8px;width:30px;height:30px;box-sizing: border-box;}
            }
            //컨텐츠 영역
            .contentsData{flex-grow: 1;border-top: 1px solid rgb(217, 217, 217);overflow: auto;position: relative; padding:0 0 30px 0;}
            // 팝업 공통 하단 버튼
            .bottomButton{margin: 0 -30px 0px;padding: 15px 0;background-color: #f7f7f7;gap:4px;}
            //오늘 창닫기
            .todayClose{margin: 0px -30px 0px; background-color: #ededed; padding:9px 30px;
                [data-orbit-component="OBTCheckBox"] svg {background-color:#fff;}
            }
            //닫기 버튼
            .closeBtn{width:13px;height:13px;background: url('../imgs/temp/klago_sp_lux.png') -140px -275px; cursor: pointer;}
        }
    }
}

// 다이얼로그 헤더에 컨텐츠 추가할때
.pubDialogHeaderContents{
    .dialog_data h1:first-child{padding: 19px 0 8px 0 !important;width: calc(100% - 30px);line-height: normal !important;}
}
   
/***************   
selective_input   
****************/   
.selectiveInput{position:relative; box-sizing: border-box;display:inline-block;min-height:27px;vertical-align:middle;}   
.selectiveInput .selectField{width:100%;min-height:27px;height:100%;padding: 0 0 0 0px;border:1px solid #cccccc;font-size:12px;background:#ffffff;outline:none;box-sizing:border-box;cursor:text;}   
.selectiveInput .selectField:after {content:"";display:block;clear:both;height:0;visibility:hidden;}   
.selectiveInput .selectField:focus {border:1px solid #3badde;}   
   
.selectiveInput .selectField.disabled{color:#8d8d8d;background:#f3f3f3;cursor:not-allowed;}   
.selectiveInput .selectField.disabled:before{content:"";position:absolute;top:0;left:0;right:0;bottom:0;background:transparent;cursor:not-allowed;z-index:1}   
.selectiveInput .selectField.disabled:focus{background:#f3f3f3;cursor:not-allowed;}   
.selectiveInput .selectField.disabled .addresObjView{border-color:#cccccc !important;background:transparent !important;color: #cccccc !important;}   
.selectiveInput .selectField.disabled .addresObjView .btn_edit{display:none !important;}   
.selectiveInput .selectField.disabled .addresObjView .btn_del{display:none !important;}   
.selectiveInput .selectField.disabled .addresObjView .addr_input{display:none !important;}   
.selectiveInput .selectField.disabled .addresObjViewText{margin: 0 5px !important;}   
   
.selectiveInput .selectField .addresObjView{position: relative;float:left;min-height:14px;line-height:14px;margin: 1px 0;padding: 1px 0 2px 0;color: #000000;line-height: normal;border: 1px solid #adcfff;background-color: #eff7ff;cursor:text;margin-left:1px;outline:none;}   
.selectiveInput .selectField .addresObjView .addresObjViewText{margin: 0 5px !important;white-space:nowrap;}   
.selectiveInput .selectField .addresObjView.write .addresObjViewText{visibility:hidden}   
.selectiveInput .selectField .addresObjView.edit .addresObjViewText{visibility:hidden}   
.selectiveInput .selectField .addresObjView .btn_edit{cursor:pointer; display: inline-block;width: 8px;height: 8px;background:url('../imgs/ico/icon_mail_edit_b.png') no-repeat center center;outline:none;margin: 0 5px 0 0;}   
.selectiveInput .selectField .addresObjView .btn_del{cursor:pointer; display: inline-block;width: 8px;height: 8px;background:url('../imgs/ico/icon_mail_close_b.png') no-repeat center center;outline:none;margin: 0 5px 0 0;}   
.selectiveInput .selectField .addresObjView .addr_input{display:none;}   
.selectiveInput .selectField .addresObjView.write .addr_input{display:block;position: absolute;top: -1px;left: -1px;right: -1px;bottom: -1px;}   
.selectiveInput .selectField .addresObjView.write .addr_input .editInput{width: 100%;height: 100%;text-align: left;padding:2px;border: 1px solid transparent;background-color: #ffffff;box-sizing: border-box;outline: none;}   
.selectiveInput .selectField .addresObjView.edit .addr_input{display:block;position: absolute;top: -1px;left: -1px;right: -1px;bottom: -1px;}   
.selectiveInput .selectField .addresObjView.edit .addr_input .editInput{width: 100%;height: 100%;text-align: left;padding:2px;border: 1px solid #3badde;background-color: #ffffff;box-sizing: border-box;outline: none;}   
   
.selectiveInput .selectField .addresObjView.error{border: 1px solid #ffb3ad;background-color: #ffeff0;}   
.selectiveInput .selectField .addresObjView.error .btn_edit{background:url('../imgs/ico/icon_mail_edit_r.png') no-repeat center center;}   
.selectiveInput .selectField .addresObjView.error .btn_del{background:url('../imgs/ico/icon_mail_close_r.png') no-repeat center center;}   
.selectiveInput .selectField .addresObjView.error.edit .addr_input .editInput{width: 100%;height: 100%;text-align: left;padding:2px;border: 1px solid #f38383;background-color: #ffffff;box-sizing: border-box;outline: none;}   
   
.selectiveInput .cloneHiddenAddr{overflow: hidden;position: absolute;top: -10000px;left: -10000px;width: 1px;height: 1px;}   
.selectiveInput .selectField:focus{border:1px solid #2196f3;}   
.selectiveInput .selectField.disabled:focus{border:1px solid #cccccc;}   
   
.div_autoComplete{display:none;position:absolute;border:1px solid #cccccc;background:#ffffff;z-index:102;width:100%;max-height:150px;overflow-x:hidden;overflow-y:auto;text-align:left;box-sizing: border-box;}   
.div_autoComplete li{height:22px;line-height:22px;padding:0 12px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;display:list-item;}   
.div_autoComplete li:hover{cursor:pointer;background:#f5f5f5;}   
.div_autoComplete li.on{background:#f5f5f5;}   
.div_autoComplete li .marking{background:#fff1c0;}   
   
.selectField.error {background:url('../imgs/ico/icon_input_error.png') no-repeat right 10px center;border:1px solid #e85e30;;   
    .txt {color:#e85e30; margin-left: 7px;line-height: 25px;;}   
    &:focus {border:1px solid #e85e30;}   
}   
   
/*****************************   
    마스터,관리자 공통 팝업   
******************************/   
.pageheadRight {position: absolute;top:-58px; right:0px;}   
   
.managerPop{width:100%; height:100%; /*padding:0 20px 20px 20px;*/ min-width: 1024px;   
    .popCon{border: solid 1px #d9d9d9; background-color: #fff; box-sizing: border-box; margin: 0 20px 20px 20px;}   
    .pageHeader {height:50px;box-sizing: border-box;border-bottom: 1px solid #e5e5e5; padding: 0px 20px;line-height:50px;   
        h2 {font-size:18px; }   
    }   
    /*타이틀*/   
    .MenuTitle{height:50px; padding:0 20px; line-height:50px;   
        .left_div{float:left; font-size:18px; padding:0 0 0 30px;   
            &.snbGroup {background:url("../imgs/ico/ic_master_normal.png") no-repeat left center;}   
            &.snbCorp {background:url("../imgs/ico/ic_administrator_normal.png") no-repeat left center;}   
            &.snbUser {background:url("../imgs/ico/ic_user_normal.png") no-repeat left center;}   
        }   
        .right_div{float:right; right:0px;   
            &.ico_help{cursor: pointer; margin-top:15px; width: 17px; height:17px; background: url('../imgs/ico/ic_question_01_m_disable.png') no-repeat center right;}   
        }   
    }     
    .fb_div {position: relative;}   
    .pageheadRight {top:11px; right:20px;}   
     /*---레프트 변경후 삭제할것------ */   
     .LnbMenu{border-right: solid 1px #d9d9d9; box-sizing: border-box; overflow:auto;   
        .LnbMenuCon{   
   
            .sub_2dep + .sub_3dep{height: 0;transform: rotateX(-90deg);}   
            .sub_2dep.on + .sub_3dep{height: auto;transform: rotateX(0deg);transition: 0.5s;   
                li:first-child{margin:10px 0 0 0;}       
            }   
   
            .sub_2dep{display:block; font-size: 14px; font-weight: bold; line-height: 1; color: #000; border-bottom:solid 1px #d9d9d9; padding:10px 25px 10px 10px;    
                background:url("../imgs/ico/ic_arrow_down_01_m_normal.png") no-repeat right 10px center; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis; cursor: pointer;   
                &:hover{color:#1c90fb;}   
                &.on{background:url("../imgs/ico/ic_arrow_up_01_m_normal.png") no-repeat right 10px center;}       
                &.on + .sub_3dep{display:block;}     
   
            }   
            .sub_3dep{font-size: 13px; font-weight: bold; line-height: 1; color: #000; border-bottom:solid 1px #d9d9d9; padding:0px 10px;   
                  
                li{background:url("../imgs/bg/bg_popSnb_depth.png") no-repeat left top 3px; cursor:pointer; padding:0 0 10px 15px; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis; cursor: pointer;   
                &:hover{color:#1c90fb;}       
                &.on{color:#1c90fb;}                   
                &:last-child{padding:0 0 10px 15px;}   
                }                  
            }   
        }   
    }   
       
    /*레프트 뉴*/   
    .LnbMenuManager{border-right: solid 1px #d9d9d9; box-sizing: border-box; overflow:auto;   
        .LnbMenuCon.sub_1dep{   
            .tit_div + .sub_2dep{height: 0;transform: rotateX(-90deg);}   
            .tit_div.on + .sub_2dep{height: auto;transform: rotateX(0deg);transition: 0.5s;   
                > li:first-child{margin:10px 0 0 0;}       
            }   
            > li{    
                > .tit_div{display:block; font-size: 14px; font-weight: bold; line-height: 1; color: #000; border-bottom:solid 1px #d9d9d9; padding:10px 25px 10px 10px; background:url("../imgs/ico/ic_arrow_down_01_m_normal.png") no-repeat right 10px center; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis; cursor: pointer;   
                &:hover{color:#1c90fb;}   
                &.on{background:url("../imgs/ico/ic_arrow_up_01_m_normal.png") no-repeat right 10px center;}       
                &.on + .sub_3dep{display:block;}     
                }   
            }   
            .sub_2dep{font-size: 13px; font-weight: bold; line-height: 1; color: #000; border-bottom:solid 1px #d9d9d9; padding:0px 10px;overflow-x:auto;          
               li{ white-space:nowrap;text-overflow:ellipsis; cursor: pointer;     
                    .tit_div {background:url("../imgs/bg/bg_popSnb_depth.png") no-repeat left top 3px; cursor:pointer; padding:0 0 10px 15px;}   
                    > .tit_div:hover {color:#1c90fb;}   
                    > .tit_div.on{color:#1c90fb;}                   
                }   
                .dep_close {background:url("../imgs/ico/ic_arrow_right_02_m_normal.png") no-repeat left -3px top 0px !important;}   
                .dep_open {background:url("../imgs/ico/ic_arrow_left_02_m_normal.png") no-repeat left -1px top 0px !important;}   
                .dep_close + ul {display: none;}   
                .dep_open + ul {display: block;}   
                .sub_3dep{   
                    > li {padding-left:15px;   
                       > .tit_div:hover {color:#1c90fb;}   
                       > .tit_div.on{color:#1c90fb;}                   
                    }   
                    .sub_4dep{   
                        > li {padding-left:15px;   
                            > .tit_div:hover {color:#1c90fb;}   
                            > .tit_div.on{color:#1c90fb;}                   
                        }   
                        .sub_5dep{   
                            > li {padding-left:15px;   
                                > .tit_div:hover {color:#1c90fb;}   
                                > .tit_div.on{color:#1c90fb;}                   
                            }   
                        }       
                    }   
                }                  
            }   
        }   
    }   
   
    /*컨텐츠*/   
    .contntsView{overflow:auto; word-break: break-all; padding:20px; height: 100%;}   
}   
   
/*****************************   
   카드리스트    
******************************/   
.pubCardListStyle {background: #f2f2f2; border:1px solid #e6e6e6;position: relative;height:100%;box-sizing:border-box;   
    &.noSort [class*="OBTCardList_sortstyle"] {display: none;}   
    &.checkCardList {   
        [class*="OBTCardList_checkstyle"] {padding-right: 0; margin-left: 10px;}   
    }   
   
    &.qwMenuList {   
        [class*="OBTPagination_pageRight"] {display:none;}   
        [class*="OBTCardList_checkstyle"] {padding-right: 0; margin-left: 10px;}   
        .qw {position: absolute;right:10px; top:17px;   
            ul{   
                li {background:none !important; border:none !important;   
                    > div {height:25px !important;}   
                    span {display: inline-block;width: 14px; height:14px;border-radius:50%;background: #e6e6e6;}   
                    &.basic {   
                        span.on {background:#20c997;}   
                    }   
                    &.erp {   
                        span.on {background:#1c90fb;}   
                    }   
                }   
            }   
        }   
    }      
   
    &.ctfMenuList {   
        [class*="OBTPagination_pageRight"] {display:none;}   
        [class*="OBTCardList_checkstyle"] {padding-right: 0; margin-left: 10px;}   
        .cardList > div > div + div > div > div > div ul > li {border-radius: 3px !important;}   
        .cardList > div > div + div > div > div > div ul > li .selectedBorder{border-radius: 3px !important;}   
        .ctfBox{padding:0 16px;box-sizing:border-box;   
            .topUnit{height:41px;border-bottom:1px solid #e6e6e6;box-sizing:border-box;   
                .text01{font-size:13px;line-height:40px;color:#000000;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}   
                .text02{font-size:12px;line-height:40px;color:#a6a6a6;margin:0 0 0 10px;}   
            }   
            .botUnit{padding:13px 0 0 0;   
                .lineUnit{height:24px;margin:0 0 10px 0;   
                    .tag01{width:42px;height:18px;font-size:11px;line-height:18px;text-align:center;color:#ffffff;background-color:#7f98fd;border-radius:18px;}   
                    .tag02{width:42px;height:18px;font-size:11px;line-height:18px;text-align:center;color:#ffffff;background-color:#37c1d6;border-radius:18px;}   
                    .ico{justify-content:flex-end;   
                        .mobile{width:24px;height:24px;margin:0 0 0 12px;background:url('../imgs/ico/ic_certify_mobile_l_normal.png') no-repeat center;   
                            &.on{background:url('../imgs/ico/ic_certify_mobile_l_over.png') no-repeat center;}   
                        }   
                        .otp{width:24px;height:24px;margin:0 0 0 12px;background:url('../imgs/ico/ic_certify_otp_l_normal.png') no-repeat center;   
                            &.on{background:url('../imgs/ico/ic_certify_otp_l_over.png') no-repeat center;}   
                        }   
                        .device{width:24px;height:24px;margin:0 0 0 12px;background:url('../imgs/ico/ic_certify_device_l_normal.png') no-repeat center;   
                            &.on{background:url('../imgs/ico/ic_certify_device_l_over.png') no-repeat center;}   
                        }   
                        .password{width:24px;height:24px;margin:0 0 0 12px;background:url('../imgs/ico/ic_certify_password_l_normal.png') no-repeat center;   
                            &.on{background:url('../imgs/ico/ic_certify_password_l_over.png') no-repeat center;}   
                        }   
                        .fingerPrint{width:24px;height:24px;margin:0 0 0 12px;background:url('../imgs/ico/ic_certify_fingerprint_l_normal.png') no-repeat center;   
                            &.on{background:url('../imgs/ico/ic_certify_fingerprint_l_over.png') no-repeat center;}   
                        }   
                    }   
                }   
            }   
        }   
        .ctf {position: absolute;right:10px; top:22px;   
            ul{   
                li {background:none !important; border:none !important; float: left;   
                    > div {height:25px !important;}   
                    span {display: inline-block;width: 32px; height:32px;}   
                    &.fingerprint {   
                        span {background:url("../imgs/ico/ic_certify_fingerprint_l_disable.png") no-repeat center center; }   
                        span.on {background:url("../imgs/ico/ic_certify_fingerprint_l_over.png") no-repeat center center; }   
                    }   
                    &.otp {   
                        span{background:url("../imgs/ico/ic_certify_otp_l_normal.png") no-repeat center center;}   
                        span.on {background:url("../imgs/ico/ic_certify_otp_l_over.png") no-repeat center center;}   
                    }   
                    &.device {   
                        span{background:url("../imgs/ico/ic_certify_device_l_disable.png") no-repeat center center;}   
                        span.on {background:url("../imgs/ico/ic_certify_device_l_over.png") no-repeat center center;}   
                    }   
                    &.password {   
                        span{background:url("../imgs/ico/ic_certify_password_l_normal.png") no-repeat center center;}   
                        span.on {background:url("../imgs/ico/ic_certify_password_l_over.png") no-repeat center center;}   
                    }   
                }   
            }   
        }   
    }   
   
    .card_search{overflow: hidden;padding: 16px 10px 10px 10px;   
        .btnImg {vertical-align: middle;margin-left: 4px; margin-top:-3px;}   
        &.qwSearch{position: relative; height: 100px; padding: 9px 0; margin: 0 13px 10px; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;}
    }   
    .cd_tit {position:absolute;z-index: 2;
        &.qwCd_tit{top: 75px; left: 13px;}   
        .ct {margin-right: 7px;
            em{font-weight:bold;}
        }   
    }   
    .cd_sort {position:absolute;z-index: 2;right:0px;padding: 10px 12px 0px 0px;
        &.qwCd_sort{top: 75px; right: 13px; padding: 0;}
    }   
    .cardList {border:none;border-top:1px solid #e6e6e6;   
        [class*="OBTCardList_addRow"] {background: #fff;}   
    }   
    .cardList #inputElement {color:#000 !important;}   
    .cardList > div {border:none;}   
    .cardList > [class*="OBTCardList_pagination"] {border-top:1px solid #e6e6e6;box-sizing: border-box;}   
    .cardList > div > div:first-child {height:36px !important;border-bottom:0px;}   
    .cardList > div > div:first-child + div {display: none;}   
    .cardList > div > div + div > div >div > div{padding:0 10px;padding-top: 10px;box-sizing: border-box;}   
    .cardList > div > div + div > div > div > div ul > li {margin-bottom: 4px;border:1px solid #d7d7d7;background: #fff; border-radius:3px;box-sizing: border-box;}   
    .cardList > div > div + div > div > div > div ul > li .selectedBorder {border-radius: 3px;}   
    .cardList > div > div + div > div > div > div ul > li > div:first-child{padding:0 !important;height:36px;}   
    .cardList.toneDown .selectedList {background-color: #f2f8fd !important;}   
    .cardList.toneDown > div > div + div > div > div > div ul > li {border: solid 1px #e0e0e0;}   
    .cardList.toneDown > div > div + div > div > div > div ul > li > div > div > div {color:#a6a6a6 !important;}   
    .cardList.toneDown [class*="OBTCardList_li_CardList"] > div > div:nth-child(2) > div {color:#a6a6a6 !important;}    
    .cardList.toneDown [class*="OBTCardList_selectedCardList"]{border:1px solid #afcfed;box-shadow: none;}   
}   
   
.pubCardListStyle.system {background: #fff; border:1px solid #e6e6e6;position: relative;height:100%;box-sizing:border-box; border-top-color:#000;   
    .cardList {border:none;border-top:1px solid #000;}   
    .cardList > div {border:none; border-bottom:1px solid #e6e6e6;}   
    .cardList > [class*="OBTCardList_pagination"] {border:none;}   
    .cardList > div + div > div > div + div + div {display:none;}   
    .cardList > div > div + div > div > div > div ul > li {margin-bottom: 4px;border:1px solid #d7d7d7;background: #fff; border-radius:3px;box-sizing: border-box;}   
    .cardList > div > div + div > div > div > div ul > li .selectedBorder {border-radius: 3px;}   
}   
   
.pubCardListStyle.noTitle{   
    .cardList > div > div:first-child {height:0px !important;display: none;}   
}   
   
.cardTempBox{margin:0 16px;   
    // type   
    .typeUnit01{width:32px;height:18px;margin:2px 0 0 10px;font-size:11px;line-height:18px;color:#ffffff;border-radius:18px;box-sizing:border-box;text-align:center;   
       &.type1{background-color: #7f98fd;}   
       &.type2{background-color: #37c1d6;}   
    }   
    // text   
    .cardText01{margin:14px 0 5px 0;font-size:12px;line-height:20px;color:#4a4a4a;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}   
 }   
   
.stamp_ta table {width: 100%;table-layout: fixed;}   
.stamp_ta table th,.stamp_ta table td {border:1px solid #e6e6e6}   
.stamp_ta table th {height:33px;background: #f2f2f2;}   
.stamp_ta table td {height:126px;text-align: center;}   
.stamp_ta table td .pic {position: relative; width: 82px; height: 82px; border-radius: 4px; border: solid 1px #e6e6e6; background-color: #fff;margin:0 auto;box-sizing: border-box;}   
.stamp_ta table td .pic .pic_img {background: #fff; text-align: center;position:absolute;z-index: 2;width: 71px; left:5px; top:4px;height: 71px;}   
.stamp_ta table td .pic .pic_img> img {max-width: 71px; max-height: 71px;background: #fff;}   
.stamp_ta table td .pic:hover .btn_set {display:block;}   
.stamp_ta table td .btn_set {display:none;width: 100%;height: 100%;position:absolute;left:0;top:0;}   
.stamp_ta table td.form_td .pic {width: 220px;height: 82px;}   
.stamp_ta table td.form_td .pic .pic_img {width: 160px; height: 28px;left:50%;top:50%;margin-left: -80px;margin-top: -14px;}   
.stamp_ta table td.form_td .pic .pic_img> img {max-width: 160px; max-height: 28px;}   
.stamp_ta table td .btn_set .opaci {width: 100%;height:100%;background: #000;  position:absolute;left:0;top:0;background-color: rgba(0, 0, 0, 0.2);z-index:3;}   
.stamp_ta table td .txt {position:absolute;top:50%;margin-top: -9px;width: 82px;display:inline-block;left:50%;margin-left: -41px;z-index: 1;}   
.stamp_ta table td .btn_set .btns {position:absolute;top:30px;z-index: 4;width: 62px;left:50%;margin-left:-31px;}   
.stamp_ta table td .btn_set .btnImg {margin: 0 2px;}   
   
/*****************************   
   다운레이어    
******************************/   
.downLayer {box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.16);position: absolute;bottom:0px;width:100%; border:1px solid #a6a6a6;background: #fff;z-index:10;box-sizing: border-box;}   
.downLayer_in {position:relative; padding:20px;}   
.downLayer .semo {position:absolute; width:80px; left:50%; margin-left:-40px; top:-23px; cursor:pointer;   
    .semoL{float:left;width:8px; height:25px; background:url('../imgs/bg/downLayer_semoL.png') no-repeat left top; background-size:8px 24px;}   
    .text{float:left; width:64px; padding:3px 0 2px 0; background: #fff; box-shadow: 0 -10px 8px -7px rgba(0, 0, 0, 0.16);border-top:1px solid #a6a6a6;text-align: center;}   
    .semoR{float:left;width:8px; height:25px; background:url('../imgs/bg/downLayer_semoR.png') no-repeat right top; background-size:8px 24px;}   
}   
   
/*****************************   
   색상    
******************************/   
.colorPick {   
    ul {   
        li{float: left;margin-right: 6px; position:relative;height: 20px;   
            span{width:20px;height:20px;display:inline-block;border-radius:50%;cursor:pointer;}   
            span.plus_sp {width: 20px; height: 20px; border: 1px solid #7b7b7b; border-radius: 50%; background: url('../imgs/ico/icon_cal_add_normal.png') no-repeat center center; box-sizing: border-box;
                &:hover{
                    filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                }
            }   
            span.check {display:none;background:url('../imgs/ico/check@2x.png') no-repeat center center;position:absolute;left:0;top:0; background-size: 10px 7px;}   
            &.on span.check {display:inline-block;}   
        }      
    }   
}   

/* 테이블 > 다국어 레이어 팝업 */  
.depa_inp_div { background:#fff; width: 100%; padding:10px 10px 5px 10px; box-sizing: border-box; left:0; top:39px; z-index: 100;   
    border:1px solid #a6a6a6;position:absolute;   
    > p {margin-bottom: 10px;}   
    > div {margin-bottom: 5px;}   
    table {width: 100%; margin-bottom: 5px;   
        td,th {border:none !important; }   
        th {padding: 3px 0px !important;}   
        td { padding: 3px 8px !important;}   
    }   
}   
   
/*검색필터*/   
.sortSet{position: relative;   
    .sortSp { display: block; border: 0px; outline: none; font-size: 12px; font-family: inherit; color: #000; cursor: pointer; overflow: hidden; text-overflow: ellipsis; background:url("../imgs/ico/ic_arrow_down_02_s_normal@2x.png") no-repeat right center; background-size: 14px; padding-right: 15px;}   
    .sortSp:hover { background:url("../imgs/ico/ic_arrow_down_02_s_over@2x.png") no-repeat right center;background-size: 14px;}   
    .sortList {border: 1px solid rgb(204, 204, 204);min-width: 168px; position:absolute;right:0px; top:20px;color: rgba(0, 0, 0, 0.87); background-color: rgb(255, 255, 255); transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; box-sizing: border-box;-webkit-tap-highlight-color: rgba(0, 0, 0, 0);  opacity: 0; transform: scaleY(0);transform-origin: left top; z-index: 1000; max-height: 841px; overflow-y: auto;   
        ul{   
            li {padding: 2px 6px 0px; text-decoration: none; font-size: 12px; color: rgb(74, 74, 74); line-height: 20px; cursor: pointer;}   
            li.on {color: rgb(28, 144, 251);background: rgb(233, 245, 255);}   
            li:hover {background: rgb(233, 245, 255);}   
        }   
        &.check {   
            li {padding-left: 24px;}   
            li.on { color: rgb(28, 144, 251);background: rgb(233, 245, 255);background: url('../imgs/ico/ic_check_01_s_over@2x.png') no-repeat 7px 6px; background-size: 14px;}   
        }   
    }   
    &.on {   
        .sortSp {background:url("../imgs/ico/ic_arrow_up_02_s_normal@2x.png") no-repeat right center;background-size: 14px;}   
        .sortSp:hover {background:url("../imgs/ico/ic_arrow_up_02_s_over@2x.png") no-repeat right center;background-size: 14px;}   
        .sortList {opacity: 1; transform: scaleY(1);}   
    }   
   
    .sortList{   
        dl dt{font-size:12px;font-weight:bold;line-height:21px;border-bottom: 1px solid #f0f0f0;padding: 0 0 0 10px;}   
        dl dd{font-size:12px;line-height:21px;border-bottom: 1px solid #f0f0f0;}   
        dl dd ul li{font-size:12px;line-height:27px;color: #000000;padding: 0 0 0 26px;box-sizing: border-box;cursor: pointer;line-height: 27px;
            &:hover{background-color: #e6f5ff;
                span{
                    &.up{background: url('../imgs/ico/ic_ascending_order_s_over.png') no-repeat right;}
                    &.down{background: url('../imgs/ico/ic_descending_order_s_over.png') no-repeat right;}
                }
            }
        }   
        dl dd ul li.chk{font-size:12px;line-height:27px;color: #1c90fb;background: url('../imgs/ico/ic_check_01_s_over@2x.png') no-repeat 10px 5px; background-size: 14px;}   
        dl dd ul li.chk:hover{font-size:12px;line-height:27px;color: #1c90fb;background:#e6f5ff url('../imgs/ico/ic_check_01_s_over@2x.png') no-repeat 10px 5px; background-size: 14px;}   
        dl dd ul li span{display:block;margin:0 10px 0 0;line-height: 24px;}   
        dl dd ul li span.up{display:block;background: url('../imgs/ico/ic_ascending_order_s_normal.png') no-repeat right;}   
        dl dd ul li span.down{display:block;background: url('../imgs/ico/ic_descending_order_s_normal.png') no-repeat right;}   
    }   
   
    span.imc {display: inline-block !important;margin-right: 5px !important;width: 10px; height:10px;border-radius:50%;background: #e6e6e6;   
        &.basic {background:#1c90fb;}   
        &.user {background:#67ccb5;}   
        &.uc {background:#1c90fb;}   
        &.erp {background:#20c997;}   
        &.erp2 {background:#f48da5;}   
    }   
    &.disabled {cursor:not-allowed;   
        .sortSp {cursor:not-allowed; background:url("../imgs/ico/ic_arrow_down_02_s_disable.png") no-repeat right center;background-size: 14px;color:#8d8d8d;}   
        .sortSp:hover { background:url("../imgs/ico/ic_arrow_down_02_s_disable.png") no-repeat right center;background-size: 14px;}   
    }   
    &.keepSt {   
        .sortList {    
            ul {   
                li {padding:3px 33px 4px 51px;}   
                li.ico1 {background: url("../imgs/ico/ic_all_s_normal.png") no-repeat left 30px center;}   
                li.ico2 {background: url("../imgs/ico/ic_proval_s_normal.png") no-repeat left 30px center;}   
                li.ico3 {background: url("../imgs/ico/ic_mail_s_normal.png") no-repeat left 30px center;}   
                li.ico4 {background: url("../imgs/ico/ic_report_s_normal.png") no-repeat left 30px center;}   
                li.ico5 {background: url("../imgs/ico/ic_oneffice_s_normal.png") no-repeat left 30px center;}   
                li.ico6 {background: url("../imgs/ico/ic_calendar_s_normal.png") no-repeat left 30px center;}   
                li.ico7 {background: url("../imgs/ico/ic_msg_s_normal.png") no-repeat left 30px center;}   
                li.ico8 {background: url("../imgs/ico/ic_comment_s_normal.png") no-repeat left 30px center;}   
                li.ico9 {background: url("../imgs/ico/ic_board_s_normal.png") no-repeat left 30px center;}   
                li.ico10 {background: url("../imgs/ico/ic_attach_s_normal.png") no-repeat left 30px center;}   
   
                li.on.ico1 {background: url("../imgs/ico/ic_all_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico2 {background: url("../imgs/ico/ic_proval_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico3 {background: url("../imgs/ico/ic_mail_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico4 {background: url("../imgs/ico/ic_report_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico5 {background: url("../imgs/ico/ic_oneffice_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico6 {background: url("../imgs/ico/ic_calendar_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico7 {background: url("../imgs/ico/ic_msg_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico8 {background: url("../imgs/ico/ic_comment_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico9 {background: url("../imgs/ico/ic_board_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
                li.on.ico10 {background: url("../imgs/ico/ic_attach_s_over.png") no-repeat left 30px center,url('../imgs/ico/ic_check_01_s_over.png') no-repeat 7px 7px;;}   
            }   
        }   
    }   
}   
.btn_div {   
    .sortSet {margin-top:5px;}   
}   
   
/*노트*/   
.noteCardList{   
    [class*="OBTCardList_checkstyle"] {margin-left: 10px;}   
    .noteCard{position: relative;width: 100%;   
        .loca  {float: left; font-size:12px; color:#666666; margin:13px 0 0 5px; vertical-align:middle;overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 155px;}   
        .ico_folder { background:url('../imgs/tree/ic_folder_blue.png') no-repeat left center;padding-left:18px;}   
        .date {float:right;font-size:12px;color:#666666;margin:13px 17px 2px 0px;}   
        .tit { width: 210px; height: 25px; font-size: 16px; margin: 0px 0px 0px 5px; overflow: hidden; white-space: nowrap; word-break: break-all; text-overflow: ellipsis; padding: 15px 0px 0px;}   
        .icons {float: right; margin-right: 15px;margin-top:2px;   
            ul {   
                > li { border:none !important; float: left;background: none !important; margin-left: 4px;   
                    > div {width: 15px !important;height: 15px !important;border:none;padding:0;}   
                }   
            }   
            .file {background:url('../imgs/ico/ic_attach_s_normal.png') no-repeat center center;}   
            .book {background:url('../imgs/ico/ic_bookmark_01_m_normal.png') no-repeat center center;
                &:hover{background:url('../imgs/ico/ic_bookmark_01_m_over.png') no-repeat center center;}
                &.on{background:url('../imgs/ico/ic_favorite_on.png') no-repeat center center;
                    &:hover{pointer-events: none;}
                }
            }   
        }   
    }   
}   
.noteHead {border-bottom:1px solid #e6e6e6;box-sizing:border-box;padding-top:10px;   
    .left_div {float: left;width:calc(100% - 250px);   
        [class*="OBTTextField"] > div > div{border:none !important; padding-top:3px !important;   
            input[type="text"] {font-size:14px !important;}   
        }   
    }   
    .right_div {float: right;width:250px;text-align: right;   
        > div {text-align: left;}   
        .ico_folder { margin-left: 27px;float: left;background:url('../imgs/tree/ic_folder_blue.png') no-repeat center center; width: 20px;height: 27px;}   
    }   
    .note_tit {   
        .book {float: left;background:url('../imgs/ico/ic_bookmark_01_m_normal.png') no-repeat center center;width: 25px;height:27px;
            &:hover{background:url('../imgs/ico/ic_bookmark_01_m_over.png') no-repeat center center;}
            &.on{background:url('../imgs/ico/ic_favorite_on.png') no-repeat center center;
                &:hover{pointer-events: none;}
            }
        }
        .tit {font-size:16px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;width: calc(100% - 35px);}   
        overflow: hidden;   
    }   
    .note_info{margin-left: 24px; margin-top: 5px;float: left;  width: calc(100% - 85px);   
        li {float: left; background:url("../imgs/bg/bg_line_bar01.png") no-repeat left center;padding-right:11px;padding-left:10px;   
            dt {float: left;margin-right: 5px;color:#a6a6a6;}   
            dd {float: left;color:#a6a6a6;}   
            .ico_folder {background:url('../imgs/tree/ic_folder_blue.png') no-repeat left center; padding-left: 20px;color:#a6a6a6;}   
            .loca {display: block; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;  width: 100%;}   
        }   
        li:first-child {padding-left: 0;background: none;}   
        li:last-child {padding-right: 0;  width: calc(100% - 330px);}   
    }   
    .note_etc {float: right;margin-right: 10px;   
        li {float: left;margin-left: 5px;}   
    }   
}   
.noteTexta {padding:5px 0;box-sizing:border-box;   
    &.border{border:1px solid #e6e6e6;}   
    textarea {border:none !important;font-size:13px !important;line-height: 21px !important; color: rgb(26, 26, 26);vertical-align: top;box-sizing: border-box;resize: none;}   
}   
   
.noteTextaView {padding: 5px 6px; font-size: 13px; line-height: 21px;white-space: pre-wrap;}   
.note_btn_edit { background: url("../imgs/ico/ic_edit_s_normal.png") no-repeat center center !important;border:none !important;
    &:hover{background: url("../imgs/ico/ic_edit_s_over.png") no-repeat center center !important;}
}   
.note_btn_del { background: url("../imgs/ico/ic_trashcan_s_normal.png") no-repeat center center !important;border:none !important;
    &:hover{background: url("../imgs/ico/ic_trashcan_s_over.png") no-repeat center center !important;}
}   
   
/* 페이지 컨테이너 특화 타이틀 영역을 위한 커스텀 */   
// [class*="OBTPageContainer_mainButtons"]{position: relative; z-index: 2;}
[class*="OBTPageContainer_root"] > [class*="OBTPageContainer_section"] {overflow:inherit !important;}   

.posi_top_box {background: #fff;position: absolute;   
    .pubAvbStyle {   
        > div > [class*="OBTConditionPanel_"] {border:none; padding:6px 4px 3px 4px;}   
        .left_sear {position: absolute; top: 6px; left: 0px;}   
        .right_sear {position: absolute; top: 6px; right: 37px;}   
        .right_sear input[type="text"] {padding-right:27px !important; width:calc(100% - 27px) !important;}   
        .singleSearch {position: absolute;right:-35px;}   
        [class*="OBTConditionPanel_optionalWrapper"] {padding:6px 4px 4px 0px !important;}   
        [class*="OBTConditionPanel_optional"] {justify-content: flex-end;}   
        [class*="OBTConditionPanel_optional_"] {padding:4px 36px 4px 0px !important;}   
        [class*="OBTConditionItem_tooltipWrapper"] {justify-content: flex-end;}   
        [class*="OBTConditionItem_tooltipWrapper"] div {margin-left:0px !important;}   
        [class*="OBTConditionPanel_primaryFunctions"] [class*="OBTConditionPanel_searchButton"] {border:none !important;background: none !important;position: relative; z-index: 2;}   
        [class*="OBTTooltip_tooltipFloatingPanel"] {display: none !important;}   
        .bar {width:1px; height:16px; background:url("../imgs/bg/ico_page_bar.png") no-repeat center center;position:absolute;right:-3px;top:15px;}   
        .dateBtn [class*="OBTButtonGroup_focus"] {color:#007ff4 !important;  background-image: linear-gradient(to top, #d0ebff, #edf6ff) !important;}           
    }   
   
    &.rightBox {   
        .pubAvbStyle { position: relative;   
            [class*="OBTConditionPanel_primaryFunctions"] [class*="OBTConditionPanel_searchButton"] {position: absolute;right:9px;top:10px;}   
            [class*="OBTConditionPanel_root"] > [class*="OBTConditionPanel_primaryRoot"] > [class*="OBTConditionPanel_primaryFunctions"] {margin:0px;}   
            [class*="OBTConditionPanel_root"]  > [class*="OBTConditionPanel_primaryRoot"] > [class*="OBTConditionPanel_primary"] {justify-content: flex-end;}   
            .sear input[type="text"] {padding-right:27px !important; width:calc(100% - 27px) !important;}   
        }   
    }   
}   
.wid440 {width:440px !important;}   
   
/*****************************   
   원피스/ 원챔버 / 전재결재특화 LNB / 게시판 / 확장기능 / 연락처   
******************************/   
.onfficeSide, .eapSide ,.boardSide, .extSide, .addrSide {background: #fff;   
    .sideRegi{
        .addrSideBtn{width: 100%; height: 34px; border-radius: 2px; background-color: #1c90fb; cursor: pointer;
            span{position: relative; line-height: 33px; font-size: 13px; letter-spacing: -0.65px; color: #ffffff;
                &::after{content: ''; position: absolute; top: 4px; right: -18px; width: 14px; height: 14px; background: url('../imgs/ico/ic_arrow_down_01_s_w_normal@2x.png') no-repeat center center; background-size: 14px;}
            }
            &:hover{background-color: #036dd2;
                span::after{content: ''; position: absolute; top: 4px; right: -18px; width: 14px; height: 14px; background: url('../imgs/ico/ic_arrow_down_01_s_w_over@2x.png') no-repeat center center; background-size: 14px;}
            }
            &.on{background-color: #1c90fb;
                &::after{content: ''; position: absolute; top: 4px; right: -18px; width: 14px; height: 14px; background: url('../imgs/ico/ic_arrow_up_01_s_w_normal@2x.png') no-repeat center center; background-size: 14px;}
                &:hover{background-color: #036dd2;
                    span::after{content: ''; position: absolute; top: 4px; right: -18px; width: 14px; height: 14px; background: url('../imgs/ico/ic_arrow_up_01_s_w_over@2x.png') no-repeat center center; background-size: 14px;}    
                }
            }
        }
    }
    .ofMenuLnb {position: relative; border-top:1px solid #e6e6e6;   
        .addrSideList{z-index: 99; position: absolute; top: -13px; left: 12px; right: 12px; height: 56px; border: 1px solid #a6a6a6; box-shadow: 0 1px 1px 0 rgba(6, 6, 6, 0.1); background-color: #ffffff; box-sizing: border-box;
            .sideIco{line-height: 27px; font-size: 12px; padding-left: 32px; cursor: pointer;
                &.write{background: url('../imgs/ico/ic_docu_write_per_m_normal@2x.png') no-repeat left 11px center; background-size: 18px;
                    &:hover{background-color: #eff7ff;}
                }
                &.busiCard{background: url('../imgs/ico/ic_businesscard_m_normal@2x.png') no-repeat left 11px center; background-size: 18px;
                    &:hover{background-color: #eff7ff;}
                }
            }
        }
        ul.arrUp { height: 0; transform: rotateX(-90deg);}   
        ul { height: auto; transform: rotateX(0deg); transition: 0.5s;   
            li { position: relative;              
                .tit_div {height:36px; line-height:36px;overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis;padding-right: 10px;display:flex;}   
                .tit {padding-left: 22px;display:block;overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis; cursor:pointer; font-size:13px;background-size: 18px;}   
                .num {font-size: 11px; font-weight: bold;  color: #1c90fb; margin-left: 4px;color:#a6a6a6;}   
                .more{position: absolute; top: 5px; right: 10px; width: 18px; height: 18px; background: url('../imgs/ico/ic_more_detail_m_normal.png') no-repeat center center; cursor: pointer;
                    &:hover{background: url('../imgs/ico/ic_more_detail_m_over.png') no-repeat center center;}
                }
                .dashboard .tit {background-image: url("../imgs/ico/ic_dashboard_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .storage .tit {background-image: url("../imgs/ico/ic_storage_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .buiding .tit {background-image: url("../imgs/ico/ic_folder_building_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .recent .tit {background-image: url("../imgs/ico/ic_clock_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .share .tit {background-image: url("../imgs/ico/ic_folder_user_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .impo .tit {background-image: url("../imgs/ico/ic_star_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .sear .tit {background-image: url("../imgs/ico/ic_search_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .auth .tit {background-image: url("../imgs/ico/ic_user_add_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .graph .tit {background-image: url("../imgs/ico/ic_graph_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .orgSet .tit {background-image: url("../imgs/ico/ic_org_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .doc .tit {background-image: url("../imgs/ico/ic_doc_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .cate .tit {background-image: url("../imgs/ico/ic_paper_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .graphSet .tit {background-image: url("../imgs/ico/ic_graph_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .secuSet .tit {background-image: url("../imgs/ico/ic_lock_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .del .tit {background-image: url("../imgs/ico/ic_delete_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .oneflip .tit {background-image: url("../imgs/ico/ic_oneflip_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .oneHome .tit {background-image: url("../imgs/ico/ic_oneppice_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .my .tit {background-image: url("../imgs/ico/ic_folder_my_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .secuDoc .tit {background-image: url("../imgs/ico/ic_lock_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .setDoc .tit {background-image: url("../imgs/ico/ic_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .setting .tit {background-image: url("../imgs/ico/ic_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
   
                .boxSubmit .tit {background-image: url("../imgs/ico/ic_box_submit_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .boxReceive .tit {background-image: url("../imgs/ico/ic_box_receive_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .boxPredocu .tit {background-image: url("../imgs/ico/ic_box_predocu_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .boxCategory .tit {background-image: url("../imgs/ico/ic_box_category_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .signSetting .tit {background-image: url("../imgs/ico/ic_sign_setting_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .transfer .tit {background-image: url("../imgs/ico/ic_transfer_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .boxDocu .tit {background-image: url("../imgs/ico/ic_docu_box_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .distributionDocu .tit {background-image: url("../imgs/ico/ic_docu_distribution_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
   
                .notice .tit {background-image: url("../imgs/ico/ic_notice_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .write .tit {background-image: url("../imgs/ico/ic_write_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .board .tit {background-image: url("../imgs/ico/ic_board_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .survey .tit {background-image: url("../imgs/ico/ic_survey_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .activity .tit {background-image: url("../imgs/ico/ic_activity_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
                .visitor .tit {background-image: url("../imgs/ico/ic_visitor_normal.png");background-repeat:  no-repeat; background-position: left  center;}   
   
                .allAddr .tit {background-image: url("../imgs/ico/ic_address_normal@2x.png");background-repeat:  no-repeat; background-position: left  center;}   
                .favoriteAddr .tit {background-image: url("../imgs/ico/ic_address_favo_normal@2x.png");background-repeat:  no-repeat; background-position: left  center;}   
                .groupAddr .tit {background-image: url("../imgs/ico/ic_address_group_normal@2x.png");background-repeat:  no-repeat; background-position: left  center;}                   
                .groupAddr{position: relative;
                    .addBtnSetting{position: absolute; top: 50%; right: 12px; transform: translateY(-50%); width: 14px; height: 14px; background: url('../imgs/ico/ic_settine_02_s_normal.png') no-repeat center center; cursor: pointer;
                        &:hover{background: url('../imgs/ico/ic_settine_02_s_over.png') no-repeat center center;}
                        & > [class*="OBTFloatingPanel_root"] {position:absolute;left:-5px !important;top:-7px !important;visibility:visible !important;transform: translateX(-100%);}
                    }
                }
                
                .tit_div.on, .tit_div:hover {   
                    .tit {color:#1c90fb;}   
                    .num {color:#1c90fb;}   
                    &.dashboard .tit {background-image: url("../imgs/ico/ic_dashboard_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.storage .tit {background-image: url("../imgs/ico/ic_storage_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.buiding .tit {background-image: url("../imgs/ico/ic_folder_building_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.recent .tit {background-image: url("../imgs/ico/ic_clock_select.png");background-repeat: no-repeat; background-position: left  center;}   
                    &.share .tit {background-image: url("../imgs/ico/ic_folder_user_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.impo .tit {background-image: url("../imgs/ico/ic_star_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.sear .tit {background-image: url("../imgs/ico/ic_search_select.png");background-repeat: no-repeat; background-position: left  center;}   
                    &.auth .tit {background-image: url("../imgs/ico/ic_user_add_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.graph .tit {background-image: url("../imgs/ico/ic_graph_select.png");background-repeat: no-repeat; background-position: left  center;}   
                    &.orgSet .tit {background-image: url("../imgs/ico/ic_org_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.doc .tit {background-image: url("../imgs/ico/ic_doc_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.cate .tit {background-image: url("../imgs/ico/ic_paper_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.graphSet .tit {background-image: url("../imgs/ico/ic_graph_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.secuSet .tit {background-image: url("../imgs/ico/ic_lock_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.del .tit {background-image: url("../imgs/ico/ic_delete_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.oneflip .tit {background-image: url("../imgs/ico/ic_oneflip_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.oneHome .tit {background-image: url("../imgs/ico/ic_oneppice_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.my .tit {background-image: url("../imgs/ico/ic_folder_my_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.secuDoc .tit {background-image: url("../imgs/ico/ic_lock_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.setting .tit {background-image: url("../imgs/ico/ic_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}  
   
                    &.boxSubmit .tit {background-image: url("../imgs/ico/ic_box_submit_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.boxReceive .tit {background-image: url("../imgs/ico/ic_box_receive_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.boxPredocu .tit {background-image: url("../imgs/ico/ic_box_predocu_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.boxCategory .tit {background-image: url("../imgs/ico/ic_box_category_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.signSetting .tit {background-image: url("../imgs/ico/ic_sign_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.transfer .tit {background-image: url("../imgs/ico/ic_transfer_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.boxDocu .tit {background-image: url("../imgs/ico/ic_docu_box_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.distributionDocu .tit {background-image: url("../imgs/ico/ic_docu_distribution_select.png");background-repeat:no-repeat; background-position: left  center;}   
                    &.notice .tit {background-image: url("../imgs/ico/ic_notice_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.write .tit {background-image: url("../imgs/ico/ic_write_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.board .tit {background-image: url("../imgs/ico/ic_board_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.survey .tit {background-image: url("../imgs/ico/ic_survey_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.activity .tit {background-image: url("../imgs/ico/ic_activity_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.visitor .tit {background-image: url("../imgs/ico/ic_visitor_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.allAddr .tit {background-image: url("../imgs/ico/ic_address_select@2x.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.favoriteAddr .tit {background-image: url("../imgs/ico/ic_address_favo_select@2x.png");background-repeat:  no-repeat; background-position: left  center;}   
                    &.groupAddr .tit {background-image: url("../imgs/ico/ic_address_group_select@2x.png");background-repeat:  no-repeat; background-position: left  center;}   
                }    
                // 예외처리   
                .tit_div.on{   
                    &.setDoc .tit {color:#000000;}   
                }   
                .tit_div:hover{   
                    &.setDoc .tit {color:#1c90fb;background-image: url("../imgs/ico/ic_setting_select.png");background-repeat:  no-repeat; background-position: left  center;}   
                }               
            }   
            .tit_div:hover {background-color: #eff7ff; }   
            .arr {position: absolute;height: 36px;width: 11px;top:0;left:6px; cursor: pointer;   
                &.down {background: url("../imgs/ico/ic_arrow_down_01_s_normal.png") no-repeat center center; background-size: 11px;}   
                &.up {background: url("../imgs/ico/ic_arrow_right_01_s_normal.png") no-repeat center center; background-size: 11px;}   
            }   
            .arr:hover {   
                &.down {background: url("../imgs/ico/ic_arrow_down_01_s_over.png") no-repeat center center; background-size: 11px;}   
                &.up {background: url("../imgs/ico/ic_arrow_right_01_s_over.png") no-repeat center center; background-size: 11px;}   
            }           
        }   
   
        ul.dep1 { margin-top: 4px;   
            > li {   
                > .tit_div {padding-left: 20px;   
                    .tit {font-weight: bold;}   
                }   
                > ul.dep2 {padding-left:28px;   
                    li{padding-left:13px;background: url('../imgs/bg/bg_dat.png') repeat-y 1px 0px;clear: both;position:relative;   
                        &:last-child{background: none;}   
                        .tit_div {height:28px; line-height:28px;
                            &:hover {background-color: #fff; }   
                            &.dep_open{
                                + ul{display: block;} 
                                .arr{display: inline-block;background:url('../imgs/ico/btn_tree_open.png') no-repeat 0px 10px}   
                                .tit{background: none;}
                            }
                            &.dep_close{
                                + ul{display: none;}
                                .arr{display: inline-block;background:url('../imgs/ico/btn_tree_close.png') no-repeat 0px 10px}   
                                .tit{background: none;}
                            }
                        }   
                        .nav-icon{position:absolute;left:-2px;top:0px;width: 14px;height: 15px;background:#fff url('../imgs/bg/bg_datL2.png') no-repeat 3px 0px;}   
                        .arr{width:14px;height:20px;float: left;display:none;top: 0px;left: -3px;}   
                        .tit{padding-left: 2px;}   
                    }   
                    > li:first-child > .dep_open::after{content: ''; position: absolute; top: 0; left: 0; width: 10px; height: 10px; background: #fff;}
                    > li:first-child > .dep_close::after{content: ''; position: absolute; top: 0; left: 0; width: 10px; height: 10px; background: #fff;}
                }   
            }   
        }   
   
        ul.dep2 {transition:all 0.3s ease-in-out;overflow: hidden;   
            &.arrUp {height:0px !important;}   
        }           
    }   
}   
   
/*상단 좌우 붙는 박스*/   
.top_less_box {height: 54px !important;box-sizing: border-box;border-bottom:1px solid #e5e5e5; background:#fff;width: calc(100% + 40px) !important;position: absolute;left:-20px;z-index: 2;padding: 0 20px;   
    .left_div {float: left;overflow: hidden;margin-left:20px;margin-top: 13px;   
    }   
    .right_div {float: right;padding-top: 13px;padding-right: 20px;}   
    &.top_ex_box {width:100% !important;position:inherit;left:0;}   
    &.plus20 {width: calc(100% + 20px) !important;}   
}   
.top_less_box_bg {height:34px;margin-top: 0 !important;}   
.top_btn_blind:after {content:'';height: 50px !important;box-sizing: border-box; background:#fff;position: absolute;top:-74px;right:30px;z-index: 2;width:60px}   
   
.top_less_box_zero {height: 0px !important;box-sizing: border-box;background:#fff;width: calc(100% + 40px) !important;position: absolute;left:-20px;z-index: 2;padding: 0 20px; z-index: 400;   
    .left_div {float: left;overflow: hidden;margin-left:20px;margin-top: 13px;   
    }   
    .right_div {float: right;padding-top: 13px;padding-right: 20px;}   
    &.top_ex_box {width:100% !important;position:inherit;left:0;}   
}   
   
.dock_top_less {height:calc(100% + 20px) !important;margin-top:-20px;}   
.dock_left_right_less {width:calc(100% + 40px) !important;margin-left:-20px;}   
.noBgBtn {border:none !important;background: none !important;}   
   
/*****************************   
   다운로드 센터   
******************************/   
.downloadCenterHeader{width:100%;height: 80px;margin-top:10px;border-radius: 4px;overflow: hidden;box-sizing:border-box;padding:0 1px;   
    .dcHeaderText{position: absolute;top:38px;left:70px;   
        .tit{font-size: 22px;font-weight: bold;line-height: 26px;color: #0c2b65;margin: 0 20px 0 0;
            .blue{color:#1c90fb;}   
        }   
        .dis{font-size: 12px;line-height: 18px;color: #5c7198;}
    }   
    .dcHeaderBg{background: url('../imgs/bg/img_downloadcenter.png') no-repeat center center;background-size: 100% 100%;}   
}   
   
.downloadCenterContents{position: relative;margin:20px 0 0 0; box-sizing: border-box;   
    .downloadBox{/* width:953px; */box-sizing: border-box;   
        .unit{position: relative;min-width: 280px;height: 310px;margin:0 0 0 6px;border-radius: 10px;border:1px solid #e6e6e6;background-color: #ffffff;box-sizing: border-box;   
            &:first-child{margin:0;}   
            &.bg1{background: url('../imgs/ico/img_download_agent.png') no-repeat top 20px center;}   
            &.bg2{background: url('../imgs/ico/img_download_msg.png') no-repeat top 20px center;}   
            &.bg3{background: url('../imgs/ico/img_download_onechamber.png') no-repeat top 20px center;}   
            &.bg4{background: url('../imgs/ico/img_download_mobile.png') no-repeat top 20px center;}   
            .txt{margin:170px 20px 0 20px;padding:0 0 20px 0;font-size:16px;font-weight:bold;line-height:24px;color:#4a4a4a;text-align: center;border-bottom:1px solid #e6e6e6;}   
            .installation{margin:15px 0 5px 0;font-size:13px;line-height:18px;color:#a6a6a6;text-align:center;   
                &.blue{color:#1c90fb;}   
            }   
            .btn{text-align: center;}   
            .winTxt{display:inline-block;margin: 15px 20px 5px 0;padding: 0 0 0 22px;font-size:13px;line-height:18px;color: #000000;text-align: left;background: url('../imgs/uc/ic_download_windows.png') no-repeat left 0 center;   
                & + .btn{margin:0 20px 0 0;}   
            }   
            .macTxt{display:inline-block;margin: 15px 0 5px 20px;padding: 0 0 0 22px;font-size:13px;line-height:18px;color: #000000;text-align: left;background: url('../imgs/uc/ic_download_mac.png') no-repeat left 0 center;   
                & + .btn{margin:0 0 0 20px;}   
            }   
            .line{width:1px;height:20px;margin:42px 0 0 0;background-color: #e6e6e6;}   
            .googleBtn{margin:25px 2px 0 0;padding:0 6px;height:40px;}   
            .appstoreBtn{margin:25px 0 0 2px;padding:0 6px;height:40px;}   
   
            // QR code   
            .QrBox{position: absolute;top: 35px;left: 50%;width: 150px;height: 150px;margin: 0 0 0 -95px;padding: 20px;border: 1px solid #e6e6e6;border-radius: 10px;background: #ffffff;-webkit-animation-fill-mode: none;animation-fill-mode: none;z-index: 1;   
                &::after{content:'';position: absolute;width: 10px;height: 10px;border: 1px solid #e6e6e6;background: #fff;z-index: -1;}   
                &.left::after{left:30px;bottom: -6px;width: 10px;height: 10px;border-width: 0 1px 1px 0;transform: rotate(45deg);}   
                &.right::after{right:30px;bottom: -6px;width: 10px;height: 10px;border-width: 0 1px 1px 0;transform: rotate(45deg);}   
                img{width:100%;}   
                .clearDim{position: fixed;top: 0;left:0;right: 0;bottom: 0;width: auto;height: auto;border-radius: 0;box-sizing: border-box;background-color:transparent;z-index: -1;}   
            }   
        }   
    }   
    .serviceBox{box-sizing: border-box;   
        .grayTxt{color:#8c8c8c;}   
        .winTxt{padding:0 0 0 22px;text-align: left;background: url('../imgs/uc/ic_download_windows.png') no-repeat left 0 center;}   
        .macTxt{padding:0 0 0 22px;text-align: left;background: url('../imgs/uc/ic_download_mac.png') no-repeat left 0 center;}   
        .chromeTxt{padding:0 0 0 22px;text-align: left;background: url('../imgs/uc/img_download_chrome.png') no-repeat left 0 center;}   
        .safariTxt{padding:0 0 0 22px;text-align: left;background: url('../imgs/uc/img_download_safari.png') no-repeat left 0 center;}   
        .ieTxt{padding:0 0 0 22px;text-align: left;background: url('../imgs/uc/img_download_ie.png') no-repeat left 0 center;}   
    }   
    .systemBox{box-sizing: border-box;   
        .referenceTitle{font-size:12px;font-weight:bold;color:#000000;}   
        .referenceDis{font-size:12px;color:#4a4a4a;}   
    }
    
    .serviceTab{padding:0 0 20px 0;
        .tabBtn{position: relative;width: 280px;height: 44px;border: 1px solid #cccccc;border-radius: 100px;font-size: 16px;line-height: 44px;color: #a6a6a6;text-align: center;box-sizing: border-box;
            .web{width: 140px;height: 44px;line-height: 44px;margin:-1px 0 0 -1px;padding: 0 0 0 24px;border-radius: 100px;background: url('../imgs/uc/ic_download_web_off@2x.png') no-repeat left 49px center;background-size: 24px;box-sizing: border-box;cursor:pointer;
                &.on{color:#ffffff;background: #1c90fb url('../imgs/uc/ic_download_web_on@2x.png') no-repeat left 49px center;background-size: 24px;}
            }
            .mobile{width: 140px;height: 44px;line-height: 44px;margin:-1px 0 0 -1px;padding: 0 0 0 24px;border-radius: 100px;background: url('../imgs/uc/ic_download_mobile_off@2x.png') no-repeat left 34px center;background-size: 24px;box-sizing: border-box;cursor:pointer;
                &.on{color:#ffffff;background: #1c90fb url('../imgs/uc/ic_download_mobile_on@2x.png') no-repeat left 34px center;background-size: 24px;}
            }
        }
    }
    .webConWrap{
        .serviceBox{box-sizing: border-box;   
            .icoWin{margin:0 10px;width:20px;height:20px;background: url('../imgs/uc/ic_download_windows.png') no-repeat left 0 center;}   
            .icoMac{margin:0 10px;width:20px;height:20px;background: url('../imgs/uc/ic_download_mac.png') no-repeat left 0 center;}   
        }   
    }
    .mobileConWrap{
        .serviceBox{flex-wrap:wrap;gap:14px;   
            .unitBox{position:relative;min-width:calc(50% - 7px);max-width:calc(50% - 7px);height:120px;padding:10px 30px;border:1px solid #e6e6e6;border-radius:10px;background-color:#ffffff;box-sizing: border-box;
                .ico{width:100px;height:100px;margin:0 20px 0 0;
                    &.Amaranth{background:url('../imgs/uc/amaranth_10_72_amaranth@2x.png') no-repeat center;background-size:100px;}
                    &.Mullen{background:url('../imgs/uc/amaranth_10_mullen_72@2x.png') no-repeat center;background-size:100px;}
                    &.ONEFFICE{background:url('../imgs/uc/amaranth_10_oneffice_72@2x.png') no-repeat center;background-size:100px;}
                    &.ONECHAMBER{background:url('../imgs/uc/amaranth_10_onechamber_72@2x.png') no-repeat center;background-size:100px;}
                    &.Meet{background:url('../imgs/uc/amaranth_10_meet_72@2x.png') no-repeat center;background-size:100px;}
                    &.vc{background:url('../imgs/uc/amaranth-10-vc-72@2x.png') no-repeat center;background-size:100px;}
                }
                .top{
                    .tit{font-size:16px;font-weight:bold;height:24px;line-height:24px;letter-spacing:-0.8px;color:#000000;}
                    .badge{width:fit-content;margin:2px 0 2px 10px;padding:0 6px;font-size:11px;height:18px;line-height:18px;letter-spacing:-1.1px;color:#494949;border:1px solid #494949;border-radius:18px;}
                }
                .sub{margin:10px 0 0 0;font-size:12px;height:auto;line-height:18px;color:#4a4a4a;}
                .hoverBtn{display:none;position:absolute;top:0;left:0;right:0;bottom:0;gap:14px;background-color:rgba(34,70,119,0.4);border-radius:10px;
                    .btn{width:160px;height:50px;border-radius:8px;background-color:#f9fafc;cursor:pointer;
                        &.googlePlay{background:#f9fafc url('../imgs/uc/img_download_googleplay@2x.png') no-repeat center;background-size:140px 34px;}
                        &.appStore{background:#f9fafc url('../imgs/uc/img_download_appstore@2x.png') no-repeat center;background-size:140px 34px;}
                        &:hover{position:relative;
                            & > .QrBox{display: block;}
                        }
                        // QR code   
                        .QrBox{display: none;position: absolute;top: -205px;left: 50%;width: 150px;height: 150px;margin: 0 0 0 -96px;padding: 20px;border: 1px solid #e6e6e6;border-radius: 10px;background: #ffffff;animation-fill-mode: none;z-index: 1;
                            &::after{content: "";position: absolute;width: 10px;height: 10px;left: calc(50% - 5px);bottom: -6px;width: 10px;height: 10px;border-width: 0 1px 1px 0;border-color: transparent transparent #e6e6e6 #e6e6e6;border-style: solid;transform: rotate(45deg);background: #fff;}   
                            img{width:100%;}   
                        }
                    }
                }
                &:hover{
                    .hoverBtn{display:flex;}
                }
                &:active{
                    .hoverBtn{display:flex;}
                }
                
            }
        }   
    }
}   
   
/*방문객현황*/   
.visitorHead {position: relative;   
    > h2 {float: left;    font-size: 18px; padding-right: 14px; font-weight: bold; letter-spacing: -0.8px; text-align: center; color: #000000; line-height: 27px;}   
    .btn_set {float: left;   
    > div {vertical-align: middle;}   
    }   
    .cal_posi {position: absolute;top:29px;z-index:1;}   
}   
   
/*퇴사처리, 스텝 공통*/   
.xHidden [class*="OBTDockPanel_wrapper"] {overflow-x:hidden !important;}   
.yHidden [class*="OBTDockPanel_wrapper"] {overflow-y:hidden !important;}   
.bs_set_step {background: #fff;height: 100%;left:0;top:0px;right:0;position:absolute;display:none;box-sizing: border-box;   
    &.active { display: block;   
        .layer_div { left: 0px; width: 100%; display: block; }   
    }   
}   
.bs_set_step .Pop_border .tit_p {font-weight: normal;}   
.fwn_div.Pop_border .tit_p {font-weight: normal;}    
.bs_tab {border:1px solid #dcdcdc;overflow: hidden;}   
.bs_tab.tab1 ul li {width: 100% !important;}   
.bs_tab.tab2 ul li {width: 50% !important;}   
.bs_tab.tab3 ul li {width: 33.33% !important;}   
.bs_tab.tab4 ul li {width: 25% !important;}   
.bs_tab.tab5 ul li {width: 20% !important;}   
.bs_tab.tab6 ul li {width: 16.6% !important;}   
.bs_tab.tab7 ul li {width: 14.28% !important;}   
.bs_tab ul li {float: left;text-align: center;width: 33%;position:relative;}   
.bs_tab ul li:last-child {width:34%;}   
.bs_tab ul li .tit_step {display:inline-block;height:38px;line-height: 38px;font-size:14px;width: 100%;font-weight: bold;cursor:default;text-indent: 14px;}   
.bs_tab ul li.on .tit_step {background: #50A2E0;color:#fff;}   
.bs_tab ul li .arr_semo {display:block; position:absolute;right:-22px;top:-1px;width: 0px;height: 0px;border:20px solid transparent;border-left:20px solid #dcdcdc;border-right: none;}   
.bs_tab ul li .arr_semo:after {content:"";position:absolute;right: 2px; top: -20px;width: 0px;height: 0px;border:20px solid transparent;border-left:20px solid #fff;border-right: none;}   
.bs_tab ul li.on .arr_semo {border:20px solid transparent;border-left:20px solid #dcdcdc;border-right: none;}   
.bs_tab ul li.on .arr_semo:after {content:"";position:absolute;right: 2px; top: -20px;width: 0px;height: 0px;border:20px solid transparent;border-left:20px solid #50A2E0;border-right: none;}   
.bs_tab ul li.on:before {content:"";position:absolute;left: 0px; top: -1px;width: 0px;height: 0px;border:20px solid transparent;border-left:20px solid #fff;border-right: none;}   
.bs_tab ul li:first-child.on:before {display: none;}   
   
.bs_tab ul li:last-child.on .arr_semo {right:none;left:0px;top:-1px;width: 0px;height: 0px;border:20px solid transparent;border-left:20px solid #dcdcdc;border-right: none;}   
.bs_tab ul li:last-child.on .arr_semo:after {right:none;left:-22px; top: -20px;width: 0px;height: 0px;border:20px solid transparent;border-left:20px solid #fff;border-right: none;}   
.bs_tab ul li:first-child {z-index:2;}   
   
.chkBoxList{   
    .title{color:#000; font-size:13px;margin-top: 10px; padding-left:45px;margin-bottom: 5px;background:url('../imgs/ico/ic_check_03_m_normal.png') no-repeat left 18px center;cursor: pointer;   
        &.checked {background:url('../imgs/ico/ic_check_03_m_over.png') no-repeat left 18px center;}   
    }   
    ul{padding:5px 0;}   
    ul li{font-size:13px; cursor:pointer; display:block; width: 100%; height: 54px; border-radius: 3px; border: solid 1px #e6e6e6; box-sizing: border-box; margin:0 16px 15px 0; background:url('../imgs/ico/ic_check_03_m_normal.png') no-repeat left 18px center; display: flex; align-items: center;}   
    ul li.checked{border: solid 1px #1c90fb; background:#e9f5ff url('../imgs/ico/ic_check_03_m_over.png') no-repeat left 18px center;}   
    ul li .textDiv {width:100%; height:100%; align-items: center; display: flex; padding:0 0 0 45px; line-height:18px;}       
    ul li .tit {overflow: hidden;text-overflow: ellipsis;margin-right:20px;max-width:calc(100% - 30px);position: relative;line-height: 27px;}   
    ul li .ico {width:27px; height:22px; display: inline-block;margin-right: 3px;       
        &.group {background:url('../imgs/uc/ico_group_human.png') no-repeat center center;background-size:18px 18px;}   
        &.company {background:url('../imgs/tree/ic_comp_01.png') no-repeat center center;background-size:16px 14px;}   
    }   
    ul li .subTxt {width:100%; height:100%; align-items: center; display: flex; flex:400px; padding:0 0 0 45px; line-height:18px;color:#666;position:relative;padding-left:30px;margin-left:20px;overflow: hidden;text-overflow: ellipsis;white-space:nowrap;}   
    ul li .subTxt .stSp {line-height: 27px;}   
    ul li .subTxt:after {content:'|';font-size:12px; color:#666;position:absolute;left:0px;}       
    ul li .subTxt [class*="OBTTooltip_tooltipFloatingPanel"] {max-width: 350px;}   
    ul li .subTxt [class*="OBTTooltip_tooltipFloatingPanel"] [class*="OBTTooltip_contents"] {line-height: 16px;white-space:normal;}   
    ul li [class*="OBTTooltip_root"] {overflow: hidden;text-overflow: ellipsis;max-width:calc(100% - 20px)}   
}   
   
//툴팁   
.tooltip[tooltip]:hover:after { content: attr(tooltip); position: fixed; top:var(--tooltip-top); left:var(--tooltip-left);z-index: 1;;   
    border-radius: 3px; border: solid 1px #a6a6a6; background-color: #ffffff; font-size: 12px; line-height: 12px; padding: 7px 10px 7px 12px; color:#000; box-sizing: border-box; text-align: left;line-height:16px;   
  }   
.tooltip:hover::before{content:''; position: fixed;top:var(--tooltip-arr-top); left:var(--tooltip-arr-left);width:11px; height:7px;background:url("../imgs/bg/bg_tooltip_arrow.png") left top 1px no-repeat; z-index: 2;}   
   
.tooltip.top[tooltip]:hover:after {top:initial !important;bottom:var(--tooltip-bottom);}   
.tooltip.top:hover::before{content:'';-ms-transform: rotateX(180deg);-webkit-transform: rotateX(180deg);transform: rotateX(180deg);top:inherit !important;bottom:var(--tooltip-arr-bottom); }   
.tooltip[tooltip].max:hover:after {white-space: normal;max-width:var(--tooltip-max-width);}   
   
/*업무보고*/   
.report_box {   
    .type01Unit01{min-width:190px;height:70px;margin:0 0 0 0;padding:11px 0 0 75px;border:1px solid #cccccc;border-radius:10px;background:#ffffff url('../imgs/ico/tab_all_none.png') no-repeat top 10px left 15px;box-sizing:border-box;cursor: pointer;   
        &:hover{border:1px solid #1c90fb;}   
        &.on{border:1px solid #1c90fb;background:#f5faff url('../imgs/ico/tab_all_sele.png') no-repeat top 10px left 15px;}   
        .txt01{height:22px;font-size:13px;line-height:22px;color:#4a4a4a;}   
        .txt02{height:24px;font-size:13px;line-height:24px;color:#4a4a4a;   
            b{padding:0 2px 0 3px;font-size:18px;font-weight:bold;line-height:22px;color:#000000;letter-spacing: -0.9px;margin:0 2px 0 0;text-decoration: underline;}   
        }   
   }   
   .type01Unit02{min-width:190px;height:70px;margin:0 0 0 8px;padding:11px 0 0 75px;border:1px solid #cccccc;border-radius:10px;background:#ffffff url('../imgs/ico/tab_report_receive_none.png') no-repeat top 10px left 15px;box-sizing:border-box;cursor: pointer;   
        &:hover{border:1px solid #1c90fb;}   
        &.on{border:1px solid #1c90fb;background:#f5faff url('../imgs/ico/tab_report_receive_sele.png') no-repeat top 10px left 15px;}   
        .txt01{height:22px;font-size:13px;line-height:22px;color:#4a4a4a;}   
        .txt02{height:24px;font-size:13px;line-height:24px;color:#4a4a4a;   
            b{padding:0 2px 0 3px;font-size:18px;font-weight:bold;line-height:22px;color:#000000;letter-spacing: -0.9px;margin:0 2px 0 0;text-decoration: underline;}   
        }   
   }   
   .type01Unit03{min-width:190px;height:70px;margin:0 0 0 8px;padding:11px 0 0 75px;border:1px solid #cccccc;border-radius:10px;background:#ffffff url('../imgs/ico/tab_report_refer_none.png') no-repeat top 10px left 15px;box-sizing:border-box;cursor: pointer;   
        &:hover{border:1px solid #1c90fb;}   
        &.on{border:1px solid #1c90fb;background:#f5faff url('../imgs/ico/tab_report_refer_sele.png') no-repeat top 10px left 15px;}   
        .txt01{height:22px;font-size:13px;line-height:22px;color:#4a4a4a;}   
        .txt02{height:24px;font-size:13px;line-height:24px;color:#4a4a4a;   
            b{padding:0 2px 0 3px;font-size:18px;font-weight:bold;line-height:22px;color:#000000;letter-spacing: -0.9px;margin:0 2px 0 0;text-decoration: underline;}   
        }   
   }   
   .type01Unit04{min-width:190px;height:70px;margin:0 0 0 8px;padding:11px 0 0 75px;border:1px solid #cccccc;border-radius:10px;background:#ffffff url('../imgs/ico/tab_report_share_none.png') no-repeat top 10px left 15px;box-sizing:border-box;cursor: pointer;   
        &:hover{border:1px solid #1c90fb;}   
        &.on{border:1px solid #1c90fb;background:#f5faff url('../imgs/ico/tab_report_share_sele.png') no-repeat top 10px left 15px;}   
        .txt01{height:22px;font-size:13px;line-height:22px;color:#4a4a4a;}   
        .txt02{height:24px;font-size:13px;line-height:24px;color:#4a4a4a;   
            b{padding:0 2px 0 3px;font-size:18px;font-weight:bold;line-height:22px;color:#000000;letter-spacing: -0.9px;margin:0 2px 0 0;text-decoration: underline;}   
        }   
    }   
    .type01Unit05{min-width:190px;height:70px;margin:0 0 0 8px;padding:11px 0 0 75px;border:1px solid #cccccc;border-radius:10px;background:#ffffff url('../imgs/ico/tab_report_star_none.png') no-repeat top 10px left 15px;box-sizing:border-box;cursor: pointer;   
        &:hover{border:1px solid #1c90fb;}   
        &.on{border:1px solid #1c90fb;background:#f5faff url('../imgs/ico/tab_report_star_sele.png') no-repeat top 10px left 15px;}   
        .txt01{height:22px;font-size:13px;line-height:22px;color:#4a4a4a;}   
        .txt02{height:24px;font-size:13px;line-height:24px;color:#4a4a4a;   
            b{padding:0 2px 0 3px;font-size:18px;font-weight:bold;line-height:22px;color:#000000;letter-spacing: -0.9px;margin:0 2px 0 0;text-decoration: underline;}   
        }   
    }   
}   
   
.wrHead {border-bottom:1px solid #e6e6e6;box-sizing:border-box;padding-top:10px;   
    [class*="OBTTextField"] > div > div{border:none !important; padding-top:3px !important; padding-bottom:9px !important;   
        input[type="text"] {font-size:14px !important;}   
    }   
}   
   
.btn_pop_return{background: url("../imgs/ico/ic_return_m_normal.png") no-repeat center center!important; border: none !important;
    &:hover{background: url("../imgs/ico/ic_return_m_over.png") no-repeat center center!important;}
}   
.btn_pop_export{background: url("../imgs/ico/ic_export_m_normal.png") no-repeat center center !important; border: none !important;
    &:hover{background: url("../imgs/ico/ic_export_m_over.png") no-repeat center center!important;}
}   
.btn_pop_more {background: url("../imgs/ico/ic_more_detail_m_normal@2x.png") no-repeat center center !important; border: none !important; background-size: 18px !important;
    &:hover{background: url("../imgs/ico/ic_more_detail_m_over@2x.png") no-repeat center center!important; background-size: 18px !important;}
}   
.btn_pop_star{background: url('../imgs/ico/ic_favorite_m_off.png') no-repeat center center !important; border: none !important;   
    &:hover{background: url('../imgs/ico/ic_favorite_m_over.png') no-repeat center center !important;}   
    &.on{background: url('../imgs/ico/ic_favorite_m_on.png') no-repeat center center !important;}   
}   
   
.noBorderText{   
    > div {   
        >div {border: none !important;}   
    }   
    input {background:none !important; font-size:12px !important;}   
}    
   
.noBorderTextarea {padding:5px 0;box-sizing:border-box;   
    textarea {border:none !important;font-size:12px !important;line-height: 21px !important; color: rgb(26, 26, 26);vertical-align: top;box-sizing: border-box;resize: none;}   
}   
.noBorderTextaView {font-size: 13px; line-height: 21px;white-space: pre-wrap;box-sizing: border-box;}   
   
/*드랍다운레이어*/   
.dropDownLayer { position: absolute; box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1); border: solid 1px #a6a6a6; background-color: #ffffff;   
    ul {   
        li { line-height: 27px; padding-left: 7px; cursor: pointer;   
            span { display: inline-block; }   
            &:hover { background: #e6f5ff; }   
            &.cmt span { background: url("../imgs/ico/ic_comment_s_normal@2x.png") no-repeat left center; padding-left: 17px; background-size: 14px;}   
            &.edit span { background: url("../imgs/ico/ic_edit_s_normal@2x.png") no-repeat left center; padding-left: 17px; background-size: 14px;}   
            &.del span { background: url("../imgs/ico/ic_trashcan_s_normal.png") no-repeat left center; padding-left: 17px; }   
            &.like span { background: url("../imgs/uc/icon_dropdown_like.png") no-repeat left center; padding-left: 17px; }   
            &.like.on span { background: url("../imgs/uc/icon_board_like_click.png") no-repeat left center; padding-left: 17px; }   
            &.scrap span { background: url("../imgs/ico/ic_scrap_s_normal.png") no-repeat left center; padding-left: 17px; }   
            &.print span { background: url("../imgs/ico/ic_print_s_normal.png") no-repeat left center; padding-left: 17px; }   
            &.userweek span { background: url("../imgs/ico/ic_user_s_normal@2x.png") no-repeat left center; padding-left: 17px; background-size: 14px;}   
            &.excel span { background: url("../imgs/ico/ic_excel_save_s_normal.png") no-repeat left center; padding-left: 17px; }   
            &.open span { background: url("../imgs/ico/ic_open_s_normal.png") no-repeat left center; padding-left: 17px; }   
            &.private span { background: url("../imgs/ico/ic_private_s_normal.png") no-repeat left center;padding-left: 17px; }   
            &.shareAgain span { background: url("../imgs/ico/ic_share_again_s_normal.png") no-repeat left center;padding-left: 17px; }   
            &.copy span { background: url("../imgs/ico/ic_doc_copy_s_normal@2x.png") no-repeat left center;padding-left: 17px; background-size: 14px;}   
            &.history span { background: url("../imgs/ico/ic_docu_history_s_normal@2x.png") no-repeat left center;padding-left: 17px; background-size: 14px;}   
            &.remind span { background: url("../imgs/ico/ic_survey_reminders_s_normal@2x.png") no-repeat left center;padding-left: 17px; background-size: 14px;}   
        }   
    }   
}   
.work_onefficeArea {min-height: 400px;border-bottom:1px solid #e6e6e6;position: relative;padding-top:40px;}   
.btn_modify_oneffice {width: 78px; height: 30px;display: inline-block;background: url("../imgs/uc/btn_modify_normal.png") no-repeat left center;position: absolute; right:0px; top:6px;cursor: pointer; }   
.btn_modify_txt {min-width: 44px; height: 30px;display: inline-block;border-radius: 15px;background:url("../imgs/ico/ic_edit_m_white.png") no-repeat left 12px center,linear-gradient(to bottom, #5eb5fd, #1c90fb) ;position: absolute; right:0px; top:6px;cursor: pointer;padding:0 15px;padding-left: 32px;color:#fff;line-height:30px;text-align: center;}    
   
/*마이페이지 FIDO 이증기기 설정*/   
.fido_sel_ta {margin: 0 auto;}   
.fido_sel_ta table {width: 100%;}   
.fido_sel_ta table th,.fido_sel_ta table td {border:none;text-align: center;}   
.fido_sel_ta .textBox{margin:50px 0 0 0;text-align: center;}   
.fido_sel_ta .textBox .sertiHelpBtn{position:relative;display:inline-block;margin:0 0 0 20px;font-size:14px;line-height:20px;letter-spacing: -0.7px;color:#000000;}   
.fido_sel_ta .textBox .sertiHelpBtn::before{content:'';position: absolute;top:0;left:-20px;width:18px;height:20px;background: url('../imgs/ico/ic_question_02_m_normal.png') no-repeat left center;}   
   
.fido_install {padding:180px 0 0 0;background: url('../imgs/main/img_app_install.png') no-repeat top 5px center;}   
.fido_install .sub{font-size:12px;line-height:15px;text-align: center;color:#8c8c8c;}   
   
.fido_box.imgBoxStyle{position:relative;}   
.fido_box.imgBoxStyle .text{font-size:15px;font-weight:bold;line-height:21px;letter-spacing: -0.8px;text-align: center;color:#4a4a4a;margin:4px 0;}   
.fido_box.imgBoxStyle .text .icoFingerPrint{display:inline-block;vertical-align:middle;width:28px;height:32px;margin:0;background: url('../imgs/ico/ic_certify_fingerprint_l_normal.png') no-repeat left center;}   
.fido_box.imgBoxStyle .text .icoOtp{display:inline-block;vertical-align:middle;width:28px;height:32px;margin:0;background: url('../imgs/ico/ic_private_l_normal.png') no-repeat left top;}   
.fido_box.imgBoxStyle .text .icoDevice{display:inline-block;vertical-align:middle;width:28px;height:32px;margin:0;background: url('../imgs/ico/ic_certify_device_l_normal.png') no-repeat left center;}   
.fido_box.imgBoxStyle .sub{font-size:12px;line-height:15px;text-align: center;color:#8c8c8c;   
   .menuIco{display: inline-block;width:14px;height:14px;vertical-align:bottom;background: url('../imgs/ico/ic_view_list_02_s_over.png') no-repeat center;}   
}   
.fido_box.imgBoxStyle.certi-notallowed{padding:160px 0 0 0;background: url('../imgs/main/img_certi_notallowed.png') no-repeat top 0px center;}   
.fido_box.imgBoxStyle.user-notallowed{padding:90px 0 0 0;background: url('../imgs/main/img_user_notallowed.png') no-repeat top 0px center;}   
.fido_box.imgBoxStyle.certification{padding:210px 0 0 0;background: url('../imgs/main/img_certification.png') no-repeat top 50px center;}   
.fido_box.imgBoxStyle.device-notregistered{padding:210px 0 0 0;background: url('../imgs/main/img_device_notregistered.png') no-repeat top 50px center;}   
.fido_box.imgBoxStyle.app-install{padding:210px 0 0 0;background: url('../imgs/main/img_app_install.png') no-repeat top 50px center;}   
.fido_box.imgBoxStyle.complete{padding:160px 0 0 0;background: url('../imgs/main/ic_complete.png') no-repeat top 35px center;}   
// pin입력   
.fido_box.pin_area{padding:35px;}   
.fido_box.pin_area .pin_input{height: 50px;margin: 0 4px;border-radius: 4px;border: 1px solid #e6e6e6;background: #ffffff;box-sizing: border-box;}   
.fido_box.pin_area .pin_input .number{width: 100%;padding:13px 0;font-size:16px;line-height:24px;border: none;text-indent: 0;border-radius: 4px;text-align: center;background: transparent;outline:none;}   
.fido_box .text{font-size:13px;line-height:41px;letter-spacing: -0.65px;text-align: center;}   
.fido_box .text span{font-size:13px;font-weight:bold;line-height:41px;}   
   
.fido_box.pin_area .pin_input{padding: 0 0 10px 0;border-bottom: 1px solid #d9d9d9;}   
.fido_box.pin_area .pin_input table{width:100%;table-layout:fixed;border-collapse: separate;border-spacing: 5px;}   
.fido_box.pin_area .pin_input table td{border:1px solid #d9d9d9;height: 50px;border-radius: 3px;}   
.fido_box.pin_area .pin_input table td .number{width: 100%;height: 100%;font-size: 22px;outline: 0;border: none;text-indent: 0;border-radius: 3px;}   
.fido_box.pin_area .pin_board{padding: 10px 0 0 0;}   
.fido_box.pin_area .pin_board table{width:100%;table-layout:fixed;border-collapse: separate;border-spacing: 5px;}   
.fido_box.pin_area .pin_board table td{border:1px solid #d9d9d9;height: 50px;border-radius: 3px;}   
.fido_box.pin_area .pin_board table td .btn{width: 100%;height: 100%;font-size: 15px;font-weight: bold;outline: 0;border: none;border-radius: 10px;}   
.fido_box.pin_area .pin_board table td  input[type='button']:active {background: #f4f9fe;}   
   
.fido_box.imgDevice{position:relative;}   
.fido_box.imgDevice.device-connected{padding:110px 0 0 0;background: url('../imgs/main/img_device.png') no-repeat top -5px center;}   
.fido_box.imgDevice.device-notconnected{padding:180px 0 0 0;background: url('../imgs/main/img_device_notconnected.png') no-repeat top 30px center;}   
.fido_box.imgDevice .text{font-size:13px;line-height:41px;letter-spacing: -0.65px;text-align: center;}   
.fido_box.imgDevice .text span{font-size:13px;font-weight:bold;line-height:41px;}   
   
/*home style 공통*/   
.zeroWrap {position: relative;   
    [class*="OBTDockPanel_middle"]{margin-top: 0 !important;}   
    .zeroWrap_in { box-sizing:border-box;}   
   
    .dash_head { border-bottom: 1px solid #e6e6e6; overflow: hidden;height:50px;   
        .left_div {float: left;line-height: 51px;   
            .tit {float: left; font-size: 14px; font-weight: bold; letter-spacing: -0.35px;}   
        }   
        .right_div {float: right; overflow: hidden;   
            .arr {font-size: 12px; letter-spacing: -0.6px; color:#4a4a4a;background:url('../imgs/ico/ic_arrow_right_01_s_disable.png') no-repeat right center;padding-right:16px;line-height: 1; margin-top: 20px; cursor: pointer;display:inline-block;
                &:hover{
                    filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                }
            }   
            .so_txt {color:#a6a6a6;letter-spacing: -0.6px;font-size:12px;margin-top: 16px;display: inline-block;}   
        }   
   
        .gg_sel {float: left; margin-left: 20px;   
            ul{   
                li {float: left;margin-right: 4px;   
                    // old ver   
                    .btn_total {border:1px solid #929fbc !important;border-radius: 2px;background: #fff !important;height:27px;color:#929fbc;}   
                    .btn_total.on {border:none !important;background: #929fbc !important; color:#fff;font-weight: bold;}   
                    .btn_ing {border:1px solid #46a3f0 !important;border-radius: 2px;background: #fff !important;height:27px;color:#46a3f0;}   
                    .btn_ing.on {border:none !important;background: #46a3f0 !important; color:#fff;font-weight: bold;}   
                    .btn_confirm {border:1px solid #20c997 !important;border-radius: 2px;background: #fff !important;height:27px; color:#20c997;}   
                    .btn_confirm.on {border:none !important;background: #20c997 !important; color:#fff;font-weight: bold;}   
                    // new type   
                    .btnType01 {border:1px solid #929fbc !important;border-radius: 2px;background: #fff !important;height:27px;color:#929fbc;}   
                    .btnType01.on {border:none !important;background: #929fbc !important; color:#fff;font-weight: bold;}   
                    .btnType02 {border:1px solid #46a3f0 !important;border-radius: 2px;background: #fff !important;height:27px;color:#46a3f0;}   
                    .btnType02.on {border:none !important;background: #46a3f0 !important; color:#fff;font-weight: bold;}   
                    .btnType03 {border:1px solid #20c997 !important;border-radius: 2px;background: #fff !important;height:27px; color:#20c997;}   
                    .btnType03.on {border:none !important;background: #20c997 !important; color:#fff;font-weight: bold;}   
                }   
            }   
        }   
    }   
}   
.homeMainBg {height: 340px; box-sizing:border-box;background-image: linear-gradient(to bottom, #f5f8fa, #e4edf4);}   
   
.btn_homeRegi {border-radius: 4px; box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16); background-image: linear-gradient(to left, #4d7bff, #4dcaff) !important; width: 169px; height: 48px !important; border:none !important; letter-spacing: -0.7px; text-align: center; font-size:14px !important; color:#fff;font-weight: bold;
    > [class*="OBTButton_wrapper"] {
        > [class*="OBTButton_labelText"] {font-size:14px !important;}
    }
}   
.homeCon{padding: 0 30px;padding-bottom: 30px;overflow: hidden;}   
   
/**************************************************   
   커스텀 트리 (keep에서 사용중)   
**************************************************/   
.customTreeWrap{padding:15px 0;   
    > ul{display:block !important;min-width:100%;width:fit-content;   
       li{   
          & > .itemDiv{padding:0 0 0 10px;}   
          &.expend{ > ul{display:block !important;} }   
          ul{display:none;   
             li{   
                & > .itemDiv{padding:0 0 0 25px;}   
                &.expend{ > ul{display:block !important;} }   
                ul{display:none;   
                   li{   
                      & > .itemDiv{padding:0 0 0 35px;}   
                      &.expend{ > ul{display:block !important;} }   
                      ul{display:none;   
                         li{   
                            & > .itemDiv{padding:0 0 0 45px;}   
                            &.expend{ > ul{display:block !important;} }   
                            ul{display:none;   
                               li{   
                                  & > .itemDiv{padding:0 0 0 55px;}   
                                  &.expend{ > ul{display:block !important;} }   
                                  ul{display:none;   
                                     li{   
                                        & > .itemDiv{padding:0 0 0 65px;}   
                                        &.expend{ > ul{display:block !important;} }   
                                        ul{display:none;   
                                           li{   
                                              & > .itemDiv{padding:0 0 0 75px;}   
                                              &.expend{ > ul{display:block !important;} }   
                                              ul{display:none;   
                                                 li{   
                                                    & > .itemDiv{padding:0 0 0 85px;}   
                                                    &.expend{ > ul{display:block !important;} }   
                                                    ul{display:none;   
                                                       li{   
                                                          & > .itemDiv{padding:0 0 0 95px;}   
                                                          &.expend{ > ul{display:block !important;} }   
                                                          ul{display:none;   
                                                             li{   
                                                                & > .itemDiv{padding:0 0 0 105px;}   
                                                                &.expend{ > ul{display:block !important;} }   
                                                             }   
                                                          }   
                                                       }   
                                                    }   
                                                 }   
                                              }   
                                           }   
                                        }   
                                     }   
                                  }   
                               }   
                            }   
                         }   
                      }   
                   }   
                }   
             }   
          }   
       }   
    }   
    ul{animation-fill-mode: none;   
       li{position:relative;   
          > .itemDiv{width:100%;height:26px;border:1px solid transparent;box-sizing: border-box;   
             &:hover{border:1px solid #1c90fb;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);}   
             &:hover .dragHandler{display:block;}   
             &:hover .btnBox{display:flex;}   
             // 드래그핸들러   
             .dragHandler{display:none;position: absolute;top:0;left:0;width:16px;height:26px;background:url('../imgs/ico/ico_movebar.png') no-repeat center;cursor: move;animation-fill-mode: none;}   
             // 아이콘   
             .ico-root{position:relative;width:18px;height:26px;padding:0 0 0 12px;background:url('../imgs/tree/ic_comp_01.png') no-repeat right;cursor: pointer;}   
             .ico-comp-open{position:relative;width:18px;height:26px;padding:0 0 0 12px;background:url('../imgs/tree/ic_comp_02.png') no-repeat right;cursor: pointer;   
                &::before{content:'';position:absolute;top:0;left:0;width:10px;height:26px;background:url('../imgs/ico/ic_arrow_down.png') no-repeat center;}   
             }   
             .ico-comp-close{position:relative;width:18px;height:26px;padding:0 0 0 12px;background:url('../imgs/tree/ic_comp_02.png') no-repeat right;cursor: pointer;   
                &::before{content:'';position:absolute;top:0;left:0;width:10px;height:26px;background:url('../imgs/ico/ic_arrow_right.png') no-repeat center;}   
             }   
             .ico-open{position:relative;width:18px;height:26px;padding:0 0 0 12px;background:url('../imgs/tree/ic_folder_open_blue.png') no-repeat right;cursor: pointer;   
                &::before{content:'';position:absolute;top:0;left:0;width:10px;height:26px;background:url('../imgs/ico/ic_arrow_down.png') no-repeat center;}   
             }   
             .ico-close{position:relative;width:18px;height:26px;padding:0 0 0 12px;background:url('../imgs/tree/ic_folder_blue.png') no-repeat right;cursor: pointer;   
                &::before{content:'';position:absolute;top:0;left:0;width:10px;height:26px;background:url('../imgs/ico/ic_arrow_right.png') no-repeat center;}   
             }   
             .ico-user{position:relative;width:18px;height:26px;padding:0 0 0 12px;background:url('../imgs/tree/ic_user.png') no-repeat right;}   
             // 텍스트   
             .titleBox{height:27px;margin:0 0 0 5px;white-space:nowrap;   
                .txt{font-size:12px;line-height:27px;color:#000000;   
                   &.on{color: #1c90fb;}      
                }   
                .cnt{font-size:12px;line-height:27px;color:#a6a6a6;margin:0 0 0 5px;}   
             }   
          }   
       }   
    }   
 }   
   
.switchChk {display:flex; align-items: center;
    input[type="checkbox"] {display: none;}   
   
    .label_on_off > * {vertical-align: top; font-size: 10px; -webkit-transition: all .3s; -moz-transition: all .3s; -ms-transition: all .3s; -o-transition: all .3s; transition: all .3s;}   
   
    .label_on_off{margin-left: 5px; overflow: hidden; position: relative; display: inline-block; width: 38px; height: 16px; color: #fff; font-weight: bold; cursor: pointer; border-radius: 13px; border:1px solid #c4c4c4; background-color: #ffffff; -webkit-border-radius: 13px; -moz-border-radius: 13px; -webkit-transition: all .3s; -moz-transition: all .3s; -ms-transition: all .3s; -o-transition: all .3s; transition: all .3s;   
        .marble {position: absolute; top: 3px; left: 3px; display: block; width: 10px; height: 10px; background-color: #fff; background: #c4c4c4; border-radius: 50%; -webkit-border-radius: 50%; -moz-border-radius: 50%; }   
        .on {display: none; padding-left: 6px;}   
        .off {padding-left: 15px; line-height: 15px; color:#c4c4c4;}   
    }   
       
    .input_on_off:checked + .label_on_off {border:1px solid #1c90fb; background-color: #ffffff; 
        .on {display: inline-block;color:#1c90fb}   
        .off {display: none;}   
        .marble {background: #1c90fb;left: 25px;}   
    }   
}   
   
/**************************************************   
   공통 :: 멀티 라인 텍스트박스 (KEEP링크)   
**************************************************/   
.multiLineContents{border:1px solid #cccccc;border-radius: 3px;background:#ffffff;   
    &:focus-within {border: 1px solid #1c90fb;}   
    input[type="text"].cloneHiddenText{position:absolute; left:-1000000px;top:-10000000px; visibility: hidden;}   
    .textBox{width:100%;height:100%;min-height:30px;padding:5px 7px;outline: none;font-size: 13px;line-height: 1.8;vertical-align: middle;word-break: break-all;background: #ffffff;border-radius: 3px;box-sizing: border-box;overflow-y: auto;}      
    // .keepLink{position: relative;display: inline-block;height: 18px;margin: 0 5px 0 0;padding: 0 0 0 20px;cursor: pointer;max-width: 200px;vertical-align: middle;    
    //     img{position: absolute;top: 0;left: 0;width: 18px;height: 18px;}    
    //     .text{width:100%;font-size:12px;font-family:auto;line-height:19px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
    //     &.small{position: relative;display: inline-block;height: 15px;margin: 0 5px 0 0;padding: 0 0 0 15px;cursor: pointer;max-width: 180px;vertical-align: middle;    
    //         img{position: absolute;top: 2px;left: 0;width: 11px;height: 11px;}    
    //         .text{width:100%;font-size:11px;font-family:auto;line-height:15px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
    //     }   
    // }  
}   
   
/**************************************************   
   공통 :: KEEP 내보내기   
**************************************************/
// 23.05.17 내보내기 스크롤처리   
.keepExport{flex-wrap:wrap;padding:11px 14px;border:1px solid #e6e6e6;overflow:auto;   
    .linkCopy{cursor: pointer;padding:10px;   
        .ico{width:50px;height:50px;border-radius: 100px;background:#f2f5fa url('../imgs/ico/ico_export_link.png') no-repeat center;}   
        &:hover{   
            .ico{background:#e0f1ff url('../imgs/ico/ico_export_link.png') no-repeat center;}   
        }   
    }   
    .keep{cursor: pointer;padding:10px;   
        .ico{width:50px;height:50px;border-radius: 100px;background:#f2f5fa url('../imgs/ico/ico_export_keep.png') no-repeat center;}   
        &:hover{   
            .ico{background:#e0f1ff url('../imgs/ico/ico_export_keep.png') no-repeat center;}   
        }   
    }   
    .mail{cursor: pointer;padding:10px;   
        .ico{width:50px;height:50px;border-radius: 100px;background:#f2f5fa url('../imgs/ico/ico_export_mail.png') no-repeat center;}   
        &:hover{   
            .ico{background:#e0f1ff url('../imgs/ico/ico_export_mail.png') no-repeat center;}   
        }   
    }   
    .board{cursor: pointer;padding:10px;   
        .ico{width:50px;height:50px;border-radius: 100px;background:#f2f5fa url('../imgs/ico/ico_export_board.png') no-repeat center;}   
        &:hover{   
            .ico{background:#e0f1ff url('../imgs/ico/ico_export_board.png') no-repeat center;}   
        }   
    }   
    .kiss{cursor: pointer;padding:10px;   
        .ico{width:50px;height:50px;border-radius: 100px;background:#f2f5fa url('../imgs/ico/ico_export_kiss.png') no-repeat center;}   
        &:hover{   
            .ico{background:#e0f1ff url('../imgs/ico/ico_export_kiss.png') no-repeat center;}   
        }   
    }   
    .law{cursor: pointer;padding:10px;   
        .ico{width:50px;height:50px;border-radius: 100px;background:#f2f5fa url('../imgs/ico/ic_export_law@2x.png') no-repeat center; background-size: 24px;}   
        &:hover{   
            .ico{background:#e0f1ff url('../imgs/ico/ic_export_law@2x.png') no-repeat center; background-size: 24px;}   
        } 
    }
    .txt{margin:6px 0 0 0;font-size:12px;line-height:18px;text-align: center;}   
}   
   
/**************************************************   
   KEEP :: 리뉴얼   
**************************************************/   
.HPM0600{   
    [class*="OBTTreeView_root"]{border-color:#e6e6e6;}   
    .sortText{margin: 0 0 4px 0;   
        .textBtn{position:relative;font-size:14px;line-height:27px;color:#4a4a4a;letter-spacing:-0.7px;padding:0 0 0 21px;cursor: pointer;   
           &:first-child{padding:0 0 0 10px;}   
           &.on:first-child{padding:0 0 0 10px;}   
           &:first-child::before{display:none;}   
           &::before{content:'';position: absolute;top:8px;left:11px;width:1px;height:11px;background-color: #e6e6e6;}   
           &.on{font-size:14px;font-weight:bold;line-height:27px;color:#1c90fb;letter-spacing:-0.7px;padding:0 0 0 21px;cursor: pointer;}   
        }   
    }   
    .keepGridWrap{width:100%;height:100%;border:1px solid #e6e6e6;border-top:2px solid #000000;box-sizing: border-box;   
        .keepGridHeader{width:100%;height:32px;border-bottom:1px solid #e6e6e6;background-color:#fafafa;box-sizing: border-box;table-layout: fixed;   
            tr{   
                th{font-size:12px;font-weight:bold;line-height:30px;text-align: center;border-left:1px solid #e6e6e6;cursor: pointer;   
                    &:first-child{border-left:none;}   
                    .pArrow{cursor: pointer;display: none;width: 12px;height: 18px;margin: 0 0 0 2px;vertical-align: text-bottom;}   
                    .pArrow.up{display:inline-block;background: url('../imgs/ico/ic_arrow_up_02_m_normal.png') no-repeat center;}   
                    .pArrow.down{display:inline-block;background: url('../imgs/ico/ic_arrow_down_02_m_normal.png') no-repeat center;}   
                }   
            }   
        }   
        .keepGridList{width:100%;height:auto;background-color:#ffffff;box-sizing: border-box;table-layout: fixed;   
            tr{border-bottom:1px solid #e6e6e6;   
                &:hover{background-color: #eff7ff;}   
                &.on{background-color: #eff7ff;}   
                td{height:32px;font-size:12px;line-height:20px;text-align: center;vertical-align:top;padding:5px 8px;box-sizing:border-box;   
                    &.le{text-align: left;}   
                    &.ri{text-align: right;}   
                    .module{   
                        img{width:18px;height:18px;margin:1px 5px 0 0;vertical-align: top;}   
                    }   
                    .title{font-size:12px;line-height:20px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;cursor: pointer;}   
                    .keyword{margin:5px 0 0 0;   
                        &:after{content:"";display:block;clear:both;height:0;visibility:hidden;}   
                        .inItem{float: left;height: 18px;line-height: 1;margin: 0 4px 4px 0;padding: 0 6px;border: 1px solid #e6e6e6;border-radius: 20px;box-sizing: border-box;background-color: #ffffff;cursor: pointer;   
                            .txt{font-size:11px;line-height:18px;color:#8c8c8c;}   
                        }   
                    }   
                    .detailInfo{justify-content: center;   
                        .detailInfoBtn{width:18px;height:21px;background: url('../imgs/ico/ic_info_01_m_normal.png') no-repeat center;cursor: pointer;   
                            &:hover{background: url('../imgs/ico/ic_info_01_m_over.png') no-repeat center;}   
                        }   
                    }   
                }   
            }   
        }   
        .no_data {height:100%; box-sizing:border-box; text-align:center; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #8c8c8c; letter-spacing:-0.8px;   
            &.small{font-size: 12px;   
                .text{padding-top: 70px; background:url("../imgs/empty/img_empty_keep_s.png") no-repeat center center;background-size:42px 42px; margin-top: -35px;}   
            }   
        }   
    }   
}   
   
/**************************************************   
   KEEP :: 싱세 레이어   
**************************************************/   
.keepLayer{position: fixed;top: 0px;bottom: 20px;right:20px;border: 1px solid #e1e1e1;box-sizing: border-box;background-color: #ffffff;box-shadow: 0 0 10px 0 rgba(0,0,0,0.16);z-index: 2;overflow:hidden;animation-fill-mode:none;   
    .layerDetailWrap{position:relative;width: 330px;height:100%;box-sizing: border-box;   
        // 상세 헤더   
        .detailHeader{height:48px;padding:12px 20px;border-bottom:1px solid #e6e6e6;box-sizing: border-box;   
            .title{height:24px;font-size:16px;line-height:24px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}   
            .detailBtn{height:24px;padding:3px 0;overflow: hidden;box-sizing:border-box;   
                .btnDone {display:block;width: 18px;height:18px;margin:0 0 0 10px;background:url('../imgs/ico/ic_check_01_m_normal.png') no-repeat center;cursor:pointer;   
                    &:hover{background:url('../imgs/ico/ic_check_01_m_over.png') no-repeat center;}   
                }   
                .btnMore {display:block;width: 18px;height:18px;margin:0 0 0 10px;background:url('../imgs/ico/ic_more_detail_m_normal.png') no-repeat center;cursor:pointer;   
                    &:hover{background:url('../imgs/ico/ic_more_detail_m_over.png') no-repeat center;}   
                }   
                .btnClose {display:block;width: 18px;height:18px;margin:0 0 0 10px;background:url('../imgs/ico/ic_close_01_m_normal.png') no-repeat center;cursor:pointer;   
                    &:hover{background:url('../imgs/ico/ic_close_01_m_over.png') no-repeat center;}   
                }   
            }   
        }   
    
       // 상세 컨텐츠   
       .detailContents{margin:15px 20px;   
            // 등록/수정모드   
            .detailsUnit{padding:1px 0;border-bottom:1px solid #e7e7e7;   
                &.edit{padding:5px 0;border-bottom:1px solid #e7e7e7;}   
                &:last-child{border-bottom:none;}   
       
                dt{min-width: 70px;height: 27px;line-height: 27px;padding: 5px 0 5px 22px;font-size:13px;line-height:27px;color: #666666;   
                    .btnBox{line-height:1;padding: 3px 0;}   
                }   
                dt.icoModule{background: url('../imgs/ico/ic_view_list_02_m_normal.png') no-repeat left;}   
                dt.icoKeep{background: url('../imgs/ico/ic_keep_off_m_normal.png') no-repeat left;}   
                dt.icoUser{background: url('../imgs/ico/ic_user_m_normal.png') no-repeat left;}   
                dt.icoCal{background: url('../imgs/ico/ic_calendar_m_normal.png') no-repeat left;}   
                dt.icoTag{background: url('../imgs/ico/ic_docu_tag_m_normal.png') no-repeat left;}   
                dt.icoContents{background: url('../imgs/ico/ic_survey_m_normal.png') no-repeat left;}   
                dd{padding:5px 0;   
                    .text{font-size:12px;line-height:27px;color:#666666;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}   
                    .textarea{padding: 0 0 0 22px;font-size:12px;line-height:18px;color: #666666;word-break: break-all;box-sizing: border-box;}   
                    .textMultiBox{width:100% !important;background-color: #ffffff;   
                        textarea{padding:6px !important;line-height: 1.5 !important;}   
                    }   
                }   
                .OriginalInfoBox{width:100%;border:1px solid #e0e0e0;border-radius:3px;background-color:#f5f5f5;margin:12px 0;padding:0 24px 0 16px;box-sizing:border-box;   
                    .title{padding:14px 0 6px 0;font-size:12px;line-height:20px;color:#4a4a4a;letter-spacing: -0.6px;border-bottom:1px solid #e6e6e6;}   
                    .dtxt{   
                        .dt{width:62px;font-size:12px;line-height:1.5;color:#666666;letter-spacing: -0.6px;}   
                        .dd{min-height:20px;font-size:12px;line-height:1.5;color:#666666;letter-spacing: -0.6px;}   
                    }   
                }   
            }   
        }   
    }   
 }   
   
 /**************************************************   
   KEEP :: 컨텍스트메뉴   
**************************************************/   
.keep_contextMenu{position: fixed;box-shadow:0 0 10px 0 rgba(0, 0, 0, 0.1);border: solid 1px #a6a6a6;background-color: #ffffff;z-index: 10;   
    .pop_con{padding:5px 0;   
       .pop_list_div:after{content:"";display: block;height:1px;margin: 5px 15px;box-sizing: border-box;background:#e5e5e5;}   
       .pop_list_div:last-child:after{display:none;}   
       .pop_list_div .pop_menu{min-width: 98px;height: 25px;cursor: pointer;padding: 0 15px;clear: both;}   
       .pop_list_div .pop_menu:hover{transition:all 0.3s;background:#eaf6ff;}   
       .pop_list_div .pop_menu .tit{float: left;font-size: 12px;color: #3f3f3f;letter-spacing: -0.25px;margin: 3px 0 0 0;}   
       .pop_list_div .pop_menu .ico_edit{float: left;width: 18px;height: 18px;margin: 4px 10px 0 0;background: url('../imgs/ico/ic_edit_m_normal.png') no-repeat center center;}   
       .pop_list_div .pop_menu .ico_delete{float: left;width: 18px;height: 18px;margin: 4px 10px 0 0;background: url('../imgs/ico/ic_trashcan_m_normal.png') no-repeat center center;}   
       .pop_list_div .pop_menu .ico_link{float: left;width: 18px;height: 18px;margin: 4px 10px 0 0;background: url('../imgs/ico/ic_link_m_normal.png') no-repeat center center;}   
    }   
    .semo{position: absolute;width: 10px;height: 10px;border:solid 1px #a6a6a6;background: #ffffff;z-index:-1;   
       &.topLeft{top: -6px;left: 21px;width: 10px;height: 10px;border-width: 1px 0px 0px 1px;transform: rotate(45deg);}   
       &.topRight{top: -6px;right: 21px;width: 10px;height: 10px;border-width: 1px 0px 0px 1px;transform: rotate(45deg);}   
       &.leftTop{top: 21px;left: -6px;width: 10px;height: 10px;border-width: 1px 0px 0px 1px;transform: rotate(-45deg);}   
       &.leftBottom{bottom: 21px;left: -6px;width: 10px;height: 10px;border-width: 1px 0px 0px 1px;transform: rotate(-45deg);}   
       &.rightTop{top: 21px;right: -6px;width: 10px;height: 10px;border-width: 0 1px 1px 0;transform: rotate(-45deg);}   
       &.rightBottom{bottom: 21px;right: -6px;width: 10px;height: 10px;border-width: 0 1px 1px 0;transform: rotate(-45deg);}   
       &.bottomLeft{bottom: -6px;left: 21px;width: 10px;height: 10px;border-width: 0px 1px 1px 0;transform: rotate(45deg);}   
       &.bottomRight{bottom: -6px;right: 21px;width: 10px;height: 10px;border-width: 0px 1px 1px 0;transform: rotate(45deg);}   
    }   
 }   
 
/**************************************************   
   GridType01 :: 기본형   
**************************************************/   
.GridType01{min-width: fit-content;width: 100%;height: 100%;   
    table{width:100%; box-sizing:border-box; table-layout: fixed;}       
    &.line{border-width:0 1px 0 1px; border-color:#e6e6e6; border-style: solid;} //박스 테두리 있는 테이블
    &.noThead{border-top:2px solid #666;}   
    .gridHeader{            
        thead{height:32px; background-color:#fafafa; border-top:2px solid #666;   
            th{font-size:13px; padding:13px 0; border:solid #e6e6e6; border-width:0px 1px 1px 0px; text-align: center; letter-spacing: -0.6px; font-weight:bold;   
                &:last-child{border-right:none;}
                .info{display:inline-flex;width:14px;height:14px;vertical-align:middle;margin-left:4px;background:url('../imgs/ico/ic_info_01_s_normal@2x.png') no-repeat;background-size:14px;}   
            }      
        }   
    }   
    .gridBody{     
        tbody{   
            tr{   
                &:hover td{background-color:#eff7ff;}   
                &:active td{background-color:#eff7ff;}   
                &.on td{background-color:#eff7ff;}   
                &.unread{   
                    .titleCon{   
                        .title{font-weight:bold;}   
                    }   
                }   
            }   
            td{font-size:13px; height:26px; padding: 13px 0; border-bottom:1px solid #e6e6e6; text-align: center; box-sizing: border-box; background-color:#fff;   
                &.con{text-align: left;   
                    em{min-width: 25px; height: 18px; padding: 0 6px; margin-right:5px; border-radius: 10px; background-color:#ff8787; color:#fff; text-align: center; font-size: 11px;}   
                    .text{cursor: pointer; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis;}   
                    .file{background:url("../imgs/ico/ic_attach_s_normal@2x.png") no-repeat left center; background-size: 14px 14px; padding-left:13px; color:#7b7b7b; margin-left:5px;cursor: pointer;}      
                    .cmt{background:url("../imgs/ico/ic_comment_s_normal@2x.png") no-repeat left center; background-size: 14px 14px; padding-left:13px; color:#7b7b7b; margin-left:5px;}  
                }                   
                .text{cursor: pointer;}     
                .curAuto{cursor: auto;}     
   
                .titleCon{display: flex; flex-direction: row; min-width: 0; padding-right: 5px;   
                    .title{overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis; line-height:20px;}   
                }  
            }       
            .ar{text-align: right; padding-right:10px !important;}   
            .al{text-align: left; padding-left:10px !important;}     
            .imgCss{vertical-align: middle; margin-right:10px; width:18px; height:18px;}       
            .disable{color:#a6a6a6;} 
        }  
        &.line{                
            tr{
                td{border-right:1px solid #e6e6e6; box-sizing: border-box;
                    &:last-child{border-right:none;}
                }       
            } 
        }   
    }   
    .no_data.small .text{padding-top: 85px;}      
    .ico_cnt{margin:0 0 0 6px;padding:0 0 0 15px;font-size:12px;color:#7d7d7d; cursor: pointer; line-height:20px;
        &:hover{text-decoration: underline;
            filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
        }   
        &.attach{background:url('../imgs/ico/ic_attach_s_normal@2x.png') no-repeat left 3px center; background-size: 14px;}   
        &.reply{background:url('../imgs/ico/ic_comment_s_normal@2x.png') no-repeat left; background-size: 14px;}  
        &.connect{background:url("../imgs/ico/ic_connect_s_normal@2x.png") no-repeat left; background-size: 14px;}  
    }   
    .ico_new{width:14px; height:14px; margin-left:6px; vertical-align: middle; margin-top: 3px;}      
    .ico_newPage{width:14px; height:20px;line-height:20px; margin-left:6px; vertical-align: middle;background:url("../imgs/ico/ic_write_newpage_s_normal@2x.png") no-repeat left; background-size: 14px;cursor: pointer;
        &:hover{background:url("../imgs/ico/ic_write_newpage_s_over@2x.png") no-repeat left; background-size: 14px;}
    }
    
    //그리드 내 아이콘 추가
    .star{width: 18px; height: 18px; background: url('../imgs/ico/ic_favorite_m_off.png') no-repeat center; cursor: pointer; text-align: center; vertical-align: middle; 
        &:hover{background: url('../imgs/ico/ic_favorite_m_over.png') no-repeat center;}   
        &.on{background: url('../imgs/ico/ic_favorite_m_on.png') no-repeat center;}   
    }
    //구분
    .radiusText{font-size: 11px; padding: 0 6px 1px 6px; border-radius: 9px; border: solid 1px #656565; text-align: center;color: #494949;}

    //상태
    .state {
        &.confirm{color:#20c997;background:url('../imgs/ico/ico_confirm_03.png') no-repeat left; padding-left:20px;}    
        &.save {color:#c8b465; background:url('../imgs/ico/ico_save.png') no-repeat left; padding-left:20px;}
    }
    
}   
   
// 데이터 없을 떄    
.GridTypeBg{border:solid #e6e6e6; border-width:0px 1px 1px 1px; box-sizing: border-box;}   
   
// 상단 기능 필터 영역   
.pageContainerFilter{margin-bottom:15px;   
    .listFncWrap{margin:0 !important;   
        .line{width: 1px;height:15px;margin: 6px 9px;background-color:#e6e6e6;}   
        .leftFnc{height:27px;   
            .allChk{margin:5px 10px 0 0;width:18px;height:18px;background:url('../imgs/ico/ic_check_02_m_disable.png') no-repeat center;cursor: pointer;   
                &.on{background:url('../imgs/ico/ic_check_03_m_over.png') no-repeat center;}   
            }   
        }   
        .rightFnc{justify-content: flex-end; align-items: center;   
            .alignFilterFnc{position: relative; margin: 5px 0 0 10px; cursor: pointer; padding: 0 15px 0 0;  background: url('../imgs/ico/ic_arrow_down_02_s_normal@2x.png') no-repeat center right; background-size: 14px;
                &:hover{color:#319df6;background: url('../imgs/ico/ic_arrow_down_02_s_over@2x.png') no-repeat center right;background-size:14px;}
                &.on{background: url('../imgs/ico/ic_arrow_up_02_s_normal@2x.png') no-repeat center right;background-size:14px;}    
                &.on:hover{color:#319df6;background: url('../imgs/ico/ic_arrow_up_02_s_over@2x.png') no-repeat center right;background-size:14px;}         
            }       
            .FilterBoxBasic{top:28px;right:0;   
                ul{   
                    li{font-size:12px;line-height:27px;color: #000000;padding: 0 0 0 26px;box-sizing: border-box;cursor: pointer;   
                        &:hover{background-color: #e6f5ff;
                            span{
                                &.up{background: url('../imgs/ico/ic_ascending_order_m_over@2x.png') no-repeat right; background-size: 18px;}
                                &.down{background: url('../imgs/ico/ic_descending_order_m_over@2x.png') no-repeat right; background-size: 18px;}
                            }
                        }
                        &.chk{font-size:12px;line-height:27px;color: #1c90fb;background: url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 18px;}   
                        &.chk:hover{font-size:12px;line-height:27px;color: #1c90fb;background:#e6f5ff url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 18px;}   
                        span{display:block;margin:0 10px 0 0;   
                            &.up{display:block;background: url('../imgs/ico/ic_ascending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}   
                            &.down{display:block;background: url('../imgs/ico/ic_descending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}   
                        }   
                    }   
                }   
            }   
        }     
    }   
}   

/**************************************************   
   GridType02 :: 열/행 고정 스크롤형   
**************************************************/ 
.GridType02{position: relative; width: 100%; border-top: 2px solid #666666 !important; box-sizing: border-box;
    &.line{ border: solid #e6e6e6;border-width:0 1px;}
    .gridWrap{position: relative; background-color: #ffffff;
        &.totalLine{border-top: 1px solid #e6e6e6;}
        // 테이블 공통
        table{position: relative; border-collapse: separate; width: 100%; overflow: visible; white-space: nowrap;
            tbody tr{background-color: #ffffff; cursor: pointer;
                &:last-child{border-right: none;}
                &.on{background-color: #e6f5ff;}
                &.total{
                    td{background-color: #fff1d6 !important;}
                    th{background-color: #fff1d6 !important;}
                }
            }
            th{height: 46px; border-right: 1px solid #e6e6e6; border-bottom: 1px solid #e6e6e6; background-color: #fafafa;
                .info{display:inline-flex;width:14px;height:14px;vertical-align:middle;margin-left:4px;background:url('../imgs/ico/ic_info_01_s_normal@2x.png') no-repeat;background-size:14px;}   
            }
            td{height: 46px; padding: 0 10px; border-right: 1px solid #e6e6e6; border-bottom: 1px solid #e6e6e6;}
            .tBorder{border-right: 1px solid #e6e6e6;}
        }
        // sticky 공통
        .sot-top{position: sticky; top: 0}
        .sot-left{position: sticky; left: 0; z-index: 10;}
        .z30{z-index: 30 !important;}

        .gridLeft table{table-layout: auto;
            tr:last-child td{border-bottom: none;}
        }
        .gridRight table{table-layout: fixed;
            tr:last-child td{border-bottom: none;}
            th:last-child{border-right:none;}
            td:last-child{border-right:none;}
        }

        // emptySet
        .gridNoData{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);}
    }
    // 스크롤
    .n-scroll{scrollbar-width: none !important;-ms-overflow-style: none !important;}
    .n-scroll::-webkit-scrollbar{display: none !important;}
}
   
/**************************************************   
   GridType03 :: 필터 구분형   
**************************************************/   
.GridType03{   
    [class*="OBTDockPanel_middle"]{margin-top:0 !important;}   
    // 타이틀 & 검색   
    .titleSearchWrap{box-sizing:border-box;   
        .titleSearch{position: absolute;top: -50px;left: 0;right:50px;height: 49px;padding:0 10px 0 20px;box-sizing: border-box;background-color: #ffffff;   
            .titleDivUnit{   
                .title{font-size:18px;line-height:49px;letter-spacing:-0.8px;color:#000000;}   
                .cnt{align-items: center; max-width: 420px; line-height: 49px; margin:0 0 0 10px; font-size:13px; font-weight:bold; letter-spacing:-0.8px;   
                    .searchTxt{max-width: 150px; line-height: 1; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;   
                        span{color:#1c90fb;}   
                    }   
                }   
                .refreshBtn{margin:18px 0 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_refresh_s_normal@2x.png') no-repeat center center; background-size: 14px; cursor: pointer;   
                    &:hover{background:url('../imgs/ico/ic_refresh_s_over@2x.png') no-repeat center center; background-size: 14px;}   
                }   
            }   
            .searchDivUnit{margin:11px 0;justify-content: flex-end;   
                &::after{content: "";display: block;clear: both;background: #e6e6e6;width: 1px;height: 16px;right: 0;top: 18px;position: absolute;}   
                .searchBox{position:relative;   
                    .searchInput > div > div{padding:3px 30px 3px 6px !important;}
                    .searchBtn{position: absolute;top:4px;right:0;width:30px;height:20px;background: url("../imgs/ico/ic_search_m_normal.png") no-repeat center;cursor: pointer;   
                        &:hover{background: url("../imgs/ico/ic_search_m_over.png") no-repeat center;}   
                        &.disabled{background: url("../imgs/ico/ic_search_m_disable.png") no-repeat center;}   
                    }   
                }    
            }   
        }   
        .searchDetail{position:relative;padding:20px 70px 20px 20px;border-bottom: 1px solid #e6e6e6;   
            .row{margin:0 0 5px 0;height:27px;   
                &:last-child{margin:0;}   
                dl{padding:0 20px 0 0;   
                    dt{min-width:80px;text-align:right;margin:0 10px 0 0;line-height:27px;}   
                    dd{   
                        .fullPicker{   
                            > div {width:100% !important;}   
                         }   
                    }   
                }   
            }   
            .searchBtn{position:absolute;top:20px;right:20px;}   
        }   
    }   
    // 기능영역   
    .listFncWrap{margin:0 !important;padding:13px 20px 12px 20px;border-bottom: 1px solid #e6e6e6;box-sizing:border-box;   
        .leftFnc{height:27px;   
            .allChk{margin:5px 10px 0 0;width:18px;height:18px;background:url('../imgs/ico/ic_check_02_m_disable.png') no-repeat center;cursor: pointer;   
                &.on{background:url('../imgs/ico/ic_check_03_m_over.png') no-repeat center;}   
            }   
        }   
        .rightFnc{height:27px;justify-content: flex-end;
            .alignFilterFnc{position:relative;margin:4px 0 0 10px;cursor: pointer;padding: 0;background: none;   
                .txt{font-size:12px;line-height:18px;color:#000000;}   
                .arrow{width:18px;height:18px;background:url('../imgs/ico/ic_arrow_down_02_s_normal@2x.png') no-repeat center center; background-size: 14px;
                    &.on{background: url('../imgs/ico/ic_arrow_up_02_s_normal@2x.png') no-repeat center center;background-size:14px;}    
                }
                &:hover{
                    .arrow{color:#319df6;background: url('../imgs/ico/ic_arrow_down_02_s_over@2x.png') no-repeat center center;background-size:14px;
                        &.on{color:#319df6;background: url('../imgs/ico/ic_arrow_up_02_s_over@2x.png') no-repeat center center;background-size:14px;}         
                    }
                }
                .alignFilterTypeBox{top:23px;right:0;}   
            }   
            .alignFilterTypeBox{position:absolute;width: 160px;height: auto;border: 1px solid #a6a6a6;box-sizing: border-box;background: #ffffff;z-index: 1;   
                dt{font-size:12px;font-weight:bold;line-height:21px;border-bottom: 1px solid #f0f0f0;padding: 0 0 0 10px;}   
                dd{font-size:12px;line-height:21px;border-bottom: 1px solid #f0f0f0;   
                    ul{   
                        li{font-size:12px;line-height:27px;color: #000000;padding: 0 0 0 26px;box-sizing: border-box;cursor: pointer;   
                            &:hover{background-color: #e6f5ff;
                                span{
                                    &.up{background: url('../imgs/ico/ic_ascending_order_m_over@2x.png') no-repeat right; background-size: 18px;}
                                    &.down{background: url('../imgs/ico/ic_descending_order_m_over@2x.png') no-repeat right; background-size: 18px;}
                                }
                            }
                            &.chk{font-size:12px;line-height:27px;color: #1c90fb;background: url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 18px;}   
                            &.chk:hover{font-size:12px;line-height:27px;color: #1c90fb;background:#e6f5ff url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 18px;}   
                            span{display:block;margin:0 10px 0 0;   
                                &.up{display:block;background: url('../imgs/ico/ic_ascending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}   
                                &.down{display:block;background: url('../imgs/ico/ic_descending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}   
                            }   
                        }   
                    }   
                }   
            }   
   
            .gridViewType{margin:0 0 0 10px;   
                ul{overflow: hidden;   
                    li{width: 26px;height: 25px;border: 1px solid #a6a6a6;transition: 0.3s;background-image: linear-gradient(to top, #f0f0f0, #ffffff);   
                        &:first-child{border-width: 1px;border-right-color: transparent;border-radius: 2px 0 0 2px;}   
                        &:last-child{border-width: 1px;border-left-color: transparent;border-radius: 0 2px 2px 0;}   
                        span{display: block;width: 26px;height: 25px;cursor: pointer;   
                            &.listBtn{background: url("../imgs/ico/ic_view_list_02_m_normal.png") no-repeat center;}   
                            &.thumbnailBtn{background: url("../imgs/ico/ic_view_thumbnail_02_m_normal.png") no-repeat center;}   
                        }   
                        &.on{border: 1px solid #1c90fb;background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);   
                            span{   
                                &.listBtn{background: url("../imgs/ico/ic_view_list_02_m_over.png") no-repeat center;}   
                                &.thumbnailBtn{background: url("../imgs/ico/ic_view_thumbnail_02_m_over.png") no-repeat center;}   
   
                            }   
                        }   
                        &.disabled{   
                            span{   
                                &.listBtn{background: url("../imgs/ico/ic_view_list_02_m_disable.png") no-repeat center;}   
                                &.thumbnailBtn{background: url("../imgs/ico/ic_view_thumbnail_02_m_disable.png") no-repeat center;}   
                            }   
                        }   
                    }   
                }   
            }   
            .line{width: 1px;height:15px;margin: 6px 4px;background-color:#e6e6e6;}   
        }   
    }   
    // GridType03ListView   
    .GridType03ListView{min-width:fit-content;width:100%;height:100%;padding:20px;box-sizing: border-box;   
        .tableGroup{position:relative;   
            .flexTableWrap{   
                .tableHeader{border-radius:4px;background-color:#f2f6f8;   
                    .tr{position:relative;margin:0 0 0 20px;height:30px;line-height:30px;font-size:12px;color:#8c8c8c;letter-spacing:-0.6px;text-align:center;   
                        &:last-child{margin:0 20px;}   
                        &.cen{justify-content: center;align-items: center;}   
                        .trLink{cursor: pointer;
                            &:hover{filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);}   
                            .arrDown{width:18px;height:30px;background:url('../imgs/ico/ic_arrow_down_02_m_normal@2x.png') no-repeat center center; background-size: 18px;}   
                        }   
                        .selectBox{position:absolute;min-width: 100px;height: auto;border: 1px solid #a6a6a6;box-sizing: border-box;background: #ffffff;z-index: 1;   
                            ul{   
                                li{font-size:12px;line-height:27px;text-align:left;color: #000000;padding: 0 0 0 26px;box-sizing: border-box;cursor: pointer;   
                                    &:hover{background-color:#e6f5ff ;}   
                                    &.chk{font-size:12px;line-height:27px;color: #1c90fb;background: url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 18px;}   
                                    &.chk:hover{font-size:12px;line-height:27px;color: #1c90fb;background:#e6f5ff url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 18px;}   
                                    span{display:block;margin:0 10px 0 0;   
                                        &.up{display:block;background: url('../imgs/ico/ic_ascending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}   
                                        &.down{display:block;background: url('../imgs/ico/ic_descending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}   
                                    }   
                                }   
                            }   
                        }
                        .info{display:inline-flex;width:14px;height:14px;vertical-align:middle;margin-left:4px;background:url('../imgs/ico/ic_info_01_s_normal@2x.png') no-repeat;background-size:14px;}      
                    }   
                }   
                .tableBody{margin:10px 0 0 0;   
                    > li{position:relative;margin:6px 0 0 0;height:54px;border:1px solid #e6e6e6;border-radius:4px;background-color:#ffffff;box-sizing: border-box;cursor: pointer;   
                        &:first-child{margin:0;}   
                        &.unread{   
                            .td{   
                                .titDiv{   
                                    .title{font-weight:bold;}
                                }   
                            }   
                        }   
                        &:hover{border:1px solid #1c90fb;background-color:#ffffff;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);   
                            .hoverBtn{display: flex;justify-content: center;align-items: center;}   
                        }   
                        &:active{border:1px solid #1c90fb;background-color:#f3f9ff;}   
                        &.on{border:1px solid #1c90fb;background-color:#f3f9ff;}   
                        &.on:hover{   
                            .hoverBtn{display: flex;justify-content: center;align-items: center;background:linear-gradient(90deg, rgba(243,249,255,0.8) 0%, rgba(243,249,255,1) 100%);}   
                        }   
                        &.delLine{text-decoration:none !important; background-color: #f5f5f5;   
                            .td *{color: #8c8c8c !important;   
                                [class*="OBTTooltip_contents"]{color: #000 !important;}   
                            }   
                            .processType07 *{color: #f8a457 !important}   
                        }   
                        .td{margin:0 0 0 20px;box-sizing: border-box;   
                            &:last-child{margin:0 20px;}   
                            &.cen{justify-content: center;align-items: center;}   
                            &.le{justify-content: center;align-items: flex-start;   
                                &.v-box{justify-content: center;align-items:unset;}   
                            }   
                            &.ri{justify-content: center;align-items: flex-end;   
                                &.v-box{justify-content: center;align-items:unset;}   
                            }   
                            .listChk{width:18px;height:18px;background:url('../imgs/ico/ic_check_02_m_disable.png') no-repeat center;cursor: pointer;   
                                &.on{background:url('../imgs/ico/ic_check_03_m_over.png') no-repeat center;}   
                            }   
                            .ico_important{width: 18px; height: 18px; background: url('../imgs/ico/ic_important_off_m_disable@2x.png') no-repeat center center; background-size: 18px;
                                &.on{background: url('../imgs/ico/ic_important_on_m_normal@2x.png') no-repeat center center; background-size: 18px;}
                            }
                            .dateText{font-size:13px;line-height:52px;letter-spacing:-0.33px;color:#000000;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;}   
                            .tdText{font-size:13px;line-height:52px;letter-spacing:-0.33px;color:#000000;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;}   
                            .titDiv{margin:7px 0 0 0;max-width:100%;height:20px; align-items: center;   
                                .ico_red{min-width:34px;font-size: 11px;color: #ffffff;line-height: 18px;text-align:center;margin:2px 4px 0 0;border-radius: 10px;vertical-align: top;background-color: #ff8787;}   
                                .ico_orange{min-width:34px;font-size: 11px;color: #ffffff;line-height: 18px;text-align:center;margin:2px 4px 0 0;border-radius: 10px;vertical-align: top;background-color:#FFA94D;}   
                                .ico_img{width:16px;height:16px;margin:2px 4px 0 0;}   
                                .ico_set{width: 14px; height: 14px; margin-right: 4px;   
                                    &.security{background: url('../imgs/ico/ic_security_s@2x.png') no-repeat center center; background-size: 14px;}    
                                    &.emergency{background: url('../imgs/ico/ic_emergency_s@2x.png') no-repeat center center; background-size: 14px;}   
                                    &.sign{background: url('../imgs/ico/ic_sign_sub_s_normal@2x.png') no-repeat center center; background-size: 14px;}   
                                    &.inspection{background: url('../imgs/ico/ic_inspection_s@2x.png') no-repeat center center; background-size: 14px;}   
                                    &.draft{background: url('../imgs/ico/ic_draft_all_s_normal@2x.png') no-repeat center center; background-size: 14px;}  
                                    &.time{background: url('../imgs/ico/ic_time_s@2x.png') no-repeat center center; background-size: 14px;}
                                    &.department{background: url('../imgs/ico/ic_department_s@2x.png') no-repeat center center; background-size: 14px;}
                                    &.docuReceive{background: url('../imgs/ico/ic_docu_receive_s@2x.png') no-repeat center center; background-size: 14px;}
                                    &.docuSend{background: url('../imgs/ico/ic_docu_send_s@2x.png') no-repeat center center; background-size: 14px;}
                                    &.receiveSend{background: url('../imgs/ico/ic_receive_send_s@2x.png') no-repeat center center; background-size: 14px;}
                                    &.resend{background: url('../imgs/ico/ic_resend_s@2x.png') no-repeat center center; background-size: 14px;}
                                    &.instead{background: url('../imgs/ico/ic_approval_instead_s@2x.png') no-repeat center center; background-size: 14px;}
                                    &.lock{background: url('../imgs/ico/ic_lock_s@2x.png') no-repeat center center; background-size: 14px 14px;} 
                                }   
                                .title{font-size:13px;line-height:20px;letter-spacing:-0.33px;color:#000000;text-overflow:ellipsis;white-space:nowrap;overflow: hidden;}   
                                .ico_cnt{margin:0 0 0 6px;padding:0 0 0 18px;font-size:12px;color:#7d7d7d;   
                                    &:hover{text-decoration: underline;
                                        filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                                    }   
                                    &.attach{background:url('../imgs/ico/ic_attach_s_normal@2x.png') no-repeat center left 3px; background-size: 14px;}   
                                    &.reply{background:url('../imgs/ico/ic_comment_s_normal@2x.png') no-repeat center left; background-size: 14px;}   
                                }   
                            }   
                            .infoDiv{margin:2px 0 0 0;height:18px;   
                                .infoLink:hover{text-decoration:underline;text-decoration-color:#8c8c8c;}   
                                .txt{font-size:11px;line-height:18px;color: #8c8c8c;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}   
                                .line{width: 1px;height: 8px;margin: 5px 4px;background-color:#e6e6e6;}   
                            }   
                            .picDiv{min-width:24px;width:24px;min-height:24px;height:24px;margin:14px 6px 14px 0;border-radius:24px;overflow: hidden;   
                                img{width:100%;min-height:24px;}   
                            }   
                            .nameDiv{margin:7px 0 0 0;height:20px;font-size:13px;line-height:20px;letter-spacing:-0.33px;color:#000000;}   
                            .process{min-height:18px;align-items: center;   
                                img{width:16px;height:16px;margin:1px 4px 0 0;}   
                                span{font-size:12px;line-height:18px;}   
                                &.processType01{color:#9da3aa;}   
                                &.processType02{color:#f0c325;}   
                                &.processType03{color:#46a3f0;}   
                                &.processType04{color:#919191;}   
                                &.processType05{color:#20C997;}   
                                &.processType06{color:#FF8787;}   
                                &.processType07{color:#f8a457;}   
                                .ellipsis2{max-height: 36px;line-height: 18px;white-space:normal;}   
                            }   
                        }   
                        .hoverBtn{display:none;position:absolute;top:0;right:0;bottom:0;padding:0 20px 0 10px;border-radius:4px;background:linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 100%);   
                            .btn{width:18px;height:18px;margin:0 0 0 10px;   
                                img{vertical-align: unset;}   
                                &:hover img{
                                    // 클래스가 아닌 아이콘 이미지는 필터로 over 처리
                                    filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                                }
                            }   
                        }   
                    }   
                }   
            }   
            .checkBox{   
                svg{left:4px !important;background-color:#ffffff;}   
            }   
        }   
    }   
    .GridType03ThumbNailView{width:100%;padding:20px;box-sizing: border-box;}   
}   

/**************************************************   
   GridType04 :: 디자인 기본형 + div flex 구조   
**************************************************/
.GridType04{min-width: fit-content;width: 100%;height: 100%;   
    &.box {border-style:solid;border-width:2px 1px 1px 1px;border-color:#666666 #e6e6e6 #e6e6e6 #e6e6e6;box-sizing:border-box;
        .tr{border:none !important;}
        .tableHeader{
            .th{border:none;border-bottom:1px solid #e6e6e6;}
        }
        .tableBody{
            .td{border:none;border-bottom:1px solid #e6e6e6;}
            .hoverWrap{bottom:1px !important;}
        }
    }
    .tableGroup{position:relative;width:100%;box-sizing: border-box;   
        .flexTableWrap{   
            .tableHeader{
                .tr{height:32px;border-style:solid;border-width:2px 1px 1px 1px;border-color:#666666 transparent #e6e6e6 transparent;background-color:#fafafa;
                    .th{position: relative;padding:5px 10px;font-size: 13px;font-weight: bold;text-align: center;letter-spacing: -0.6px;border-left:1px solid #e6e6e6;box-sizing: border-box;
                        &:first-child{border-left:none;}
                        .info{display:inline-flex;width:14px;height:14px;vertical-align:middle;margin-left:4px;background:url('../imgs/ico/ic_info_01_s_normal@2x.png') no-repeat;background-size:14px;}    
                    }
                }
            }   
            .tableBody{   
                .tr{position:relative;border:1px solid;border-color:transparent transparent #e6e6e6 transparent;background-color:#ffffff;
                    &:hover{border:1px solid #1c90fb;background-color:#eff7ff;
                        .hoverWrap{display: flex;justify-content: flex-end;}   
                    }   
                    &.on{background-color:#eff7ff;}   
                    &.on:hover{border:1px solid #1c90fb;background-color:#eff7ff;
                        .hoverWrap{display: flex;justify-content: flex-end;}
                    }   
                    .td{position:relative;min-height:42px;padding:2px 10px;font-size:12px;letter-spacing:-0.6px;border-left:1px solid #e6e6e6;box-sizing: border-box;                        
                        &:first-child{border-left:none;}   
                        &.cen{justify-content: center;align-items: center;}   
                        &.le{justify-content: center;align-items: flex-start;}   
                        &.ri{justify-content: center;align-items: flex-end;}
                        .dragItem{width:100%;
                            &:hover .dragHandler{display:block;}
                            // 드래그핸들러
                            .dragHandler{display:none;position: absolute;top:8px;left:0;width:16px;height:27px;background:url('../imgs/ico/ico_movebar.png') no-repeat center;cursor: ns-resize;animation-fill-mode: none;}   
                        }
                        .toggleSwitch{margin:0;padding:0;width:38px;height:16px;cursor: pointer;transition: 0.3s;    
                            &.on{background: url('../imgs/uc/btn_toggle_on.png') no-repeat center;background-size: 38px 16px;}    
                            &.off{background: url('../imgs/uc/btn_toggle_off.png') no-repeat center;background-size: 38px 16px;}    
                         }
                    }   
                    .hoverWrap{display:none;position:absolute;top:0;right:0;bottom:0;
                        .hoverBtn{height: 100%;padding: 0 20px 0 10px;align-items: center;background:linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 100%);   
                            .btn{width:18px;height:18px;margin:0 0 0 10px;cursor:pointer;   
                                img{vertical-align: unset;}
                                &:hover img{
                                    // 클래스가 아닌 아이콘 이미지는 필터로 over 처리
                                    filter: invert(42%) sepia(83%) saturate(1989%) hue-rotate(189deg) brightness(100%) contrast(97%);
                                }   
                            }   
                        }
                    }   
                }   
            }   
        }   
    }   
}
   
/**************************************************   
   pubGrid    
**************************************************/   
//Basic (기본)   
.gridTableBasic{   
    table{width:100%; box-sizing:border-box; table-layout: fixed;}   
    .gridTableBasicH{            
        thead{height:32px; background-color:#fafafa; border-top:2px solid #666;   
            th{border:solid #e6e6e6; border-width:0px 1px 1px 0px; text-align: center; letter-spacing: -0.6px; font-weight:bold;   
                &:last-child{border-right:none;}   
            }      
        }   
    }   
    .gridTableBasicB{   
        tbody{   
            td{height:32px; border-bottom:1px solid #e6e6e6; text-align: center;    
                &.con{text-align: left;   
                    em{min-width: 25px; height: 18px; padding: 0 6px; margin-right:5px; border-radius: 10px; background-color:#ff8787; color:#fff; text-align: center; font-size: 11px;}   
                    .text{cursor: pointer; overflow:hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis;}   
                    .file{background:url("../imgs/ico/ic_attach_s_normal.png") no-repeat left center; background-size: 14px 14px; padding-left:13px; color:#7b7b7b; margin-left:5px;cursor: pointer;}   
                }   
            }           
        }   
    }   
}   
   
// 데이터 없을 떄    
.gridTableBorder{border:solid #e6e6e6; border-width:0px 1px 1px 1px;}   
   
/**************************************************   
   pubGridPaging :: 페이징   
**************************************************/   
.PubGridPaging{padding-top: 20px;position: relative; // 디자인 정책에 따라 수정 230102
    .paging{line-height: 0;justify-content: center;   
        button{position: relative;width: 21px;height: 21px;background-color: transparent;border-width: initial;border-style: none;border-color: initial;border-image: initial;outline: none;   
            &.first{margin-right:3px;}   
            &.prev{margin-right:3px;}   
            &.next{margin-left:3px;}   
            &.last{margin-left:3px;}   
            &.num{width: 21px; height: 21px; padding: 0px 3px; font-size: 12px; font-family: 돋움, Dotum, Helvetica, "Apple SD Gothic Neo", sans-serif;letter-spacing: 0px; text-align: center; text-decoration: none; line-height: initial; vertical-align: top; color: #4a4a4a; border-radius: 0px; border: none; outline: none; background: transparent; box-sizing: border-box; cursor: pointer;    
                &.on{color: #1c90fb;}   
                &.on01{color: #1c90fb;border: 1px solid #1c90fb;background:#e9f5ff;}   
                &:hover{color: #1c90fb;border: 1px solid #1c90fb;background:#e9f5ff;}   
            }   
        }   
        [class*="OBTDropDownList_default"] {margin:0 0 0 5px;}   
        [class*="OBTDropDownList_default"] div div>span:first-child{   
            display: block;   
            overflow: hidden;   
            height: 19px!important;   
            padding: 0 24px 0 7px!important;   
            border: 1px solid rgb(219, 219, 219);   
            background: rgb(255, 255, 255);   
            line-height: 19px!important;   
        }   
        [class*="OBTDropDownList_default"] div div>button{   
            margin-top: -12px!important;   
            height: 21px!important;   
        }   
    }   
    .pagingBox{position: absolute; right: 0px; /*top: 10px;*/ line-height:18px; color:#8c8c8c; max-width: 150px;text-align: right;  
        .page_info > span{display: inline-block;   
            &.fwb{font-weight: 700; color: #000;}  
        }  
    }  
    .totalCount{color:#8c8c8c;
        em{color: #000;}
    }
}     
   
/**************************************************   
   AmaLink 쪽지 아마링크 팝업   
**************************************************/   
.AmaLink{position: relative; width: 100%; height: 100%; padding: 20px; box-sizing: border-box;
    //& > div:first-child{display:flex;flex-direction: column;flex:1 1;}   
    .title{font-size: 16px; font-weight:bold; letter-spacing: -0.8px;}   
    .profile{width: 24px; height: 24px; margin-right: 6px; border-radius: 50px; cursor: pointer;}   
    .userBox{align-items: center; margin-top: 10px; font-size: 13px;   
        .people{min-width:50px;margin-right: 20px; color: #000000;}   
        .user{margin-right: 5px; line-height: 24px; overflow: hidden; white-space: nowrap; word-break: break-all; text-overflow: ellipsis; color: #8c8c8c; cursor: pointer;}   
        .time{color: #8c8c8c;}   
        .btn{margin-left: 15px;}   
    }   
    .amaConBox{height: 100%; margin-top: 15px; padding: 20px 0; border-top: 1px solid #e6e6e6; box-sizing: border-box;word-break:break-all;   
        .con{height: 100%; font-size: 13px; color: #000000;   
            .msg{align-items: center; cursor: pointer;   
                img{width: 14px; height: 14px; margin-right: 4px;}   
                em{color: #1c90fb; background-color: #eff7ff; text-decoration: underline;}   
            }   
        }   
    }   
    .userlist{position: absolute; top: 109px; right: 23px; width: 180px; padding: 7px 0; border: 1px solid #a6a6a6; background-color: #ffffff; box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1); box-sizing: border-box;   
        .userInfo{align-items: center; padding: 6px 11px;    
            .name{max-width: 110px; font-size: 13px; line-height: 1.42; letter-spacing: -0.33px; overflow: hidden; white-space: nowrap; word-break: break-all; text-overflow: ellipsis; cursor: pointer;}   
            .time{font-size: 11px; color: #8c8c8c;}   
        }   
    }   
    &.secuMess{text-align: center; align-items: center;  
        .securityBg{width: 142px; height: 142px; margin-top: 90px; background: url('../imgs/bg/img_security_message@2x.png') no-repeat center center; background-size: 142px;}  
        strong{margin-top: 22px; font-size: 20px; font-weight:bold; letter-spacing: -0.5px;}  
        p{margin-top: 10px; font-size: 15px;}  
        .pwBox{align-items: center; justify-content: center; width: 396px; height: 55px; margin-top: 20px; border: 1px solid #e6e6e6;}  
    }  
}  
  
/**************************************************   
   wehagoLogin 위하고 로그인   
**************************************************/   
// 위하고 로그인 배너  
.wehagoLoginWrap {word-break:break-all;  
    [class*="banner-wehago"] {display:flex;align-items:center;width:100%;padding:15px 33px;border:1px solid #e2eaf8;border-radius:4px;background-image:linear-gradient(to right,#f2f6ff 2%,#e5eeff 97%);box-sizing:border-box;    
        > div {align-self:center;    
            &:first-child {display:inline-flex;align-items:center;flex:1;padding-right:30px;font-size:13px;color:#5c7198;letter-spacing:-0.65px;}    
            &:last-child button {height:30px;padding:0 17px;border:none;border-radius:15px;background:rgba(255, 255, 255, 0.8) !important;box-shadow:0 3px 6px 0 rgba(48, 103, 193, 0.2);    
                &:hover {border:none !important;}    
                span {font-size:11px !important;font-weight:bold;    
                    &:after {content:'';display:inline-block;margin-left:11px;border:4px solid transparent;border-right:none;border-left-color:#62666c;}    
                }    
            }    
        }    
    }    
    .banner-wehago {height:50px;padding-left:249px;padding-right:32px;background-image:url('../imgs/bg/img_wehago_login_left.png'), url('../imgs/bg/img_wehago_login_right.png'), linear-gradient(to right, #e4f4ff, #e6f6ff 64%, #ebf9ff);background-position:left top, right top;background-repeat:no-repeat;    
        > div {    
            &:first-child {padding-right:0;width:calc(100% - 249px);    
                > div {font-size:13px;color:#0c2b65;font-weight:bold;letter-spacing:-0.65px;    
                    &.cont {width:calc(100% - 102px);text-align:right;    
                        > div {display:inline-block;margin:0 20px;max-width:calc(100% / 3 - 41px);vertical-align:top;    
                            > div {vertical-align:top;}    
                            &:nth-child(1), &:nth-child(2), &:nth-child(3), &[class*="ico"] {/* 아이콘별 클래스명 추가 */    
                                &:only-child {max-width:calc(100% - 41px);}    
                                &:before {content:'';margin-right:8px;display:inline-block;width:26px;height:26px;border-radius:50%;background-color:rgba(163, 211, 255, 0.9);background-repeat:no-repeat;background-position:50% 50%;vertical-align:middle;}    
                                [data-orbit-component="OBTTooltip"] {overflow:hidden;width:auto;max-width:calc(100% - 34px);white-space:nowrap;text-overflow:ellipsis;line-height:25px;text-align:left;}    
                                em {margin-left:2px;font-size:12px;color:#5c7198;letter-spacing:-0.6px;font-weight:normal;}    
                            }    
                            //회사명    
                            &.ico-comp {    
                                &:before {background-image:url('../imgs/ico/ic_comp02_white_m_normal.png');}    
                                [data-orbit-component="OBTTooltip"] {max-width:calc(100% - 59px);}    
                            }    
                            //포인트    
                            &.ico-point {    
                                &:before {background-image:url('../imgs/ico/ic_point_white_m_normal.png');}    
                                [data-orbit-component="OBTTooltip"] {max-width:calc(100% - 91px);    
                                    > strong {padding-right:2px;}    
                                }    
                                > em {display:inline-block;width:32px;margin-left:0;}    
                            }    
                            //사용자    
                            &.ico-user {    
                                &:before {background-image:url('../imgs/ico/ic_user_white_m_normal.png');}    
                                [data-orbit-component="OBTTooltip"] {max-width:calc(100% - 34px);}    
                            }    
                        }    
                    }    
                }    
            }    
            &:last-child {display:flex;align-items:center;    
                p {margin-right:16px;font-size:13px;color:#5c7198;letter-spacing:-0.65px;}    
                button {    
                    span {display:flex;align-items:center;    
                    &:before {content:'';display:inline-block;width:14px;height:14px;margin-right:6px;background:url('../imgs/ico/ic_onoff_s_normal.png') no-repeat 0 0;}    
                    &:after {display:none;}    
                    }    
                    &:hover span {color:#1c90fb;    
                    &:before {background-image:url('../imgs/ico/ic_onoff_s_over.png');}    
                    }    
                }    
            }    
        }    
    }  
    .banner-floating {display:inline-block;    
        > .floating-panel {margin-top:5px;padding:10px 12px 6px 12px;border:1px solid #a6a6a6;border-radius:3px;background:#fff;    
           &:before, &:after {position:absolute;left:50%;content:'';display:inline-block;width:0;height:0;border-style:solid;border-width:5px 5px 7px 5px;margin-left:-5px;}    
           &:before {top:-12px;border-color:transparent transparent #777 transparent;}    
           &:after {top:-11px;border-color:transparent transparent #fff transparent;}    
           > div > div:first-child {position:relative !important;overflow-x:hidden !important;max-height:220px;margin-bottom:0 !important;    
              & + div {display:none;}    
           }    
           .radio1{padding-bottom:4px;    
            > div{text-align:left;    
                > span{min-width:170px;margin-bottom:4px !important;padding:9px 12px;border:1px solid #e0e0e0;border-radius:3px;box-sizing:border-box;    
                    &:last-child {margin-bottom:0 !important;}    
                    &:hover, &.on {border:1px solid #1c90fb;background:#eff7ff;box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);}    
                    input ~ svg, input ~ span {left:12px !important;}    
                    }    
                }  
            }    
        }    
    }  
}  

//세션컨펌알림
.sessionConfirm{position: relative;
    [data-orbit-component="OBTTooltip"]{
        &.cancle{
            [data-orbit-component="OBTFloatingPanel"] {top: 190px !important; left: 75px !important;}
        }
        &.save{
            [data-orbit-component="OBTFloatingPanel"] {top: 190px !important; left: 110px !important;}
        }
    } 
}

// 위하고 로그인 팝업
.wehagoLoginPopup{  
    p {font-size:15px;line-height:1.5;}  
    .heading {min-height:180px;padding:43px 30px;box-sizing:border-box;  
        > strong {display:inline-block;margin-bottom:12px;font-size:24px;line-height:36px;letter-spacing:-1.2px;}  
        &.t1, &.t2, &.t3{background-repeat:no-repeat;background-position:100% 100%;}  
        &.t1 {background-image:url('../imgs/bg/img_wehago_login.png'), linear-gradient(to right, #eff8fe 100%, #d9f0ff 0%);}  
        &.t2 {background-image:url('../imgs/bg/img_taxinvoice.png'), linear-gradient(to right, #eff8fe 100%, #d9f0ff 0%);}  
        &.t3 {background-image:url('../imgs/bg/img_buliding.png'), linear-gradient(to right, #eff8fe 100%, #d9f0ff 0%);}  
    }  
    .content {padding:20px 30px 30px;text-align:center;  
        > p + [class*="choice"] {margin-top:12px;}  
        .btn button {margin-top:20px;padding:0 16px;height:32px;font-size:15px;border-color:#1c90fb;color:#fff;background-image:linear-gradient(to top, #1c90fb, #5eb5fd);border-radius:2px;border:1px solid #1c90fb;outline:none;  
            &:hover {border:1px solid #136ad5;background-image:linear-gradient(0deg, #3280e0, #3ba1ff);}  
            &:active {background-color:#1c90fb;background-image:linear-gradient(180deg,#3280e0,#3ba1ff);}  
        }  
    }  
    .choice{padding:19px 20px;background:#f5f7f9;border-radius:10px;  
        input {position:absolute;top:-100px;left:-100px;  
            & + label {position:relative;display:inline-block;padding-left:20px;margin-right:30px;line-height:20px;letter-spacing:-0.3px;  
                &:before, &:after {content:'';position:absolute;display:inline-block;box-sizing:border-box;}  
                &:before {top:3px;left:0;width:14px;height:14px;border-radius:50%;border:1px solid #a6a6a6;background-color:#fff;}  
                &:after {top:7px;left:4px;width:6px;height:6px;border-radius:50%;background-color:#fff;}  
            }  
            &:checked + label {  
                &:before {border:1px solid #1e91fb;}  
                &:after {background-color:#1e91fb;}  
            }  
        }  
          
    }  
}

// 업무관리 프로젝트 선택 팝업 (모든 모듈에서 호출되는 팝업)
.UBAP048{    
    .GridType01{
        .gridHeader{
            th{height: 33px; padding: 0; box-sizing: border-box;}
        }
        .gridBody{
            td{height: 33px; padding: 0 5px; border-right: 1px solid #e6e6e6; box-sizing: border-box;
                &:last-child{border-right: none;}            
            }
        }
    }
    .btnBox{width: 47px;justify-content: center;align-items: center;padding-top:37px;} 
 }

 // 주소검색기능 팝업
 .UAAP020{
    .right_div{
        [data-orbit-component="OBTRadioButtonGroup"] > div > span{
            &:last-child{margin-right:0px !important;}
        }
    }
    .pubAvbStyle{
        [class*="OBTConditionItem_label"]{min-width: 45px;}
        .ml60{margin-left:60px;}
    }    
    .gridBody{
        .arrowBtn{width:40px; display: inline-flex; justify-content: center; align-items: center;
            .down{background:url("../imgs/ico/ic_arrow_down_01_s_normal.png") no-repeat center; display:inline-block; cursor: pointer; width:14px; height:14px;
                &:hover{background:url("../imgs/ico/ic_arrow_down_01_s_over.png") no-repeat center;}
            }
            .up{background:url("../imgs/ico/ic_arrow_up_01_s_normal.png") no-repeat center; display:inline-block; cursor: pointer; width:14px; height:14px;
                &:hover{background:url("../imgs/ico/ic_arrow_up_01_s_over.png") no-repeat center;}
            }      
        } 
        em{color:#1c90fb}
    }
 }

/**************************************************   
   lottieImage 로티 이미지  
**************************************************/   
.LottieImage{
    .lottieLoading{padding: 0 0 20px 0; text-align: center;
        &.border{border: 1px solid #e6e6e6;}
        .title{font-size: 16px; font-weight:bold;}
        .text{margin-top: 4px; font-size: 12px; color: #8c8c8c;}
    }
    .box{margin-left: 20px; padding: 20px; border: 1px solid #e6e6e6; box-sizing: border-box;
        .boxTit{font-weight:bold; margin-bottom: 10px;}
        pre{width: 100%; background-color: #eeeeee;}
    }
}

/**************************************************   
   Filter 필터
**************************************************/   
.filterWrap{position: relative;
    .filterBtn01, .filterBtn02{background: url("../imgs/ico/ic_arrow_down_02_s_normal@2x.png") no-repeat center right; background-size: 14px; padding-right: 15px; cursor: pointer;
        &:hover{background: url("../imgs/ico/ic_arrow_down_02_s_over@2x.png") no-repeat center right; background-size: 14px;} 
        &.on{background: url("../imgs/ico/ic_arrow_up_02_s_normal@2x.png") no-repeat center right; background-size: 14px; 
            &:hover{background: url("../imgs/ico/ic_arrow_up_02_s_over@2x.png") no-repeat center right; background-size: 14px;} 
        } 
    }
    // 공통
    dl{
        dt{padding: 0 0 1px 10px; border-bottom: 1px solid #f0f0f0; font-weight:bold; font-size: 12px;}
        dd{border-bottom: 1px solid #f0f0f0;
            &:last-child{border-bottom: none;}
        }
    }
    ul{
        li{font-size:12px;  padding: 0 0 0 22px; line-height: 27px; box-sizing: border-box; cursor: pointer;
            &:hover{background-color: #e6f5ff;
                span{
                    &.up{background: url('../imgs/ico/ic_ascending_order_m_over@2x.png') no-repeat right; background-size: 18px;}
                    &.down{background: url('../imgs/ico/ic_descending_order_m_over@2x.png') no-repeat right; background-size: 18px;}
                }
            }
            &.chk{color: #1c90fb; background: url('../imgs/ico/ic_check_01_s_over@2x.png') no-repeat 5px 5px; background-size: 14px;
                &:hover{color: #1c90fb; background: #e6f5ff url('../imgs/ico/ic_check_01_s_over@2x.png') no-repeat 5px 5px; background-size: 14px;}
            }
            &.on{color: #1c90fb;background-color: #e6f5ff;}
            &.normal{color: #000000; padding-left: 10px;
                &:hover{color: #1c90fb;}
                &.normalOn{color: #1c90fb;}
            }
            span{display: block; padding-right: 20px; margin-right: 10px; line-height: 24px; white-space: nowrap;
                &.up{background: url('../imgs/ico/ic_ascending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}
                &.down{background: url('../imgs/ico/ic_descending_order_m_normal@2x.png') no-repeat right; background-size: 18px;}
                &.imc{display: inline-block; padding-right: 0; margin-right: 5px; width: 10px; height: 10px; border-radius: 50%; background-color: #e6e6e6;   
                    &.basic, &.uc{background-color: #1c90fb;}   
                    &.user {background-color: #67ccb5;}   
                    &.erp {background-color: #20c997;}   
                    &.erp2 {background-color: #f48da5;} 
                }
            }
        }   
    }
    .filterFn{min-width: 150px; margin: 5px; padding: 5px 0 1px 5px; border-top: 1px solid #f0f0f0; box-sizing: border-box;
        .btnRefresh{font-size:11px;  color:#7c7c7c; padding:0 0 0 18px; background: url("../imgs/ico/ic_reset_s_normal.png") no-repeat left top 2px; cursor: pointer;
            &:hover{color:#1c90fb;background: url("../imgs/ico/ic_reset_s_over.png") no-repeat left top 2px;}
        }
    }
    .filterList01{position: absolute; top: 0; right: 0; min-width: 100px; height: auto; border: 1px solid #a6a6a6; background-color: #ffffff; box-sizing: border-box; z-index: 10;}
    .filterList02{position: absolute; top: 0; right: 0; min-width: 168px; border: 1px solid #cccccc; border-radius: 2px; background-color: #ffffff; transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; box-sizing: border-box; -webkit-tap-highlight-color: #000000; box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; opacity: 0; transform: scaleY(0); transform-origin: left top; max-height: 841px; overflow-y: auto; z-index: 11;
        &.listOn{opacity: 1; transform: scaleY(1);}
        dt{font-size:12px;font-weight:bold;line-height:21px; padding: 0 0 0 10px;}
        dd{font-size:12px;line-height:21px;}
    }
}

/**************************************************   
   Amaranth10 약관동의
**************************************************/  
.agreePop{
    .header{text-align: center; margin: 20px 0;
        h1{font-size: 18px; font-weight:bold; padding:0 0 5px 0; letter-spacing: -0.9px;}
        .sText{color: #a6a6a6;}
    }

    .wrap-content{
        .total{border-bottom: solid 1px #e6e6e6; box-sizing: border-box; font-size: 14px;}
        .conBox{padding:20px; background-color: #f9fafc;
            .tabBox{padding: 13px 20px 12px; border-radius: 4px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); background-color: #fff; margin-bottom:8px;
                &:last-child{margin-bottom:0px;}
            }  
        } 
    }   
    
    .detailBox{border-radius: 4px; border: solid 1px #e6e6e6; padding:30px 0px 0px 30px;
        .detailCon{padding-right:30px;
            .line{padding:0 0 20px 0; border-bottom:1px solid #e6e6e6;}
            dl{padding-top:20px;
                &:last-child{padding-bottom:30px;}
                dt{font-size: 14px; font-weight:bold; letter-spacing: -0.35px; padding:0 0 5px 0;}
                dd{color: #4a4a4a;line-height: 18px; padding-left:11px;
                    em{color: #1c90fb; font-weight:bold;}
                }
            }
        }       
    }
}

/**************************************************    
   쪽지보내기 
**************************************************/   
.msgSend{ 
    .receive_tit{width: 85px;height: 60px;line-height: 60px;text-align: center;font-size: 12px;font-weight:bold;} 
    .btnImg{margin:0 0 0 4px !important;} 
    .textBox textarea{padding:10px !important;} 
}

/**************************************************     
   첨부파일 이력조회  
**************************************************/    
.UAAP018{ 
    .fileBox{padding:0 10px;height:42px;border:1px solid #e6e6e6;box-sizing:border-box;font-size:12px;color:#000000; 
        .name{padding:0 0 0 20px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;} 
        .ext{min-width:max-content;} 
        .volume{padding:0 0 0 5px;min-width:max-content;} 
    } 
    .fileTooltipFix{max-width:500px;} 
    .fileHistory{margin: 10px 0 0 0;border-style: solid;border-color: #666666 transparent #a6a6a6 transparent;border-width: 2px 0 1px 0; 
        ul{ 
            li{padding:0 20px;height:54px;border-top:1px solid #e6e6e6;box-sizing:border-box; 
                &:first-child{border:none;} 
                .ico{min-width:28px;min-height:28px;width:28px;height:28px; 
                    &.preview{background:url('../imgs/ico/ic_notice_preview@2x.png') no-repeat center;background-size:28px;} 
                    &.download{background:url('../imgs/ico/ic_notice_download@2x.png') no-repeat center;background-size:28px;} 
                    &.onechamber{background:url('../imgs/ico/ic_notice_onechamber@2x.png') no-repeat center;background-size:28px;} 
                } 
                .name{margin:0 0 0 10px;min-width: 35px;max-width: 35px;height: 20px;line-height: 20px;font-size:13px;letter-spacing:-0.65px;color:#000000;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;cursor:pointer;} 
                .line{margin:0 8px;width:1px;height:10px;background-color: #e6e6e6;}    
                .text{font-size:13px;letter-spacing:-0.65px;color:#000000;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;} 
                .date{margin:0 0 0 10px;font-size:12px;color:#a6a6a6;} 
                .webIco{margin:0 0 0 10px;width:14px;height:14px;min-width:14px;min-height:14px;background:url('../imgs/ico/ic_web_s_normal@2x.png') no-repeat center;background-size:14px;} 
                .mobileIco{margin:0 0 0 10px;width:14px;height:14px;min-width:14px;min-height:14px;background:url('../imgs/ico/ic_mobile_s_normal@2x.png') no-repeat center;background-size:14px;} 
            } 
        } 
    } 
}

/*****************************    
    대시보드   
******************************/   
.dashboardBox{
    &.data-area{overflow:hidden;position:relative; padding:20px 0 20px 20px !important;background-color: #f0f5fb;box-sizing:border-box;
       &.admin{background-color: #e7eff0;}    
       &:before {content:'';position:absolute; height:230px; background-repeat:no-repeat;background-position:0 100%;background-size:contain;}    
       &:after {content:'';position:absolute; height:230px; background-repeat:no-repeat;background-position:0 100%;background-size:contain;}    
       & > div {position:relative;display:inline-flex;height:190px;padding:20px 40px;border-radius:6px;background:rgba(255,255,255,0.7);box-shadow:0 3px 10px 0 #dbe4eb;box-sizing:border-box;z-index:1;}    
    } 
} 

/*****************************    
    Status (상태)   
******************************/   
.statusLabel{height: 18px; padding: 0 6px; font-size: 11px; line-height: 17px; text-align: center; border-radius: 9px; box-sizing: border-box;
    &.small{height: 14px !important; line-height: 14px !important;}
    &.type1{color: #ffffff; background-color: #20c997;}
    &.type2{color: #ffffff; background-color: #2dbcb5;}
    &.type3{color: #ffffff; background-color: #39b0d2;}
    &.type4{color: #ffffff; background-color: #46a3f0;}
    &.type5{color: #ffffff; background-color: #ff8787;}
    &.type6{color: #ffffff; background-color: #f8a457;}
    &.type7{color: #ffffff; background-color: #f0c325;}
    &.type8{color: #ffffff; background-color: #c8b465;}
    &.type9{color: #ffffff; background-color: #9da3aa;}
    &.type10{color: #919191; background-color: #e2e2e2;}
}
.proceIco{font-size: 12px; padding: 0 4px 0 19px;
    &.ok{color: #20c997; background: url('../imgs/ico/ico_confirm_03.png') no-repeat left center; background-size: 16px;} 
    &.confirm01{color: #2dbcb5; background: url('../imgs/ico/ico_confirm_02.png') no-repeat left center; background-size: 16px;} 
    &.confirm02{color: #39b0d2; background: url('../imgs/ico/ico_confirm_01.png') no-repeat left center; background-size: 16px;} 
    &.confirmPro{color: #46a3f0; background: url('../imgs/ico/icon_confirm_inpro.png') no-repeat left center; background-size: 16px;}
    &.pro{color: #46a3f0; background: url('../imgs/ico/ico_inpro.png') no-repeat left center; background-size: 16px;}
    &.returnPro{color: #46a3f0; background: url('../imgs/ico/icon_return_inpro.png') no-repeat left center; background-size: 16px;}
    &.return{color: #ff8787; background: url('../imgs/ico/ico_return.png') no-repeat left center; background-size: 16px;}
    &.cancelRed{color: #ff8787; background: url('../imgs/ico/ic_cancel_red.png') no-repeat left center; background-size: 16px;}
    &.error01{color: #ff8787; background: url('../imgs/ico/ico_error_01.png') no-repeat left center; background-size: 16px;}
    &.error{color: #f8a457; background: url('../imgs/ico/ico_error.png') no-repeat left center; background-size: 16px;}
    &.hold{color: #f8a457; background: url('../imgs/ico/ico_hold_02.png') no-repeat left center; background-size: 16px;}
    &.fail{color: #f8a457; background: url('../imgs/ico/ico_fail.png') no-repeat left center; background-size: 16px;}
    &.write{color: #f8a457; background: url('../imgs/ico/ico_write.png') no-repeat left center; background-size: 16px;}
    &.pend{color: #f0c325; background: url('../imgs/ico/ico_pend.png') no-repeat left center; background-size: 16px;}
    &.later{color: #f0c325; background: url('../imgs/ico/ic_sign_later_yellow.png') no-repeat left center; background-size: 16px;}
    &.save{color: #c8b465; background: url('../imgs/ico/ico_save.png') no-repeat left center; background-size: 16px;}
    &.cancelBrown{color: #c8b465; background: url('../imgs/ico/ic_cancel_brown.png') no-repeat left center; background-size: 16px;}
    &.face{color: #c8b465; background: url('../imgs/ico/ic_face_yellow.png') no-repeat left center; background-size: 16px;}
    &.holdGray{color: #9da3aa; background: url('../imgs/ico/ico_hold.png') no-repeat left center; background-size: 16px;}
    &.reference{color: #9da3aa; background: url('../imgs/ico/ic_reference_gray.png') no-repeat left center; background-size: 16px;}
    &.failGray{color: #9da3aa; background: url('../imgs/ico/ico_docu_del.png') no-repeat left center; background-size: 16px;}
    &.confirmGray{color: #919191; background: url('../imgs/ico/ic_confirm_gray.png') no-repeat left center; background-size: 16px;}
    &.exp{color: #919191; background: url('../imgs/ico/ic_expected_gray.png') no-repeat left center; background-size: 16px;}
}
.statusBullet{position: relative; display: flex; align-items: center; font-size: 12px;
    &::before{content: ''; border-radius: 50%;}
    &.s2::before{width: 2px; height: 2px; margin-right: 4px;}
    &.s4::before{width: 4px; height: 4px; margin-right: 2px;}
    &.s6::before{width: 6px; height: 6px; margin-right: 4px;}
    &.s10::before{width: 10px; height: 10px; margin-right: 5px;}
    &.type1{color: #20c997;
        &::before{background-color: #20c997;}
    }
    &.type2{color: #2dbcb5;}
        &::before{background-color: #2dbcb5;}
    &.type3{color: #39b0d2;
        &::before{background-color: #39b0d2;}
    }
    &.type4{color: #46a3f0;
        &::before{background-color: #46a3f0;}
    }
    &.type5{color: #ff8787;
        &::before{background-color: #ff8787;}
    }
    &.type6{color: #f8a457;
        &::before{background-color: #f8a457;}
    }
    &.type7{color: #f0c325;
        &::before{background-color: #f0c325;}
    }
    &.type8{color: #c8b465;
        &::before{background-color: #c8b465;}
    }
    &.type9{color: #9da3aa;
        &::before{background-color: #9da3aa;}
    }
    &.type10{color: #e2e2e2;
        &::before{background-color: #e2e2e2;}
    }
    &.type11{color: #a6a6a6;
        &::before{background-color: #818181;}
    }
}

/*****************************    
    oneAi   
******************************/  
// ONE AI 퀵버튼 
.oneaiBtn {
    position: absolute; width: 50px; height: 50px; z-index: 10; user-select: none; border-radius: 25px; cursor: pointer;
    background: #fff url("../imgs/ico/ic_oneai@2x.png") no-repeat center; background-size: 50px;
    transition: width 0.5s; box-shadow: 0 5px 10px 0 rgba(129, 144, 172, 0.3);
    &::before { content: ""; display: block; width: 50px; height: 50px; border-radius: 25px; position: absolute; left: 50%; top: 50%;
        transform: translate(-50%, -50%); z-index: 10; transition: all 0.5s; transition-property: width; transition-duration: 0.5s;
      }
    &:hover {position: absolute; border-radius: 26px; width: 129px; height: 52px;
        background-image: linear-gradient(95deg, #2b9dff -19%, #7f6eff 63%, #e557ff); background-size: 100%;
        &::before {width: 127px; background:#fff url("../imgs/ico/img_lg_ai_type@3x.png")  no-repeat center; background-size: 93px 32px;}
    }
}

// ONE AI 타이틀 버튼
.btn_oneAi { background:url("../imgs/ico/ic_oneai_s@2x.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important; background-size: 18px !important;}                  
.btn_oneAi:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_oneai_s@2x.png") no-repeat left 4px center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important; background-size: 18px !important;}    
[class*="OBTButton_disabled"].btn_oneAi{border: solid 1px #cccccc !important; background:#f5f5f5 url("../imgs/ico/ic_oneai_s@2x.png") no-repeat center center !important; background-size: 18px !important;}   
.btn_oneAi.on {border: solid 1px #1c90fb !important; background:url("../imgs/ico/ic_oneai_s@2x.png") no-repeat center center, linear-gradient(to top, #a0d6fe, #e6f5ff) !important; background-size: 18px !important;}     
input.btn_oneAi {border:1px solid #ccc !important; border-radius: 2px;}   
input.btn_oneAi:hover {border: solid 1px #a6a6a6 !important;}

// ONE AI 이미지 + 라벨텍스트
.btn_img_oneAi > div > img{width: 18px; height: 18px;}

// oneAi CheckBox
.oneAiCheckBox{margin:0 0 0 0;
    & > div > [class*="OBTCheckBox2_checkIcon"]{background:url('../imgs/ico/checkbox_oneAi_non.png') no-repeat center/100% !important}
    & > div > [class*="OBTCheckBox2_defaultChecked"]{background:url('../imgs/ico/checkbox_oneAi_checked.png') no-repeat center/100% !important}
    & > div > [class*="OBTCheckBox2_defaultDisabled"]{background:url('../imgs/ico/checkbox_oneAi_disa.png') no-repeat center/100% !important}
}

//oneAi blue 버튼
.oneAiBlueBtn{
    color: #ffffff !important;
    border: none !important;
    border-radius: 6px !important;
    box-shadow: 0 2px 4px 0 rgba(101, 122, 255, 0.24) !important;
    background: linear-gradient(283deg, #4daeff, #7458ff) !important;
    &:hover{background: linear-gradient(283deg, #4494db, #634fdb) !important;}
    &[class*="OBTButton_disabled"]{box-shadow: none !important;background-image: none !important;background-color: #d9d9d9 !important;}
    font-size:14px;
}
.oneAiGrayBtn{
    color: #a6a6a6 !important;
    border: none !important;
    border-radius: 6px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16) !important;
    background: #f5f5f5 !important;
    &:hover{background: #f5f5f5 !important;}
    &[class*="OBTButton_disabled"]{box-shadow: none !important;background-image: none !important;background-color: #d9d9d9 !important;}
    font-size:14px;
    cursor:not-allowed;
}

// oneAiPanel Layer 
.oneAiPanel{
    [class*="OBTDockPanel_right"]{margin-left: 0 !important; padding-left: 0 !important;}
    [class*="OBTDockPanel_right"] > div{margin-left:4px;}

    .oneAiLayer{
        .layerWarp{display:flex; background:linear-gradient(to bottom, #fff, #f7fcfe 46%, #d9e0f8 122%); padding:15px 20px 0 20px;
            .headWarp{display:flex; align-items: center;
                .title{font-size: 14px; font-weight:bold; letter-spacing: -0.7px; color: #000;}
                .btnClose{background:url('../imgs/ico/ic_btn_close_normal@2x.png') no-repeat center center; background-size: 24px;}
            }
            .conWarp{margin-top:30px;
                .conBox{padding-top:24px;
                    &:first-child{padding-top:0px;}
                    dt{letter-spacing: -0.6px; color: #000; margin-bottom:10px; font-weight:bold;}
                    dd{padding-bottom:8px; color: #000;
                        &:last-child{padding-bottom:0px;}
                    }
                }
            }
        }
        .oneAibtnBox{background-color:#fff; padding:14px 20px 18px 20px;
            > [class*="OBTTooltip_root"]{width:100%;
                [class*="OBTButton_labelText"]{font-size:14px;}
            }  
            .line{margin-right:10px; width:1px; height:15px; background-color:#e6e6e6;}
            .oneAiGrayBtn{align-items: center; display: flex; justify-content: center; padding: 0 10px;
                [class*="OBTButton_wrapper"] img {width:24px; height:24px;}
            }
        }       
    }    
}

// ONE AI 버튼 구분선
.oneAiBtnLine{position: relative; padding-right: 20px;
    &::after{content: ''; display: block; position: absolute; top: 50%; right: 10px; transform: translateY(-50%); width: 1px; height: 16px; background-color: #e6e6e6;}
}

// ONE AI 인터렉션 버튼
.oneAiLottie{position: relative; z-index: 1;
    .oneAiLottieBox{position: relative; z-index: 10; top: 0; right: 0; height: 30px; cursor: pointer;}
}

// ONE AI 검색창
.oneAiSearchWarp{
    // 타이틀
    .oneAiTitle{font-size: 15px; font-weight: bold; line-height: 18px; color: #000; padding-bottom:10px; border-bottom:1px solid #afb6c4; box-sizing: border-box; margin:20px 0 14px 0;
        em{color: #5f65ff; margin-left:6px;}
        &:first-child{margin-top:0px;}
    }

    // 중간 알림성 텍스트
    .oneAiAlertText{margin: 0 0 10px 0;font-size: 13px;font-weight: normal;line-height: 20px;color: #000;}

    // 컨텐츠 영역
    .oneAiComp{
        // form
        .oaForm{border:1px solid #e0e0e0;border-radius:6px;background-color:#ffffff;box-sizing:border-box;overflow:hidden;
            table{width:100%;table-layout:fixed;
                tr{border-top:1px solid rgba(175,182,196,0.15);
                    &:first-child{border-top:none;}
                    th{height:38px;padding:10px 14px;font-size:12px;font-weight:bold;letter-spacing:-0.3px;color:#0b0c0e;text-align:left;background-color:#f4f5f6;box-sizing:border-box;}
                    td{height:38px;padding:10px 14px;font-size:12px;font-weight:normal;letter-spacing:-0.3px;color:#0b0c0e;text-align:left;background-color:#ffffff;box-sizing:border-box;}
                }
            }
        }
        // grid
        .oaGrid{border:1px solid #e0e0e0;border-radius:6px;background-color:#ffffff;box-sizing:border-box;overflow:auto;
            table{width:100%;table-layout:fixed;
                tr{border-top:1px solid rgba(175,182,196,0.15);
                    th{height:38px;padding:10px 7px;font-size:12px;font-weight:bold;letter-spacing:-0.3px;color:#0b0c0e;text-align:center;background-color:#f4f5f6;box-sizing:border-box;}
                    td{height:38px;padding:10px 7px;font-size:12px;font-weight:normal;letter-spacing:-0.3px;color:#0b0c0e;text-align:center;background-color:#ffffff;box-sizing:border-box;
                        &.left{text-align:left;}
                        &.center{text-align:center !important;}
                        &.right{text-align:right;}
                    }
                }
            }
        }
    }

    // 버튼
    .oneAiBtn{margin:14px 0 0 0; display: flex; align-items: center; justify-content: center;
        > button{margin-right:6px;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(101, 122, 255, 0.24);
            background-image: linear-gradient(to left, #4daeff, #7458ff);
            border:none;
            outline:none;
            padding: 4px 10px 5px;
            color:#fff;
            &:last-child{margin-right:0px;}
        }
    }
}

/*****************************    
    공통 :: 아마링크
******************************/
.keepLink{position: relative;display: inline-block;height: 18px;margin: 0 5px 0 0;padding: 0 0 0 20px;cursor: pointer;max-width: 200px;vertical-align: middle;    
    img{position: absolute;top: 0;left: 0;width: 18px;height: 18px;}    
    .text{width:100%;font-size:12px;font-family:auto;line-height:19px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
    &.small{position: relative;display: inline-block;height: 15px;margin: 0 5px 0 0;padding: 0 0 0 15px;cursor: pointer;max-width: 180px;vertical-align: middle;    
        img{position: absolute;top: 2px;left: 0;width: 11px;height: 11px;}    
        .text{width:100%;font-size:11px;font-family:auto;line-height:15px;color: #1c90fb;text-decoration: underline #1c90fb;background-color: rgba(28, 144, 251, 0.07);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}    
    }   
}

/*****************************    
    공통 :: oneai 옵션화 팝업
******************************/
.newPortalOneaiPopup{position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); display: flex; align-items: center; position:relative; z-index: 3000; width:660px; height:440px; background: url('../imgs/bg/bg_oneai_Introduction@2x.png') no-repeat left top; background-size: 660px 440px;
    .clo{align-items: center; display: flex; height: 50px; justify-content: flex-end; width: 100%; margin-right: 27px;}
    .Badge{border-radius: 15px; border: solid 1px #807eff; box-sizing: border-box; padding: 5px 20px; text-align: center; letter-spacing: -0.42px; font-size: 14px; font-weight: bold; color: #624dfc;}
    .text{padding-top:30px; letter-spacing: -0.48px; color: #000; font-size: 16px;}
    .title{align-items: center; padding-top:1px; color: #000126; letter-spacing: -1.02px; font-size: 34px; font-weight: bold;
        .oneaiLogo{width:140px; height:42px; margin:0 8px; background: url('../imgs/ico/lg_oneai_42@2x.png') no-repeat left center; background-size: 140px 42px;}
    }
    .text2{font-size:16px; letter-spacing: -0.4px; color: #000; padding-top:5px;
        em{color: #a143ff; font-weight: bold;}
    }
    .telBox{display: flex; justify-content: center; align-items: center; width: 540px; height: 96px; margin-top:30px; font-weight: bold;
        .icoBox{align-items: center; display: flex; height:50px; font-size: 20px; letter-spacing: -0.6px; color: #8c8c8c; padding:0 31px 0 70px; background: url('../imgs/ico/ic_buying_advice@4x.png') no-repeat left center; background-size: 50px 50px;}
        .number{font-size: 24px; line-height: 1.13; color: #000;}
    }
    .btnBox{margin:30px 0 0 0;
        > p{cursor:pointer; padding:10px 30px; border-radius: 4px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); background-image: linear-gradient(112deg, #5895ff 1%, #8f74ff 97%); font-size: 14px; color: #fff; letter-spacing: -0.35px; font-weight: bold;
            &:hover, &:active{background-image: linear-gradient(112deg, #4876cc 1%, #715ecc 97%);}
        }
    }
}

// [공통] 프로필 공개여부 아이콘 (이승환 주임 요청 추가본)
.watchIcon{width:18px;height:18px;cursor: pointer;
    &.on{background:url('../imgs/ico/ic_watch_m_over@2x.png') no-repeat center;background-size:18px;}    
    &.off{background:url('../imgs/ico/ic_watch_cancel_m_normal@2x.png') no-repeat center;background-size:18px;}    
    &.disabled{background:url('../imgs/ico/ic_watch_cancel_m_disable@2x.png') no-repeat center;background-size:18px;cursor: default;}    
}

/*****************************    
    OBT컴포넌트 커스텀
******************************/
//OBTDialog 스크롤바 커스텀
.OBTDialogScrollbar{
    [class*="OBTDialog_data"]{overflow: hidden !important;    
       [class*="OBTDialog_dataWrapper"]{overflow: auto !important; 
            &::-webkit-scrollbar {width:4px;}
            &::-webkit-scrollbar-track {background-color:none;}
            &::-webkit-scrollbar-thumb {background-color:#d4d5d7;}
            &::-webkit-scrollbar-button {display: none;}
            &::-webkit-scrollbar-thumb:hover {background-color:none;}
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-thumb {border-radius: 2px;}
       }      
    }
}

/************************************************** 
   공통:: 장소검색 팝업 
**************************************************/ 
.placeSearchPop{
    .addList{ border:1px solid #e0e0e0; height:100%; 
        .topBox_sw {padding:8px 10px; border-bottom:1px solid #e0e0e0; background: #fafafa;}   
        .sw_div {border-bottom:1px solid #e0e0e0;padding:10px 8px; cursor:pointer; 
            dl { 
                dt{line-height: 25px;font-weight: bold;} 
                dt:hover,dd:hover,dd.on{text-decoration:underline;} 
            } 
            &.on,&:hover {background: #e9f5ff;} 
        }     
    } 
    .pagingBox{height: 40px; background-color: #fafafa; box-sizing: border-box; display: flex; justify-content: center; border-top: solid 1px #e6e6e6; margin-top: -1px;}
    .mapView { border:1px solid #e6e6e6; box-sizing: border-box;
        .mapDiv {width: 100%; overflow:auto; } 
        .mapBtm {border-top:1px solid #e6e6e6;height:40px; 
            .navi{line-height: 27px;margin:6px 0 0 6px;} 
        } 
    } 
} 

/************************************************** 
   공통:: 특화 타이틀 컴포넌트화
**************************************************/ 
// 타이틀 영역
.titleWrap{
    [class*='OBTPageContainer_titleText']{margin-right: 8px;}
    [class*='OBTPageContainer_onlineManual']{margin-left: 0 !important;}
    .titleRefresh{width: 18px; height: 18px; background: url('../imgs/ico/ic_refresh_m_normal@2x.png') no-repeat center center; background-size: 18px; vertical-align: middle;
        &:hover{background:url('../imgs/ico/ic_refresh_m_over@2x.png') no-repeat center center; background-size: 18px;}   
    }

    // 퍼블리싱 특화
    .title{margin-right: 8px;}
    > [class*='OBTTooltip_root']{line-height: 1;}
}
// 상세검색    
.searchDivWrap{gap: 4px;}
.searchDetailWarp{padding: 20px; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;
    .searchDetail{position: relative; flex-wrap: wrap; gap: 8px 50px; margin-right: 4px;
        dl{display: inline-flex;
            dt{min-width: 47px; text-align: right; margin: 0 10px 0 0; line-height: 27px;}   
            dd{min-width: 178px; gap: 4px;
                .fullPicker{   
                    > div {width: 100% !important;}   
                 }   
            }   
        }
    }
    .btnImgTxt{padding-left: 25px !important;
        &.btn_search{height: 100%; max-height: 62px; background-position: left 6px center !important;}
    }
}

/************************************************** 
   공통:: 아이콘
**************************************************/ 
.commonIco{
    .watch_s{min-width:14px; min-height: 14px; background: url('../imgs/ico/ic_watch_s_normal@2x.png') no-repeat center; background-size: 14px;}
    .attach_s{min-width:14px; min-height: 14px; background: url('../imgs/ico/ic_attach_s_normal@2x.png') no-repeat center; background-size: 14px;}
    .comment_s{min-width:14px; min-height: 14px; background: url('../imgs/ico/ic_comment_s_normal@2x.png') no-repeat center; background-size: 14px;}
    .like_s{min-width:14px; min-height: 14px; background: url('../imgs/ico/ic_like_01_s_normal_red@2x.png') no-repeat center; background-size: 14px;
        &.on{background: url('../imgs/ico/ic_like_02_s_normal_red@2x.png') no-repeat center; background-size: 14px;}
    }
    .agree_s{min-width:14px; min-height: 14px; background: url('../imgs/ico/ic_grid_agree@2x.png') no-repeat center; background-size: 14px;
        &.on{background: url('../imgs/ico/ic_grid_agree_on@2x.png') no-repeat center; background-size: 14px;}
    }
    .disagree_s{min-width:14px; min-height: 14px; background: url('../imgs/ico/ic_grid_disagree@2x.png') no-repeat center; background-size: 14px;
        &.on{background: url('../imgs/ico/ic_grid_disagree_on@2x.png') no-repeat center; background-size: 14px;}
    }
    .new_s{min-width:14px; min-height: 14px; background: url('../imgs/ico/ic_grid_new@2x.png') no-repeat center; background-size: 14px;}
    .newpage_s{min-width:14px; min-height:14px; background: url('../imgs/ico/ic_write_newpage_s_normal@2x.png') no-repeat center; background-size: 14px; cursor: pointer;
        &:hover{background: url('../imgs/ico/ic_write_newpage_s_over@2x.png') no-repeat center; background-size: 14px;}    
    }
    .export_m{min-width:18px; min-height:18px; background: url('../imgs/ico/ic_export_m_normal@2x.png') no-repeat center; background-size: 18px; cursor: pointer;
        &:hover{background: url('../imgs/ico/ic_export_m_over@2x.png') no-repeat center; background-size: 18px;}
    }
    .more_m{min-width:18px; min-height:18px; background: url("../imgs/ico/ic_more_detail_m_normal@2x.png") no-repeat center; background-size: 18px; cursor: pointer;
        &:hover{background: url("../imgs/ico/ic_more_detail_m_over@2x.png") no-repeat center; background-size: 18px;}
    }
    .close_m{min-width:18px; min-height:18px; background: url("../imgs/ico/ic_close_01_m_normal@2x.png") no-repeat center; background-size: 18px; cursor: pointer;
        &:hover{background: url("../imgs/ico/ic_close_01_m_over@2x.png") no-repeat center; background-size: 18px;}
    }
    .edit_m{min-width:18px; min-height:18px; background: url('../imgs/ico/ic_edit_m_normal@2x.png') no-repeat center; background-size: 18px; cursor: pointer;
        &:hover{background: url('../imgs/ico/ic_edit_m_over@2x.png') no-repeat center; background-size: 18px;}
    } 
}

/**************************************************    
   공통 :: 전체, 미진행, 진행, 완료 (상태 박스탭) 디자인    
**************************************************/    
.ProgressTab{    
    .unit{min-width:190px;height:70px;margin:0 0 0 8px;padding:0 15px;border:1px solid #cccccc;border-radius:10px;background-color:#ffffff;box-sizing:border-box;cursor: pointer;    
        .txt01{height:22px;font-size:13px;line-height:22px;color:#000000;}    
        .txt02{height:24px;font-size:13px;line-height:24px;color:#000000;    
           b{font-size:18px;font-weight:bold;line-height:22px;color:#000000;letter-spacing: -0.9px;margin:0 2px 0 0;}    
        }   
        &:first-child{margin: 0;}
        &:hover, &.on{border:1px solid #1c90fb;
            &.type1 .chargeIcon{background: #e0f1ff url('../imgs/ico/tab_all_none.png') no-repeat center center;}
            &.type2 .chargeIcon{background: #e0f1ff url('../imgs/ico/tab_charge_notproceed_none.png') no-repeat center center;}
            &.type3 .chargeIcon{background: #e0f1ff url('../imgs/ico/tab_charge_ongoing_none.png') no-repeat center center;}
            &.type4 .chargeIcon{background: #e0f1ff url('../imgs/ico/tab_charge_fin_none.png') no-repeat center center;}
            &.type5 .chargeIcon{background: #e0f1ff url('../imgs/ico/tab_charge_cancel_none.png') no-repeat center center;}
        }
        &.on{background-color: #f5faff;}
        &.type1{
            .chargeIcon{width: 50px; height: 50px; border-radius: 50%; background:#f6f7f9 url('../imgs/ico/tab_all_none.png') no-repeat center center;}
        }
        &.type2{
            .chargeIcon{width: 50px; height: 50px; border-radius: 50%; background:#f6f7f9 url('../imgs/ico/tab_charge_notproceed_none.png') no-repeat center center;}
            .txt02 b{color: #919191;}
        }
        &.type3{
            .chargeIcon{width: 50px; height: 50px; border-radius: 50%; background:#f6f7f9 url('../imgs/ico/tab_charge_ongoing_none.png') no-repeat center center;}
            .txt02 b{color: #46a3f0;}
        }
        &.type4{
            .chargeIcon{width: 50px; height: 50px; border-radius: 50%; background:#f6f7f9 url('../imgs/ico/tab_charge_fin_none.png') no-repeat center center;}
            .txt02 b{color: #20c997;}
        }
        &.type5{
            .chargeIcon{width: 50px; height: 50px; border-radius: 50%; background:#f6f7f9 url('../imgs/ico/tab_charge_cancel_none.png') no-repeat center center;}
            .txt02 b{color: #ff8787;}
        }        
    }    
}    