.root {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    gap: 5px;

    .multiLangButtonWrap {
        width: 27px;
        height: 27px;
        .multiLangButton {
            box-sizing: border-box;
        }
    }

    & > *:first-child {
        flex: 1 1 auto;
    }
}

.multiLangPanelRoot {
    margin: 5px 0px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 10px;
    .multiLangPanelTable {
        tr {
            td:first-child {
                width: 1%;
                white-space: nowrap;
                font-weight: bold;
                text-align: right;
                padding-right: 5px;
            }
            &:not(:last-child) {
                td {
                    padding-bottom: 5px;
                }
            }

            .multiLangItem {
                display: flex;

                & > * {
                    flex: 1 1 auto;
                }
            }
        }
    }
}

.floatingPanel {
    z-index: 1000;
}
