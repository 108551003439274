.root {
  display: inline-block;
  position: fixed;
  z-index: 2001;

  &.invisible {
    display: none;
  }
  &.opacity {
    opacity: 0;
  }
}

.rootWrapper {
  position: fixed;
  display: none;
}
