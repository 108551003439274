@import "../Common/Common";

.root {
    display: inline-block;

    .wrapper {
        display: inline-flex;
        flex-direction: row;
        box-sizing: border-box;
        align-items: center;
        position: relative;
        width: 100%;
        outline: none;
        line-height: normal;

        &:not(.customInput) {
            height: 27px;
            padding: 3.5px 7px;
            border: 1px solid #cccccc;
            background: #ffffff;

            &.readonly:not(.stateless) {
                background-color: #f2f2f2;
            }

            &.disabled:not(.stateless) {
                background-color: #f2f2f2;
                color: #a6a6a6;
            }

            &.required:not(.stateless) {
                @include required;
            }

            // &:focus-within,
            &.focus {
                border-color: #1c90fb;
            }
        }

        .dateInput {
            font-size: 12px;
            outline: none;
            border: none;
            font-family: inherit;
            padding: 6px 0px 5px 0px;
            background: transparent;

            &:disabled {
                -webkit-text-fill-color: #a6a6a6;
                color: #a6a6a6;
            }
        }

        .weekday {
            margin-left: 3px;

            &.sunday {}

            &.saturday {}
        }

        .fromToSeparator {
            margin: 0px 3px;
        }

        .icon {
            position: absolute;
            right: 7px;
            top: calc(50% - 7px);
            cursor: pointer;
        }

        &.disabled .icon {
            opacity: 0.2;
            cursor: none;
            pointer-events: none;
        }

        &.stateless .dateInput:disabled {
            -webkit-text-fill-color: #000000;
            color: #000000;
        }

        &.stateless.disabled .icon {
            opacity: 1;
        }
    }
}

.inputYMD {
    width: 62px;
}

.inputYM {
    width: 45px;
}

.inputY {
    width: 28px;
}


.controlButtonRoot {
    padding: 8px 10px 10px;
    border-bottom: 1px solid #e0e0e0;
    outline: none;

    .innerDiv {
        display: flex;
        position: relative;

        .defaultButton {
            margin-top: 2px;
            padding: 1px 0 0 !important;
        }

        .firstButton {
            width: 12% !important;
        }

        .secondButton {
            width: 12.5% !important;
            margin-left: 2px;
        }

        .topButton {
            width: 12% !important;
            margin-left: 2px;
        }

        .middleButton {
            float: left;
            width: 12% !important;
            margin-left: 2px;
        }

        .bottomButton {
            width: 35px !important;
            margin-left: -1px;
        }

        .hiddenButtonContainer {
            cursor: pointer;
            line-height: 25px;
            height: 25px;
            border: 1px solid rgb(211, 211, 211);
            padding: 0 !important;
            box-sizing: border-box;
            position: relative;
            font-size: 12px;
            font-family: "돋움", "Dotum", Helvetica, "Apple SD Gothic Neo", sans-serif;
            letter-spacing: -0.5px;
            text-align: center;
            background: #fff url('./ic_add_01_s_over.png') 100% 0/8px no-repeat;

            &:hover {
                background-image: none;
            }

            .badgeContainer {
                position: absolute;
                top: -40px;
                left: -15.5px;
                height: 40px;
                box-sizing: border-box;

                .badge {
                    box-sizing: border-box;
                    display: flex;
                    background: #fff;
                    border: 1px solid rgb(211, 211, 211);
                    border-radius: 5px;
                    padding: 5px;
                    box-sizing: border-box;
                    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;

                    &>.badgeButton {
                        padding: 0 8px;
                        height: 25px;
                        border: none;
                        outline: none;
                        background: #fff;
                        border-radius: 5px;
                        font-size: 12px;
                        font-family: "돋움", "Dotum", Helvetica, "Apple SD Gothic Neo", sans-serif;
                        letter-spacing: -0.5px;
                        white-space: nowrap;

                        &:hover {
                            background: #1c90fb;
                            color: #fff;
                        }
                    }

                    .buttonFocus {
                        background: #1c90fb;
                        color: #fff;
                    }

                    .buttonBlur {
                        background: #fff;
                        color: #000;
                    }
                }

                .badgeBar {
                    position: absolute;
                    bottom: -1px;
                    left: 13.5px;
                    width: 49.2px;
                    height: 5px;
                    transform: perspective(.30em) rotateX(-5deg);
                    border-left: 1px solid rgb(211, 211, 211);
                    border-right: 1px solid rgb(211, 211, 211);
                    background: #fff;
                }
            }

            .badgeContainerHidden {
                display: none;
            }
        }

    }

    .innerDiv3 {
        position: relative;
        padding-left: 1px;

        .dragBar {
            position: absolute;
            top: 100%;
            height: 3px;
            margin-top: -3px;
            background: #1c90fb;
            content: '';
            z-index: 10;
        }
    }
}