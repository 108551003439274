.root {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    user-select: none;

    &.disabled {
        cursor: not-allowed;
    }

    .arrowButton {
        box-sizing: border-box;
        flex: 0 0 auto;
        border: 1px solid #ccc;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            &:hover {
                border: 1px solid #ccc;
            }
        }

        &.frozen {
            pointer-events: none;
        }
        &:hover {
            border: 1px solid #1c90fb;
        }
        &:active {
            border: 1px solid #1c90fb;
        }

    }

    .prevButton {
        background: url("../Images/ic_arrow_left_01_s_normal@2x.png") no-repeat center center;
        background-size: 16px;

        &.disabled:hover {
            border: 1px solid #ccc;
            background: url("../Images/ic_arrow_left_01_s_normal@2x.png") no-repeat center center;
            background-size: 16px;
        }
        &:active,
        &:hover {
            background: url("../Images/ic_arrow_left_01_s_over@2x.png") no-repeat center center;
            background-size: 16px;
        }
    }

    .verticalPrevButton {
        background: url("../Images/ic_arrow_up_01_s_normal@2x.png") no-repeat center center;
        background-size: 16px;

        &.disabled:hover {
            border: 1px solid #ccc;
            background: url("../Images/ic_arrow_up_01_s_normal@2x.png") no-repeat center center;
            background-size: 16px;
        }
        &:active,
        &:hover {
            background: url("../Images/ic_arrow_up_01_s_over@2x.png") no-repeat center center;
            background-size: 16px;
        }
    }
    .nextButton {
        background: url("../Images/ic_arrow_right_01_s_normal@2x.png") no-repeat center center;
        background-size: 16px;

        &.disabled:hover {
            border: 1px solid #ccc;
            background: url("../Images/ic_arrow_right_01_s_normal@2x.png") no-repeat center center;
            background-size: 16px;
        }
        &:active,
        &:hover {
            background: url("../Images/ic_arrow_right_01_s_over@2x.png") no-repeat center center;
            background-size: 16px;
        }
    }
    .verticalNextButton {
        background: url("../Images/ic_arrow_down_01_s_normal@2x.png") no-repeat center center;
        background-size: 16px;

        &.disabled:hover {
            border: 1px solid #ccc;
            background: url("../Images/ic_arrow_down_01_s_normal@2x.png") no-repeat center center;
            background-size: 16px;
        }
        &:active,
        &:hover {
            background: url("../Images/ic_arrow_down_01_s_over@2x.png") no-repeat center center;
            background-size: 16px;
        }
    }

    .child {
        box-sizing: border-box;
        flex: 1 1 auto;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        user-select: none;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        &.frozen {
            pointer-events: none;
        }

        img {
            -webkit-user-drag: none;
        }

        .defaultTemplate {
            padding: 0 15px;
            overflow: hidden;
            box-sizing: border-box;
            border: 1px solid #ccc;
            border-radius: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 100%;
            .image {
                width: 50px;
                height: 50px;
                margin: 0 10px 0 0;
                border-radius: 100%;
                background: #f6f7f9;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .imageMouseOver {
                background-color: #e0f1ff;
            }
            .textArea{
                display: block;

                .textAreaSub {
                    color: #4A4A4A;
                }
                .textAreaMain {
                    color: #4A4A4A;
                }
            }

            &.selectedItem {
                border: 1px solid #1c90fb;
                background-color: #f5faff;

                .textArea {
                    .textAreaSub {
                        color: #000000;
                    }
                    .textAreaMain {
                        color: #000000;
                    }
                }
            }

            &.mouseOverItem {
                border: 1px solid #1c90fb;
                color: #000000;
            }
        }
    }
}
