.root{
    width: 186px;
    display: inline-flex;
    flex-direction: column;
    box-sizing: content-box;
    font-size: 13px;
   
    &>.top{
        display: flex;

        &>.palette{
            display: flex; 
            position: relative;
            width: 161px; 
            height: 120px;
            margin: 5px;
        }

        &>.selectColor{
           display: flex;
           position: relative;
           width: 10px;
           height: 120px;
           margin-top: 5px;
        }
    }
    &>.bottom{
        display: flex ;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0 5px;
        border-bottom: 1px solid;
        }
    
    &>.newNowColor{
        display: flex ;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        padding: 3px 0;
        &>.newColor{
            width: 25px;
            height: 25px;
            margin-left: 5px;
        }
        &>.nowColor{
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
    }
    &>.another{
        text-align: center;
        font-size: 13px;
        padding-bottom: 7px;
        cursor: pointer;
        &:hover{
            color:#0070C0;
            text-decoration: underline;            
        }
    }
    }
