.obtMaskedTextField,
.obtMaskedTextField * {
    box-sizing: content-box;
    line-height: normal;
}

.obtMaskedTextField {
    display: inline-block;

    &.searchPrivacyIconShown {
        position: relative;
    }

    & > .searchPrivacyIconRoot {
        position: absolute;
        width: 18px;
        right: 5px;
        top: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        pointer-events: none;

        & > .searchPrivacyIcon {
            border-radius: 7px;
            pointer-events: all;
            cursor: pointer;
        }
    }
}
