.root {
    .child {
        &:not(:last-child) {
            margin-bottom: 0px;
        }

        & > div:first-child > div:first-child > button {
            border-top: 1px solid #e6e6e6 !important;
            border-bottom: 1px solid #e6e6e6 !important;
        }

        &:first-child > div:first-child > div:first-child > button {
            border-top: 2px solid #666666 !important;
        }

        &.selected > div:first-child > div:first-child > button {
            border-bottom: 1px solid #a6a6a6 !important;
        }

        &.selected + .child > div:first-child > div:first-child > button {
            border-top: 1px solid #a6a6a6 !important;
        }

        &:last-child.selected > div:first-child > div:last-child {
            border-bottom: 1px solid #a6a6a6 !important;
        }
    }
}
