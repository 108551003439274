.default {
  display: inline-block;
  border: 1px solid #878787;
}

.head {
  height: 52px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: #f2f2f2;
  border-bottom: 1px solid #878787;
  box-sizing: border-box;
}

.headLeft {
  // width: calc(100% - 535px);
  padding-left: 20px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex: 1 1 auto;
  overflow: hidden;
  min-width: 30px;

  &>span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    white-space: nowrap;
  }
}

.headRight {
  flex: 0 0 auto;
  // width: 500px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
}

.add {
  width: 27px;
  height: 27px;
  margin-right: 30px;
  padding: 0px !important;
  background-image: none !important;
}

.smallAdd {
  margin-right: 10px;
}

.delete {
  width: 27px;
  height: 27px;
  margin-right: 2px;
  padding: 0px !important;
  background-image: none !important;
}

.up {
  width: 27px;
  height: 27px;
  margin-right: 2px;
  padding: 0px !important;
  background-image: none !important;
}

.down {
  width: 27px;
  height: 27px;
  margin-right: 28px;
  padding: 0px !important;
  background-image: none !important;
}

.newWindow {
  height: 24px;
  width: 40px;
  border: 0px;
  padding: 0px !important;
  background-image: none !important;

  div {
    img {
      width: 24px;
      height: 23px;
    }
  }
}

.middleButton {
  margin-left: 26px;
}

.print {
  height: 24px;
  width: 40px;
  border: 0px;
  padding: 0px !important;
  background-image: none !important;

  div {
    img {
      width: 24px;
      height: 23px;
    }
  }
}

.pdfSearch {
  width: 40px;
  height: 24px;
  border: 0px;
  // margin-left: 26px;
  padding: 0px !important;
  background-image: none !important;

  div {
    img {
      width: 24px;
      height: 23px;
    }
  }
}

.viewerCollapseBtn {
  width: 20px;
  height: 20px;
  outline: none;
  border: none;
  background: url('./img/btn_arrow_down_normal.png') center center/cover no-repeat;
  transition: transform 0.2s ease;
  transform-origin: center;

  &:hover {
    background-image: url('./img/btn_arrow_down_hover.png');
  }
}

.arrowAnimate {
  transform: rotate(-0.5turn);
}

.LS_splitbtn {
  top: 237.688px;
}

.splitHtml {
  background-image: none !important;

  div {
    div {
      div {
        button {
          border: 0px !important;
          height: 23px !important;

          div {
            span {
              img {
                width: 24px;
                height: 23px;
              }
            }
          }
        }
      }
    }
  }
}

.dropDownPercent {
  margin-right: 20px;
}

.smallDropDownPercent {
  margin-right: 5px;
}

.bar {
  height: 20px;
  margin-right: 20px;
  border-right: 1px solid #c9c9c9;
}

.body {
  display: flex;
  background-color: #999999;
  overflow: hidden;
}

.pdfBody {
  display: flex;
  background-color: #f2f2f2;
}

.view {
  display: flex;
  flex-direction: column;
}

.previewButton {
  width: 5px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #999999;
  cursor: e-resize;

  &>button {
    border: 1px solid black;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #f7f7f7;
    height: 90px;
  }
}

.renderer {
  width: 100%;
  // 전체 높이에서 head높이 빼기
  height: calc(100% - 52px);
}

.pdfDocument {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f7f7f7;
}

.contentsScroll {
  flex-grow: 1;
  overflow: hidden;
  background-color: #999999;
  width: 100%;
  height: 100%;
}

.previewScroll {
  flex-grow: 1;
  overflow: hidden;
  transition: width .2s ease-out;
}

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  gap: 20px;
}

.previewContentsWrapper {
  width: 190px;
  height: 100%;
  // margin: 10px 0;
  cursor: pointer;

  .previewContents {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
    }
  }

  .previewPageNumber {
    font-size: 12px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
  }
}

.contents {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfWrapper {
  margin: auto;
}

.mark {
  opacity: 0.5;
  background: #ff0;
  box-shadow: 0px 2px 10px #ff0;
  color: transparent;
  white-space: pre;
}

.dockpanel {
  overflow: hidden;
}

.floatingPanel {
  display: flex;
  flex-direction: column;
  background-color: #575757;
  width: 220px;
  height: 103px;

  &>div:first-child {
    color: white;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
}

.searchPreButton {
  width: 40px;
  height: 21px;

  &>div {
    &>span {
      font-size: 8px !important;
      color: #000000;
    }
  }
}

.searchNextButton {
  width: 40px;
  height: 21px;

  &>div {
    &>span {
      font-size: 8px !important;
      color: #000000;
    }
  }
}

.searchTextField {
  width: 200px;
  height: 25px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
}

.searchClose {
  background-color: #575757 !important;
  background-image: none !important;
  border: 0px;
  width: 11px;
  height: 11px;
  padding: 0px !important;

  &>div {
    img {
      width: 11px;
      height: 11px;
      background-color: #575757 !important;
    }
  }
}

.previewCollapseBtnContainer {
  box-sizing: border-box;
  width: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #999;

  .previewCollapseBtn {
    background: transparent;
    width: 20px;
    height: 100px;
    border: none;
    background-repeat: no-repeat;
    outline: none;

    &.open {
      background-image: url('./img/btn_sb_close_over.png');
    }

    &.closed {
      background-image: url("./img/btn_sb_open_normal.png");

      &:hover {
        background-image: url("./img/btn_sb_open_over.png");
      }
    }
  }
}