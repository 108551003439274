.root {
        border: solid 1px #e9e9e9;

    & > .labelText {
        margin: 14px 16px 7px 16px;
        min-height: calc(100% - 48px) !important;
        // font-family: NotoSansCJKkr;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.12px;
        text-align: left;
        color: var(--greyish-brown);
    }

    & > .empty {
        min-height: calc((100% - 48px) / 2) !important;
        height: 16px;
    }

    & > .progressDiv {
        position: relative;
        height: 12px;
        margin: 0px 0px 16px 16px;

        & > div:nth-child(1) {
            background-color: #eaeaea !important;
            float: left !important;
            width: calc(100% - 51px)!important;
        }
    
        & > .innerText {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
            width: 51px;
            height: 100%;

            // font-family: NotoSansCJKkr;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.96;
            letter-spacing: normal;
            text-align: right;
            float: left;
        }
    }
}




