.breadcrumb {
    display: flex;
    align-items: center;
    height: 50px;
    background-color : rgb(248, 249, 250);
    padding:0 10px;
    font-size: 1.25em;
}

.node-item{  
  display:flex;
  position: relative;
  font-weight: bold;
  align-items: center;
  user-select: none;
  
  &--end{
    display: flex;
    position: relative;
    cursor: pointer;
    margin: 4px 0 0 0;
    user-select: none;

    & > img {
      pointer-events: none;
      transition: transform 0.2s ease;
      transform-origin: center;
    }

    .arrowDown {
      transform: rotate(0.5turn);
    } 
  }

  &__prev{
    cursor: pointer;
    color: #4ea1d3;
    font-weight: normal;
  }
}

.dropdown{
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  background-color: white;
  position:absolute;
  border: 1px solid #d1d3d4;
  z-index: 10;
  top:20px;
  font-size:11px;
  box-shadow: rgba(0, 0,0 ,0.12) 0px 1px 6px, rgba(0, 0,0 ,0.12) 0px 1px 4px;
  & a{
    text-decoration: none;
  }
  & li {
    line-height: 22px;
    padding: 0 7px;
    height: 27px;
    line-height: 27px;
    font-size: 12px;

    &:hover {
      background: #e9f5ff;
    } 
  }
}

.title {
  font-weight: bold;
  user-select: none;
}

.arrowButton {
  user-select: none;
  pointer-events: none;
  padding:3px 10px 0 10px;
  height:14px;
}