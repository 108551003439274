.root {
    margin-bottom: 10px;

    /* 아코디언 */
    .wrapper {
        margin: 0px;
        padding: 0px;
        position: initial;

        & > div {
            position: relative;
        }

        .accordion {
            box-sizing: border-box; 
            cursor: pointer; 
            display: flex; 
            outline: none; 
            position: relative; 
            text-align: left; 
            vertical-align: top; 
            border-radius: 0px; 
            border-width: 2px 0px 1px; 
            border-style: solid none; 
            border-top-color: rgb(102, 102, 102); 
            border-right-color: initial; 
            border-bottom-color: rgb(220, 219, 220); 
            border-left-color: initial; 
            border-image: initial; 
            background: transparent; 
            width: 100%; 
            font-family: inherit;
            margin-top: -1px; 
            padding: 0px 0px 0px 10px; 
            font-size: 13px; 
            align-items: center; 
            height: 32px; 
            line-height: 12px; 

            &.disabled {
                cursor: not-allowed;
            }

            &.large {
                height: 40px;
            }

            &.largeHavingImageUrl {
                height: 40px;       
                padding-left: 38px;
            }

            &.defaultHavingImageUrl {
                padding-left: 34px;
            }

            /* imageUrl */
            & > .imageUrl {
                position: absolute; 
                top: 50%; 
                display: block;
                left: 10px; 
                width: 18px; 
                height: 18px;
                margin-top: -10px; 
                text-align: center;

                & > img {
                    width: 18px;
                    height: 18px;

                    &.large {
                        width: 22px;
                        height: 22px;
                        margin-top: -3px;
                    }
                }
            }

            /* OBTButton 영역 */
            & > .button {
                position: absolute;
                top: 50%;
                right: 30px;
                z-index: 10;
                margin-top: -10px;

                &.useSeparator {
                    right: 24px;
                }

                & > .separator {
                    margin-left: 2px;
                }

                .stateLabel {
                    margin-top: 1px;
                    height: 18px;
                    border-radius: 10px;
                    padding: 0px 6px;
                    font-size: 11px;
                    line-height: 18px;
                    color: white;
                    text-align: center;
                    min-width: 4em;
                    pointer-events: none;
                    margin-right: 10px;
                }
            }

            /* 위아래 아이콘 */
            & > .arrowIcon {
                background: url("./Images/ic_arrow_down_01_m_normal.png") no-repeat;
                display: inline-block; 
                overflow: hidden; 
                line-height: 100em; 
                vertical-align: middle; 
                width: 18px; 
                height: 18px; 
                text-align: center; 
                position: absolute; 
                right: 6px; 
                z-index: 30;

                &.open {
                    background: url('./Images/ic_arrow_up_01_m_normal.png') no-repeat;
                }
            }

            /* 타이틀 영역 */
            & > .title {
                color: #4a4a4a;
                font-weight: lighter;

                &.open {
                    color: rgb(28, 144, 251);
                    font-weight: bold;
                }

                &.disabled {
                    color: rgb(166,166,166);
                }
            }
        }
    }

    /* children 영역 */
    .contents {
        display: block; 
        border-top: 0px; 
        border-bottom: 1px solid rgb(220, 219, 220); 
        background: transparent; 
        padding: 14px 10px; 
        margin: 0px; 
        position: relative;
        opacity: 1;

        &.useSection {
            padding: 0px;
        }

        &.closed {
            opacity: 0;
            height: 0px;
            width: 0px;
            padding: 0px;
            border: none;
            overflow: hidden;
        }
    }
}
