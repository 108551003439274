@charset "utf-8"; 

/**************************************************  
공통/포털 공통  
**************************************************/ 

/* 원챔버 탐색기 설치 */ 
.UAAP021{display:flex;-ms-display:flex; height:100%; justify-content: center; align-items: center;
    background: url('../imgs/bg/img_onechamber_install@2x.png') no-repeat top 40px center; background-size: 820px 320px;
    dl{text-align: center; margin-top:350px;
        dt{font-size: 22px; color: #000; font-weight: bold;align-items: center; display: inline-flex;letter-spacing: -1.1px;
            img{padding-right:8px;}
        }
        dd{font-size: 15px; color: #a6a6a6; padding-top:14px;letter-spacing: -0.75px;
            em{color:#000;}
        }
    }    
    .downloadBox{width:400px; padding:21px 0 19px 0; border-radius: 10px; border: solid 1px #e6e6e6; margin-top:30px;
        .conBox{display:inline-flex;padding: 4px 0 6px 0px; color: #000; align-items: center; justify-content: center;
            &:first-child{border-right: 1px solid #e6e6e6; box-sizing: border-box;}
            .winTxt{background: url('../imgs/uc/ic_download_windows.png') no-repeat left 0 center; padding-left: 22px;} 
            .macTxt{background: url('../imgs/uc/ic_download_mac.png') no-repeat left 0 center; padding-left: 22px;}  
            .btn{margin:5px 0 0 0; min-width: 82px;}   
        } 
    }
}

/* 헤더 조직도 */
.organizationInfoBox{position:relative;width: 250px;height: 205px;margin:0 0 10px 0;padding:0 16px;border-radius: 4px;border: solid 1px #d5dce8;background-color: #f2f5fa;box-sizing: border-box;
    .profile_pic{width: 72px;height: 72px;margin: 20px auto 0 auto;border-radius: 36px;overflow: hidden;background:url('../imgs/empty/profile_noimg_large.png') no-repeat center;
        img{width:100%;}  
    }  
    .profile_name{margin: 8px 0 0 0;padding: 0 0 11px 0;font-size:14px;font-weight:bold;line-height:20px;color: #000000;text-align: center;border-bottom: 1px solid rgba(190,198,222,0.4);
        span{position:relative;font-size:11px;line-height:20px;color:#4a4a4a;margin:0 0 0 10px;padding:0 0 0 10px;vertical-align:top;}  
        span::before{content:'';position: absolute;top:4px;left:0;width:1px;height:10px;background:#bec6de;}  
    }  
    .profile_etc{align-items:center;margin:10px 0 0 0; text-align: center;
        .profileId{max-width:175px; display: inline-block;text-align:left;font-size:11px;line-height:24px;color:#4a4a4a;}  
        .profileBd{display: inline-block;text-align:left;font-size:11px;line-height:24px;color:#4a4a4a;padding:0 0 0 21px;background: url('../imgs/ico/ic_birthday.png') no-repeat top 3px left;}  
    }  
    .badgeBox{position: absolute; top:11px; right:11px;
        .profile_badge {max-width:74px; height:18px; padding:0 8px 0 7px; text-align:center; border-radius:9px; font-size:11px; letter-spacing:-1.1px; color: #fff; background: #9da3aa; box-sizing:border-box;}  
    
    }
    .btnBox{justify-content: center;
        > [data-orbit-component=OBTTooltip] {width:18px;height: 18px; margin-right:14px; cursor:pointer;
            &:last-child{margin-right:0px;}
        }
        .profile_gift {background: url('../imgs/ico/ic_gift_m_normal@2x.png') no-repeat center; background-size: 18px;}  
        .profile_export {background: url('../imgs/ico/ic_export_m_normal.png') no-repeat center; background-size: 18px;}  
        .profile_myGroup {background: url('../imgs/ico/ic_mygroup_m_normal@2x.png') no-repeat center; background-size: 18px;}  
        .profile_mail {background: url('../imgs/ico/ic_mail_m_normal@2x.png') no-repeat center; background-size: 18px;}  
        .profile_video {background: url('../imgs/ico/ic_video_conversation_m_normal@2x.png') no-repeat center; background-size: 18px;}  
        .profile_link {background: url('../imgs/ico/ic_link_m_normal@2x.png') no-repeat center; background-size: 18px;}  
        .profile_setting {background: url('../imgs/ico/ic_setting_basic_m_normal@2x.png') no-repeat center; background-size: 18px;}  
        .profile_msg {background: url('../imgs/ico/ic_msg_m_normal@2x.png') no-repeat center; background-size: 18px;}  
        .profile_comment {background: url('../imgs/ico/ic_comment_m_normal@2x.png') no-repeat center; background-size: 18px;}  
    }
}  

.organizationCardBox{position:relative;width: 100%;height: 100%;
    .profile_pic{width: 72px;height: 72px;margin:23px 0 0 25px;border-radius: 36px;overflow: hidden;background:url('../imgs/empty/profile_noimg_large.png') no-repeat center;
        img{width:100%;}  
    }  
    .infoTop{margin:24px 0 0 20px;
        .profile_name{max-width:90px;font-size:14px;font-weight:bold;line-height:20px;color: #000000;text-align: left;}  
        .profile_position{max-width:70px;font-size:14px;font-weight:bold;line-height:20px;color: #000000;text-align: left;margin:0 0 0 5px;}  
        .profile_id{max-width:100px;position:relative;font-size:14px;line-height:20px;color:#4a4a4a;margin:0 0 0 5px;padding:0 0 0 5px;vertical-align:top;}  
        .profile_id::before{content:'';position: absolute;top:6px;left:0;width:1px;height:10px;background:#bec6de;}  
        .profile_label{max-width: 65px; height: 18px; margin:0 0 0 5px; padding: 0 8px 0 7px; text-align: center; line-height: 18px; border-radius: 9px; font-size: 11px; letter-spacing: -1.1px; color: #ffffff; background: #9da3aa; box-sizing: border-box;}  
    }  
    .infoMid{margin:0 20px;min-height:30px;
        .profile_org{font-size:12px;line-height:1.25;color: #4a4a4a;text-align: left;}  
        .profile_org.line:hover{text-decoration: underline;cursor:pointer;}  
    }   
    .infoBot{margin:0 20px;
        .profile_phone{font-size:12px;line-height:20px;color: #a6a6a6;text-align: left;margin:0 10px 0 0;padding:0 0 0 20px;background: url('../imgs/ico/ic_phone_01_m_normal.png') no-repeat top 2px left;}  
        .profile_mobile{font-size:12px;line-height:20px;color: #a6a6a6;text-align: left;margin:0 10px 0 0;padding:0 0 0 20px;background: url('../imgs/ico/ic_mobile_m_normal.png') no-repeat top 2px left;}  
    }   
    .deleteBox{display:none;}  
}

.organizationBtnBox{position: absolute;top:0;right:0;
    .listType{margin:0 0 0 4px;
        &::after {content:"";display:block;clear:both;height:0;visibility:hidden;}  
        ul{overflow: hidden;
            li{float: left;width: 26px;height: 25px;border: 1px solid #a6a6a6;transition: 0.3s;background-image: linear-gradient(to top, #f0f0f0, #ffffff);}  
            li:first-child{border-width: 1px;border-right-color: transparent;border-radius: 2px 0 0 2px;}  
            li:last-child{border-width: 1px;border-left-color: transparent;border-radius: 0 2px 2px 0;}  
            li.on{border: 1px solid #1c90fb;background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);}  
            li span{display: block;width: 100%;height: 100%;cursor: pointer;}  
            li span.typeThumbBtn{background: url("../imgs/ico/ic_view_thumbnail_02_m_normal.png") no-repeat center;}  
            li span.typeListBtn{background: url("../imgs/ico/ic_view_list_02_m_normal.png") no-repeat center;}  
            li.on span.typeThumbBtn{background: url("../imgs/ico/ic_view_thumbnail_02_m_over.png") no-repeat center;}  
            li.on span.typeListBtn{background: url("../imgs/ico/ic_view_list_02_m_over.png") no-repeat center;}  
        }  
    }  
}

.excelSaveRadioBox{margin: 10px 0 0 0;padding: 20px;border: 1px solid #e6e6e6;text-align: center;}  

.pubCardListStyle.orgCardList .cardList{border-top:none;
    .selectedList{background: #eff7ff !important;}  
    & > div > div + div > div > div > div ul > li{border-radius:3px;}  
    & > div > div + div > div > div > div ul > li .selectedBorder{border-radius:3px;}  
}

.pubFormStyle.orgForm{  
   &.bt > table{border-top:none;}  
   th {font-size: 12px;color:#000000;}  
}  

// 조직도 마이그룹 카드리스트 커스텀  
.orgCardMyGroup{
    .organizationCardBox .deleteBox {display:block;position: absolute;top:11px;right:11px;}  
    .organizationCardBox .deleteBox .deleteBtn {display: block;width: 18px;height: 18px;background: url('../imgs/ico/ic_close_01_m_normal.png') no-repeat center;z-index:1;cursor: pointer;}  
    .selectedBorder{z-index:2;}  
    .selectedBorder + .organizationCardBox .deleteBox .deleteBtn{background: url('../imgs/ico/ic_close_01_m_over.png') no-repeat center;}  
}

.pubTabStyle.orgTab{  
   > div > div > div > div > ul > div:first-child li > button > span:first-child{display:none;}  
} 

// 사용자선택 팝업
.orgContact{position: relative; display: flex; justify-content: flex-end; margin-bottom:4px;
    .addBtn{display: flex; align-items: center; height:27px; padding: 0 20px 0 10px; border: 1px solid #cccccc; box-sizing: border-box; border-radius: 2px;        
        &:hover{border: solid 1px #a6a6a6; box-sizing: border-box;}       
        &.up{background:url("../imgs/ico/btn_snb_arrow_up@2x.png") no-repeat right 6px top 7px, linear-gradient(0deg,#f0f0f0,#fff); background-size:11px;}
        &.down{background:url("../imgs/ico/btn_snb_arrow_down@2x.png") no-repeat right 6px top 8px, linear-gradient(0deg,#f0f0f0,#fff); background-size:11px;}
        &:active{border: solid 1px #a6a6a6; box-sizing: border-box;
            &.up{background:url("../imgs/ico/btn_snb_arrow_up@2x.png") no-repeat right 6px top 7px, linear-gradient(0deg, #dddddd, #f5f5f5); background-size:11px;}
            &.down{background:url("../imgs/ico/btn_snb_arrow_down@2x.png") no-repeat right 6px top 8px, linear-gradient(0deg, #dddddd, #f5f5f5); background-size:11px;}        
        }
    }
    .FilterBoxBasic{position:absolute; border: 1px solid #a6a6a6; box-sizing: border-box; background: #ffffff;z-index: 10; top: 31px; min-width:150px; width:auto;
        ul{   
            li{font-size:12px;line-height:27px; padding: 0 10px 0 32px; cursor: pointer;   
                &.docuWrite{background: url('../imgs/ico/ic_docu_write_per_m_normal@2x.png') no-repeat left 9px center; background-size: 18px;}   
                &.businesscard{background: url('../imgs/ico/ic_businesscard_m_normal@2x.png') no-repeat left 9px center; background-size: 18px;}   
                &:hover{
                    &.docuWrite{background:#e6f5ff url('../imgs/ico/ic_docu_write_per_m_normal@2x.png') no-repeat left 9px center; background-size: 18px;}   
                    &.businesscard{background:#e6f5ff url('../imgs/ico/ic_businesscard_m_normal@2x.png') no-repeat left 9px center; background-size: 18px;} 
                }
            }   
        }  
    }
}

/*****************************   
    공통 - 프로필 팝업   
******************************/   
/* 프로필 */   
.profilePop{padding: 30px; background: #f2f5fa; border:1px solid #d5dce8; border-radius: 4px; box-sizing:border-box; overflow: hidden;   
    .profile_img { text-align: center;
        .img{width:131px; height:166px; background:url('../imgs/empty/profile_personaldata_noimg_02.png') no-repeat center center; background-size:100% 100%; margin: 0 auto;   
            img{width:131px; height:166px; background:#f2f5fa;}   
        }   
        .name{max-width: 131px; font-weight: bold; margin-top: 10px; font-size: 14px; line-height: 15px;}   
        .profile_label{min-width: 45px; max-width: 130px; height: 18px; margin: 5px 0 0 0; padding: 0 8px 0 7px; text-align: center; line-height: 18px; border-radius: 9px; font-size: 11px; letter-spacing: -1.1px; color: #ffffff; background: #9da3aa; box-sizing: border-box;}
    }   
}   
.profile_info {padding: 0 0 0 50px; font-size:11px; color:#4a4a4a;    
    table {table-layout: fixed;
        tr{   
            &:last-child td{padding-bottom:1px;}   
            td{padding-bottom:13px; line-height:16px;padding-left:25px;color:#4a4a4a; vertical-align: top;   
                &.icon01{background:url("../imgs/ico/ic_comp_02_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon02{background:url("../imgs/ico/ic_user_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon03{background:url("../imgs/ico/ic_mobile_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon04{background:url("../imgs/ico/ic_phone_01_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon05{background:url("../imgs/ico/ic_mail_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon06{background:url("../imgs/ico/ic_address_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon07{background:url("../imgs/uc/ico_profile_icon07.png") no-repeat top 0px left; background-size: 18px 16px;}   
                &.icon08{background:url("../imgs/ico/ic_birthday_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon09{background:url("../imgs/ico/ic_pc_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon10{background:url("../imgs/ico/ic_print_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon11{background:url("../imgs/ico/ic_career_m_normal@2x.png") no-repeat top 1px left 2px; background-size: 14px 14px;}   
                &.icon12{background:url("../imgs/ico/ic_sns_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                &.icon13{background:url("../imgs/ico/ic_mail_personal_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //개인메일   
                &.icon14{background:url("../imgs/ico/ic_organization_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //조직도
                &.icon15{background:url("../imgs/ico/ic_join_date_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //입사일      
                .profileTooltip{max-width: 100%; line-height: 18px; white-space: nowrap; word-break: break-all; text-overflow: ellipsis; overflow: hidden;}
            }   
        }   
    }           
}
.profile_btn { padding: 0 30px;height:106px;border-radius: 4px;border: solid 1px #dbdbdb;margin-top: 10px; box-sizing: border-box;   
    ul{display:flex;margin-top: 26px;   
        li{flex:1 1 auto; text-align: center; display: inline-block;  cursor:pointer;box-sizing:border-box;  position: relative;   
            .ico {width: 24px;height: 24px;margin: 0 auto;}   
            &.video{   
                .ico{background: url('../imgs/ico/ic_video_conversation_l_normal.png') no-repeat center center;}   
            }   
            &.cal{   
                .ico{background: url('../imgs/ico/ic_calendar_l_normal.png') no-repeat center center;}   
            }   
            &.todo{   
                .ico{background: url('../imgs/ico/ic_todo_01_l_normal.png') no-repeat center center;}   
            }   
            &.report{   
                .ico{background: url('../imgs/ico/ic_report_l_normal.png') no-repeat center center;}   
            }   
            &.mail{   
                .ico{background: url('../imgs/ico/ic_mail_l_normal.png') no-repeat center center;}   
            }   
            &.msg{   
                .ico{background: url('../imgs/ico/ic_msg_l_normal.png') no-repeat center center;}   
            }   
            &.cmt{   
                .ico{background: url('../imgs/ico/ic_comment_l_normal.png') no-repeat center center;}   
            }      
            &.sms{   
                .ico{background: url('../imgs/ico/ic_sms_l_normal.png') no-repeat center center;}   
            }
            &.fax{   
                .ico{background: url('../imgs/ico/ic_print_l_normal.png') no-repeat center center;}   
            }        
            .tit { line-height: 20px;color:#4a4a4a;margin-top:7px;}   
            &::after {content: '';position: absolute;right:0px; top:16px;height:27px;width: 1px;background-color:#dce2eb;}   
            &:last-child::after {display: none;}   
        }   
        li:hover{   
            .tit {color:#1c90fb;}   
            &.video{   
                .ico{background: url('../imgs/ico/ic_video_conversation_l_over.png') no-repeat center center;}   
            }   
            &.cal{   
                .ico{background: url('../imgs/ico/ic_calendar_l_over.png') no-repeat center center;}   
            }   
            &.todo{   
                .ico{background: url('../imgs/ico/ic_todo_01_l_over.png') no-repeat center center;}   
            }   
            &.report{   
                .ico{background: url('../imgs/ico/ic_report_l_over.png') no-repeat center center;}   
            }   
            &.mail{   
                .ico{background: url('../imgs/ico/ic_mail_l_over.png') no-repeat center center;}   
            }   
            &.msg{   
                .ico{background: url('../imgs/ico/ic_msg_l_over.png') no-repeat center center;}   
            }   
            &.cmt{   
                .ico{background: url('../imgs/ico/ic_comment_l_over.png') no-repeat center center;}   
            }
            &.sms{   
                .ico{background: url('../imgs/ico/ic_sms_l_over.png') no-repeat center center;}   
            }
            &.fax{   
                .ico{background: url('../imgs/ico/ic_print_l_over.png') no-repeat center center;}   
            }          
        }   
    }   
}

/* 리뉴얼 프로필 */   
.newProfilePop{
    .profileBox{border-radius: 4px; border: solid 1px #e6e6e6; background-color: #f9fafc; box-sizing: border-box;
        .title{display: flex; align-items: center;height:54px; border-radius: 4px 4px 0 0; border-bottom: solid 1px #e6e6e6; box-sizing: border-box; background-color: #fff; font-weight: bold; padding:0 21px; font-size:14px;
            .btn{display: flex; align-items: center;
                .line{margin: 0 10px; border-right:1px solid #e6e6e6; height:16px;}
                .icoLink {background:url("../imgs/ico/ic_link_m_normal.png") no-repeat left 4px center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
                .icoExport {background:url("../imgs/ico/ic_export_m_normal.png") no-repeat left 4px center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
                .icoGift {background:url("../imgs/ico/ic_gift_m_normal.png") no-repeat left 4px center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}   
                .icoVideo {background:url("../imgs/ico/ic_video_conversation_m_normal.png") no-repeat left 4px center, linear-gradient(to top, #f0f0f0, #ffffff) !important;} 
                .mygroup {background:url("../imgs/ico/ic_folder_my_m_normal.png") no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important;}    
            }
        }
        .profileInfo{margin:30px 0 30px 41px;
            .profileImg {text-align: center; justify-content:center;align-items:center;
                .img{width:131px; height:166px; background:url('../imgs/empty/profile_personaldata_noimg_02.png') no-repeat center center; border-radius: 3px; border: solid 1px #e3e3e3; box-sizing: border-box;
                    img{width:100%; height:100%; border-radius: 3px;}   
                }   
                .name{max-width: 131px; font-weight: bold; margin-top: 10px; font-size: 14px; line-height: 15px;}   
                .profile_label{min-width: 45px; max-width: 130px; height: 18px; margin: 5px 0 0 0; padding: 0 8px 0 7px; text-align: center; line-height: 18px; border-radius: 9px; font-size: 11px; letter-spacing: -1.1px; color: #ffffff; background: #9da3aa; box-sizing: border-box;}
            }  
            .profileCon {padding: 0 0 0 50px; font-size:11px; color:#4a4a4a;   
                ul{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;min-width:0;-webkit-flex:1;flex:1;
                    &:last-child{
                        li{padding-bottom: 0px;}
                    }
                    li{line-height:18px;padding-left:25px;color:#4a4a4a; vertical-align: top; -webkit-flex:1;flex:1; min-height:22px; padding-bottom: 13px; word-break: break-word; min-width: 0;      
                        > [class*="OBTTooltip_root"] {display:flex;}            
                        &.icon01{background:url("../imgs/ico/ic_comp_02_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon02{background:url("../imgs/ico/ic_user_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon03{background:url("../imgs/ico/ic_mobile_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon04{background:url("../imgs/ico/ic_phone_01_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon05{background:url("../imgs/ico/ic_mail_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon06{background:url("../imgs/ico/ic_address_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon07{background:url("../imgs/uc/ico_profile_icon07.png") no-repeat top 0px left; background-size: 18px 16px;}   
                        &.icon08{background:url("../imgs/ico/ic_birthday_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon09{background:url("../imgs/ico/ic_pc_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon10{background:url("../imgs/ico/ic_print_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon11{background:url("../imgs/ico/ic_career_m_normal@2x.png") no-repeat top 1px left 2px; background-size: 14px 14px;}   
                        &.icon12{background:url("../imgs/ico/ic_sns_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon13{background:url("../imgs/ico/ic_mail_personal_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //개인메일   
                        &.icon14{background:url("../imgs/ico/ic_organization_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //조직도
                        &.icon15{background:url("../imgs/ico/ic_join_date_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //입사일      
                        &.icon16{background:url("../imgs/ico/ic_employee_num_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //사번      
                        &.icon17{background:url("../imgs/ico/ic_emergency_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //비상연락망      
                        &.icon18{background:url("../imgs/ico/ic_memo_personal_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //개인메모      
                    }   
                }           
            } 
        }
        .profileMenu { margin: 30px 0; display:flex; align-items:center;
            ul{
                li{flex:1 1 auto; text-align: center; display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;min-width:0; cursor:pointer;box-sizing:border-box;  position: relative;   
                    .icon{width: 24px; height: 24px; margin: 0 auto;}
                    .video{background: url('../imgs/ico/ic_video_conversation_l_normal.png') no-repeat center center;}   
                    .cal{background: url('../imgs/ico/ic_calendar_l_normal.png') no-repeat center center;}   
                    .todo{background: url('../imgs/ico/ic_todo_01_l_normal.png') no-repeat center center;}                       
                    .report{background: url('../imgs/ico/ic_report_l_normal.png') no-repeat center center;}   
                    .mail{background: url('../imgs/ico/ic_mail_l_normal.png') no-repeat center center;}   
                    .msg{background: url('../imgs/ico/ic_msg_l_normal.png') no-repeat center center;}   
                    .cmt{background: url('../imgs/ico/ic_comment_l_normal.png') no-repeat center center;}      
                    .sms{background: url('../imgs/ico/ic_sms_l_normal.png') no-repeat center center;}
                    .fax{background: url('../imgs/ico/ic_print_l_normal.png') no-repeat center center;}        
                    > span {line-height: 20px;color:#4a4a4a; margin-top: 5px;}   
                    &::after {content: '';position: absolute;right:0px; top:8px; height:27px;width: 1px;background-color:#dce2eb;}   
                    &:last-child::after {display: none;}   
                }   
                li:hover{   
                    > span {color:#1c90fb;}   
                    .video{background: url('../imgs/ico/ic_video_conversation_l_over.png') no-repeat center center;}   
                    .cal{background: url('../imgs/ico/ic_calendar_l_over.png') no-repeat center center;}   
                    .todo{background: url('../imgs/ico/ic_todo_01_l_over.png') no-repeat center center;}   
                    .report{background: url('../imgs/ico/ic_report_l_over.png') no-repeat center center;}   
                    .mail{background: url('../imgs/ico/ic_mail_l_over.png') no-repeat center center;}   
                    .msg{background: url('../imgs/ico/ic_msg_l_over.png') no-repeat center center;}   
                    .cmt{background: url('../imgs/ico/ic_comment_l_over.png') no-repeat center center;}
                    .sms{background: url('../imgs/ico/ic_sms_l_over.png') no-repeat center center;}
                    .fax{background: url('../imgs/ico/ic_print_l_over.png') no-repeat center center;}          
                }   
            }   
        }  
    }    
}

// 프로필 팝업 (2024고도화)
.newProfilePopupWrap{
    .profileHeader{width:100%;height:50px;padding:0 31px;box-sizing: border-box;background-color:#1c90fb;
        h1{font-size:18px;line-height:50px;color:#ffffff;letter-spacing:-0.9px;}
        .closeBtn{margin-top:16px;width:18px;height:18px;background:url('../imgs/ico/ic_close_01_m_white.png') no-repeat center;cursor:pointer;}
    }
    .profileContents{padding:20px 31px 31px 31px;
        .profileBtns {align-items: center;margin-bottom:6px;
            .line{margin: 0 10px; border-right:1px solid #e6e6e6; height:16px;}
            .icoGift {background:url("../imgs/ico/ic_gift_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important; background-size: 18px !important;}   
            .icoVideo {background:url("../imgs/ico/ic_video_conversation_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-size: 18px !important;} 
            .icoLink {background:url("../imgs/ico/ic_link_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-size: 18px !important;}   
            .mygroup {background:url("../imgs/ico/ic_mygroup_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-size: 18px !important;}    
            .myInfo {background:url("../imgs/ico/ic_setting_basic_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-size: 18px !important;}   
            .mail {background:url("../imgs/ico/ic_mail_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-size: 18px !important;}   
            .msg {background:url("../imgs/ico/ic_msg_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-size: 18px !important;}    
            .comment {background:url("../imgs/ico/ic_comment_m_normal@2x.png") no-repeat left 4px top 4px, linear-gradient(to top, #f0f0f0, #ffffff) !important;background-size: 18px !important;} 
        }
        .profileBox{margin:0 0 10px 0;padding:21px;min-height:235px;border-radius: 4px; border: solid 1px #e6e6e6; background-color: #f9fafc; box-sizing: border-box;
            .profileImg {position:relative;text-align: center;align-items:center;
                .img{width:120px; height:156px; background:url('../imgs/empty/profile_personaldata_noimg_02.png') no-repeat center center; border-radius: 4px;box-sizing: border-box;overflow:hidden;
                    img{width:100%; height:100%; border-radius: 4px;}   
                }   
                .name{max-width: 120px; font-weight: bold; margin-top: 7px; font-size: 14px; line-height: 15px;}   
                .profile_label{position:absolute;bottom:-12px;min-width: 45px; max-width: 130px; height: 18px;padding: 0 8px 0 7px; text-align: center; line-height: 18px; border-radius: 9px; font-size: 11px; letter-spacing: -1.1px; color: #ffffff; background: #9da3aa; box-sizing: border-box;}
            }
            .profileCon {padding: 0 0 0 22px; font-size:11px; color:#4a4a4a;   
                ul{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;min-width:0;-webkit-flex:1;flex:1;
                    &:last-child{
                        li{padding-bottom: 0px;}
                    }
                    li{line-height:18px;padding-left:23px;color:#4a4a4a; vertical-align: top; -webkit-flex:1;flex:1; min-height:18px; padding-bottom: 16px; word-break: break-word; min-width: 0;      
                        > [class*="OBTTooltip_root"] {display:flex;}            
                        &.icon01{background:url("../imgs/ico/ic_comp_02_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon02{background:url("../imgs/ico/ic_user_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon03{background:url("../imgs/ico/ic_mobile_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon04{background:url("../imgs/ico/ic_phone_01_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon05{background:url("../imgs/ico/ic_mail_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon06{background:url("../imgs/ico/ic_address_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon07{background:url("../imgs/uc/ico_profile_icon07.png") no-repeat top 0px left; background-size: 18px 16px;}   
                        &.icon08{background:url("../imgs/ico/ic_birthday_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon09{background:url("../imgs/ico/ic_pc_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon10{background:url("../imgs/ico/ic_print_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon11{background:url("../imgs/ico/ic_career_m_normal@2x.png") no-repeat top 1px left 2px; background-size: 14px 14px;}   
                        &.icon12{background:url("../imgs/ico/ic_sns_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;}   
                        &.icon13{background:url("../imgs/ico/ic_mail_personal_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //개인메일   
                        &.icon14{background:url("../imgs/ico/ic_organization_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //조직도
                        &.icon15{background:url("../imgs/ico/ic_join_date_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //입사일      
                        &.icon16{background:url("../imgs/ico/ic_employee_num_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //사번      
                        &.icon17{background:url("../imgs/ico/ic_emergency_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //비상연락망      
                        &.icon18{background:url("../imgs/ico/ic_home_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //집전화      
                        &.icon19{background:url("../imgs/ico/ic_status_messages_m_normal@2x.png") no-repeat top 0px left; background-size: 18px 18px;} //상태메시지      
                    }   
                }           
            }
        }
        .profileEtcBox{padding:21px;min-height:144px;border-radius: 4px; border: solid 1px #e6e6e6; background-color: #ffffff; box-sizing: border-box;
            .profileMenu{
                .etcTitle{margin:0 0 21px 0;height:18px;line-height:18px;font-size:12px;font-weight:bold;color:#000000;}
                .etcMenuSlide{margin:0 0 12px 0;
                    .prevArrow{margin:0 10px 0 0;width:18px;height:18px;background:url("../imgs/ico/ic_arrow_left_01_m_normal.png") no-repeat center;cursor:pointer;
                        &:hover{background:url("../imgs/ico/ic_arrow_left_01_m_over.png") no-repeat center;}
                        &.disabled{background:url("../imgs/ico/ic_arrow_left_01_m_disable.png") no-repeat center;}
                    }
                    .nextArrow{margin:0 0 0 10px;width:18px;height:18px;background:url("../imgs/ico/ic_arrow_right_01_m_normal.png") no-repeat center;cursor:pointer;
                        &:hover{background:url("../imgs/ico/ic_arrow_right_01_m_over.png") no-repeat center;}
                        &.disabled{background:url("../imgs/ico/ic_arrow_right_01_m_disable.png") no-repeat center;}
                    }
                    ul{overflow:hidden;scroll-behavior: smooth;
                        li{text-align: center;min-width:44px;box-sizing:border-box;cursor:pointer;
                            .icon{width: 24px; height: 24px; margin: 0 auto;}
                            .calendar{background: url('../imgs/ico/ic_calendar_l_normal.png') no-repeat center center;}   
                            .todo{background: url('../imgs/ico/ic_todo_01_l_normal.png') no-repeat center center;}   
                            .report{background: url('../imgs/ico/ic_report_l_normal.png') no-repeat center center;}   
                            .mail{background: url('../imgs/ico/ic_mail_l_normal.png') no-repeat center center;}   
                            .msg{background: url('../imgs/ico/ic_msg_l_normal.png') no-repeat center center;}   
                            .comment{background: url('../imgs/ico/ic_comment_l_normal.png') no-repeat center center;}   
                            .approval{background: url('../imgs/ico/ic_proval_l_normal.png') no-repeat center center;}   
                            .oneffice{background: url('../imgs/ico/ic_profile_oneffice_l_normal.png') no-repeat center center;}   
                            .onechamber{background: url('../imgs/ico/ic_onechamber_l_normal.png') no-repeat center center;}   
                            .kiss{background: url('../imgs/ico/ic_kiss_l_normal.png') no-repeat center center;}   
                            .crm{background: url('../imgs/ico/ic_crm_l_normal.png') no-repeat center center;}   
                            .calc{background: url('../imgs/ico/ic_calculator_l_normal.png') no-repeat center center;}   
                            > span {margin-top:7px;line-height: 18px;font-size: 12px;color:#4a4a4a;letter-spacing: -0.3px;}   
                        }   
                        li:hover{   
                            > span {color:#1c90fb;}   
                            .calendar{background: url('../imgs/ico/ic_calendar_l_over.png') no-repeat center center;}   
                            .todo{background: url('../imgs/ico/ic_todo_01_l_over.png') no-repeat center center;}   
                            .report{background: url('../imgs/ico/ic_report_l_over.png') no-repeat center center;}   
                            .mail{background: url('../imgs/ico/ic_mail_l_over.png') no-repeat center center;}   
                            .msg{background: url('../imgs/ico/ic_msg_l_over.png') no-repeat center left -1px;}   
                            .comment{background: url('../imgs/ico/ic_comment_l_over.png') no-repeat center center;}   
                            .approval{background: url('../imgs/ico/ic_proval_l_over.png') no-repeat center center;}   
                            .oneffice{background: url('../imgs/ico/ic_profile_oneffice_l_over.png') no-repeat center center;}   
                            .onechamber{background: url('../imgs/ico/ic_onechamber_l_over.png') no-repeat center center;}   
                            .kiss{background: url('../imgs/ico/ic_kiss_l_over.png') no-repeat center center;}   
                            .crm{background: url('../imgs/ico/ic_crm_l_over.png') no-repeat center center;}   
                            .calc{background: url('../imgs/ico/ic_calculator_l_over.png') no-repeat center center;}   
                        }   
                        li.disabled{   
                            .calendar{background: url('../imgs/ico/ic_calendar_l_disable.png') no-repeat center center;}   
                            .todo{background: url('../imgs/ico/ic_todo_01_l_disable.png') no-repeat center center;}   
                            .report{background: url('../imgs/ico/ic_report_l_disable.png') no-repeat center center;}   
                            .mail{background: url('../imgs/ico/ic_mail_l_disable.png') no-repeat center center;}   
                            .msg{background: url('../imgs/ico/ic_msg_l_disable.png') no-repeat center left -1px;}   
                            .comment{background: url('../imgs/ico/ic_comment_l_disable.png') no-repeat center center;}   
                            .approval{background: url('../imgs/ico/ic_proval_l_disable.png') no-repeat center center;}   
                            .oneffice{background: url('../imgs/ico/ic_profile_oneffice_l_disable.png') no-repeat center center;}   
                            .onechamber{background: url('../imgs/ico/ic_onechamber_l_disable.png') no-repeat center center;}   
                            .kiss{background: url('../imgs/ico/ic_kiss_l_disable.png') no-repeat center center;}   
                            .crm{background: url('../imgs/ico/ic_crm_l_disable.png') no-repeat center center;}   
                            .calc{background: url('../imgs/ico/ic_calculator_l_disable.png') no-repeat center center;}   
                        }   
                    }
                }
            }
            .profileDoc{margin:0 0 0 20px;padding:0 0 0 20px;min-width:304px;border-left:1px solid #e6e6e6;box-sizing:border-box;
                .etcTitle{margin:0 0 14px 0;height:18px;line-height:18px;font-size:12px;font-weight:bold;color:#000000;}
                .etcDocList {
                    ul{
                        li{margin:8px 0 0 0;
                            &:first-child{margin:0;}
                            .title{padding:0 0 0 24px;font-size:12px;font-weight:normal;height:18px;line-height:18px;color:#000000;cursor:pointer;overflow:hidden;}
                            .date{margin:0 0 0 34px;font-size:12px;font-weight:normal;letter-spacing:-0.3px;height:18px;line-height:18px;color:#a6a6a6;}
                        }
                    }
                }
            }
            &.small{padding:20px 21px;min-height:fit-content;border-radius: 4px; border: solid 1px #e6e6e6; background-color: #ffffff; box-sizing: border-box;
                .profileMenu{
                    .etcTitle{margin:0 40px 0 0;height:18px;line-height:18px;font-size:12px;font-weight:bold;color:#000000;}
                    .etcMenuSlide{margin:0;
                        ul{overflow:hidden;scroll-behavior: smooth;gap:20px;}
                    }
                }   
            }
        }
    }
    .profileConPop{position: absolute;width: 100%;height: 100%;inset: auto;
        .dim{content:'';position: absolute;width: 100%;height: 100%;inset: auto;background-color:rgba(0, 0, 0, 0.5);}
        ul{position: relative;z-index: 1;border-radius: 5px;box-shadow: 0px 5px 10px 0 rgba(6, 6, 6, 0.2);background-color: #ffffff;overflow:hidden;
            li{min-width: 280px;height: 46px;padding: 0 20px 0 49px;box-sizing: border-box;cursor: pointer;border-top:1px solid #e6e6e6;
                &:first-child{border:none;}
                &:hover{color:#1c90fb;}
                &.ico01{background:url("../imgs/ico/ic_mail_m_normal@2x.png") no-repeat left 20px center; background-size: 18px;}  
                &.ico02{background:url("../imgs/ico/ic_mail_personal_m_normal@2x.png") no-repeat left 20px center; background-size: 18px;}
            }
        }

    }
}

/**************************************************  
시스템설정 공통  
**************************************************/  

/* 검색영역 */ 
.UJBTopBox{position:relative;border:solid #e6e6e6;border-top-color:#666666;border-width:2px 1px 0 1px;background-color:#fafafa;  
    .searchBox {padding:8px 12px;border:none;overflow: hidden;  
        dl{margin:8px 0 0 0;overflow:hidden;  
            &:first-child{margin:0;}  
            dt{line-height:27px;color:#4a4a4a;box-sizing:border-box;text-align: right;padding-right:6px;font-size:13px;letter-spacing: -0.05em;float:left;padding-left:20px;vertical-align: middle;}     
            dt.btnDt {text-align: left;padding-left: 8px;}         
            dd{float:left;vertical-align: middle;height:27px;
                &.posi_re [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:40px !important;
                    &.notFind [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:0px !important;}
                }
                .posi_re [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:40px !important;
                    &.notFind [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:0px !important;}
                }
            }     
        }     
        .find_sp {position:absolute;right:1px; top:50%;margin-top:-10px; line-height:1;color:#1c90fb;font-size:12px;height: 20px; line-height: 20px; padding-right: 5px; padding-left: 5px; background: #fff;}                 
    }  
    .titleBox{padding:0 12px;height:28px;line-height:28px;}     
    & + [class*="OBTTreeView_root"]{border:1px solid #e6e6e6} 
}

/* 소탭 스타일 */
.UJBTabStyle{
    .tabCustom{  
        > div > div > div {border-bottom:none !important;} 
        > div > div > div > div > ul > div:first-child > li > button > span:first-child{background:none !important;} 
        > div > div > div > div > ul > div > li > button {border-bottom:none !important; font-size:14px !important; width: max-content !important;     min-width: min-content !important; text-align: left !important; padding: 0 10px !important;}  
        > div > div > div > div > ul > div:first-child > li > button {padding-left:0px !important;}  
        > div > div > div > div > ul > div > li > button > span { top: 8px !important; bottom: 8px !important;}
    }
}  
.UJBTabs{
    .UJBTab{font-size: 14px; letter-spacing: -0.7px; color: #4a4a4a; cursor: pointer;
        &::after{content: ''; display: inline-block; width: 1px; height: 11px; background-color: #e6e6e6; margin: 0 10px; cursor: default;}
        &:last-child::after{display: none;}
        &.on{font-weight: bold; color: #1c90fb;}
    }
}

/* 카드리스트 */
.cardList.UJBCard{height: calc(100% - 110px) !important; border-top: none !important;
    & > div > div:first-child{display: none;}
    & > div > div + div > div > div > div{padding-top: 0;}
    & > div > div + div > div > div > div ul > li{position: relative; border-radius: 3px;}
    & > div > div + div > div > div > div ul > li .selectedBorder{border-radius: 3px;}
}
.pubCardListStyle{
    &.UJBQwMenuList{border-top: 2px solid #666666; background: #fafafa;
        [class*="OBTPagination_pageRight"] {display:none;}   
        [class*="OBTCardList_checkstyle"]{position: absolute; top: 50%; left: 17px; transform: translateY(-50%); padding-right: 0;}
        .qwMenu{width: 100%; box-sizing: border-box;
            &.disa{position: relative; background-color: #f1f1f1 !important; color: #a6a6a6;}
            [class*="OBTCheckBox2_root"]{margin-right: 0 !important;}
            .dataBox{align-items: center; padding-left: 17px;
                [class*="OBTTooltip_root"]{max-width: fit-content;}
                .data1{font-size: 12px; line-height: 15px; color: #a6a6a6; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
                .data2{height: 19px; line-height: 18px; font-size: 13px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
            }
            .qw{position: relative; height: 100%; padding: 10px 17px 10px 0; justify-content: space-around; box-sizing: border-box;
                .sc{position: relative; width: 8px; height: 8px; border-radius: 30px;
                    [class*="OBTTooltip_root"]{position: absolute; top: 0; left: 0; width: 8px; height: 8px;}
                    &.both{background-color: #67ccb5;}
                    &.uc{background-color: #4eabfa;}
                    &.erp{background-color: #f48da5;}
                }
            }
        }   
    }
}

.UJBFilterBox{position: absolute;top:28px;right:0px;width:288px;padding:15px 16px;border:1px solid #a6a6a6;background-color: #ffffff;box-sizing: border-box;z-index: 1;
    .title{margin:0 0 10px 0;font-size:13px;line-height:18px;color:#000000;    
        span{font-size:11px;line-height:18px;color:#a6a6a6;margin:0 0 0 10px;}    
    }    
   
    .filter-align{margin: 0 0 14px 0;padding: 0 0 14px 0;border-bottom: 1px solid #e7e7e7;overflow: hidden;    
    .alignWrap{    
        .btn{font-size:12px;line-height:27px;text-align: center;color:#000000;border:1px solid #cccccc;border-color:#cccccc transparent #cccccc #cccccc;cursor: pointer;transition: 0.3s;    
            &:first-child{border-radius: 2px 0px 0px 2px;border-color:#cccccc transparent #cccccc #cccccc;}    
            &:last-child{border-radius: 0px 2px 2px 0px;border-color:#cccccc #cccccc #cccccc #cccccc;}    
            &.on{color:#ffffff;border:1px solid #1c90fb;background-color: #1c90fb;}    
            &.disabled{color:#cccccc;border:1px solid #cccccc;background-color: #f5f5f5;cursor: not-allowed;}    
        }    
    }    
    }    
    .filter-state{margin: 0 0 14px 0;padding: 0 0 14px 0;border-bottom: 1px solid #e7e7e7;overflow: hidden;    
    .stateWrap{    
        .btn{min-width: 90px; padding:0 0 0 33px;font-size:12px;line-height:40px;text-align: left;color:#000000;border:1px solid #e6e6e6;border-radius: 3px;background: #ffffff url("../imgs/ico/ic_check_02_m_disable.png") no-repeat left 10px center;cursor: pointer;transition: 0.3s;    
            &:nth-child(1){margin:2px 2px 2px 0px;}    
            &:nth-child(2){margin:2px 0px 2px 2px;}    
            &.on{border:1px solid #1c90fb;background: #e9f5ff url("../imgs/ico/ic_check_03_m_over.png") no-repeat left 10px center;}    
        }    
    }    
    }    
    .filter-fn{    
        .btnRefresh{font-size:11px;line-height:21px;text-align: left;color:#7c7c7c;padding:0 0 0 20px;background: url("../imgs/ico/ic_reset_s_normal.png") no-repeat left 0px center;cursor: pointer;
            &:hover{color:#1c90fb;background: url("../imgs/ico/ic_reset_s_over.png") no-repeat left 0px center;}
        }    
        .btnSave{border:1px solid #dbdbdb;}    
    }     
} 

.topBoxUJB{
    [class*="OBTConditionPanel_primary_"]{min-width: 363px !important; max-width: 600px !important;}
    [class*="OBTConditionPanel_primaryFunctions"]{flex: 1 !important; justify-content: flex-end !important;}
    [class*="OBTConditionItem_label"]{min-width: 60px !important;}
    [class*="OBTConditionItem_item"]{min-width: 110px !important; flex: 1 !important;}
    [class*="OBTConditionItem_tooltipWrapper"]{min-width: 110px !important;}
    .searchBox{position:relative;
        [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:40px !important;
            [class*="OBTTextField_obtTextField"].notFind > div > div > input {padding-right:0px !important;}
        }
    }
}

// 조직코드 삭제 알럿
.systemAlert{width: 380px;    
    .conBox{width: 100%; height: 40px; margin:18px 0 0 0; background-color:#fff; border: 1px solid #e0e0e0; border-radius: 3px; box-sizing: border-box;    
        .con{justify-content: space-between; height: 16px; padding: 0 25px; font-size: 12px; letter-spacing: -0.6px; color: #8c8c8c; line-height: 40px; border-right: 1px solid #e6e6e6; box-sizing: border-box;    
            .num{font-size: 11px; letter-spacing: -0.55px;     
                &.co1{color: #f8a457;}    
                &.co2{color: #20c997;}    
                &.co3{color: #ff8787;}    
                em{margin: -1px 2px 0 0; font-size: 16px; font-weight:bold; letter-spacing: -0.8px;}    
            }    
            &:last-child{border-right: none;}    
        }    
    }    
    .subBox{margin: 10px 0 0 0; width: 100%; height: 152px; text-align: left; border: 1px solid #d4dfe7; background-color: #f3f7fa; box-sizing: border-box;    
        .txtWrap{padding: 10px 12px;
            .txt{margin: 10px 0 0 0; font-size: 11px; color: #000000;    
                &:first-child{margin: 0;}   
            }    
        }
    }    
}   

//ID변경 팝업
.UJBP009{
    .pubFormStyle{
        tbody{
            td{word-break: break-all;}
        }
    }
}

// 업데이트, 업데이트목록   
.UJBP029{
    .GridType01{margin-top: 5px;
        .gridHeader thead th{padding: 12px 0;}
        .gridBody tbody td{padding: 11px 0;}
    }
    .titleUnitBox{position: relative;height:46px;padding:9px 0 8px 0;box-sizing:border-box;border-bottom:2px solid #000000;    
        h3{position: relative;padding:0 0 0 7px;font-size:14px;font-weight:bold;line-height:32px;color:#000000;letter-spacing:-0.7px;text-overflow:ellipsis;white-space: nowrap;overflow: hidden;    
           &::before{content:'';position: absolute;top:15px;left:0;width:3px;height:3px;border-radius:3px;background-color: #000000;}    
        }    
    }   
    .refBox{background:url('../imgs/ico/ic_info_03_m_normal@2x.png') no-repeat top 1px left 0px; background-size: 18px 18px;   
        .infoTxt{margin:0 0 0 22px;font-size:12px;line-height:18px;color:#4a4a4a;}   
    }   
    .updateText{height:40px;border:1px solid #e6e6e6;font-size:12px;line-height:40px;color:#000000;text-align:center;}   
    .no_list {height:100%; box-sizing:border-box; text-align:center; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #8c8c8c; letter-spacing:-0.8px;   
        &.border { border:1px solid #e6e6e6;}   
        .noUpdateList{background:url("../imgs/empty/img_update_system.png") no-repeat center center; padding-top: 120px; margin-top: -45px;}   
    }   
    .updateBox{padding: 10px; border-radius: 4px; border: solid 1px #ececec; background-color: #f7fafc; text-align: center;      
        > span {padding-left:25px;}      
        .ico_success {background:url('../imgs/ico/ico_confirm_03.png') no-repeat left center;}  
        .ico_warning {background:url('../imgs/ico/ico_pend.png') no-repeat left center;}  
    }  
}   

// 공지사항 팝업
.UJBP030pop{
    ._isDialog > div:last-child{overflow: hidden; border: none !important;
        .dialog_content .dialog_data{
            [class*="OBTDialog_data"]{overflow: visible !important;
                [class*="OBTDialog_dataWrapper"]{bottom: 0 !important;}
            }
        }
    }
}
.UJBP030new{
    .inner{margin: 10px 0 0 0; border: 1px solid #e6e6e6; box-sizing: border-box;
        .UJBTabStyle .tabCustom > div > div > div:first-child > div{padding-left: 10px;}
        .cardWrap{width: 372px; padding: 20px; box-sizing: border-box;
            &.UJBTabStyle{padding: 12px 20px 20px;
                > div > div > div > div:last-child{padding-top: 5px !important; max-height: calc(100% - 30px) !important;}
            }
            .pubCardListStyle{border-top: none;
                .cardList{border-top: 2px solid #666666;
                    [class*="OBTCardList_virtualCardList"] > div{padding: 10px 0;}
                    > div > div + div > div > div > div{padding-top: 0 !important;}
                    .selectedList .flexBox .imgWrap{background-color: #191c24;
                        img{opacity: 1;}
                    }
                    .flexBox{padding: 10px 17px; box-sizing: border-box;
                        p{margin: 0 15px 0 0; font-size: 13px; letter-spacing: -0.33px;
                            &.imgWrap{width: 36px; height: 36px; margin: 0 10px 0 0; border-radius: 6px; background-color: #333948;
                                img{width: 100%; height: 100%; opacity: 0.4; transition: opacity 0.5s;}
                            }
                        }
                        .title, .text{
                            > [class*="OBTTooltip_root"]{display: block !important; width: fit-content; max-width: 100%;}
                        } 
                        .title{line-height: 18px; font-size: 13px; letter-spacing: -0.33px;}
                        .text{font-size: 12px; color: #a6a6a6;}
                    }
                }
            }
        }
        .conWrap{padding: 20px; border-left: 1px solid #e6e6e6; box-sizing: border-box;
            .conTop{position: relative; margin: 10px 0 0 0;
                .tabCustom > div > div > div:first-child > div{margin-right: 80px !important;}
                [class*="OBTTabs_buttonGroup"]{top: 2px !important; right: 30px !important;}
                .btnWrap{position: absolute; top: 0; right: 0; z-index: 10;}
                [class*="OBTTabs_template"]{border-top: 2px solid #666666;}
                [class*="OBTAccordion2_root"]:first-child [class*="OBTAccordion2_wrapper"] [class*="OBTAccordion2_accordion"]{border-top-width: 0 !important;}
                [class*="OBTAccordion2_accordion"]{
                    .statusLabel{font-weight: normal !important;}
                } 
                [class*="OBTAccordion2_contents"] .accIn{padding: 11px; border: 1px solid #f5f5f5; box-sizing: border-box;}
            }
            .conTitle{padding: 0 0 20px 0; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;
                p{position: relative; max-width: fit-content; font-size: 16px; font-weight: bold; letter-spacing: -0.8px;
                    &:hover{cursor: pointer;
                        span{text-decoration: underline;}
                        .newPageIcon::after{display: inline-block;}
                    }
                    .newPageIcon{position: relative; display: inline-block; width: 18px; height: 18px; padding-left: 6px; vertical-align: middle; background: url('../imgs/ico/ic_write_newpage_m_normal.png') no-repeat top -1px center;
                        &::after{content: ''; position: absolute; bottom: 2px; left: 0; display: none; width: 100%; height: 1px; background-color: #000000;}
                    }
                }
                .date{margin: 0 0 0 4px; font-size: 12px; color: #8c8c8c;}
            }
            .conBox{height: 100%;
                img{width: 100% !important; height: auto !important;}
            }
            .countWrap{padding: 1px 0 9px 10px; border-bottom: 2px solid #666666;
                .allCount{line-height: 25px; font-size: 14px; font-weight: bold; letter-spacing: -0.7px; color: #1c90fb;}
            }
            .accLabelDiv{
                .labelWrap{
                    &::after{content: ''; display: inline-flex; width: 1px; height: 11px; margin: 0 10px; background-color: #e6e6e6;}
                    .rightLabel{height: 16px; padding: 0 3px; margin: 0 4px 0 0; line-height: 14px; font-size: 11px; letter-spacing: -0.55px; border: 1px solid #a6a6a6; border-radius: 2px; color: #4a4a4a; box-sizing: border-box;
                        &:last-child{margin: 0;}
                    }
                }
                .icoWrap{
                    &::after{content: ''; display: inline-flex; width: 1px; height: 11px; margin: 0 10px; background-color: #e6e6e6;}
                    .attach{padding: 0 0 0 15px; line-height: 20px; color: #7d7d7d; background: url('../imgs/ico/ic_attach_s_normal@2x.png') no-repeat left center; background-size: 14px;}
                }
                .count{font-size: 12px; color: #000000;}
            }
        }
    }
    .btBtn{justify-content: space-between; position: relative; left: -30px; width: calc(100% + 60px); height: 40px; padding: 0 0 0 30px; margin: 30px 0 0 0; background-color: #50596c; box-sizing: border-box;
        > p{width: 86px; height: 100%; line-height: 38px; font-size: 14px; text-align: center; letter-spacing: -0.7px; color: #ffffff; background-color: #394357; cursor: pointer;}
    }
    .no_list {height:100%; box-sizing:border-box; text-align:center; display: flex; align-items: center; justify-content: center; font-size: 16px; color: #8c8c8c; letter-spacing:-0.8px;   
        &.small{font-size: 12px;
            .noUpdateList{background-size: 60px; padding-top: 70px; margin-top: -35px;}
        }
        &.border { border:1px solid #e6e6e6;}   
        .noUpdateList{background:url("../imgs/empty/img_update_system.png") no-repeat center center; padding-top: 120px; margin-top: -45px;}   
    }
}

// 비밀번호 찾기   
.UJBP032{       
    .passFind{   
        .passBg{text-align:center; display: flex; align-items: center; justify-content: center; font-weight:bold; font-size: 15px; margin-top:-40px; padding-top:160px;   
            .sText{color:#a6a6a6; font-size:12px;font-weight: normal; height:20px;}   
        }   
        .bg01{background:url('../imgs/ico/img_id.png') no-repeat center center;}   
        .bg02{background:url('../imgs/ico/img_pw.png') no-repeat center center;}   
        .bg03{background:url('../imgs/ico/img_mail_send.png') no-repeat center center; background-size: 100px 100px;}   
        .bg04{background:url('../imgs/ico/img_manager_ask_left.png') no-repeat center center; padding-top:180px !important;} 
        .bg05{background:url('../imgs/ico/img_pw_reset.png') no-repeat center center;} 
   
        .inputBox01{padding: 18px 50px; border: solid 1px #e6e6e6;box-sizing: border-box;   
            em{font-weight:bold; margin-right: 10px;}   
        }   
        .inputBox02{background:url('../imgs/ico/ic_manager_ask.png') no-repeat left 20px center; background-size: 32px 32px; padding: 20px 30px 20px 63px; border: solid 1px #e6e6e6; font-size: 13px; border-radius: 3px; box-sizing: border-box;   
            &:hover, &:active, &.on{box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16); border: solid 1px #1c90fb; background-color:#eff7ff; cursor: pointer;}   
            em{font-weight:bold; margin-right: 10px;}   
            &.mail{background:url('../imgs/ico/ic_certified_num_send.png') no-repeat left 20px center !important; background-size: 32px 32px;
                &:hover, &:active{background:#eff7ff url('../imgs/ico/ic_certified_num_send.png') no-repeat left 20px center !important;}
            }
            &.mobile{background:url('../imgs/ico/ic_verifi_mobile@2x.png') no-repeat left 20px center !important; background-size: 32px 32px !important;
                &:hover, &:active, &.on{background:#eff7ff url('../imgs/ico/ic_verifi_mobile@2x.png') no-repeat left 20px center !important; background-size: 32px 32px !important;}
                &.disabled{background:#f5f5f5 url('../imgs/ico/ic_verifi_mobile_disable@2x.png') no-repeat left 20px center !important; background-size: 32px 32px !important;}
            }
        }   
    }   
   
    .passFindBox{height:42px; width: 100%; display: flex; flex-direction: column; justify-content: center;   
        ul{display: flex; flex-wrap:wrap;       
            li { float: left; width:151px; height: 42px; text-align: center; border-top:1px solid #e6e6e6;border-bottom:1px solid #e6e6e6;box-sizing: border-box; padding: 10px 0 0 0px; background: #f5f5f5;margin-left: 16px; font-size:13px; position: relative;   
                &:before {content:'';display:inline-block; position:absolute;   
                    background: url("../imgs/bg/bg_eapline02_before.png") no-repeat;background-size:16px 42px;left:-16px;top:-1px;width: 16px;height:42px; z-index:1; border-top:1px solid #e6e6e6;border-bottom:1px solid #e6e6e6; box-sizing: border-box;}   
                &:after {content:'';display:inline-block; position:absolute;   
                    background: url("../imgs/bg/bg_eapline02_after.png") no-repeat;background-size:16px 42px;right:-16px;top:-1px;width: 16px;height:42px;box-sizing: border-box; z-index:1;}   
                .num{width: 20px;height: 20px;margin: 0 6px 0 0;padding: 2px 4px 3px; text-align:center; background-color: #e6e6e6; color:#a6a6a6; border-radius: 50% 50%; font-size:11px;}   
                .text{color:#a6a6a6;}   
            }   
            li.on {border-bottom:1px solid #1c90fb;border-top:1px solid #1c90fb; background: #e9f5ff;box-sizing: border-box;       
                &:before {content:'';display:inline-block; position:absolute;   
                    background: url("../imgs/bg/bg_eapline01_blue_before.png") no-repeat;background-size:16px 42px;width: 16px;height:42px; z-index:1; border-top:1px solid #1c90fb;border-bottom:1px solid #1c90fb;box-sizing: border-box;   
                }   
                &:after {content:'';display:inline-block; position:absolute; background: url("../imgs/bg/bg_eapline02_blue.png") no-repeat;background-size:16px 42px;width: 16px;height:42px; z-index:2;}     
                .num{width: 20px;height: 20px;margin: 0 6px 0 0;padding: 2px 4px 3px; text-align:center; background-color: #1c90fb; color:#fff; border-radius: 50% 50%;font-size:11px;}   
                .text{color:#1c90fb;}     
            }       
            li.on + li {   
                &:before {content:'';display:inline-block; position:absolute; background: url("../imgs/bg/bg_eapline02_before.png") no-repeat;background-size:16px 42px;width: 16px;height:42px;z-index:1;}   
            }          
            li:first-child {   
                &::before{ border-top:1px solid #e6e6e6;border-left:1px solid #e6e6e6;border-bottom:1px solid #e6e6e6;background: #f5f5f5;}   
            }   
            li.on:first-child {   
                &::before{ border-top:1px solid #1c90fb;border-left:1px solid #1c90fb;border-bottom:1px solid #1c90fb;background: #e9f5ff;}   
            }               
            li:last-child {   
                &::after{ border-top:1px solid #e6e6e6;border-right:1px solid #e6e6e6;border-bottom:1px solid #e6e6e6;background: #f5f5f5;box-sizing: border-box;}   
            }   
            li.on:last-child {   
                &::after{ border-top:1px solid #1c90fb;border-right:1px solid #1c90fb;border-bottom:1px solid #1c90fb;background: #e9f5ff;box-sizing: border-box;}   
            }   
        }   
    }  
    .passwordSet{
        dl{margin-bottom:10px;
            &:last-child{margin-bottom: 0px;}
            dt{min-width:120px; text-align: right; line-height: 27px; font-weight: bold;}
            dd{margin-left:6px;color:#4a4a4a;   
                    span{color:#1c90fb;}   
                    &.textRed{color:#fc5356;}  
            }
        }
    } 
}  

// 비밀번호 재설정 안내   
.UJBP033{       
    .passSet{   
        .bg{text-align:center; display: flex; align-items: center; justify-content: center; font-weight:bold; font-size: 15px; background:url('../imgs/ico/img_manager_reset.png') no-repeat top 70px center; line-height:23px; padding: 190px 0 50px 0;   
            .sText{color:#a6a6a6; font-size:12px; font-weight: normal; line-height: 1.33; margin-top: 1px;}          
            &.ask{background:url('../imgs/ico/img_manager_ask_left.png') no-repeat top 70px center; padding: 190px 0 30px 0;} // 원격로그인 승인
            &.remote{background:url('../imgs/ico/img_pw_reset.png') no-repeat top 70px center; padding: 190px 0 30px 0;} // 원격로그인 승인 요청
        }     
        .inputBox{border: solid 1px #e6e6e6; box-sizing: border-box; padding:18px 50px 18px 0;   
            dl{margin-bottom:10px; display:flex;-ms-display:flex; height:100%; display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;min-width:0;   
                dt{width: 150px; float: left; text-align: right; padding-right: 6px; color:#010101;font-weight:bold;padding-top: 5px;}   
                &:last-child{margin-bottom:0px;}   
            }   
            &.ask dl dt{width: 128px;}
        }   
        .passSetInfo{margin-top:10px; border: solid 1px #d4dfe7; background-color: #f3f7fa;box-sizing: border-box;padding: 15px;   
            ul{   
                li{color:#4a4a4a;   
                    span{color:#1c90fb;}   
                    &.textRed{color:#fc5356;}   
                }   
            }   
        }   
    }   
}  

//추가권한설정 팝업
.UJBP039{
    .codeInputBox{position: relative; width: 100%;
        .title{line-height: 27px; color: #4a4a4a; font-size: 13px; min-width: 102px; text-align: right;  margin-right: 6px;}
        .textInput > div > div {padding:3px 24px 3px 6px !important;}     
        .codeInput > div > div > div > span{padding:3px 24px 3px 6px !important;}     
        .codeInputBtn{position: absolute;top:4px;right:5px;width:18px;height:18px;background: url("../imgs/ico/ic_code_m_normal.png") no-repeat center;cursor: pointer;     
            &:hover{background: url("../imgs/ico/ic_code_m_over.png") no-repeat center;}     
        }     
    } 
}

// 조직권한수정 팝업
.UJBP041{padding-top: 10px;
    .permIco{display: inline-block; width: 18px; height: 18px; margin: 0 5px; background: url('../imgs/ico/ic_parent_permission@2x.png') no-repeat center center; background-size: 18px;}
}

/************************************************** 
   시스템설정 
**************************************************/ 
// 라이선스 정보 아코디언
.licenseWrap{
    .licenseTitle{height: 40px; padding: 0 21px; border-radius: 4px; border: 1px solid #d5dce8; background-color: #f2f5fa; box-sizing: border-box; overflow: hidden;
        &.on{border-radius: 4px 4px 0 0; border-bottom: none;}
        p{font-size: 12px; color: #000000; margin: 0 4px 0 0;}
        .infoBtn{width: 18px; height: 18px; background: url('../imgs/ico/ic_info_01_m_normal.png') no-repeat center center; cursor: pointer;
            &:hover{background: url('../imgs/ico/ic_info_01_m_over.png') no-repeat center center;}
        }
        .arrClose{width: 14px; height: 14px; background: url('../imgs/ico/ic_refer_arrow_up_normal@2x.png') no-repeat center center; background-size: 14px; cursor: pointer;}
        .arrOpen{width: 14px; height: 14px; background: url('../imgs/ico/ic_refer_arrow_down_normal@2x.png') no-repeat center center; background-size: 14px; cursor: pointer;}
    }
    .licenseBox{position: relative; padding: 14px 21px 21px; border-radius: 0 0 4px 4px; border: 1px solid #d5dce8; background-color: #f2f5fa; box-sizing: border-box; overflow: hidden;
        ul{width: 100%; padding: 20px 0; white-space: nowrap; border-radius: 4px; background-color: #ffffff; box-shadow: 0 2px 5px 0 rgba(219, 228, 235, 0.71); box-sizing: border-box; overflow: hidden; scroll-behavior: smooth;
            li{display: inline-flex; min-width: 136px; max-width: 227px; height: 60px; align-items: center; text-align: center; padding: 0 24px; border-right: 1px solid #e6e6e6; box-sizing: border-box;
                &:last-child{border-right: none;}
                .noLicense{width: 100%; height: 100%; border-radius: 2px; background-color: #fafafa;}
                .type{font-size: 13px; font-weight: bold; letter-spacing: -0.33px; color: #000000;}
                .count{font-size: 15px; letter-spacing: -0.38px; color: #4a4a4a;
                    em{font-weight: bold; color: #1c90fb;}
                }
                .sub{font-size: 13px; letter-spacing: -0.33px; color: #a6a6a6;}
            }
        }
        .arrLeft{position: absolute; top: 50%; left: 9px; transform: translateY(-50%); width: 24px; height: 24px; border-radius: 12px; background: rgba(0, 0, 0, 0.25) url('../imgs/ico/ic_arrow_left_01_w@2x.png') no-repeat center center; background-size: 14px; cursor: pointer;
            &:hover{background: rgba(0, 0, 0, 0.4) url('../imgs/ico/ic_arrow_left_01_w@2x.png') no-repeat center center; background-size: 14px;}
        }
        .arrRight{position: absolute; top: 50%; right: 9px; transform: translateY(-50%); width: 24px; height: 24px; border-radius: 12px; background: rgba(0, 0, 0, 0.25) url('../imgs/ico/ic_arrow_right_01_w@2x.png') no-repeat center center; background-size: 14px; cursor: pointer;
            &:hover{background: rgba(0, 0, 0, 0.4) url('../imgs/ico/ic_arrow_right_01_w@2x.png') no-repeat center center; background-size: 14px;}
        }
    }
}

// 라이선스 정의 팝업
.UJAP016{
    ul{width: 100%; height: 100%; margin: 10px 0 0 0; padding: 13px 16px; border-radius: 3px; border: 1px solid #d4dfe7; background-color: #f3f7fa; overflow: hidden; box-sizing: border-box;
        li{font-size: 12px; color: #4a4a4a; margin: 0 0 10px 0;
            &:last-child{margin: 0;}
            p{font-weight: bold; color: #000000;}
        }
    }
}

// 상용직관리 카드리스트
.workerCardList{position: relative;
    .workerCard{width: 100%;
        .imgBox{width: 32px; height: 32px; border-radius: 50%; overflow: hidden;
            img{width: 100%; height: 100%;}
        }
        .topTxt{color: #4a4a4a;}
    }
}
// 상용직관리
.workerWrap{
    .titleBox{border-bottom: 1px solid #666666;}
    .titBtn{gap: 4px;
        .line{width: 1px; height: 16px; margin: 0 2px; background-color: #e6e6e6;}
        .btn_clo{width: 18px; height: 18px;
            img{width: 18px; height: 18px;}
        }
    }
    .tabBox{height: 44px;}
} 
.userSignBox{position: relative; width: 100%; height: 100%; overflow: hidden; text-align: center; margin: 0 auto; align-items: center; 
    ul{width:100%; height: 100%; white-space: nowrap; text-align: center; overflow: hidden; scroll-behavior: smooth; height:100%; 
        li{display: inline-grid;align-content:center;gap:5px; width:86px; height:90px; margin: 0 3px 0 3px; border-radius: 3px; border: solid 1px #e0e0e0; box-sizing:border-box;  
            > div > div > span {margin-right:0px !important; margin-left: 2px;} 
            &:hover{border-color:#1c90fb;} 
            .stamp_default{width: 46px; height: 46px; background: url("../imgs/bg/EA_confirm_bg.gif") no-repeat center center; background-size: 46px 46px;  margin: 0 auto; overflow: hidden; word-break: break-all;line-height: 49px;white-space:break-spaces;} 
        } 
    } 
    .arrLeft{width: 24px;height: 24px; background:url('../imgs/ico/ic_arrow_left_01_l_normal.png') no-repeat center center; background-size: cover; cursor: pointer; 
        &:hover{background:url('../imgs/ico/ic_arrow_left_01_l_over.png') no-repeat center center;} 
    } 
    .arrRight{width: 24px;height: 24px; background:url('../imgs/ico/ic_arrow_right_01_l_normal.png') no-repeat center center; background-size: cover; cursor: pointer; 
        &:hover{background:url('../imgs/ico/ic_arrow_right_01_l_over.png') no-repeat center center;} 
    } 
 
    // 사인등록 
    .stampBox {overflow: hidden; word-break: break-all;} 
    .stampBox .pic {position: relative; width:46px; height:46px; border-radius: 4px; border: solid 1px #e6e6e6;margin:0 auto;box-sizing: border-box; background:#eaeaea;} 
    .stampBox .pic .pic_img {background: #fff; text-align: center;position:absolute; z-index: 2; top:2px;} 
    .stampBox .pic .pic_img> img {max-width:49px; max-height:49px;background: #fff;} 
    .stampBox .pic:hover .btn_set {display:block;} 
    .stampBox .btn_set {display:none;width: 100%;height: 100%;position:absolute;left:0;top:0;} 
    .stampBox .btn_set .opaci {width: 100%;height:100%;background: #000;  position:absolute;left:0;top:0;background-color: rgba(0, 0, 0, 0.2);z-index:3;} 
    .stampBox .txt {position:absolute;top:50%;margin-top: -9px;width: 82px;display:inline-block;left:50%;margin-left: -41px;z-index: 1;} 
    .stampBox .btn_set .btns {position:absolute;top:10px;z-index: 4;width: 62px;left:50%;margin-left:-31px;} 
    .stampBox .btn_set .btnImg {margin: 0 2px;} 
} 

// 사업장관리
.UJA1030card{width: 100%; padding: 13px 18px; box-sizing: border-box;
    .box{font-size: 12px; color: #666666;}
    .nameBox{font-size: 13px; margin-top: 10px;
        .name1{margin-right: 10px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
        .name2{text-align: right; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}
    }
}
 
// 사진등록 
.singUpload{       
    .img{width:85px; height:90px; border:solid 1px #b5b5b5; background:url('../imgs/empty/pic_Noimg.png') no-repeat center center; background-size:100% 100%; 
        img{width:77px; height:82px; padding: 4px; background: #fff; margin-top: 0px !important;} 
        .txt{position: absolute; text-align: center; width: 85px; bottom: 10px;} 
    } 
} 

/*화면설정*/ 
.UJA3010{
    .borderForm{padding: 20px;}
    .pubAccordionStyle [class*="OBTAccordion2_title"] .so_txt{font-weight: normal;}
    
    .tit_sp{ 
        &.on{color: #1c90fb;} 
    } 
    .pubFormStyle .so_txt {color:#4a4a4a; font-size:12px; font-weight: normal; margin: 0;} 
    
    .disp_div {
        .pre_div { float: left; box-sizing: border-box; 
            .memberViewType{float: left; padding: 8px 0 0 20px; width: 310px; height: 31px; border: 1px solid #e6e6e6; border-bottom: none; 
                &::after {content:"";display:block;clear:both;height:0;visibility:hidden;} 
                ul{overflow: hidden; 
                    li{float: left; width: 45px; height: 20px; line-height: 20px; font-size: 11px; border: 1px solid #e0e0e0; text-align: center;cursor: pointer; 
                        &:first-child{border-width: 1px; border-top-left-radius:3px; border-bottom-left-radius: 3px;} 
                        &:last-child{border-width: 1px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; margin-right: 10px;} 
                    } 
                    .on{border: 1px solid #1c90fb; color:#007ff4; background-color:#eff7ff;} 
                } 
            } 
        } 
        .form_div { float: left; padding-left: 20px; box-sizing: border-box; 
            .sysBox{align-items: center;}
            .colorBox{position: relative; width: 27px; height: 27px; padding: 3px; margin-left: 2px; border: 1px solid #cccccc; box-sizing: border-box; cursor: pointer;
                .colorInBox{display: inline-block; width: 19px; height: 19px; border: 1px solid #cccccc; box-sizing: border-box;}
                .UJA3010color{position: absolute; top: -27px; right: 27px; z-index: 10;}
            }
            .tool{width: 18px; height: 18px; margin-left: 4px;
                .refBox{display: inline-block; width: 18px; height: 18px;}
            }
            .fwn{width: 14px; margin: 0 4px 0 0;}
        } 
    } 
    
    .disp_tab { 
        > div{ 
            > div{ 
                >div:nth-child(2) {overflow:auto;} 
            } 
        } 
    } 
}
.preview_bg {position:relative; 
    &.login{width: 676px; height: 424px; margin-top: 10px; border: 1px solid #e6e6e6; box-sizing: border-box; overflow: hidden;
        .memberViewType{float: left; width: 100%; height: 44px; border-bottom: 1px solid #e6e6e6;
            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;} 
            ul{overflow: hidden; margin: 11px 0 0 20px; 
                li{float: left; height: 20px; padding: 0 8px; line-height: 20px; font-size: 11px; border: 1px solid #e0e0e0; text-align: center;cursor: pointer; 
                    &:first-child{border-width: 1px; border-top-left-radius:3px; border-bottom-left-radius: 3px;} 
                    &:last-child{border-width: 1px; border-top-right-radius: 3px; border-bottom-right-radius: 3px;} 
                } 
                .on{border: 1px solid #1c90fb; color:#007ff4; background-color:#eff7ff;} 
            } 
        }
        .pv_pic{width: 676px; height: 380px; overflow: hidden;
            .bg_login{z-index: 1; position: absolute; bottom: 0; right: 0; width: 156px; height: 380px; padding: 123px 18px 0; background: url('../imgs/bg/login_1920_bg.png'); box-sizing: border-box;
                p{padding-bottom: 26px; font-size: 9px; font-weight:bold; letter-spacing: -0.09px; color: #2e3645;}
                .input{width: 100%; height: 18px; border-radius: 9px; border: 0.5px solid #c4c4c4; background-color: #ffffff;}
                .btn{width: 100%; height: 19px; margin-top: 16px; line-height: 19px; text-align: center; color: #ffffff; font-size: 6px; font-weight:bold; letter-spacing: -0.06px; border-radius: 3px; box-shadow: 0 2px 10px 0 rgba(61, 69, 86, 0.2); background-image: linear-gradient(to right, #0af 4%, #8168ff 95%); box-sizing: border-box;}
                
                &.login1280{right: 45px; padding: 105px 24px; width: 202px; height: 378px; background: url('../imgs/bg/login-1280-bg.png');
                    p{padding-bottom: 34px;}
                    .input{height: 23px; border-radius: 12px;}
                    .btn{height: 24px; margin-top: 20px;}
                }
            }
            .logoTxtBox{z-index: 10; position: absolute; left: 24px; top: 90px;
                .logo_div {line-height: 30px;} 
                .logo_img {vertical-align: middle;} 
                .txt {margin-top: 37px; font-size: 15px; line-height: 1.33; letter-spacing: -0.15px;color:#ffffff;} 
                .txt2 {margin-top: 17px; font-size: 10px; line-height: 1.33; letter-spacing: -0.15px;color:#ffffff;} 
                .copy {margin-top: 150px; font-size: 9px; line-height: 1.33; letter-spacing: -0.15px;color:rgba(255,255,255, 0.2);} 
                
                &.box1280{left: 80px; top: 108px;
                    .txt{margin-top: 18px;}
                    .txt2{margin-top: 6px}
                    .copy{margin-top: 160px;}
                }
            }
            .bg{position: absolute; bottom: 0; left: 0; width:676px; height: 380px;
                &.bg1280{left: 50px; width: 579px; height: 378px;}
            } 
        } 
    } 
    &.main{margin-top: 10px; 
        .pv_pic{width:676px;height:444px;overflow:hidden; border: 1px solid #e6e6e6; 
            .pv_pic_bg { 
                img{width: 676px; height: 444px;} 
            } 
            .logo_div {position:absolute;left:35px; top:3px;} 
            .logo_img {vertical-align: middle;} 
            .txt {position:absolute;top: 140px; font-size: 15px;  font-weight: bold; color: #ffffff; display: flex; justify-content: center; width: inherit;} 
            .txt2 {position:absolute;top:166px; font-size: 12px; line-height: 0.58; color: #ffffff; display: flex; justify-content: center; width: inherit;} 
            .mainBG{position: absolute; z-index: -1; top: 0; left: 0; width: 100%; height: 100%;} 
        } 
    } 

    &.sub{height:447px; 
        .logo_div {position:absolute;left:35px; top:0px;line-height:27px;} 
        .logo_img {vertical-align: middle;} 
        .pv_pic_bg {position:absolute;top:0px;left:0;right:0px;bottom:0px; 
            background: url("../imgs/bg/img_frame_sub.png") no-repeat;width:100%; height:100%; 
        } 
    } 

    &.mobile{border: 1px solid #e6e6e6; margin-top: 10px; width: 100%; height: 100%; box-sizing: border-box; overflow: hidden; 
        .pre_btn{width: 100%; height: 44px; border-bottom: 1px solid #e6e6e6; box-sizing: border-box; 
            .memberViewType{  
                &::after {content:"";display:block;clear:both;height:0;visibility:hidden;} 
                ul{overflow: hidden; margin: 11px 0 0 20px; 
                    &:last-child{margin-left: 0;} 
                    li{float: left; width: 45px; height: 20px; line-height: 20px; font-size: 11px; border: 1px solid #e0e0e0; text-align: center;cursor: pointer; 
                        &:first-child{border-width: 1px; border-top-left-radius:3px; border-bottom-left-radius: 3px;} 
                        &:last-child{border-width: 1px; border-top-right-radius: 3px; border-bottom-right-radius: 3px; margin-right: 10px;} 
                    } 
                    .on{border: 1px solid #1c90fb; color:#007ff4; background-color:#eff7ff;} 
                } 
            } 
        } 
        .pv_pic{width:678px;height:448px;overflow:hidden; 
            .pv_pic_bg {margin-top: 28px; 
                img{display: block; width: 185px; height: 391px; margin: 0 auto;} 
            } 
            .logo_div {position:absolute;left:50%; top:160px; transform: translateX(-50%);} 
            .logo_img {vertical-align: middle;} 
            
            .pv_pic_bg_phone_main{position: relative; width: 183px; margin: 33px auto 0; padding: 30px 0 30px 0; border: 1px solid #e6e6e6; box-sizing: border-box; 
                img{width: 181px; height: 317px;} 
                .logo_img_phone_main{position: absolute; top: 62px; left: 4px; z-index: -1; width: 173px; height: 203px;} 
            } 

            .pv_pic_bg_tablet_login{margin-top: 28px; 
                img{display: block; width: 522px; height: 391px; margin: 0 auto;} 
            } 
            .logo_div_tablet_login{position:absolute; left: 50%; top:160px; transform: translateX(-50%);} 
            .logo_img_tablet_login{vertical-align: middle;} 

            .pv_pic_bg_tablet_main{position: relative; width: 520px; margin: 33px auto 0; padding: 60px 15px 30px; border: 1px solid #e6e6e6; box-sizing: border-box; 
                img{width: 491px; height: 285px;} 
                .logo_img_tablet_main{position:absolute; z-index: -1; left: 99px; top: 60px; width: 239px; height: 285px;} 
            } 
            .logo_div_tablet_main{position:absolute; left:100px; top:110px;} 
            .logo_img_tablet_logo{vertical-align: middle;} 
        } 
    } 

    &.pc{ 
        .memberViewType{float: left; margin: 10px 0 0 0; width: 676px; height: 44px; border: 1px solid #e6e6e6; border-bottom: none; 
            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;} 
            ul{overflow: hidden; margin: 11px 0 0 20px; 
                li{float: left; width: 45px; height: 20px; line-height: 20px; font-size: 11px; border: 1px solid #e0e0e0; text-align: center;cursor: pointer; 
                    &:first-child{border-width: 1px; border-top-left-radius:3px; border-bottom-left-radius: 3px;} 
                    &:last-child{border-width: 1px; border-top-right-radius: 3px; border-bottom-right-radius: 3px;} 
                } 
                .on{border: 1px solid #1c90fb; color:#007ff4; background-color:#eff7ff;} 
            } 
        } 
        .pv_pic_win{width:678px;height:448px;overflow:hidden; position: absolute; top: 55px; 
            .logo_img1_div_win {position:absolute; top:30px;left:100px;text-align: center;width: 224px; height: 391px;} 
            .logo_img1_div_in_win {line-height:30px;margin-top:85px;} 
            .logo_img1_win {vertical-align: middle;} 
            .logo_img2_win {vertical-align: middle;} 
            .pv_pic_bg_win {position:absolute;top:0px;left:0;right:0px;bottom:0px; background: url("../imgs/bg/img_pcmessenger_window.png") no-repeat center center;width:100%; height:100%; background-size: 100% 100%;} 
            .logo_div_win {position:absolute;left:384px; top:30px;line-height: 22px;} 
        } 
        .pv_pic_mac{width:678px;height:448px;overflow:hidden; position: absolute; top: 55px; 
            .logo_img1_div_mac {position:absolute; top:28px;left:113px;text-align: center;width: 208px; height: 391px;} 
            .logo_img1_div_in_mac {line-height:30px;margin-top:85px;} 
            .logo_img1_mac {vertical-align: middle;} 
            .logo_img2_mac {vertical-align: middle;} 
            .pv_pic_bg_mac {position:absolute;top:0px;left:0;right:0px;bottom:0px; background: url("../imgs/bg/img_pcmessenger_mac.png") no-repeat center center;width:100%; height:100%; background-size: 100% 100%;} 
            .logo_div_mac {position:absolute;left:428px; top:28px;line-height: 20px;} 
        } 
    }   
    
    &.onechamber{ 
        .memberViewType{float: left; margin: 10px 0 0 0; width: 676px; height: 44px; border: 1px solid #e6e6e6; border-bottom: none; 
            &::after {content:"";display:block;clear:both;height:0;visibility:hidden;} 
            ul{overflow: hidden; margin: 11px 0 0 20px; 
                li{float: left; width: 45px; height: 20px; line-height: 20px; font-size: 11px; border: 1px solid #e0e0e0; text-align: center;cursor: pointer; 
                    &:first-child{border-width: 1px; border-top-left-radius:3px; border-bottom-left-radius: 3px;} 
                    &:last-child{border-width: 1px; border-top-right-radius: 3px; border-bottom-right-radius: 3px;} 
                } 
                .on{border: 1px solid #1c90fb; color:#007ff4; background-color:#eff7ff;} 
            } 
        } 
        .one_pic_win{width:678px; height:448px; overflow:hidden; top: 55px; border:1px solid #e6e6e6; box-sizing: border-box;             
            .bg_win {width: 326px; height: 388px; background-size: 326px 388px !important; background: url("../imgs/bg/img_onechamber_login_w@2x.png") no-repeat left top; margin: 30px 10px 30px 40px;
                .logo_win{padding: 62px 0 0 0; justify-content: center; align-items: center; width: 100%; height: 36px; display: flex;}
            } 
            .bg_pop{position:relative; width:262px; height:152px; background-size: 262px 152px !important; background: url("../imgs/bg/img_onechamber_popup_w@2x.png") no-repeat left top; margin: 265px 0 0 0;
                .logo_pop{justify-content: center; align-content: center; height: 32px; display: inline-grid; padding: 0 0 0 8px;}
            }
        } 
        .one_pic_mac{width:678px;height:448px;overflow:hidden; position: absolute; top: 55px; 
            .bg_mac {position:absolute;top:0px;left:0;right:0px;bottom:0px; background: url("../imgs/bg/img_ci_mac@2x.png") no-repeat center; width:100%; height:100%; background-size: 100% 100%;
                .logo_mac{position:absolute; display: inline-grid; justify-content: center; align-items: center;
                    &.logo01{height: 21px; margin: 175px 0 0 30px; width: 195px;}
                    &.logo02{height: 10px; margin:86px 0 0 236px; width: 410px;}
                }
            } 
        } 
    }  
} 
 
//사원정보엑셀업로드 
.UJA8050{position:relative; 
    .infoBox{height: 90px; border-radius:3px; border: solid 1px #d4dfe7; background-color: #f3f7fa; box-sizing: border-box; padding: 10px 20px; margin:20px 0 0 0; overflow-y:auto; word-break: break-all;} 
    .container{height: 100%;} 
    .userDown{position: absolute; top: 0px; z-index: 100; right: 0;} 
}    

// 메뉴설정    
.UJB2010{ 
    .gnbListBox{position:relative;border:solid #e6e6e6;border-top-color:#666666;border-width:2px 1px 1px 1px;background-color:#fafafa;box-sizing: border-box; 
        ul{padding:13px;box-sizing: border-box; 
            li{position:relative;margin:0 0 6px 0;height:64px;border:1px solid #e0e0e0;border-radius:3px;background-color:#ffffff;padding:9px 11px;box-sizing: border-box; 
                &.on, 
                &:hover{border:1px solid #1c90fb;background-color:#eff7ff;box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);cursor: pointer;} 
                &:hover .icoMove{display:flex;cursor:ns-resize;} 
                .icoMove{display:none;position:absolute;top:0;left:0;width:16px;height:100%;background:url('../imgs/ico/ic_cardlist_movebar@2x.png') no-repeat center;background-size:16px;} 
                .icoWrap{width:48px;height:44px;margin:0 4px 0 0;justify-content:center;align-items:center; 
                    .icoDiv{width:36px;height:36px;border-radius:6px;background-color:#333948; 
                        img{width:100%;height:100%;} 
                    } 
                } 
                .menuName{margin:11px 0;font-size:13px;letter-spacing:-0.32px;color:#000000;} 
            } 
        } 
    } 
    .pubFormStyle{ 
        [class*="OBTTextField_obtTextField"].bgRequired > div:first-child, 
        [class*="OBTTextField_obtTextField"].bgRequired > div:first-child > div:first-child{background-color:#fef4f4 !important;} 
        .addFileList{margin:9px 0 0 0;width:100%;height:18px;line-height:18px; 
            .fileName{font-size:12px;line-height:18px;color:#000000;} 
            .fileIco{margin:0 3px 0 0;width:14px;height:18px;background:url('../imgs/ico/ic_attach_s_normal.png') no-repeat center;background-size:14px;} 
            .deleteIco{margin:0 0 0 3px;width:14px;height:18px;background:url('../imgs/ico/ic_trashcan_s_normal.png') no-repeat center;background-size:14px;cursor: pointer;} 
        } 
        .UJB2010TextLinkBox{width: 100%;height: 27px;border-radius: 0px; 
            .textBox{padding: 5px 6px 0 6px;min-height: 27px;line-height: 13px;border-radius: 0px; 
                .keepLink{max-width:calc(100% - 20px)} 
            } 
        } 
        .codeInputBox{position: relative;     
            .textInput > div > div {padding:3px 24px 3px 6px !important;}     
            .codeInput > div > div > div > span{padding:3px 24px 3px 6px !important;}     
            .codeInputBtn{position: absolute;top:4px;right:5px;width:18px;height:18px;background: url("../imgs/ico/ic_code_m_normal.png") no-repeat center;cursor: pointer;     
               &:hover{background: url("../imgs/ico/ic_code_m_over.png") no-repeat center;}     
            }     
         } 
    } 
    .formTitleFn{ 
        .line{width:1px;height:16px;background-color:#e6e6e6;margin:6px 6px 0 6px;}    
        .closeBtn{width:18px;height:27px;background: url('../imgs/ico/ic_close_01_m_normal.png') no-repeat center;cursor: pointer;     
            &:hover{color:#319df6;background: url('../imgs/ico/ic_close_01_m_over.png') no-repeat center;}     
         } 
    } 
    .icoSetBox{ border-top: 1px solid #e6e6e6; margin-top: 10px; padding-top: 10px; box-sizing: border-box;
        ul{max-height: 120px;
            &::-webkit-scrollbar {width:5px;background-color: #f5f5f5;} /* 스크롤바 전체 */  
            &::-webkit-scrollbar-thumb {background-color: #dcdcdc;border-radius: 5px;} /* 스크롤바 휠 */    
            &::-webkit-scrollbar-thumb:hover {background-color: #a0a0a0;} /* 스크롤바 휠 마우스 오버 시 */
            li{width:52px;height:51px; justify-content:center;align-items:center; border:1px solid #e6e6e6; display: inline-flex; box-sizing: border-box; cursor: pointer; margin:0 5px 5px 0;
                &:last-child{margin-right:0px;}
                &.on{background-color: #eff7ff; border-color:#1c90fb;}
                p{width:36px;height:36px;border-radius:6px;background-color:#333948;
                    img{width:100%;height:100%;} 
                } 
            }
        }
    } 
}

// 권한Role설정
.UJB2020{
    [class*="OBTTitle_container"]{margin-bottom: 0;}
    [class*="OBTTitle_subLabelTextField"]{font-weight:bold; font-size: 14px !important; margin-top: 0 !important;}
    
    .editBtn{margin-left: 4px;}
    .helpTool{position: absolute; left: 88px; top: 0;}
    .searchBox{position:relative;
        &.posi{position: absolute; right: 108px; top: 3px;}
        .searchBtn{position: absolute;top:4px;right:0;width:30px;height:20px;background: url("../imgs/ico/ic_search_m_normal.png") no-repeat center;cursor: pointer;   
            &:hover{background: url("../imgs/ico/ic_search_m_over.png") no-repeat center;}   
            &.disabled{background: url("../imgs/ico/ic_search_m_disable.png") no-repeat center;}
        }   
        [class*="OBTTextField_obtTextField"] > div > div > input {padding-right:40px !important;
            [class*="OBTTextField_obtTextField"].notFind > div > div > input {padding-right:0px !important;}
        }
    }
    .serBox{width: 100%; height: 78px; padding: 10px 14px; border: 2px solid #e0e0e0; box-sizing: border-box;}
    .GridType01 .gridHeader thead{height: 34px;}
    .GridType01 .gridHeader thead th{padding: 0;}
    .GridType01 .gridBody tbody td{height: 32px; padding: 0 10px; border-right: 1px solid #e6e6e6;
        &:last-child{border-right: none; padding: 0 5px;}
    }
    .GridType01 .gridBody tbody td > div{line-height: 12px; width: 100%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;} 
    .UJBQwMenuList .icoBox{width: 18px; height: 18px; margin: 0 10px 0 0;
        .permIco{width: 18px; height: 18px; background: url('../imgs/ico/ic_parent_permission@2x.png') no-repeat center center; background-size: 18px;}
    } 
}

// 권한설정
.UJB2050{
    .groupListMenu{margin-top:5px; height: 27px;
        p{position:relative; display:inline-flex; align-items: center; min-width: 36.5px; text-align:center; cursor: pointer; border:1px solid #e6e6e6; box-sizing: border-box; background-image: linear-gradient(to top, #f0f0f0, #ffffff); margin-left:-1px; -webkit-flex: 1; justify-content: center; flex: 1;
            &.on{color:#007ff4; border-color:#1c90fb; background-image: linear-gradient(to top, #d0ebff, #edf6ff);
                &::after{content:""; position: absolute; right:0; border-right: 1px solid #1c90fb; height: 27px; box-sizing: border-box;}
            }
            &:first-child{margin-left:0px;}
            &:last-child.on{
                &::after{content:""; border-right:none; height: 27px;}
            }
        }
    }
    .UJBQwMenuList{
        .qwSearch{
            &.h135{height:135px !important;}
        }  
        .top108{top: 108px !important; display: inline-flex;}         
    }

    .qwCd_sort{
        &.tab2{min-width: max-content; margin-right: 5px;}
    }
    
    //카드리스트
    .UJBCard{
        &.h122{height: calc(100% - 122px) !important;}
        &.h158{height:calc(100% - 158px) !important;}  
    }
    .dataBox{
        .useSet{font-size: 11px; padding: 0 3px; color: #4a4a4a; border-radius: 2px; border: solid 1px #a6a6a6; box-sizing: border-box; letter-spacing: -0.5px; margin-right:6px; align-items: center; margin-top: 1px; display: flex; background-color: #fff;}
    }
    //그리드
    [class*="OBTTitle_container"]{margin-bottom: 0;}
    [class*="OBTTitle_subLabelTextField"]{font-weight:bold; font-size: 14px !important; margin-top: 0 !important;}
    .helpTool{position: absolute; left: 88px; top: 0;}

    .userAddBox{padding: 10px; border: solid 2px #e0e0e0; box-sizing: border-box; margin:10px 0 10px 0;}
    .tabCustom{height:auto !important;
        > div {display: inline-block !important;}
    }    
}

// 접근가능IP설정
.UJB3060{
    .card_FilterBox{position: relative; padding:19px 0; margin: 0 13px 10px; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;
        .sortSet{
            &.on {   
                .UJBFilterBox {display:block;}   
            }
        }
        .UJBFilterBox{display: none;} 
    } 
    .cd_tit {
        &.qwCd_tit{top: 10px; left: 0px;}   
    }   
    .cd_sort {
        &.qwCd_sort{top: 10px; right: 0px; padding: 0;}
    }

    //카드리스트
    .UJBCard{height: calc(100% - 69px) !important;}    
    
    .typeBox{padding: 0 10px;
        > div{margin-bottom:5px;
            &:last-child{margin-bottom:0px}
        }
        .typeUnit{min-width: 40px; height: 18px; border-radius: 10px; padding: 0 6px; font-size: 11px; line-height: 18px; color: #fff; text-align: center;
            &.type1{background-color: #46a3f0;}    
            &.type2{background-color: #20c997;}   
        }  
    }
}

//용량관리
.UJB3070{   
    .graph{position:relative;width:400px;height:400px;margin:0 auto;   
        .total{position: absolute;top: 50%;left: 50%;margin:-110px 0 0 -60px;width: 120px;height: 120px;border-radius: 100px;background-color: #ffffff;   
            .text{font-size:14px;font-weight:bold;line-height:120px;color: #000000;text-align: center;}   
        }   
    }   
    .pubFormStyle{   
        .ico{width:14px;height:14px;margin:2px 0 0 5px;background: url('../imgs/ico/ic_survey_s_normal.png') no-repeat center;cursor: pointer;   
            &:hover{background: url('../imgs/ico/ic_survey_s_over.png') no-repeat center;}   
        }   
    }   
    .tabTitle{margin:0 0 10px 10px;   
        .tit{font-size:14px;line-height:1;color: #4a4a4a;letter-spacing: -0.7px;cursor: pointer;   
            &.on{font-size:14px;font-weight:bold;line-height:1;color:#1c90fb;}   
        }   
        .line{width:1px;height:11px;margin:2px 10px 0 10px;background-color: #e6e6e6;}   
    }   
    .gcmsBtn{   
        img{margin-right: 5px !important;}   
    }   
}   
   
// 이차인증설정
.UJB3030{
    .pubFormStyle{
        > table tbody td.orgTd{padding: 0 !important;
            .radioBox{padding: 0 8px 10px;}
            .chipBox{border-top: 1px solid #e6e6e6; padding: 10px 8px; box-sizing: border-box;}
        }
    }
}

/***************************** 
    마이페이지 
******************************/ 
.myPageWrap { padding: 0 20px; 
    .content_form_titlebox { width: 100%; border: 1px solid #dcdcdc; box-sizing: border-box; 
        .form_title { padding: 0px; margin: 0px; height: 36px; border: none; border-bottom: 1px solid #dcdcdc; background: #f9f9f9; 
            h5 { font-size: 12px; font-weight: bold; line-height: 36px; padding-left: 22px; background: url("../imgs/ico/ico_dot01.png") no-repeat 15px 16px; box-sizing: border-box;} 
        } 
        .form_container {position: relative; 
            .form_leftbox { width: 200px; min-height: 720px; padding: 15px; box-sizing: border-box; box-sizing: border-box; 
                ul { 
                    li { border: 1px solid #dcdcdc; margin: 0 0 10px 0; box-sizing: border-box; } 
                    li.profile_img {overflow: hidden; 
                        .profile_imgbox { display: table-cell; width: 169px; height: 169px; text-align: center; vertical-align: middle; background: #f9f9f9; 
                            img { width: 60%; max-width: 169px; max-height: 169px; } 
                        } 
                    } 
                    li.sign_img {overflow: hidden; 
                        .sign_imgbox { display: table-cell; width: 169px; height: 169px; text-align: center; vertical-align: middle; background: #f9f9f9; 
                            .stamp { margin: 0px auto; width: 33px; height: 35px; padding: 1px 3px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ea4949; border: 5px solid #ea4949; border-radius: 50%; line-height: 18px; letter-spacing: -2px; overflow: hidden; word-break: break-all; } 
                            .stamp2 { margin: 0px auto; width: 33px; height: 35px; padding: 1px 3px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ffffff; background: #ea4949; border: 5px solid #ea4949; border-radius: 50%; line-height: 17px; letter-spacing: -2px; overflow: hidden; word-break: break-all; } 
                            .stamp3 { margin: 0px auto; width: 33px; height: 35px; padding: 1px 3px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ea4949; border: 5px solid #ea4949; border-radius: 20%; line-height: 18px; letter-spacing: -2px; overflow: hidden; word-break: break-all; } 
                            .stamp4 { margin: 0px auto; width: 33px; height: 35px; padding: 1px 3px 0 0; text-align: right; font-size: 18px; font-family: stamp; color: #ffffff; background: #ea4949; border: 5px solid #ea4949; border-radius: 20%; line-height: 18px; letter-spacing: -2px; overflow: hidden; word-break: break-all; } 
                        } 
                    } 
                    li.controll_btn { border: none; text-align: center;} 
                } 
            } 
            .form_rightbox { position: absolute; top: 0; left: 200px; right: 0; bottom: 0; padding: 15px; border-left: 1px solid #dcdcdc; box-sizing: border-box; } 
        } 
    } 
} 

.expSp { background: url("../imgs/ico/ic_info_03_s_normal.png") no-repeat left center;padding-left: 16px;color:#1c90fb; cursor: pointer;} 

/*fido 인증*/ 
.fido_mobile_txt {font-size:15px;padding-left: 30px;background: url("../imgs/ico/ic_mobile_l_normal.png") no-repeat left center;line-height: 40px;} 
 
/************************************************** 
   시스템알림 / 업데이트 진행 / 파일 업로드 진행
**************************************************/ 
// 업데이트 로딩바 
[class*="OBTLoading_root"].updateLoading{ 
    [class*="OBTLoading_large"]{ 
        & > div:nth-child(1) > div:nth-child(2) > div > div > div > div:nth-child(2) > div > span > strong{ 
            font-size:14px !important; 
        } 
    } 
 
    [class*="OBTLoading_box"][class*="OBTLoading_large"]{ 
        & > div:first-child > div:nth-child(2) > div > div > div > div:nth-child(2) > div > span > strong{ 
            font-size:14px !important; 
        }  
    } 
} 

// 공통
.systemAlertUpdate{position: fixed; right: 30px; z-index: 99999; gap: 10px;
    .systemAlertView, .systemUpdateView{position: initial !important;}
    
    .maxBtn{position: absolute; top: 10px; right: 30px; width: 14px; height: 14px; background: url('../imgs/ico/ic_maximize_s_normal@2x.png') no-repeat center; background-size: 14px; cursor: pointer;} 
    .miniBtn{position: absolute; top: 10px; right: 30px; width: 14px; height: 14px; background: url('../imgs/ico/ic_minimize_s_normal@2x.png') no-repeat center; background-size: 14px; cursor: pointer;} 
    .closeBtn{position: absolute; top: 10px; right: 10px; width: 14px; height: 14px; background: url('../imgs/ico/ic_close_01_s_normal@2x.png') no-repeat center; background-size: 14px; cursor: pointer;} 
    
    .topUnitBox{
        .txt01{font-size: 11px; line-height: 16px; color: #ffffff; letter-spacing: -0.28px;} 
        .txt02{font-size: 11px; line-height: 16px; color: rgba(255,255,255,0.5); letter-spacing: -0.28px;}
        .txt03{font-size: 10px; line-height: 14px; color: rgba(255,255,255,0.5); letter-spacing: -0.25px;}
    }
    .btnUnitBox{ 
        .btn{width: 74px; height: 26px; padding: 0 22px 0 10px; font-size: 11px; line-height: 24px; color: #ffffff; letter-spacing: -0.55px; border: 1px solid #ffffff; border-radius: 13px; box-sizing: border-box; background: url('../imgs/main/alertView/ic_arrow_right_w_s.png') no-repeat top 7px right 10px; cursor: pointer; 
            &:first-child{margin: 0;} 
            &:hover{border: 1px solid #40b2ed; transition: 0.5s;} 
        } 
    } 
    .botUnitBox{ 
        .progressBox{ 
            &.success{
                .bar{background-color: #1c90fb;}
                .per{color: #1c90fb;}
            }
            &.fail{
                .bar{background-color: #e85e30;}
                .per{color: #e85e30;}
            }
            .progressBg{margin: 0 10px 0 0; width: 100%; height: 4px; border-radius: 50px; background-color: rgba(250, 250, 250, 0.2); overflow: hidden; 
                .bar{height: 4px; border-radius: 50px; transition: width 0.5s;} 
            } 
            .per{min-width: 30px; height: 16px; font-size: 11px; line-height: 16px; letter-spacing: -0.28px; text-align: left;} 
        } 
    } 
}
 
// 시스템 업데이트 알림 
.systemAlertView{position:fixed;right:30px;bottom:30px;min-width:280px;width:fit-content;height:94px !important; min-height:94px !important; border-radius:5px;background-color:rgba(48,60,82,0.92);transition:all 0.5s;z-index:99999; 
    .closeBtn{position:absolute;top:10px;right:10px;width:14px;height:14px;background:url('../imgs/ico/ic_close_01_s_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;} 
    .ico{margin:29px 12px 29px 24px;width:36px;height:36px;background:url('../imgs/main/alertView/ico_noti_update.png') no-repeat; background-size: 36px;} 
    .topUnitBox{margin:0 30px 7px 0; 
        .badge{margin:0 6px 0 0;width:28px;height:16px;font-size:10px;line-height:16px;color:#ffffff;text-align:center;border-radius:2px;background-color:#ff8787;} 
        .txt{font-size:11px;line-height:16px;color:#ffffff;letter-spacing: -0.28px;} 
    } 
    .btnUnitBox{ 
        .btn{width:74px;height:26px;margin:0 0 0 6px;padding:0 22px 0 10px;font-size:11px;line-height:24px;color:#ffffff;letter-spacing: -0.55px; border:1px solid #ffffff;border-radius:13px;box-sizing:border-box; background:url('../imgs/main/alertView/ic_arrow_right_w_s.png') no-repeat top 7px right 10px; cursor: pointer; 
            &:first-child{margin:0;} 
            &:hover{border:1px solid #40b2ed;transition: 0.5s;} 
        } 
    } 
} 
 
// 시스템 업데이트 진행 토스트 
.systemUpdateView{position:fixed;right:30px;bottom:30px;transition:all 0.5s;z-index:99999; 
    // 업데이트 진행 
    .updateProgress{position: relative; width:280px;height:108px !important; min-height:108px !important; border-radius:5px;background-color:rgba(48,60,82,0.92); 
        .miniBtn{position:absolute;top:10px;right:30px;width:14px;height:14px;background:url('../imgs/ico/ic_minimize_s_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;} 
        .closeBtn{position:absolute;top:10px;right:10px;width:14px;height:14px;background:url('../imgs/ico/ic_close_01_s_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;} 
        .ico{margin:36px 12px 36px 24px;width:36px;height:36px;background:url('../imgs/ico/ico_noti_updating@2x.png') no-repeat;background-size:36px;} 
        .topUnitBox{margin:21px 30px 2px 0; 
            .txt01{margin:0 0 2px 0;font-size:11px;line-height:16px;color:#ffffff;letter-spacing: -0.28px;} 
            .txt02{font-size:10px;line-height:16px;color:rgba(255,255,255,0.5);letter-spacing: -0.28px;} 
        } 
        .botUnitBox{margin:0 30px 0 0; 
            .progressBox{ 
                .progressBg{margin:6px 10px 6px 0;width:100%;height:4px;border-radius:50px;background-color: rgba(250, 250, 250, 0.2);overflow:hidden; 
                    .bar{height:4px;border-radius:50px;background-color:#1c90fb;transition:width 0.5s;} 
                } 
                .per{min-width:30px;height:16px;font-size:11px;line-height:16px;color:#1c90fb;letter-spacing: -0.28px;text-align: left;} 
            } 
        } 
    } 
    // 업데이트 실패 
    .updateFail{position: relative; width:280px;height:108px !important; min-height:108px !important; border-radius:5px;background-color:rgba(48,60,82,0.92); 
        .closeBtn{position:absolute;top:10px;right:10px;width:14px;height:14px;background:url('../imgs/ico/ic_close_01_s_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;} 
        .ico{margin:36px 12px 36px 24px;width:36px;height:36px;background:url('../imgs/ico/ico_noti_update_fail@2x.png') no-repeat;background-size:36px;} 
        .topUnitBox{margin:21px 30px 2px 0; 
            .txt01{margin:0 0 2px 0;font-size:11px;line-height:16px;color:#ffffff;letter-spacing: -0.28px;} 
            .txt02{font-size:10px;line-height:16px;color:rgba(255,255,255,0.5);letter-spacing: -0.28px;} 
        } 
        .botUnitBox{margin:0 30px 0 0; 
            .progressBox{ 
                .progressBg{margin:6px 10px 6px 0;width:100%;height:4px;border-radius:50px;background-color: rgba(250, 250, 250, 0.2);overflow:hidden; 
                    .bar{height:4px;border-radius:50px;background-color:#e85e30;transition:width 0.5s;} 
                } 
                .per{min-width:30px;height:16px;font-size:11px;line-height:16px;color:#e85e30;letter-spacing: -0.28px;text-align: left;} 
            } 
        } 
    } 
    // 업데이트 완료 
    .updateDone{position: relative; width:280px;height:226px !important; min-height:226px !important; border-radius:5px;background-color:rgba(48,60,82,0.92);overflow: hidden; 
        .closeBtn{position:absolute;top:10px;right:10px;width:14px;height:14px;background:url('../imgs/ico/ic_close_01_s_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;} 
        .bgUnitBox{background-color:#303c52; 
            .ico{margin:31px 12px 0 24px;width:36px;height:36px;background:url('../imgs/ico/ico_noti_update_fin@2x.png') no-repeat;background-size:36px;} 
            .topUnitBox{margin:21px 30px 0 0; 
                .txt01{margin:0 0 2px 0;font-size:11px;line-height:16px;color:#ffffff;letter-spacing: -0.28px;} 
                .txt02{font-size:10px;line-height:16px;color:rgba(255,255,255,0.5);letter-spacing: -0.28px;} 
            } 
            .btnUnitBox{margin:5px 0 14px 0; 
                .btn{ 
                    width:74px;height:26px;margin:0 0 0 6px;padding:0 22px 0 10px; 
                    font-size:11px;line-height:24px;color:#ffffff;letter-spacing: -0.55px; 
                    border:1px solid #ffffff;border-radius:13px;box-sizing:border-box; 
                    background:url('../imgs/main/alertView/ic_arrow_right_w_s.png') no-repeat top 7px right 10px; 
                    cursor: pointer; 
                    &:first-child{margin:0;} 
                    &:hover{border:1px solid #40b2ed;transition: 0.5s;} 
                } 
            } 
        } 
        .infoUnitBox{padding:14px 24px 0 24px; 
            .txt01{margin:0 0 8px 0;font-size:10px;line-height:14px;color:rgba(250, 250, 250, 0.5);letter-spacing: -0.25px;} 
            .box{padding:10px;border:1px solid rgba(255, 255, 255, 0.1);border-radius:5px; 
                .ch1{padding:0 0 8px 20px;background:url('../imgs/ico/ic_customer_center_w_s_normal@2x.png') no-repeat top left;background-size: 14px;} 
                .ch2{padding:0 0 0 20px;background:url('../imgs/ico/ic_homepage_w_s_normal@2x.png') no-repeat top left;background-size: 14px;} 
                dt{margin:0 5px 0 0;font-size:11px;line-height:14px;color:rgba(250, 250, 250, 0.5);letter-spacing: -0.25px;} 
                dd{font-size:11px;line-height:14px;color:rgba(250, 250, 250, 0.8);letter-spacing: -0.5px; 
                    &.linkTxt{cursor: pointer;text-decoration: underline;} 
                } 
            } 
        } 
    } 
    // 업데이트 스몰 
    .updateSmall{width:54px;height:54px !important; min-height:54px !important; border-radius:54px;background-color:rgba(48,60,82,0.92);overflow: hidden;padding: 13px 5px 0 5px;box-sizing:border-box;cursor: pointer; 
        .progress{ 
            .pro{padding:0 0 0 14px;font-size:10px;line-height:16px;color:#ffffff;letter-spacing: -0.25px;text-align: left;background:url('../imgs/ico/ic_updating_w_s_normal@2x.png') no-repeat left;background-size:14px;} 
            .per{padding:0 0 0 5px;font-size:11px;line-height:16px;color:#1c90fb;letter-spacing: -0.28px;text-align:center;} 
        } 
        .failed{ 
            .pro{padding:0 0 0 14px;font-size:10px;line-height:16px;color:#ffffff;letter-spacing: -0.25px;text-align: left;background:url('../imgs/ico/ic_updating_w_s_normal@2x.png') no-repeat left;background-size:14px;} 
            .per{padding:0 0 0 5px;font-size:11px;line-height:16px;color:#e85e30;letter-spacing: -0.28px;text-align:center;} 
        } 
    } 
} 

// 파일 업로드 진행
.systemUploadView{transition: all 0.5s;
    .uploadProgress{position: relative; width: 280px; border-radius: 5px; background-color: rgba(48,60,82,0.92); overflow: hidden;
        .wrap{padding: 24px 24px 20px;
            &.bg{background-color: #303c52;}
            .ico{width: 36px; height: 36px; margin: 0 12px 0 0; background:url('../imgs/ico/ico_noti_uploading@2x.png') no-repeat center center; background-size: 36px;} 
            .topUnitBox{
                .txt03{margin-top: 6px;}
            }
            .botUnitBox{margin-top: 8px;}
        }
        .fileUnitBox{padding: 0 24px; box-sizing: border-box;
            li{padding: 12px 0 9px; border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                &:last-child{border-bottom: none;}
                .fileIcon{width: 16px; height: 16px; margin-right: 8px;}
                dl{
                    dt{line-height: 1; font-size: 11px; letter-spacing: -0.28px; color: rgba(255, 255, 255, 0.8);
                    }
                    dd{margin-top: 6px; line-height: 1; font-size: 10px; letter-spacing: -0.25px; color: rgba(255, 255, 255, 0.5);
                        span{display: flex; flex-direction: row; align-items: center;
                            &::after{content: ''; display: flex; width: 1px; height: 8px; margin: 0 2px 0 8px; background-color: rgba(255, 255, 255, 0.1);}
                        }
                        p{max-width: fit-content; padding: 3px 6px 3px 20px; border-radius: 8px; background: url('../imgs/ico/ic_folder_s_normal@2x.png') no-repeat left 5px center; background-size: 12px; box-sizing: border-box;
                            // &:hover{color: #ffffff; background: rgba(255, 255, 255, 0.1) url('../imgs/ico/ic_folder_s_click_hover@2x.png') no-repeat left 5px center; background-size: 12px; cursor: pointer;} 미지원 건으로 인해 주석처리 (1차 개발적용)
                            &.error{color: #ff8787; background: url('../imgs/ico/ic_erorr_bl_t@2x.png') no-repeat left 5px center; background-size: 12px; cursor: default;}
                        }
                    }
                    .lh1{max-width: 100%;} // 툴팁 썼을 때
                }
                .stateIcon{position: relative; width: 16px; height: 16px; margin-left: 4px;
                    &:hover .cancelBtn{visibility: visible;}
                    &.error{background: url('../imgs/ico/ic_error_red@2x.png'); background-size: 16px;}
                    &.confirm{background: url('../imgs/ico/ic_confirm_green@2x.png'); background-size: 16px;}
                    .graph{position: relative; width: 16px; height: 16px; margin: 0 auto; transform:rotate(-90deg);
                        .fill{animation: chart-fill 2s reverse; transform-origin: center;}
                    }
                    .cancelBtn{position: absolute; top: 0; left: 0; visibility: hidden; width: 16px; height: 16px; background: url('../imgs/ico/ico_docu_del.png') no-repeat center center; cursor: pointer;}
                }
            }
        }
    }
    .thumb-vertical{width: 4px !important; border-radius: 2px; background-color: rgba(255, 255, 255, 0.4);}
}
 
/************************************************** 
   Biz 사용자관리 (옵션에 따른 특화메뉴) 
**************************************************/ 
// 사용자 그룹관리 
.UJBB010{ 
    // 카드리스트 커스텀 
    .UJBB010CardList{background-color: #fafafa; 
        &.noSort [class*="OBTPagination_pageRight"] {display: none;} 
        .searchUnit{padding:9px 12px 8px 12px;} 
        .cardList{ 
           > div > div:first-child{height:28px !important;box-sizing: border-box;} 
           > div > div + div > div > div > div ul {height: auto !important;min-height: fit-content !important;}
           > div > div + div > div > div > div ul > li {border-radius: 3px !important;} 
           > div > div + div > div > div > div ul > li .selectedBorder{border-radius: 3px !important;} 
           > div > div + div > div > div > div ul > li > div:first-child{padding:0 17px !important;} 
           .UJBB010CardBox{margin:10px 0 0 0;position:relative; 
              & .alignMiddle{align-items: center;} 
              // text 
              .cardText01{font-size:13px;line-height:20px;color:#000000;letter-spacing: -0.32px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;} 
              .cardText02{font-size:12px;line-height:18px;color:#a6a6a6;position: relative;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;} 
            } 
        } 
    } 
    .layerBtns{position:absolute;top:0;right:0;z-index:1;height:27px;line-height:27px; 
        button.btn_clo {margin:0 0 0 5px;background:url("../imgs/ico/ic_close_01_m_normal.png") no-repeat center center !important;border:none !important;vertical-align:middle;} 
    } 
} 
 
// 사용자 초대관리 
.UJBB020{ 
    [class*="OBTConditionItem_label"]{min-width:120px;} 
} 
// 전체 사용자 
.UJBB030{ 
    [class*="OBTConditionItem_label"]{min-width:150px;} 
} 
// 사용자그룹관리 팝업 
.UJBBP03{ 
    .centerBtnWrap{margin:0 10px;justify-content:center;} 
} 
// 사용자 초대/재초대 팝업 
.UJBBP04, 
.UJBBP06{ 
    .radioGroupStyle{ 
        & > div > span{height:27px !important;line-height: 27px !important;} 
    } 
    .excelDown{width:18px;height:18px;background:url('../imgs/ico/ic_excel_save_m_normal.png') no-repeat center;cursor: pointer; 
        &:hover{background:url('../imgs/ico/ic_excel_save_m_over.png') no-repeat center;} 
    } 
} 
 
// 초대장 미리보기 
.UJBBP07{ 
    .previewBox{font-size:14px;line-height:1.5;color:#000000; 
        .title{font-size:16px;line-height:2;color:#000000;border-bottom:2px solid #222222;margin:0 0 20px 0;padding:0 0 5px 0;} 
    } 
    .text_line{cursor: pointer;} 
} 
 
/****************************************************** 
    시스템설정(Erp) 
******************************************************/ 
/*==================================================== 
    인쇄양식관리(SYB0100)
====================================================*/ 
.SYB0100 {
    /* 페이지 */
    //카드리스트
    .card-list1 > div > div {
        .tbl-tdr {width:35%;}
    }

    /* 팝업 */
    &.ly-popup {
        //파일업로더
        .UpDownLoader .flexBox.detail .icon {max-width:calc(100% - 55px);padding-left:0;background:none;//파일명영역
            &[data-orbit-component="OBTTooltip"] p {overflow:hidden;line-height:30px;text-overflow:ellipsis;}
        }
    }
}

/*==================================================== 
    모바일권한설정(SYM0010) 
====================================================*/ 
.SYM0010 {
    /* 페이지 */
    //카드리스트
    .card-list1 > div > div {
        .tbl-tdr {width:80px;}
    }
}

/************************************************** 
   마이그레이션 Migration 
**************************************************/ 
.Migration{position: relative; height: 100%; 
    .migHeaderWrap{align-items: center; 
        .main-ci{position: relative; width: 200px; height: 50px; margin: 0; padding: 5px 20px; background-color: #ffffff;  box-sizing: border-box; z-index: 101; 
            h1{overflow: hidden; margin: 0; font-size: 1; height: 40px; line-height: 40px; 
                img{max-width: 150px; max-height: 38px; vertical-align: middle; cursor: pointer;} 
            } 
        } 
        .headTabs{height: 50px; white-space: nowrap; overflow: hidden;
            .tabToolTip{width: 200px;}
            .tab{min-width: 115px; padding: 0 10px 0 25px; height: 32px; margin: 0 2px 0 0; line-height: 30px; font-size: 12px; color: #8c8c8c; letter-spacing: -0.5px; border: 1px solid #e6e6e6; border-radius: 3px; background: url('../imgs/ico/icon_tabon_new_normal@2x.png') no-repeat left 10px center; background-size: 11px; box-sizing: border-box; cursor: pointer;
                &:hover{border: 1px solid #9f9f9f; background-color: #eff7ff; transition: all 0.3s;}
                &.on{border: 1px solid #0e7add; color: #1c90fb; background: #eff7ff url('../imgs/ico/icon_tabon_new_select@2x.png') no-repeat left 10px center; background-size: 11px;}
                &.disable{color: #a6a6a6; border: 1px solid #e6e6e6; background: #f5f5f5 url('../imgs/ico/ic_write_newpage_s_disable.png') no-repeat left 10px center; background-size: 14px;
                    &:hover{border: 1px solid #e6e6e6; background: #f5f5f5 url('../imgs/ico/ic_write_newpage_s_disable.png') no-repeat left 10px center; background-size: 14px;}
                }
            }
        }
        .headfnc{justify-content: flex-end; padding: 0 20px; 
            .migUser{font-size: 14px;  
                .migName{font-weight:bold; 
                    .migId{margin-left: 6px; font-weight:normal; font-size: 12px; color: #a6a6a6;} 
                } 
            }     
        } 
    } 
    .migPageTitleBox{width: 100%; height: 50px; padding: 0 20px; align-items: center; background-color: #1c90fb; box-sizing: border-box; 
        .title{font-size: 18px; color: #ffffff;} 
        .migBtnBox{justify-content: flex-end; align-items: center; 
            .migBtn{height: 27px; padding: 5px 10px; line-height: 14px; margin-right: 4px; text-align: center; border: 1px solid rgba(255,255,255,0.5); border-radius: 2px; color: #ffffff; white-space: nowrap; box-sizing: border-box; cursor: pointer; 
                &:hover{border: 1px solid rgba(255,255,255,0.8);} 
                &.on{border: 1px solid rgba(255,255,255,0.8);} 
                &:last-child{margin-right: 0;} 
                &.import{position: relative;  
                    .importList{position: absolute; bottom: -46px; left: -1px; text-align: center; border: 1px solid #a6a6a6; color: #000000; background-color: #ffffff; z-index: 100; 
                        .list{width: 64px; padding: 3px 0; box-sizing: border-box; 
                            &:hover{background-color: #e6f5ff;} 
                            &.on{background-color: #e6f5ff;} 
                        } 
                    } 
                } 
                &.save{padding-left: 30px; background: url('../imgs/ico/ic_save_w.png') no-repeat left 10px center; background-size: 18px; box-sizing: border-box;} 
                &.start{padding-left: 30px; background: url('../imgs/ico/ic_migration_w.png') no-repeat left 10px center; background-size: 18px; box-sizing: border-box;} 
                &.compBtn{padding-left: 30px; background: url('../imgs/uc/check_off.png') no-repeat left 8px center;
                    &.on{background: url('../imgs/uc/check_on.png') no-repeat left 8px center;}
                }
            } 
            .space{align-items: center; 
                &::before{content: ''; width: 1px; height: 16px; margin: 0 10px 0 6px; background-color: rgba(255, 255, 255, 0.2);} 
                &::after{content: ''; width: 1px; height: 16px; margin: 0 10px; background-color: rgba(255, 255, 255, 0.2);} 
            } 
            .iconExcelDownNor{width: 18px; height: 18px; cursor: pointer;} 
        } 
    } 
    .topWrap{width: 100%; padding: 0 20px 10px; border-bottom: 1px solid #e5e5e5; box-sizing: border-box; 
        .titleBox{float: left; height: 42px; line-height: 42px; padding-top: 10px; align-items: center; box-sizing: border-box;
            .title{min-width: 28px; font-size: 16px; font-weight:bold; letter-spacing: -0.8px;} 
            .refreshBtn{width:18px; height:18px; margin-top: 1px; background:url('../imgs/ico/ic_refresh_m_normal.png') no-repeat center; cursor: pointer; 
                &:hover{background:url('../imgs/ico/ic_refresh_m_over.png') no-repeat center;} 
            } 
            .txt{min-width: 130px; margin-left: 10px; font-size: 12px; letter-spacing: -0.3px; color: #a6a6a6;} 
            .drop{margin-left: 10px;} 
        } 
        .processStep{float: right; text-align: center; min-width: 590px; padding-top: 10px; box-sizing: border-box;
            .scrollCheck{margin-right: 10px; justify-content: flex-end; align-items: center;
                [class*="OBTCheckBox2_root"]{margin-right: 0 !important;}
            }
            .step{position: relative; width: 80px; height:32px; border: 1px solid #e6e6e6; border-radius: 2px; line-height: 30px; font-size: 13px; letter-spacing: -0.33px; color: #4a4a4a; background-color: #ffffff; box-sizing: border-box; cursor: pointer; 
                .txtDiv{position: relative; z-index: 2; padding: 0 5px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;} 
                &:not(:first-child).on:before, &:not(:last-child):after{content:''; position:absolute; top:50%; width:18px; height:15px; margin-top:-8px; border:solid #e6e6e6; border-width:1px 1px 0 0; background: #ffffff; transform:rotate(60deg) skewX(28deg); z-index:1;} 
                &:before{left: -10px;} 
                &:after{right: -10px;} 
                &:not(:first-child, :last-child){padding-left: 5px;} 
                &.on{border-color: #1c90fb; background: #e9f5ff; color: #1c90fb; font-weight:bold; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); 
                    &:before{border-color: #1c90fb !important;} 
                    &:after{border-color: #1c90fb !important; background: #e9f5ff;} 
                } 
                &.disa{color: #a6a6a6; background-color: #f5f5f5; 
                    &:hover{color: #a6a6a6; text-decoration: none;} 
                    &:before{background-color: #f5f5f5;} 
                    &:after{background-color: #f5f5f5;} 
                } 
                &:hover{color: #1c90fb; text-decoration: underline;} 
            } 
        } 
    } 
    .conWrap{height: 100%; padding: 20px; box-sizing: border-box; 
        .boxTitle{align-items: center; 
            .cnt{font-size: 14px; font-weight:bold; letter-spacing: -0.7px; margin-top: -2px; color: #a6a6a6; 
                em{color: #1c90fb;} 
            } 
        } 
        .box01{ 
            .searchBtn{position: absolute;top:4px;right:0;width:30px;height:20px;background: url("../imgs/ico/ic_search_m_normal.png") no-repeat center;cursor: pointer; 
                &:hover{background: url("../imgs/ico/ic_search_m_over.png") no-repeat center;} 
                &.disabled{background: url("../imgs/ico/ic_search_m_disable.png") no-repeat center;} 
            } 
            .openViewIco{background: url('../imgs/ico/ic_openview_m_normal@2x.png') no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important; background-size: 18px !important;} 
            .openViewIco:active {border: solid 1px #a6a6a6 !important; background:url("../imgs/ico/ic_openview_m_normal@2x.png") no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important; background-size: 18px !important;}             
            .closeViewIco{background: url('../imgs/ico/ic_closeview_m_normal@2x.png') no-repeat center center, linear-gradient(to top, #f0f0f0, #ffffff) !important; background-size: 18px !important;} 
            .closeViewIco:active{border: solid 1px #a6a6a6 !important; background: url('../imgs/ico/ic_closeview_m_normal@2x.png') no-repeat center center, linear-gradient(to bottom, #dddddd, #f5f5f5) !important; background-size: 18px !important;} 
        } 
        .box02{box-sizing: border-box; transition: all 0.3s; 
            .subBox{align-items: center; 
                .countTxt{margin-right: 15px; color: #1c90fb;}
                [class*="OBTCheckBox2_root"]{margin-right: 10px !important;} 
                .filterBox{position: relative; text-align: right;
                    .filterBtn{justify-content: flex-end; padding: 0 12px 0 0; line-height: 27px; border: none;background: url("../imgs/ico/ic_arrow_down_02_s_normal@2x.png") no-repeat center right !important; background-size: 14px !important; border: none !important;padding-right:15px;margin-right: 10px; 
                        em{cursor: pointer;}
                        &:hover{background: url("../imgs/ico/ic_arrow_down_02_s_over@2x.png") no-repeat center right !important; background-size: 14px !important; border: none !important;} 
                        &.on {background: url("../imgs/ico/ic_arrow_up_02_s_normal@2x.png") no-repeat center right !important; background-size: 14px !important; 
                            &:hover {background: url("../imgs/ico/ic_arrow_up_02_s_over@2x.png") no-repeat center right !important; background-size: 14px !important;} 
                        } 
                    } 
                    .filterList{position: absolute; top: 25px; left: -110px; width: 150px; text-align: left; border: 1px solid #a6a6a6;box-sizing: border-box;background: #ffffff;z-index: 100; 
                        li{font-size:12px;line-height:27px;color: #000000;padding: 0 0 0 25px;box-sizing: border-box;cursor: pointer; 
                            &:hover{background-color:#e6f5ff ;}  
                            &.chk{font-size:12px;line-height:27px;color: #1c90fb;background: url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat 5px 6px; background-size: 18px;} 
                            &.chk:hover{font-size:12px;line-height:27px;color: #1c90fb;background:#e6f5ff url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat 5px 6px; background-size: 18px;} 
                            em{padding-right: 24px; max-width: 50px; overflow: hidden; white-space: nowrap; word-break: break-all; text-overflow:ellipsis;}
                        } 
                    } 
                } 
            } 
        } 
    } 
} 
.migLineProgress{ 
    &[class*="OBTLinearProgress_root"]{position: relative !important; padding: 20px 0 10px !important; border: none !important; 
        [class*="OBTLinearProgress_progressDiv"]{margin: 0 !important; 
            > div{width: 100% !important; border-radius: 30px !important; 
                div{border-radius: 30px !important;} 
            } 
            [class*="OBTLinearProgress_innerText"]{position: absolute !important; top: -20px !important; font-weight: bold !important; font-size: 13px !important; color: #1c90fb !important;} 
        } 
    } 
} 
.migAlert{color: #fc5356;} 
 
// 이력확인 팝업 
.MIGP001{position: relative; width: 100%; height: 100%; padding-bottom: 20px; box-sizing: border-box; 
    .GridType01{height: 100%; margin-top: 20px; 
        .gridHeader{ 
            th{padding: 7px 0 !important; 
                &:first-child{padding: 8px 2px 6px 6px !important;} 
            } 
        } 
        .gridBody{ 
            &:hover td{background-color:#ffffff;} 
            td{overflow: hidden;white-space:nowrap;word-break:break-all;text-overflow:ellipsis;border-right: 1px solid #e6e6e6; padding: 6px 8px !important; 
                &:first-child{padding: 7px 2px 7px 6px !important;} 
                &:last-child{border-right: none;} 
                &.cus{cursor: pointer;} 
                span{ 
                    &.ref{display: inline-block; width: 100px; padding: 0 10px; font-size: 11px; line-height: 18px; border-radius: 30px; text-align: center; color: #ffffff; background-color: #46a3f0;} 
                    &.save{display: inline-block; width: 48px; padding: 0 10px; font-size: 11px; line-height: 18px; border-radius: 30px; text-align: center; color: #ffffff; background-color: #9da3aa;} 
                    &.suc{display: inline-block; width: 48px; padding: 0 10px; font-size: 11px; line-height: 18px; border-radius: 30px; text-align: center; color: #ffffff; background-color: #20c997;} 
                } 
            } 
        } 
    } 
} 
 
// Bizboxα 불러오기 팝업, iCUBE 불러오기 팝업 
.MIGP002, .MIGP003{position: relative; width: 100%; height: 100%; 
    .migTab{width: 100%; height: 100%;} 
    .migForm{margin-top: 10px; width: 100%;} 
    .btnBox{margin-top: 15px; justify-content: center;} 
} 
 
.MIGP004{position: relative; width: 100%; height: 100%; 
    .btn{float: right;} 
} 

// 매핑조건설정 팝업
.MIGP005{position: relative; width: 100%; height: 100%;
    .GridType01{border: solid #e6e6e6; border-width: 0 1px 0px 1px;
        .gridHeader{
            th{padding: 0; height: 35px;}
        }
        .gridBody{
            tr{
                &:hover td{background-color: #ffffff;}
            }
            td{padding: 0 10px; height: 50px; border-right: 1px solid #e6e6e6;
                &:last-child{border-right: 0;}
                > div{margin: 0 auto;}
            }
        }
    }
}

.MIGP006{
    .migTxtBox{padding: 15px; border: 1px solid #e6e6e6; box-sizing: border-box;
        .migTxt{line-height: 25px;
            p{color: #ea4949;}
        }
    }
}
 
/************************************************** 
   마이그레이션 로그인 MigrationLogin 
**************************************************/ 
.MigrationLogin{margin: 0 auto; 
    &.mobile{width: calc(100% - 40px) !important;  margin-left: 20px; margin-right: 40px;
        .loginConWrap{padding:0 50px; display: flex; justify-content: center; height: 100%;}
    }
    .loginHeadWrap{width: 100%; padding: 6px 20px; font-size: 11px; color: #07203c; border-bottom: 1px solid #e6e6e6; box-sizing: border-box;} 
    .loginConWrap{width: 100%; align-items: center; text-align: center; padding: 110px 83px; box-sizing: border-box; 
        .conBox01{ 
            .logo{width: 172px; height: 26px;} 
            .title{margin-top: 8px; font-size: 16px; color: #07203c;} 
        } 
        .conBox02{position: relative; margin: 20px 0 36px; width: 100%; 
            .inputBox{position: relative; margin-top: 14px; 
                input{width: 100%; height: 50px; padding: 0 52px 0 24px; font-size: 16px; border: 1px solid #c4c4c4; outline: none; border-radius: 25px; box-sizing: border-box; 
                    &::placeholder{font-size: 16px; letter-spacing: -0.4px; color: #c4c4c4;} 
                    &:focus{border: 1px solid #1c90fb;} 
                    &:focus + .closeIco{position: absolute; top: 50%; right: 18px; transform: translateY(-50%); width: 24px; height: 24px; background: url('../imgs/ico/ic_close_bl_m_none@2x.png') no-repeat center center; background-size: 24px;} 
                    &.error{border: 1px solid #fc4c60;} 
                    &.disable{color: #c4c4c4; border: 1px solid #c4c4c4; background-color: #f5f5f5;} 
                } 
                .closeIco{position: absolute; top: 50%; right: 18px; transform: translateY(-50%); width: 24px; height: 24px; background: url('../imgs/ico/ic_close_bl_m_sele@2x.png') no-repeat center center; background-size: 24px; cursor: pointer;} 
                .watchIco{position: absolute; top: 50%; right: 18px; transform: translateY(-50%); width: 24px; height: 24px; background: #ffffff url('../imgs/ico/ic_watch_cancel_l_normal@2x.png') no-repeat center center; background-size: 24px; cursor: pointer;} 
            } 
            .errorTxt{position: absolute; bottom: -23px; left: 50%; transform: translateX(-50%); color: #fc4c60;} 
        } 
        .btnBox{width: 100%; height: 54px; font-size: 18px; font-weight:bold; line-height: 52px; border-radius: 10px; outline: none; border: none; color: rgba(250, 250, 250, 0.5); background-color: #b4b9bc; box-shadow: 0 3px 15px 0 rgba(61, 69, 86, 0.27); box-sizing: border-box; cursor: pointer; 
            &.on{color: #ffffff; background: linear-gradient(to right, #0af 4%, #8168ff 95%);} 
        } 
    } 
} 
 
/**************************************************  
   연동설정  
**************************************************/  
.UJBA100{  
    .reference-area1 {    
        > div > div > p span {display: inline-block;vertical-align: top;margin-left: 3px;}    
        /* 공통 */    
        > div > div {    
            > strong:first-child {padding-left:25px;background:url('../imgs/ico/ic_info_03_m_normal.png') no-repeat 0 1px;}    
            > p {padding-top:4px;padding-left:25px;color:#4a4a4a;}    
        }  
    }  
    .accDiv{position: relative;border:1px solid #e6e6e6;border-radius:4px;overflow:hidden;margin-top:8px;  
        .onLink{color:#1c90fb;text-decoration:underline;cursor: pointer;}
        &.expand{border:1px solid #1c90fb;  
            .title{background-color:#eff7ff;border-bottom:1px solid #1c90fb;  
                .num{background-color:#1c90fb;}  
                .txt{color:#000000;}  
            }  
        }  
        .title{width:100%;height:48px;padding:0 21px;background-color:#fafafa;border-bottom:none;box-sizing: border-box;cursor: pointer;  
            .num{width:20px;height:20px;margin:14px 0;font-size:11px;font-weight:bold;color:#ffffff;line-height:19px;text-align:center;border-radius:20px;background-color:#b2b2b2;}  
            .txt{margin:0 0 0 5px;font-size:12px;font-weight:bold;color:#b2b2b2;line-height:48px;}  
            .arr{width:18px;height:18px;margin:15px 0;  
                &.up{background:url('../imgs/ico/ic_arrow_up_01_m_normal.png') no-repeat center;}  
                &.down{background:url('../imgs/ico/ic_arrow_down_01_m_normal.png') no-repeat center;}  
            }  
        }  
        .accCon{padding:21px;  
            .imgAcc{width:100%;max-width:780px;}  
            p{margin:0 0 8px 0;font-size:12px;font-weight:normal;color:#222222;line-height:1.5;  
                &.bold{font-weight:bold;} 
                &.smallGrayText{margin:2px 0 8px 0;font-size:10px;color:#8c8c8c;}  
            }  
            .reference-area1{width:780px;  
                .smallGrayText{font-size:10px;color:#8c8c8c;}  
            }  
        }  
    }      
    
    //탭 커스텀
    .pubTabStyle {
        // [class*="OBTTabs_buttonGroup"] {}
        > div > div > div > div > ul > div li > button {width: auto !important; padding: 0 14px !important; min-width: auto !important;}   
    }
}

// 오픈API연동설정
.UJBA160{
    .tokenTab{position: relative; margin-top:10px;
        .tabSearch{position: absolute; right:0; margin-top: 1px;}        
    }
    .apiTab > div > div:nth-child(2) {margin:0px;}
}

/**************************************************  
   업무보고  
**************************************************/  
//업무보고 팝업
.UHAP006{width:calc(100% - 62px); height:125px;
    .multiLineContents{
        .textBox{overflow-y: hidden;
            .keepLink{max-width: calc(100% - 25px);} 
        }
    }
}

/**************************************************  
   업데이트내역 에디터 폰트
**************************************************/
.upDateEditerFont{
    td *{font-family: '굴림' !important;}
}

/**************************************************    
   공통 :: 체크 드롭다운리스트    
**************************************************/
.UJB7010{
    .topboxUJB7010{position:relative; width: 178px; 
        &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}
        .inbox{width:100%; height:27px; line-height: 25px; padding:0 10px 0 7px; border:1px solid #cccccc; box-sizing: border-box; cursor: pointer;
           &.on{border: 1px solid #58adfc;}
        }
       
        .arr{position:absolute;top:8px;right:10px;width:12px !important;height:12px !important; background: url("../imgs/ico/btn_arrow_down_normal.png") no-repeat right center !important; background-size: 12px !important; cursor: pointer;
           &.up{background:url("../imgs/ico/btn_arrow_up_hover.png") no-repeat right center !important; background-size: 12px !important; transition:all 0.3s !important;}
           &.down{background: url("../imgs/ico/btn_arrow_down_normal.png") no-repeat right center !important; background-size: 12px !important; transition:all 0.3s !important;}
           &:hover{background: url("../imgs/ico/btn_arrow_down_hover.png") no-repeat right center !important; background-size: 12px !important;}   
       }
     
        .list{z-index: 1; position:absolute;top:27px;left:0px;width:inherit;border:1px solid #cccccc;background:#ffffff;box-sizing: border-box;
           ul{
              li{padding:0 7px;line-height:27px; text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
                 &:hover{background-color: #e6f5ff; cursor: pointer;}
                 &.on{background-color: #e6f5ff; color:#2e98fc;}
              }
           }
            .addTr{border-bottom:1px solid #cccccc; box-sizing: border-box;}
            .foot{border-top:1px solid #cccccc; box-sizing: border-box; background-color: #f5f5f5; padding:5px; justify-content: center; display: flex;}
        }
    }
}

/**************************************************    
   ONE AI 관리 :: UJB3120    
**************************************************/
.UJB3120{
    .creditUsageBox{margin:10px 0 0 0;padding:20px;border-radius:4px;border:1px solid #d5dce8;background-color:#f2f5fa;box-sizing:border-box;
        .boxLeft{margin:0 22px 0 0;
            p{height:24px;line-height:24px;font-size:16px;font-weight:bold;color:#000000;}
            .date{margin:2px 0 0 0;height:16px;line-height:16px;font-size:12px;color:#a6a6a6;}
            .fullPicker{    
                > div {width:100% !important;}    
            }
        }
        .boxRight{padding:15px 20px 15px 28px;height:80px;border-radius: 6px;box-shadow: 0 3px 10px 0 #dbe4eb;background-color: rgba(255,255,255,0.7);box-sizing: border-box;
            dl{
                dt{height:20px;line-height:20px;font-size:13px;color:#4a4a4a;letter-spacing:-0.32px;
                    &.use::before{content:'';width:8px;height:8px;border-radius:8px;background-color:#4eabfa;margin:0 4px 0 0;}
                    &.unUse::before{content:'';width:8px;height:8px;border-radius:8px;background-color:#ebebeb;margin:0 4px 0 0;}
                }
                dd{min-width:90px;height:30px;line-height:30px;font-size:20px;color:#000000;letter-spacing:-0.5px;font-weight:bold;text-align:center;}
            }
            .line{margin:0 28px;width:1px;height:50px;background-color:#e6e6e6;}
            .progressBar{border:none !important;
                .progressLabel{
                    .tit{height:20px;line-height:25px;font-size:12px;color:#000000;letter-spacing:-0.6px;font-weight:normal;margin:0 4px 0 0;}
                    .per{height:20px;line-height:20px;font-size:16px;color:#000000;letter-spacing:-0.8px;font-weight:bold;
                        span{font-weight:normal;}
                    }
                }
                [class*="OBTLinearProgress_labelText"]{margin:14px 0 7px 0 !important;}
                [class*="OBTLinearProgress_progressDiv"] {margin:0 0 16px 0 !important;}
                [class*="OBTLinearProgress_progressDiv"] > div{width:100% !important;border-radius:6px !important;background-color:#ebebeb !important;}
                [class*="OBTLinearProgress_progressDiv"] > div > div{border-radius:6px !important;background-color:#4eabfa !important;}
                [class*="OBTLinearProgress_innerText"]{display:none !important;}
            }

        }
    }
    .creditStatusBox{margin:20px 0 0 0;
        .statusTabs{height:27px;
            li{font-size:14px;color:#4a4a4a;letter-spacing:-0.7px;cursor: pointer;
                &.on{font-weight:bold;color:#1c90fb;}
                &.line{margin:0 10px;width:1px;height:11px;background-color:#e6e6e6;}
            }
        }
        .graphBox{margin:5px 0 0 0;border:1px solid #e0e0e0;border-radius:4px;background-color:#ffffff;box-sizing: border-box;}
        .usageTable{position:relative;border-top:2px solid #666666;overflow-x:auto;
            .th{min-width:70px;height:40px;padding:5px;font-size:12px;font-weight:bold;color:#000000;background-color:#fafafa;box-sizing: border-box;border: solid #e6e6e6;border-width: 0 1px 1px 0;
                &:last-child{border-width:0 0 1px 0;}
                &.tit{position:sticky;left:0;padding:0 10px 0 0;font-size:12px;font-weight:bold;color:#000000;background-color:#f2f6f8;box-sizing: border-box;justify-content:end;}
                &.total{position:sticky;right:0;font-size:12px;font-weight:bold;color:#000000;background-color:#fff1d6;box-sizing: border-box;border-left:1px solid #a6a6a6;}
            }
            .td{min-width:70px;height:40px;padding:5px;font-size:12px;font-weight:normal;color:#000000;background-color:#ffffff;box-sizing: border-box;border: solid #e6e6e6;border-width: 0 1px 1px 0;
                &:last-child{border-width:0 0 1px 0;}
                &.tit{position:sticky;left:0;padding:0 10px 0 0;font-size:12px;font-weight:bold;color:#000000;background-color:#f2f6f8;box-sizing: border-box;justify-content:end;}
                &.total{position:sticky;right:0;font-size:12px;font-weight:bold;color:#000000;background-color:#fff1d6;box-sizing: border-box;border-left:1px solid #a6a6a6;}
            }
        }
    }
}

/**************************************************    
   타 그룹웨어 마이그레이션 이관처리
**************************************************/
.MigrationThirdParty{position: relative;width:100%;height:100%;
    .migHeader{width:100%;height:46px;line-height:46px;padding:0 24px;font-size:18px;font-weight:bold;color:#ffffff;box-sizing:border-box;background-color:#1c90fb;}
    .migContainer{padding:20px;box-sizing:border-box;
        .migTitle{border-bottom:1px solid #666666;}
        .migTitle2{border-bottom:1px solid #e6e6e6;}
        .migInfoTitle{display: inline-flex;align-items: center;
            .info{display: inline-flex;width: 14px;height: 14px;vertical-align: middle;margin-left: 4px;background: url('../imgs/ico/ic_info_01_s_normal@2x.png') no-repeat;background-size: 14px;}
        }
        .migGrid{
            .migGridType{min-width: fit-content;width: 100%;height: 100%;   
                &.box {border-style:solid;border-width:2px 1px 1px 1px;border-color:#666666 #e6e6e6 #e6e6e6 #e6e6e6;box-sizing:border-box;
                    .tr{border:none !important;}
                    .tableHeader{
                        .th{border:none;border-bottom:1px solid #e6e6e6;}
                    }
                    .tableBody{
                        .td{border:none;border-bottom:1px solid #e6e6e6;}
                        .hoverWrap{bottom:1px !important;}
                    }
                }
                .tableGroup{position:relative;width:100%;box-sizing: border-box;   
                    .flexTableWrap{   
                        .tableHeader{border:solid #e6e6e6;border-width:0 1px 0 1px;   
                            .tr{height:32px;border-style:solid;border-width:2px 1px 1px 1px;border-color:#666666 transparent #e6e6e6 transparent;background-color:#fafafa;
                                .th{position: relative;padding:5px 10px;font-size: 13px;font-weight: bold;text-align: center;letter-spacing: -0.6px;border-left:1px solid #e6e6e6;box-sizing: border-box;
                                    &:first-child{border-left:none;}   
                                }
                            }
                        }   
                        .tableBody{border:solid #e6e6e6;border-width:0 1px 1px 1px;background-color:#fafafa;
                            .tr{position:relative;border:1px solid;border-color:transparent transparent #e6e6e6 transparent;background-color:#ffffff;
                                .td{position:relative;min-height:32px;padding:2px 10px;font-size:12px;letter-spacing:-0.6px;border-left:1px solid #e6e6e6;box-sizing: border-box;                        
                                    &:first-child{border-left:none;}   
                                    &.cen{justify-content: center;align-items: center;}   
                                    &.le{justify-content: center;align-items: flex-start;}   
                                    &.ri{justify-content: center;align-items: flex-end;}

                                    .icoAlert{width:18px;height:18px;
                                        &.done{background:url('../imgs/ico/ic_alert_check@3x.png') no-repeat center;background-size:18px;}
                                        &.warning{background:url('../imgs/ico/ic_alert_warning@3x.png') no-repeat center;background-size:18px;}
                                    }
                                    .icoBtnJson{width:18px;height:18px;background:url('../imgs/ico/ic_json_m_normal@2x.png') no-repeat center;background-size:18px;cursor: pointer;}
                                }   
                            }   
                        }   
                    }   
                }   
            }


            
        }
        .migProcess{padding:21px 0px;border:1px solid #e0e0e0;border-radius:10px;background-color:#ffffff;box-sizing:border-box;
            .on{
                .ico{
                    &.icoType01{background:url('../imgs/ico/ic_table_regi_select@3x.png') no-repeat center;background-size:50px;}
                    &.icoType02{background:url('../imgs/ico/ic_data_move_select@3x.png') no-repeat center;background-size:50px;}
                    &.icoType03{background:url('../imgs/ico/ic_migration_select@3x.png') no-repeat center;background-size:50px;}
                    &.icoType04{background:url('../imgs/ico/ic_data_edit_select@3x.png') no-repeat center;background-size:50px;}
                    &.icoType05{background:url('../imgs/ico/ic_comment_select@3x.png') no-repeat center;background-size:50px;}
                }
                .titleBox{
                    .num{color:#0c2b65;}
                    .tit{color:#0c2b65;}
                }
            }
            .ico{margin:0 0 10px 0;width: 50px;height: 50px;
                &.icoType01{background:url('../imgs/ico/ic_table_regi_normal@3x.png') no-repeat center;background-size:50px;}
                &.icoType02{background:url('../imgs/ico/ic_data_move_normal@3x.png') no-repeat center;background-size:50px;}
                &.icoType03{background:url('../imgs/ico/ic_migration_normal@3x.png') no-repeat center;background-size:50px;}
                &.icoType04{background:url('../imgs/ico/ic_data_edit_normal@3x.png') no-repeat center;background-size:50px;}
                &.icoType05{background:url('../imgs/ico/ic_comment_normal@3x.png') no-repeat center;background-size:50px;}
            }
            .titleBox{height:18px;line-height:18px;margin:0 0 15px 0;
                .num{font-size:16px;font-weight:normal;letter-spacing:-0.4px;color:#8c98b1;}
                .tit{font-size:16px;font-weight:bold;letter-spacing:-0.4px;color:#8c98b1;margin:0 0 0 4px;}
                .warning{width:18px;height:18px;background:url('../imgs/ico/ic_alert_warning@3x.png') no-repeat center;background-size:18px;margin:1px 0 0 6px;}
            }
            .btnBox{margin:0 0 15px 0;gap:4px;}
            .txtBox{gap:4px;
                .txt{font-size:12px;letter-spacing:-0.6px;color:#000000;
                    &.t_blue{color:#1c90fb;}
                    &.t_red{color:#fc5356;}
                    &.t_gray{color:#a6a6a6;}
                    &.bar{margin:0 2px;}
                }
            }
            .statusLabel{width: fit-content !important;min-width:fit-content !important;}
            .next{position:relative;width:23px;height:100%;
                &::before{content:'';position:absolute;top:0;left:11px;width: 1px;height:100%;opacity: 0.4;background-color: #d4dfe7;}
                &::after{content:'';position:absolute;top: 50%;width: 23px;height: 23px;background:url('../imgs/ico/ic_arrow_right@2x.png') no-repeat center;background-size:23px;transform: translateY(-50%);}
            }

        }
        .migSubTab{
            > div > div > div {border-bottom:none !important;}
            > div > div > div > div > ul > div li > button{min-width:38px !important;width:auto !important;border:none !important;padding:0 10px !important;}
            > div > div > div > div > ul > div li > button > span:first-child{top: 6px !important;height: 11px !important;margin: 5px 0 !important;}   
        }
    }
}
.migJsonText{
    > div > div > textarea {color: #000000 !important;background-color: #ffffff !important;}
}

/**************************************************    
   배포이력관리 :: UJA3110    
**************************************************/
.UJA3110.pubFormStyle{
    >  table {border-top:none;}
}

















