$background-color: #f3f7fa;
$border-color: #d4dfe7;
$border-radius: 3px;

.root {
    box-sizing: border-box;
    min-height: 40px;
    padding: 10px 0px;
    background-color: $background-color;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    position: relative;

    & > .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 15px;

        & > .titleArea {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;

            & > .titleIcon {
                margin-right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 16px;
                height: 16px;
            }

            & > .titleText {
                font-size: 12px;
                margin-right: 8px;
            }
        }

        & > .contents {
            flex: 1 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        & > .toolArea {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;

            & > .titleButtons {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 4px;
            }

            & > .splitter {
                width: 21px;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    content: '';
                    width: 1px;
                    height: 16px;
                    background-color: $border-color;
                }
            }

            & > .accordionButton {
                cursor: pointer;
                transition: transform 0.2s ease;
            }
        }
    }

    & > .contents {
        margin: 0px 15px;
        & li {
            list-style: none;
            position: relative;
            padding-left: 10px;

            &::before {
                content: '·';
                position: absolute;
                left: 0px;
                top: 0px;
            }
        }
    }

    &.accordion {
        & > .contents {
            margin-top: 10px;
        }
        &.floating {
            & > .contents {
                position: absolute;
                margin: 0px;
                left: -1px;
                right: -1px;
                bottom: 0px;
                overflow: visible;
    
                & > .contentsFloatWrap {
                    z-index: 2000;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    top: 0px;
                    background-color: $background-color;
                    border-left: 1px solid $border-color;
                    border-right: 1px solid $border-color;
                    border-bottom: 1px solid $border-color;
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                    padding: 10px 15px 10px 15px;
                }
            }
        }
        &.open {
            &.floating {
                padding-bottom: 0px;
                border-bottom: none;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
            }
            & > .title {
                padding-bottom: 10px;
                border-bottom: 1px solid $border-color;
            }
        }
        &:not(.open) {
            & > .title {
                & > .toolArea {
                    & > .accordionButton {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.multiline {
        .contents {
            &:not(:first-child) {
                padding-top: 4px;
                padding-left: 20px;
            }
            &:first-child {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }

    &:not(.accordion) {
        .title {    
            .titleArea {    
                .titleText {
                    font-weight: bold;
                }
            }
        }
    }
}