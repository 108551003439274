.root {
    // display: inline-flex;
    // flex-direction: column;
    display: inline-block;

    align-items: flex-start;
    border: solid 1px #cccccc;
    overflow: auto;
    outline: none;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        background:transparent;
    }

    &::-webkit-scrollbar:vertical {
        width:6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color:rgba(0,0,0,.2);
        border-radius: 5px;
        border:1px solid transparent;
        background-clip: content-box;
    }

    & > :first-child {
        padding-top: 11px;
    }

    & > :last-child {
        padding-bottom: 11px;
    }

    & .treeNode {
        & > .treeNodeContents {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            height: 26px;
            margin: 0 1px;
            // TODO: 전체 길이를 넘어가는 텍스트의 경우 정책확인 필요
            overflow: hidden;

            & > .expander {
                margin-right: 5px;
                cursor: pointer;
                &.disabled {
                    cursor: not-allowed;
                }
            }

            & > .imageUrl {
                margin-right: 5px;
                max-width: 16px;
                max-height: 16px;
                cursor: pointer;
                &.disabled {
                    cursor: not-allowed;
                }
            }

            & > .icon {
                margin-right: 5px;
                cursor: pointer;
                &.disabled {
                    cursor: not-allowed;
                }
            }

            & > .checkBox {
                display: inline-flex;
                cursor: pointer;
                &.visible {
                    display: none;
                }
                &.disabled {
                    cursor: not-allowed;
                }
            }

            & > div > .treeLabelText {
                font: inherit;
                line-height: initial;
                font-size: 12px;
                cursor: pointer;
                user-select: none;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border: none;
                color: inherit;
                background: none;
                &.selectedOver {
                    background: none;
                    outline: none;
                }
                &.edit {
                    display: none;
                }
                &.disabled {
                    color: #8c8c8c;
                    cursor: not-allowed;
                }
                &.focused:not(.disabled):not(.selectedOver) {
                    background-color: #e9f5ff;
                    outline: 1px solid #00a2ff;
                }
                &.noFocused {
                    outline: none;
                }
            }

            &.disabled {
                color: #8c8c8c;
            }

            & > .notEdit {
                display: none;
            }

            &:not(.disabled):not(.edit) > .editDeleteImage {
                display: flex;
            }

            &.outLine:not(.disabled):not(.edit):not(.notEditSort) {
                cursor: pointer;
                box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.18);
                outline: solid 1px #1c90fb;
                background: url(./img/icon-con-change.png) no-repeat 3px center;
                &.default > div {
                    margin-left: 14px;
                }
                &.folder > div {
                    margin-left: 21px;
                }
            }

            & > .textField {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                & > div > input {
                    display: inline-flex;
                    height: 22px;
                    font-size: 11.5px;
                    box-sizing: border-box;
                    outline: none;
                    border: solid 1px #1c90fb;
                    &.required {
                        background-color: rgb(254, 244, 244);
                    }
                }
                & > .saveImage {
                    padding-left: 11px;
                    cursor: pointer;
                    &.saveImageNone {
                        display: none;
                    }
                }

                & > .cancelImage {
                    padding-left: 7px;
                    cursor: pointer;
                    &.cancelImageNone {
                        display: none;
                    }
                }
            }
        }

        &.collapsed > .treeNodeChildren {
            display: none;
        }

        &.visible {
            display: none;
        }
    }
}

.group {
    display: flex;
    align-items: center;
    height: 26px;
}

.arrow {
    display: flex;
    margin-right: 5px;
    cursor: pointer;
}

.image {
    display: flex;
    margin-right: 5px;
    cursor: pointer;
}

.text {
    display: flex;
    padding: 5px 4px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    &:focus {
        background-color: #e9f5ff;
        border-color: #00a2ff;
    }
}

.child {
    margin-left: 30px;
}

.clickChild {
    display: none;
}

.emptyData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.emptyTextNormal {
    margin-top: 10px;
    font-size: 12px;
    color: #8c8c8c;
}

.emptyTextSmall {
    margin-top: 10px;
    font-size: 12px;
    color: #8c8c8c;
}

.emptyTextTiny {
    margin-left: 5px;
    font-size: 12px;
    color: #8c8c8c;
}