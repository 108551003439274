$colorPositive1: #20C997;
$colorPositive2: #2DBCB5;
$colorPositive3: #39B0D2;
$colorProgress: #46A3F0;
$colorNegative1: #FF8787;
$colorNegative2: #F8A457;
$colorPending1: #f0c325;
$colorPending2: #C8B465;
$colorNeutral: #9DA3AA;

.root {
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  font-weight: normal;
  letter-spacing: normal;
  color: #000000;
  // outline: none;
  background-color: transparent;
  cursor: pointer;

  & > .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > .labelText {
      position: relative;
      line-height: normal;
    }
    & > *:not(:last-child) {
      margin-right: 2px;
    }
  }

  &.typebig {
    height: 32px;
    padding: 0px 16px;
    & > .wrapper > .labelText {
      font-size: 15px;
    }
  }
  &.typedefault {
    height: 27px;
    padding: 0px 10px;
    & > .wrapper > .labelText {
      font-size: 12px;
    }
  }
  &.typesmall {
    height: 21px;
    padding: 0px 8px;
    & > .wrapper > .labelText {
      font-size: 11px;
    }
  }
  &.typeicon {
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 12.5px;
    border: none;
    padding: 0px;
    font-size: 12px;

    & > .wrapper {
      flex-direction: column;
      & > *:not(:last-child) {
        margin-right: none;
        margin-bottom: 2px;
      }
    }
  }

  &.typestate {
    padding: 0px 10px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
    & > .wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-width: 78px;
      min-height: 25px;
    }

    &.stateSelected {
      background-color: lightgray;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba(lightgray, 0.1);
    }
  }

  &.typeicon {
    &.overBackground {
      &:hover,
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.disabled:not(.typeicon):not(.typestate) {
    border-color: #cccccc;
    background-color: #f5f5f5;
    color: #a6a6a6;
    cursor: not-allowed;
  }

  &:not(.typeicon):not(.typestate):not(.disabled) {
    &.themedefault {
      background-image: linear-gradient(to top, #f0f0f0, #ffffff);
      &:hover {
        border: solid 1px #9f9f9f;
      }
      &:active {
        background-image: linear-gradient(to bottom, #dddddd, #f5f5f5);
      }
    }
    &.themeblue {
      border-color: #1c90fb;
      color: #ffffff;
      background-image: linear-gradient(to top, #1c90fb, #5eb5fd);
      &:hover {
        border: solid 1px #136ad5;
        background-image: linear-gradient(to top, #3280e0, #3ba1ff);
        // & > .wrapper > .labelText::after {
        //   background-color: #ffffff;
        // }
      }
      &:active {
        background-image: linear-gradient(to bottom, #3280e0, #3ba1ff);
        // & > .wrapper > .labelText::after {
        //   background-color: #ffffff;
        // }
      }
    }
    &.themeskyBlue {
      border-color: #aedafe;
      background-image: linear-gradient(to top, #d0ebff, #edf6ff);
      &:hover {
        border-color: #5eb5fd;
        // background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);
      }
      &:active {
        background-image: linear-gradient(to bottom, #a4d6fe, #e6f5ff);
      }
    }
  }

  &:not(.typeicon):not(.typestate).themedrawer {
    border-color: #b5b5b5;
    background: #50596c;
    color: #ffffff;
    &:hover {
      border-color: #e6e6e6;
      // background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);
    }
    &:active {
      border-color: #b5b5b5;
    }

    &:disabled {
      color: #b3b3b3;
      border-color: #999999;

      &:hover {
        border-color: #999999;
      }
      &:active {
        border-color: #999999;
      }
    }
  }

  &:not(.typeicon):not(.typestate).themedrawerImportant {
    border-color: #1c90fb;
    background: #1c90fb;
    color: #ffffff;
    &:hover {
      border-color: #2ba7ff;
      background: #2ba7ff;
      // background-image: linear-gradient(to top, #a0d6fe, #e6f5ff);
    }
    &:active {
      border-color: #1c90fb;
      background: #1c90fb;
    }

    &:disabled {
      color: #737373;
      border-color: #b3b3b3;
      background: #b3b3b3;

      &:hover {
        border-color: #b3b3b3;
        background: #b3b3b3;
      }
      &:active {
        border-color: #b3b3b3;
        background: #b3b3b3;
      }
    }
  }

  &.typestate.themestatePositive1 {
    border-color: $colorPositive1;
    color: $colorPositive1;
    &.stateSelected {
      background-color: $colorPositive1;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorPositive1, 0.1);
    }
  }
  &.typestate.themestatePositive2 {
    border-color: $colorPositive2;
    color: $colorPositive2;
    &.stateSelected {
      background-color: $colorPositive2;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorPositive2, 0.1);
    }
  }
  &.typestate.themestatePositive3 {
    border-color: $colorPositive3;
    color: $colorPositive3;
    &.stateSelected {
      background-color: $colorPositive3;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorPositive3, 0.1);
    }
  }
  &.typestate.themestateProgress {
    border-color: $colorProgress;
    color: $colorProgress;
    &.stateSelected {
      background-color: $colorProgress;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorProgress, 0.1);
    }
  }
  &.typestate.themestateNegative1 {
    border-color: $colorNegative1;
    color: $colorNegative1;
    &.stateSelected {
      background-color: $colorNegative1;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorNegative1, 0.1);
    }
  }
  &.typestate.themestateNegative2 {
    border-color: $colorNegative2;
    color: $colorNegative2;
    &.stateSelected {
      background-color: $colorNegative2;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorNegative2, 0.1);
    }
  }
  &.typestate.themestatePending1 {
    border-color: $colorPending1;
    color: $colorPending1;
    &.stateSelected {
      background-color: $colorPending1;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorPending1, 0.1);
    }
  }
  &.typestate.themestatePending2 {
    border-color: $colorPending2;
    color: $colorPending2;
    &.stateSelected {
      background-color: $colorPending2;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorPending2, 0.1);
    }
  }
  &.typestate.themestateNeutral {
    border-color: $colorNeutral;
    color: $colorNeutral;
    &.stateSelected {
      background-color: $colorNeutral;
      color: #ffffff;
    }
    &:not(.stateSelected):hover {
      background-color: rgba($colorNeutral, 0.1);
    }
  }
}
