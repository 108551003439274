@keyframes functionPanelShow {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

.root {
  width: 100%;
  height: 100%;
  //   min-width: '800px';
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;

  &>.header {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 0px 20px;
    box-sizing: border-box;

    &>.title {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      &>.titleButton {
        background-image: url("./Images/ico-title-fnc.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 18px;
        height: 18px;
        cursor: pointer;
        outline: none;
        margin-right: 7px;
      }

      &>.titleText {
        color: #000000;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &>.titleBlank {
        flex-grow: 1;
      }

      // & > .onlineManual {
      //   display: flex;
      //   margin-left: 9px;
      //   & > div > button {
      //     margin-right: 4px;
      //   }

      // }
    }

    &>.mainButtons {
      display: flex;
      flex-direction: row;
      align-items: center;

      &>*:not(:last-child) {
        margin-right: 3px;
      }

      & .primaryFunctionButton {
        line-height: initial;
      }

      & .primaryFunctionSplitButton {
        line-height: initial;

        & * {
          background-color: transparent !important;
          outline: none !important;
        }

        &>div>div>button {
          border-color: rgb(211, 211, 211) !important;

          &:first-child {
            border-right: none !important;
          }

          &:last-child {
            border-left: none !important;

            &>span {
              left: 3px;
            }
          }
        }

        &:hover {
          &>div>div>button {
            border-color: rgb(140, 140, 140) !important;
          }
        }
      }

      &>.splitter {
        background-color: rgb(211, 211, 211);
        height: 20px;
        width: 1px;
        margin-left: 4px;
      }

      & .systemButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &>button {
          border: none !important;

          &>span {
            background-position: center !important;
            background-size: auto !important;
          }
        }

        &:hover {
          &>button {
            border-radius: 12.5px !important;
            background-color: rgba(0, 0, 0, 0.1) !important;
          }
        }
      }

      & .backgroundColorNone {

        &:hover,
        &:active {
          background-color: white !important;
        }
      }

      & .functionButton {
        line-height: initial;
        position: relative;

        &.collapsed .functionPanelWrapper {
          display: none;
        }

        &.show .functionPanelWrapper {
          animation: functionPanelShow 200ms forwards ease-out;
          transform-origin: top center;
        }

        &>.functionPanelWrapper {
          position: absolute;
          // left: 0px;
          top: 27px;
          // width: 216px;
          padding-top: 0px;
          z-index: 2000;
          box-shadow:
            rgba(0, 0, 0, 0.12) 0px 1px 6px,
            rgba(0, 0, 0, 0.12) 0px 1px 4px;

          &>.functionPanel {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 1px solid rgb(163, 163, 163);
            background-color: white;

            &>* {
              width: 100%;
              box-sizing: border-box;
            }

            &>.button {
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &.disabled {
                background-color: rgb(242, 242, 242);
                color: rgb(166, 166, 166);
                cursor: not-allowed;
              }

              &:not(.disabled):hover {
                background-color: rgb(245, 245, 245);
              }

              .funclabelTooltip {
                &>div {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }

            &>.function {
              font-size: 12px;
              font-weight: 700;
              line-height: 30px;
              height: 30px;
              color: #000000;
              padding: 0px 10px;
            }

            &>.child {
              font-weight: normal;
              padding-left: 20px;
              line-height: 22px;
              height: 22px;
              color: #4a4a4a;
              width: 100%;

              &>div {
                display: inherit;

                &>div {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }

            &>.function:not(.child)+.function.child {
              border-top: 1px solid rgb(233, 233, 233);
            }

            &>*:last-child.child {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }

  &>.section {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: auto;
    position: relative;
    padding-bottom: 0px;
    transition: padding-bottom 0.4s cubic-bezier(0.23, 1, 0.32, 1);

    &.drawerShown {
      padding-bottom: 66px;
    }

    &>.contentsWrapper {
      z-index: 0;
      flex: 1 1 auto;
      position: relative;
      outline: none;

      &>.contents {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 20px;
        bottom: 20px;
      }

      &.noContentPadding>.contents {
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
      }

      &>.popupContents {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
      }
    }

    .sideBarButtonsWrapper {
      display: flex;
      flex-direction: column;
    }

    .sideBarCollapseButton {
      // z-index: 1;
      width: 12px;
      height: 100px;
      border: none;
      background: transparent;
      background-repeat: no-repeat;
      outline: none;
      background-image: url("./Images/btn_sb_close_normal.png");

      &.open {
        background-image: url("./Images/btn_sb_close_over.png");
      }

      &.closed {
        background-image: url("./Images/btn_sb_open_normal.png");

        &:hover {
          background-image: url("./Images/btn_sb_open_over.png");
        }
      }

      &.disabled {
        background-image: url("./Images/btn_sb_open_normal.png");

        &:hover {
          cursor: default;
        }
      }
    }

    .informationBarCollapseButton {
      width: 12px;
      height: 100px;
      top: calc(50% + 45px);
      // position: absolute;
      border: none;
      background: transparent;
      background-repeat: no-repeat;
      outline: none;
      background-image: url("./Images/btn_info_open_normal.png");

      &.open {
        background-image: url("./Images/btn_info_close_over.png");
      }

      &.closed {
        background-image: url("./Images/btn_info_open_normal.png");

        &:hover {
          background-image: url("./Images/btn_info_open_over.png");
        }
      }

      &.disabled {
        background-image: url("./Images/btn_info_open_normal.png");

        &:hover {
          cursor: default;
        }
      }
    }

    &>.contentsWrapper {
      &>.sideBarCollapseButton {
        position: absolute;
        right: 0px;
        top: calc(50% - 50px);
      }
    }

    &>.sideBar {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      // min-width: 11px;
      z-index: 1;

      &:focus {
        outline: none;
      }

      &>.sideBarWrapper {
        width: 211px;
        height: 100%;
        margin-left: -1px;
        overflow: hidden;
        transition: width 0.25s ease;

        &>.sideBarContents {
          width: 211px;
          height: 100%;
          background-color: #f5f5f5;
          border-left: 1px solid #e6e6e6;
          //   padding: 5px;
          overflow-y: auto;
          box-sizing: border-box;

          &>div {
            padding: 5px;
          }

          &>div>* {
            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }

      &>.informationBarWrapper {
        width: 358px;
        height: 100%;
        margin-left: -1px;
        overflow: hidden;
        transition: width 0.25s ease;

        &>.informationBarContents {
          width: 358px;
          height: 100%;
          background-color: #ffffff;
          border-left: 1px solid #e6e6e6;
          overflow-y: auto;
          box-sizing: border-box;

          &>div {
            padding: 5px;
          }

          &>div>* {
            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }

        &.collapsed {
          width: 0px;
        }
      }

      &.collapsed {
        &>.sideBarWrapper {
          width: 0px;
        }
      }
    }

    &.floatSideBar {
      &>.contentsWrapper:not(.noContentPadding) {
        &>.contents {
          right: 20px;
        }
      }

      &>.sideBar {
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
      }
    }
  }

  &>.drawerWrapper {
    position: fixed;
    // left: 0px;
    // right: 0px;
    bottom: 0px;
    height: 66px;
    overflow: hidden;
    transition: height 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    z-index: 900;

    &.collapsed {
      height: 0px;
    }

    &>.drawer {
      height: 66px;
      padding: 0px 20px 0px 27px;
      background-color: rgb(80, 89, 108);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &>.selection {
        font-size: 18px;
        color: rgb(255, 255, 255);

        &>.selectionCount {
          color: rgb(26, 224, 237);
        }
      }

      &>.drawerContents {
        display: flex;
        flex-direction: row;

        &>*:not(:last-child) {
          margin-right: 4px;
        }

        & .drawerButton {
          line-height: initial;

          &>button {
            background-color: transparent !important;
            border: 1px solid rgb(181, 181, 181) !important;
            color: rgb(222, 222, 222) !important;
            padding: 0px 20px 0px !important;
            font-size: 15px !important;
            height: 32px !important;

            &:hover {
              background-color: rgb(59, 68, 85) !important;
              border: 1px solid rgb(59, 68, 85) !important;
            }
          }
        }

        & .drawerSplitButton {
          line-height: initial;

          &>div>div>button {
            background-color: transparent !important;
            border-color: rgb(181, 181, 181) !important;
            color: rgb(222, 222, 222) !important;
            height: 32px !important;
            outline: none !important;

            &:first-child {
              border-right: none !important;
              padding: 0px 5px 0px 20px !important;
              font-size: 15px !important;
            }

            &:last-child {
              border-left: none !important;
              padding: 0px 32px 0px 0px !important;

              &>span {
                left: 5px;
                background-position: -25px -225px;
                width: 11px;
                height: 6px;
              }

              &[class~="click"]>span {
                background-position: -0px -225px;
              }
            }
          }

          &:hover {
            &>div>div>button {
              background-color: rgb(59, 68, 85) !important;
              border-color: rgb(59, 68, 85) !important;
            }
          }
        }
      }
    }
  }

  &>.modalContainer {
    position: relative;
    z-index: 1000;
  }
}

.planIcon {
  background-image: url("./Images/PlanIcon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  cursor: pointer;
  outline: none;
  width: 23px;
  height: 23px;
  margin-left: 5px;
}

.planBox {
  z-index: 100;
  position: absolute;
  left: -14px;
  top: 5px;
  width: 574px;
  height: 200px;
  cursor: default;

  .planArrow {
    position: absolute;
    left: 10px;
    top: 0px;
    border: solid #a6a6a6;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    background-color: #ffffff;
  }

  .planContent {
    position: absolute;
    left: 0px;
    top: 3px;
    right: 0px;
    bottom: 0px;
    box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1);
    border: solid 1px #a6a6a6;
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .planTitle {
      padding: 15px 20px;
      border-bottom: 1px solid #a6a6a6;
      font-size: 14px;
      font-weight: bold;
      position: relative;
    }

    .planView {
      position: relative;
      overflow: auto;

      .planClickArea {
        position: absolute;
        width: 185px;
        height: 53px;
        border-radius: 4px;
        border: 1px solid #1c90fb;
        opacity: 0;
        transition: opacity 200ms ease-out;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.errorPageRoot {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>.errorImg {
    width: 260px;
    height: 220px;
  }

  &>.errorTitle {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1px;
    line-height: 0.9;
    text-align: center;
    margin-bottom: 8px;
  }

  &>.errorContents {
    font-size: 16px;
    color: #a6a6a6;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    line-height: 1.44;
    text-align: center;
  }
}

.onlineManual {
  display: flex;
  gap: 5px;
  margin-left: 9px;
  height: 28px;
  box-sizing: border-box;
  overflow: hidden;
  padding-right: 10px;

  &>.buttonContainer {
    padding: 0 4px;
    background: #eaf7ff;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    transition: width 400ms ease-in-out;
    overflow: hidden;
    border-radius: 28px;

    &>.iconWrapper {
      position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      position: relative;
    }

    &>.labelWrapper {
      font-size: 11px;
      padding-right: 4px;
      height: 20px;
      line-height: 20px;
      white-space: nowrap;
    }
  }

  &>.defaultBgButton {
    background: #eaf7ff;
    color: #000;
  }

  &>.hoverBgButton {
    color: #fff;
    background: #1c90fb;
  }
}

.mainButtonSplit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &>.mainButtonSplitImg {
    margin-right: 2px;
  }

  &>.mainButtonSplitDrop {
    margin-left: 5px;
  }
}

.manualUpdateDate {
  font-size: 12px;
  color: #1c90fb;
  margin-bottom: 10px;
}

.fatalError {
  z-index: 999999;
}

.oneAiDataAnalysisButton {
  border-color: transparent !important;

  &.enabled {
    background: #eff1f7 !important;
    border-radius: 15px;
    padding-right: 12px;
  }

  &.disabled {
    background: #f5f5f5 !important;
    border-radius: 15px;
    padding-left: 12px;
    padding-right: 12px;
  }
}