.obtCalendar-erp {
    position: relative;

    .erpToolbar {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: calc(100% - 111px);
        height: 60px;
        left: 111px;

        .previous-month-button {
            border-right: 0px !important;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            position: relative;

            &:hover::after {
                content: "";
                position: absolute;
                right: -1px;
                top: -1px;
                bottom: -1px;
                width: 1px;
                background-color: #9f9f9f;
            }
        }

        .next-month-button {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }

        .btnThisMonth {
            margin-left: 4px;
        }
    }

    .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0px;
        .fc-left {
            display: flex;
            align-items: center;
            height: 60px;
            & > h2 {
                font-size: 18px;
            }
        }
    }

    // column Header 배경, 폰트크기 지정
    .fc-day-header {
        height: 40px;
        border-radius: 0px;
        background-color: #f5f8fa;
        font-size: 12px;
        border: 0px;
        & > span {
            // position: absolute;
            // top: 50%;
            // transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }

    // column Header margin지정
    .fc-row.fc-widget-header {
        margin-bottom: 8px;
    }

    // 날짜 font 크기 지정
    .fc-day-top {
        font-size: 13px;
        padding: 3px 0px 0px 6px;
    }

    // 날짜 좌측 위로 정렬
    .fc-day-number {
        float: left !important;
    }

    // columnHeader 테두리 제거
    .fc-view.fc-dayGridMonth-view.fc-dayGrid-view thead td {
        border: none;
    }

    // 이벤트 테두리 제거
    .fc-event {
        border: none;
    }

    .fc-sat {
        color: #1c90fb;
    } /* 토요일 */
    .fc-sun {
        color: #ff0000;
    } /* 일요일 */

    .fc-event-container {
        .fc-day-grid-event {
            border-radius: 0px;
            padding: 1px 6px;
            .fc-content {
                .fc-title {
                    font-size: 10px;
                }
            }
        }
    }
}