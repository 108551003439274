$SpaceBetweenComponents: 10px;

.root {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  // margin: 5px;

  .top,
  .bottom {
    box-sizing: content-box;
    flex: 0 0 auto;
    transition: height 250ms ease-out;
  }

  .top > *:not(.resizer):not(:first-child) {
    margin-top: $SpaceBetweenComponents;
  }

  .bottom > *:not(.resizer):not(:first-child) {
    margin-top: $SpaceBetweenComponents;
  }

  .top:not(.collapsed) ~ .middle {
    margin-top: $SpaceBetweenComponents;
  }

  .middle {
    flex: 1 1 auto;
    position: relative;

    & > .wrapper {
      display: flex;
      flex-direction: row;

      .left,
      .right {
        box-sizing: content-box;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        transition: width 250ms ease-out;
      }

      .left > *:not(.resizer):not(:first-child) {
        margin-left: $SpaceBetweenComponents;
      }

      .right > *:not(.resizer):not(:first-child) {
        margin-left: $SpaceBetweenComponents;
      }

      .fill {
        flex: 1 1 auto;
        position: relative;

        & > .wrapper {
          overflow: auto;
        }
      }

      .left:not(.collapsed) ~ .fill {
        margin-left: $SpaceBetweenComponents;
      }

      .fill ~ .right:not(.collapsed) {
        margin-left: $SpaceBetweenComponents;
      }
    }
  }

  .middle ~ .bottom:not(.collapsed) {
    margin-top: $SpaceBetweenComponents;
  }
}

.resizable {
  position: relative;
  overflow: hidden;
  &:not(.collapsed) {
    &.top {
      padding-bottom: 14px;
    }
    &.bottom {
      padding-top: 14px;
    }
    &.left {
      padding-right: 14px;
    }
    &.right {
      padding-left: 14px;
    }
  }
}

.resizer {
  position: absolute;
  background-color: #e5e5e5;

  &.top {
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 4px;
    cursor: row-resize;
  }
  &.bottom {
    top: 0px;
    left: 0px;
    right: 0px;
    height: 4px;
    cursor: row-resize;
  }
  &.left {
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 4px;
    cursor: col-resize;
  }
  &.right {
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 4px;
    cursor: col-resize;
  }
}

.wrapper {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.collapsed {
  // display: none !important;
  &.top,
  &.bottom {
    height: 0px !important;
  }
  &.left,
  &.right {
    width: 0px !important;
  }
  overflow: hidden;
  // padding: 0px !important;

  &.resizable > .resizer {
    display: none !important;
  }
}

.resizing {
  transition: height 10ms ease !important;
}
