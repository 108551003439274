.root{
    display: inline-flex;
    box-sizing: content-box;
    background-color: white;
    justify-content: center;
    align-items: center;

    &.frozen{
        pointer-events: none;
        user-select: none;
    }

    &.disabled{
        cursor: not-allowed;
        opacity: 0.5;
    }
}