.wrapper { 
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #cccccc; 
    min-height: 27px;
    row-gap: 2.5px;
    padding: 1.5px 0px 2px 5px;
    flex-basis: 100%;
    
    &:focus-within {
        border: 1px solid rgb(103, 179, 246) !important;
    }

    &.useScrollMode {
        overflow-y: auto;
    }

    &.disabled {
        cursor: not-allowed;
        user-select: none;
        color: #a6a6a6;
        background-color: #f2f2f2;
    }

    &.required {
        background-color: #fef4f4;
    }

    &.readOnly {
        * > button {
            cursor: default;
        }
        border: 1px solid #cccccc !important;
    }

    &.height {
        align-content: flex-start;
    }

    &.maxHeight {
        align-content: flex-start;
        overflow-y: auto;
    }

    .item { 
        box-sizing: border-box;
        height: 21px;
        line-height: 21px;
        border-radius: 2px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1px 4px 0.7px 0px;
        white-space: nowrap;
        overflow: hidden;

        & > div {
            padding: 2.2px 3px 2.8px 5px;
            display: flex;

            p {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                line-height: 27px;
                margin-right: 4px;
            }

            img:first-child {
                width: 14px;
                height: 14px;
                padding: 4.5px 1px 0px 1px;
                margin-right: 2px;
            }

            button {
                border: none;
                outline: none;
                background: inherit;
    
                &:first-child > img {
                    width: 14px;
                    height: 14px;
                    margin: 2px 0.5px 1px 8px;
                    padding: 1.5px 2px 1px 1px;
                }
    
                &:nth-child(2)> img{
                    width: 14px;
                    height: 14px;
                    margin: 2px 0px 1px 0.5px;
                    padding: 1.5px 2px 1px 1px;
                }
            }
        }

        &.correct {
            @extend .item;
            background-color: #eaf2fd; 
        }

        &.uncorrect {
            @extend .item;
            background-color: #fff1f1; 
        }

        &.disabled {
            @extend .item;
            border: 1px solid lightgray;
        }

        &.default {
            @extend .item;
            border: 1px solid #a6a6a6;
        }

        &.required {
            @extend .item;
            background-color: white;
        }

        & > input {
            border: none;
            outline: none;
        }
    }

    .editItem {   
        @extend .item;
        border: 1px solid #a6a6a6;
        padding-left: 5px;

        &:focus {
            font-size: 12px;
            line-height: 12px;
        }

        &.correct {
            @extend .editItem;
            background-color: #eaf2fd;
        }

        &.uncorrect {
            @extend .editItem;
            background-color: #fff1f1;
        }

        &.default {
            @extend .editItem;
            border: 1px solid #a6a6a6; 
            background-color: #ffffff; 
        }
    }
    
    .inputWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 2px;
        overflow: hidden;
        flex: 1;

        & > input {
            border: none;
            outline: none;
            overflow: hidden;
            flex:1 1 auto;
            font-size: 12px;
            font-family: inherit;
            min-width: 10px;
        }
    }
}

.dropDown {
    width: 540px;
    border: 1px solid #a6a6a6;
    box-shadow: 0px 1px 1px 0px rgba(6,6,6,0.1);
    outline: none;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;

    & > ul {
        box-sizing: border-box;
        background: #ffffff;
        outline: none;

        &:focus {
            outline: none;
        }
    }
}

.dropdownItem {   
    box-sizing: border-box;
    height: 27px;
    padding: 0px 10px;
    line-height: 27px;

    &:hover {
        cursor: pointer;
    }
}

.dropDownItemInner {
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        line-height: 27px;

        .dropDownImg {
            // width: 21px;
            // height: 21px;
            object-fit: contain;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
        }
    }
}

.tooltip {
    display: initial;
}

.deleteButton {
    border: none;
    outline: none;
    background: transparent;
    background-image: url('./ic_trashcan_m_normal.png');
    width: 18px;
    height: 18px;
    line-height: 27px;
    text-align: center;
}

.toggleButton {
    flex: 0 0 auto;
    z-index: 222;
    border: none;
    outline: none;
    background: transparent;
    background-image: url('./icon-btn-arrow-down-normal.png');
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 27px;
    margin-top: 2px;

    &.open {
        background-image: url('./icon-btn-arrow-top-normal.png');
    }
}

.customButton {
    flex: 0 0 auto;
    z-index: 222;
}