@charset "utf-8";

/******************************************************
    Erp 공통
******************************************************/
/*====================================================
    [layout]
====================================================*/
/* 기본 : 컴포넌트 수정 / 기본 여백 수정 */
.wrap-content {
    [class*="OBTDockPanel_right"]:not([class*="OBTDockPanel_collapsed"]):not([class*="OBTDockPanel_resiz"]),
    [class*="OBTDockPanel_left"]:not([class*="OBTDockPanel_resiz"]) + [class*="OBTDockPanel_fill"],
    [class*="OBTDockPanel_left"] > div:not(:first-child):not([class*="OBTDockPanel_resiz"]),
    [class*="OBTDockPanel_right"] > div:not(:first-child):not([class*="OBTDockPanel_resiz"]) {margin-left:20px !important;}
}

/* 타입0 : 컴포넌트 수정 / 여백 수정 */
.wrap-content0 {
    //상단영역
    > [class*="OBTDockPanel_top"] {
        //공통
        > div {padding-left:20px;padding-right:20px;box-sizing:border-box;}
        //페이지 헤딩(타이틀 + SETP)
        > .heading {display:flex;align-items:center;width:auto !important;min-height:55px;border-bottom:1px solid #e5e5e5;
            & + .heading {margin-top:0 !important;}
            .ti {flex:1;
                > strong {display:inline-block;font-size:16px;letter-spacing:-0.8px;vertical-align:middle;
                    &:not(:first-child) {margin-left:20px;}
                }
                //가이드 문구
                > span {display:inline-block;margin-left:6px;letter-spacing:-0.3px;color:#a6a6a6;vertical-align:middle;}
                //태그 타입 아이콘
                > [class*="tag-type"] {margin:2px 0 0 7px;vertical-align:middle;}
                //input / 버튼 공통
                > div {margin-left:10px;vertical-align:middle;
                    & + .btn-date {margin-left:4px;}//input + 버튼
                }
                //버튼(이전 / 다음 / 이번주)
                .btn-date {display:inline-block;
                    > div {vertical-align:middle;
                        & + div {margin-left:4px;}
                    }
                }
            }
        }
    }
    //middle영역
    [class*="OBTDockPanel_right"]:not([class*="OBTDockPanel_collapsed"]):not([class*="OBTDockPanel_resiz"]),
    [class*="OBTDockPanel_left"]:not([class*="OBTDockPanel_resiz"]) + [class*="OBTDockPanel_fill"],
    [class*="OBTDockPanel_left"] > div:not(:first-child):not([class*="OBTDockPanel_resiz"]),
    [class*="OBTDockPanel_right"] > div:not(:first-child):not([class*="OBTDockPanel_resiz"]) {margin-left:20px !important;}
}

/* 타입1 : 컴포넌트 수정 / col, row 균등 */
.wrap-content1 {
    //col 균등
    //col 균등 공통
    &.t1, &.t6, &.t2, &.t5 {
        > [class*="OBTDockPanel_middle"] > [class*="OBTDockPanel_wrapper"] {
            > [class*="OBTDockPanel_left"],
            > [class*="OBTDockPanel_right"] {flex:1;
                &[class*="OBTDockPanel_resiz"] {flex:none;}
                > div:not([class*="OBTDockPanel_resiz"]) {flex:1;}
            }
            > [class*="OBTDockPanel_right"]:not([class*="OBTDockPanel_resiz"]),
            > [class*="OBTDockPanel_left"]:not([class*="OBTDockPanel_resiz"]) + [class*="OBTDockPanel_fill"] {margin-left:20px !important;}
        }
    }
    //타입1-1 : col 균등(2개)
    &.t1 > [class*="OBTDockPanel_middle"] > [class*="OBTDockPanel_wrapper"] {
        > [class*="OBTDockPanel_left"][class*="OBTDockPanel_resiz"],
        > [class*="OBTDockPanel_right"][class*="OBTDockPanel_resiz"] {width:calc(50% - 12px);}
    }
    //타입1-2 : col 균등(3개)
    &.t6 > [class*="OBTDockPanel_middle"] > [class*="OBTDockPanel_wrapper"] {
        > [class*="OBTDockPanel_left"][class*="OBTDockPanel_resiz"],
        > [class*="OBTDockPanel_right"][class*="OBTDockPanel_resiz"] {width:calc(33.3% - 16px);}
    }
    //타입1-3 : col 균등(3개) / 여백 수정
    &.t2 > [class*="OBTDockPanel_middle"] > [class*="OBTDockPanel_wrapper"] {
        > [class*="OBTDockPanel_right"]:not([class*="OBTDockPanel_resiz"]),
        > [class*="OBTDockPanel_left"]:not([class*="OBTDockPanel_resiz"]) + [class*="OBTDockPanel_fill"] {margin-left:0 !important;}
    }
    //타입1-4 : col 1:2
    &.t5 > [class*="OBTDockPanel_middle"] > [class*="OBTDockPanel_wrapper"] {
        > [class*="OBTDockPanel_right"],
        > [class*="OBTDockPanel_fill"]:not(:first-child) {flex-grow:2;}
        > [class*="OBTDockPanel_left"][class*="OBTDockPanel_resiz"] {width:calc(33.3% - 12px);}
        > [class*="OBTDockPanel_right"][class*="OBTDockPanel_resiz"] {width:calc(66.6% - 12px);}
    }

    //row 균등
    //타입1-5 / 타입1-6 공통
    &.t3, &.t7 {
        > [class*="OBTDockPanel_top"],
        > [class*="OBTDockPanel_bottom"] {flex:1;
            &[class*="OBTDockPanel_resiz"] {flex:none;}
            > div:not([class*="OBTDockPanel_resiz"]) {height:100%;}
        }
        //심플스크롤 내 아코디언 컴포넌트
        .scroll-line {
            [data-orbit-component="OBTAccordion"]:last-child,
            [data-orbit-component="OBTAccordion2"]:last-child {margin-bottom:1px !important;}
        }
    }
    //타입1-5 : row 균등(2개)
    &.t3 {
        > [class*="OBTDockPanel_top"][class*="OBTDockPanel_resiz"],
        > [class*="OBTDockPanel_bottom"][class*="OBTDockPanel_resiz"] {height:calc(50% - 12px);}
    }
    //타입1-6 : row 균등(3개)
    &.t7 {
        > [class*="OBTDockPanel_top"][class*="OBTDockPanel_resiz"],
        > [class*="OBTDockPanel_bottom"][class*="OBTDockPanel_resiz"] {height:calc(33.3% - 16px);}
    }
    //타입1-7 : row 균등(2개) / 전표입력(전표분개) 전용
    &.t4 {
        //기본
        > [class*="OBTDockPanel_bottom"] {height:40%;
            //상세그리드
            [class*="OBTDockPanel_bottom"]:not([class*="OBTDockPanel_resiz"]) {/* height:0; */margin-top:0;}/* 전표입력 사용 모든 메뉴에서 open 클래스명이 추가되면 height 활성화 */
        }
        &.open {
            > [class*="OBTDockPanel_bottom"] {height:60%;
                //상세그리드
                [class*="OBTDockPanel_bottom"]:not([class*="OBTDockPanel_resiz"]) {height:102px;margin-top:10px;}
            }
        }
        //아코디언 내부 전표분개 삽입
        .wrap-t4 {
            &[data-orbit-component="OBTAccordion"] > div:first-child > div:nth-child(2) > [data-orbit-component="OBTDockPanel"],
            &[data-orbit-component="OBTAccordion2"] > [class*="OBTAccordion2_contents"] > [data-orbit-component="OBTDockPanel"] {
                > [class*="OBTDockPanel_middle"] {min-height:117px;}
            }
        }
    }
}

/* 타입2 : 컴포넌트 수정 / 여백 수정 */
.wrap-content2 {
    > [class*="OBTDockPanel_middle"] > [class*="OBTDockPanel_wrapper"] {
        > [class*="OBTDockPanel_fill"],
        > [class*="OBTDockPanel_left"],
        > [class*="OBTDockPanel_right"] {margin-left:0 !important;
            > div:not(:first-child) {margin-left:0 !important;}
        }
    }
}

/* 타입3 : 레이아웃 설정 */
.wrap-content3 {
    //기본
    > div:not(:first-child),
    > ul:not(:first-child),
    > ol:not(:first-child),
    > dl:not(:first-child),
    > p:not(:first-child),
    > table:not(:first-child) {margin-top:10px;}
    //타입1
    &.t1 {
        > div:not(:first-child),
        > ul:not(:first-child),
        > ol:not(:first-child),
        > dl:not(:first-child),
        > p:not(:first-child),
        > table:not(:first-child) {margin-top:30px;}
    }
    //타입2
    &.t2 {
        > div:not(:first-child),
        > ul:not(:first-child),
        > ol:not(:first-child),
        > dl:not(:first-child),
        > p:not(:first-child),
        > table:not(:first-child) {margin-top:20px;}
    }
}

/* 타입4 : 레이아웃 설정 */
.wrap-content4 {display:flex;flex-wrap:wrap;width:100%;
    //기본 : 1*1, 2*2 / 자식균등
    > div, > ul, > ol, > dl, > p {flex:1;min-width:calc(50% - 10px);height:auto !important;
        &:nth-child(2n) {margin-left:20px;}
        &:nth-child(2) {
            ~ div, ~ ul, ~ ol, ~ dl, ~ p {margin-top:20px;}
        }
    }
    //타입1 : 1*n구조(4개 이상) / 자식 균등
    &.t1 {
        > div, > ul, > ol, > dl, > p {min-width:auto;
            &:not(:first-child) {margin:0 0 0 20px;}
        }
    }
    //타입2 : 1*2 / 균등제거
    &.t2 {
        > div, > ul, > ol, > dl, > p {min-width:auto;
            &:not(:first-child) {flex:none;margin:0 0 0 20px;}
        }
    }
}

/* 타입5 : 레이아웃 설정 */
.wrap-content5 {
    > .content {display:flex;flex-direction:column;height:100%;box-sizing:border-box;
        //heading(컨텐츠 헤딩)
        > .heading {padding:0 !important;}
        //컨텐츠
        > .cont {flex:1;}
        > div:not(.heading) + div:not(.UpDownLoader):not([data-orbit-component="OBTExcelFormDialog"]) {margin-top:10px;}
    }
}

/* 타입6 : 컴포넌트 수정 / 높이 수정 */
.wrap-content6 {
    > [class*="OBTDockPanel_middle"] {min-height:201px;}
}

/*====================================================
    [contents]
====================================================*/
/*----------------------------------------------------
    [OBTConfirm : 기본설정 해제]
----------------------------------------------------*/
[data-orbit-component="OBTConfirm"], [data-orbit-component="OBTAlert"] {
    [class*="wrap-content"] {text-align:left;}
}

/*----------------------------------------------------
    [wrap-content 공통]
----------------------------------------------------*/
[class*="wrap-content"] {
    /*----------------------------------------------------
        [공통]
    ----------------------------------------------------*/
    /* reset */
    & {word-break:break-all;}
    i {font-style:normal;}
    a:not([class*="fc-"]) {color:#1c90fb;}//달력내 a태그 제외
    .area-hidden {display:none !important;}

    /* <a> 태그와 동일한 디자인(<a> 태그 미사용) */
    .text-a {color:#1c90fb;text-decoration:underline;cursor:pointer;}

    /* display */
    .dp_f {display:flex !important;}
    .dp_if {display:inline-flex !important;}
    .dp_t {display:table !important;}

    /* 여백 */
    .mt11 {margin-top:11px !important;}
    .pr19 {padding-right:19px !important;}
    
    /* font색상 */
    .text_red {color:#fc5356 !important;}//common.scss 수정되면 삭제
	.text_red1 {color:#f46c6c !important;}
    .text_gray1 {color:#8c8c8c !important;}
    .text_black1 {color:#4a4a4a !important;}
    .text-type100 {color:#919191 !important;}//진행완료
    .text-type101 {color:#20c997 !important;}//승인, 완료
    .text-type102 {color:#2dbcb5 !important;}//승인, 완료
    .text-type103 {color:#39b0d2 !important;}//승인, 완료
    .text-type104 {color:#46a3f0 !important;}//진행
    .text-type105 {color:#ff8787 !important;}//반려
    .text-type106 {color:#f8a457 !important;}//수정, 보류
    .text-type107 {color:#f0c325 !important;}//대기
    .text-type108 {color:#c8b465 !important;}//임시보관
    .text-type109 {color:#9da3aa !important;}//미청구

    /* 숫자 넘버링 */
    [class^="list-num"] {display:inline-block;width:18px;height:18px;border-radius:50%;background:#94a5c1;font-size:11px;line-height:1.6;color:#fff;text-align:center;}
    .list-num1 {background:#4eabfa}
    .list-num2 {background:#50cbde}
    .list-num3 {background:#afd873}
    .list-num4 {background:#f7ad68}
    .list-num5 {background:#f5d471}

    /* EmptySet : UC 공통 사용(공통 수정 및 추가) */
    .no_data {flex-direction:column;
        //only Text
        .noimg {margin:0;padding:0;background:none;}
        //text 2row
        &.t1 .text {padding-top:126px;}//Large Size
        &.small.t1 .text {padding-top:88px;}//Small Size
        //버튼
        > [data-orbit-component="OBTButton"] {margin-top:12px;}
    }

    /* 컨텐츠 시작 위치 맞춤 */
    .cont-offset {height:calc(100% - 33px) !important;margin-top:33px;}

    /*----------------------------------------------------
        [메인 타이틀영역 : 컴포넌트 영역(OBTPageContainer_titleText)]
    ----------------------------------------------------*/
    &.wrap-ti {display:flex;align-items:center;
        //구분표시 / 아이콘
        > [class*="state-type"], > img {margin-left:10px;}
        //안내메시지
        > .guide {margin-left:10px;font-size:12px;line-height:18px;color:#4a4a4a;
            &[class*="ico"] {padding-left:20px;background-repeat:no-repeat;background-position:0 50%;}
            &.ico1 {background-image:url('../imgs/ico/ic_caution_01_m_normal.png');}
            &.ico2 {background-image:url('../imgs/ico/ic_close_02_m_normal.png');}
            &.ico3 {background-image:url('../imgs/ico/ic_security_m_normal.png');}
        }
    }

    /*----------------------------------------------------
        [상세헤딩 : heading + 데이터(리스트)]
    ----------------------------------------------------*/
    .data-heading {
        /* 타입1(기본) */
        > [class^="heading"]:not(.heading2) {display:flex;min-height:33px;
            //블릿해제
            &.t1 > strong:first-child:before {display:none;}
            //타이틀
            > strong:first-child {height:28px;margin-top:5px;font-size:14px;color:#000;letter-spacing:-0.7px;
                &:before {content:'';display:inline-block;width:3px;height:3px;margin:-1px 5px 0 0;background:#333;vertical-align:middle;}
                //중요 타이틀 색상 설정
                .type {color:#868e96;}//기타
                .type1 {color:#46a3f0;}//정상
                .type2 {color:#20c997;}//휴가
                .type3 {color:#748ffc;}//출장
                .type4 {color:#ac91f5;}//외근
                .type5 {color:#f0c325;}//지각
                .type6 {color:#ffa94d;}//조퇴
                .type7 {color:#ff8787;}//결근
                //구분바 추가
                > i + i:before {content:'';display:inline-block;width:1px;height:16px;margin:-2px 10px 0;background:#e6e6e6;vertical-align:middle;}
            }
            //OBTTreeView 타이틀
            > .tree {margin-top:5px;
                button {height:28px;padding:0;border:none !important;background:none !important;
                    span {height:28px;padding-left:18px;font-size:14px;color:#000;letter-spacing:-0.7px;font-weight:bold;}
                    &:hover span:after {display:none;}
                }
                &.a-down button {
                    span {background:url('../imgs/ico/ic_arrow_down_01_m_normal.png') no-repeat left 2px;}
                    &:hover span {background-image:url('../imgs/ico/ic_arrow_down_01_m_over.png');}
                }
                &.a-up button {
                    span {background:url('../imgs/ico/ic_arrow_up_01_m_normal.png') no-repeat left 3px;}
                    &:hover span {background-image:url('../imgs/ico/ic_arrow_up_01_m_over.png');}
                }
            }
            //서브영역
            > span {flex:1;color:#1c90fb;letter-spacing:-0.33px;text-align:right;}//오른쪽정렬(기본)
            > span, > div:not(.tree):not([class*="btn"]) {margin-top:7px;padding-left:8px;//가운데영역
                //공통
                > span {color:#1c90fb;letter-spacing:-0.33px;
                    &:not(:first-child) {padding-left:10px;}
                    & + [data-orbit-component="OBTCheckBox"],
                    & + [data-orbit-component="OBTRadioButtonGroup"] {margin-left:10px;}
                }//보조문구
                i {color:#4a4a4a;letter-spacing:-0.5px;}//보조문구
                > [data-orbit-component="OBTCheckBox"] + [data-orbit-component="OBTRadioButtonGroup"],
                > [data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTCheckBox"] {margin-left:10px;}
                &.t1, &.t2, &.t3, &.t4 {flex:1 1 auto;
                    //상태 공통
                    > [class*="tag-type"], > [class*="state-type"],//상태(아이콘)
                    > span > [class*="tag-type"], > span > [class*="state-type"] {vertical-align:top;//상태 + 상태설명
                        &:not(:first-child) {margin-left:10px;}
                    }
                }
                //왼쪽정렬(checkbox + text / text)
                &.t1 {text-align:left;
                    > div {vertical-align:top;}//checkbox/radio
                    //상태
                    > [class*="state-type1"],//상태(아이콘)
                    > span > [class*="state-type1"] {margin-top:2px;}//상태 + 상태설명
                }
                //왼쪽정렬(OBTTextField / OBTCodePicker / OBTDropDownList ...)
                &.t2 {margin-top:0;text-align:left;
                    > div {vertical-align:bottom;//input
                        & + div {padding-left:8px;}
                    }
                    > span {display:inline-block;margin-bottom:2px;}//보조문구
                    //상태
                    > [class*="tag-type"], > [class*="state-type"] {margin-top:7px;
                        & + div {padding-left:10px;}//상태 + input
                    }
                    > [class*="state-type1"] {margin-top:9px;}//상태(아이콘)
                    > span > [class*="state-type1"] {margin-top:2px;}//상태 + 상태설명
                }
                //오른쪽정렬(checkbox + text / text)
                &.t3 {text-align:right;
                    &.vm {margin-top:0;line-height:27px;}//보조문구(세로가운데정렬)
                    & + [class*="btn"] {flex:none;}
                    > div {text-align:left;vertical-align:top;}//checkbox / radio
                    //상태
                    > [class*="state-type1"],//상태(아이콘)
                    > span > [class*="state-type1"] {margin-top:2px;}//상태 + 상태설명
                }
                //오른쪽정렬(OBTTextField / OBTCodePicker / OBTDropDownList ...)
                &.t4 {margin-top:0;text-align:right;
                    > .ti {margin:0 6px 0 20px;line-height:27px;}
                    > div {text-align:left;vertical-align:bottom;//input
                        & + div {padding-left:8px;}
                    }
                    > span {display:inline-block;margin-bottom:2px;}//보조문구
                    > .summary {vertical-align:bottom;}
                    & + [class*="btn"] {flex:none;}
                    //상태
                    > [class*="tag-type"], > [class*="state-type"] {margin-top:7px;
                        & + div {padding-left:10px;}//상태 + input
                    }
                    > [class*="state-type1"] {margin-top:9px;}//상태(아이콘)
                    > span > [class*="state-type1"] {margin-top:2px;}//상태 + 상태설명
                }
                //only 텍스트 + 14px
                &.t5 {margin-top:5px;font-size:14px;}
                //only 보조문구
                &:first-child {padding-left:0;}
                //정보영역
                &.summary,
                &.t1 > .summary, &.t2 > .summary, &.t3 > .summary, &.t4 > .summary {
                    em {display:inline-block;
                        &:not(:first-child)::before {content:'';display:inline-block;margin:0 9px 0 10px;width:1px;height:10px;background:#e6e6e6;line-height:10px;font-size:0;}
                    }
                }
                //search
                .search {position:relative;display:inline-block;
                    > span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                    input {padding-right:22px !important;box-sizing:border-box;}
                    > div {vertical-align:bottom;
                        & + div {margin-left:4px;}
                        &:last-child {position:absolute;right:1px;top:1px;}
                    }
                }
            }
            //버튼 공통
            > [class*="btn"] {padding-left:10px;text-align:right !important;
                > div {display:inline-block;
                    &:not(:only-child):not(:last-child) {margin-right:4px;}
                    > div:not(:only-child):not(:last-child) {margin-right:4px;}
                }
                //이미지 버튼(테두리 X)
                > div:only-child button.img-btn1 {margin-top:3px;}
                button {vertical-align:middle;
                    img {vertical-align:middle;}
                    img + span {margin-top:-1px;}
                }
                .img-btn1 {width:auto;}
            }
            > strong + [class*="btn"],//타이틀 + 버튼
            > [data-orbit-component="OBTTooltip"] + [class*="btn"],//OBTTreeView 타이틀 + 버튼
            > [class*="btn"]:only-child {flex:1;}//only 버튼
            //버튼 구분바 O
            > .btn {
                > div:not(:only-child):not(:last-child):after {content:'';display:inline-block;width:1px;height:16px;margin:0 6px 0 10px;background:#e6e6e6;vertical-align:middle;}
            }
            > .t1 + .btn, > .t2 + .btn, > .t3 + .btn, > .t4 + .btn {
                > div:only-child {
                    &:before {content:'';display:inline-block;width:1px;height:16px;margin:2px 10px 0 0;background:#e6e6e6;vertical-align:middle;}
                    [class*="OBTTooltip_tooltipFloatingPanel"] {margin-left:6px;}
                }
            }
            //버튼 구분바 X
            > .btn1 {
                > div:not(:only-child):not(:last-child):after {display:none;}
            }
        }
        > [class^="heading"] ~ div:not(.fix) {height:calc(100% - 33px) !important;
            //서브타이틀
            > .ti, .wrap-content3 > .ti {display:block;padding-bottom:5px;
                &:not(:first-child) {margin-top:10px;}
                > span {padding-left:10px;font-weight:normal;}
                //타입1
                &.t1 {
                    &:before {content:'';display:inline-block;width:3px;height:3px;margin:-1px 5px 0 0;border-radius:50%;background:#000;vertical-align:middle;}
                }
                //타입2
                &.t2 {padding-top:13px;border-top:1px solid #666;
                    &:before {content:'';display:inline-block;width:3px;height:3px;margin:-1px 5px 0 0;border-radius:50%;background:#000;vertical-align:middle;}
                    & + div, & + table {border-top:1px solid #e6e6e6;}
                    &:not(:first-child) {margin-top:0;border-top:none;}
                }
            }
            &[data-orbit-component="OBTDockPanel"] {
                > div > div > [class*="OBTDockPanel_fill"] > div > div > .ti,
                > div > div > [class*="OBTDockPanel_left"] > div > .ti,
                > div > div > [class*="OBTDockPanel_right"] > div > .ti,
                > [class*="OBTDockPanel_top"] > div > .ti,
                > [class*="OBTDockPanel_bottom"] > div > .ti {display:block;padding-bottom:5px;
                    &:not(:first-child) {margin-top:10px;}
                }
            }
            //wrap-content3 내부 > 서브타이틀
            &.wrap-content3, .wrap-content3 {
                > .ti {margin-bottom:-5px;padding-bottom:0;}
            }
        }

        /* 타입2 */
        > .heading1 {margin-bottom:10px;border-bottom:1px solid #e6e6e6;box-sizing:border-box;
            //border color 변경
            &.border1 {border-color:#666;}
        }
        > .heading1 ~ div:not(.fix) {height:calc(100% - 44px) !important;}

        /* 타입3 */
        > .heading2 {border-bottom:1px solid #d9d9d9;
            > strong:first-child {display:block;height:34px;padding:0 10px;background:#fafafa;font-size:13px;line-height:34px;color:#000;
                > span {display:inline-block;padding-left:6px;font-weight:normal;}
            }
        }
        > .heading2 ~ div:not(.fix) {height:calc(100% - 35px) !important;}

        /* 타입4 */
        > .heading3 {
            //기본타이틀
            > strong:first-child {color:#1c90fb !important;
                &:before {width:22px !important;height:22px !important;margin-top:-4px !important;background:transparent no-repeat !important;}
            }
        }

        /* 타입5 */
        > .heading4:not(.heading2) {height:40px;padding:0 20px;border:1px solid #d9d9d9;border-top:2px solid #666;background-color:#fafafa;box-sizing:border-box;
            & > strong:first-child {height:38px;line-height:38px;margin-top:0;}
            & ~ div:not(.fix) {height:calc(100% - 41px) !important;}
            & ~ div {border-top:none;}
            //배경1
            &.t1 {background-color:#fff1f1;
                & > strong:first-child:before {display:inline-block;}
            }
            //배경2
            &.t2 {background-color:#eaf2fd;}
        }

        /* 타입6 */
        &.heading-none {
            > [class^="heading"] {display:none;}
            > [class^="heading"] ~ div:not(.fix) {height:100% !important;}
        }

        /* 타입7 : 기존메뉴 제외 신규메뉴 사용 안함(23.08.03) */
        &.heading-none2 {
            > [class^="heading"] {visibility:hidden;}
        }

        /* 타입8 */
        &.data-heading.center{
            .heading{ max-height:45px;margin-top:0px;
                .heading_in{  box-sizing:border-box;line-height:45px;width:100%;height:45px;
                    margin-top:0px !important;position:relative;overflow: hidden;
                    strong{width: 100%;position: absolute;top: 0; text-align: center;font-size:15px;}
                }
            }
            > [class^="heading"] ~ div:not(.fix) {height:calc(100% - 45px) !important;}
        }
    }

    /*----------------------------------------------------
        [컨텐츠헤딩 : 타이틀 / 보기방식 / 검색])
    ----------------------------------------------------*/
    .content > .heading {display:flex;align-items:center;height:53px;margin-bottom:20px;padding:0 20px;border-bottom:1px solid #e6e6e6;box-sizing:border-box;
        .ti {display:flex;align-items:center;flex:1 1 auto;
            > span:not(.checkbox):first-child {margin-right:10px;color:#a6a6a6;}//타이틀1
            > strong {font-size:14px;letter-spacing:-0.7px;}//타이틀(기본)
            > strong + div {margin-left:10px;}//타이틀 + 버튼
            > div + div {margin-left:4px;}//버튼 + 버튼
            //버튼(달력)
            .btn-date {margin-left:5px;
                > div {width:20px !important;
                    > div:first-child {position:relative;padding:0;border:none;
                        &:before {content:'';position:absolute;left:50%;top:50%;width:4px;height:4px;margin:-3px 0 0 -3px;border:solid #666;border-width:0 2px 2px 0;transform:rotate(45deg);}
                        > input {width:100%;height:100%;opacity:0;box-sizing:border-box;}
                        > img {right:0;top:0;width:100%;height:100%;padding:0;opacity:0;}
                    }
                }
            }
        }
        .date {flex:1 1 auto;
            > b {line-height:27px;color:#010101;
                &:not(:first-child) {margin-left:15px;}
            }
            > div {margin-left:6px;vertical-align:middle;}
        }
        .btn {flex:1 1 auto;min-width:120px;color:#010101;font-weight:bold;text-align:right;
            > div {vertical-align:middle;
                &:not(:last-child) {margin-right:10px;
                    & + div {margin-left:-6px;}
                }
            }
            [data-orbit-component="OBTButton"] {font-weight:normal;}
            //구분바
            .bar {display:inline-block;
                &:after {content:'';display:inline-block;width:1px;height:16px;margin:0 6px 0 10px;background:#e6e6e6;vertical-align:middle;}
                > div {vertical-align:middle !important;
                    & + div {margin-left:4px;}
                }
            }
            //뷰타입
            .viewtype {display:inline-block;height:27px;margin-left:9px !important;}
            .bar + strong, .bar + .viewtype {margin-left:-6px !important;}//bar영역 + 뷰타입타이틀/뷰타입
        }
        .search {position:relative;
            > span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}//타이틀
            > [data-orbit-component="OBTTextField"] {vertical-align:top;//input
                input {padding-right:22px !important;box-sizing:border-box;}
            }
            > [data-orbit-component="OBTButton"] {position:absolute;right:1px;top:1px;}//검색버튼
        }
        .guide {font-size:14px;color:#1c90fb;}
        .ti ~ div, .date ~ div {margin-left:10px;}

        /* 타입1 */
        &.t1 {height:27px;margin-bottom:14px;border-bottom:none;
            .ti {
                > strong {padding-right:4px;font-size:18px;}
                > div:nth-child(3) {margin-left:-1px;}
                > div:nth-child(4) {margin-left:4px;}
            }
        }
    }

    /*----------------------------------------------------
        [검색영역 : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 공통 */
    [data-orbit-component="OBTConditionItem"] {
        > div {//OBTConditionItem_tooltipWrapper
            > div:not(:only-child):not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTTooltip"]):not([data-orbit-component="OBTRadioButtonGroup"]) {height:27px;line-height:26px;
                & + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {margin-left:2px;}
            }
            > div:not([data-orbit-component="OBTCheckBox"]) + [data-orbit-component="OBTCheckBox"],
            > [data-orbit-component="OBTCheckBox"] + div:not([data-orbit-component="OBTCheckBox"]),
            > [data-orbit-component="OBTRadioButtonGroup"] + div:not([data-orbit-component="OBTRadioButtonGroup"]) {margin-left:10px;}
            > div + [data-orbit-component="OBTRadioButtonGroup"],
            > [data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTCheckBox"] {margin-left:20px !important;}
			> div + [data-orbit-component="OBTTooltip"] {margin-left:5px !important;}
        }

        /* OBTCodePicker */
        .codepicker {height:27px;}//IE

        /* OBTPreviousNextSelector */
        [data-orbit-component="OBTPreviousNextSelector"] {width:calc(100% - 120px);}

        /* ~ 표시 */
        .dash {display:inline-block;width:14px;text-align:center;
            & + div {margin-left:0 !important;}
        }

        /* nolabel */
        &.nolabel-area {
            > div {//OBTConditionItem_tooltipWrapper
                //타이틀 영역 input 설정
                > div:not(:only-child):first-child {
                    > div {width:102px !important;}
                    & + div {margin-left:6px !important;}
                    //필수입력
                    &[data-orbit-component="OBTTooltip"] {left:108px;
                        > div {width:auto !important;}
                        & + div {width:auto !important;margin-left:0 !important;
                            > div {width:102px !important;}
                            & + div {margin-left:6px !important;}
                            //only(label 영역 공백(기본))
                            &.required {width:100% !important;margin-left:108px !important;
                                > div:not([data-orbit-component="OBTCheckBox"]) {width:100% !important;}
                                //내용 label 영역부터 시작
                                &.t1 {width:100% !important;margin-left:0 !important;}
                            }
                        }
                    }
                }
                > div:not(:first-child):not([data-orbit-component="OBTDatePicker"]):not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {width:100%;}
                //only(label 영역 공백(기본))
                > div:only-child {margin-left:108px;
                    > div {vertical-align:middle !important;}
                    > div:not(:only-child):not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTTooltip"]):not([data-orbit-component="OBTRadioButtonGroup"]) {height:27px;line-height:26px;
                        & + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {margin-left:2px;}
                        & + [data-orbit-component="OBTRadioButtonGroup"] {margin-left:20px;}
                    }
                    > div:not([data-orbit-component="OBTDatePicker"]):not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {width:100%;}
                    > div:not([data-orbit-component="OBTTooltip"]):not([data-orbit-component="OBTCheckBox"]) + [data-orbit-component="OBTCheckBox"],
                    > [data-orbit-component="OBTCheckBox"] + div:not([data-orbit-component="OBTCheckBox"]),
                    > [data-orbit-component="OBTRadioButtonGroup"] + div:not([data-orbit-component="OBTRadioButtonGroup"]) {margin-left:10px;
                        & + [data-orbit-component="OBTRadioButtonGroup"] {margin-left:20px;}
                    }
                    //내용 label 영역부터 시작
                    &.t1 {width:100%;margin-left:0;}
                }
            }
            //필수입력(내용 label 영역부터 시작)
            &.t1 {
                > div > [data-orbit-component="OBTTooltip"] {left:0 !important;}
            }
        }
    }

    /*----------------------------------------------------
        [아코디언 : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 공통 */
    [data-orbit-component="OBTAccordion"], [data-orbit-component="OBTAccordion2"] {
        //아코디언 마지막 항목 기본 여백 초기화
        &:last-child {margin-bottom:0;}
        //아코디언 타이틀 버튼(열기 / 닫기) 상단 여백 초기화 : 컴포넌트에 margin-top:-1로 되어있어 처음 아코디언 상단 라인 짤림(컴포넌트 수정되면 삭제)
        &:first-child > div:first-child > div:first-child > button,
        &:first-child > div:first-child > [class*="OBTAccordion2_accordion"] {margin-top:0 !important;}
        //아코디언 내부 그리드 / 폼패널 / 정보테이블(기본) : Topline 1px
        [id^="gridWrapper"],
        [data-orbit-component="OBTFormPanel"],
        .data-tbl1, .data-tbl2, .data-tbl6, .data-tbl8, .data-tbl4, .data-tbl10 {border-top:1px solid #e6e6e6;}
        //아코디언 내부 그리드 / 폼패널 / 정보테이블 / 타이틀 + 그리드(폼패널, 정보테이블) : Topline 2px
        .data-heading {
            .data-tbl10 {border-top:1px double #666;}
            [id^="gridWrapper"],
            [data-orbit-component="OBTFormPanel"],
            .data-tbl1, .data-tbl2, .data-tbl6, .data-tbl8, .data-tbl4, .data-tbl10.t2 {border-top:2px solid #666;}
        }
        //아코디언 타이틀 내 버튼 영역 / 버튼 2개 이상
        > div:first-child > div:first-child .btn {display:inline-block;
            [data-orbit-component="OBTButton"] {margin-left:4px;}
        }
        //그리드 최소 높이 설정
        [id^="gridWrapper"] {min-height:68px;}
        [class*="data-heading"] [id^="gridWrapper"] {min-height:69px;}
    }

    /* 아코디언 높이 100% 설정 */
    //단일 아코디언(type=default)
    //[OBTAccordion]
    [data-orbit-component="OBTAccordion"].p100 {height:100%;
        > div:nth-child(1) {display:flex;flex-direction:column;height:100%;
            > div:nth-child(2) {flex:1;box-sizing:border-box;}
        }
    }
    //[OBTAccordion2]
    [data-orbit-component="OBTAccordion2"].p100 {display:flex;flex-direction:column;height:100%;
        &[class*="Accordion2_opened"] > [class*="OBTAccordion2_contents"] {flex:1;box-sizing:border-box;}
    }

    //그룹 아코디언(OBTAccordionGroup type=default)
    .accordion-p100 {display:flex;flex-direction:column;height:100%;
        //[OBTAccordion]
        //기본
        > [data-orbit-component="OBTAccordion"][class*="OBTAccordionGroup_selected"] {flex:1;
            > div:nth-child(1) {display:flex;flex-direction:column;height:100%;
                > div:nth-child(2) {flex:1;box-sizing:border-box;}
            }
        }
        //필요한 경우만 높이 100% 설정
        &.t1 {
            > [data-orbit-component="OBTAccordion"].p100 {height:auto;}
            > [data-orbit-component="OBTAccordion"][class*="OBTAccordionGroup_selected"]:not(.p100) {flex:none;}
            > [data-orbit-component="OBTAccordion"][class*="OBTAccordionGroup_selected"].p100 {flex:1;
                > div:nth-child(1) > div:nth-child(2) {
                    [class*="data-heading"] {height:100%;}
                    > .wrap-content4 {height:100%;}//OBTDockPanel X / div col 균등 레이아웃
                }
            }
        }
        //[OBTAccordion2]
        //기본
        > [data-orbit-component="OBTAccordion2"][class*="Accordion2_opened"] {flex:1;display:flex;flex-direction:column;
            > [class*="OBTAccordion2_contents"] {flex:1;box-sizing:border-box;}
        }
        //필요한 경우만 높이 100% 설정
        &.t1 {
            > [data-orbit-component="OBTAccordion2"].p100 {height:auto;}
            > [data-orbit-component="OBTAccordion2"][class*="Accordion2_opened"]:not(.p100) {flex:none;}
            > [data-orbit-component="OBTAccordion2"][class*="Accordion2_opened"].p100 {flex:1;
                > [class*="OBTAccordion2_contents"] {
                    [class*="data-heading"] {height:100%;}
                    > .wrap-content4 {height:100%;}//OBTDockPanel X / div col 균등 레이아웃
                }
            }
        }
    }

    /* 타입1 */
    //단일 아코디언
    .accordion-type1 {border-radius:4px;background:#fff;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.1);
        //[OBTAccordion]
        &[data-orbit-component="OBTAccordion"] {
            > div:nth-child(1) {
                //타이틀영역
                > div:nth-child(1) > button {height:46px !important;padding-left:20px !important;border:none !important;font-size:12px !important;color:#000 !important;font-weight:bold !important;
                    //열기/접기
                    > span:nth-child(2) {right:23px !important;}
                }
                //컨텐츠영역
                > div:nth-child(2) {margin:0 20px !important;padding:15px 0 !important;border-bottom:none !important;border-top:1px solid #e6e6e6 !important;color:#4a4a4a;}
            }
        }
        //[OBTAccordion2]
        &[data-orbit-component="OBTAccordion2"] {
            //타이틀영역
            > [class*="OBTAccordion2_wrapper"] > [class*="OBTAccordion2_accordion"] {height:46px;padding-left:20px;border:none;font-size:12px;
                //타이틀
                > [class*="OBTAccordion2_title"] {color:#000;font-weight:bold;}
                //열기/접기
                > [class*="OBTAccordion2_arrowIcon"] {right:20px;}
            }
            //컨텐츠영역
            &[class*="Accordion2_opened"] > [class*="OBTAccordion2_contents"] {margin:0 20px;padding:15px 0;border-bottom:none;border-top:1px solid #e6e6e6;color:#4a4a4a;}
        }
    }
    //그룹 아코디언
    [class*="OBTAccordionGroup"] {
        .accordion-type1:not(:last-child) {margin-bottom:10px !important;}
        .accordion-type1 {
            //[OBTAccordion]
            &[data-orbit-component="OBTAccordion"],
            &[data-orbit-component="OBTAccordion"] + .accordion-type1,
            &[data-orbit-component="OBTAccordion"][class*="OBTAccordionGroup_selected"] {
                //타이틀
                > div:nth-child(1) > div:nth-child(1) > button {border:none !important;}
            }
            //컨텐츠
            &[data-orbit-component="OBTAccordion"]:last-child > div:nth-child(1) > div:nth-child(2) {border-bottom:none !important;}
        }
    }

    /* 타입2 */
    //단일 아코디언
    .accordion-type2 {border-radius:4px;border:1px solid #d5dce8;background:#f2f5fa;
        //[OBTAccordion]
        &[data-orbit-component="OBTAccordion"] {
            > div:nth-child(1) {
                //타이틀영역
                > div:nth-child(1) > button {height:39px !important;padding-left:20px !important;border:none !important;font-size:12px !important;color:#000 !important;font-weight:normal !important;
                    &:before {width:22px !important;}
                    //이미지
                    > span:nth-child(1) {left:20px !important;}
                    //열기/접기
                    > span:nth-child(2) {right:22px !important;}
                }
                //컨텐츠영역
                > div:nth-child(2) {padding-left:20px !important;padding-right:20px !important;border-bottom:none !important;border-top:1px solid #d5dce8 !important;}
            }
        }
        //[OBTAccordion2]
        &[data-orbit-component="OBTAccordion2"] {
            //타이틀영역
            > [class*="OBTAccordion2_wrapper"] > [class*="OBTAccordion2_accordion"] {height:39px;padding-left:20px;border:none;font-size:12px;
                //이미지
                &[class*="OBTAccordion2_defaultHavingImageUrl"] {padding-left:42px;}
                > [class*="OBTAccordion2_imageUrl"] {left:20px;margin-top:-9px;}
                //타이틀
                > [class*="OBTAccordion2_title"] {color:#000;font-weight:normal;}
                
                //열기/접기
                > [class*="OBTAccordion2_arrowIcon"] {right:20px;width:14px;height:14px;background-image:url('../imgs/ico/ic_refer_arrow_down_normal.png');
                    &[class*="OBTAccordion2_open"] {background-image:url('../imgs/ico/ic_refer_arrow_up_normal.png');}
                }
            }
            //컨텐츠영역
            &[class*="Accordion2_opened"] > [class*="OBTAccordion2_contents"] {padding-left:20px;padding-right:20px;border-bottom:none;border-top:1px solid #d5dce8;}
        }
        //컨텐츠영역
        //계산내역 + 진행순서
        .order-sum3 {padding:0;border-radius:0;border:none;background:transparent;}
    }

    /* 타입3 */
    //단일 아코디언
    .accordion-type3 {overflow:hidden;border-radius:8px;border:1px solid #e6e6e6;
        //[OBTAccordion]
        &[data-orbit-component="OBTAccordion"] {
            > div:nth-child(1) {
                //타이틀영역
                > div:nth-child(1) > button {height:52px !important;margin-top:0 !important;padding-left:20px !important;border:none !important;background:#f7f8fa !important;font-size:14px !important;color:#000 !important;letter-spacing:-0.35px !important;font-weight:bold !important;
                    //열기/접기
                    > span:nth-child(2) {right:23px !important;}
                }
                //컨텐츠영역
                > div:nth-child(2) {padding:25px 20px !important;border-bottom:none !important;}
            }
        }
        //[OBTAccordion2]
        &[data-orbit-component="OBTAccordion2"] {
            //타이틀영역
            > [class*="OBTAccordion2_wrapper"] > [class*="OBTAccordion2_accordion"] {height:52px;margin-top:0;padding-left:20px;border:none;background:#f7f8fa;font-size:14px;
                //타이틀
                > [class*="OBTAccordion2_title"] {color:#000;letter-spacing:-0.35px;font-weight:bold;}
                
                //열기/접기
                > [class*="OBTAccordion2_arrowIcon"] {right:21px;width:14px;height:14px;background-image:url('../imgs/ico/ic_refer_arrow_down_normal.png');
                    &[class*="OBTAccordion2_open"] {background-image:url('../imgs/ico/ic_refer_arrow_up_normal.png');}
                }
            }
            //컨텐츠영역
            &[class*="Accordion2_opened"] > [class*="OBTAccordion2_contents"] {padding:25px 20px;border-bottom:none;}
        }
    }

    /*----------------------------------------------------
        [그리드 : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* OBTDataGrid */
    //그리드 / 그리드 + 페이징
    .pagination-area {overflow:hidden;display:flex;flex-direction:column;
        > div:first-child {flex:1;
            &.area1 {flex:none;}
        }
    }

    /* OBTListGrid */
    [id^="gridList"]:not(:only-child) {height:calc(100% - 38px) !important;}

    /*----------------------------------------------------
        [OBTGroupSelector : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 가로 고정사이즈 : 사이드 버튼 O */
    //타입1(기본) : 컴포넌트 템플릿
    .gselector1 {
        > [class*="OBTGroupSelector_child"] {
            //이미지
            [class*="OBTGroupSelector_image"] {
                img {width:50px;height:50px;}
            }
            //텍스트
            [class*="OBTGroupSelector_textArea"] {width:calc(100% - 90px);
                //sub(타이틀)
                [class*="ti"] {letter-spacing:-0.7px;}
                .ti2 {font-size:14px;
                    > em {font-weight:bold;}
                }
                .ti3 {display:flex;align-items:flex-end;line-height:1.1;font-weight:bold;
                    > span {flex:1;padding-left:5px;text-align:right;
                        > strong {font-size:18px;}
                    }
                }
                //main(컨텐츠)
                [class*="cont"] {letter-spacing:-1px;
                    em:not([class*="state-type3"]) {padding-right:5px;}
                    > strong {font-size:18px;}
                    //상태아이콘(사용/미사용)
                    [class*="state-type3"] {min-width:43px;margin-top:5px;}
                    > em:empty {display:none;}//상태아이콘 X(전체)
                }
                .cont {margin-top:-1px;}//기본컨텐츠
                .cont2 {margin-top:2px;
                    > div {display:flex;align-items:flex-end;font-size:11px;line-height:1.3;
                        > span:last-child {flex:1;text-align:right;
                            > strong {font-size:14px;font-weight:normal;}
                        }
                    }
                }
            }
        }
    }

    //타입2
    .gselector2 {
        > [class*="OBTGroupSelector_child"] {
            //이미지
            [class*="OBTGroupSelector_image"] {width:16px;height:16px;margin-left:13px;
                img {width:16px;height:16px;}
            }
            //텍스트
            [class*="OBTGroupSelector_textArea"] {display:flex;width:calc(100% - 47px);margin-right:8px;padding-left:0;line-height:1;
                > div:nth-child(2) {flex:1;padding-left:5px;}
                //sub(타이틀)
                [class*="ti"] {letter-spacing:-0.7px;}
                //main(컨텐츠)
                [class*="cont"] {font-size:11px;letter-spacing:-0.7px;text-align:right;
                    > strong {font-size:15px;}
                }
            }
        }
    }

    /*----------------------------------------------------
        [radio : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 공통 */
    [data-orbit-component="OBTRadioButtonGroup"] > div {
        > span:only-child, > span:last-child {margin-right:0 !important;}
        label {vertical-align:top;}
    }

    /* nolabel */
    [data-orbit-component="OBTRadioButtonGroup"].nolabel {width:auto !important;
        > div > span {margin-right:0 !important;width:14px;height:14px;line-height:14px !important;
            > span {display:block;width:14px;height:14px;
                input ~ svg, input ~ span {margin-top:-7px !important;}
                label {position:absolute !important;visibility:hidden;padding-left:0 !important;opacity:0;z-index:-10 !important;}
            }
        }
    }

    /* 타입1(기본) */
    //label 텍스트 있음
    .radio1 {
        input ~ svg, input ~ span {width:18px !important;height:18px !important;margin-top:-9px !important;border-radius:0 !important;background-color:transparent !important;background-repeat:no-repeat !important;background-position:0 0 !important;box-sizing:border-box;
            path, circle {display:none;}
        }
        input ~ svg {background-image:url('../imgs/ico/radio_l.png');}
        input ~ span {background-image:url('../imgs/ico/radio_l_over.png') !important;}//focus
        //checked
        input:checked ~ svg {background-image:url('../imgs/ico/radio_l_checked.png');}
        input:checked ~ span {background-image:url('../imgs/ico/radio_focused_l.png') !important;}//focus
        //required
        span.required > span {
            input ~ svg {background-image:url('../imgs/ico/radio_r_l.png');}//unchecked
            input ~ span {background-image:url('../imgs/ico/radio_r_l_over.png') !important;}//unchecked + focus
            input:checked ~ svg {background-image:url('../imgs/ico/radio_r_l_checked.png');}//checked
            input:checked ~ span {background-image:url('../imgs/ico/radio_r_l_focused.png') !important;}//checked + focus
        }
        //disabled
        span.disabled > span {
            input ~ svg {background-image:url('../imgs/ico/radio_l_disable_01.png');}//unchecked
            input:checked ~ svg {background-image:url('../imgs/ico/radio_l_disable.png');}//checked
        }
        //label
        label {padding-left:26px !important;font-size:14px !important;letter-spacing:-0.35px !important;}
    }
    //label 텍스트 없음
    .radio1.t1 {
        > div > span {margin-right:0 !important;width:18px;height:18px;
            label {position:absolute !important;visibility:hidden;padding-left:0 !important;opacity:0;z-index:-10 !important;}
        }
    }

    /* 타입2 */
    //label 텍스트 있음
    .radio2 {
        input ~ svg, input ~ span {width:18px !important;height:18px !important;margin-top:-9px !important;border-radius:0 !important;background-color:transparent !important;background-repeat:no-repeat !important;background-position:0 0 !important;box-sizing:border-box;
            path, circle {display:none;}
        }
        input ~ svg {background-image:url('../imgs/ico/ic_check_03_m_disable.png');}
        input ~ span {background-image:url('../imgs/ico/ic_check_03_m_focus.png') !important;}//focus
        //체크 이미지 아래 투명 채우기
        > div > span:before {content:'';position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;border-radius:50%;background:#fff;}
        //checked
        input:checked ~ svg {background-image:url('../imgs/ico/ic_check_03_m_over.png');}
        input:checked ~ span {background-image:url('../imgs/ico/ic_check_03_m_select_focus.png') !important;}//focus
        //required
        /* span.required > span {} */
        //disabled
        span.disabled > span {
            input ~ svg {background-image:url('../imgs/ico/ic_check_03_m_d.png');}
        }
        //label
        label {padding-left:24px !important;}
    }
    //label 텍스트 없음
    .radio2.t1 {
        > div > span {margin-right:0 !important;width:18px;height:20px;
            label {position:absolute !important;visibility:hidden;padding-left:0 !important;opacity:0;z-index:-10 !important;}
        }
    }

    /* 타입3 */
    //label 텍스트 있음
    .radio3 {
        > div > span {line-height:38px !important;
            input ~ svg, input ~ span {width:38px !important;height:38px !important;margin-top:-19px !important;border-radius:0 !important;background-color:transparent !important;background-repeat:no-repeat !important;background-position:0 0 !important;box-sizing:border-box;
                path, circle {display:none;}
            }
            input ~ svg {background-image:url('../imgs/ico/ic_tem_normal.png');}
            input ~ span {background-image:url('../imgs/ico/ic_tem_focus.png') !important;}//focus
            //checked
            input:checked ~ svg {background-image:url('../imgs/ico/ic_tem_select.png');}
            input:checked ~ span {background-image:url('../imgs/ico/ic_tem_select_focus.png') !important;}//focus
            //required
            /* &.required > span {} */
            //disabled
            &.disabled > span {
                input ~ svg {background-image:url('../imgs/ico/ic_tem_disable.png');}
            }
            //label
            label {padding-left:44px !important;line-height:38px !important;}
        }
    }
    //label 텍스트 없음
    .radio3.t1 {
        > div > span {margin-right:0 !important;width:38px;height:38px;
            label {position:absolute !important;visibility:hidden;padding-left:0 !important;opacity:0;z-index:-10 !important;}
        }
    }

    /* 타입4 : 컨텐츠형 */
    .radio-area1 {width:100%;
        &[data-orbit-component="OBTRadioButtonGroup"] > div:first-child,
        &:not([data-orbit-component="OBTRadioButtonGroup"]) > div > div:first-child {display:flex;
            > span {overflow:visible !important;flex:1;min-width:0;margin-right:8px !important;line-height:normal !important;
                &:last-child {margin-right:0 !important;}
                input {opacity:0;}
                input ~ svg, input ~ span {left:36px !important;}
                label {display:flex !important;min-height:64px;padding:18px 30px 19px 61px !important;height:100%;border-radius:8px;border:1px solid #e6e6e6;line-height:normal !important;box-sizing:border-box;
                    div {display:inline-flex;align-items:center;
                        &:nth-child(1) > [class*="tag-type"] {margin-left:6px;}
                        &:nth-child(2) {flex:1;justify-content:flex-end;margin-left:20px;text-align:right;
                            > span {min-width:fit-content;font-size:12px;letter-spacing:-0.3px;color:#8c8c8c;}
                            > strong {margin-top:-2px;font-size:18px;line-height:1.2;letter-spacing:-0.45px;
                                &:not(:first-child) {margin-left:10px;}
                            }
                            > i {margin:-2px 0 0 1px;font-size:14px;letter-spacing:-0.35px;font-weight:normal;}//단위
                        }
                    }
                }
                //checked
                input:checked ~ label {border-color:#1c90fb;background:rgba(230, 241, 255, 0.45);box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.06);}
                //focus
                input + span + label {border-color:#000 !important;}
            }
        }
        //툴팁
        &:not([data-orbit-component="OBTRadioButtonGroup"]) {display:flex;}
        > [data-orbit-component="OBTRadioButtonGroup"] {overflow:hidden;flex:1;
            & + div {margin-left:8px;}
            > div > span {
                &:only-child {margin-right:0 !important;}
                label {
                    div {
                        &:nth-child(2) {overflow:hidden;
                            > strong {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
                        }
                    }
                }
            }
        }
    }

    /* 타입5 : 컨텐츠형 */
    .radio-area2 {
        > div > span {margin:0 !important;line-height:normal !important;
            > span {display:block;
                input ~ svg, input ~ span {display:none !important;}
                label {min-width:72px;height:27px;padding:0 10px !important;border:1px solid #ccc;background:#f0f0f0 !important;background-image:linear-gradient(0deg, #f0f0f0, #fff) !important;line-height:25px !important;color:#010101;text-align:center;box-sizing:border-box;}
                //checked
                input:checked ~ label {border-color:#1c90fb;background:#a3d7fe !important;background-image:linear-gradient(0deg, #d0ebff, #edf6ff) !important;z-index:10 !important;}
                //focus
                input + span + label {border-color:#000 !important;z-index:10 !important;}
            }
            &:first-child {border-radius:2px 0 0 2px;}
            &:last-child {border-radius:0 2px 2px 0;}
            &:not(:first-child) {margin-left:-1px !important;}
            //disabled
            &.disabled {
                label, input:checked ~ label {border-color:#ccc !important;background:#f5f5f5 !important;color:#a6a6a6;}
            }
        }
    }

    /* 타입6 : 컨텐츠형 */
    .radio-area3 {border:1px solid #e6e6e6;background:#f0f5fa;box-sizing:border-box;
        [data-orbit-component="OBTRadioButtonGroup"] {width:100%;
            > div {display:flex;flex-wrap:wrap;padding:21px;
                > span {margin:9px !important;border-radius:8px;line-height:normal !important;box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.08);
                    input ~ svg, input ~ span {visibility:hidden;left:50% !important;margin-left:-19px;}
                    label {overflow:hidden;position:relative;width:230px;padding:16px 16px 0 !important;background:#fff;line-height:1.5 !important;box-sizing:border-box;
                        > .area1 {height:144px;line-height:1.5;
                            strong {display:block;width:100%;}//타이틀 : 장문
                        }
                        > .area2 {height:30px;margin:10px -16px 0;padding:0 16px;background:#f5f9fc;font-size:11px;line-height:30px;color:#4a4a4a;text-align:center;box-sizing:border-box;
                            > em {font-weight:bold;}
                        }
                    }
                    //checked / hover / focus
                    input:checked + svg, &:hover svg, input + span {visibility:visible;pointer-events:none;
                        & + label:after {content:'';position:absolute;left:0;right:0;top:0;bottom:0;width:100%;height:100%;border-radius:8px;border:1px solid #1c90fb;box-sizing:border-box;pointer-events:none;}
                    }
                    //checked
                    input:checked ~ label:after {background:rgba(28, 144, 251, 0.05);}
                    //focus
                    input + span + label:after {border-color:#000;}
                }
            }
        }
    }

    /*----------------------------------------------------
        [checkbox]
    ----------------------------------------------------*/
    /* nolabel : 컴포넌트 수정 */
    [class*="OBTCheckBox_root"].nolabel {width:14px;height:14px;vertical-align:middle;
        > div:first-child {display:block;
            > label {display:block;width:14px;height:14px;
                svg {top:0 !important;margin-top:0 !important;}
                > span:last-child {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
            }
        }
    }

    /* 원모양 체크박스1(18 X 18) : 컴포넌트 수정 */
    [class*="OBTCheckBox"].checkbox {padding-right:0;vertical-align:middle;
        & + .checkbox {padding-left:20px;}
        svg {width:18px !important;height:18px !important;margin-top:-9px !important;background:transparent url('../imgs/ico/ic_check_02_m_disable.png') no-repeat !important;box-sizing:border-box;
            path, polygon {display:none;}
        }
        .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_02_m_focus.png') !important;}//focus
        //체크 이미지 아래 투명 채우기
        label:before {content:'';position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;border-radius:50%;background:#fff;}
        //checked
        svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_m_over.png') !important;}
        .checkedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_m_select_focus.png') !important;}//focus
        //required(필수)
        &.required {
            svg:not([class*="UFOCheckBox_focusCheckBox"]) {background-image:url('../imgs/ico/ic_check_02_r_m_disable.png') !important;}//unchecked + unfocus
            .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_02_r_m_focus.png') !important;}//unchecked + focus
        }
        //disabled
        &.disabled {
            svg {background-image:url('../imgs/ico/ic_check_02_m_d.png') !important;}
            //체크 이미지 아래 투명 채우기
            label:before {cursor:not-allowed;}
        }
        //label 텍스트
        [class*="UFOCheckBox_label_text"] {padding-left:22px;}
        //label 텍스트 없음
        &.nolabel {width:18px !important;height:18px !important;padding-left:0;
            label {width:18px !important;height:18px !important;}
        }
    }

    /* 원모양 체크박스2(18 X 18) : 컴포넌트 수정 */
    .checkbox4 {padding-right:0;vertical-align:middle;
        & + .checkbox4 {padding-left:20px;}
        svg {width:18px !important;height:18px !important;margin-top:-9px !important;background:transparent url('../imgs/ico/ic_check_03_m_disable.png') no-repeat !important;box-sizing:border-box;
            path, polygon {display:none;}
        }
        .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_m_focus.png') !important;}//focus
        //체크 이미지 아래 투명 채우기
        label:before {content:'';position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;border-radius:50%;background:#fff;}
        //checked
        svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_m_over.png') !important;}
        .checkedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_m_select_focus.png') !important;}//focus
        //required(필수)
        /* &.required {} */
        //disabled
        &.disabled {
            svg {background-image:url('../imgs/ico/ic_check_03_m_d.png') !important;}
            //체크 이미지 아래 투명 채우기
            label:before {cursor:not-allowed;}
        }
        //label 텍스트
        [class*="UFOCheckBox_label_text"] {padding-left:22px;}
        //label 텍스트 없음
        &.nolabel {width:18px !important;height:18px !important;padding-left:0;
            label {width:18px !important;height:18px !important;}
        }
    }

    /* 원모양 체크박스3(22 X 22) : 컴포넌트 수정 */
    .checkbox5 {padding-right:0;vertical-align:middle;
        & + .checkbox5 {padding-left:20px;}
        svg {width:22px !important;height:22px !important;margin-top:-11px !important;background:transparent url('../imgs/ico/ic_check_03_l_disable.png') no-repeat 50% 50% !important;box-sizing:border-box;
            path, polygon {display:none;}
        }
        .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_l_focus.png') !important;}//focus
        //체크 이미지 아래 투명 채우기
        label:before {content:'';position:absolute;left:0;top:50%;width:22px;height:22px;margin-top:-11px;border-radius:50%;background:#fff;}
        //checked
        svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_l_over.png') !important;}
        .checkedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_l_select_focus.png') !important;}//focus
        //required(필수)
        /* &.required {} */
        //disabled
        &.disabled {
            svg {background-image:url('../imgs/ico/ic_check_03_l_d.png') !important;}
            //체크 이미지 아래 투명 채우기
            label:before {cursor:not-allowed;}
        }
        //label 텍스트
        [class*="UFOCheckBox_label_text"] {padding-left:26px;}
        //label 텍스트 없음
        &.nolabel {width:22px !important;height:22px !important;padding-left:0;
            label {width:22px !important;height:22px !important;}
        }
    }

    /* 원모양 체크박스4(18 X 18) : 컴포넌트 미사용 */
    //타입1(기본) : label 텍스트 없음
    .checkbox {position:relative;display:inline-block;padding-right:10px;vertical-align:middle;
        input[type="checkbox"] {width:0;height:0;position:absolute;z-index:-10;}
        input[type="checkbox"] + label {overflow:hidden;position:relative;display:block;width:18px;height:0;margin:0;padding-top:18px;border:none;background:none;
            &:before {content:'';position:absolute;left:0;top:0;width:18px;height:18px;background:url('../imgs/ico/ic_check_02_m_disable.png') no-repeat;z-index:1;}
            //체크 이미지 아래 투명 채우기
            &:after {content:'';position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;border-radius:50%;background:#fff;}
        }
        input[type="checkbox"]:focus-visible + label:before {background-image:url('../imgs/ico/ic_check_02_m_focus.png');}//focus
        //checked
        input[type="checkbox"]:checked + label:before {background-image:url('../imgs/ico/ic_check_03_m_over.png') !important;}
        input[type="checkbox"]:checked:focus-visible + label:before {background-image:url('../imgs/ico/ic_check_03_m_select_focus.png') !important;}//focus
        //required
        &.required input[type="checkbox"] + label:before {background-image:url('../imgs/ico/ic_check_02_r_m_disable.png');}
        &.required input[type="checkbox"]:focus-visible + label:before {background-image:url('../imgs/ico/ic_check_02_r_m_focus.png');}//focus
        //disabled
        &.disabled input[type="checkbox"] + label {cursor:not-allowed;
            &:before {background-image:url('../imgs/ico/ic_check_02_m_d.png');}
        }
    }
    //타입2 : label 텍스트 있음
    .checkbox.t1 {padding-right:0;
        input[type="checkbox"] + label {width:auto;height:18px;padding:0 0 0 22px;color:#666;}
    }

    /* 네모모양 체크박스(27 X 27) : 컴포넌트 수정 */
    .checkbox3 {padding-right:0;vertical-align:middle;
        & + .checkbox3 {padding-left:20px;}
        svg {width:27px !important;height:27px !important;margin-top:-13px !important;background:transparent url('../imgs/ico/thum_check_normal.png') no-repeat !important;box-sizing:border-box;
            path, polygon {display:none;}
        }
        .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/thum_check_focus.png') !important;}//focus
        //checked
        svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/thum_check_select.png') !important;}
        .checkedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/thum_check_select_focus.png') !important;}//focus
        //required(필수)
        /* &.required {} */
        //disabled
        &.disabled svg {background-image:url('../imgs/ico/thum_check_disable.png') !important;}
        //label 텍스트
        [class*="UFOCheckBox_label_text"] {padding-left:31px;}
        //label 텍스트 없음
        &.nolabel {width:27px !important;height:27px !important;padding-left:0;
            label {width:27px !important;height:27px !important;}
            //disabled
            &.disabled label {cursor:not-allowed;}
        }
    }

    /* 즐겨찾기 체크박스 : 컴포넌트 수정 / labeltext 없음 */
    .checkbox2 {
        svg {position:static !important;width:18px !important;height:18px !important;margin:0 !important;background-color:transparent !important;background:url('../imgs/ico/ic_important_01_off_m_disable.png') no-repeat 0 50% !important;vertical-align:top;
            path, polygon {display:none !important;}
        }
        .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_important_01_off_m_disable.png') !important;}
        //checked
        svg[class*="UFOCheckBox_focusCheckBox"],
        .checkedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_important_01_on_m_normal.png') !important;}
        //focus
        .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"],
        .checkedFocus svg[class*="UFOCheckBox_focusCheckBox"] {outline:1px solid #000;}//focus
        //label 텍스트
        [class*="UFOCheckBox_label_text"] {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;padding:0;opacity:0;z-index:-1;}
    }

    /* ON/OFF스위치 : 컴포넌트 미사용 */
    .toggle {display:inline-block;vertical-align:middle;
        //OFF(기본)
        > input {position:absolute;opacity:0;z-index:-10;}
        > input:focus-visible + label {border-color:#000 !important;}//focus
        > label {display:block;position:relative;width:38px;height:16px;margin-top:0;border:1px solid #999;border-radius:8px;background:#fff !important;font-size:10px;color:#999;box-sizing:border-box;
            //아이콘
            &:before {content:'';display:block;position:absolute;left:2px;top:2px;width:10px;height:10px;border-radius:50%;background:#999;}
            //텍스트
            > span {overflow:hidden;display:block;height:16px;
                &:before, &:after{content:'';display:block;width:100%;height:14px;line-height:14px;text-align:center;box-sizing:border-box;}
                &:before {content:attr(data-label-off);padding:0 2px 0 12px;}
                &:after {content:attr(data-label-on);padding:0 12px 0 2px;}
            }
        }
        //ON
        > input:checked + label {border-color:#1c90fb;color:#1c90fb;
            //아이콘
            &:before {left:auto;right:2px;background:#1c90fb;}
            //텍스트
            > span {
                &:before {margin-top:-14px;}
            }
        }
        &:hover {cursor:pointer;}
    }

    /*----------------------------------------------------
        [드롭다운리스트(OBTDropDownList) : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 단일 타입 */
    /* 타입1 */
    .select1 {
        > div > div {
            > span {padding:5px 24px 0 0 !important;border-color:transparent !important;
                > span {color:#000 !important;text-align:right;}
            }
            > button {margin-top:-13px !important;}
        }
    }

    /* 타이틀 + 드롭다운리스트 */
    /* 타입1 */
    .select-area1 {display:inline-block;color:#000;
        > span {display:inline-block;margin:3px 2px 0 0;line-height:24px;}
        > [data-orbit-component="OBTDropDownList"] {vertical-align:top;}
    }

    /*----------------------------------------------------
        [input]
    ----------------------------------------------------*/
    /* 첨부파일 */
    /* 타입1 */
    .file-area1 {display:inline-block;width:100%;
        > div {vertical-align:middle;
            &:first-child {width:calc(100% - 29px) !important;}
            //button
            &:first-child + div {width:29px !important;
                button {width:27px !important;margin-left:2px;padding:0;
                    img {margin-right:0 !important;}
                    span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                }
            }
        }
    }
    /* 타입2 : UpDownloader(UC 제작) 수정 */
    .file-area2 {height:100%;
        > [data-orbit-component="OBTLoading"] {display:block;}
        [id*="micro-component"] {height:100%;
            > div, .UpDownLoader {height:100%;
                .conBox {overflow:hidden;height:calc(100% - 37px) !important;}
            }
        }
    }

    /* 인증서 선택 */
    .certificate-area {position:relative;font-size:0;line-height:0;
        [data-orbit-component="OBTTextField"] {width:100%;
            > div > div {padding-right:24px !important;}
        }
        button {position:absolute;right:1px;top:0;width:27px;padding:0;border:none;background:url('../imgs/erp/ic_input_code_normal.png') no-repeat 50% 50% !important;
            &:hover {border:none !important;}
            span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}//텍스트
        }

    }

    /* 인증번호 받기(시간) */
    .check-num {position:relative;
        .time {position:absolute;right:15px;top:50%;margin-top:-9px;color:#a6a6a6;}
    }

    /* OBTMultiLineTextField : 컴포넌트 수정 */
    .mtext {width:100%;height:100%;
        > div:first-child {height:100%;
            > div {height:100%;
                > textarea {height:100%;}
            }
        }
    }
    //컴포넌트 display:inline-block; 설정으로 생기는 여백 초기화
    .data-heading > div > [data-orbit-component="OBTMultiLineTextField"],
    [data-orbit-component="OBTAccordion"] > div > div > [data-orbit-component="OBTMultiLineTextField"] {display:block;}

    /*----------------------------------------------------
        [탭영역]
    ----------------------------------------------------*/
    /* 서브탭 : 컴포넌트(default) 수정(small X) */
    .sub-tab-default {
        > div > div:first-child > div {
            &:first-child {border-bottom:none !important;
                > div {height:33px !important;
                    button {bottom:0 !important;height:33px !important;border-bottom-color:transparent !important;font-size:14px !important;
                        > span:first-child {top:11px !important;bottom:8px !important;}
                    }
                }
            }
            & + div {max-height:calc(100% - 33px) !important;padding-top:0 !important;}
        }
    }

    /* 탭 오른쪽영역(가이드문구 / 버튼) */
    //가이드
    .tab-guide {position:absolute;right:0;top:7px;width:auto !important;height:auto !important;color:#1c90fb;letter-spacing:-0.6px;}
    //버튼
    .tab-btn {position:absolute;right:0;top:0;width:auto !important;height:27px !important;z-index:1;
        > div, > ul, > ul > li, > p {vertical-align:top;
            &:not(:first-child) {margin-left:10px;}
        }
        //버튼
        > div + div {margin-left:4px !important;}//구분라인 없음(기본)
        > .btn {display:inline-block;//구분바 있음
            &:before {content:'';display:inline-block;width:1px;height:16px;margin:0 10px 0 6px;background:#e6e6e6;vertical-align:middle;}
            > div {vertical-align:middle;
                & + div {margin-left:4px !important;}
            }
        }
        //문구(ul/p)
        > ul, > p {display:inline-block;margin-top:1px;line-height:26px;}
        > ul {
            > li {position:relative;display:inline-block;padding-left:5px;color:#4a4a4a;
                &:before {content:'';display:inline-block;position:absolute;top:50%;left:0;width:2px;height:2px;margin-top:-1px;background-color:#4a4a4a;}
            }
            //타입1
            &.t1 > li {padding-left:19px;
                &:before {width:18px;height:18px;margin-top:-9px;}
                &.type1:before {background:url('../imgs/ico/ic_authority_m_normal.png') no-repeat;}//청구인원
                &.type2:before {background:url('../imgs/ico/ic_payment_m_normal.png') no-repeat;}//결재금액
                &:not(:last-child):after {content:'';display:inline-block;margin:-3px 0 0 10px;width:1px;height:11px;background:#e6e6e6;vertical-align:middle;}
                > span {font-size:11px;
                    &:first-child {color:#a6a6a6;}
                    &:last-child {padding-left:15px;color:#1c90fb;}
                    > strong {font-size:13px;
                        &:not(:only-child) {padding-right:3px;}
                    }
                }
            }
        }
        > p {color:#1c90fb;letter-spacing:-0.6px;}
        //checkbox
        [data-orbit-component="OBTCheckBox"] {display:inline-block;line-height:27px;}
    }

    /*----------------------------------------------------
        [사진]
    ----------------------------------------------------*/
    /* 공통 */
    [class*="pic-type"] {overflow:hidden;display:inline-block;background-repeat:no-repeat;background-size:100% 100%;vertical-align:top;box-sizing:border-box;
        img {width:100%;height:100%;
            &[src] {background:#fff;}
        }
    }

    /* 타입1 : 원 */
    [class*="pic-type1"] {border-radius:50%;background-color:#dfe4e8;background-image:url('../imgs/empty/profile_noimg_large.png');
        img {height:auto;}
        //멀티사용자
        &.multi {background-image:url('../imgs/empty/ic_no_team.png');}
    }
    //28*28
    .pic-type1 {width:28px;height:28px;}
    //37*37
    .pic-type1-2 {width:37px;height:37px;}
    //24*24
    .pic-type1-3 {width:24px;height:24px;}

    /* 타입2 : 사각형 */
    //100*130
    .pic-type2 {position:relative;width:100px;height:130px;border:1px solid #e6e6e6;border-radius:4px;background-color:#eeeff1;background-image:url('../imgs/empty/profile_personaldata_noimg_01.png');
        .txt {position:absolute;top:50%;left:0;width:100%;text-align:center;transform:translateY(-50%);}//이미지 사이즈(이미지 등록 전)
    }
    //40*40
    .pic-type2-2 {width:40px;height:40px;border-radius:4px;background-color:#f6f7f9;background-image:url('../imgs/empty/img_present_emptyset.png');background-size:100%;
        //img 40*40 : 고정사이즈 설정
        img:not([src]) {display:none;}
        //img max-widht 40 * max-height 40 : 이미지 비율대로 설정
        &.t1 {position:relative;display:inline-flex;justify-content:center;align-items:center;
            &:not(:empty):before {content:'';position:absolute;left:0;right:0;top:0;bottom:0;background:#fff;}//img bg
            &:not(:empty):after {content:'';position:absolute;left:0;right:0;top:0;bottom:0;border-radius:4px;border:1px solid rgba(0, 0, 0, 0.1);box-sizing:border-box;}//img border
            img {position:relative;width:auto;height:auto;max-width:100%;max-height:100%;}
        }
    }

    /*----------------------------------------------------
        [사인]
    ----------------------------------------------------*/
    /* 공통 */
    [class*="sign-type"] {overflow:hidden;position:relative;display:inline-flex;align-items:center;justify-content:center;border:1px solid #e6e6e6;width:85px;height:90px;border-radius:4px;background:url('../imgs/empty/sign_noimg.png') no-repeat center 50%;box-sizing:border-box;
        //추가등록이미지
        img {width:49px;height:49px;background-color:#fff;}
        //사인템플릿
        .stamp_default, .stamp, .stamp3 {background-color:#fff;}
        .stamp, .stamp2, .stamp3, .stamp4 {margin:0;}
    }
    /* 탸입1 */
    //등록
    .sign {position:relative;display:inline-flex;align-items:center;
        > ul {flex:none;display:inline-block;margin:0 8px;background:none;white-space:nowrap;scroll-behavior:smooth;
            > li {display:inline-flex;flex-direction:column;justify-content:center;align-items:center;width:83px;height:90px;vertical-align:top;box-sizing:border-box;
                > [data-orbit-component="OBTRadioButtonGroup"] {width:14px;margin:0 auto;}
                .stampBox {overflow:hidden;word-break:break-all;
                    > .pic {position:relative;width:49px;height:49px;border-radius:3px;margin:0 auto;box-sizing:border-box;background:#eaeaea;
                        > .pic-img {position:absolute;top:0;background:#fff;text-align:center;z-index:2;
                            &:after {content:'';position:absolute;top:0;left:0;width:49px;height:49px;border:1px solid #e6e6e6;border-radius:3px;box-sizing:border-box;}
                        }
                    }
                }
                .stampBox .pic .pic_img {background: #fff; text-align: center;position:absolute; z-index: 2; top:0;}
                .stampBox .pic .pic_img > img {max-width:49px; max-height:49px;background: #fff;}
                .stampBox .txt {position:absolute;top:50%;margin-top:-9px;width:82px;display:inline-block;left:50%;margin-left:-41px;text-align:center;z-index:1;}
                .stampBox .pic:hover {
                    &:before {content:'';display:block;width:100%;height:100%;background:#000;position:absolute;top:0;left:0;background-color:rgba(0, 0, 0, 0.2);z-index:3;}
                    .btn-set {display:block;z-index:11;}
                }
                .stampBox .btn-set {display:none;width:100%;height:100%;position:absolute;left:0;top:0;
                    > .btns {position:absolute;top:10px;left:50%;z-index:4;width:62px;height:100%;margin-left:-31px;}
                    button {margin:0 1px;
                        img {width:18px;height:18px;background:transparent;}
                    }
                }
            }
        }
    }
    
    /*----------------------------------------------------
        [증가/하락 아이콘 : 아이콘 + 텍스트]
    ----------------------------------------------------*/
    .up, .down, .up-s, .down-s {
        &:before {content:'';display:inline-block;margin:-3px 4px 0 0;border-style:solid;border-color:transparent;vertical-align:middle;}
        span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
    }
    .up, .up-s {color:#fc5356 !important;
        &:before {border-top:none;border-bottom-color:#fc5356;}
    }
    .down, .down-s {color:#1c90fb !important;
        &:before {border-top-color:#1c90fb;border-bottom:none;}
    }

    /* 타입1 : 기본 */
    .up, .down {
        &:before {border-width:5px;}
    }
    .up:before {border-bottom-width:7px;}
    .down:before {border-top-width:7px;}

    /* 타입2 : small */
    .up-s, .down-s {
        &:before {border-width:4px;}
    }
    .up-s:before {border-bottom-width:5px;}
    .down-s:before {border-top-width:5px;}

    /*----------------------------------------------------
        [Tag아이콘]
    ----------------------------------------------------*/
    /* 타입1 */
    [class*="tag-type"] {display:inline-block;min-height:14px;padding:0 2px;border:1px solid #a6a6a6;border-radius:2px;background:#fff;font-size:11px;line-height:14px;letter-spacing:-0.55px;color:#4a4a4a;vertical-align:middle;font-weight:normal;}
    //공통 색상
    .tag-type100, .tag-type200, .tag-type300, .tag-type400 {border-color:#919191 !important;color:#919191;}//진행완료
    .tag-type101, .tag-type201, .tag-type301, .tag-type401 {border-color:#20c997;color:#20c997;}
    .tag-type102, .tag-type202, .tag-type302, .tag-type402 {border-color:#2dbcb5;color:#2dbcb5;}
    .tag-type103, .tag-type203, .tag-type303, .tag-type403 {border-color:#39b0d2;color:#39b0d2;}
    .tag-type104, .tag-type204, .tag-type304, .tag-type404 {border-color:#46a3f0;color:#46a3f0;}
    .tag-type105, .tag-type205, .tag-type305, .tag-type405 {border-color:#ff8787;color:#ff8787;}
    .tag-type106, .tag-type206, .tag-type306, .tag-type406 {border-color:#f8a457;color:#f8a457;}
    .tag-type107, .tag-type207, .tag-type307, .tag-type407 {border-color:#f0c325;color:#f0c325;}
    .tag-type108, .tag-type208, .tag-type308, .tag-type408 {border-color:#c8b465;color:#c8b465;}
    .tag-type109, .tag-type209, .tag-type309, .tag-type409 {border-color:#9da3aa;color:#9da3aa;}//미청구
    .tag-type110, .tag-type210, .tag-type310, .tag-type410 {border-color:#319df6;color:#319df6;}//오늘
    .tag-type111, .tag-type211, .tag-type311, .tag-type411 {border-color:rgba(70, 163, 240, 0.5);color:#1c90fb;}//D-day / 유리 / 납부
    .tag-type112, .tag-type212, .tag-type312, .tag-type412 {border-color:rgba(92, 113, 152, 0.5);color:#526890;}//마감
    //.tag-type113, .tag-type213, .tag-type313, .tag-type413 {}//미사용중
    .tag-type114, .tag-type214, .tag-type314, .tag-type414 {border-color:rgba(244, 108, 108, 0.5);color:#f46c6c;}//환급
	.tag-type115, .tag-type215, .tag-type315, .tag-type415 {border-color:#656565;color:#494949;}//납부 / 일반 / 공지

    /* 타입2 */
    [class*="tag-type2"] {min-height:16px;padding:0 4px;border-radius:9px;line-height:16px;}

    /* 타입3 */
    [class*="tag-type3"] {min-width:20px;min-height:16px;padding:0 6px;border-radius:9px;line-height:16px;font-weight:bold;
		//타입1
		&.t1 {background:transparent;font-weight:normal;}
	}

    /* 타입4 */
    [class*="tag-type4"] {min-height:22px;padding:0 6px;border-radius:12px;background:transparent;line-height:21px;font-size:12px;font-weight:bold;}

    /*----------------------------------------------------
        [구분표시 : 그리드영역 제외]
    ----------------------------------------------------*/
    [class*="state-type"] {display:inline-block;font-size:12px;letter-spacing:normal;font-weight:normal;}

    /* 타입1 */
    [class*="state-type1"] {height:16px;line-height:15px !important;
        &:before {content:'';display:inline-block;width:16px;height:16px;margin:-1px 4px 0 0;border-radius:50%;background-repeat:no-repeat;background-position:0 0;vertical-align:top;}
    }
    .state-type100 {color:#919191;//진행완료
        &.t1:before {background-image:url('../imgs/ico/ic_confirm_gray.png');}//진행완료
    }
    .state-type101 {color:#20c997;
        &.t1:before {background-image:url('../imgs/ico/ico_confirm_03.png');}//승인, 완료
    }
    .state-type102 {color:#2dbcb5;
        &.t1:before {background-image:url('../imgs/ico/ico_confirm_02.png');}//승인, 완료
    }
    .state-type103 {color:#39b0d2;
        &.t1:before {background-image:url('../imgs/ico/ico_confirm_01.png');}//승인, 완료
    }
    .state-type104 {color:#46a3f0;
        &.t1:before {background-image:url('../imgs/ico/icon_confirm_inpro.png');}//승인, 완료
        &.t2:before {background-image:url('../imgs/ico/icon_ing_inpro.png');}//진행
        &.t3:before {background-image:url('../imgs/ico/icon_return_inpro.png');}//반려
        &.t7:before {background-image:url('../imgs/ico/icon_pend_inpro.png');}//대기
    }
    .state-type105 {color:#ff8787;
        &.t3:before {background-image:url('../imgs/ico/ico_return.png');}//반려
        &.t4:before {background-image:url('../imgs/ico/ico_error_01.png');}//오류
        &.t5:before {background-image:url('../imgs/ico/icon_cancel_nega.png');}//취소, 실패, 삭제
    }
    .state-type106 {color:#f8a457;
        &.t5:before {background-image:url('../imgs/ico/ico_fail.png');}//취소, 실패, 삭제
        &.t6:before {background-image:url('../imgs/ico/ico_write.png');}//작성
        &.t9:before {background-image:url('../imgs/ico/ico_hold_02.png');}//보류
    }
    .state-type107 {color:#f0c325;
        &.t7:before {background-image:url('../imgs/ico/ico_pend.png');}//대기
    }
    .state-type108 {color:#c8b465;
        &.t8:before {background-image:url('../imgs/ico/ico_save.png');}//저장
    }
    .state-type109 {color:#9da3aa;//미청구
        &.t1:before {background-image:url('../imgs/ico/ico_confirm_neutral.png');}//승인, 완료
        &.t2:before {background-image:url('../imgs/ico/ico_inpro_neutral.png');}//진행
        &.t3:before {background-image:url('../imgs/ico/ico_return_neutral.png');}//반려
        &.t4:before {background-image:url('../imgs/ico/ico_error_neutral.png');}//오류
        &.t5:before {background-image:url('../imgs/ico/ico_fail_neutral.png');}//취소, 실패, 삭제
        &.t6:before {background-image:url('../imgs/ico/ico_write_neutral.png');}//작성
        &.t7:before {background-image:url('../imgs/ico/ico_pend_neutral.png');}//대기
        &.t8:before {background-image:url('../imgs/ico/ico_keep_neutral.png');}//저장
        &.t9:before {background-image:url('../imgs/ico/ico_hold_neutral.png');}//보류
        &.t10:before {background-image:url('../imgs/ico/ico_transfer_neutral.png');}//이관
    }

    /* 타입2 */
    [class*="state-type2"]:before {content:'';display:inline-block;width:10px;height:10px;margin:-1px 4px 0 0;vertical-align:middle;}
    .state-type200:before {background:#919191;}//진행완료
    .state-type201:before {background:#20c997;}
    .state-type202:before {background:#2dbcb5;}
    .state-type203:before {background:#39b0d2;}
    .state-type204:before {background:#46a3f0;}
    .state-type205:before {background:#ff8787;}
    .state-type206:before {background:#f8a457;}
    .state-type207:before {background:#f0c325;}
    .state-type208:before {background:#c8b465;}
    .state-type209:before {background:#9da3aa;}//미청구

    /* 타입3 : 태그형 */
    [class*="state-type3"] {min-width:64px;height:18px;padding:0 6px;border-radius:9px;font-size:11px;line-height:17px !important;color:#fff;text-align:center;box-sizing:border-box;
        &.t1 {min-width:34px;}//상태값 2글자 고정
        &.t2 {min-width:auto;height:14px;line-height:14px !important;}//사이즈변경
    }
    .state-type300 {background:#e2e2e2;color:#919191;}//진행완료
    .state-type301 {background:#20c997;}
    .state-type302 {background:#2dbcb5;}
    .state-type303 {background:#39b0d2;}
    .state-type304 {background:#46a3f0;}
    .state-type305 {background:#ff8787;}
    .state-type306 {background:#f8a457;}
    .state-type307 {background:#f0c325;}
    .state-type308 {background:#c8b465;}
    .state-type309 {background:#9da3aa;}//미청구
    .state-type310 {background:#53abfe;}//OK
    .state-type311 {background:#8b8bff;}//CHECK
    .state-type312 {background:#4eabfa;}//납부

    /* 타입4 : circle / only블릿(10 x 10) */
    [class*="state-type4"] {overflow:hidden;width:10px;height:0;padding-top:10px;border-radius:50%;}
    .state-type400 {background:#919191;}//진행완료
    .state-type401 {background:#20c997;}
    .state-type402 {background:#2dbcb5;}
    .state-type403 {background:#39b0d2;}
    .state-type404 {background:#46a3f0;}
    .state-type405 {background:#ff8787;}
    .state-type406 {background:#f8a457;}
    .state-type407 {background:#f0c325;}
    .state-type408 {background:#c8b465;}
    .state-type409 {background:#9da3aa;}//미청구
    .state-type410 {background:#e4e4e4;}//비활성화

    /* 타입5 : 지역표시 / square / only블릿(10 x 10) */
    [class*="state-type5"] {overflow:hidden;width:10px;height:0;padding-top:10px;}
    .state-type500 {background:#ff8787;}
    .state-type501 {background:#ffa94d;}
    .state-type502 {background:#ffca55;}
    .state-type503 {background:#ffe748;}
    .state-type504 {background:#b3e270;}
    .state-type505 {background:#60da9f;}
    .state-type506 {background:#49c8f2;}
    .state-type507 {background:#53abfe;}
    .state-type508 {background:#8b8bff;}
    .state-type509 {background:#bc8bff;}

    /*----------------------------------------------------
        [버튼영역 : 페이지에 버튼 삽입 시]
    ----------------------------------------------------*/
    /* 가로형 버튼영역(단독 사용 가능) : 버튼 사이 여백 4px */
    .btn-wrap {text-align:center;
        [data-orbit-component="OBTButton"]:not(:first-child) {margin-left:4px;}
    }

    /* 세로형 버튼영역(단독 사용 가능) : 버튼 사이 여백 4px */
    .btn-wrap2 {
        [data-orbit-component="OBTButton"] {width:100%;
            &:not(:first-child) {margin-top:4px;}
            button {width:100%;}
        }
    }

    /* 하단 버튼영역(단독 사용 가능) */
    //공통 : 버튼 사이 여백 4px
    [class*="btn-bottom"] {text-align:center;
        > div:not(:first-child) {margin-left:4px;}
    }
    //타입1 : 버튼 상단 여백 10px
    .btn-bottom {padding-top:10px;}
    //타입2 : 버튼 상단 여백 20px
    .btn-bottom2 {padding-top:20px;}

    /*----------------------------------------------------
        [버튼 : OBTButton : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 텍스트버튼 */
    /* 타입1 : OBTButtonGroup 적용 가능 */
    [class*="btn-type1"] {background:#fff !important;
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"], &.on {color:#fff;}//그룹버튼focus / 버튼on
        span {width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;
            > div {display:flex;justify-content:space-between;
                > i {flex:1;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;text-align:left;}
                > em {margin-left:4px;}
            }
        }
    }
    .btn-type100 {border-color:#929fbc;color:#929fbc;
        &:hover {border-color:#929fbc !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#929fbc !important;background:#929fbc !important;}
    }
    .btn-type101 {border-color:#20c997;color:#20c997;
        &:hover {border-color:#20c997 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#20c997 !important;background:#20c997 !important;}
    }
    .btn-type102 {border-color:#2dbcb5;color:#2dbcb5;
        &:hover {border-color:#2dbcb5 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#2dbcb5 !important;background:#2dbcb5 !important;}
    }
    .btn-type103 {border-color:#39b0d2;color:#39b0d2;
        &:hover {border-color:#39b0d2 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#39b0d2 !important;background:#39b0d2 !important;}
    }
    .btn-type104 {border-color:#46a3f0;color:#46a3f0;
        &:hover {border-color:#46a3f0 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#46a3f0 !important;background:#46a3f0 !important;}
    }
    .btn-type105 {border-color:#ff8787;color:#ff8787;
        &:hover {border-color:#ff8787 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#ff8787 !important;background:#ff8787 !important;}
    }
    .btn-type106 {border-color:#f8a457;color:#f8a457;
        &:hover {border-color:#f8a457 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#f8a457 !important;background:#f8a457 !important;}
    }
    .btn-type107 {border-color:#f0c325;color:#f0c325;
        &:hover {border-color:#f0c325 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#f0c325 !important;background:#f0c325 !important;}
    }
    .btn-type108 {border-color:#c8b465;color:#c8b465;
        &:hover {border-color:#c8b465 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#c8b465 !important;background:#c8b465 !important;}
    }
    .btn-type109 {border-color:#9da3aa;color:#9da3aa;//미청구
        &:hover {border-color:#9da3aa !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#9da3aa !important;background:#9da3aa !important;}
    }
    .btn-type110 {border-color:#919191;color:#919191;//진행완료
        &:hover {border-color:#919191 !important;}
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"],
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:hover,
        &[class*="OBTButton_root"][class*="OBTButtonGroup_focus"]:active,
        &.on {border-color:#e2e2e2 !important;background:#e2e2e2 !important;color:#919191;}
    }

    /* 타입2 */
    .btn-type2 {display:flex;align-items:center;height:30px;padding:0 10px 0 16px;border-radius:15px;border:none;background:#b8d0ce !important;letter-spacing:-0.35px;color:#fff;
        &:after {content:'';display:inline-flex;width:14px;height:14px;margin-left:1px;background:url('../imgs/ico/ic_arrow_up_white.png') no-repeat;}
        &:hover, &:active {border:none !important;background-image:none !important;}
        [class*="OBTButton_labelText"] {font-size:14px !important;}//텍스트
    }

    /* 타입3 */
    .btn-type3 {height:50px;padding:0 40px;border-radius:4px;border:none;background-image:linear-gradient(to right, #4dcaff, #4d7bff) !important;text-align:center;color:#fff;box-shadow:0 5px 10px 0 rgba(0, 0, 0, 0.16);
        &:hover, &:active {border:none !important;background-image:linear-gradient(to right, #22b2ef, #204fd3) !important;}
        &[class*="OBTButton_disabled"] {border:none !important;background:#b4b9bc !important;color:rgba(255, 255, 255, 0.5);}
        > div {width:100%;
            span {font-size:13px !important;letter-spacing:-0.33px;font-weight:bold;}
        }
    }

    /* 타입4 */
    .btn-type4 {height:50px;padding:0 36px;border-radius:4px;border:none;background-image:linear-gradient(to right, #00aaff 4%, #8168ff 95%) !important;text-align:center;color:#fff;box-shadow:0 5px 10px 0 rgba(61, 69, 86, 0.16);
        &:hover, &:active {border:none !important;background-image:linear-gradient(to right, #0084f4 4%, #654ae8 95%) !important;}
        &[class*="OBTButton_disabled"] {border:none !important;background:#b4b9bc !important;color:rgba(255, 255, 255, 0.5);box-shadow:0 5px 10px 0 rgba(61, 69, 86, 0.27);}
        > div {width:100%;
            span {font-size:14px !important;letter-spacing:-0.7px;font-weight:bold;}
        }
    }

    /* 타입5 */
    .btn-type5 {height:40px;padding:0 15px;background:#fafafa !important;
        > div > span {font-size:12px !important;}
    }

    /* 이미지버튼 */
    /* 공통 */
    [class*="img-btn"]:not(.img-btn2) {padding:0;
        //labelText 삽입
        img {margin-right:0 !important;margin-bottom:0 !important;}
        span {visibility:hidden;overflow:hidden;position:absolute !important;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
    }

    /* 타입1 */
    .img-btn {width:27px;}

    /* 타입2 */
    .img-btn1 {border:none !important;border-radius:0 !important;background:none !important;
        //small(21*21)
        &.t1 {width:21px;height:21px;}
		//small(16*21)
        &.t2 {width:16px;height:21px;}
    }

    /* 타입3 */
    .img-btn2 {padding:0 !important;border:none !important;background:none !important;
        &:hover, &:active {color:#1c90fb !important;
			span {color:inherit !important;}
		}
        > div {flex-direction:row-reverse;
            img {margin:0 0 0 4px !important;}
        }
		//폰트 타입1
		&.t1 {
			> div {
				img {margin:2px 0 0 0 !important;}
				span {font-size:13px !important;color:#4a4a4a;}
			}
			
		}
		//폰트 타입2
		&.t2 {
			> div {
				img {margin:2px 0 0 0 !important;}
				span {font-size:12px !important;color:#4a4a4a;}
			}
		}
		//폰트 타입3
		&.t3 {
			> div {
				img {margin:2px 0 0 0 !important;}
				span {font-size:12px !important;color:#a6a6a6;}
			}
		}
    }

    /*----------------------------------------------------
        [OBTButtonGroup : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 타입1 */
   .group-btn1 {
        > div > button {margin-left:0;border-radius:2px !important;
            &:after {display:none;}
        }
        > div:not(:last-child) > button {border-right-width:1px !important;border-right-style:solid !important;
            //버튼기본색상설정(색상버튼제외)
            &:not([class*="btn-type"]) {border-right-color:#ccc !important;}
            &:not([class*="btn-type"]):hover {border-right-color:#9f9f9f !important;}
            &:not([class*="btn-type"])[class*="OBTButton_root"][class*="OBTButtonGroup_focus"] {border-right-color:#1c90fb !important;}
        }
        > div + div {margin-left:4px;}
    }

    /*----------------------------------------------------
        [뷰타입 선택 버튼 : 컴포넌트 수정]
    ----------------------------------------------------*/
    .viewtype {
        button {border-radius:0;}
        > div:first-child button {border-radius:2px 0 0 2px;}
        > div:last-child button {border-radius:0 2px 2px 0;}
        > div:not(:only-child):not(:last-child) {margin-right:-1px !important;}
        .on, .on:hover {position:relative;border-color:#1c90fb !important;background-image:linear-gradient(#edf6ff, #d0ebff) !important;z-index:1;}
        .on:active {background-image:linear-gradient(#a4d6fe, #e6f5ff) !important;}
    }

    /*----------------------------------------------------
        [가이드문구]
    ----------------------------------------------------*/
    /* 타입1 */
    [class*='guide-area'] {padding-top:4px;letter-spacing:-0.6px;}
    //기본
    //.guide-area {}
    //타입1
    .guide-area1 {color:#1c90fb;}
    //타입2
    .guide-area2 {color:#fc5356;text-align:right;}

    /*----------------------------------------------------
        [카운트(감소/증가) : 컴포넌트 수정]
    ----------------------------------------------------*/
    .btn-count {width:100%;
        > div {vertical-align:middle;}
        > [data-orbit-component="OBTButton"] {
            button {width:27px;}
            &:first-child button {border-radius:2px 0 0 2px;}
            &:last-child button {border-radius:0 2px 2px 0;}
        }
        > div:nth-child(2) {width:calc(100% - 54px);
            > div > div {border-width:1px 0 !important;}
        }
    }

    /*----------------------------------------------------
        [리스트영역]
    ----------------------------------------------------*/
    /* 타입1(기본) */
    .list-area1 {
        li, dd {position:relative;padding-left:7px;
            &:before {content:'';position:absolute;left:0;top:9px;background:#4e4e4e;}
        }
        > li, > dd {
            &:before {width:2px;height:2px;border-radius:1px;}
            & + li, & + dd {margin-top:3px;}
            ul > li, dl > dd {
                &:before {width:3px;height:1px;}
            }
        }
        //블릿변경(-)
        &.t1 {
            > li, > dd {
                &:before {width:3px;height:1px;}
            }
        }
        //블릿삭제
        &.t2 {
            > li, > dd {padding-left:0;
                &:before {display:none;}
            }
        }
        //타이틀영역 고정 / 컨텐츠 들여쓰기
        &.t3 {
            > li, > dd {display:flex;
                > span:last-child {flex:1;padding-left:2px;}
            }
        }
    }
    ol.list-area1 {counter-reset:count;
        > li {display:flex;padding-left:0;
            &:before {counter-increment:count;content:counter(count) '.';position:static;width:auto;height:auto;padding-right:5px;border-radius:0;background:transparent;}
            > div {flex:1;}
        }
    }

    /* 타입2 */
    .list-area2 {display:flex;align-items:center;min-width:300px;padding:11px 25px;border:1px solid #e0e0e0;border-radius:3px;color:#4a4a4a;box-sizing:border-box;font-size:12px;//컨펌, 알럿 폰트 재설정
        > li {display:flex;flex-grow:1;align-items:center;justify-content:space-between;min-width:76px;line-height:16px;
            & + li {margin-left:25px;padding-left:24px;border-left:1px solid #e6e6e6;}
            > span {color:#8c8c8c;}
            > strong {flex:1;margin:-1px 0 1px;padding-left:10px;text-align:right;font-size:16px;color:#1c90fb;
                > i {display:inline-block;margin-bottom:-2px;padding-left:1px;font-size:11px;font-weight:normal;vertical-align:bottom;}
            }
        }
        //width:400px
        &.t1 {width:400px;}
    }

    /* 타입3 */
    .list-area3 {display:flex;width:100%;justify-content:space-around;padding:30px;border-radius:4px;border:1px solid #e6e6e6;box-sizing:border-box;
        li {flex:1;padding:0 10px;text-align:center;box-sizing:border-box;
            > span, > strong {display:block;}
            > span {font-size:13px;font-weight:bold;letter-spacing:-0.33px;color:#0c2b65;
                //아이콘
                &:after {content:'';overflow:hidden;display:block;width:70px;height:70px;margin:20px auto 0;border-radius:20px;background-color:#f2f4f7;background-repeat:no-repeat;background-position:50% 50%;}
            }
            //아이콘
            &.type1 > span:after {background-image:url('../imgs/ico/ic_history.png');}//업력
            &.type2 > span {//기업형태
                &.ico-type1:after {background-image:url('../imgs/ico/ic_company_01.png');}//중소기업
                &.ico-type2:after {background-image:url('../imgs/ico/ic_company_02.png');}//중견기업
                &.ico-type3:after {background-image:url('../imgs/ico/ic_company_03.png');}//대기업
            }
            &.type3 > span {//사원수
                &.ico-type1:after {background-image:url('../imgs/ico/ic_member_01.png');}//300명 미만
                &.ico-type2:after {background-image:url('../imgs/ico/ic_member_02.png');}//300 ~ 1000명
                &.ico-type3:after {background-image:url('../imgs/ico/ic_member_03.png');}//1000명 이상
            }
            &.type4 > span {//매출액
                &.ico-type1:after {background-image:url('../imgs/ico/ic_sales_01.png');}//400억 미만
                &.ico-type2:after {background-image:url('../imgs/ico/ic_sales_02.png');}//400억 ~ 1조
                &.ico-type3:after {background-image:url('../imgs/ico/ic_sales_03.png');}//1조 이상
            }
            > strong {margin-top:13px;font-size:14px;letter-spacing:-0.35px;color:#4a4a4a;font-weight:normal;
                > i {font-size:18px;letter-spacing:-0.45px;font-weight:bold;}
            }
        }
    }

    /* 타입4 */
    .list-area4 {padding:30px;border-radius:4px;border:1px solid #e6e6e6;color:#000;
        > li {display:flex;align-items:center;
            &:before {content:'';width:12px;height:12px;margin-right:5px;}
            &:nth-child(1):before {background:#4eabfa;}
            &:nth-child(2):before {background:#50cbde;}
            &:nth-child(3):before {background:#afd873;}
            &:nth-child(4):before {background:#f7ad68;}
            &:nth-child(5):before {background:#f5d471;}
            & + li {margin-top:18px;}
            > span:first-child {flex:1;
                & + span {padding-left:10px;color:#4a4a4a;}
            }
        }
    }

    /* 타입5 */
    .list-area5 {padding:30px;border-radius:4px;border:1px solid #e6e6e6;color:#4a4a4a;letter-spacing:-0.5px;
        > li {
            & + li {margin-top:27px;padding-top:27px;border-top:1px solid #ccdbe5;}
            > span {display:block;
                & + span {padding-top:6px;font-size:16px;color:#1c90fb;text-align:right;
                    > strong {font-size:20px;}
                }
            }
        }
    }

    /* 타입6 */
    .list-area7 {width:auto !important;box-sizing:border-box;
        li {position:relative;padding:8px 2px 9px 20px;border-bottom:1px solid #e6e6e6;
            &:before {content:'';display:inline-block;position:absolute;top:14px;left:2px;width:8px;height:8px;border-radius:50%;text-align:center;}
            &:nth-child(1):before {background:#6ed7e8;}
            &:nth-child(2):before {background:#44c5d9;}
            &:nth-child(3):before {background:#1fb3cb;}
            &:nth-child(4):before {background:#0a9eb5;}
            &:nth-child(5):before {background:#0c8598;}
            &:last-child {border-bottom:none;}
                        
            > strong {overflow:hidden;display:block;width:calc(100% - 45px);text-overflow:ellipsis;white-space:nowrap;font-weight:bold;}
            > div {display:flex;width:calc(100% - 45px);color:#8c8c8c;
                em {overflow:hidden;margin-right:8px;max-width:50%;text-overflow:ellipsis;white-space:nowrap;
                    & ~ em {flex:1;margin-right:0;max-width:calc(100% - 30px);
                        &:before {content:'';display:inline-block;margin-right:8px;width:1px;height:10px;background-color:#e6e6e6;}
                    }
                }
            }
            //텍스트 숨김
            em {
                span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
            }
            //퍼센트( '> em' 삭제 금지 )
            > em {position:absolute;top:50%;right:2px;transform:translateY(-50%);width:40px;text-align:right;}
        }
        //타입1
        &.t1 {
            li {
                &:nth-child(1):before {background:#79c3ff;}
                &:nth-child(2):before {background:#4eabfa;}
                &:nth-child(3):before {background:#238ae2;}
                &:nth-child(4):before {background:#1374c7;}
                &:nth-child(5):before {background:#17589f;}
            }
        }
        //타입2
        &.t2 {
            li {
                &:nth-child(1):before {background:#acaafa;}
                &:nth-child(2):before {background:#8e8aff;}
                &:nth-child(3):before {background:#756ef0;}
                &:nth-child(4):before {background:#5954de;}
                &:nth-child(5):before {background:#413cc7;}
            }
        }
    }

    /* 타입7 */
    .list-area8 {position:relative;display:flex;flex-wrap:wrap;
        &:before, &:after {content:'';position:absolute;top:0;bottom:0;width:1px;background:#e6e6e6;}
        &:before {left:calc(100% / 3);}
        &:after {right:calc(100% / 3);}
        li {display:inline-flex;min-width:calc(100% / 3);max-width:calc(100% / 3);padding:0 30px;box-sizing:border-box;
            &:nth-child(3) ~ li {margin-top:13px;}
            &, > [data-orbit-component="OBTTooltip"] {
                //항목
                > span:nth-child(1) {flex:1;line-height:1.4;}
                //금액
                > span:nth-child(2) {display:inline-flex;justify-content:flex-end;width:34%;padding-left:10px;line-height:1.4;text-align:right;box-sizing:border-box;
                    > strong {overflow:hidden;
                        //텍스트 전체 노출
                        &.t1 {min-width:fit-content;}
                    }
                    > i {font-weight:bold;}//단위
                }
            }
            //툴팁
            > [data-orbit-component="OBTTooltip"] {display:flex;width:100%;line-height:normal;
                //금액
                > span:nth-child(2) {
                    > strong {white-space:nowrap;text-overflow:ellipsis;}
                }
            }
            //disabled
            &.dis {
                &, > [data-orbit-component="OBTTooltip"] {
                    //금액
                    > span:nth-child(2) {color:#a6a6a6;}
                }
            }
        }
    }

	/* 타입8 */
	.list-area9 {
		> li {display:flex;align-items:center;height:21px;margin-top:6px;
			&:first-child {margin-top:0;}
            //타이틀
			> span:first-child, > span:nth-child(2) {flex:1;display:flex;align-items:center;min-width:0;font-size:13px;color:#222;
                > a {overflow:hidden;color:#222;margin-right:2px;
                    &:hover {color:#1c90fb;}
                }
                [data-orbit-component=OBTTooltip] {margin-bottom:-4px;}
            }
            > span:nth-child(2) {margin-left:6px;}
            //이름
            > [data-orbit-component=OBTTooltip] {width:51px;margin-left:6px;font-size:11px;color:#8e8e8e;}
            //날짜
            > span:last-child {position:relative;width:62px;margin-left:6px;padding-left:7px;font-size:11px;color:#8e8e8e;text-align:right;
                &:before {content: '';position:absolute;left:0;top:50%;bottom:0;transform:translateY(-50%);width:1px;height:8px;background:#000;opacity:0.1;}
            }
            //말줄임
            [data-orbit-component=OBTTooltip] {overflow:hidden;max-width:100%;height:19px;line-height:19px;white-space:nowrap;text-overflow:ellipsis;}
		}
	}

    /*----------------------------------------------------
        [OBTFloatingPanel]
    ----------------------------------------------------*/
    /* 타입1 */
    .floating1 {display:inline-block;vertical-align:top;
        > div:first-child {width:18px;height:25px;vertical-align:top;
            button {width:100%;background:url('../imgs/ico/ic_more_detail_m_normal.png') no-repeat 50% 50% !important;
                &:hover, &:active {background-image:url('../imgs/ico/ic_more_detail_m_over.png') !important;}
            }
        }
        > .floating-panel {width:120px;border:1px solid #a6a6a6;background:#fff;box-shadow:0px 1px 1px 0 rgba(6, 6, 6, 0.1);
            li {
                > a {display:block;min-height:27px;padding:4px 10px 3px;color:#000;text-decoration:none;box-sizing:border-box;
                    &:hover {background:#eff7ff;}
                }
                > [data-orbit-component="OBTButton"] {width:100%;vertical-align:top;
                    button {width:100%;height:auto;min-height:27px;padding:3px 10px 4px;border:none !important;background:none !important;text-align:left;box-sizing:border-box;
                        &:hover {background:#eff7ff !important;}
                        > div {justify-content:flex-start;}
                    }
                }
            }
        }
    }

    /* 타입2 */
    .floating2 {display:inline-block;
        > .floating-panel {margin-top:5px;padding:10px 0;border:1px solid #a6a6a6;border-radius:3px;background:#fff;
            &:before, &:after {position:absolute;left:50%;content:'';display:inline-block;width:0;height:0;border-style:solid;border-width:5px 5px 7px 5px;margin-left:-5px;}
            &:before {top:-12px;border-color:transparent transparent #777 transparent;}
            &:after {top:-11px;border-color:transparent transparent #fff transparent;}
            > div > div:first-child {position:relative !important;overflow-x:hidden !important;max-height:220px;margin-bottom:-4px !important;
                & + div {display:none;}
            }
            .radio2 {padding:0 12px;
                > div {text-align:left;
                    > span {margin-bottom:4px !important;
                        &:last-child {margin-bottom:0 !important;}
                        input ~ svg, input ~ span {left:13px !important;}
                        //체크 이미지 아래 투명 채우기
                        &:before {left:13px;z-index:2;}
                        //label
                        label {width:100%;min-width:170px;padding:9px 12px 9px 36px !important;border:1px solid #e0e0e0;border-radius:3px;box-sizing:border-box;}
                        input:checked ~ label, &:hover label/* , &.on label */ {border:1px solid #1c90fb;background:#eff7ff;box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);}//checked / hover
                        input + span + label {border-color:#000 !important;}//focus
                    }
                }
            }
        }
    }

    /*----------------------------------------------------
        [참고박스 : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 공통 */
    [class*="reference-area"] {
        /* 높이100% */
        &.p100 {height:100%;
            [class*="OBTReferencePanel_noreferbox"] {align-items:flex-start;height:100%;box-sizing:border-box;}
            [class*="OBTReferencePanel_notitle"] {height:100%;}
        }

        /* 기본 */
        > div > div {width:100%;box-sizing:border-box;
            //타이틀
            > strong:first-child {display:block;
                //서브텍스트
                .txt-sub {position:relative;padding-left:17px;
                    &:before {content:'';position:absolute;left:8px;top:4px;width:1px;height:12px;background:#e6e6e6;}
                }
            }
            //컨텐츠
            ul, ol, dl, p, li, dt, dd {line-height:18px !important;color:#4a4a4a;letter-spacing:-0.65px;
                > img {margin-top:2px;}
            }
            > ul, > ol, > dl, > p,
            > .scroll > div > ul, > .scroll > div > ol, > .scroll > div > dl, > .scroll > div > p {
                > li, > dd {position:relative;padding:2px 0 0 10px;
                    &:before {content:'·';position:absolute;left:0;top:2px;}
                    > dl, > p {padding-top:2px;}
                    ul, ol, dl {
                        //- 아이콘
                        &.t1 {
                            > li, > dd {position:relative;padding-top:2px;padding-left:7px;
                                &:before {content:'';position:absolute;left:0;top:11px;width:3px;height:1px;background:#4e4e4e;}
                            }
                            //> 아이콘
                            .t2 {
                                > li, > dd {position:relative;padding-top:2px;padding-left:8px;
                                    &:before {content:'';position:absolute;left:0;top:10px;width:2px;height:2px;border:solid #4e4e4e;border-width:1px 1px 0 0;transform:rotate(45deg);}
                                }
                            }
                        }
                    }
                }
            }
            > ol > li, > .scroll > div > ol > li {padding-left:0;
                &:before {display:none;}
            }
            //내부스크롤
            .scroll {height:calc(100% - 20px);}
            //들여쓰기
            .area1 {
                > li, > dd, &:not(ul):not(ol):not(dl) {display:flex;
                    span:last-child {flex:1;padding-left:2px;}
                }
            }
        }
    }
   
    /* 타입1 */
    .reference-area1 {
        /* 공통 */
        > div > div {
            > ul, > ol, > dl, > p,
            > .scroll > div > ul, > .scroll > div > ol, > .scroll > div > dl, > .scroll > div > p {padding:2px 15px 0 20px;
                //가로정렬
                &.align-h {
                    > li {display:inline-block;
                        &:not(:first-child) {margin-left:20px;}
                    }
                }
            }
            > dl, > .scroll > div > dl {padding-top:4px;}
            > p, > .scroll > div > p {padding-top:4px;padding-left:20px;}
            //checkbox/radio
            > div:not(.scroll), > .scroll > div:first-child > div {margin:2px 15px 0 22px;
                &:not(:nth-child(1)):not(:nth-child(2)) {margin-top:6px;}
            }
        }
        /* 타입1-1 */
        > div > div {
            > strong:first-child {padding-left:20px;background:url('../imgs/ico/ic_info_03_m_normal.png') no-repeat -1px 1px;}
            > ul, > ol, > dl, > p,
            > .scroll > div > ul, > .scroll > div > ol, > .scroll > div > dl, > .scroll > div > p {
                &:not(:nth-child(1)):not(:nth-child(2)) {padding-top:4px !important;
                    > li:first-child {padding-top:0;
                        &:before {top:0;}
                    }
                }
            }
        }
        /* 타입1-2 */
        &.t1 > div {
            //티이틀
            [class*=OBTReferencePanel_title] {
                //서브텍스트
                .txt-sub {position:relative;padding-left:17px;
                    &:before {content:'';position:absolute;left:8px;top:4px;width:1px;height:12px;background:#e6e6e6;}
                }
            }
            //컨텐츠
            > div {
                > ul, > ol, > dl, > p,
                > .scroll > div > ul, > .scroll > div > ol, > .scroll > div > dl, > .scroll > div > p {padding-left:0;padding-right:0;
                    > li:first-child {padding-top:0;
                        &:before {top:0;}
                    }
                    &:first-child {padding-top:0 !important;}
                    &:not(:nth-child(1)) {padding-top:4px !important;}
                    //가로정렬
                    &.align-h {
                        > li {padding-top:0;}
                    }
                }
            }
        }
    }

    /* 타입2 */
    .reference-area2 > div > div {position:relative;padding:0 !important;
        > strong:first-child {padding:10px 15px 10px 35px;border-bottom:1px solid #d4dfe7;background:url('../imgs/ico/ic_info_03_m_normal.png') no-repeat 14px 10px;font-weight:normal;}
        > .btn {position:absolute;right:15px;top:9px;
            > div:not(:first-child) {margin-left:4px;}
        }
        > ul, > ol, > dl, > p,
        > .scroll > div > ul, > .scroll > div > ol, > .scroll > div > dl, > .scroll > div > p {padding:4px 15px 0;
            > li:first-child {padding-top:0;}
            //가로정렬
            &.align-h {
                > li {display:inline-block;padding-top:0;
                    &:not(:first-child) {margin-left:20px;}
                }
            }
        }
        > ul, > ol, > dl, > p {
            &:nth-child(3) {padding-top:11px !important;}
            &:last-child {padding-bottom:12px;}
        }
        > .scroll {margin-top:11px;margin-bottom:12px;
            > div {
                > ul, > ol, > dl, > p {
                    &:nth-child(1) {padding-top:0 !important;}
                }
            }
        }
    }

    /* 타입3 */
    .reference-area3 {
        //제목만 있는 참고박스
        [class*="OBTReferencePanel_nochildreferbox"] {height:38px;padding-right:15px;border-radius:3px;}
        //제목이 없는 참고박스
        [class*="OBTReferencePanel_notitle"] {
            > ul, > ol, > dl, > p,
            > .scroll > div > ul, > .scroll > div > ol, > .scroll > div > dl, > .scroll > div > p {
                &:not(:nth-child(1)) {padding-top:4px !important;}
                &:nth-child(1) {padding-top:0;
                    > li:first-child {padding-top:0;}
                }
                //가로정렬
                &.t1 {display:flex;
                    li {flex:1;padding-top:0;}
                }
            }
            > dl, > .scroll > div > dl {padding-top:2px;}
            //checkbox/radio
            > div:not(:nth-child(1)) {margin-top:6px;}
        }
    }

    /*----------------------------------------------------
        [OBTChip : 컴포넌트 수정]
    ----------------------------------------------------*/
    .btn-chips {
        /* 전체박스영역 필수입력 */
        &.required {background:#fef4f4;}

        /* 타입1 */
        &.t1 {
            [data-orbit-component="OBTChip"] {
                > div {position:relative;border:1px solid #d9d9d9;
                    span.active {//선택 시
                        &:before {content:'';position:absolute;top:-1px;left:-1px;display:block;;width:100%;height:100%;border:1px solid #1c90fb;border-radius:2px;}
                    }
                }
            }
        }
    }
    
    /*----------------------------------------------------
        [OBTScrollbar : 내부 단독 OBTFormPanel, table 사용 시 상단 라인 고정]
    ----------------------------------------------------*/
    .scroll-line:after {content:'';position:absolute;top:0;left:0;width:100%;height:2px;border-top:2px solid #666;box-sizing:border-box;z-index:10;}

    /*----------------------------------------------------
        [정렬/선택버튼]
    ----------------------------------------------------*/
    /* 타입1 */
    .card-btn-info {border-right:1px solid #e6e6e6;box-sizing:border-box;
        > div {
            //카드리스트
            > ul {padding:20px;box-sizing:border-box;
                > li {position:relative;padding:0 9px;border:1px solid #e0e0e0;border-radius:3px;background:#fff;
                    [class*="area"] {display:flex;flex-direction:column;justify-content:center;padding:0 7px;}
                    .area1 {position:relative;min-height:23px;margin:0 -9px;padding:11px 16px;border-radius:2px 2px 0 0;
                        > strong {width:calc(100% - 50px);font-size:13px;line-height:1.2;letter-spacing:-0.33px;
                            > span {color:#ff5f61;
                                &:before {content:'';display:inline-block;width:1px;height:12px;margin:0 5px -1px 6px;background:#e6e6e6;}
                            }
                        }
                        > span {width:calc(100% - 50px);padding-top:4px;font-size:11px;line-height:1.2;color:#a6a6a6;letter-spacing:-0.28px;}
                        //더보기/새로고침 버튼
                        > div {position:absolute;top:50%;margin-top:-13px;z-index:2;}
                        > .floating1 {right:6px;}//더보기
                        > [data-orbit-component="OBTButton"] {right:30px;margin-top:-11px;}//정보수집
                        //태그(상태)
                        > em {position:absolute;top:50%;right:16px;margin-top:-9px;}
                    }
                    .area2 {padding:12px 7px 12px 58px;
                        &[class*="banklogo"], &[class*="card"] {position:relative;padding-left:58px;font-weight:normal;//-----------------card(num) 운영서버 반영 후 삭제
                            &:before {position:absolute;left:0;top:50%;margin-top:-14px;}
                        }
                        &:not([class*="banklogo"]) {padding-left:7px;}
                        > em {line-height:1.33;}
                        > span {font-size:11px;color:#a6a6a6;}
                    }
                    .area3 {flex-direction:row;flex-wrap:wrap;justify-content:flex-start;align-items:center;min-height:24px;padding:8px 7px 9px;border-top:1px solid #e0e0e0;line-height:20px;
                        > span {width:30%;}
                        > strong {width:70%;text-align:right;}
                    }
                    //선택버튼
                    > div:not([class*="area"]):last-child {position:absolute;left:0;right:0;top:0;bottom:0;
                        > button {width:100%;height:100%;border:none !important;background:transparent !important;opacity:0;
                            [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                            &:focus {border-radius:3px;opacity:1;}
                        }
                    }
                    &.on {border-color:#1c90fb;box-shadow:0 3px 3px 0 rgba(0, 0, 0, 0.16);}
                    &:not(:first-child) {margin-top:6px;}
                    //타입별설정
                    &[class*="type"] {//기본
                        &:first-child + li {margin-top:27px;
                            &:before {content:'';position:absolute;left:-1px;right:-1px;top:-12px;height:1px;background:#e0e0e0;}
                        }
                        .area1 {background:#f5f5f5;}
                    }
                    &.type {//전체
                        .area1 {
                            > [data-orbit-component="OBTButton"] {right:16px;}//새로고침
                        }
                    }
                    &.type1 {//지정카드
                        .area1 {background:#f0f8fe;}
                    }
                    &.type2 {//공용카드
                        .area1 {background:#fff8f2;}
                    }
                    &.type3 {//개인카드
                        .area1 {background:#eff9fa;}
                    }
                    &.type4 {//개인형법인카드
                        .area1 {background:#f7f7ff;}
                    }
                }
            }
            //추가버튼
            .btn {width:100%;margin-top:-14px;padding:0 20px 20px;box-sizing:border-box;
                > div {width:100%;
                    button {width:100%;height:64px;border-color:#e0e0e0;border-radius:3px;background:#fff !important;color:#8d8d8d;
                        &:hover {border-color:#1c90fb !important;background:#eff7ff !important;color:#1c90fb;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);}
                        img {margin-right:5px !important;}
                    }
                }
            }
        }
        //타입1
        &.t1 {border:1px solid #e6e6e6;border-top:2px solid #666;background:#fafafa;}
    }

    /* 타입2 */
    .card-btn-area {display:flex;
        //타입1
        &.t1 {
            > ul {position:relative;display:flex;flex-wrap:wrap;width:100%;padding:1px 0 0 1px;box-sizing:border-box;
                > li {position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;width:calc(25% + 1px);height:72px;margin:-1px 0 0 -1px;border:1px solid #dbdbdb;text-align:center;box-sizing:border-box;
                    &:not(:first-child) {margin-left:-1px;}
                    &[class*="banklogo"]:hover, &[class*="card"]:hover {border-color:#888 !important;z-index:2;}//-----------------card(num) 운영서버 반영 후 삭제
                    &[class*="banklogo"].on, &[class*="card"].on {border-color:#1c90fb !important;background:#e9f5ff;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);color:#1c90fb;z-index:3;}//-----------------card(num) 운영서버 반영 후 삭제
                    //선택버튼
                    > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                        > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                            [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                            &:focus {position:relative;opacity:1;z-index:1;}
                        }
                    }
                    //OBTFloatingPanel : 컴포넌트 수정
                    > .floating-panel {overflow:auto;position:absolute;left:-1px !important;top:71px !important;width:100%;height:72px;border:solid #1c90fb;border-width:0 1px 1px;background:#fff;
                        > ul {padding:7px 10px;line-height:1.3;color:#4a4a4a;text-align:left;
                            > li {position:relative;padding:1px 0 1px 7px;
                                &:before {content:'';float:left;width:2px;height:2px;margin:6px 0 0 -7px;background:#4a4a4a;}
                                &:hover {text-decoration:underline;}
                                > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                                    > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                                        [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                                        &:focus {opacity:1;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* 타입3 */
    .btn-area {
        ul {display:flex;height:100%;
            li {position:relative;display:flex;flex-direction:column;justify-content:center;flex:1;min-height:70px;padding:9px 14px 9px 74px;border:1px solid #ccc;border-radius:10px;font-size:14px;line-height:1.4;color:#4a4a4a;letter-spacing:-0.7px;box-sizing:border-box;z-index:100;
                &:not(:first-child) {margin-left:8px;}
                &:hover, &.on {border-color:#1c90fb;color:#000;
                    //아이콘
                    &:before {background-color:#e0f1ff;}
                }
                &.on {background:#f5faff;}
                //전체버튼
                > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                    > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                        [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                        &:focus {border-radius:10px;opacity:1;}
                    }
                }
                //아이콘
                &:before {content:'';position:absolute;left:14px;top:50%;width:50px;height:50px;margin-top:-25px;border-radius:50%;background-color:#f6f7f9;}
                &.type1:before {background-image:url('../imgs/ico/tab_commute_none.png');}//근태구분
                &.type2:before {background-image:url('../imgs/ico/tab_sector_none.png');}//부문
                &.type3:before {background-image:url('../imgs/ico/tab_department_none.png');}//부서
                &.type4:before {background-image:url('../imgs/ico/tab_worker_none.png');}//사원
                &.type5:before {background-image:url('../imgs/ico/tab_building_none.png');}//사업장
            }
        }
    }

    /* 타입4 */
    .btn-area2 {
        > ul {display:flex;height:100%;
            > li {position:relative;display:flex;flex-direction:column;justify-content:flex-start;flex:1;min-height:102px;padding:15px 16px;border:1px solid #e6e6e6;border-radius:4px;background-repeat:no-repeat;background-position:right bottom;font-size:13px;line-height:1.4;color:#000;letter-spacing:-0.75px;box-sizing:border-box;z-index:100;
                &:not(:first-child) {margin-left:8px;}
                &.on {border-color:#1c90fb;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);}
                //아이콘
                &:nth-child(1) {background-image:url('../imgs/ico/img_certificate_career.png');}
                &:nth-child(2) {background-image:url('../imgs/ico/img_certificate_income.png');}
                &:nth-child(3) {background-image:url('../imgs/ico/img_certificate_etc.png');}
                //컨텐츠
                > ul {margin-top:5px;
                    > li {font-size:11px;color:#a6a6a6;letter-spacing:-0.55px;}
                }
                //전체버튼
                > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                    > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                        [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                        &:focus {border-radius:4px;opacity:1;}
                    }
                }
            }
        }
    }

    /* 타입5 */
    .btn-area3 {position:relative;display:flex;justify-content:center;align-items:center;width:100%;padding:16px 40px;border:1px solid #e6e6e6;box-sizing:border-box;
        button {overflow:hidden;border:none !important;background:none;padding:0;vertical-align:top;}
        > [data-orbit-component="OBTButton"] {position:absolute;top:50%;right:18px;width:17px;height:17px;margin-top:-8px;
            &:first-child {left:18px;}
            > button {width:18px;height:18px;padding-top:18px;}
            > .prev {
                > button {background:url('../imgs/ico/ic_pre_m_normal.png') !important;}
                &:hover {background:url('../imgs/ico/ic_pre_m_over.png') !important;}
                &:disabled {background:url('../imgs/ico/ic_pre_m_disable.png') !important;}
            }
            > .next {background:url('../imgs/ico/ic_next_m_normal.png') !important;
                &:hover {background:url('../imgs/ico/ic_next_m_over.png') !important;}
                &:disabled {background:url('../imgs/ico/ic_next_m_disable.png') !important;}
            }
        }
        .slider {overflow:hidden;display:flex;flex-direction:row;justify-content:flex-start;flex-wrap:wrap;min-width:560px;width:100%;height:72px;margin-bottom:14px;
            > li {position:relative;display:flex;flex-direction:column;margin:0 14px;
                > .pic-type1 {width:52px;height:52px;}
                > em {overflow:hidden;display:block;margin-top:5px;width:52px;height:15px;line-height:15px;font-size:11px;color:#a6a6a6;text-align:center;white-space:nowrap;text-overflow:ellipsis;}
                > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                    > button {width:100%;height:100%;border:none !important;background:transparent !important;opacity:0;
                        [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                        &:focus {opacity:1;}
                    }
                }
            }
        }
        > .indicator {position:absolute;left:0;bottom:16px;display:flex;justify-content:center;width:100%;
            button {display:inline-block;margin:0 5px;width:6px;height:0;padding-top:6px;border-radius:50%;background:#ebebeb !important;vertical-align:top;
                &.active {background:#1c90fb !important;}
            }
        }
    }

    /* 타입6 */
    .btn-area4 {display:flex;flex-wrap:wrap;padding:11px 9px 10px;border:1px solid #d5dce8;border-radius:4px;background:#f2f5fa;box-sizing:border-box;
        /* 타입1(기본) : 1줄에 6개 노출 */
        > li {flex:1;min-width:calc(100% / 6 - 9px);max-width:calc(100% / 6 - 8px);margin:2px 4px 3px;box-sizing:border-box;
            > [data-orbit-component="OBTButton"] {width:100%;
                > button {width:100%;height:34px;padding:0 20px 0 50px;border-radius:3px;border-color:#e6e6e6;background-color:#fefeff;background-repeat:no-repeat;background-position:24px 50%;
                    > div {justify-content:flex-start;
                        > span {font-size:11px !important;color:#4a4a4a;}
                    }
                    &:hover, &.on {border-color:#1c90fb !important;background-color:#eff7ff;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);
                        > div > span {color:#1c90fb;font-weight:bold;}
                    }
                    //인적사항
                    &.type1 {background-image:url('../imgs/ico/ic_personaldata_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_personaldata_select.png') !important;}
                    }
                    //가족사항
                    &.type2 {background-image:url('../imgs/ico/ic_family_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_family_select.png') !important;}
                    }
                    //학력사항
                    &.type3 {background-image:url('../imgs/ico/ic_edu_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_edu_select.png') !important;}
                    }
                    //경력사항
                    &.type4 {background-image:url('../imgs/ico/ic_career_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_career_select.png') !important;}
                    }
                    //자격면허
                    &.type5 {background-image:url('../imgs/ico/ic_license_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_license_select.png') !important;}
                    }
                    //동호회
                    &.type6 {background-image:url('../imgs/ico/ic_club_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_club_select.png') !important;}
                    }
                    //고과내역
                    &.type7 {background-image:url('../imgs/ico/ic_project_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_project_select.png') !important;}
                    }
                    //포상/징계
                    &.type8 {background-image:url('../imgs/ico/ic_prize_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_prize_select.png') !important;}
                    }
                    //발령내역
                    &.type9 {background-image:url('../imgs/ico/ic_hr_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_hr_select.png') !important;}
                    }
                    //교육내역
                    &.type10 {background-image:url('../imgs/ico/ic_school_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_school_select.png') !important;}
                    }
                    //출장내역
                    &.type11 {background-image:url('../imgs/ico/ic_business_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_business_select.png') !important;}
                    }
                    //여권/비자
                    &.type12 {background-image:url('../imgs/ico/ic_passport_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_passport_select.png') !important;}
                    }
                    //추천
                    &.type13 {background-image:url('../imgs/ico/ic_recomm_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_recomm_select.png') !important;}
                    }
                    //저축
                    &.type14 {background-image:url('../imgs/ico/ic_saving_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_saving_select.png') !important;}
                    }
                    //대출
                    &.type15 {background-image:url('../imgs/ico/ic_loan_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_loan_select.png') !important;}
                    }
                    //보증보험
                    &.type16 {background-image:url('../imgs/ico/ic_insurance_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_insurance_select.png') !important;}
                    }
                    //인보증
                    &.type17 {background-image:url('../imgs/ico/ic_warranty_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_warranty_select.png') !important;}
                    }
                    //퇴직정산
                    &.type18 {background-image:url('../imgs/ico/ic_retire_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_retire_select.png') !important;}
                    }
                    //외국어
                    &.type19 {background-image:url('../imgs/ico/ic_lang_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_lang_select.png') !important;}
                    }
                    //책정임금
                    &.type20 {background-image:url('../imgs/ico/ic_wage_disable.png') !important;
                        &:hover, &.on {background-image:url('../imgs/ico/ic_wage_select.png') !important;}
                    }
                }
            }
        }

        /* 타입2 : 1줄에 4개 노출(해상도 1600px 이하 4개 / 1600px 이상 6개) */
        &.t1 {
            //해상도 1600px 이하 4개 노출
            > li {min-width:calc(100% / 4 - 9px);max-width:calc(100% / 4 - 8px);}
            //해상도 1600px 이상 6개 노출
            @media ( min-width: 1600px ) {
                > li {min-width:calc(100% / 6 - 9px);max-width:calc(100% / 6 - 8px);}
            }
        }
    }

    /* 타입7 */
    .btn-area5 {display:flex;height:320PX;
        > li {position:relative;display:flex;flex-direction:column;align-items:center;flex:1;padding:9px 30px 30px;border:1px solid #e6e6e6;border-radius:10px;background:#fff;font-size:16px;line-height:1.5;letter-spacing:-0.8px;color:#000;text-align:center;box-sizing:border-box;
            &:not(:first-child) {margin-left:14px;}
            &.on, &:hover {border-color:#1c90fb;background:#f5faff;box-shadow:0 5px 10px 0 rgba(0, 0, 0, 0.12);}
            //아이콘
            &:before {content:'';width:160px;height:160px;margin-bottom:15px;background-repeat:no-repeat;background-position:50% 0;}
            &:nth-child(1):before {background-image:url('../imgs/ico/img_card_use.png');}
            &:nth-child(2):before {background-image:url('../imgs/ico/img_docu_data.png');}
            &:nth-child(3):before {background-image:url('../imgs/ico/img_bankbook.png');}
            &:nth-child(4):before {background-image:url('../imgs/ico/img_resolution_letter.png');}
            &:nth-child(5):before {background-image:url('../imgs/ico/img_letter_reference.png');}
            //설명
            > p {margin-top:7px;font-size:13px;line-height:1.4;letter-spacing:-0.65px;color:#a6a6a6;}
            //전체버튼
            > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                    [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                    &:focus {border-radius:10px;opacity:1;}
                }
            }
        }
    }

    /* 타입8 */
    .btn-area6 {display:flex;
        > li {position:relative;flex:1;min-height:114px;padding:25px 114px 20px 26px;border-radius:10px;border:1px solid #fff;background:#fff;box-shadow:0 3px 8px 0 rgba(0, 0, 0, 0.08);box-sizing:border-box;
            &:not(:first-child) {margin-left:18px;}
            &:hover {border-color:#1c90fb;}
            //아이콘
            &:after {content:'';position:absolute;right:0;bottom:0;width:114px;height:114px;background-repeat:no-repeat;}
            &:nth-child(1):after {background-image:url('../imgs/ico/img_edu_register.png');}
            &:nth-child(2):after {background-image:url('../imgs/ico/img_certificate_issue.png');}
            &:nth-child(3):after {background-image:url('../imgs/ico/img_complete_manage.png');}
            &:nth-child(4):after {background-image:url('../imgs/ico/img_online_manual.png');}
            //타이틀
            > strong {font-size:16px;letter-spacing:-0.4px;color:#000;}
            //설명
            > p {margin-top:6px;line-height:1.33;letter-spacing:-0.6px;color:#8c8c8c;}
            //전체버튼
            > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;z-index:1;
                > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                    [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                    &:focus {border-radius:10px;opacity:1;}
                }
            }
            //포인트영역
            &.point {padding-top:26px;padding-bottom:21px;border:none;background-color:#4794f4;background-image:linear-gradient(to right, #3ec2dc, #4794f4);
                &:before {content:'';position:absolute;left:0;top:0;width:100%;height:100%;background-image:url('../imgs/bg/img_left_deco.png'), url('../imgs/bg/img_right_deco.png');background-repeat:no-repeat;background-position:left top, right bottom;}
                &:hover {background-image:linear-gradient(to right, #01abcb, #0a56be);}
                //타이틀
                > strong {color:#fff;}
                //설명
                > p {color:#97d7ef;
                    > em {color:#edff0f;}
                }
            }
        }
    }

    /* 타입9 */
    .btn-area7 {display:flex;
        > li {position:relative;flex:1;min-height:217px;padding:27px 22px 22px;border-radius:3px;border:1px solid #e0e0e0;background:#fff;text-align:center;box-sizing:border-box;
            &:not(:first-child) {margin-left:10px;}
            //hover / on
            &:hover, &.on {border-color:#1c90fb;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);}
            &.on {background:#eff7ff;
                &:before {background:#e0f1ff;}
            }
            //아이콘
            &:before {content:'';display:block;display:block;width:90px;height:90px;margin:0 auto;border-radius:50%;background:#f6f7f9;background-repeat:no-repeat;}
            &:nth-child(1):before {background-image:url('../imgs/ico/ic_present_write.png');}
            &:nth-child(2):before {background-image:url('../imgs/ico/ic_present_direct.png');}
            //타이틀
            > strong {display:block;margin-top:11px;font-size:13px;color:#000;}
            //설명
            > p {margin-top:9px;font-size:11px;line-height:1.45;color:#4a4a4a;}
            //전체버튼
            > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;z-index:1;
                > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                    [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                    &:focus {border-radius:3px;opacity:1;}
                }
            }
        }
    }

    /* 타입10 */
    .btn-area8 {
        /* 세로형(기본) */
        > li {position:relative;display:flex;flex-direction:column;justify-content:center;width:100%;min-height:170px;padding:15px 35px 15px 173px;border-radius:10px;background:#fff;box-shadow:0 5px 10px 0 rgba(52, 75, 105, 0.1);box-sizing:border-box;
            &:not(:first-child) {margin-top:20px;}
            //아이콘
            &:before {content:'';position:absolute;left:20px;top:50%;transform: translateY(-50%);width:124px;height:124px;background-repeat:no-repeat;}
            &.type1:before {background-image:url('../imgs/ico/img_use_guide@2x.png'); background-size: 124px;}
            &.type2:before {background-image:url('../imgs/ico/img_play_guide@2x.png'); background-size: 124px;}
            &.type3:before {background-image:url('../imgs/ico/img_go_hometax@2x.png'); background-size: 124px;}
            &.type4:before {background-image:url('../imgs/ico/img_ai_tip@2x.png'); background-size: 124px;}
            //타이틀
            > strong {display:block;font-size:16px;letter-spacing:-0.4px;color:#000;}
            //설명
            > p {margin-top:6px;font-size:12px;line-height:1.33;letter-spacing:-0.3px;color:#999;word-break:keep-all;}
            //전체버튼
            > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                    [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                    &:focus {border-radius:10px;opacity:1;}
                }
            }
        }
        /* 가로형 */
        &.t1 {display:flex;
            > li {flex:1;
                &:not(:first-child) {margin-top:0;margin-left:20px;}
            }
        }
    }

    /* 타입11 */
    .btn-area9 {display:flex;flex-wrap:wrap;padding:30px 35px 30px 27px;border-radius:10px;background:#fff;box-shadow:0 5px 10px 0 rgba(52, 75, 105, 0.1);font-size:13px;
        > li {position:relative;display:inline-flex;flex-direction:column;justify-content:center;width:calc(100% / 6 - 8px);min-height:80px;margin-left:8px;padding:10px 18px;border-radius:4px;border:1px solid #e6e6e6;background:#fff;box-sizing:border-box;
            &:nth-child(6) ~ li {margin-top:8px;}
            //타이틀
            > span:nth-child(1) {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
            //금액
            > span:nth-child(2) {display:flex;justify-content:flex-end;align-items:center;margin-top:7px;text-align:right;
                > strong {overflow:hidden;margin-top:-1px;font-size:16px;line-height:1.25;letter-spacing:-0.32px;text-overflow:ellipsis;white-space:nowrap;}
                > i {margin-left:1px;letter-spacing:-0.65px;}//단위
            }
            //전체버튼
            > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                    [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                    &:focus {border-radius:4px;opacity:1;}
                }
            }
        }
        //말줄임해제
        &.t1 > li {
            //타이틀
            > span:nth-child(1) {white-space:normal;}
            //금액
            > span:nth-child(2) {
                > strong {white-space:normal;}
            }
        }
    }

    /* 타입12 */
    .btn-area10 {display:grid;grid-template-columns:repeat(auto-fit, minmax(190px, 1fr));grid-gap:34px 64px;padding:36px 116px;border-radius:8px;border:1px solid #e6e6e6;background:#fff;box-sizing:border-box;
        > li {position:relative;display:flex;flex-direction:column;justify-content:center;min-height:50px;padding-left:64px;box-sizing:border-box;
            //아이콘
            &:before {content:'';position:absolute;left:0;top:50%;width:50px;height:50px;margin-top:-25px;border-radius:16px;background-color:#f0f8ff;background-repeat:no-repeat;background-position:50%;}
            &.type1:before {background-image:url('../imgs/ico/ic_type_insured_protection_on.png');}
            &.type2:before {background-image:url('../imgs/ico/ic_type_medical_on.png');}
            &.type3:before {background-image:url('../imgs/ico/ic_type_insured_medical_on.png');}
            &.type4:before {background-image:url('../imgs/ico/ic_type_edu_on.png');}
            &.type5:before {background-image:url('../imgs/ico/ic_type_card_credit_on.png');}
            &.type6:before {background-image:url('../imgs/ico/ic_type_card_debit_on.png');}
            &.type7:before {background-image:url('../imgs/ico/ic_type_bill_on.png');}
            &.type8:before {background-image:url('../imgs/ico/ic_type_store_on.png');}
            &.type9:before {background-image:url('../imgs/ico/ic_type_pension_saving_on.png');}
            &.type10:before {background-image:url('../imgs/ico/ic_type_pension_account_on.png');}
            &.type11:before {background-image:url('../imgs/ico/ic_type_house_on.png');}
            &.type12:before {background-image:url('../imgs/ico/ic_type_house_saving_on.png');}
            &.type13:before {background-image:url('../imgs/ico/ic_type_monthly_on.png');}
            &.type14:before {background-image:url('../imgs/ico/ic_type_stock_on.png');}
            &.type15:before {background-image:url('../imgs/ico/ic_type_company_on.png');}
            &.type16:before {background-image:url('../imgs/ico/ic_type_donation_on.png');}
            //타이틀
            > span:nth-child(1) {overflow:hidden;color:#4a4a4a;letter-spacing:-0.3px;text-overflow:ellipsis;white-space:nowrap;}
            //금액
            > span:nth-child(2) {display:flex;align-items:center;margin-top:3px;
                > strong {overflow:hidden;font-size:14px;line-height:1.29;letter-spacing:-0.35px;text-overflow:ellipsis;white-space:nowrap;}
                > i {margin-left:1px;letter-spacing:-0.3px;}//단위
            }
            //전체버튼
            > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                > button {width:100%;height:100% !important;border:none !important;background:transparent !important;opacity:0;
                    [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                    &:focus {opacity:1;}
                }
            }
            //비활성화
            &.dis {
                //아이콘
                &.dis:before {background-color:#f6f7f9;}
                &.type1:before {background-image:url('../imgs/ico/ic_type_insured_protection_off.png');}
                &.type2:before {background-image:url('../imgs/ico/ic_type_medical_off.png');}
                &.type3:before {background-image:url('../imgs/ico/ic_type_insured_medical_off.png');}
                &.type4:before {background-image:url('../imgs/ico/ic_type_edu_off.png');}
                &.type5:before {background-image:url('../imgs/ico/ic_type_card_credit_off.png');}
                &.type6:before {background-image:url('../imgs/ico/ic_type_card_debit_off.png');}
                &.type7:before {background-image:url('../imgs/ico/ic_type_bill_off.png');}
                &.type8:before {background-image:url('../imgs/ico/ic_type_store_off.png');}
                &.type9:before {background-image:url('../imgs/ico/ic_type_pension_saving_off.png');}
                &.type10:before {background-image:url('../imgs/ico/ic_type_pension_account_off.png');}
                &.type11:before {background-image:url('../imgs/ico/ic_type_house_off.png');}
                &.type12:before {background-image:url('../imgs/ico/ic_type_house_saving_off.png');}
                &.type13:before {background-image:url('../imgs/ico/ic_type_monthly_pay_off.png');}
                &.type14:before {background-image:url('../imgs/ico/ic_type_stock_off.png');}
                &.type15:before {background-image:url('../imgs/ico/ic_type_company_off.png');}
                &.type16:before {background-image:url('../imgs/ico/ic_type_donation_off.png');}
                //금액
                > span:nth-child(2) {color:#a6a6a6;}
            }
        }
        //말줄임해제
        &.t1 > li {
            //타이틀
            > span:nth-child(1) {white-space:normal;}
            //금액
            > span:nth-child(2) {
                > strong {white-space:normal;}
            }
        }
    }

    /*----------------------------------------------------
        [카드/은행 로고이미지 + 카드명]-----------------card(num) 운영서버 반영 후 삭제
    ----------------------------------------------------*/
    /* 타입1 : 52*28 : 세로형 */
    [class*="banklogo"],
    .card, .card1, .card2, .card3, .card4, .card5, .card6, .card7, .card8, .card9, .card10, .card11, .card12, .card13, .card14, .card15, .card16, .card17, .card18, .card19, .card20, .card21, .card22, .card23 {color:#4a4a4a;
        &:before {content:'';display:block;width:52px;height:28px;margin:0 auto;background-repeat:no-repeat;}
    }
    .banklogo:before,
    .card:before {background-image:url('../imgs/erp/ico_etc.png');}//기타
    //은행(banklogo-num(3)) / 카드(banklogo-num(2))
    .banklogo-071:before,
    .card1:before {background-image:url('../imgs/bank/ic_bank_epost_m.png');}//우체국
    .banklogo-004:before, .banklogo-01:before,
    .card2:before {background-image:url('../imgs/bank/ic_bank_kb_m.png');}//국민
    .banklogo-088:before, .banklogo-03:before,
    .card3:before {background-image:url('../imgs/bank/ic_bank_shinhan_m.png');}//신한
    .banklogo-081:before, .banklogo-21:before,
    .card5:before {background-image:url('../imgs/bank/ic_bank_hana_m.png');}//KEB하나
    .banklogo-005:before, .banklogo-05:before,
    .card5:before {background-image:url('../imgs/bank/ic_bank_hana_m.png');}//외환
    .banklogo-034:before, .banklogo-30:before,
    .card6:before {background-image:url('../imgs/bank/ic_bank_kj_m.png');}//광주
    .banklogo-007:before, .banklogo-31:before,
    .card9:before {background-image:url('../imgs/bank/ic_bank_sh_m.png');}//수협
    .banklogo-027:before, .banklogo-26:before,
    .card10:before {background-image:url('../imgs/bank/ic_bank_citi_m.png');}//씨티
    .banklogo-011:before, .banklogo-19:before,
    .card11:before {background-image:url('../imgs/bank/ic_bank_nh_m.png');}//농협
    .banklogo-020:before, .banklogo-28:before,
    .card13:before {background-image:url('../imgs/bank/ic_bank_woori_m.png');}//우리
    .banklogo-037:before, .banklogo-32:before,
    .card14:before {background-image:url('../imgs/bank/ic_bank_kj_m.png');}//전북
    .banklogo-003:before,
    .card15:before {background-image:url('../imgs/bank/ic_bank_ibk_m.png');}//기업
    .banklogo-023:before,
    .card16:before {background-image:url('../imgs/bank/ic_bank_sc_m.png');}//SC제일
    .banklogo-031:before,
    .card17:before {background-image:url('../imgs/bank/ic_bank_daegu_m.png');}//대구
    .banklogo-032:before,
    .card18:before {background-image:url('../imgs/bank/ic_bank_bnk_m.png');}//부산
    .banklogo-002:before,
    .card19:before {background-image:url('../imgs/bank/ic_bank_kdb_m.png');}//산업
    .banklogo-035:before,
    .card20:before {background-image:url('../imgs/bank/ic_bank_shinhan_m.png');}//제주
    .banklogo-039:before,
    .card21:before {background-image:url('../imgs/bank/ic_bank_bnk_m.png');}//경남
    .banklogo-045:before,
    .card22:before {background-image:url('../imgs/bank/ic_bank_mg_m.png');}//새마을금고
    .banklogo-048:before,
    .card23:before {background-image:url('../imgs/bank/ic_bank_cu_m.png');}//신용협동조합
    .banklogo-04:before,
    .card4:before {background-image:url('../imgs/bank/ic_card_bc_m.png');}//비씨
    .banklogo-11:before,
    .card7:before {background-image:url('../imgs/bank/ic_card_lotte_m.png');}//롯데
    .banklogo-13:before,
    .card8:before {background-image:url('../imgs/bank/ic_card_samsung_m.png');}//삼성
    .banklogo-12:before,
    .card12:before {background-image:url('../imgs/bank/ic_card_hyundai_m.png');}//현대

    /* 타입2 : 18*18 : 세로형 */
    [class*="banklogos"],
    .cards, .cards1, .cards2, .cards3, .cards4, .cards5, .cards6, .cards7, .cards8, .cards9, .cards10, .cards11, .cards12, .cards13, .cards14, .cards15, .cards16, .cards17, .cards18, .cards19, .cards20, .cards21, .cards22, .cards23 {color:#4a4a4a;
        &:before {content:'';display:block;width:18px;height:18px;margin:0 auto;background-repeat:no-repeat;}
    }
    //은행(banklogos-num(3)) / 카드(banklogos-num(2))
    .banklogos-071:before,
    .cards1:before {background-image:url('../imgs/bank/ic_bank_epost_s.png');}//우체국
    .banklogos-004:before, .banklogos-01:before,
    .cards2:before {background-image:url('../imgs/bank/ic_bank_kb_s.png');}//국민
    .banklogos-088:before, .banklogos-03:before,
    .cards3:before {background-image:url('../imgs/bank/ic_bank_shinhan_s.png');}//신한
    .banklogos-081:before, .banklogos-21:before,
    .cards5:before {background-image:url('../imgs/bank/ic_bank_hana_s.png');}//KEB하나
    .banklogos-005:before, .banklogos-05:before,
    .cards5:before {background-image:url('../imgs/bank/ic_bank_hana_s.png');}//외환
    .banklogos-034:before, .banklogos-30:before,
    .cards6:before {background-image:url('../imgs/bank/ic_bank_kj_s.png');}//광주
    .banklogos-007:before, .banklogos-31:before,
    .cards9:before {background-image:url('../imgs/bank/ic_bank_sh_s.png');}//수협
    .banklogos-027:before, .banklogos-26:before,
    .cards10:before {background-image:url('../imgs/bank/ic_bank_citi_s.png');}//씨티
    .banklogos-011:before, .banklogos-19:before,
    .cards11:before {background-image:url('../imgs/bank/ic_bank_nh_s.png');}//농협
    .banklogos-020:before, .banklogos-28:before,
    .cards13:before {background-image:url('../imgs/bank/ic_bank_woori_s.png');}//우리
    .banklogos-037:before, .banklogos-32:before,
    .cards14:before {background-image:url('../imgs/bank/ic_bank_kj_s.png');}//전북
    .banklogos-003:before,
    .cards15:before {background-image:url('../imgs/bank/ic_bank_ibk_s.png');}//기업
    .banklogos-023:before,
    .cards16:before {background-image:url('../imgs/bank/ic_bank_sc_s.png');}//SC제일
    .banklogos-031:before,
    .cards17:before {background-image:url('../imgs/bank/ic_bank_daegu_s.png');}//대구
    .banklogos-032:before,
    .cards18:before {background-image:url('../imgs/bank/ic_bank_bnk_s.png');}//부산
    .banklogos-002:before,
    .cards19:before {background-image:url('../imgs/bank/ic_bank_kdb_s.png');}//산업
    .banklogos-035:before,
    .cards20:before {background-image:url('../imgs/bank/ic_bank_shinhan_s.png');}//제주
    .banklogos-039:before,
    .cards21:before {background-image:url('../imgs/bank/ic_bank_bnk_s.png');}//경남
    .banklogos-045:before,
    .cards22:before {background-image:url('../imgs/bank/ic_bank_mg_s.png');}//새마을금고
    .banklogos-048:before,
    .cards23:before {background-image:url('../imgs/bank/ic_bank_cu_s.png');}//신용협동조합

    /*----------------------------------------------------
        [파일(이미지)뷰어]
    ----------------------------------------------------*/
    /* 타입1 */
    .file-view {
        //이미지
        .file {padding:0 !important;
            > div {height:447px;
                & > div:first-child {text-align:center;
                    &:before {content:'';display:inline-block;width:1px;height:100%;vertical-align:middle;}
                    img {max-width:calc(100% - 1px);vertical-align:middle;}
                }
            }
        }
        //이전/다음버튼
        .btn {overflow:hidden;height:37px;padding-top:10px;text-align:center;box-sizing:border-box;
            div + div {margin-left:4px;}
        }
    }

    /* 타입2 */
    .file-view2 {display:flex;
        [class^="OBTFileView_default"] {box-sizing:border-box;
            [class^="OBTFileView_renderer"] {height:calc(100% - 53px)}
        }
    }

    /* 타입3 */
    .file-view3 {border:1px solid #e6e6e6;background:#fff;text-align:center;box-sizing:border-box;
        img {height:100%;}
    }

    /*----------------------------------------------------
        [달력 : OBTCalendar(컴포넌트 제공 달력 외에 추가되는 요소 설정)]
    ----------------------------------------------------*/
    .calendar-area {position:relative;display:flex;box-sizing:border-box;
        .obtCalendar-erp {overflow:initial !important;width:calc(100% - 1px) !important;}//임시(컴포넌트 수정 후 삭제)
        /* 달력 상단 날짜 / 버튼 있을 경우(기본) */
        //상단 / 오른쪽영역
        .area-tr {position:absolute;right:0;top:17px;display:flex;align-items:center;height:27px;z-index:1;
            strong, .viewtype, [data-orbit-component="OBTButton"] {vertical-align:middle;}
            [class*="btn"] {display:inline-block;
                > .viewtype {display:inline-block;margin-left:9px;
                    > [data-orbit-component="OBTButton"]:not(:first-child) {margin-left:0;}
                }
                [data-orbit-component="OBTButton"] + [data-orbit-component="OBTButton"] {margin-left:4px;}
            }
            //버튼 구분바 있음
            .btn {
                //버튼 + 버튼 : 여백 / 구분라인
                > div:not(.viewtype) {display:inline-block;
                    &:before {content:'';display:inline-block;width:1px;height:16px;margin:-2px 10px 0;background:#e6e6e6;vertical-align:middle;}
                    & + [data-orbit-component="OBTButton"] {margin-left:0;}
                    > button {vertical-align:middle;}
                }
            }
            > .btn:first-child > div:first-child:before {display:none !important;}//상단 / 오른쪽영역내 only 버튼만 있을 경우 첫번째 버튼 구분바 삭제
            //버튼 구분바 없음(기본 : 오른쪽정렬) : .btn1
            .btn1 {margin-left:10px;}
        }

        /* 달력 상단 날짜/버튼 없을 경우 : 오른쪽영역만 있을 경우 */
        &.t1 {padding-top:43px;
            //.fc-toolbar.fc-header-toolbar {display:none !important;}//날짜
            //.erpToolbar {display:none !important;}//버튼(이전 / 다음 / 이번달)
            .area-tr {top:0;}//상단/오른쪽영역
        }
    }

    /*----------------------------------------------------
        [회계 계정 형식 : 차변/대변(뷰타입 : 표)]
    ----------------------------------------------------*/
    .account-format {display:flex;border:1px solid #d9d9d9;border-top:2px solid #666;box-sizing:border-box;
        [class*="area"] {flex:1;
            dt, .ti {height:40px;padding:0 20px;border-bottom:1px solid #e6e6e6;background:#fafafa;font-size:15px;line-height:40px;letter-spacing:-0.75px;
                &:before {content:'';display:inline-block;width:2px;height:12px;margin-right:6px;background:#1c90fb;}
                em {float:right;font-size:18px;font-weight:bold;letter-spacing:-0.9px;}
            }
            dd {height:calc(100% - 41px);
                > div {height:100%;}//심플스크롤
                ul {padding:11px 20px 13px;letter-spacing:-0.6px;box-sizing:border-box;
                    li {
                        > a {display:flex;position:relative;padding:1px 0 0 9px;text-decoration:none;cursor:pointer;
                            &:before {content:'';position:absolute;left:0;top:9px;width:2px;height:2px;background:#a6a6a6;}
                            span {width:50%;color:#4a4a4a;}
                            em {flex:1;margin-top:-1px;padding-left:10px;font-size:14px;text-align:right;}
                        }
                        &:hover > a, &.on > a {
                            span, em {color:#1c90fb !important;}
                        }
                    }
                }
            }
        }
        .area2 {border-left:1px solid #e6e6e6;box-sizing:border-box;}
        //상단 타이틀 + 그리드
        &.t1 {border:none;
            [class*="area"] {
                > .ti {margin-bottom:10px;border:1px solid #e6e6e6;box-sizing:border-box;}
                &:first-child .ti {border-right:none;}
                &:last-child .ti {position:relative;border-left:none;
                    &:after {position:absolute;top:50%;left:0;content:'';display:inline-block;width:1px;height:20px;margin-top:-10px;background:#e6e6e6;}
                }
                > div:not(.ti) {height:calc(100% - 50px) !important;}
            } 
        }
    }

    /*----------------------------------------------------
        [집계]
    ----------------------------------------------------*/
    /* 타입1 */
    .sum-area {display:flex;align-items:center;border:1px solid #eaedf3;border-radius:4px;background:#f8f8f8;box-sizing:border-box;
        > strong {display:block;width:250px;font-size:14px;text-align:center;}
        > ul {display:flex;flex-wrap:wrap;align-items:center;flex:1;padding:16px 0;border-radius:4px;background:#fff;
            > li[class*="type"] {position:relative;flex:1;min-width:12.5%;padding:0 5px;line-height:1;text-align:center;box-sizing:border-box;
                > span {position:relative;display:inline-block;margin:0 auto;padding-left:17px;font-size:13px;line-height:1.2;color:#4a4a4a;letter-spacing:-0.33px;
                    > i {overflow:hidden;position:absolute;left:0;top:2px;width:12px;height:0;padding-top:12px;background:#868e96;}//기타
                }
                &.type1 > span > i {background:#46a3f0;}//정상
                &.type2 > span > i {background:#20c997;}//휴가
                &.type3 > span > i {background:#748ffc;}//출장
                &.type4 > span > i {background:#ac91f5;}//외근
                &.type5 > span > i {background:#f0c325;}//지각
                &.type6 > span > i {background:#ffa94d;}//조퇴
                &.type7 > span > i {background:#ff8787;}//결근
                > strong {display:block;padding-top:3px;font-size:18px;line-height:1;color:#000;letter-spacing:-0.9px;}
                &:not(:nth-child(8n + 1)) {border-left:1px solid #efefef;}
                &:nth-child(8n) ~ li {flex:none;width:12.5%;margin-top:16px;}
            }
        }
        > strong + ul {border-radius:0 4px 4px 0;}
    }

    /* 타입2 */
    .sum-area2 {
        > div {margin-top:10px;border-radius:4px;
            &:first-child {margin-top:0;}
            > div {display:flex;align-items:center;
                > span {font-size:14px;font-weight:bold;
                    &:before {margin:0 4px 0 0;vertical-align:top;}
                }
                > strong {display:flex;flex:1;justify-content:flex-end;align-items:baseline;font-size:16px;
                    > i {font-weight:normal;font-size:14px;}
                }
            }
            > ul {
                > li {display:flex;line-height:22px;
                    > span {font-size:11px;letter-spacing:-0.55px;}
                    em {font-size:14px;letter-spacing:-0.7px;}
                }
                &:after {position:absolute;top:5px;left:50%;content:'';display:inline-block;width:1px;height:33px;background:#ececec;}
            }
            &.area {padding:10px 20px 14px 20px;border:1px solid #e6e6e6;
                > div {padding-bottom:10px;margin-bottom:15px;border-bottom:1px solid #ececec;}
                > ul {display:flex;flex-wrap:wrap;position:relative;
                    > li {width:50%;box-sizing:border-box;
                        > span {width:50px;color:#a6a6a6;}
                        > span + * {height:22px;text-align:right;}
                        em {overflow:hidden;display:inline-block;padding-right:1px;width:84px;line-height:22px;white-space:nowrap;text-overflow:ellipsis;box-sizing:border-box;}
                        &:nth-child(1) {order:1;padding-right:20px;}
                        &:nth-child(2) {order:3;padding-right:20px;}
                        &:nth-child(3) {order:2;padding-left:20px;}
                        &:nth-child(4) {order:4;padding-left:20px;}
                    } 
                }
            }
            &.total {padding:13px 20px 15px 20px;border:1px solid #c2d6e5;background-color:#eef8ff;
                > div {padding-bottom:12px;margin-bottom:7px;border-bottom:1px solid #c2d6e5;
                }
                > ul {
                    > li {justify-content:space-between;
                        > span {color:#8c8c8c;}
                        &:last-child {border-top:1px dashed #deeaf4;margin-top:6px;padding-top:6px;
                            > span {font-size:12px;}
                            em {font-size:16px;font-weight:bold;}
                        }
                    }
                    &:after {display:none;}
                }
            }
        }
    }

    /* 타입3 */
    [data-orbit-component="OBTTooltip"].sum-area3 dl, dl.sum-area3 {padding:14px 16px;border-radius:4px;background:#436ebd;color:#fff;
        dt {font-size:13px;letter-spacing:-0.65px;}
        dd {display:flex;align-items:center;margin-top:6px;
            //금액
            > span {display:flex;justify-content:flex-end;align-items:center;flex:1;min-width:0;
                &:not(:first-child) {margin-left:10px;}
                > strong {font-size:18px;letter-spacing:-0.45px;text-align:right;}
                > i {margin-left:2px;font-size:14px;letter-spacing:-0.35px;}//단위
            }
        }
    }
    //툴팁
    [data-orbit-component="OBTTooltip"].sum-area3 {width:100%;line-height:normal;
        dd {
            //금액
            > span {
                > strong {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
            }
        }
    }

    /* 타입4 */
    [data-orbit-component="OBTTooltip"].sum-area4 dl, dl.sum-area4 {display:flex;align-items:center;min-height:52px;padding:15px 30px;border-radius:4px;background:#50586a;color:#fff;font-weight:bold;box-sizing:border-box;
        dt {font-size:14px;letter-spacing:-0.35px;}
        dd {display:flex;justify-content:flex-end;align-items:center;flex:1;min-width:0;margin-left:10px;font-size:15px;letter-spacing:-0.38px;
            > strong {text-align:right;}
            > i {margin-left:2px;}//단위
        }
    }
    //툴팁
    [data-orbit-component="OBTTooltip"].sum-area4 {width:100%;line-height:normal;
        dd {
            > strong {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
        }
    }

    /*----------------------------------------------------
        [계산내역 + 합계]
    ----------------------------------------------------*/
    /* 타입1 */
    .order-sum1 {display:flex;justify-content:center;align-items:center;height:80px;border:1px solid #d9d9d9;border-top:2px solid #666;text-align:center;box-sizing:border-box;
        > div {flex:1;display:flex;flex-direction:column;justify-content:center;min-width:20%;height:100%;box-sizing:border-box;
            &:not(:first-child):before {content:'';position:absolute;left:-15px;top:0;margin-top:25px;width:26px;height:26px;z-index:1;background:url('../imgs/erp/ic_plus.png') no-repeat;}
            &.minus:before {background-image:url('../imgs/erp/ic_minus.png');}
            &.total:before {background-image:url('../imgs/erp/ic_equal.png');}
            &:not(.ti) {position:relative;padding:0 15px;color:#8c8c8c;letter-spacing:-0.6px;
                > strong {display:block;font-size:20px;line-height:30px;color:#000;
                    > span {font-size: 16px;font-weight: normal;}
                }
                &.total > strong {color:#1d90fb;}
            }
        }
        > .ti {flex:none;min-width:13%;padding:0 15px;background:#f8f8f8;font-size:14px;letter-spacing:-0.7px;font-weight:bold;box-sizing:border-box;}
        > .ti + div:before {display:none;}
    }

    /* 타입2 */
    .order-sum2 {
        > dl {padding-bottom:15px;
            dt {display:block;height:32px;margin-bottom:13px;padding:0 10px;border:solid #d9d9d9;border-width:1px 0 1px 0;background:#f8f8f8;line-height:32px;color:#000;font-weight:bold;}
            dd {overflow:hidden;height:25px;padding:0 20px;line-height:25px;color:#8c8c8c;letter-spacing:-0.6px;
                > span {float:left;}
                > strong {float:right;font-size:13px;color:#000;
                    > span {font-size:11px;font-weight:normal;vertical-align:top;}
                }
                &:last-child {
                    > strong {font-size:20px;
                        > span {font-size:16px;}
                    }
                }
            }
        }
        > dl + div {width:100%;padding:12px 20px;border-top:1px solid #d9d9d9;box-sizing:border-box;
            button {width:100%;border-color:#036ddd !important;}
        }
    }

    /* 타입3 */
    .order-sum3 {padding:14px 20px;border-radius:4px;border:1px solid #d5dce8;background:#f2f5fa;color:#a6a6a6;box-sizing:border-box;
        //공통
        .sum, .order {display:flex;align-items:center;
            .ti {width:190px;padding-right:20px;box-sizing:border-box;}
            .cont {display:inline-flex;align-items:center;}
        }
        //계산내역
        .sum {
            .ti {
                > strong, > em, > span {display:block;}
                > strong {font-size:16px;color:#000;}
                > em {font-size:14px;color:#000;}
            }
            .cont {width:calc(100% - 190px);padding:11px 25px;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 rgba(219, 228, 235, 0.71);box-sizing:border-box;
                //기호( + / - / = )
                > span {overflow:hidden;width:26px;height:0;margin:0 10px;padding-top:26px;background:url('../imgs/erp/ic_plus.png') no-repeat;// + (기본)
                    &.minus {background-image:url('../imgs/erp/ic_minus.png');}// -
                    &.total {background-image:url('../imgs/erp/ic_equal.png');}// =
                }
                > div {overflow:hidden;flex:1;
                    > em {overflow:hidden;display:block;text-align:center;white-space:nowrap;text-overflow:ellipsis;}
                    > strong {overflow:hidden;display:block;font-size:16px;letter-spacing:-0.8px;color:#fc5356;text-align:center;white-space:nowrap;text-overflow:ellipsis;
                        > span {font-size:13px;font-weight:normal;letter-spacing:-0.65px;}
                    }
                }
                .minus + div > strong {color:#1d90fb;}
                .total + div > strong {color:#000;}
            }
        }
        //진행순서
        .order {margin-top:10px;font-size:11px;
            .ti {color:#000;}
            .cont {flex-wrap:wrap;
                li + li {margin-left:5px;padding-left:18px;background:url('../imgs/ico/ic_arrow_right_01_s_disable.png') no-repeat 0 50%;}
            }
        }
    }

    /*----------------------------------------------------
        [정보/폼테이블 : 컴포넌트 미사용]
    ----------------------------------------------------*/
    /* 공통(전문가/개인화) */
    [class*="data-tbl"] {width:100%;border-top:2px solid #666;table-layout:fixed;border-spacing:0;
        th, td {overflow:hidden;height:27px;border-bottom:1px solid #e6e6e6;line-height:15px;color:#000;white-space:nowrap;text-overflow:ellipsis;
            > [data-orbit-component=OBTTooltip] {overflow:hidden;max-width:100%;height:27px;margin-bottom:-4px;line-height:27px;white-space:nowrap;text-overflow:ellipsis;}
            //도움말 아이콘(i) 툴팁
            &.al > .help {justify-content:flex-start;}
            &.ac > .help {justify-content:center;}
            &.ar > .help {justify-content:flex-end;}
            > .help {display:flex;
                > [data-orbit-component="OBTTooltip"] > img {margin-top:1px;}
            }
        }
        th {background:#fafafa;letter-spacing:-0.6px;
            //도움말 아이콘(i) 툴팁
            > .help {justify-content:flex-end;
                > [data-orbit-component="OBTTooltip"] {margin-right:4px;}
            }
        }
        td {letter-spacing:-0.3px;
            //도움말 아이콘(i) 툴팁
            > .help > [data-orbit-component="OBTTooltip"] {margin-left:5px;}
            //주소
            &.addr-area {
                > div + div:not(.box) {margin-top:5px;}
            }
            //텍스트 + 버튼(오른쪽끝 고정)
            > div.t1 {display:flex;align-items:center;
                > div:first-child {overflow:hidden;flex:1;padding-right:10px;text-align:left;white-space:nowrap;text-overflow:ellipsis;}
                > [data-orbit-component="OBTButton"] {align-self:flex-start;}
            }
            //텍스트 |(구분바) 텍스트
            > div.t2 {overflow:hidden;flex-wrap:wrap;align-items:center;white-space:nowrap;text-overflow:ellipsis;
                > span:nth-child(1) {padding-right:14px;}
                > span:nth-child(2) {flex:1;margin-left:-7px;padding-left:7px;font-size:11px;color:#a6a6a6;
                    &:before {content:'';display:inline-block;width:1px;height:11px;margin:-3px 6px 0 -7px;background:#e6e6e6;vertical-align:middle;}
                }
            }
            //상태(아이콘) + 텍스트
            > div.t3 {display:flex;align-items:center;
                > [class*="tag-type"], > [class*="state-type"] {vertical-align:baseline;
                    &:not(:only-child):first-child {margin-right:4px;}
                    &:not(:only-child):last-child {margin-left:4px;}
                }
                > span {overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
            }
        }
        tfoot {
            th, td {background:#fff1d6;}
        }
        //말줄임해제
        &.t1 {
            td {
                //텍스트 |(구분바) 텍스트
                > div.t2 {display:flex;white-space:normal;
                    > span {flex:inherit;}
                }
                //상태(아이콘) + 텍스트
                > div.t3 > span {white-space:normal;}
            }
        }
        //구분라인 / 타이틀
        tr {
            //구분라인(가로 / tr설정)
            &.line {
                > th, > td {border-bottom:1px solid #a6a6a6 !important;}
            }
            //타이틀
            &.ti {
                > th {background-color:#fafafa;text-align:left;
                    > div:first-child {display:flex;align-items:center;
                        > .info {display:flex;flex:1;//정보
                            > span {display:inline-block;padding-left:10px;color:#1c90fb;font-weight:normal;}//왼쪽 보조문구(ex.계산식)
                            > em {display:inline-block;flex:1;color:#1c90fb;text-align:right;}//오른쪽 보조문구(ex.총주유비)
                        }
                        > .btn {margin-left:6px;//버튼
                            > div {margin-left:4px;}
                        }
                    }
                }
            }
        }
        //배경색상(파랑)
        .bg1 {background:#f2f6f8;}
        .bg2 {background:#deebf2;}
        //합계배경색상(노랑)
        .total {background:#fff1d6;}//합계
        .total2 {background:#fffceb;}//소계
        .total3 {background:#fff7e0;}//중계
        //멀티라인컨텐츠(5줄)
        .ellipsis-no {height:75px;white-space:normal;text-overflow:initial;
            &.t1 {height:auto;}
            > div {height:100%;}//심플스크롤(컴포넌트)
        }
        //증빙파일
        .file-area {
            > span {display:block;max-width:calc(100% - 22px);padding-left:22px;cursor:pointer;
                &:hover {text-decoration:underline;}
            }
            //클릭시 메뉴 노출
            .menu {width:160px;border:1px solid #a6a6a6;background:#fff;box-shadow:0px 1px 1px 0 rgba(6, 6, 6, 0.1);
                li {padding:0 9px;height:27px;line-height:27px;
                    > div {display:inline-block;width:calc(100% - 20px);vertical-align:middle;
                        button {width:100%;padding:0;border:none;background:none !important;color:#000;text-align:left;font-weight:bold;
                            span {width:100%;
                                &:after {display:none;}
                            }
                        }
                    }
                    &:hover {background:#e6f5ff;}
                    &:before {content:'';display:inline-block;width:14px;height:14px;margin-right:6px;vertical-align:middle;}
                    &.pc:before {background:url('../imgs/ico/ic_download_s_normal.png');}//pc저장
                    &.view:before {background:url('../imgs/ico/ic_viewer_open_s_normal.png');}//미리보기
                }
            }
        }
        //리스트영역(공통)
        .list-area1 {
            &:not(:first-child) {margin-top:3px;}
            li, dd {
                &:before {top:7px;}
            }
        }
    }

    /* on : .data-tbl1, .data-tbl6 */
    .data-tbl1, .data-tbl6 {
        tr.on {border-color:#1c90fb;
            th, td {position:relative;background-color:rgba(28, 144, 251, 0.07);border-bottom-color:#1c90fb !important;
                &:before {content:'';position:absolute;top:0;left:-1px;display:inline-block;width:calc(100% + 2px);height:1px;background:#1c90fb;z-index:1;}
            }
        }
        //말줄임해제
        &.t1 {
            tr {
                td {
                    //텍스트 + 버튼(오른쪽끝 고정)
                    > div.t1 {
                        > div:first-child {overflow:initial;white-space:normal;}
                    }
                }
            }
            tr.on {
                th, td {overflow:initial;
                    &:before {top:-1px;}
                }
            }
        }
    }

    /* 테이블 높이 100% / 특정영역 높이 100%, 심플스크롤, 정렬 재설정 */
    .data-tbl1, .data-tbl6 {
        &.p100 {height:100%;
            td.p100 {height:100%;vertical-align:text-top;white-space:normal;text-overflow:initial;
                > div {height:100%;}//심플스크롤(컴포넌트)
            }
        }
    }

    /* 타입1 */
    .data-tbl1 {
        tr {border-left:1px solid transparent;border-right:1px solid transparent;}
        th {padding:6px 10px 7px;text-align:right;}
        td {padding:6px 8px 7px;}
        //구분라인
        th.line, td.line {border-left:1px solid #e6e6e6;
            &[rowspan] {border-left:none;border-right:1px solid #e6e6e6;}
        }
        //말줄임해제
        &.t1 {
            th, td {white-space:normal;text-overflow:inherit;}
        }
    }

    /* 타입2 */
    .data-tbl6 {
        tr {border-left:1px solid #e6e6e6;border-right:1px solid #e6e6e6;}
        th, td {padding:2px 8px 3px;text-align:center;
            &:not(:last-child) {border-right:1px solid #e6e6e6;}
            //도움말 아이콘(i) 툴팁
            > .help {justify-content:center;}
        }
        //구분라인
        th.line, td.line {border-right:1px solid #e6e6e6;}
        thead {
			tr:not(:first-child) th {font-weight:normal;}
			tr.fwb th {font-weight:bold;}
		}
        tbody th {background:#fafafa;}
        //상태(아이콘) + 텍스트
        td > div.t3 {justify-content:center;}
        td.al > div.t3 {justify-content:flex-start;}
        td.ar > div.t3 {justify-content:flex-end;}
        //말줄임해제
        &.t1 {
            th, td {white-space:normal;text-overflow:inherit;}
        }
        &.t2 {
            tr:nth-child(odd){background:#f0f8ff;}
        }
    }

    /* 타입3 */
    .data-tbl2 {border-left:1px solid #e6e6e6;border-right:1px solid #e6e6e6;
        th, td {padding:2px 8px 3px;}
        thead {
            th {border-bottom-color:#d9d9d9;}
        }
        tbody {
            th, td {background:transparent;text-align:left;
                //카드아이콘
                [class*="banklogos"] {display:flex;align-items:center;
                    &:before {margin-right:8px;}
                    > i {flex:1;}
                }
            }
            &:last-child tr:last-child,
            &.close:last-child tr.depth1 {
                th, td {border-bottom:1px solid #d9d9d9;}
            }
            //EmptySet
            .no_data {padding:20px 0;}

        }
        tfoot {
            th, td {border-top:1px solid #d9d9d9;font-weight:bold;}
        }
        //아코디언형태
        tbody.open, tbody.close {
            th, td {border-bottom-color:#c5cfda;background:#e0eaf5;}
            th {font-weight:normal;}
            //열기/닫기버튼
            th:first-child {padding-left:0;padding-right:0;border:none;}
            //2depth 리스트
            .depth2 {
                th, td {border:1px solid #e0e0e0;background:#fff;}
                th:first-child {border:none;background:#e0eaf5;}
                //select 상태
                &.on {
                    th:not(:first-child), td:not(:first-child) {background:#eff7ff;}
                }
            }
        }
        tbody.open {
            button.open {display:none;}
        }
        tbody.close {
            button.close {display:none;}
            .depth2 {display:none;}
        }
        //말줄임해제
        &.t1 {
            th, td {white-space:normal;text-overflow:inherit;}
        }
    }

    /* 타입4 */
    .data-tbl3 {border-top:none;
        th, td {padding:6px 8px 7px;text-align:center;}
        th {background:#fff;font-weight:normal;}
        thead th {height:15px;padding:5px 8px;border-color:#d9d9d9;font-size:11px;}
    }

    /* 타입5 */
    .data-tbl8 {border-bottom:1px solid #e6e6e6;
        th, td {border-bottom:none;
            &:not(:first-child) {border-left:1px solid #e6e6e6;}
        }
        thead th {padding:6px 8px 7px;text-align:center;}
        tbody tr {
            td {height:28px;padding:0 20px;
                > span, > strong {overflow:hidden;display:inline-block;width:calc(50% - 5px);vertical-align:middle;white-space:nowrap;text-overflow:ellipsis;}
                > span {color:#a6a6a6;
                    &:before {content:'';display:inline-block;width:2px;height:2px;margin:-2px 5px 0 0;background:#a0a0a0;vertical-align:middle;}
                }
                > strong {margin-left:10px;text-align:right;}
            }
            &:first-child td {padding-top:10px;}
            &:last-child td {padding-bottom:11px;}
        }
    }

    /* 타입6 */
    .data-tbl9 {width:100%;border:1px solid #e6e6e6;
        th, td {height:18px;padding:4px 8px;border:1px solid #e6e6e6;white-space:normal;text-overflow:initial;}
        th {background:#fff;font-weight:normal;
            &.ti-type1 {padding:4px 0px;background:#649cc6;color:#fff;font-weight:bold;}
            &.ti-type2 {background:#f2f6f8;text-align:left;
                &.ac {padding:4px 0;}
            }
            &.ti-type3 {background:#f5f5f5;text-align:left;}
            &.ti-type4 {background:#fafafa;font-weight:bold;}
        }
        thead th {
            > i {padding-left:8px;font-size:11px;color:#4a4a4a;}//보조문구
        }
        > tbody {
            td {text-align:right;
                //비활성화모드
                &.empty-mode {background:#f5f5f5 !important;}
                //서명
                .sign-area {position:relative;display:flex;align-items:center;
                    > strong {flex:1;font-weight:normal;}
                    > em {padding-left:10px;
                        img {position:absolute;top:50%;right:8px;width:50px;height:50px;margin-top:-25px;}
                    }

                }
                //컨텐츠 타입1
                .cont-type1 {display:flex;align-items:center;
                    span:nth-child(1) {flex:1;}
                    span:nth-child(2) {width:160px;
                        &:before {content:'';display:inline-block;width:1px;height:10px;margin-right:15px;background:#e6e6e6;}
                    }
                }
                //컨텐츠 타입2
                .cont-type2 {display:flex;align-items:center;
                    span:nth-child(1) {width:calc(50% - 12px);}
                    span:nth-child(2) {width:calc(50% + 12px);text-align:left;
                        &:before {content:'/';display:inline-block;width:24px;text-align:center;}
                    }
                }
            }
            //합계
            .total, .total th, .total td {background:#fff1d6 !important;}//합계
            .total2, .total2 th, .total2 td {background:#fffceb !important;}//소계
            .total3, .total3 th, .total3 td {background:#fff7e0 !important;}//중계
        }
    }

    /* 타입7 */
    .data-tbl10 {border-top-width:1px;border-top-style:double;
        tr {border-left:1px double transparent;border-right:1px double transparent;}
        th, td {border:1px solid #e6e6e6;text-align:center;}
        th {padding:6px 10px 7px;}
        td {padding:6px 8px 7px;}
        td + th {border-left:1px double transparent;}
        //말줄임해제
        &.t1 {
            th, td {white-space:normal;text-overflow:inherit;}
        }
        //타입1
        &.t2 {border-top-width:2px;}
    }

    /* 타입8 */
    .data-tbl11 {border-top:1px solid #646464;
        tr td {padding:0 10px;height:auto;white-space:normal;text-overflow:inherit;
            &:last-child {border-right:none !important;}
        }
        tr:nth-child(odd) {
            td {padding-top:10px;border-bottom:none;
                &:nth-child(3n + 1) {padding-top:0;border-bottom:1px solid #e6e6e6;}
                &:nth-child(3n) {border-right:1px solid #e6e6e6;}
            }
        }
        tr:nth-child(even) {
            td {padding-bottom:10px;
                &:nth-child(2n) {border-right:1px solid #e6e6e6;}  
            }
        }
    }

    /* 타입9 */
    .data-tbl12 {border-collapse:separate;border-radius:6px;border:1px solid #e6e6e6;
        th, td {height:17px;padding:10px 20px;border:none;}
        thead {
            th {background:#fafbfc;text-align:center;
                &:not(:first-child) {border-left:1px solid #e6e6e6;}
            }
            tr:first-child {
                th:first-child {border-top-left-radius:6px;}
                th:last-child {border-top-right-radius:6px;}
            }
        }
        tbody {
            th, td {border-top:1px solid #f5f5f5;background:none;color:#4a4a4a;font-weight:normal;}
            th {text-align:left;}
            th > span {display:block;margin-top:4px;}//아이콘 + 텍스트(줄바꿈)
            tr:first-child {
                th, td {border-top-color:#e6e6e6;}
            }
            tr:last-child {
                th:first-child, td:first-child {border-bottom-left-radius:6px;}
                th:last-child, td:last-child {border-bottom-right-radius:6px;}
            }
        }
        //말줄임해제
        &.t1 {
            th, td {white-space:normal;text-overflow:inherit;}
        }
        //td 세로 구분라인 추가
        &.t2 {
            tbody {
                th, td {
                    &:not(:first-child) {border-left:1px solid #f5f5f5;}
                }
            } 
        }
    }

    /* 타입10 */
    .data-tbl13 {border-collapse:separate;border-radius:4px;border:1px solid #d6dce5;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.06);
        th, td {height:17px;padding:14px 15px 15px;border:none;
            &:not(:first-child) {border-left:1px solid #f0f0f0;}
        }
        thead {
            th {background:#fafbfc;text-align:center;}
            tr:first-child {
                th:first-child {border-top-left-radius:4px;}
                th:last-child {border-top-right-radius:4px;}
            }
        }
        tbody {
            th, td {border-top:1px solid #f0f0f0;background:none;}
            th {color:#8c8c8c;text-align:left;font-weight:normal;}
            td {font-weight:bold;}
            tr:first-child {
                th, td {border-top-color:#e6e6e6;}
            }
            tr:last-child {
                th:first-child, td:first-child {border-bottom-left-radius:4px;}
                th:last-child, td:last-child {border-bottom-right-radius:4px;}
            }
        }
        //말줄임해제
        &.t1 {
            th, td {white-space:normal;text-overflow:inherit;}
        }
    }

    /* 타입11 */
    .data-tbl4 {border-bottom:1px solid #e6e6e6;
        > thead > tr, > tbody > tr, > tfoot > tr {
            > th, > td {padding:9px 8px 10px;border:solid #e6e6e6;border-width:1px 0 0;font-size:13px;text-align:center;}
            > th:first-child, > td:first-child {border-left:1px solid #fff;}
            > th:last-child, > td:last-child {border-right:1px solid #fff;}
        }
        > thead > tr > th + th {border-left:1px solid #e6e6e6;}
        > tbody > tr > th {background:#fff;font-weight:normal;}
        > thead + tbody > tr:last-child {
            > th, > td {border-bottom:1px solid #e6e6e6;}
        }
        > tfoot > tr {
            > th, > td {border-bottom:1px solid #e6e6e6;font-weight:bold;}
        }
        //OBTTextField
        [data-orbit-component="OBTTextField"] > div {background:none !important;
            > div {border:none !important;background:none !important;}
        }
        //OBTDropDownList
        [data-orbit-component="OBTDropDownList"] {width:100%;
            > div {width:100% !important;background:none !important;
                div > span {border:none !important;background:none !important;text-align:left;
                    > span {background:none !important;color:#000 !important;}
                }
            }
            &.dis span {color:#8d8d8d !important;}
        }
        //hover / on(.t1 제외)
        &:not(.t1) {
            > tfoot tr, > tbody > tr:not(.add):not(.emptyData) {
                &:hover {box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);
                    > th, > td {border-color:#1c90fb;}
                    & + tr {
                        > th, > td {border-top-color:#1c90fb;}
                    }
                }
                &:last-child:hover {
                    > th, > td {border-bottom:1px solid #1c90fb;}
                }
                &.on {
                    th, td {background:#eff7ff !important;}
                }
            }
        }
        //추가
        .add td {padding:0;
            > div {width:100%;height:100%;
                button {width:100%;height:46px;border:none !important;background-image:none !important;
                    span {margin-left:3px;font-size:13px !important;color:#8d8d8d;
                        &:after {display:none;}
                    }
                    &:hover span {color:#1c90fb;}
                }
            }
        }
        //emptyData
        .emptyData td {padding:20px 0;}
    }

    /* 타입12 */
    [class*="data-tbl5"] {border-top:none;border-collapse:separate;border-spacing:0 6px;font-size:12px;
        tr {border-radius:4px;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.1);
            //Select
            &.on {
                th, td {background:#eff7ff;}
            }
        }
        th:first-child, td:first-child {border-radius:4px 0 0 4px;}
        th:last-child, td:last-child {border-radius:0 4px 4px 0;}
        th, td {border:none;background:#fff;line-height:1.33;text-align:center;
            > [data-orbit-component=OBTTooltip] {height:auto;line-height:inherit;margin-bottom:0;vertical-align:middle;}
        }
        thead th {height:16px;padding:7px;color:#4a4a4a;letter-spacing:-0.6px;font-weight:normal;}
        tbody, tfoot {
            th, td {height:26px;padding:10px;
                //테이블 구분라인 X
                &.bar-no:before {display:none;}
            }
            //테이블 구분라인 O
            th:first-child ~ td, td:first-child ~ td {position:relative;
                &:before {content:'';position:absolute;left:0;top:16px;width:1px;height:14px;background:#e6e6e6;}
            }
        }
        tbody th {font-weight:normal;}
        tfoot {
            th, td {background:#fff;}
            td {font-weight:bold;}
        }
        //커서 변경
        &.t2 tbody tr:hover {cursor:pointer;}
    }
    //head 테이블 분리
    .data-tbl5-head {border-collapse:collapse;border-spacing:0;margin-bottom:4px;}

    /* 타입13 : ----------------- .data-tbl7 신규 버전(개발 완료 후 이전 버전(.data-tbl7) 삭제 예정) */
    [class*="data-tbl14"] {border-top:none;border-collapse:separate;color:#000;border-spacing:0 6px;
        tr {border-radius:4px;}
        //thead > 2row th(:first-child:last-child)영역 border-radius 초기화 : td.reset1
        //tbody > td영역 구분라인 삭제 : td.reset2
        th:not(.reset1):first-child, td:first-child {border-radius:4px 0 0 4px;}
        th:not(.reset1):last-child, td:last-child {border-radius:0 4px 4px 0;}
        th, td {text-align:center;
            [data-orbit-component=OBTTooltip] {height:auto;line-height:inherit;margin-bottom:0;vertical-align:middle;}
            //체크박스(18*18)
            &.ckbox {padding-left:19px;padding-right:10px;text-align:right;
                > .checkbox {width:18px;padding-right:0;}
            }
        }
        thead tr {
            th {height:16px;padding:7px;border:none;background:#f2f6f8;line-height:1.33;color:#4a4a4a;letter-spacing:-0.6px;font-weight:normal;}
        }
        tbody, tfoot {
            //Over
            tr:hover {box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);
                td {border-color:#1c90fb;}
            }
            //Select
            tr.on {
                td {border-color:#1c90fb !important;background-color:#eff7ff !important;}
            }
            th, td {height:24px;padding:10px;border:solid #e6e6e6;border-width:1px 0;background-color:#fff !important;line-height:1.33;letter-spacing:-0.33px;
                &:first-child {border-left-width:1px;}
                &:last-child {border-right-width:1px;}
                &:not(:last-child):not(.reset2) {background:url('../imgs/erp/img_line.png') no-repeat 100% 50%;}
                //숫자(카운트)1
                &.num {color:#4a4a4a;
                    //박스형태
                    > span {display:inline-block;width:30px;height:30px;border-radius:4px;background:#f8f9fa;line-height:30px;font-weight:bold;vertical-align:middle;
                        //잔여
                        &.end {background:#e4f7f3;}
                        &.end2 {background:#67ccb5;color:#fff;}
                        //부여, 사용, 결재중
                        &.ing {background:#e2e2e2;color:#8c8c8c;}
                    }
                }
                //숫자(카운트)2
                &.num2 {font-size:13px;}
                //사진 + 이름 + 직급 + 부서
                &.name {
                    &:first-child {padding-left:19px;}
                    .pic {position:relative;display:flex;flex-direction:column;justify-content:center;padding-left:30px;
                        .pic-type1-3 {position:absolute;left:0;top:50%;margin-top:-12px;}//사진(24*24)
                        strong {font-size:13px;//이름
                            &:not(:last-child) {margin-top:-2px;}
                            i {font-weight:normal;}//직급
                        }
                        span:not(.pic-type1-3) {margin:5px 0 -3px;font-size:11px;color:#8c8c8c;}//부서
                    }
                }
                //상품정보
                &.prod {
                    .pic {position:relative;display:flex;flex-direction:column;justify-content:center;padding:0 10px 0 60px;
                        .pic-type2-2.t1 {position:absolute;left:10px;top:50%;margin-top:-20px;}//사진(40*40)
                        span:not(.pic-type2-2.t1) {margin:-1px 0 1px;font-size:11px;color:#8c8c8c;}//브랜드
                        strong, del {font-size:13px;}//상품명/기간만료
                        del {color:#a6a6a6;font-weight:normal;text-decoration:line-through;}//기간만료
                        [data-orbit-component="OBTTooltip"] > strong {overflow:hidden;display:block;white-space:nowrap;text-overflow:ellipsis;}//툴팁 + 상품명
                    }
                }
                //아이콘 버튼
                &.btn > div {vertical-align:middle;
                    [class*="OBTButton_typeicon"] {height:24px;}//버튼타입 : icon
                }
                //상태(태그형 / 2자)
                &.state {
                    > [class*="state-type3"], > [data-orbit-component="OBTTooltip"] > [class*="state-type3"] {min-width:34px;height:auto;min-height:18px;}
                }
                //진행률
                &.progress {overflow:visible;
                    > div {overflow:visible;position:relative;display:block;padding:0 50px 0 63px;z-index:0;
                        &:before {content:'';position:absolute;left:63px;right:50px;top:0;height:6px;border-radius:3px;background:#ebebeb;z-index:-1;}
                        .num {position:absolute;left:0;top:50%;width:63px;margin-top:-9px;padding-right:10px;font-size:13px;color:#8c8c8c;letter-spacing:-0.98px;text-align:right;box-sizing:border-box;
                            > b {color:#000;}
                        }
                        .per {display:block;height:6px;border-radius:3px;background:#46a3f0;
                            > span {position:absolute;right:0;top:50%;width:50px;margin-top:-11px;padding-left:10px;color:#46a3f0;text-align:right;box-sizing:border-box;
                                > b {margin-right:3px;font-size:16px;letter-spacing:-1.2px;}
                            }
                        }
                    }
                }
				//텍스트(말줄임) + 아이콘
				&.fix-img {display:flex;align-items:center;
					> img {margin-left:4px;}
				}
				//텍스트 + '>'(아이콘)
				&.arrow {position:relative;padding-left:40px;padding-right:56px;text-align:left;
					&:after {content:'';position:absolute;top:50%;right:14px;transform:translateY(-50%);width:18px;height:18px;background: url("../imgs/ico/ic_arrow_right_01_m_disable.png") no-repeat center;}
				}
				//내부 컨텐츠 타입1
                &.cont-type1 > span {display:block;
                    &:not(:last-child):nth-child(1) {margin-top:-2px;}
                    &:nth-child(2) {margin:4px 0 -2px 0;}
                }
            }
        }
        tbody th {font-weight:normal;}
        tfoot td {font-weight:bold;}
    }
    //head 테이블 분리
    .data-tbl14-head {border-collapse:collapse;border-spacing:0;margin-bottom:4px;}
    //말줄임해제
    [class*="data-tbl14"].t1 {
        th, td {white-space:normal;text-overflow:initial;
            > [data-orbit-component=OBTTooltip] {white-space:normal;text-overflow:initial;}
        }
    }
    //head 영역 구분라인 생성
    .data-tbl14-head.t2 {
        thead tr {
            th:not(:last-child) {border-right:1px solid #e1e8eb;}
            &:not(:only-child):nth-child(1) {
                th[rowspan]:last-child {border-left:1px solid #e1e8eb;}
                th:not([rowspan]):first-child {border-bottom-left-radius:0;}
                th:not([rowspan]):last-child {border-bottom-right-radius:0;}
            }
            &:nth-child(2) {
                th {border-top:1px solid #e1e8eb;}
                th:not([rowspan]):first-child {border-top-left-radius:0;}
                th:not([rowspan]):last-child {border-top-right-radius:0;}
            }
        }
    }

    /* 타입13 ----------------- 공통디자인 변경으로 클래스명(.data-tbl14) 변경하여 신규 제작(틀어짐 방지하기 위해 이전 버전(.data-tbl7) 유지하고 개발 완료 후 삭제 예정) */
    //타입13-1
    .data-tbl7 {border-top:none;border-collapse:separate;border-spacing:0 6px;
        tr {border-radius:4px;
            &.on {//선택 시
                td {border-color:#1c90fb !important;background-color:#f5faff !important;}
            }
        }
        th:first-child, td:first-child {border-radius:4px 0 0 4px;}
        th:last-child, td:last-child {border-radius:0 4px 4px 0;}
        th, td {height:34px;line-height:17px;text-align:center;
            > [data-orbit-component=OBTTooltip] {height:auto;line-height:initial;margin-bottom:3px;vertical-align:middle;}
            //체크박스
            &.ckbox {padding-right:0;
                > .checkbox {width:18px;padding-right:0;}
            }
        }
        thead tr {
            th {padding:8px;border:none;background:#ecf1f5;font-weight:normal;
                &:not(.pd-reset):first-child {padding-left:30px;}
                &:not(.pd-reset):last-child {padding-right:30px;}
            }
            &:not(:only-child):nth-child(1) {
                th:not([rowspan]):first-child, td:not([rowspan]):first-child {border-bottom-left-radius:0;}
                th:not([rowspan]):last-child, td:not([rowspan]):last-child {border-bottom-right-radius:0;}
            }
            &:nth-child(2) {
                th:not([rowspan]):first-child, td:not([rowspan]):first-child {border-top-left-radius:0;}
                th:not([rowspan]):last-child, td:not([rowspan]):last-child {border-top-right-radius:0;}
                .pd-reset {border-radius:0 !important;}
            }
        }
        tbody, tfoot {
            th, td {padding:12px 8px;border-top:1px solid #e6e6e6;background-color:#fff !important;
                &:first-child {padding-left:30px;border-left:1px solid #e6e6e6;}
                &:last-child {padding-right:30px;border-right:1px solid #e6e6e6;}
                &:not(.ckbox):not(.btn):not(.state):not(:last-child) {background:#fff url('../imgs/erp/img_line.png') no-repeat 100% 50%;}
                //숫자(카운트)
                &.num {font-size:13px;font-weight:bold;
                    //박스형태
                    > span {display:inline-block;width:30px;height:30px;border-radius:4px;font-size:12px;line-height:30px;color:#919191;letter-spacing:-1px;vertical-align:middle;
                        //잔여
                        &.end {background:#e4f7f3;color:#4a4a4a;}
                        //발생, 합계
                        &.ing {background:#e2e2e2;color:#4a4a4a;}
                    }
                }
                //사진 + 이름 + 직급 + 부서
                &.name {
                    .pic {overflow:hidden;position:relative;display:block;max-width:calc(100% - 38px);height:28px;min-height:28px;line-height:28px;padding-left:38px;white-space:nowrap;text-overflow:ellipsis;
                        .pic-type1 {position:absolute;left:0;top:50%;margin-top:-14px;}//사진
                    }
                    strong i {font-weight:normal;}//직급
                    span:not([class]) {margin-left:3px;font-size:11px;color:#a6a6a6;}//부서
                }
                //버튼
                &.btn > div {vertical-align:middle;}
                //상태
                &.state {
                    > [class*="state-type"], > [data-orbit-component="OBTTooltip"] > [class*="state-type"] {min-width:34px;height:auto;min-height:18px;}
                }
                //진행률
                &.progress {overflow:visible;padding:0;
                    > div {overflow:visible;position:relative;display:block;height:auto;margin-bottom:0;padding:0 80px 0 63px;line-height:52px;z-index:0;
                        &:before {content:'';position:absolute;left:63px;top:0;width:calc(100% - 143px);height:6px;border-radius:3px;background:#ebebeb;z-index:-1;}
                        .num {position:absolute;left:8px;top:-23px;width:55px;padding-right:10px;font-size:13px;color:#797979;text-align:right;box-sizing:border-box;
                            > b {color:#000;}
                        }
                        .per {display:block;height:6px;border-radius:3px;background:#46a3f0;
                            > span {position:absolute;right:30px;top:-23px;width:50px;padding-left:10px;font-size:13px;color:#46a3f0;text-align:right;box-sizing:border-box;
                                > b {font-size:16px;}
                            }
                        }
                    }
                }
            }
        }
        tbody th {font-weight:normal;}
        tfoot td {font-weight:bold;}
    }
    //타입13-2 : 말줄임해제
    .data-tbl7.t1 {
        th, td {white-space:normal;text-overflow:initial;vertical-align:middle;
            > [data-orbit-component=OBTTooltip] {white-space:normal;text-overflow:initial;}
        }
        tbody tr td {
            //사진 + 이름 + 직급 + 부서
            &.name {
                .pic {display:flex;flex-direction:column;justify-content:center;height:auto;line-height:inherit;white-space:normal;text-overflow:initial;}
                span:not([class]) {display:block;margin-left:0;}//부서
            }
        }
    }
    //타입13-3
    .data-tbl7.t2 {border-collapse:collapse;border-spacing:0;margin-top:6px;}

    /* 타입14 */
    .data-tbl16 {border-collapse:separate;border-radius:4px;border:1px solid #e6e6e6;
        th, td {height:17px;padding:16px 10px;border:none;letter-spacing:normal;
            &:not(:first-child) {border-left:1px solid #e6e6e6;}
        }
        thead {
            th {background:#f8f9fa !important;text-align:center;font-weight:normal;
                &.total {font-weight:bold;}
            }
            tr:first-child {
                th:first-child {border-top-left-radius:4px;}
                th:last-child {border-top-right-radius:4px;}
            }
        }
        tbody {
            th, td {border-top:1px solid #e6e6e6;}
            th {background:#f8f9fa;text-align:right;font-weight:normal;}
            td {font-weight:bold;}
            tr:last-child {
                th:first-child, td:first-child {border-bottom-left-radius:4px;}
                th:last-child, td:last-child {border-bottom-right-radius:4px;}
            }
        }
        //말줄임해제
        &.t1 {
            th, td {white-space:normal;text-overflow:inherit;}
        }
    }

    /*----------------------------------------------------
        [테이블스크롤 : thead고정 / tbody스크롤]//----------------- 미사용 확인 후 삭제
    ----------------------------------------------------*/
    /* 타입1/타입2 공통 */
    .tbl-scroll-wrap1, .tbl-scroll-wrap2 {position:relative;height:100% !important;border-top:2px solid #666;box-sizing:border-box;
        &:before {content:'';position:absolute;right:0;top:0;width:18px;border-bottom:1px solid #e6e6e6;background:#fafafa;z-index:1;}
        .tbl-scroll {overflow-y:auto;overflow-x:hidden;height:100%;
            table thead th {width:50%;height:0;padding:0;border-bottom:none;
                .th-text {position:absolute;top:0;width:inherit;border-bottom:1px solid #e6e6e6;background:#fafafa;line-height:32px;}
                &:not(:first-child) .th-text {margin-left:-1px;border-left:1px solid #e6e6e6;}
            }
        }
    }

    /* 타입1 */
    .tbl-scroll-wrap1{padding-top:33px;
        &:before {height:32px;}
        &:after {content:'';position:absolute;left:0;right:0;bottom:0;width:100%;height:1px;border-top:1px solid #e6e6e6;z-index:1;box-sizing:border-box;}
        .tbl-scroll {
            &:before, &:after {content:'';position:absolute;top:0;bottom:0;width:1px;background:#e6e6e6;z-index:1;}
            &:before {left:0;}
            &:after {right:0;}
            table {border:none;
                thead th .th-text {line-height:32px;}
            }
        }
    }

    /* 타입2 */
    .tbl-scroll-wrap2 {padding-top:41px;
        &:before {height:40px;}
        .tbl-scroll table {border-top:none;
            thead th .th-text {line-height:40px;}
        }
    }

    /*----------------------------------------------------
        [폼테이블 : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 공통 */
    [data-orbit-component="OBTFormPanel"] {border-collapse:collapse;border-bottom:none;box-sizing:border-box;
        tr {border-left:1px solid transparent;border-right:1px solid transparent;
            &.on {border-color:#1c90fb;
                th, td {position:relative;background-color:rgba(28, 144, 251, 0.07);border-bottom-color:#1c90fb !important;
                    &:before {content:'';position:absolute;top:-1px;left:-1px;display:inline-block;width:calc(100% + 2px);height:1px;background:#1c90fb;}
                }
            }
            &:last-child {
                > th, > td {border-bottom:1px solid #e6e6e6;}
            }
        }
        th {padding-left:9px;letter-spacing:-0.6px;text-align:right;
            //OBTTooltip
            [class*="OBTTooltip_contents"] {font-weight:normal;}
            [data-orbit-component="OBTTooltip"] {margin-right:4px;
                > img {margin-top:2px;}
            }
            //텍스트 + 상태(오른쪽끝 고정) + 버튼(오른쪽끝 고정)
            &.t1 > div {text-align:left;
                > div:first-child {flex:1;padding-right:10px;
                    > span {color:#1c90fb;text-decoration:underline;cursor:pointer;}
                }
                > [class*="state-type"]:not(:last-child) {margin-right:2px;}
                > div:not(:first-child) {font-weight:normal;}
            }
        }
        td {padding-right:7px;
            > div {//OBTFormPanel_contentsWrapper
                .dash {display:inline-block;width:14px;text-align:center;vertical-align:middle;}//~표시
                .at {display:inline-block;width:20px;text-align:center;vertical-align:middle;}//@표시
                .unit {padding-left:3px;}//단위
                > div {
                    &:not(.btn-chips) {box-sizing:border-box;}
                    & ~ div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not([id^="gridWrapper"]):not([data-orbit-component="OBTTooltip"]) {margin-left:4px;vertical-align:middle;}
                    > div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {vertical-align:middle;}
                    & + [data-orbit-component="OBTTooltip"] {margin-left:5px;//OBTTooltip
                        > img {margin-top:2px;}
                    }
                    & + [data-orbit-component="OBTButton"] > .img-btn1 {margin-left:3px;}//아이콘버튼(테두리X)
                }
                .dash, .at {
                    & + div {margin-left:0 !important;}
                }
                span:not([class^="ti"]):not([class^="dash"]):not([class^="at"]), .unit {
                    & + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not([data-orbit-component="OBTTooltip"]) {margin-left:10px !important;}//설명문구/단위 + div
                    & + [data-orbit-component="OBTTooltip"] {margin-left:5px;//OBTTooltip
                        > img {margin-top:2px;}
                    }
                    & + [data-orbit-component="OBTButton"] > .img-btn1 {margin-left:-5px;}//설명문구/단위 + 아이콘버튼(테두리X)
                }
                //버튼
                > [data-orbit-component="OBTButton"] {white-space:nowrap;}
                //타이틀 + input
                [class^="ti"] {display:inline-block;min-width:60px;padding-right:5px;text-align:right;box-sizing:border-box;//고정사이즈
                    & + div {margin-left:0 !important;}
                }
                //설명문구
                > span:not(:first-child):not(.dash):not(.at) {margin-left:10px;}
                //OBTCodePicker
                [data-orbit-component="OBTCodePicker"] > div {vertical-align:top;}//IE
                //체크박스 + input / input + 체크박스
                [data-orbit-component="OBTCheckBox"] + div:not([data-orbit-component="OBTCheckBox"]),
                div:not([data-orbit-component="OBTCheckBox"]) + [data-orbit-component="OBTCheckBox"] {margin-left:10px !important;}
				[data-orbit-component="OBTCheckBox"] + [data-orbit-component="OBTTooltip"]:not(.noclass) {margin-left:5px !important;}//noclass : 우선순위를 위해 설정
                //라디오버튼 + input / input(첫번째) + 라디오버튼
                [data-orbit-component="OBTRadioButtonGroup"] + div:not([data-orbit-component="OBTRadioButtonGroup"]),
                div:not([data-orbit-component="OBTRadioButtonGroup"]):first-child + [data-orbit-component="OBTRadioButtonGroup"] {margin-left:10px !important;}
				[data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTTooltip"]:not(.noclass) {margin-left:5px !important;}//noclass : 우선순위를 위해 설정
                //라디오버튼 + 라디오버튼 / input + 라디오버튼 / 단위(명, %, 이상, 이하 등) + 라디오버튼
                [data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTRadioButtonGroup"],
                div:not([data-orbit-component="OBTRadioButtonGroup"]) + [data-orbit-component="OBTRadioButtonGroup"],
                .unit + [data-orbit-component="OBTRadioButtonGroup"] {margin-left:20px !important;}
                //OBTChips + 버튼
                [data-orbit-component="OBTChips"] + [data-orbit-component="OBTButton"] {align-self:flex-start;}
            }
            //설명문구 세로 정렬
            &.align-v > div {flex-direction:column;align-items:flex-start;
                > div {width:100%;
                    > [data-orbit-component="OBTButton"] {white-space:nowrap;}//버튼
                }
                > div:not([class]) {display:flex;align-items:center;
                    //설명문구
                    > span:not(:first-child):not(.dash) {margin-left:10px;}
                    //textinput + textinput
                    > div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {margin-left:4px;}
                }
                > div, > span, > table, > ul, > ol, > dl {
                    &:not(:first-child) {margin:5px 0 0 !important;}
                }
                //라디오버튼(세로형)
                > [data-orbit-component="OBTRadioButtonGroup"] > div > span + span:not([style*="-block"]) {margin-top:3px;}
                //라디오버튼(세로형 / 라디오버튼 분리) + 체크박스
                > div.t1 {margin-top:3px !important;
                    > [data-orbit-component="OBTRadioButtonGroup"] {vertical-align:top;}
                }
                //체크박스
                > div {
                    [data-orbit-component="OBTCheckBox"] + [data-orbit-component="OBTCheckBox"] {margin-left:20px;}
                }
            }
            //td height 100%
            &.height-100p {height:100%;box-sizing:border-box;}
            //테이블내 헤딩
            .heading {display:flex;align-items:center;width:100%;height:27px;margin-bottom:1px;
                > span {flex:1;}
                > .btn {max-height:27px;
                    > div:not(:first-child) {margin-left:4px;}
                }
            }
            //텍스트 + 상태 + 버튼
            &.t1 > div {
                > div:first-child {flex:1;padding-right:10px;
                    > span {color:#1c90fb;text-decoration:underline;cursor:pointer;}
                }
                > [class*="state-type"]:not(:last-child) {margin-right:2px;}
            }
            //input + button
            &.t2 {
                > div {position:relative;
                    > div {
                        > div > div > input {padding-right:27px !important;}
                        & + div {position:absolute;top:1px;right:1px;}
                    }
                }
            }
            //text + bar + text
            &.t3 {
                > div {
                    ul {display:flex;
                        li {
                            &:not(:first-child) {position:relative;margin-left:10px;padding-left:10px;
                                &:before {content:'';position:absolute;top:50%;left:0;width:1px;height:14px;margin-top:-7px;background:#e6e6e6;}
                            }
                            span {margin-right:5px;color:#a6a6a6;}
                            em {display:inline-block;width:65px;text-align:center;}
                        }
                    }
                }
            }
            //th + td + td : td 내부 input 사이즈 위치 및 사이즈 설정
            &.t4 {
                > div {
                    > div:first-child:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {margin-left:-11px;
                        &[data-orbit-component="OBTTextField"]:only-child {width:calc(100% + 11px) !important;}
                    }
                    > [data-orbit-component="OBTCheckBox"], > [data-orbit-component="OBTRadioButtonGroup"] {margin-left:-5px;}//checkbox / radio
                    > span:first-child {margin-left:-6px}//가이드문구
                }
            }
            //td 영역 균등분할 멀티 컨텐츠
            &.t5 {
                > div {
                    > div:first-child {display:inline-flex;align-items:center;width:calc(100% - 37px);
                        > div {display:flex;flex:1;align-items:center;justify-content:flex-end;
                            > span, > div {
                                &:first-child {flex:1;text-align:right;}
                            }
                            > span + [data-orbit-component="OBTNumberField"] {width:70px;}
                        }
                        > div:not(:only-child):first-child {flex:none;width:250px;}
                        > div + div {margin-left:10px;}
                    }
                    > [data-orbit-component="OBTButton"] {margin-left:10px !important;}
                }
            }
            //색상박스
            .color-box {overflow:hidden;width:50px;height:0;padding-top:25px;border:1px solid #ccc;background:#fff;}
        }
        //구분라인
        tr.line {
            > th, > td {border-bottom:1px solid #a6a6a6 !important;}
        }
        th.line, td.line {border-left:1px solid #e6e6e6;
            &[rowspan] {border-left:none;border-right:1px solid #e6e6e6;}
        }
        //타이틀
        .ti {
            th {
                > div {justify-content:flex-start !important;text-align:left;
                    > .info {display:flex;flex:1;//정보 / 타이틀
                        > span {display:inline-block;padding-left:10px;color:#1c90fb;font-weight:normal;}//왼쪽 보조문구(ex.계산식)
                        > em {display:inline-block;flex:1;color:#1c90fb;text-align:right;}//오른쪽 보조문구(ex.총주유비)
                    }
                    > .btn {margin-left:6px;//버튼
                        > div {margin-left:4px;}
                    }
                }
            }
        }
        //합계
        .total {
            th, td {background:#fff1d6;}
            th {
                > div {justify-content:center !important;}
            }
        }
        //정렬
        .al {
            > div {justify-content:flex-start !important;}
        }
        .ac {
            > div {justify-content:center !important;}
        }
        .ar {
            > div {justify-content:flex-end !important;}
        }
        //배경색상(파랑)
        .bg1 {background:#f2f6f8;}
        .bg2 {background:#deebf2;}
        //합계배경색상(노랑)
        .total {background:#fff1d6;}//합계
        .total2 {background:#fffceb;}//소계
        .total3 {background:#fff7e0;}//중계
    }

    /* 타입1 */
    .form-tbl {
        td {
            input,
            [data-orbit-component="OBTDropDownList"] span {font-weight:bold;}
        }
    }

    /* 타입2 */
    .form-tbl1 {
        th:not(:first-child), td:not(:first-child) {border-left:1px solid #e6e6e6;}
        thead {
            th {height:27px;padding:6px 8px 7px;border-bottom:1px solid #e6e6e6;background:#fafafa;text-align:center;}
            tr:not(:first-child) th {font-weight:normal;}
        }
        tbody {
            th, td {
                > div {justify-content:center;
                    [data-orbit-component="OBTCheckBox"]:only-child {width:auto !important;
                        &.nolabel {width:14px !important;}
                    }
                }
            }
        }
        //on
        .on {
            > th, > td {background:#eff7ff;}
        }
    }

    /* 타입3 */
    .form-tbl2 {border:none;
        > tbody {
            > tr {height:31px;
                > th, > td {height:27px;padding:2px 6px !important;border:none !important;}
                > th {padding-right:0 !important;background:#fff;}
                > td > div {//OBTFormPanel_contentsWrapper
                    //input, button
                    > div button > div {
                        > span {margin-top:-2px;}
                    }
                    //도움말아이콘
                    > .help {height:auto;margin-left:5px !important;cursor:pointer;}
                }
                &:first-child {
                    th, td {padding-top:14px !important;}
                }
                &:last-child {
                    th, td {padding-bottom:14px !important;}
                }
            }
        }
    }

    /* 타입4 */
    .form-tbl3 {width:100%;border:1px solid #e6e6e6;border-top:2px solid #666;
        tr {height:33px;border:none;
            &:not(:first-child) > th {border-top:1px double #a6a6a6;}
        }
        th, td {border:1px solid #e6e6e6;}
        th {padding:7px 8px;text-align:center;
            > [class*="OBTFormPanel_contentsWrapper"] {display:block;}
        }
        td {padding:0;
            //input
            [data-orbit-component] > div > div {height:32px !important;padding:0 !important;border:none !important;line-height:32px !important;
                input {height:100%;padding:0 8px !important;box-sizing:border-box;}
            }
            //OBTDatePicker
            [data-orbit-component="OBTDatePicker"] > div:first-child {width:100% !important;
                [class*="OBTDatePickerRebuild_dateInput"] {padding-right:0 !important;}
                [class*="OBTDatePickerRebuild_inputY"] {width:36px !important;}
                [class*="OBTDatePickerRebuild_inputYM"] {width:53px !important;}
                [class*="OBTDatePickerRebuild_inputYMD"] {width:70px !important;}
            }
            //그리드
            &.grid {overflow:hidden;
                > [class*="OBTFormPanel_contentsWrapper"] {flex-direction:column;align-items:flex-start;width:calc(100% + 2px);
                    [id^="gridWrapper"] {border:none;
                        & + [id^="gridWrapper"]:after {content:'';position:absolute;left:0;top:-1px;width:100%;height:1px;background:#e6e6e6;}
                    }
                }
            }
        }
    }

    /* 타입5 */
    .form-tbl4 {padding:20px 18px;border-radius:4px;border:1px solid #e6e6e6;box-sizing:border-box;
        > div {height:100%;}//심플스크롤
        [data-orbit-component="OBTFormPanel"] {border:none;
            > thead {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
            > tbody {
                > tr {height:27px;border:none;
                    > td {height:27px;padding:0px 2px;border:none !important;
                        &.line {overflow:hidden;padding-left:39px;
                            > [class*="OBTFormPanel_contentsWrapper"] {position:relative;
                                &:before {content:'';position:absolute;left:-21px;top:-8px;bottom:0;width:1px;background:#e6e6e6;}
                            }
                        }
                    }
                    &:not(:first-child) > td {padding-top:8px;}
                }
            }
        }
    }

    /* 타입6 */
    .report-tbl1 {width:100%;border:1px solid #e6e6e6;border-top:2px solid #666;
        tr {height:33px;border:none;}//컴포넌트초기화
        th, td {padding:7px 8px;border:1px solid #e6e6e6;background:#fff;
            //수정전
            &.before-area {border-left-style:double;border-left-color:#a3d2ff;}
            &.before-area + .before-area {border-left-color:#e6e6e6;}
            //수정후
            &.after-area {border-left-style:double;border-left-color:#ffacac;}
            &.after-area + .after-area {border-left-color:#e6e6e6;}
            //컴포넌트 재설정
            > [class*="OBTFormPanel_contentsWrapper"] {overflow:hidden;display:block;white-space:nowrap;text-overflow:ellipsis;}
            //┗ 하위 아이콘
            &.ico-sub > div:before {content:'';display:inline-block;width:7px;height:7px;margin:4px 3px 0 5px;border:solid #000;border-width:0 0 2px 2px;vertical-align:top;box-sizing:border-box;}
            //코드피커 아이콘
            &.ico-code {padding-left:4px;cursor:pointer;
                &:after {content:'';display:block;float:left;width:18px;height:18px;background:url('../imgs/ico/ic_code_m_normal.png') no-repeat;}
                > [class*="OBTFormPanel_contentsWrapper"] {float:right;width:calc(100% - 22px);}
            }
        }
        th > [class*="OBTFormPanel_contentsWrapper"] {white-space:normal;text-overflow: initial;}
        > thead, > .thead-area {
            th {background:#fafafa;text-align:center;font-weight:bold;}
            tr.ti-area1 {
                > .before-area {border-color:#a3d2ff;background:#a3d2ff;}//수정전
                > .after-area {border-color:#ffacac;background:#ffacac;}//수정후
            }
        }
        > tbody, > tfoot {
            th {text-align:left;font-weight:normal;
                &[class*="ti"] {text-align:center;}
                &[class*="ti-type"] {padding:7px 0px;border-left-width:1px;font-weight:bold;}//th 색상변경
                &.ti-type1 {border-left-color:#fc5356;box-shadow:inset 1px 0px 0 0 #fc5356;background:#fff1f1;color:#fc5356 !important;}//과세표준매출세액 / 근로소득 / 근로
                &.ti-type2 {border-left-color:#1c90fb;box-shadow:inset 1px 0px 0 0 #1c90fb;background:#eaf2fd;color:#1c90fb !important;}//매입세액 / 사업소득 / 사업
                &.ti-type3 {border-left-color:#2baabe;box-shadow:inset 1px 0px 0 0 #2baabe;background:#e2f4f7;color:#2baabe !important;}//경감/공제세액 / 기타소득 / 기타
                &.ti-type4 {border-left-color:#e6a000;box-shadow:inset 1px 0px 0 0 #e6a000;background:#fcf6e8;color:#e6a000 !important;}//퇴직소득
                &.ti-type5 {border-left-color:#63b44b;box-shadow:inset 1px 0px 0 0 #63b44b;background:#edf7ea;color:#63b44b !important;}//연금소득
                &.ti-type6 {border-left-color:#ff7a34;box-shadow:inset 1px 0px 0 0 #ff7a34;background:#fdf3eb;color:#ff7a34 !important;}//이자배당소득/이자
                &.ti-type7 {border-left-color:#a87ad8;box-shadow:inset 1px 0px 0 0 #a87ad8;background:#f9f1ff;color:#a87ad8 !important;}//해지추징세액등
                &.ti-type8 {border-left-color:#7785f0;box-shadow:inset 1px 0px 0 0 #7785f0;background:#f0f2fa;color:#7785f0 !important;}//양도
                &.ti-type9 {border-left-color:#43b8e3;box-shadow:inset 1px 0px 0 0 #43b8e3;background:#edf8fc;color:#43b8e3 !important;}//내국법인
                &.ti-type10 {border-left-color:#d37cc6;box-shadow:inset 1px 0px 0 0 #d37cc6;background:#faf2f9;color:#d37cc6 !important;}//외국법인
            }
            td {text-align:center;
                //클래스명이 없는 td(순서)
                &:not([class]) {padding:7px 0px;}
                //비활성화모드
                &.empty-mode {background:#f5f5f5 !important;}
                //수정후모드(빨간색)
                &.modify-mode {color:#fc5356;}
                //데이터모드(DB연동데이터(고정데이터X))
                &.data-mode {text-align:right;}
            }
            //데이터모드(DB연동데이터(고정데이터X)) 예외 설정
            tr:not(.sum-area1):not(.sum-area2):not(.etc-area1):not(.etc-area2) {
                td.data-mode:not(.before-area):not(.empty-mode):not(.focus-mode):hover {background:#c0e4ff;box-shadow:inset 0 0 0 1px #fff;}
            }
            //합계1 : 합계
            tr.sum-area1 {
                th, td {background:#fff1d6;font-weight:bold;}
                th {text-align:center;}
            }
            //합계2 : 소계
            tr.sum-area2 {
                th, td {background:#fffceb;}
                th {text-align:center;}
            }
            //기타1
            tr.etc-area1 {
                th, td {border-top:1px double #f5d3b6;border-bottom-color:#f5d3b6 !important;background:#fffad5;font-weight:bold;}
                th {text-align:center;}
                //타입1
                &.t1 {
                    th, td {border-top-color:#a6a6a6;}
                    th {text-align:left;}
                }
            }
            //기타
            tr.etc-area2 {
                th, td {background:#f3fde2;font-weight:bold;}
                th {text-align:center;}
            }
            //포커스모드
            tr.focus-mode {
                th:not([class*="ti"]), td {background:#eff7ff;}
                //td포커스모드
                td.focus-mode {background:#c0e4ff !important;box-shadow:inset 0 0 0 1px #1c90fb;}
                //td포커스모드 + 수정모드(input생성)
                td.edit-mode {padding:1px;background:#fff !important;
                    .edit {vertical-align:top;//input(컴포넌트초기화)
                        > div > div {height:30px !important;padding:0 !important;border:none !important;line-height:30px !important;
                            input {padding:0 8px !important;text-align:right !important;box-sizing:border-box;}
                        }
                    }
                }
                //데이터모드(DB연동데이터(고정데이터X)) 예외 설정
                &:not(.sum-area1):not(.sum-area2):not(.etc-area1):not(.etc-area2) {
                    td.data-mode:not(.before-area):not(.empty-mode):not(.focus-mode):hover {box-shadow:inset 0 0 0 1px #eff7ff;}
                }
            }
        }
    }

    /*----------------------------------------------------
        [다국어 등록/뷰]
    ----------------------------------------------------*/
    .languages-area {position:relative;overflow:inherit !important;
        //페이지(등록 / 주소 : 컴포넌트 폼패널)
        &.align-v > div > div:first-child > div:nth-child(2) {flex:1;}//우편번호 + 버튼
        //페이지(뷰 : 정보테이블)
        &.addr-area {
            > div.t1 > div:first-child > div + div {margin-top:5px;}
        }
        //팝업(등록 / 뷰 / 이름 / 주소)
        .box {position:absolute;top:40px;left:8px;margin-left:0 !important;width:calc(100% - 15px) !important;padding:10px 10px 5px 10px;border:1px solid #a6a6a6;background:#fff;z-index:100;box-sizing:border-box;
            //안내문구
            > p {margin-bottom:10px;}
            //이름 / 주소
            > div, > ul > li {margin-bottom:5px;vertical-align:top;}
            //이름
            > ul > li {display:flex;align-items:center;
                > em {width:48px;padding-right:8px;font-weight:bold;text-align:right;box-sizing:border-box;}//타이틀
            }
            //주소
            > div > div {vertical-align:top;
                & + div {margin-left:2px;}
            }
        }
        //팝업(등록 / 주소)
        &.align-v .box {top:33px;}
    }

    /*----------------------------------------------------
        [아코디언 : 컴포넌트 미사용]
    ----------------------------------------------------*/
    /* 타입1 */
    .accordion-area1 {border-top:2px solid #666;font-size:13px;text-align:center;
        /* 공통 */
        dt {display:flex;
            //width 설정
            > :nth-child(1) {width:38px;padding:0;}
            > :nth-child(2) {width:13%;}
            > :nth-child(3) {width:20%;}
            > :nth-child(4) {flex:1;}
            > :nth-child(5) {width:15%;}
            > :nth-child(6) {width:13%;}
            > div, > span {overflow:hidden;padding:0 10px;text-overflow:ellipsis;white-space:nowrap;box-sizing:border-box;}
        }
        dt, dd {box-sizing:border-box;
            //nolabel
            .nolabel {margin-top:-2px;}
        }
        /* 타이틀 */
        > dt {height:46px;line-height:46px;font-weight:bold;
            > span:not(:first-child), > div:not(:first-child) {border-left:1px solid #e6e6e6;}
        }
        /* 리스트 */
        > dd {position:relative;border:solid #e6e6e6;border-width:1px 0;
            & + dd{margin-top:-1px;}
            //hover / on
            &:not(.no_data):hover:after,
            &:not(.no_data).on:after {content:'';position:absolute;left:0;right:0;top:-1px;bottom:-1px;border:1px solid #1c90fb;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);z-index:2;}
            //아코디언 컨텐츠
            dl {position:relative;z-index:3;
                //타이틀
                dt {height:46px;line-height:46px;
                    > :nth-child(6) {width:9%;}
                    > :nth-child(7) {width:4%;padding:0;
                        > div {vertical-align:middle;}
                    }
                }
                //내용
                dd {display:none;margin:1px;padding:14px 20px;background:#f1f6fa;
                    &.open {display:block;}
                }
            }
            //emptyData
            &.no_data {padding-bottom:20px;padding-top:20px;}
        }
    }

    /*----------------------------------------------------
        [카드리스트 : 컴포넌트 수정]
    ----------------------------------------------------*/
    /* 타입1(기본) */
    .card-list1 {
        //타입1 : 카드리스트 + 페이징
        &.t1 {height:calc(100% - 4px) !important;}
        //타입2
        &.t2 {border-top:none;
            > div {border:none;
                //header
                [class*="OBTCardList_sortstyle"],
                [class*="OBTCardList_headLine"],
                .headLine {display:none;}
                //리스트
                > [class*="OBTCardList_virtualCardList"] > div {background:none;
                    .selectedBorder {inset:-1px !important;}//선택 라인
                }
            }
        }
        //header
        > div {
            //only headerComponent
            [class*="OBTCardList_isOnlyHeaderComponent"] {padding-top:9px;padding-bottom:18px;
                & + .headLine {width:100%;height:10px;margin-top:-9px;background-color:#fafafa;//하단 라인 생성
                    > div {position:absolute;height:1px;background-color:#e0e0e0;}
                }
            }
        }
        //카드리스트
        > div > div {
            //증가/하락
            .up, .down {color:#4a4a4a !important;}
            //Direct 카드리스트
            [class*="tbl-td"] {overflow:hidden;display:inline-flex;flex-direction:column;justify-content:center;height:100%;font-size:13px;color:#000;letter-spacing:-0.33px;vertical-align:middle;box-sizing:border-box;
                > div:not([data-orbit-component="OBTTooltip"]) {line-height:1.5;}
                //버튼(:hover)
                button[class*="OBTButton_typeicon"]:hover {background:none;}
                //사진
                &.pic {flex-direction:row;justify-content:flex-start;align-items:center;
                    > div {width:auto;font-size:13px !important;color:#000 !important;letter-spacing:-0.33px !important;
                        &:first-child .sub1 {font-size:12px;letter-spacing:0;}//타이틀영역(서브정보)
                    }
                    > div:nth-child(2) {padding-left:10px;
                        > div:nth-child(2) {font-size:12px;color:#a6a6a6;letter-spacing:0;}//정보영역(두번째줄)
                    }
                }
                //ico
                &.ico {flex-direction:row;justify-content:flex-start;align-items:center;
                    > div {width:auto;font-size:13px !important;color:#000 !important;letter-spacing:-0.33px !important;
                        &:first-child .sub1 {font-size:12px;letter-spacing:0;}//타이틀영역(서브정보)
                    }
                    > div:nth-child(2) {padding-left:10px;
                        > div:nth-child(2) {font-size:12px;color:#a6a6a6;letter-spacing:0;}//정보영역(두번째줄)
                    }
                    //아이콘
                    [class*="ico-type"] {overflow:hidden;display:inline-flex;justify-content:center;align-items:center;vertical-align:top;box-sizing:border-box;}
                    //32*32
                    .ico-type1 {width:32px;height:32px;border-radius:50%;background-color:#e8f1f9;}
                    //30*30
                    .ico-type2 {width:30px;height:30px;}
                }
            }
            .tbl-tdl {flex:1;
                &.p100 {width:100%;}//only 1개 항목
                //순위(number)
                .order {position:relative;display:inline-block;width:100%;font-size:13px;color:#1c90fb;font-weight:bold;letter-spacing:-0.33px;vertical-align:middle;
                    &:after {content:'';position:absolute;top:50%;right:0;transform:translateY(-50%);width:1px;height:20px;background-color:#e6e6e6;}
                }
            }
            .tbl-tdc:not(:first-child) {padding-left:10px;}
			.selectedBorder + .tbl-tdc {padding-left:0;}//선택시
            .tbl-tdl > div, .tbl-tdc > div {
                &:first-child .sub1 {font-size:12px;letter-spacing:0;}//타이틀영역(서브정보)
                &:not(:first-child) {font-size:12px;color:#a6a6a6;letter-spacing:0;}//정보영역(두번째줄)
            }
            .tbl-tdr {align-items:flex-end;width:40%;padding-left:10px;font-size:12px;color:#4a4a4a;letter-spacing:0;text-align:right;
                > div {width:100%;}
                &.pic {justify-content:flex-end;}
				> [class*="state-type"]:not(:first-child) {margin-top:1px;}
            }
            //선택리스트
            .selectedList {
                //ico
                [class*="tbl-td"].ico {
                    //32*32
                    .ico-type1 {background-color:#c4e4ff;}
                }
            }
        }
    }

    /* 타입2 */
    .card-list2 {
         > div {border-width:0 0 1px 0;background:#f5f8fa;
            //카드리스트(OBTCardList) 선택(OBTCheckBox)
            [data-orbit-component="OBTCheckBox"] {width:18px;
                svg {width:18px !important;height:18px !important;margin-top:-9px !important;background:transparent url('../imgs/ico/ic_check_02_m_disable.png') no-repeat !important;box-sizing:border-box;
                    path, polygon {display:none;}
                }
                .unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"],//전체선택
                &.unCheckedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_02_m_focus.png') !important;}//단일선택 / focus
                //체크 이미지 아래 투명 채우기
                label:before {content:'';position:absolute;left:0;top:50%;width:18px;height:18px;margin-top:-9px;border-radius:50%;background:#fff;}
                //checked
                svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_m_over.png') !important;}
                .checkedFocus svg[class*="UFOCheckBox_focusCheckBox"],//전체선택
                &.checkedFocus svg[class*="UFOCheckBox_focusCheckBox"] {background-image:url('../imgs/ico/ic_check_03_m_select_focus.png') !important;}//단일선택 / focus
            }
            //header
            .header {height:auto !important;margin:20px 20px 7px 20px;padding:0 10px;border-radius:4px;background:#ecf1f5;
                //카드리스트(OBTCardList) 선택(OBTCheckBox)
                [data-orbit-component="OBTCheckBox"] {padding:0 10px;}
                //타이틀
                .tbl {display:flex;align-items:center;padding:7px 0;line-height:1.33;color:#4a4a4a;letter-spacing:-0.6px;text-align:center;
                    > div {padding:0 10px;box-sizing:border-box;
                        //선택(OBTRadioButton)
                        &.check {width:38px;}
                        //타이틀
                        &.ti {flex:1;}
                        //체크박스, 타이틀 이외 영역
                        &:not(.check):not(.ti) {padding-left:11px;}
                    }
                }
            }
            //컴포넌트 구분라인
            .headLine,
            .header + div {display:none;}
            //리스트
            > div:nth-child(3) > div {bottom:17px;background:none;
                [class*="OBTCardList_li_CardList"] > div {padding:0 9px;border-color:#e6e6e6 !important;
                    //Over
                    &:hover {border-color:#1c90fb !important;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);}
                    //카드리스트(OBTCardList) 선택(OBTCheckBox)
                    [class*="OBTCardList_checkstyle"] {padding:0 10px;
                        & + .pic {padding-left:0;}//OBTCheckBox + 사진
                    }
                    //Direct 카드리스트
                    [class*="tbl-td"] {overflow:hidden;display:inline-flex;flex-direction:column;justify-content:center;height:100%;padding:0 10px;font-size:12px;color:#000;letter-spacing:-0.33px;text-align:center;vertical-align:middle;box-sizing:border-box;
                        > div:not([data-orbit-component="OBTTooltip"]) {line-height:1.33;
                            &:nth-child(2) {margin-top:4px;}
                        }
                        //선택(OBTRadioButton)
                        &.check {width:38px;z-index:1;}
                        //타이틀
                        &.ti {flex:1;text-align:left;
                            > div:nth-child(1) {font-weight:bold;}
                            > div:nth-child(2) {font-size:11px;color:#8c8c8c;}
                            //사진
                            &.pic {flex-direction:row;justify-content:flex-start;align-items:center;
                                [class*="pic-type1"] {margin-right:10px;}
                                > strong {flex:1;font-weight:normal;}
                            }
                        }
                        //체크박스, 타이틀 이외 영역
                        &:not(.check):not(.ti) {position:relative;padding-left:11px;
                            &:before {content:'';position:absolute;left:0;top:50%;width:1px;height:16px;margin-top:-8px;background:#e6e6e6;}
                            > div > span {margin-right:10px;}
                        }
                    }
                    //Select
                    &.selectedList {background:#eff7ff !important;
                        .selectedBorder {margin:-1px;box-shadow:none;}
                    }
                }
            }
        }
    }

    /* 컨텐츠(검색 / 정보 등) + 카드리스트 + 추가버튼(카드리스트 버튼 미사용) */
    .card-list-area {overflow:hidden;display:flex;flex-direction:column;
        //검색영역
        .condition-area1 {padding:8px 12px;border:solid #e6e6e6;border-top-color:#666;border-width:2px 1px 0 1px;background:#fafafa;box-sizing:border-box;
            //OBTTextField + OBTButton
            > div[data-orbit-component="OBTTextField"] {width:calc(100% - 31px);vertical-align:top;}
            > div[data-orbit-component="OBTButton"] {margin-left:4px;vertical-align:top;}
            //1줄 항목 2개이상 / 검색영역 2줄이상
            > div:not([data-orbit-component]) {display:flex;align-items:center;
                &:not(:first-child) {margin-top:4px;}
                > div:not(:first-child) {margin-left:4px;}
                //타이틀 + 라디오버튼(체크박스)
                > .ti {height:20px;margin:0 10px 2px 0;line-height:20px;}
                > .ti + div {flex:1;margin-bottom:2px;text-align:right;
                    > div > span:last-child {margin-right:0 !important;}//라디오버튼
                }
            }
        }
        //카드리스트
        .card-list1 {flex-grow:1;height:auto !important;
            &:not(:first-child) {border-top:none;}
        }
        //추가버튼(카드리스트 버튼 미사용)
        .btn-add {position:relative;border:1px solid #e6e6e6;border-top:1px solid #ccc;margin-top:-1px;border-radius:0;width:100%;height:64px;padding:0;background:#fff !important;box-sizing:border-box;
            > div {width:100%;height:100%;box-sizing:border-box;
                > span {user-select:none;font-size:14px !important;color:#8d8d8d;}
            }
            &.on {
                > div {border:1px solid #1c90fb;background:#e6f5ff;}
            }
            &:hover {border:1px solid #e6e6e6 !important;border-top:1px solid #ccc !important;}
        }
    }

    /*----------------------------------------------------
        [트리뷰]
    ----------------------------------------------------*/
    /* 검색 + 트리뷰 */
    .tree-view-area {overflow:hidden;display:flex;flex-direction:column;
        //검색영역
        .condition-area1 {padding:13px;border:solid #ccc;border-width:1px 1px 0;box-sizing:border-box;
            //검색영역 1줄 : input + button
            > div[data-orbit-component="OBTTextField"] {width:calc(100% - 31px);vertical-align:top;}
            > div[data-orbit-component="OBTButton"] {margin-left:4px;vertical-align:top;}
        }
        //트리뷰
        .condition-area1 + div {flex-grow:1;}
    }

    /*----------------------------------------------------
        [그래프]
    ----------------------------------------------------*/
    /* 타입1(기본) */
    .data-graph1 {overflow:hidden;display:flex;flex-direction:column;height:100%;border:1px solid #e0e0e0;border-radius:4px;box-sizing:border-box;
        //타입1
        &.t1 {border-color:#e6e6e6;border-radius:0;}
        //타입2
        &.t2:not([class*="wrap-content"]) {border:none;border-radius:0;
            > .heading {padding:0 0 7px !important;}
            > .graph {padding:0 0 15px !important;
                &:only-child, &:last-child {padding:0 !important;}
            }
            .info4 {padding:0;}
        }

        /* 그래프영역 */
        //그래프영역 그룹
        .area1 {display:flex;flex-direction:column;background:#eff8fb;box-sizing:border-box;}
        //헤딩
        .heading {width:100%;padding:10px 15px 7px;text-align:center;box-sizing:border-box;
            strong {display:block;font-size:16px;color:#000;letter-spacing:-0.8px;font-weight:normal;}
        }
        //정보(dl / ul)
        [class*="info"] {position:relative;color:#4a4a4a;
            dt {
                em {display:inline-block;height:18px;padding:0 9px;border-radius:9px;background:#697489;font-size:11px;line-height:18px;color:#fff;letter-spacing:-0.55px;}
            }
            dd {display:flex;
                > span {display:inline-block;width:40%;letter-spacing:-0.6px;}
                > em {display:inline-block;width:60%;font-size:11px;color:#000;letter-spacing:-0.13px;text-align:right;
                    b {font-size:13px;font-weight:normal !important;}
                }
            }
        }
        .info1 {display:table;table-layout:fixed;width:calc(100% - 30px);margin:5px 15px 10px;background:#fff;border-radius:4px;box-shadow:0 3px 5px 0 rgba(0, 1, 1, 0.08);box-sizing:border-box;
            dt {width:129px;padding:9px 20px;display:table-cell;text-align:center;vertical-align:middle;background:#fbfbfb;box-sizing:border-box;
                span {display:block;margin-top:10px;font-size:13px;letter-spacing:-0.65px;}
            }
            dd {width:100%;padding:0 20px;vertical-align:middle;box-sizing:border-box;
                &:nth-child(2) {padding-top:9px;}
                &:last-child {padding-bottom:9px;}
            }
        }
        .info2 {display:flex;padding:20px 0;background:#eff7ff;
            li {flex:1;border-right:1px solid #d5e0eb;
                dl {padding:0 30px;
                    dt {
                        span {font-size:14px;color:#000;letter-spacing:-0.7px;font-weight:bold;}
                        em {float:right;}
                    }
                    dt + dd {margin-top:7px;}
                    dd {
                        > span {width:45%;vertical-align:middle;}
                        > em {width:55%;}
                    }
                }
                &:last-child {border-right:none;}
            }
        }
        .info3 {display:flex;flex-direction:column;justify-content:center;padding:20px 50px;min-height:102px;background:#fafbfc;box-sizing:border-box;
            > li {display:flex;
                & + li {margin-top:4px;}
                > span:first-child {letter-spacing:-0.6px;color:#4a4a4a !important;
                    & + span {flex:1;min-width:30%;padding-left:10px;text-align:right;font-weight:bold;}
                    &:only-child {text-align:center;flex:1;}
                }
            }
        }
        .info4 {padding:0 15px 15px;text-align:center;letter-spacing:-0.5px;
            //범례
            > em {display:block;font-size:16px;color:#1c90fb;
                > b {font-size:20px;}
            }
            //정보
            > p {margin-top:20px;color: #5c7198;}
        }
        .info5 {padding:17px 16px;background-color:#fafbfc;
            li {display:flex;
                &:last-child {margin-bottom:0;}
                > strong {width:64px;
                    em {margin-left:4px;display:inline-block;padding:0 7px;min-height:18px;border:1px solid #a3a8b1;border-radius:9px;font-size:11px;color:#595d65;}
                }
                > dl {display:flex;flex:1;
                    dt, dd {width:25%;}
                    dt {color:#7b7b7b;
                        &:first-child {width:20%;
                            & + dd {width:30%;}
                        }
                    }
                    dd {justify-content:flex-end;color:#4a4a4a;text-align:right;padding-right:10px;
                        em {width:auto;}
                        & + dt {padding-bottom:5px;padding-left:11px;border-left:1px solid #d9d9d9;}
                        &:last-child {padding-right:0;}
                    }
                }
				&:last-child dl dd + dt {padding-bottom:0;}
            }
        }
        //graph
        .graph {flex:auto;display:flex;justify-content:center;align-items:center;min-height:1px;padding:0 15px 15px;text-align:center;
            &:only-child, &:first-child {padding:15px;}
        }
        //OBTButtonGroup
        [data-orbit-component="OBTButtonGroup"] {padding:15px 15px 10px;}

        /* 데이터영역 */
        //데이터영역 그룹
        .area2 {padding:15px;box-sizing:border-box;
            &.t1 {padding-top:5px;}
            &.t2 {padding-left:0;}
            > .ti {display:block;padding-bottom:5px;//서브타이틀
                &:not(:first-child) {margin-top:10px;}
            }
        }
    }

    /* 타입2 */
    .data-graph2 {display:flex;flex-direction:column;height:100%;border:1px solid #dddee2;border-radius:2px;box-sizing:border-box;
        //헤딩
        .heading {width:100%;background:#f8f8f8;text-align:left;
            strong {display:block;padding:0 12px;font-size:14px;line-height:38px;color:#000;letter-spacing:-0.8px;
                > span {float:right;font-size:12px;color:#4a4a4a;letter-spacing:-0.3px;font-weight:normal;
                    .ti {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                }
            }
        }
        //정보(ul)
        .info1 {position:static;padding:0 12px;color:#000;min-height:34px;border-top:2px solid #efefef;background:#f8f8f8;box-sizing:border-box;
            li {display:inline-block;height:32px;line-height:32px;
                &:not(:first-child):before {content:'';display:inline-block;width:1px;height:10px;margin:0 10px;background:#e0e0e0;vertical-align:middle;}
                .ti {color:#8c8c8c;}
            }
        }
        //graph
        .graph {flex:1;padding:20px;text-align:center;}
    }

    /* 타입3 */
    .data-graph3 {padding:10px;text-align:center;
        //graph
        .graph {width:100%;}
        //범례
        .info {margin-top:20px;
            li {display:inline-flex;align-items:center;
                &:before {content:'';margin-right:4px;width:12px;height:12px;}
                & + li {margin-left:12px;}
                //색상
                &.type1:before {background:#4eabfa;}//파랑
                &.type2:before {background:#afd873;}//연두
                &.type3:before {background:#f48da5;}//분홍
                &.type4:before {background:#f5d471;}//노랑
            }
        }
    }

    /* 타입4 */
    .data-graph4 {display:inline-flex;align-items:center;width:100%;
        //graph
        .graph {width:100px;height:100px;}
        //범례
        .info {flex:1;margin-right:30px;
            li {display:flex;align-items:center;
                &:before {content:'';width:6px;height:6px;margin-right:4px;border-radius:50%;}
                & + li {margin-top:6px;}
                > b {width:26%;color:#a6a6a6;letter-spacing:-0.3px;font-weight:normal;}
                > em {flex:1;padding-left:5px;font-size:16px;letter-spacing:-0.8px;text-align:right;font-weight:bold;
                    > .unit {margin-left:2px;font-size:12px;font-weight:normal;}
                }
                //색상
                &.type1:before {background:#4eabfa;}//파랑
                &.type2:before {background:#afd873;}//연두
                &.type4:before {background:#f5d471;}//노랑
            }
        }
    }

    /* 타입5 */
    .data-graph5 {display:flex;align-items:center;width:100%;height:100%;
        //범례
        > .info {width:50px;padding-bottom:18px;
            li {display:flex;align-items:center;
                &:before {content:'';width:12px;height:12px;margin-right:4px;}
                & + li {margin-top:20px;}
                //색상
                &.type1:before {background:#20c997;}//초록(완료)
                &.type2:before {background:#46a3f0;}//파랑(수료)
                &.type4:before {background:#e2e2e2;}//회색(미수료)
            }
        }
        //graph
        > .graph {flex:1;height:100%;margin-left:10px;
            //그래프
            ul {display:flex;flex-wrap:wrap;align-items:center;height:100%;}//심플스크롤 X / 4개이하
            > .data {position:relative;height:calc(100% - 18px);//심플스크롤 O / 5개이상
                &:before, &:after {content:'';position:absolute;left:155px;right:65px;top:0;bottom:0;width:calc(100% - 220px);height:100%;box-sizing:border-box;}
                &:before {background:repeating-linear-gradient(to right, #fff, #fff calc(20% - 1px), #e6e6e6 calc(20% - 1px), #e6e6e6 20%);}
                &:after {border:solid #e6e6e6;border-width:0 1px 0 1px;}
                li {position:relative;display:flex;align-items:center;width:100%;min-height:20px;padding:9px 0;font-size:11px;line-height:1.27;color:#4a4a4a;letter-spacing:-0.55px;text-align:right;z-index:1;
                    &:first-child {padding-top:17px;}
                    &:last-child {padding-bottom:17px;}
                    //교육명
                    > strong {overflow:hidden;width:145px;margin-right:11px;white-space:nowrap;text-overflow:ellipsis;}
                    //그래프
                    > div {flex:1;height:20px;color:#fff;background:#e5eaed;line-height:20px;
                        > span {overflow:hidden;display:block;height:20px;padding:0 10px;box-sizing:border-box;
                            > b {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                            > i {margin-left:2px;font-size:9px;letter-spacing:-0.45px;}
                            //색상
                            &.type1 {background-color:#20c997;background-image:linear-gradient(to right, #4cdb93, #20c997);}//초록(완료)
                            &.type2 {background-color:#46a3f0;background-image:linear-gradient(to right, #3cbcff, #46a3f0);}//파랑(수료)
                        }
                    }
                    //인원수
                    > span {width:55px;margin-left:11px;}
                }
            }
            //단위
            > .unit {display:flex;height:18px;padding-left:143px;color:#8c8c8c;
                > span {text-align:center;}
                > span:nth-child(1), > span:nth-child(3) {width:24px;}
                > span:nth-child(2) {flex:1;}
                > span:nth-child(4) {width:54px;font-size:11px;text-align:right;}
            }
        }
    }

    /* 타입6 : OBTLinearProgress 컴포넌트 수정 */
    .data-graph6 {padding:26px 10px;color:#8c8c8c;
        > strong {display:block;letter-spacing:-0.6px;font-weight:normal;
            > span {float:right;font-size:13px;color:#d0d0d0;font-weight:bold;
                &.on {color:#1c90fb;}
            }
        }
        > ul li {overflow:hidden;width:100%;padding-top:8px;
            > div {display:inline-block;width:calc(100% - 94px) !important;height:12px !important;margin:-1px 0 0 10px;border:none;vertical-align:middle;
                > [class*="OBTLinearProgress_empty"] {height:0;}
                > div {margin:0;
                    > div:first-child {width:calc(100% - 45px) !important;height:12px !important;border-radius:6px !important;background:#f5f5f5 !important;
                        > div {border-radius:6px !important;background-image:linear-gradient(to right, #228be6, #4dabf7);box-shadow:2px 0 4px 0 rgba(0, 0, 0, 0.12);}
                    }
                    > div:last-child {width:45px;font-size:13px;color:#000;justify-content:flex-end;}
                }
            }
            &:nth-child(2) > div > div > div > div {background-image:linear-gradient(to right, #82c91e, #a9e34b);}
            > span {float:right;font-size:13px;color:#000;vertical-align:middle;}
        }
    }

    /* 타입7 */
    .data-graph7 {overflow:hidden;display:flex;flex-direction:column;height:100%;border:1px solid #e0e0e0;border-top-width:2px;border-top-color:#3abed3;box-sizing:border-box;
        /* 그래프영역 */
        //그래프영역 그룹
        .area1 {display:flex;flex-direction:column;box-sizing:border-box;}
        //헤딩
        .heading {width:100%;padding:11px 15px;text-align:center;background-color:#f5fcfd;box-sizing:border-box;
            strong {display:block;font-size:14px;color:#000;letter-spacing:-0.7px;}
        }
        //graph
        .graph {flex:auto;display:flex;justify-content:center;align-items:center;min-height:1px;padding:20px 20px 10px 20px;text-align:center;
            &:only-child, &:first-child {padding:20px;}
        }

        /* 데이터영역 */
        //데이터영역 그룹
        .area2 {padding:0 15px;box-sizing:border-box;}

		//타입1
		&.t1 {border-top-color:#1c90fb;
			.heading {background-color:#f3f9ff;}
		}
		//타입2
		&.t2 {border-top-color:#8782fd;
			.heading {background-color:#f9f9ff;}
		}
    }

    /*----------------------------------------------------
        [박스]
    ----------------------------------------------------*/
    /* 타입1 */
    .box-area {padding:9px 10px;border:1px solid #e5e5e5;background:#fafafa;text-align:center;box-sizing:border-box;
        > div {vertical-align:middle;}
        //배경 X */
        &.t1 {background:none;}
        //타이틀 + input / 텍스트
        .ti {padding-right:6px;color:#010101;font-weight:bold;vertical-align:middle;
            & + div {display:inline-block;vertical-align:middle;}
        }
    }

    /* 타입2 */
    .box-area1 {padding:12px 20px;border:1px solid #e5e5e5;background:#fafafa;text-align:left;box-sizing:border-box;
        > div[data-orbit-component] {margin:2px 0;vertical-align:middle;}
        //배경(X)
        &.t1 {background:none;}
		//OBTScrollbar 전용 : 배경(X) + 수직 중앙정렬
		&.t2 {background:none;
			> div:first-child {//scrollbar
				> div {display:flex;align-content:center;align-items:center;justify-content:center;flex-wrap:wrap;height:100%;padding:10px;box-sizing:border-box;}
			}
		}
    }

    /* 타입3 */
    .box-area2 {padding:20px;border-top:2px solid #666;background:#f5f8fa;text-align:left;box-sizing:border-box;}

    /* 타입4 */
    .box-area3 {min-height:75px;border:1px solid #d5dce8;border-radius:3px;background:#f2f5fa;box-sizing:border-box;font-size:12px;
        > div {
            > strong {display:block;padding:10px 11px 5px;}//타이틀
            > ul {padding:10px 11px;
                > li {line-height:16px;
                    &:not(:last-child) {margin-bottom:16px;}
                    > strong {display:block;font-weight:normal;}
                }
            }
        }
    }

    /* 타입5 */
    .box-area4 {display:flex;flex-wrap:wrap;justify-content:space-between;padding:30px;border-radius:6px;border:1px solid #cdd3dd;box-shadow:0px 3px 5px 0 rgba(0, 0, 0, 0.07);box-sizing:border-box;
        .area1, .area2 {overflow:hidden;display:flex;flex-direction:column;margin-top:30px;box-sizing:border-box;
            &.t1 {flex-wrap:wrap;flex-direction:row;justify-content:space-between;}//가로형
            > div, > ul, > ol, > dl, > p, > table {margin-top:20px;
                &:first-child {margin-top:0 !important;}
            }
            .ti-area {
                & + div, & + ul, & + ol, & + dl, & + p, & + table {margin-top:0 !important;}
            }
        }
        .area1:nth-child(1), .area2:nth-child(1), .area2:nth-child(2), .area1 + .area2, .ti-area + .area1 {margin-top:0 !important;}
        .area1 + .area2 {margin-top:30px !important;}
        .area1 {width:100%;}//col 1
        .area2 {width:calc(50% - 6px);}//col 2
        //타이틀
        .ti-area {display:flex;justify-content:space-between;align-items:flex-end;width:100%;padding-bottom:17px;font-size:13px;letter-spacing:-0.33px;color:#0c2b65;box-sizing:border-box;
            &.ac {justify-content:center;}
            > span:not(:first-child) {padding-left:10px;font-size:11px;letter-spacing:-0.28px;text-align:right;color:#5c7198;font-weight:normal;}//단위
            > span > i {padding-left:6px;font-weight:normal;}//서브타이틀
        }

        /* 내부박스 */
        &.t1 {padding:0;border:none;border-radius:0;box-shadow:none;
            > .area1, > .area2 {margin-top:20px !important;padding:30px;border:1px solid #cdd3dd;border-radius:6px;box-shadow:0px 3px 5px 0 rgba(0, 0, 0, 0.07);
                &.t2 {padding:30px 0 0 !important;//여백설정
                    .ti-area {padding-left:30px !important;padding-right:30px !important;}
                    .graph {padding-left:30px !important;padding-right:30px !important;}
                }
            }
            > .area1:nth-child(1), .area2:nth-child(1), .area2:nth-child(2), .area1 + .area2, .ti-area + .area1 {margin-top:0 !important;}
            > .area1 + .area2 {margin-top:20px !important;}
        }
    }

    /* 타입6 */
    .box-area5 {padding:20px;border-radius:4px;border:1px solid #d5dce8;background:#f2f5fa;
        //타이틀
        > .heading {display:flex;align-items:flex-end;padding-bottom:10px;
            > strong, > .ti > strong {font-size:16px;color:#000;}
			> strong + span, > .ti > strong + span, > .ti + span {padding-left:10px;color:#a6a6a6;}
            //타이틀영역 재설정
			> .ti {display:flex;align-items:flex-end;flex:1;}
        }
        //컨텐츠
        > div:not(.heading) {border:none;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 rgba(219, 228, 235, 0.71);}
        > div:not(.heading) + div {margin-top:10px;}
    }

    /* 타입7 */
    .box-area6 {padding:8px;border:2px solid #e6e6e6;box-sizing:border-box;}

    /* 타입8 */
    .box-area7 {padding:10px 20px;border-radius:3px;border:1px solid #e0e0e0;text-align:center;box-sizing:border-box;}

	/* 타입9 */
	.box-area8 {padding:14px 20px 20px;border-radius:4px;border:1px solid #e0e0e0;text-align:center;box-sizing:border-box;
		> .heading {overflow:hidden;display:flex;align-items:flex-end;height:20px;margin-bottom:15px;text-align:left;
			> strong {font-size:14px;letter-spacing:-0.35px;color:#000;
				& + [data-orbit-component="OBTButton"] {margin-left:auto;}
				& + .btn {margin-left:auto;}
			}
			> .btn {margin-bottom:-1px;margin-left:10px;
				> [data-orbit-component="OBTButton"] {vertical-align:middle;
					& + [data-orbit-component="OBTButton"] {margin-left:6px;}
                    > button.img-btn1.t2 {margin-bottom:-2px;}
				}
			}
		}
		//타입1
		&.t1 {padding:20px 30px;border-radius:10px;border-color:#dce5ed;}
	}
	
    /*----------------------------------------------------
        [선택영역박스]
    ----------------------------------------------------*/
    /* 컴포넌트 사용 */
    .choice-area {height:auto !important;padding:12px 20px;border:1px solid #d9d9d9;box-sizing:border-box;
        > strong:first-child {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
        > div {line-height:normal;
            > div > span {vertical-align:middle !important;}
            //1row = 다중
            &.col {line-height:normal !important;
                > em:not(:first-child), > div:not(:first-child) {margin-left:10px;}
                > div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]) {vertical-align:middle;
                    & + div {margin-left:2px !important;}//1row = input + input
                }
                [data-orbit-component="OBTCheckBox"], [data-orbit-component="OBTRadioButtonGroup"] {vertical-align:middle !important;}
                [data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTCheckBox"] {margin-top:-2px;}
                [data-orbit-component="OBTCheckBox"] + [data-orbit-component="OBTRadioButtonGroup"],
                [data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTCheckBox"] {margin-left:20px !important;}//1row = check + radio / radio + check
            }
            [data-orbit-component="OBTCodePicker"] > div {vertical-align:top;}//IE
        }
        //공통
        &.t3, &.t4, &.t7 {display:flex;flex-wrap:wrap;align-items:flex-start;
            > div {margin:0;
                //main checkbox + sub checkbox
                &.group > div {display:block;
                    & ~ div {margin-top:5px !important;}
                }
            }
        }
        &.t5, &.t6 {padding-bottom:10px;
            > div {display:block;
                > div {display:flex;flex-wrap:wrap;
                    > span {margin:0 !important;}
                }
            }
        }
        //세로정렬(only checkbox)
        &.t1 {
            > div {display:block;margin-left:0;
                &[data-orbit-component="OBTCheckBox"] + [data-orbit-component="OBTCheckBox"] {margin-top:5px;}//checkbox + checkbox
            }
        }
        //세로정렬(only radio)
        &.t2 {padding-bottom:10px;
            > div {display:block;
                > div > span {margin-bottom:0 !important;line-height:18px !important;
                    &:not(:first-child) {margin-top:3px !important;}
                }
                &[data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTRadioButtonGroup"] {margin-top:3px;}//radio + radio
            }
        }
        //세로정렬
        &.t1.t2 {
            > div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch):not(.text):nth-child(1),
            > strong + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch):not(.text) {margin-top:2px;}//div(checkbox / radio X) 첫번째
            > div.col.ra.ch:nth-child(1),
            > strong + div.col.ra.ch {margin-top:-1px;}//1row다중(radio + checkbox) 첫번째
            > div {
                /* 공통 */
                &:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch) + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch) {margin-top:9px;}//div + div(checkbox / radio X)
                &:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch):last-child {margin-bottom:4px;}//div(checkbox / radio X) last-child
                &.in {margin-left:20px !important;}//들여쓰기
                /* checkbox */
                &[data-orbit-component="OBTCheckBox"]:last-child, &.ch:last-child {margin-bottom:2px;}
                &[data-orbit-component="OBTCheckBox"] + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch),//checkbox(세로) + div(checkbox / radio X)
                &.ch + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch),//checkbox(가로) + div(checkbox / radio X)
                &:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch) + [data-orbit-component="OBTCheckBox"],//div(checkbox / radio X) + checkbox(세로)
                &:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch) + .ch {margin-top:7px;}//div(checkbox / radio X) + checkbox(가로)
                &[data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTCheckBox"],//radio + checkbox(세로)
                &[data-orbit-component="OBTRadioButtonGroup"] + .ch {margin-top:3px;}//radio + checkbox(가로)
                &.ch + .ch,//checkbox(가로) + checkbox(가로)
                &[data-orbit-component="OBTCheckBox"] + .ch,//checkbox(세로) + checkbox(가로)
                &.ch + [data-orbit-component="OBTCheckBox"] {margin-top:5px;}//checkbox(가로) + checkbox(세로)
                &.ch + .ra {margin-top:4px !important;}//checkbox(가로) + radio(가로)
                /* radio */
                &[data-orbit-component="OBTRadioButtonGroup"] + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch) {margin-top:5px;}//radio + div(checkbox / radio X)
                &:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch) + [data-orbit-component="OBTRadioButtonGroup"] {margin-top:7px;}//div(checkbox / radio X) + radio(세로)
                &:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch) + .ra {margin-top:6px;}//div(checkbox / radio X) + radio(가로)
                &[data-orbit-component="OBTCheckBox"] + [data-orbit-component="OBTRadioButtonGroup"],//checkbox(세로) + radio(세로)
                &.ch + [data-orbit-component="OBTRadioButtonGroup"] {margin-top:5px;}//checkbox(가로) + radio(세로)
                &[data-orbit-component="OBTCheckBox"] + .ra {margin-top:4px;}//checkbox(세로) + radio(가로)
                &[data-orbit-component="OBTRadioButtonGroup"] + .ra {margin-top:2px;}//radio(세로) + radio(가로)
                &.ra + .ra {margin-top:3px;}//radio(가로) + radio(가로)
                &.ra > div > span:not(:first-child) {margin-top:0 !important;}//radio(가로)
                /* 1row = 다중(radio + checkbox) */
                &.col.ra.ch:last-child {margin-bottom:0;}//1row다중(radio + checkbox) last-child
                &:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch):not(.ra) + .col.ra.ch {margin-top:7px !important;}
                &:not(.col):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ra) + .col.ra.ch,//1row다중 X + 1row다중(radio + checkbox)
                &.col.ra.ch + div:not([data-orbit-component="OBTCheckBox"]):not([data-orbit-component="OBTRadioButtonGroup"]):not(.ch):not(.ra) {margin-top:5px !important;}//1row다중(radio + checkbox) + div(checkbox / radio X)
                &.col.ra.ch + [data-orbit-component="OBTCheckBox"],//1row다중(radio + checkbox) + checkbox(세로)
                &.col.ra.ch + [data-orbit-component="OBTRadioButtonGroup"],//1row다중(radio + checkbox) + radio(세로)
                &.col.ra.ch + .ch,//1row다중(radio + checkbox) + checkbox(가로)
                &[data-orbit-component="OBTRadioButtonGroup"] + .col.ra.ch,//1row다중(radio + checkbox) + checkbox(세로)/radio(세로)
                &.col.ra.ch + .col.ra.ch {margin-top:3px !important;}//1row다중(radio + checkbox) + 1row다중(radio + checkbox)
                &.ra + .col.ra.ch,//radio(가로) + 1row다중(radio + checkbox)
                &.col.ra.ch + .ra {margin-top:2px !important;}//1row다중(radio + checkbox) + radio(가로)
                /* text */
                &.text + .text {margin-top:7px !important;}
            }
        }
        //가로정렬 2*n
        &.t3, &.t7 {
            > div:not(.area) {flex-basis:50%;
                & + div:not(.area) ~ div {margin-top:5px !important;}
            }
            //checkbox, radio 복합
            > .area {display:flex;align-items:flex-start;flex-basis:100%;margin-top:3px;
                &:first-child {margin-top:0;}
                &:last-child {margin-bottom:-2px;}
                > div {flex:1;
                    &:first-child {flex-grow:2;margin-top:0;
                    }
                    &:not(:only-child):last-child {
                        > div > span {margin:0 0 0 20px !important;}
                    }
                }
            }
        }
        &.t7 > div {height:27px;line-height:27px;
            &.btn {text-align:right;
                > div + div {margin-left:2px;}
            }
        }
        //가로정렬 3*n(only checkbox)
        &.t4 {
            > div {flex-basis:33.3%;
                & + div + div ~ div {margin-top:5px !important;}
            }
        }
        //가로정렬 2*n(only radio)
        &.t5 {
            > div > div {
                > span {flex-basis:50%;
                    &:nth-child(2) ~ span {margin-top:3px !important;}
                }
            }
        }
        //가로정렬 3*n(only radio)
        &.t6 {
            > div > div {
                > span {flex-basis:33.3%;
                    &:nth-child(3) ~ span {margin-top:3px !important;}
                }
            }
        }
    }

    /* 컴포넌트 미사용 */
    .choice-area2 {position:relative;padding:19px 20px;background:#f5f7f9;border-radius:10px;
        input {position:absolute;top:-100px;left:-100px;
            & + label {position:relative;display:inline-block;padding-left:20px;margin-right:30px;line-height:20px;letter-spacing:-0.3px;
                &:before, &:after {content:'';position:absolute;display:inline-block;box-sizing:border-box;}
                &:before {top:3px;left:0;width:14px;height:14px;border-radius:50%;border:1px solid #a6a6a6;background-color:#fff;}
                &:after {top:7px;left:4px;width:6px;height:6px;border-radius:50%;background-color:#fff;}
            }
            &:checked + label, &:checked:focus + label {
                &:before {border:1px solid #1e91fb;}
                &:after {background-color:#1e91fb;}
            }
            &:checked + label {
                &:before {background:#fff;}
            }
            &:focus + label {
                &:before {border-color:#1e91fb;background:#eff7ff;}
                &:after {background-color:#eff7ff;}
            }
        }
        
    }

    /*----------------------------------------------------
        [툴팁]
    ----------------------------------------------------*/
    /* 타입1 : only 텍스트 */
    .tooltip-cus {line-height:normal;}

    /* 타입2 : 컴포넌트 미사용 */
    .tooltip-area {display:inline-block;padding:7px 12px;border:1px solid #a6a6a6;border-radius:3px;
        > dt {padding-bottom:5px;font-size:13px;letter-spacing:-0.33px;font-weight:bold;}
        > li, > dd, > p {min-width:124px;line-height:1.4;color:#797979;text-align:left;
            > strong {float:right;padding-left:10px;color:#000;}
        }
        //컴포넌트 사용
        &.t1 {padding:0;border:none;border-radius:0;}
    }

    /* 타입3 : 컴포넌트 사용 */
    .tooltip-area2 {text-align:left;
        > li:not(:first-child) {margin-top:6px;}
    }

    /*----------------------------------------------------
        [컨텐츠 데이터(레이아웃)]
    ----------------------------------------------------*/
    /* 타입1 */
    .data-content1 {display:flex;flex-direction:column;padding:100px 30px 30px;background:#f5f8fa;box-sizing:border-box;
        //배경
        &.bgimg1 {background-image:url('../imgs/bg/img_deco_left.png'), url('../imgs/bg/img_deco_right.png');background-position:left top, right top;background-repeat:no-repeat;}
        //헤드라인
        .headline1 {display:flex;align-items:center;
            //타이틀
            > strong {font-size:28px;color:#000;letter-spacing:-1.4px;
                > span {color:#1c90fb;}//포인트색상
            }
            //정보
            > p {flex:1;margin-left:20px;font-size:14px;line-height:1.29;letter-spacing:-0.35px;color:#8c8c8c;}
        }
        //컨텐츠
        > div, > ul {
            &:not(:first-child) {margin-top:50px;}
        }
        //세로 가운데 정렬
        &.t1 {justify-content:center;padding-top:30px;}
    }

    /* 타입2 */
    .data-content2 {padding:40px 54px;background:#f0f5fa;box-sizing:border-box;
        > div, > ol, > ul, > dl, > table, > p {
            &:not(:first-child) {margin-top:18px;}
        }
    }

    /*----------------------------------------------------
        [컨텐츠 데이터(상세)]
    ----------------------------------------------------*/
    /* 타입1 : 순위 데이터(리스트) */
    .data-area1 {width:auto !important;border-top:1px solid #646464;box-sizing:border-box;
        > li {position:relative;border-bottom:1px solid #e5e5e5;counter-increment:num;
            &:before {content:counter(num);display:inline-block;position:absolute;top:50%;width:18px;height:18px;margin-top:-9px;border-radius:50%;background:#94a5c1;font-size:11px;color:#fff;text-align:center;}
            &:nth-child(1):before {background:#4eabfa;}
            &:nth-child(2):before {background:#50cbde;}
            &:nth-child(3):before {background:#afd873;}
            &:nth-child(4):before {background:#f7ad68;}
            &:nth-child(5):before {background:#f5d471;}
            dt, dd {overflow:hidden;color:#000;letter-spacing:-0.6px;text-overflow:ellipsis;white-space:nowrap;}
            dd {color:#4a4a4a;
                span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
            }
            //링크
            a {overflow:hidden;position:absolute;right:5px;top:50%;width:22px;height:22px;margin-top:-11px;cursor:pointer;
                &:before {content:'';display:block;width:100%;height:100%;background:url('../imgs/ico/ic_arrow_right_01_s_normal.png') no-repeat 50% 50%;}
                &:hover:before {background-image:url('../imgs/ico/ic_arrow_right_01_s_over.png');}
            }
        }
        //2n 줄바꿈
        &:not(.t1):not(.t2) {
            > li {padding:8px 37px 3px 57px;
                &:before {left:20px;}
                dt, dd {display:inline-block;width:50%;}
                dd {
                    &:nth-child(2n) {text-align:right;
                        & ~ dd {margin-top:-4px;}
                    }
                }
                //li:hover
                &:hover {background:#e6f5ff;cursor:pointer;}
            }
        }
        //3개 한줄 노출
        &.t1, &.t2 {
            > li {
                &:before {left:15px;}
                dl {display:flex;align-items:center;
                    dt {flex:1;}
                    dd {text-align:right;
                        &:nth-child(2) {width:20%;}//건수
                        &:nth-child(3) {width:35%;}//금액
                    }
                }
            }
        }
        &.t1 > li {padding:5px 15px 5px 40px;}//height:29px
        &.t2 > li {padding:16px 15px 17px 40px;}//height:52px
    }

    /* 타입2 : 제목 + 내용(리스트) */
    .data-area2 {padding:5px 0;color:#000;font-weight:bold;
        li {position:relative;padding:5px 0 5px 27%;
            > span {position:absolute;left:0;top:5px;width:27%;padding-right:10px;font-weight:normal;box-sizing:border-box;}
        }
    }

    /* 타입3 : 카드리스트형태 */
    .data-area3 {position:relative;border:1px solid #e6e6e6;border-top:2px solid #666;background:#fafafa;font-size:13px;color:#000;letter-spacing:-0.33px;box-sizing:border-box;
        ul {overflow:auto;height:100%;padding:12px;box-sizing:border-box;
            > li {background:#fff;
                a {overflow:hidden;position:relative;display:block;height:64px;padding:0 10px 0 60px;border:1px solid #e0e0e0;border-radius:3px;line-height:62px;color:#000 !important;text-decoration:none;box-sizing:border-box;white-space:nowrap;text-overflow:ellipsis;
                    &:before {content:'';position:absolute;left:17px;top:50%;width:32px;height:32px;margin-top:-16px;background:gray;}
                }
                &.on a {border:1px solid #1c90fb;background:#e9f5ff;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);}
            }
            > li:not(:first-child) {margin-top:6px;}
        }
        //타입1
        &.t1 {border:none;background:none;
            ul {padding:0;
                li a {display:flex;align-items:center;
                    > span {overflow:hidden;flex:1;white-space:nowrap;text-overflow:ellipsis;}
                    > [class*="state-type"] {margin-left:10px;}
                }
            }
        }
    }

    /* 타입4 : 등록 / 삭제박스 */
    .data-area4 {display:flex;padding:8px 10px;border:1px solid #d9d9d9;box-sizing:border-box;
        [class*="area"] {display:flex;align-items:center;
            > div + div {margin-left:4px;}
        }
        .area1 {flex:1;
            > b {padding-right:6px;text-align:right;box-sizing:border-box;}
            > [data-orbit-component]:not([data-orbit-component="OBTButton"]) {flex:1;}//입력박스(버튼제외)
        }
        .area2:before {content:'';width:1px;height:15px;margin:0 10px;background:#e6e6e6;}
    }

    /* 타입6 : 사용현황(정보모음형태 / 비주얼) */
    .data-area6 {overflow:hidden;position:relative;height:230px;padding:20px !important;background-image:linear-gradient(98deg, #f0f5fb -1%, #dfe5ef 1672px);box-sizing:border-box;
        &:before {content:'';position:absolute;left:0;bottom:0;width:1672px;;height:230px;background-repeat:no-repeat;background-position:0 100%;}
        &:after {content:'';position:absolute;bottom:0;width:509px;height:230px;margin-left:20px;background-repeat:no-repeat;background-position:0 100%;}
        //대시보드영역
        > div {position:relative;display:inline-flex;height:190px;padding:20px 40px;border-radius:6px;background:rgba(255, 255, 255, 0.7);box-shadow:0 3px 10px 0 #dbe4eb;box-sizing:border-box;z-index:1;
            //기본정보
            > .heading {position:relative;width:230px;padding-bottom:50px;
                //제목
                > .ti {padding-top:2px;font-size:18px;line-height:1.33;letter-spacing:-0.9px;
                    //날짜설정
                    > .date {display:flex;margin-top:-2px;margin-bottom:6px;
                        > div + div {margin-left:4px;}
                        strong, em {margin-right:5px;line-height:27px;}//타이틀
                        //상태
                        > [class*="state"] {align-self:center;margin-left:10px;}
                    }
                    //상태
                    > [class*="state"] {margin:-4px 0 0 10px;vertical-align:middle;}
                    //부서/팀 + 타이틀 : 한줄
                    > span:not([class*="state"]) {overflow:hidden;display:block;text-overflow:ellipsis;white-space:nowrap;
                        > em {color:#1c90fb;font-weight:bold;}//부서/팀(text)
                        > [data-orbit-component] {margin-top:4px;vertical-align:text-bottom;}//부서/팀(input 컴포넌트)
                    }
                    //부서/팀 + 이름 + 타이틀 : 두줄
                    > span.t1 {
                        > em {overflow:hidden;display:block;color:#000;font-weight:normal;text-overflow:ellipsis;white-space:nowrap;}//부서/팀(text)
                        > strong {color:#1c90fb;}//이름
                    }
                }
                //보조문구
                > span {display:block;margin-top:3px;font-size:13px;line-height:1.23;letter-spacing:-0.33px;color:#a6a6a6;}
                //합계 : 노출방법(건수 : 00)(기본)
                > .total {display:flex;align-items:center;position:absolute;bottom:0;width:100%;height:50px;padding:0 20px;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 rgba(219, 228, 235, 0.71);font-size:13px;color:#a6a6a6;letter-spacing:-0.33px;box-sizing:border-box;
                    > span {line-height:1.17;}
                    > strong {overflow:hidden;flex:1;margin-top:-2px;margin-left:5px;font-size:13px;color:#474747;letter-spacing:-0.33px;font-weight:normal;text-align:right;text-overflow:ellipsis;white-space:nowrap;
                        > b {font-size:18px;letter-spacing:-0.45px;}//사용일
                        > i {font-size:16px;color:#a6a6a6;letter-spacing:-0.4px;}//전체일
                        > span {position:relative;top:-2px;font-size:14px;color:#a6a6a6;letter-spacing:-0.35px;}//구분(|)
                    }
                    //노출방법(시간 : 00시간00분)
                    &.t1 > strong > b {font-size:16px;letter-spacing:-0.4px;}
                }

                /* 타입1 */
                &.t1 {width:210px;
                    //제목
                    > .ti {
                        //날짜설정(2개)
                        > .date {position:absolute;bottom:6px;width:100%;margin:0;
                            > li {display:flex;flex-direction:column;
                                & + li {margin-left:4px;}
                                > em {margin-right:0;font-size:11px;line-height:normal;color:#a6a6a6;letter-spacing:-0.28px;}
                            }
                        }
                    }
                }
            }
            //상세정보1
            > .info {position:relative;display:flex;flex-direction:column;justify-content:center;width:196px;margin-left:30px;padding:29px 0 0 30px;border-left:1px solid #e6e6e6;
                > strong {position:absolute;top:0;font-size:13px;letter-spacing:-0.9px;}//타이틀
                > ul {display:flex;flex-direction:column;justify-content:center;height:100%;//현황
                    > li {display:flex;flex:1;align-items:center;min-height:23px;max-height:38px;font-size:13px;color:#a6a6a6;letter-spacing:-0.33px;
                        &:before {content:'';width:2px;height:2px;margin-right:4px;background:#818181;}
                        &:not(:first-child) {border-top:1px dashed #e6e6e6;}
                        > span {line-height:1;}
                        > strong {overflow:hidden;flex:1;margin-left:5px;font-size:16px;line-height:1.1;color:#474747;letter-spacing:-0.4px;text-align:right;text-overflow:ellipsis;white-space:nowrap;}
                        //기준일(날짜)
                        &.date {
                            > strong {font-size:14px;letter-spacing:-0.35px;}
                        }
                    }
                }
                //버튼
                > .btn {margin-top:10px;text-align:center;}
                //타입1
                &.t1 {padding-top:0;}
            }
            //상세정보2
            > .info2 {position:relative;display:inline-flex;align-items:center;width:281px;margin-left:30px;padding-left:30px;border-left:1px solid #e6e6e6;
                //급여내역
                > .list {flex:1;min-width:0;color:#a6a6a6;letter-spacing:-0.3px;
                    > li:not(.sum) {display:flex;align-items:center;padding:1px 0;
                        &:before {content:'';display:inline-block;width:6px;height:6px;margin-right:4px;border-radius:3px;vertical-align:middle;}
                        &:nth-child(1):before {background:#4eabfa;}
                        &:nth-child(2):before {background:#d887ed;}
                        &:nth-child(3):before {background:#f5d471;}
                        > strong {overflow:hidden;flex:1;margin-left:5px;color:#474747;text-align:right;text-overflow:ellipsis;white-space:nowrap;}
                    }
                    li.sum {display:flex;align-items:center;margin-top:6px;padding-top:7px;border-top:1px dashed #e6e6e6;
                        > strong {overflow:hidden;flex:1;margin-left:5px;font-size:15px;color:#474747;text-align:right;text-overflow:ellipsis;white-space:nowrap;}
                    }
                }
                //그래프
                > .graph {width:90px;height:90px;margin-left:30px;}
                //계산상세 버튼
                > .btn {position:absolute;right:-21px;top:-3px;
                    .img-btn2 {color:#a6a6a6;}
                }
            }
            //상세정보3
            > .info3 {display:inline-flex;flex-direction:column;width:299px;margin-left:30px;padding-left:30px;border-left:1px solid #e6e6e6;
                //재직일수 / 재직월수
                > .list {display:inline-flex;flex-direction:column;flex:1;padding:0 20px;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 rgba(219, 228, 235, 0.71);
                    > li {display:flex;align-items:center;flex:1;
                        &:not(:first-child) {border-top:1px solid #e6e6e6;}
                        > span {font-size:12px;color:#a6a6a6;letter-spacing:-0.3px;}
                        > strong {overflow:hidden;flex:1;margin-left:5px;font-size:13px;color:#474747;letter-spacing:-0.33px;text-align:right;font-weight:normal;text-overflow:ellipsis;white-space:nowrap;
                            > b {font-size:18px;letter-spacing:-0.45px;}
                        }
                    }
                }
                //버튼 / 퇴직금
                > .total {display:flex;align-items:flex-end;width:100%;height:50px;margin-top:9px;
                    .btn {height:50px;padding:0 15px;border:none !important;border-radius:4px;background-image:linear-gradient(90deg, #4dcaff, #4d7bff) !important;letter-spacing:-0.33px;color:#fff;vertical-align:middle;box-shadow:0 5px 10px 0 rgba(0, 0, 0, 0.16);
                        span {font-size:13px;}
                    }
                    .sum {display:inline-flex;align-items:center;flex:1;min-width:0;height:50px;margin-left:10px;padding:0 20px;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 #dbe4eb;font-size:13px;color:#a6a6a6;letter-spacing:-0.33px;box-sizing:border-box;
                        > strong {overflow:hidden;flex:1;margin-top:-2px;margin-left:5px;font-size:18px;color:#474747;letter-spacing:-0.45px;text-align:right;text-overflow:ellipsis;white-space:nowrap;}
                    }
                }
            }
            //그래프(진행률)
            > .graph {align-self:center;position:relative;transform:rotate(-90deg);width:140px;height:140px;margin:-1px -1px 0 29px;
                .back {stroke:#ebebeb;}
                .fill {stroke:#3097eb;animation:chart-fill 2s reverse;transform-origin:center;}
                strong.info {position:absolute;right:50%;top:40px;width:140px;margin-right:-68px;font-size:36px;line-height:1.17;color:#474747;letter-spacing:-1.44px;text-align:center;animation:chart-info 2s forwards;transform:rotate(90deg);opacity:0;
                    > i {font-size:18px;letter-spacing:-1.35px;}
                    > span {display:block;margin-top:4px;font-size:12px;letter-spacing:-0.9px;color:#4a4a4a;font-weight:normal;text-align:center;}
                }
                @keyframes chart-fill {
                    to {stroke-dasharray:0 100;}
                }
                @keyframes chart-info {
                    to {opacity:1;}
                }
            }
            //버튼
            > .btn {position:absolute;right:10px;top:8px;
                .img-btn2 {color:#a6a6a6;}
            }
        }

        /* 타입1 */
        &.t1 {
            &:before, &:after {display:none;}
            //대시보드영역
            > div {display:flex;width:100%;max-width:100%;background:#fff;
                //그래프
                > .graph {overflow:hidden;align-self:auto;flex:1;transform:none;width:auto;height:auto;margin:0 0 0 30px;padding:25px 0 0 30px;border-left:1px solid #e6e6e6;box-sizing:border-box;
                    > .ti {position:absolute;top:0;display:flex;align-items:flex-end;width:calc(100% - 30px);font-size:13px;
                        > strong {flex:1;text-align:center;}
                        > span {font-size:10px;color:#a6a6a6;text-align:right;}
                    }
                }
            }
        }
    }

    /* 타입7 : 연차계획등록 박스형태(합계 / 일자별) */
    .data-area7 {overflow:hidden;border:1px solid #e6e6e6;border-radius:4px;box-sizing:border-box;
        //총합계/전체동의
        .total {display:flex;height:60px;padding:0 20px;font-size:16px;line-height:60px;
            > li {display:inline-flex;flex:1;
                > .ti {padding-left:21px;background:url('../imgs/ico/ic_docu_m_normal.png') no-repeat 0 50%;
                    > [data-orbit-component="OBTTooltip"] {margin-top:-2px;margin-left:2px;vertical-align:middle;}
                }
                > strong {flex:1;font-size:20px;color:#1c90fb;text-align:right;
                    > span {font-size:16px;}
                }
                &:nth-child(2) {margin-left:20px;padding-left:20px;border-left:1px solid #e6e6e6;
                    > strong {color:#ff8787;}
                }
            }
            
        }
        //등록폼/약관상세
        .cont {padding:10px;border-top:1px solid #e6e6e6;background:#f9fafc;
            //등록폼
            li {
                dl {border:1px solid #ccc;border-radius:4px;background:#fff;
                    dt {display:flex;align-items:center;height:58px;padding:0 10px 0 20px;border-radius:4px 4px 0 0;line-height:58px;color:#a6a6a6;letter-spacing:-0.7px;color:#a6a6a6;
                        > strong {width:calc(50% - 68px);font-size:15px;}
                        > em {width:50%;padding-right:2px;font-size:14px;text-align:right;box-sizing:border-box;
                            > b {margin-left:5px;font-size:16px;}
                        }
                        > div {position:relative;width:34px;height:34px;
                            &:last-child:before {content:'';position:absolute;left:0;top:50%;width:1px;height:12px;margin-top:-6px;background:#e6e6e6;}
                            > button {width:100%;height:100%;}
                        }
                    }
                    dd {display:flex;align-items:center;min-height:27px;padding:16px 20px;
                        &:first-child {padding-top:20px;}
                        &:last-child {padding-bottom:20px;}
                        & + dd {border-top:1px solid #e6edf4;}
                        > strong {width:75px;}
                        > div {flex:1;
                            > div {vertical-align:middle;
                                & + div {margin-left:2px;}
                            }
                            .dash {display:inline-block;width:14px;text-align:center;}//~표시
                        }
                    }
                    dt + dd {border-top:1px solid #ccc;}
                }
                &:not(:first-child) {margin-top:10px;}
            }
            //등록폼 : 닫힘상태
            li.close dd {display:none;}
            //등록폼 : 열림상태
            li.open {
                dl {border-color:#50596c;
                    dt {background:#f4f8fc;color:#000;}
                    dt + dd {border-color:#50596c;}
                }
            }
            //약관상세
            .area1 {min-height:46px;padding:13px 20px;border-radius:4px;background:#fff;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.1);box-sizing:border-box;
                & + div {margin-top:10px;}
                > div, > table, > p, > ul, > dl, > ol {
                    &:not(:first-child) {margin-top:10px;}
                }
                > [data-orbit-component="OBTCheckBox"] {font-weight:bold;}
            }
        }
        //추가버튼
        .btn {padding:0 10px 10px;background:#f9fafc;
            > div {width:100%;
                button {width:100%;height:60px;border-radius:4px;border-color:#ccc;background:#fff !important;
                    span {margin:-1px 0 0 4px;font-size:14px !important;color:#8d8d8d;
                        &:after {display:none;}
                    }
                    &:hover {border-color:#1c90fb !important;background:#eff7ff !important;
                        span {color:#1c90fb;}
                    }
                }
            }
        }

        /* 높이100% */
        &.t1 {height:100%;
            //총합계/전체동의
            .total {font-weight:bold;}
            //등록폼/약관상세
            .cont {height:calc(100% - 60px);padding:0;box-sizing:border-box;
                //심플스크롤
                .scoll-area {height:100%;
                    > div:first-child {padding:20px;box-sizing:border-box;}
                }
            }
        }
    }

    /* 타입8 : 개인화 리스트 ----------------- 공통디자인 변경으로 클래스명(.data-area28) 변경하여 신규 제작(틀어짐 방지하기 위해 이전 버전(.data-area8/.data-area8.t1/.data-area8.t2) 유지하고 개발 완료 후 삭제 예정) */
    //타입8-1
    .data-area8 {
        //리스트
        .content {overflow:hidden;position:relative;display:flex;flex-direction:column;height:100%;
            //heading(컨텐츠헤딩)
            > .heading {margin-bottom:0;padding:0 20px;}
            //뷰타입 : 리스트
            > .heading + div, > .cont {flex:1;}
            .cont {background:#f5f8fa;
                > div {
                    > dl {overflow:auto;position:relative;min-height:100%;padding:15px 20px 20px;box-sizing:border-box;
                        &:before {content:'';position:absolute;left:29px;top:0;bottom:0;width:1px;background:#e9e9e9;}
                        dt {position:relative;display:flex;align-items:center;height:30px;z-index:1;
                            > strong {font-size:14px;letter-spacing:-0.7px;}
                            .btn {display:flex;margin-left:5px;
                                li {margin-left:6px;
                                    button, button:hover {height:18px;padding:0 6px;border:none;border-radius:9px;color:#fff;
                                        &.type1 {background:#f0c325;}
                                        &.type2 {background:#748ffc;}
                                        &.type3 {background:#ff8787;}
                                        &.type4 {background:#ac91f5;}
                                        &.type5 {background:#55cc91;}
                                        &.type6 {background:#53abfe;}
                                    }
                                }
                            }
                            &:not(:first-child) {margin-top:16px;}
                        }
                        dd {display:flex;align-items:center;margin:6px 0 0 28px;padding:11px 0 11px 20px;border:1px solid #e6e6e6;border-radius:4px;background:#fff;
                            > span:not(.checkbox):not(.time), > strong, > div {position:relative;padding:0 11px;
                                &:before {content:'';position:absolute;left:0;top:50%;width:1px;height:16px;margin-top:-8px;background:#e6e6e6;}
                            }
                            > .checkbox {width:28px;}//체크박스
                            > .time {width:70px;color:#a6a6a6;}//시간
                            > .ti {display:flex;align-items:center;flex:1;padding-left:38px;//사용처
                                &:first-child:before {display:none;}
                                > div {position:absolute;left:10px;top:50%;margin-top:-12px;}
                                > strong {position:relative;font-weight:normal;
                                    //아이콘
                                    &[class*="ico"] {padding-left:4px;
                                        &:before {content:'';position:absolute;left:-20px;top:50%;width:18px;height:18px;margin-top:-9px;background-repeat:no-repeat;background-position:0 0;}
                                    }
                                    &.ico-type1:before {background-image:url('../imgs/ico/ic_debt_balance_m_normal.png');}//거래처별 채무잔액 이체
                                    &.ico-type2:before {background-image:url('../imgs/ico/ic_debt_sum_m_normal.png');}//거래건별 채무금액 이체
                                    &.ico-type3:before {background-image:url('../imgs/ico/ic_paymentslip_m_normal.png');}//지급전표금액 이체
                                    &.ico-type4:before {background-image:url('../imgs/ico/ic_fixmoney_m_normal.png');}//확정자금 이체
                                    &.ico-type5:before {background-image:url('../imgs/ico/ic_expenseclaims_m_normal.png');}//임직원 경비청구금액 이체
                                    &.ico-type6:before {background-image:url('../imgs/ico/ic_advancedmoney_m_normal.png');}//전도금 이체
                                }
                            }
                            > .card-type {width:12%;}//카드타입
                            > .appr-num {width:12%;text-align:center;}//승인번호
                            > strong {display:flex;align-items:center;justify-content:flex-end;width:24%;text-align:right;//사용금액
                                > [class*="state-type"] {margin-top:1px;}
                                > del {flex:1;}
                            }
                            > .btn > div {vertical-align:middle;
                                & + div {margin-left:15px;}
                            }
                            > .ti + .btn:before {display:none;}//타이틀 + 버튼
                            &.on {border-color:#1c90fb;background:#f0f8ff;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);}
                        }
                    }
                    > table:only-child {width:calc(100% - 40px);margin:15px 20px 20px;}
                }
            }
            //뷰타입 : 달력
            .cont1 {overflow:auto;flex:1;padding:20px;box-sizing:border-box;}
            //emptyData(content 내부)
            > .no_data {flex:1;}
        }
        //emptyData
        .no_data {flex-direction:column;background:#f5f8fa;
            > p {margin-top:7px;line-height:1.5;font-size:12px;letter-spacing:-0.6px;}
            > [data-orbit-component="OBTButton"] {margin-top:12px;}
        }
    }
    //타입8-2
    .data-area8.t1 {
        //리스트
        .content {
            //뷰타입
            .cont {
                //공통
                > div > dl dd {margin-top:0;padding:0;border:none;background:none;}//테이블(.data-tbl7)이 삽입될경우 dd 초기화
                //테이블 head 고정, body 스크롤
                &.t1 > div {overflow-x:hidden !important;height:100%;
                    > dl {display:flex;flex-direction:column;
                        dd {flex-direction:column;flex:1;
                            > div {flex:1;width:100%;box-sizing:border-box;}
                        }
                    }
                    &.t1 {display:flex;flex-direction:column;padding:14px 20px 20px;box-sizing:border-box;//:only-child table
                        > div {flex:1;}
                    }
                }
            }
        }
    }
    //타입8-3
    .data-area8.t2 {
        //리스트
        .content {
            //뷰타입 : 리스트
            .cont {
                > div {
                    > dl {
                        &:before {display:none;}
                        dt {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                        dd {margin-left:0;padding-right:20px;font-size:13px;
                            > span:not(.checkbox):not(.time), > strong, > div {padding:0 20px;}
                            > .ti {
                                > strong {font-weight:bold;}
                            }
                            > .info1 {display:flex;align-items:center;width:15%;
                                > i {font-size:12px;color:#a6a6a6;}
                                > strong {flex:1;padding-left:10px;text-align:right;}
                            }
                            > .btn {width:15%;padding-right:0;text-align:right;}
                            &.on {position:relative;z-index:1;}
                            //서브 컨텐츠
                            &.sub-area {display:none;}
                            &.open {display:block;margin-top:-5px;padding:18px 20px 14px;border-radius:0 0 4px 4px;border-color:#1c90fb;background:#e2e8f2;}
                        }
                    }
                }
            }
        }
    }

    /* 타입9 : 기본정보(사진 + 폼패널) */
    .data-area9 {display:flex;padding:10px;border:1px solid #e6e6e6;box-sizing:border-box;
        .pic-type2 {width:131px;height:166px;}
        .info {width:calc(100% - 141px);margin-left:10px;}
    }

    /* 타입10 : 기본정보(사진 + 정보 / 사진 + 정보 + 정보) */
    .data-area10 {position:relative;display:flex;flex-wrap:wrap;align-content:center;padding:17px 24px 18px 96px;border:1px solid #d5dce8;border-radius:4px;background:#f2f5fa;font-size:13px;color:#4a4a4a;box-sizing:border-box;
        em {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}//타이틀
        dt {width:100%;padding-bottom:3px;
            strong {font-size:15px;color:#000;}//이름
            > span > span:before {content:'';display:inline-block;width:1px;height:10px;margin:0 5px;background:#bec6de;}//사원번호
            .pic-type1 {position:absolute;top:50%;left:24px;width:52px;height:52px;margin-top:-26px;}//사진
        }
        dd {line-height:1.46;
            & + dd:before {content:'';display:inline-flex;width:1px;height:10px;margin:0 5px;background:#bec6de;}
        }
        li {position:relative;width:100%;padding-left:7px;
            &:before {content:'';position:absolute;left:0;top:9px;width:2px;height:2px;border-radius:1px;background:#4e4e4e;}
            & + li {margin-top:2px;}
        }
        //1col
        .area1 {display:flex;flex-wrap:wrap;align-content:center;flex:1;}
        //2col
        .area2 {position:relative;display:flex;flex-wrap:wrap;align-content:center;width:50%;padding-left:79px;
            &:before {content:'';position:absolute;top:0;bottom:0;left:40px;width:1px;height:100%;margin-right:40px;background:#dee2ef;}
        }

        /* 타입1 */
        &.t1 {padding:29px 40px 29px 132px;
            .area1 {
                dt {padding-bottom:2px;
                    .pic-type1 {left:40px;width:72px;height:72px;margin-top:-36px;}
                }
                dd {width:100%;font-size:12px;
                    & + dd:before {display:none;}
                    &:not(:last-child) {
                        i {vertical-align:middle;}
                    }
                    &:last-child {margin-top:3px;color:#a6a6a6;
                        i + i {margin-left:4px;}
                    }
                }
            }
            .area2 {width:63%;font-size:11px;
                dt {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                dd {min-height:18px;padding:1px 0 1px 22px;background-repeat:no-repeat;box-sizing:border-box;
                    &:nth-child(4) ~ dd {margin-top:12px;}
                    &:nth-child(2) {order:1;width:150px;padding-right:20px;background-image:url('../imgs/ico/ic_user_m_normal.png');}
                    &:nth-child(4) {order:2;width:calc(100% - 150px);background-image:url('../imgs/ico/ic_birthday_m_normal.png');}
                    &:nth-child(6) {order:3;width:150px;padding-right:20px;background-image:url('../imgs/ico/ic_mobile_m_normal.png');}
                    &:nth-child(8) {order:5;width:150px;padding-right:20px;background-image:url('../imgs/ico/ic_phone_01_m_normal.png');}
                    &:nth-child(10) {order:4;width:calc(100% - 150px);background-image:url('../imgs/ico/ic_pc_m_normal.png');}
                    &:nth-child(12) {order:6;width:calc(100% - 150px);background-image:url('../imgs/ico/ic_address_m_normal.png');}
                }
            }
        }

        /* 타입2 */
        &.t2 {padding:17px 24px 18px 24px;}
    }

    /* 타입11 : 연차계획일수 리스트 */
    .data-area11 {display:flex;flex-direction:column;min-height:100%;border:1px solid #e6e6e6;border-radius:6px;
        //타이틀
        > strong {display:block;margin-bottom:10px;padding:14px 24px 14px;border-radius:6px 6px 0 0;background:#f5f8fa;font-size:15px;line-height:1.3;font-weight:bold;letter-spacing:-0.75px;text-align:center;}
        //리스트
        > strong + div,//심플스크롤(컴포넌트)
        ul {flex:1 1 auto;margin-bottom:10px;
            ul {margin-bottom:0;}
            > li {position:relative;margin:0 24px;padding:13px 0;
                & ~ li {border-top:1px dashed #dbdbdb;}
                > span:not(.day) {display:inline-block;height:27px;font-size:14px;line-height:27px;letter-spacing:-0.7px;vertical-align:middle;
                    &:nth-child(1) {width:30%;}
                    &:nth-child(2) {width:70%;text-align:right;}
                    > strong {font-size:18px;line-height:20px;vertical-align:text-bottom;}
                }
                > .day {display:block;padding:8px 0 3px;line-height:1.25;color:#919191;letter-spacing:-0.3px;}
            }
        }
        //정보
        .info-area {margin:0 24px 19px;padding-top:10px;border-top:1px solid #f0f1f2;
            > div:not(.btn) {display:flex;align-items:center;height:33px;
                > div {display:inline-block;height:27px;font-size:14px;line-height:27px;letter-spacing:-0.7px;vertical-align:middle;
                    &:nth-child(1) {width:60%;
                        > div {margin-top:-3px;margin-left:3px;vertical-align:middle;}//도움말아이콘
                    }
                    &:nth-child(2) {width:40%;color:#ff8787;text-align:right;}
                    > strong {font-size:18px;line-height:20px;vertical-align:text-bottom;}
                }
                //가이드문구
                &.guide {padding-left:23px;background:url('../imgs/ico/ico_confirm_03.png') no-repeat 0 50%;font-size:11px;line-height:1.27;color:#666;}
            }
            //버튼영역
            > .btn {margin-top:10px;
                > div {width:100%;
                    button {width:100%;}
                }
            }
        }
    }

    /* 타입12 : 근무시간정보 */
    .data-area12 {display:flex;align-items:center;padding:20px;box-sizing:border-box;
        [class*="area"]:not(:first-child) {position:relative;padding-left:51px;
            &:before {content:'';position:absolute;left:25px;top:50%;width:1px;height:37px;margin-top:-19px;background:#e6e6e6;}
        }
        //근무시간 / 그래프
        .area1 {width:20%;
            > span {font-size:13px;letter-spacing:-0.65px;}
            > strong {margin-left:10px;font-size:14px;}
            > .graph {overflow:hidden;display:flex;height:16px;margin:5px 0 4px;border-radius:8px;
                > [class*="type"] {overflow:hidden;height:0;padding-top:16px;box-sizing:content-box;
                    & + span {margin-left:1px;}
                }
                > .type1 {background:#46a3f0;}
                > .type2 {background:#f48da5;}
                > .type3 {background:#ebebeb;}
                > .type4 {background:#20c997;}
                > .type5 {background:#f8a457;}
            }
        }
        //근무시간정보
        .area2 {display:inline-flex;align-items:center;flex:1;
            li {display:inline-flex;align-items:center;flex:1;
                &:before {content:'';width:10px;height:10px;margin-right:5px;border-radius:50%;}
                &.type1:before {background:#46a3f0;}
                &.type2:before {background:#f48da5;}
                &.type3:before {background:#ebebeb;}
                &.type4:before {background:#20c997;}
                &.type5:before {background:#f8a457;}
                & + li {margin-left:25px;}
                > span {width:35%;font-size:13px;line-height:20px;letter-spacing:-0.65px;color:#333;}
                > strong {flex:1;margin-top:-1px;font-size:14px;line-height:20px;text-align:right;}
            }
        }
    }

    /* 타입13 : 근무시간리스트 */
    .data-area13 {border-top:1px solid #e6e6e6;background:#f5f8fa;box-sizing:border-box;
        .list {width:calc(100% - 28px);height:calc(100% - 40px);margin:20px 14px;border-collapse:separate;border-spacing:6px 0;table-layout:fixed;
            > thead th {height:19px;padding-bottom:15px;font-size:13px;
                &:not(:first-child) {vertical-align:top;}
                &.sun {color:#ff0000;}//일요일
                &.sat {color:#1c90fb;}//토요일
                //상태
                [class*="tag-type2"] {display:block;width:40px;margin:5px auto 0;}
            }
            > tbody tr {
                th, td {height:9%;padding:0 19px;vertical-align:middle;box-sizing:border-box;}
                th {background:#ecf1f5;}
                td {border:solid #e6e6e6;border-width:0 1px;background:#fff;text-align:center;
                    //비활성화
                    &.dis {background:#f8f9fa;color:#a6a6a6;}
                }
                &:first-child {
                    th, td {height:11%;padding-top:7px;border-radius:4px 4px 0 0;}
                    td {border-top-width:1px;}
                }
                &:nth-child(2) {
                    th, td {height:12%;}
                }
                &:last-child {
                    th, td {border-radius:0 0 4px 4px;}
                    td {border-bottom-width:1px;}
                }
                //버튼영역
                &.btn {
                    th, td {
                        > div {vertical-align:middle;}
                    }
                }
                //상태영역
                &.state {
                    th, td {position:relative;height:16%;padding-top:13px;
                        &:before {content:'';position:absolute;left:19px;right:19px;top:12px;height:1px;background:#e6e6e6;}
                        [class*="state-type"] {min-width:34px;}
                    }
                }
            }
        }
    }

    /* 타입14 : 정보모음 */
    .data-area14 {display:flex;align-content:center;padding:15px 0;border:1px solid #ccc;border-radius:10px;color:#4a4a4a;box-sizing:border-box;
        > [class*="info"] {border-left:1px solid #e6e6e6;
            &:before {background-repeat:no-repeat;background-position:0 0;}
            &:first-child {border-left:none;}
        }
        > div:not([class*="data-graph"]) {position:relative;display:flex;flex:1;justify-content:space-between;align-items:center;min-height:50px;padding:0 20px 0 80px;box-sizing:border-box;
            > strong {font-size:15px;
                &::before {position:absolute;top:50%;left:20px;margin-top:-25px;content:'';display:inline-block;width:50px;height:50px;border-radius:50px;background-color:#f5f5f8;}
            }
        }
        > dl {position:relative;flex:1;min-height:50px;padding:0 30px 0 110px;box-sizing:border-box;
            > dt, > dd {display:flex;justify-content:space-between;align-items:center;line-height:18px;}
            > dt {padding-bottom:8px;margin-bottom:5px;border-bottom:1px solid #e6e6e6;
                > strong {position:absolute;top:50%;left:30px;display:inline-flex;flex-direction:column;align-items:center;transform:translateY(-50%);width:50px;text-align:center;line-height:20px;
                    &::before {content:'';display:inline-block;width:50px;height:50px;border-radius:50px;background-color:#f5f5f8;z-index:-1;}
                }
                > b {font-size:11px;letter-spacing:-0.28px}
                > em {overflow:hidden;flex:1;padding-left:10px;font-size:26px;line-height:1;color:#000;letter-spacing:-1.3px;font-weight:bold;text-align:right;white-space:nowrap;text-overflow:ellipsis;
                    > span {margin-right:8px;font-size:14px;letter-spacing:-0.7px;font-weight:normal;
                        &.unit {margin-left:2px;margin-right:0;font-size:13px;letter-spacing:0;}
                    }
                }
            }
            > dd {
                > em {font-size:14px;letter-spacing:-0.7px;text-align:right;
                    > .unit {font-size:11px;letter-spacing:-0.55px;}
                }
            }
            //폰트 타입1
            &.t1 {
                > dd {
                    > em {font-size:18px;letter-spacing:-0.9px;font-weight:bold;
                        > .unit {font-size:13px !important;letter-spacing:-0.65px;font-weight:normal;}
                    }
                }
            }
            //폰트 타입2
            &.t2 {
                > dt > em {font-size:18px !important;}
                > dd {justify-content:space-between !important;
                    > span {visibility:visible !important;position:static !important;left:0 !important;top:0 !important;width:auto !important;height:auto !important;font-size:11px;color:#a6a6a6;opacity:1 !important;z-index:1 !important;}
                }
            }
            //폰트 타입3
            &.t3 {
                > dt {padding-bottom:13px;margin-bottom:8px;
                    > b {font-size:12px;}
                    > em {font-size:22px !important;
                        > span.unit {font-size:14px;}
                    }
                }
                > dd {justify-content:space-between !important;margin-top:2px;
                    > span {visibility:visible !important;position:static !important;left:0 !important;top:0 !important;width:auto !important;height:auto !important;font-size:12px;color:#a6a6a6;opacity:1 !important;z-index:1 !important;}
                    > em {font-size:16px;}
                }
            }
        }
        //타입1
        &.t1 {
            > dl {padding:0 20px 0 90px;
                &::before {position:absolute;top:50%;left:20px;margin-top:-25px;content:'';display:inline-block;width:50px;height:50px;border-radius:50px;background-color:#f5f5f8;}
                > dt > em {font-size:16px;letter-spacing:-0.8px;}
                > dd {justify-content:flex-end;
                    > span {visibility:hidden;overflow:hidden;position:absolute;left:-1px;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                    > em {
                        &:not(:last-child)::after {content:'/';display:inline-block;padding:0 4px;font-size:11px;}
                        > .unit {margin-left:2px;font-size:11px;}
                    }
                }
            }
        }
        //타입2
        &.t2 {
            //정보
            > dl {padding:14px 40px 14px 200px;
                &::before {left:40px;margin-top:-60px;width:120px;height:120px;border-radius:0;background-color:transparent;}
            }
            //그래프
            [class*="data-graph"] {width:34%;padding:0 40px;border-left:1px solid #e6e6e6;}
        }
        //아이콘
        .info1 {//수입
            > dt {
                > strong::before {background-image:url('../imgs/ico/tab_fund_income_none.png');}
                > em {color:#fc5356;}
            }
        }
        .info2 {//지출
            > dt {
                > strong::before {background-image:url('../imgs/ico/tab_fund_expense_none.png');}
                > em {color:#1c90fb;}
            }
        }
        .info3 {//총청구금액
            > dt > em {color:#46a3f0;}
            &::before {background-image:url('../imgs/ico/tab_charge_sum_none.png');}
        }
        .info4 {//최고사용그룹
            > dt > em {color:#43c2d6;}
            &::before {background-image:url('../imgs/ico/tab_use_group_none.png');}
        }
        .info5 {//최고사용용도
            > dt > em {color:#887af4;}
            &::before {background-image:url('../imgs/ico/tab_use_purpose_none.png');}
        }
        .info6 {//Web
            > strong::before {background-image:url('../imgs/ico/tab_web_none.png');}
        }
        .info7 {//메신저
            > strong::before {background-image:url('../imgs/ico/tab_messenger_none.png');}
        }
        .info8 {//모바일
            > strong::before {background-image:url('../imgs/ico/tab_mobile_none.png');}
        }
        .info9 {//총인원
            > dd > em {color:#46a3f0;}
            &::before {background-image:url('../imgs/ico/tab_total_member_none.png');}
        }
        .info10 {//총 지급금액
            > dd > em {color:#43c2d6;}
            &::before {background-image:url('../imgs/ico/tab_total_pay_none.png');}
        }
        .info11 {//총 공제금액
            > dd > em {color:#887af4;}
            &::before {background-image:url('../imgs/ico/tab_total_deduct_none.png');}
        }
        .info12 {//총 차인지급액
            > dd > em {color:#c275fa;}
            &::before {background-image:url('../imgs/ico/tab_total_deductpay_none.png');}
        }
        .info13 {//재료비
            &::before {background-image:url('../imgs/ico/ic_material_cost.png');}
        }
        .info14 {//노무비
            &::before {background-image:url('../imgs/ico/ic_labor_cost.png');}
        }
        .info15 {//경비
            &::before {background-image:url('../imgs/ico/ic_expense_cost.png');}
        }
        .info16 {//제조원가요약
            &::before {background-image:url('../imgs/ico/img_production_cost.png');}
        }
    }

    /* 타입15 : 정보모음(리스트) */
    .data-area15 {display:flex;flex-wrap:wrap;align-content:stretch;align-content:center;;justify-content:space-between;
        > li {display:flex;width:calc(50% - 11px);padding:12px 0;border-radius:4px;border:1px solid #e6e6e6;background:#fff;box-sizing:border-box;margin-bottom:22px;
            &:nth-child(2n) {margin-left:0}
            &:nth-last-child(-n+2) {margin-bottom:0;}
            &:hover {border:1px solid #1c90fb;background:#f5faff;}
            > dl {display:flex;align-content:center;width:100%;
                > dt {width:170px;text-align:center;align-self:center;
                    button {border:none;background:transparent !important;height:auto;
                        &:focus,
                        &:active{padding:0;}
                        &:hover {border:none !important;background:none !important;}
                        > div {position:relative;flex-direction:column;
                            span {display:block;font-size:14px !important;line-height:16px;letter-spacing:-0.7px;color:#4a4a4a;}
                        }
                    }
                }
                > dd {display:flex;flex:1;flex-direction:column;justify-content:center;align-items:flex-start;
                    &:nth-child(3) {flex:none;align-items:center;position:relative;width:120px;
                        &:before{content:'';position:absolute;top:5px;left:0;width:1px;height:calc(100% - 10px);background:#e6e6e6;z-index:1;}
                        em {font-size:11px;margin-bottom:6px;
                            &:before {content:'';display:inline-block;width:17px;height:17px;margin-right:2px;vertical-align:top;background:url('../imgs/ico/ic_collect_auto_m_normal.png') no-repeat 0 0;}
                        }
                        em.direct {
                            &:before {background:url('../imgs/ico/ic_list_m_normal.png') no-repeat 0 0;}
                        }
                    }
                    > ul {display:flex;flex-direction:column;width:100%;
                        > li {display:flex;margin:3px 0;
                            i {position:relative;width:70px;flex:none;color:#a6a6a6;padding:0 16px 0 22px;
                                &:before {content:'';position:absolute;top:1px;left:0;display:inline-block;width:18px;height:18px;margin-right:4px;vertical-align:middle;}
                            }
                            em {flex:1;}
                            &:nth-child(1) {
                                i:before {background:url('../imgs/ico/ic_inputcal_m_normal.png') no-repeat 0 0;}
                            }
                            &:nth-child(2) {
                                i:before {background:url('../imgs/ico/ic_time_m_normal.png') no-repeat 0 0;}
                            }
                            &:nth-child(3) {
                                i:before {background:url('../imgs/ico/ic_survey_m_normal.png') no-repeat 0 0;}
                            }
                        }
                    }
                }
            }
        }
    }

    /* 타입16 : 내용1(가변) + 내용2(고정) + 상태(고정) */
    .data-area16 {
        > li {position:relative;display:flex;align-items:center;padding-left:7px;
            &:before {content:'';position:absolute;left:0;top:9px;width:2px;height:2px;border-radius:1px;background:#4e4e4e;}
            & + li {margin-top:3px;}
            > a {flex:1;}
            > span {width:75px;margin-left:4px;text-align:right;}
            > em {margin-left:4px;}
        }
    }

    /* 타입17 : 정보모음(리스트) */
    .data-area17 {display:flex;flex-wrap:wrap;
        > li {flex:1;min-width:calc(100% / 3 - 7px);max-width:calc(100% / 3 - 7px);border-radius:4px;border:1px solid #ececec;box-sizing:border-box;
            &:not(:nth-child(3n + 1)) {margin-left:10px;}
            &:nth-child(3) ~ li {margin-top:10px;}
            //타이틀
            > div:nth-child(1) {display:flex;align-items:center;height:40px;padding:0 20px;border-radius:4px 4px 0 0;background:#fafafa;font-size:14px;line-height:40px;box-sizing:border-box;
                > strong {flex:1;}
                > em {color:#1c90fb;font-weight:bold;text-align:right;
                    > i {font-size:12px;font-weight:normal;}
                }
                > [data-orbit-component="OBTButton"] {margin-left:8px;}
            }
            //리스트
            > div:nth-child(2) {height:145px;
                ul {padding:18px 20px 20px;font-size:11px;color:#a6a6a6;box-sizing:border-box;
                    > li {
                        & + li {margin-top:14px;}
                        div {display:inline;vertical-align:top;
                            &.ti, &.row {display:block;}
                            &:not([class]) + div:not([class]):before {content:'/';padding:0 3px;}
                            &:not(.t1) > em {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                            &.ti {min-height:21px;margin-bottom:-1px;padding:1px 0 0 33px;background-repeat:no-repeat;font-size:13px;color:#000;
                                > strong {font-weight:normal;
                                    > i:before {content:'/';}
                                }
                            }
                            &.t1 > em:after {content:':';padding:0 4px;}
                        }
                    }
                }
            }
            //학력사항
            &.type1 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_edu_select.png');}
            //경력사항
            &.type2 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_career_select.png');}
            //자격면허
            &.type3 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_license_select.png');}
            //고과내역
            &.type4 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_project_select.png');}
            //포상/징계
            &.type5 > div:nth-child(2) ul > li {
                > div.ti {background-image:url('../imgs/ico/ic_prize_select.png');}//포상
                &.t1 > div.ti {background-image:url('../imgs/ico/ico_punishment_select.png');}//징계
            }
            //발령내역
            &.type6 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_hr_select.png');}
            //교육내역
            &.type7 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_school_select.png');}
            //출장내역
            &.type8 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_business_select.png');}
            //외국어
            &.type9 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_lang_select.png');}
            //책정임금
            &.type10 > div:nth-child(2) ul > li > div.ti {background-image:url('../imgs/ico/ic_wage_select.png');}
        }
    }

    /* 타입18 : 기본정보 */
    .data-area18 {border:1px solid #e6e6e6;background:#f2f5fa url('../imgs/ico/img_building.png') no-repeat 24px 50%;font-size:11px;line-height:1.31;color:#4a4a4a;box-sizing:border-box;
        ul {min-height:100%;margin-left:120px;padding:24px 30px;background:#fff;box-sizing:border-box;
            > li {min-height:30px;padding:8px 0 8px 24px;background-repeat:no-repeat;background-position-y:6px;box-sizing:border-box;
                &:nth-child(1) {background-image:url('../imgs/ico/ic_user_m_normal.png');}
                &:nth-child(2) {background-image:url('../imgs/ico/ic_phone_01_m_normal.png');}
                &:nth-child(3) {background-image:url('../imgs/ico/ic_mobile_m_normal.png');}
                &:nth-child(4) {background-image:url('../imgs/ico/ic_address_m_normal.png');}
                > span {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}//타이틀
            }
        }
    }

    /* 타입19 : 기본정보 */
    .data-area19 {display:flex;border:1px solid #e0e0e0;padding:30px 0 30px 160px;background:url('../imgs/ico/img_funds_daily_report.png') no-repeat 17px 72px;font-size:13px;letter-spacing:-0.65px;box-sizing:border-box;
        > dl {flex:1;padding:0 30px;box-sizing:border-box;
            dd {display:flex;align-items:center;
                > span {color:#a6a6a6;}
                > strong {padding-left:10px;text-align:right;box-sizing:border-box;}
            }
            & + dl {border-left:1px solid #e0e0e0;}
        }
        > .area1 {flex:none;width:35%;padding-top:3px;padding-bottom:5px;
            dt {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
            dd {padding:3px 0;
                > span {width:32%;font-size:12px;}
                > strong {width:68%;font-size:16px;letter-spacing:-0.8px;}
                &.t1 {display:flex;align-items:center;width:100%;
                    > span:nth-child(1) {width:auto !important;max-width:50%;color:#000;}
                    > span:nth-child(2) {width:30px !important;text-align:center;}
                    > span:nth-child(3) {flex:1;width:auto !important;color:#000;}
                }
            }
            .today {margin-top:20px;padding-top:21px;border-top:1px dashed #e0e0e0;}
        }
        > .area2 {
            dt {height:30px;padding:0 0 5px 23px;line-height:30px;background-repeat:no-repeat;background-position:0 7px;
                &.ti1 {background-image:url('../imgs/ico/ico_finance_info_m_normal.png');}
                &.ti2 {background-image:url('../imgs/ico/ico_funds_info_m_normal.png');}
                &.ti3 {background-image:url('../imgs/ico/ic_paymentslip_m_normal.png');}
            }
            dd {padding:4px 0 5px;
                > span {width:48%;font-size:11px;}
                > strong {width:52%;font-size:14px;letter-spacing:-0.7px;}
            }
            //EmptySet
            .no_data {height:calc(100% - 35px);}
        }

        //area1 / area1 / area2
        &.t1 {padding:35px 6px 35px 163px;background:url('../imgs/ico/img_funds_current_state.png') no-repeat 17px 50%;
            > dl {padding:0 24px;}
            > .area1 {width:34%;padding-top:10px;padding-bottom:14px;
                dd {
                    > span {width:50%;}
                    > strong {width:50%;}
                    &.today {
                        > span {width:32%;}
                        > strong {width:68%;} 
                    }
                }
                .today {margin-top:26px;padding:26px 0 0;
                    > strong {font-size:20px;}
                }
            }
        }
        //area1 / area1
        &.t2 {padding:30px 0 30px 199px;background:url('../imgs/ico/img_funds_loan.png') no-repeat 50px 50%;
            > dl {padding:0 70px;
                &:first-child {padding-right:80px;}
                &:last-child {padding-left:80px;}
            }
            > .area1 {width:50%;padding-bottom:4px;
                dd {
                    > span {width:36%;}
                    > strong {width:65%;}
                }
                .today {margin-top:10px;padding:8px 0 0;
                    > strong {font-size:20px;}
                }
            }
        }
    }

    /* 타입20 : 기본정보 */
    .data-area20 {display:flex;align-items:flex-start;align-items:stretch;padding:30px 40px;border:1px solid #d5dce8;border-radius:4px;background:#f2f5fa;box-sizing:border-box;
        > div {width:300px;
            &.area1 {position:relative;
                &.on {display:block;}
                &.off {display:none;}
                > strong {font-size:18px;line-height:1.5;}
                > em {display:block;margin-top:2px;font-size:14px;line-height:20px;color:#4a4a4a;
                    > i {color:#a6a6a6;}
                }
                > ol {position:absolute;bottom:0;left:0;
                    > li {line-height:18px;color:#4a4a4a;
                        span {display:inline-block;margin-right:10px;color:#688dd3;}
                    }
                }
            }
            &.area2 {display:flex;align-items:flex-start;width:calc(100% - 300px);box-sizing:border-box;
                > div {display:flex;width:calc(50% - 5px);padding:18px 30px;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 rgba(219, 228, 235, 0.71);
                    > div {margin-right:30px;font-size:15px;line-height:22px;color:#4a4a4a;
                        > strong {display:block;}
                        > em {margin-top:5px;min-width:53px;}
                    }
                    > ul {flex:1;
                        > li {display:flex;align-items:center;justify-content:space-between;line-height:1.67;letter-spacing:-0.3px;
                            > em {color:#a6a6a6;}
                            > strong {color:#474747;}
                            &:first-child {margin-bottom:4px;padding-bottom:2px;border-bottom:1px solid #e6e6e6;line-height:1.85;letter-spacing:-0.33px;
                                > em {font-size:13px;}
                                > strong {font-size:15px;}
                            }
                        }
                    }
                    &:last-child {margin-left:10px;}
                }
            }
        }
    }

    /* 타입21 : 정보안내 */
    .data-area21 {padding:0 27px;border:1px solid #d4dfe7;border-radius:3px;background:#f3f7fa;box-sizing:border-box;
        > div {display:flex;align-items:center;padding:20px 0 22px;
            & + div {border-top:1px solid #e0e0e0;}
            > dl {flex:1;line-height:1.5;
                > dt {font-size:16px;color:#1c90fb;letter-spacing:-0.8px;font-weight:bold;
                    //아이콘
                    &[class*="ico"] {padding-left:21px;background-repeat:no-repeat;background-position:0 50%;}
                    &.ico-sms {background-image:url('../imgs/ico/ic_comment_m_over.png');}//SMS 알림
                    &.ico-mobile {background-image:url('../imgs/ico/ic_mobile_m_over.png');}//MOBILE-TAX 발행
                }
                > dd {margin-top:1px;}
            }
            //가이드
            > .guide {display:flex;align-items:center;width:275px;margin-left:25px;padding:13px 18px;border-radius:6px;background:#fff;font-size:11px;line-height:1.2;color:#8c8c8c;box-sizing:border-box;
                > p {flex:1;margin-left:10px;padding-left:10px;border-left:1px solid #e0e0e0;}
            }
        }
    }

    /* 타입22 : 정보안내(상태정보) */
    .data-area22 {display:flex;justify-content:center;padding:20px 0;font-size:15px;
        //이미지
        [class*="type"] {display:flex;flex-direction:column;align-items:center;text-align:center;
            &:before {content:'';display:block;width:150px;height:150px;margin-bottom:20px;border-radius:50%;background-color:#e6f5ff;background-repeat:no-repeat;}
            & + [class*="type"] {margin-left:20px;}
        }
        .type1:before {background-image:url('../imgs/ico/img_writing.png');}//재무보고서 작성중
    }

    /* 타입23 : 정보안내(신호등) */
    .data-area23 {position:relative;display:flex;flex-direction:column;padding:0 30px 0 270px;letter-spacing:-0.55px;
        .heading {
            [class*="state-type3"] {margin-right:4px;
                &:before, &:after {content:'';position:absolute;top:50%;background-repeat:no-repeat;}
                &:before {left:30px;width:204px;height:96px;margin-top:-48px;background-image:url('../imgs/ico/img_green_off.png'), url('../imgs/ico/img_yellow_off.png'), url('../imgs/ico/img_red_off.png'), url('../imgs/ico/img_trafficlights.png');background-position:32px 43%, 87px 43%, 142px 43%, 0 0;}//신호등틀 / off
                &:after {width:30px;height:30px;margin-top:-20px;}//on
            }
            .state-type301:after {left:62px;background-image:url('../imgs/ico/img_green_on.png');}//매우우수
            .state-type307:after {left:117px;background-image:url('../imgs/ico/img_yellow_on.png');}//우수
        }
        .cont {margin-top:15px;color:#4a4a4a;}
    }

    /* 타입24 : 진행현황 */
    .data-area24 {display:flex;align-items:center;padding:19px;border:1px solid #d5dce8;border-radius:4px;background:#f2f5fa;box-sizing:border-box;
        //타이틀
        .heading {padding:0 30px 0 10px;font-size:18px;line-height:24px;
            > strong {display:block;color:#1c90fb;}
        }
        //현황
        .cont {display:inline-flex;align-items:center;flex:1;padding:15px 19px;border-radius:6px;background:rgba(255, 255, 255, 0.7);box-shadow:0 3px 10px 0 #dbe4eb;
            [class*="area"] {flex:1;
                &:not(:first-child) {position:relative;margin-left:19px;padding-left:19px;
                    &:before {content:'';position:absolute;left:0;top:0;bottom:0;width:1px;height:100%;background:#e6e6e6;}
                }
            }
            //정보
            .area1 {display:inline-flex;align-items:center;
                li {display:inline-flex;flex-wrap:wrap;align-items:center;flex:1;min-height:50px;text-align:center;
                    &:not(:first-child) {position:relative;padding-left:19px;
                        &:before {content:'';position:absolute;left:0;top:0;bottom:0;width:1px;height:100%;background:#e6e6e6;}
                    }
                    & + li {margin-left:19px;}
                    &[class*="type"] > span:before {content:'';display:inline-block;width:8px;height:8px;margin-right:5px;border-radius:50%;}//블릿
                    &.type1 > span:before {background:#4eabfa;}
                    &.type2 > span:before {background:#ebebeb;}
                    > span {width:100%;font-size:13px;letter-spacing:-0.33px;color:#4a4a4a;}//타이틀
                    > strong {width:100%;font-size:20px;letter-spacing:-0.5px;//건수
                        > i {font-size:16px;font-weight:normal;}//단위
                    }
                }
            }
            //진행율
            .area2 {display:inline-flex;flex-wrap:wrap;align-content:center;flex:none;width:30%;min-height:50px;
                > .info {
                    > span {font-size:13px;letter-spacing:-0.65px;}
                    > strong {margin-left:5px;font-size:18px;line-height:1;letter-spacing:-0.9px;}
                }
                > .graph {overflow:hidden;display:flex;width:100%;height:12px;margin-top:11px;border-radius:6px;background:#ebebeb;
                    > span {overflow:hidden;height:0;padding-top:12px;border-radius:6px;background:#4eabfa;box-sizing:content-box;}
                }
            }
        }
    }

    /* 타입25 : 정보안내(정보 / 비주얼) */
    .data-area25 {overflow:hidden;position:relative;display:flex;min-height:352px;padding:30px 54px;background-image:linear-gradient(101deg, #edf5f5 -1%, #e7eff0 101%);color:#000;box-sizing:border-box;
        &:before {content:'';position:absolute;left:0;bottom:0;width:1672px;min-height:352px;background:url('../imgs/bg/img_edu_manager_bg.png') no-repeat 0 100%;}
        /* 공통 */
        .area1, .area2 {position:relative;z-index:1;}

        /* 교육정보 */
        .area1 {width:640px;margin-top:13px;
            //헤딩
            .heading {
                .ti {line-height:1.33;
                    > strong {font-size:30px;letter-spacing:-1.5px;
                        > span {font-weight:normal;}
                    }
                    > p {margin-top:4px;font-size:18px;letter-spacing:-0.9px;color:#1c90fb;}
                }
                .btn {margin-top:16px;
                    > div + div {margin-left:6px;}
                }
            }
            //교육정보
            .info {display:flex;justify-content:space-around;margin-top:14px;padding:20px 0;border-radius:6px;background:rgba(255, 255, 255, 0.7);color:#000;box-shadow:0 3px 10px 0 #dbe4eb;box-sizing:border-box;
                > li {position:relative;flex:1;padding:8px 20px 18px;
                    &:not(:first-child) {border-left:1px solid #e6e6e6;
                        &:before {content:'';position:absolute;left:-8px;top:50%;width:15px;height:15px;margin-top:-8px;border-radius:50%;background:url('../imgs/ico/ic_process_next.png') no-repeat;}
                    }
                    > strong, > span {display:block;}
                    > em {display:inline-block;border-bottom:1px solid #1c90fb;font-size:14px;line-height:16px;color:#1c90fb;font-weight:bold;letter-spacing:-0.35px;}
                    > strong {margin-top:2px;font-size:16px;letter-spacing:-0.8px;}
                    > span {margin-top:9px;font-size:11px;line-height:1.45;letter-spacing:-0.55px;opacity:0.8;}
                }
            }
        }

        /* 교육형태 */
        .area2 {width:438px;margin:39px 0 0 40px;
            //헤딩
            .heading {font-size:18px;line-height:1.33;letter-spacing:-0.45px;text-align:center;}
            //교육형태
            .info {margin-top:19px;border-radius:6px;background:rgba(255, 255, 255, 0.7);font-size:16px;letter-spacing:-0.4px;box-shadow:0 3px 10px 0 #dbe4eb;
                li {display:flex;align-items:center;height:70px;padding:0 156px 0 26px;background-repeat:no-repeat;background-position:100% 0;box-sizing:border-box;
                    &:not(:last-child) {border-bottom:1px solid #e6e6e6;}
                    > strong {width:93px;}
                    > span {flex:1;margin-bottom:-3px;font-size:11px;letter-spacing:-0.55px;opacity:0.8;
                        &:not(:first-child) {margin-left:10px;}
                    }
                    //이미지
                    &:nth-child(1) {background-image:url('../imgs/bg/img_edu01.png');}
                    &:nth-child(2) {background-image:url('../imgs/bg/img_edu02.png');}
                    &:nth-child(3) {background-image:url('../imgs/bg/img_edu03.png');}
                }
            }
        }
    }

    /* 타입26 : 정보안내 */
    .data-area26 {padding:15px 20px 20px;border-radius:4px;border:1px solid #d5dce8;background:#f2f5fa;box-sizing:border-box;
        /* heading */
        .heading {display:flex;
            //타이틀
            > .ti {flex:1;
                > strong {font-size:16px;color:#000;}
                > span {display:inline-block;margin-left:10px;font-size:12px;line-height:1.33;color:#a6a6a6;}
            }
            //버튼
            > .btn {margin:1px 0 0 10px;}
        }
        /* 상세정보 */
        .cont {display:flex;justify-content:space-around;margin-top:10px;padding:30px 10px;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 rgba(219, 228, 235, 0.71);
            > li {display:flex;flex-wrap:wrap;flex-direction:column;align-items:center;flex:1;padding:3px 0 1px;font-size:11px;line-height:1.45;text-align:center;box-sizing:border-box;
                &:not(:first-child) {border-left:1px solid #e6e6e6;}
                > strong, > em, > p {width:134px;}
                > strong, > em {color:#4a4a4a;}
                > strong {letter-spacing:-0.28px;}
                > em {margin-top:12px;padding-top:63px;letter-spacing:-0.55px;}
                > p {margin-top:3px;color:#a6a6a6;letter-spacing:-0.55px;}
                //아이콘
                &[class*="ico-type"] > em {background-repeat:no-repeat;background-position:center top;}
                &.ico-type1 > em {background-image:url('../imgs/ico/ic_resident_none.png');}//주민등록번호
                &.ico-type2 > em {background-image:url('../imgs/ico/ic_foreigner_none.png');}//외국인등록번호
                &.ico-type3 > em {background-image:url('../imgs/ico/ic_passport_none.png');}//여권번호
                &.ico-type4 > em {background-image:url('../imgs/ico/ic_license_none.png');}//운전면허번호
                &.ico-type5 > em {background-image:url('../imgs/ico/ic_account_none.png');}//계좌번호
                &.ico-type6 > em {background-image:url('../imgs/ico/ic_credit_none.png');}//카드번호
                //비활성화
                &.dis {
                    > strong, > em {color:#a6a6a6;}
                    &.ico-type5 > em {background-image:url('../imgs/ico/ic_account_disable.png');}//계좌번호
                    &.ico-type6 > em {background-image:url('../imgs/ico/ic_credit_disable.png');}//카드번호
                }
            }
        }
    }

    /* 타입27 : 정보안내 */
    .data-area27 {overflow:hidden;position:relative;display:flex;align-items:center;height:80px;padding:10px 20px;border-radius:3px;border:1px solid #e6e6e6;color:#000;box-sizing:border-box;
        //타이틀 hidden
        [class*="area"] > div:not(.sum) > i,
        [class*="area"] > div > span > i {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
        //제목 + 문서번호 + 기안일
        .area1 {overflow:hidden;flex:1;
            > [data-orbit-component="OBTTooltip"] {display:block;}//툴팁
            strong {overflow:hidden;display:block;font-size:13px;line-height:initial;letter-spacing:-0.33px;white-space:nowrap;text-overflow:ellipsis;}
            > .sub {display:flex;align-items:center;margin-top:4px;font-size:11px;color:#8c8c8c;
                > span + span:before {content:'';display:inline-block;width:1px;height:8px;margin:0 5px;background:#e6e6e6;}
            }
        }
        //브랜드 + 품목명칭 + 사진 + 품목단가 + 품목수량
        .area2 {overflow:hidden;flex:1;padding-left:72px;
            > .ti {
                > span:not(.pic) {display:block;font-size:11px;color:#8c8c8c;}
                > [data-orbit-component="OBTTooltip"] {display:block;}//툴팁
                strong {overflow:hidden;display:block;font-size:13px;line-height:initial;letter-spacing:-0.33px;white-space:nowrap;text-overflow:ellipsis;}
                > .pic {overflow:hidden;position:absolute;left:20px;top:50%;display:flex;justify-content:center;align-items:center;width:58px;height:58px;margin-top:-29px;border-radius:6px;background:url('../imgs/empty/img_present_emptyset.png') no-repeat;
                    &:not(:empty):before {content:'';position:absolute;left:0;right:0;top:0;bottom:0;background:#fff;}//img bg
                    &:not(:empty):after {content:'';position:absolute;left:0;right:0;top:0;bottom:0;border-radius:6px;border:1px solid rgba(0, 0, 0, 0.1);box-sizing:border-box;}//img border
                    img {position:relative;max-width:100%;max-height:100%;}
                }
            }
            > .sub {display:flex;align-items:center;margin-top:2px;font-size:11px;color:#4a4a4a;
                > span + span:before {content:'';display:inline-block;width:1px;height:8px;margin:0 5px;background:#e6e6e6;}
            }
        }
        //기안자 / 결재상태 / 총액
        .area3 {display:inline-flex;justify-content:flex-end;align-items:center;
            > div {display:inline-flex;align-items:center;margin-left:20px;
                &:before {content:'';display:inline-block;width:1px;height:18px;margin-right:20px;background:#e6e6e6;}
                //기안자
                &.name {font-size:12px;letter-spacing:-0.3px;
                    > span:last-child {max-width:100px;margin-left:5px;}
                }
                //결재상태
                &.state {
                    > [class*="state-type1"] {line-height:16px !important;
                        &:before {margin-top:0;}
                    }
                }
                //총액
                &.sum {min-width:calc(100% - 20px);
                    > i {font-size:12px;color:#4a4a4a;}
                    > span {flex:1;margin-left:20px;font-size:13px;font-weight:bold;letter-spacing:-0.33px;text-align:right;}
                }
            }
        }

        /* width 설정 */
        &.t1 {
            //기안자 / 결재상태 / 총액
            .area3 {width:27%;}
        }

        /* EmptySet */
        .no_data {width:100%;}
    }

    /* 타입28 : 개인화 리스트 ----------------- .data-area8 신규 버전(개발 완료 후 이전 버전(.data-area8/.data-area8.t1/.data-area8.t2) 삭제 예정) */
    //타입28-1
    .data-area28 {
        //리스트
        .content {overflow:hidden;position:relative;display:flex;flex-direction:column;height:100%;
            //heading(컨텐츠헤딩)
            > .heading {margin-bottom:0;padding:0 20px;}
            //뷰타입 : 리스트
            > .heading + div, > .cont {flex:1;}
            .cont {background:#f5f8fa;
                > div {
                    > dl {overflow:auto;position:relative;min-height:100%;padding:18px 20px 20px;box-sizing:border-box;
                        &:before {content:'';position:absolute;left:28px;top:25px;bottom:0;width:1px;background:#e9e9e9;}
                        dt {position:relative;display:flex;align-items:center;height:20px;margin-bottom:12px;z-index:1;
                            &:not(:first-child) {margin-top:20px;}
                            > strong {font-size:14px;letter-spacing:-0.7px;}
                            .btn {display:flex;margin-left:5px;
                                li {margin:1px 0 -1px 6px;
                                    button, button:hover {height:18px;padding:0 6px;border:none;border-radius:9px;color:#fff;
                                        &.type1 {background:#f0c325;}
                                        &.type2 {background:#748ffc;}
                                        &.type3 {background:#ff8787;}
                                        &.type4 {background:#ac91f5;}
                                        &.type5 {background:#55cc91;}
                                        &.type6 {background:#53abfe;}
                                    }
                                }
                            }
                        }
                        dd {display:flex;align-items:center;min-height:24px;margin:6px 0 0 28px;padding:10px 9px;border:1px solid #e6e6e6;border-radius:4px;background:#fff;color:#000;line-height:1.33;letter-spacing:-0.33px;
                            &:hover {border-color:#1c90fb;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);}//Over
                            &.on {border-color:#1c90fb;background:#eff7ff;}//Select
                            > span, > strong, > div {padding:0 10px;}
                            > span:not(.checkbox):not(.time):not(:first-child), > strong:not(:first-child), > div:not(:first-child) {position:relative;padding-left:11px;
                                &:before {content:'';position:absolute;left:0;top:50%;width:1px;height:16px;margin-top:-8px;background:#e6e6e6;}//구분라인
                            }
                            > .checkbox {width:18px;text-align:center;}//체크박스
                            > .time {width:55px;color:#8c8c8c;text-align:center;}//시간
                            > .ti {display:flex;flex-wrap:wrap;align-items:center;flex:1;padding-left:34px !important;//타이틀
                                > [data-orbit-component="OBTButton"] {position:absolute;left:7px;top:50%;height:24px;margin-top:-12px;}//버튼타입 : icon
                                > strong {position:relative;font-weight:normal;
                                    //아이콘
                                    &[class*="ico"] {padding-left:4px;
                                        &:before {content:'';position:absolute;left:-20px;top:50%;width:18px;height:18px;margin-top:-9px;background-repeat:no-repeat;background-position:0 0;}
                                    }
                                    &.ico-type1:before {background-image:url('../imgs/ico/ic_debt_balance_m_normal.png');}//거래처별 채무잔액 이체
                                    &.ico-type2:before {background-image:url('../imgs/ico/ic_debt_sum_m_normal.png');}//거래건별 채무금액 이체
                                    &.ico-type3:before {background-image:url('../imgs/ico/ic_paymentslip_m_normal.png');}//지급전표금액 이체
                                    &.ico-type4:before {background-image:url('../imgs/ico/ic_fixmoney_m_normal.png');}//확정자금 이체
                                    &.ico-type5:before {background-image:url('../imgs/ico/ic_expenseclaims_m_normal.png');}//임직원 경비청구금액 이체
                                    &.ico-type6:before {background-image:url('../imgs/ico/ic_advancedmoney_m_normal.png');}//전도금 이체
                                }
                            }
                            > .card-type {width:12%;}//카드타입
                            > .appr-num {width:12%;text-align:center;}//승인번호
                            > strong {display:flex;align-items:center;justify-content:flex-end;width:24%;text-align:right;//금액
                                > [class*="state-type"] {margin-top:1px;}
                                > del {flex:1;}
                            }
                            > .info1 {display:flex;align-items:center;width:15%;//금액(항목명 + 금액)
                                > i {font-size:12px;color:#8c8c8c;}
                                > strong {flex:1;padding-left:10px;text-align:right;}
                            }
                            > .btn {width:15%;text-align:right;//버튼
                                > div {vertical-align:middle;
                                    & + div {margin-left:15px;
                                        > [class*="OBTButton_typeicon"] {height:24px;margin-right:-4px;}//버튼타입 : icon
                                    }
                                }
                            }
                            > .ti + .btn:before {display:none;}//타이틀 + 버튼
                        }
                    }
                }
            }
            //뷰타입 : 달력
            .cont1 {overflow:auto;flex:1;padding:20px;box-sizing:border-box;}
            //emptyData(content 내부)
            > .no_data {flex:1;}
        }
        //emptyData
        .no_data {flex-direction:column;background:#f5f8fa;
            > p {margin-top:7px;line-height:1.5;font-size:12px;letter-spacing:-0.6px;}
            > [data-orbit-component="OBTButton"] {margin-top:12px;}
        }
    }
    //타입28-2
    .data-area28.t1 {
        //리스트
        .content {
            //뷰타입
            .cont {
                //공통
                > div {
                    //헤딩영역 + 리스트(table)
                    > dl {padding-bottom:14px;
                        dt:not(:first-child) {margin-top:14px;}
                        dd {flex-direction:column;padding:0;border:none;background:none;
                            &:hover {box-shadow:none;}//Over
                            > span, > strong, > div {padding:0;}//기본 여백 해제
                            > span:not(.checkbox):not(.time):not(:first-child), > strong:not(:first-child), > div:not(:first-child) {padding-left:0;//기본 여백 해제
                                &:before {display:none;}//구분라인 해제
                            }
                        }
                    }
                    //only 리스트(table)
                    table thead tr th {background:#ecf1f5;}
                    > table {width:calc(100% - 40px);
                        &.data-tbl14-head {margin:20px 20px 4px;}//head
                        &.data-tbl14 {margin:0 20px 14px;}//body
                    }
                }
                //테이블 head 고정, body 스크롤
                &.t1 > div {overflow-x:hidden !important;height:100%;
                    > dl {display:flex;flex-direction:column;
                        dd {flex:1;margin-top:0;
                            > div {flex:1;width:100%;box-sizing:border-box;}
                        }
                    }
                    //only 리스트(table)
                    &.t1 {display:flex;flex-direction:column;box-sizing:border-box;
                        > div {flex:1;
                            table {width:calc(100% - 40px);
                                &.data-tbl14-head {margin:20px 20px 4px;}//head
                                &.data-tbl14 {margin:0 20px 14px;}//body
                            }
                        }
                    }
                }
            }
        }
    }
    //타입28-3
    .data-area28.t2 {
        //리스트
        .content {
            //뷰타입
            .cont {
                > div {
                    > dl {
                        &:before {display:none;}
                        dt {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                        dt + dd {margin-top:2px;}
                        dd {min-height:27px;margin-left:0;padding:8px 0 9px;font-size:13px;
                            &:first-child {margin-top:2px;}
                            &.on {position:relative;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);z-index:1;}//Select
                            > span, > strong, > div {padding:0 20px;}
                            > span:not(.checkbox):not(.time):not(:first-child), > strong:not(:first-child), > div:not(:first-child) {padding-left:21px;}
                            > .ti {padding-left:39px !important;//타이틀
                                > strong {font-weight:bold;}
                            }
                            //서브 컨텐츠
                            &.sub-area {display:none;
                                &:hover {box-shadow:none;}//Over
                                > span, > strong, > div {padding:0;}//기본 여백 해제
                                > span:not(.checkbox):not(.time):not(:first-child), > strong:not(:first-child), > div:not(:first-child) {padding-left:0;//기본 여백 해제
                                    &:before {display:none;}//구분라인 해제
                                }
                            }
                            &.open {display:block;margin-top:-5px;padding:24px 20px 14px;border-radius:0 0 4px 4px;border-color:#1c90fb;background:#e2e8f2;}
                        }
                    }
                }
            }
        }
    }

    /* 타입29 : 정보안내(이미지 / 정보안내) */
    .data-area29 {width:210px;min-height:315px;padding:19px;border-radius:16px;border:1px solid #e2e2e2;box-sizing:border-box;
        //이미지
        .pic {overflow:hidden;position:relative;display:flex;justify-content:center;align-items:center;width:170px;height:170px;border-radius:8px;background:#f6f7f9 url('../imgs/empty/img_present_emptyset_l.png') no-repeat 50%;
            &:not(:empty):before {content:'';position:absolute;left:0;right:0;top:0;bottom:0;background:#fff;}//img bg
            &:not(:empty):after {content:'';position:absolute;left:0;right:0;top:0;bottom:0;border-radius:8px;border:1px solid rgba(0, 0, 0, 0.1);box-sizing:border-box;}//img border
            img {position:relative;max-width:100%;max-height:100%;}
        }
        //정보
        .info {margin-top:18px;font-size:12px;letter-spacing:-0.6px;
            > li {display:flex;
                & + li {margin-top:16px;}
                > i {color:#939393;}//타이틀
                > span {overflow:hidden;flex:1;margin-left:10px;color:#222;text-align:right;white-space:nowrap;text-overflow:ellipsis;}//내용
            }
        }
    }

    /* 타입30 : 정보안내(이미지 / 정보입력) */
    .data-area30 {display:flex;min-height:315px;padding:19px;border-radius:16px;border:1px solid #c1d9f0;background-image:linear-gradient(292deg, #d4eaff, #f2faff);box-sizing:border-box;
        //이미지
        .pic-area {position:relative;
            //이미지
            > .pic {overflow:hidden;position:relative;display:flex;justify-content:center;align-items:center;width:318px;height:275px;border-radius:8px;background:#f6f7f9;
                &:before {content:'';position:absolute;left:50%;top:50%;width:150px;height:150px;margin-left:-75px;margin-top:-75px;background:url('../imgs/empty/img_present_emptyset_l.png') no-repeat 50%;}
                &:after {content:'';position:absolute;left:0;right:0;top:0;bottom:0;border-radius:8px;border:1px solid rgba(0, 0, 0, 0.1);box-sizing:border-box;}
                img {position:relative;max-width:100%;max-height:100%;}
            }
            //버튼
            > .btn {position:absolute;top:120px;width:100%;z-index:1;
                > div button {width:36px;height:36px;}
                > div:nth-child(1) {position:absolute;left:8px;}//다음
                > div:nth-child(2) {position:absolute;right:8px;}//이전
            }
        }
        //정보입력
        .info {flex:1;margin-left:19px;
            > div {position:relative;
                //제목
                > [data-orbit-component="OBTTextField"] {width:100%;
                    > div:first-child > div {height:26px !important;padding:3px 45px 3px 9px !important;border-radius:6px;}
                }
                //메세지
                > [data-orbit-component="OBTMultiLineTextField"] {overflow:hidden;height:calc(100% - 15px);border-radius:6px;
                    textarea {padding:11px 9px !important;border-radius:6px;background:#fff !important;}
                }
                //카운트
                > span {position:absolute;right:11px;bottom:9px;margin-top:-9px;font-size:11px;letter-spacing:-0.55px;text-align:right;color:#939393;
                    > strong {color:#222;font-weight:normal;}
                }
            }
            > div + div {height:calc(100% - 41px);margin-top:7px;
                //카운트
                > span {right:2px;bottom:-2px;margin-top:0;font-size:10px;}
            }
        }
    }

    /* 타입31 : 정보안내(정보 / 그래프) */
    .data-area31 {display:flex;min-height:246px;padding:30px 40px;border-radius:10px;background:#fff;box-shadow:0 3px 8px 0 rgba(0, 0, 0, 0.08);box-sizing:border-box;
        //기본정보
        > .heading {width:265px;
            //타이틀 + 컴포넌트(input)
            > .ti {font-size:18px;line-height:1.33;letter-spacing:-0.9px;
                //컴포넌트(input)
                > strong + [data-orbit-component] {margin-top:27px;}
                > [data-orbit-component] {margin-bottom:8px;}
            }
            //버튼
            > [data-orbit-component="OBTButton"] {width:100%;margin-top:12px;
                button {width:100%;}
            }
        }
        //그래프(진행률)
        > .graph {flex:1;margin-left:40px;padding-left:50px;border-left:1px solid #e6e6e6;box-sizing:border-box;}
    }

    /* 타입32 : 정보영역 + 일러스트(모션) */
    .data-area32 {overflow:hidden;position:relative;min-width:708px;max-width:922px;height:550px;padding:70px;border-radius:10px;background:#fff;box-shadow:0 5px 10px 0 rgba(52, 75, 105, 0.1);box-sizing:border-box;z-index:1;
        //타이틀 + 이름
        > strong {display:block;margin-top:2px;font-size:27px;letter-spacing:-1.35px;font-weight:normal;}
        //문구
        > p {margin-top:10px;font-size:22px;line-height:1.36;letter-spacing:-0.55px;}
        //날짜
        > .date {display:flex;align-items:center;margin-top:19px;font-size:14px;letter-spacing:-0.3px;color:#848a95;
            > [class*="tag-type"] {margin-bottom:-2px;margin-right:5px;}//태그아이콘
        }
        //버튼
        > [data-orbit-component="OBTButton"] {margin-top:34px;}
        //일러스트(모션)
        > .visual {overflow:hidden;position:absolute;left:0;bottom:0;width:922px;height:550px;z-index:-1;}
    }

    /* 타입33 : 정보영역 */
    .data-area33 {padding:34px 32px;border-radius:10px;background:#fff;box-shadow:0 5px 10px 0 rgba(52, 75, 105, 0.1);box-sizing:border-box;
        > div, > ul, > ol, > dl, > p, > table {
            &:not(:first-child) {margin-top:20px;}
        }
        //타이틀
        > strong {display:block;margin-top:9px;font-size:14px;letter-spacing:-0.35px;font-weight:normal;}
        //문구
        > p {margin-top:8px !important;font-size:18px;line-height:1.33;letter-spacing:-0.45px;}
        //그래프
        > .graph {width:100%;height:183px;margin-top:12px !important;padding-bottom:27px;text-align:center;box-sizing:border-box;
            ul {position:relative;display:flex;justify-content:space-evenly;align-items:flex-end;height:100%;border-bottom:1px solid #e6e6e6;padding:0 5px;box-sizing:border-box;
                &:before {content:'';position:absolute;left:0;right:0;top:0;bottom:0;background:repeating-linear-gradient(to top, #fff, #fff calc(20% - 1px), #f2f2f2 calc(20% - 1px), #f2f2f2 20%);}
                li {animation:chart-fill2 0.5s ease-out;position:relative;width:50px;height:0;border-radius:5px 5px 0 0;background:#4eabfa;box-shadow:2px 0 4px 0 rgba(0, 0, 0, 0.1);z-index:1;
                    > span {position:absolute;left:0;bottom:-27px;width:100%;font-size:11px;color:#4a4a4a;letter-spacing:-0.28px;}
                    &.bg {background:#ff7f72;}//납부
                }
                @keyframes chart-fill2 {
                    from {height:0;}
                }
            }
        }
        //정보
        > .info {
            > li > [data-orbit-component="OBTTooltip"], > li {display:flex;align-items:center;min-height:54px;padding:10px 20px;border-radius:4px;border:1px solid #e6e6e6;background:#fff;font-size:13px;box-sizing:border-box;
                & + li {margin-top:10px;}
                //항목
                > span:nth-child(1) {display:inline-flex;align-items:center;
                    [class*="tag-type"] {margin:1px 0 0 7px;}
                }
                //금액
                > span:nth-child(2) {display:inline-flex;justify-content:flex-end;align-items:center;flex:1;min-width:0;margin-left:10px;text-align:right;
                    > strong {overflow:hidden;margin-top:-1px;font-size:16px;letter-spacing:-0.32px;line-height:1.2;}
                    > i {margin-left:1px;letter-spacing:-0.65px;}//단위
                }
            }
            //툴팁
            > li {position:relative;
                > [data-orbit-component="OBTTooltip"] {width:calc(100% + 40px);min-height:52px;margin:-10px -20px;border:none;line-height:normal;
                    //금액
                    > span:nth-child(2) {
                        > strong {white-space:nowrap;text-overflow:ellipsis;}
                    }
                }
            }
        }
    }

    /* 타입34 : 정보영역 + 일러스트(모션) */
    .data-area34 {display:flex;align-items:center;justify-content:center;min-height:200px;padding:19px 49px;border-radius:8px;border:1px solid #e6e6e6;background:linear-gradient(to bottom, #e6f5ff, #fff);line-height:1.36;box-sizing:border-box;
        //일러스트(모션)
        > .visual {width:240px;height:160px;}
        //정보
        > .info {margin-left:15px;
            //heading
            > .heading {font-size:16px;letter-spacing:-0.4px;color:#000;
                > strong {display:block;margin-top:4px;font-size:24px;letter-spacing:-0.6px;}
            }
            //문구
            > .txt1 {margin-top:10px;font-size:14px;letter-spacing:-0.7px;color:#a6a6a6;}
        }
        //red
        &.t1 {background:linear-gradient(to bottom, #fff8ef, #fff);}
    }

    /* 타입35 : 정보영역 + 일러스트(모션) */
    .data-area35 {overflow:hidden;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:50px 49px 49px;border-radius:8px;border:1px solid #e6e6e6;background:linear-gradient(to bottom, #f0f9ff, #fff 568px);line-height:1.36;box-sizing:border-box;
        //heading
        > .heading {font-size:20px;letter-spacing:-0.5px;color:#000;text-align:center;
            > strong {display:block;margin-top:5px;font-size:28px;letter-spacing:-0.7px;}
        }
        //일러스트(모션)
        > .visual {position:relative;width:700px;height:272px;margin-top:11px;z-index:1;
            &:before {content:'';position:absolute;left:-1000%;right:-1000%;top:210px;height:225px;background:linear-gradient(to bottom, #e3f4ff, #fff 225px);z-index:-1;}
        }
        //정보
        .info {position:relative;display:flex;align-items:center;height:56px;margin-top:30px;border-radius:8px;border:1px solid #e6e6e6;background:#fff;box-sizing:border-box;z-index:1;
            > li {display:flex;align-items:center;min-width:200px;height:16px;padding:0 20px;font-size:13px;
                & + li {border-left:1px solid #e6e6e6;}
                //금액
                > span:nth-child(2) {display:inline-flex;justify-content:flex-end;align-items:center;flex:1;min-width:0;margin-left:10px;text-align:right;
                    > strong {font-size:15px;letter-spacing:-0.38px;}
                    > i {margin-left:1px;letter-spacing:-0.33px;}//단위
                }
            }
        }
        //문구
        > .txt1 {position:relative;margin-top:11px;letter-spacing:-0.3px;color:#8c8c8c;text-align:center;z-index:1;
            > b {color:#333;}
        }
        //red
        &.t1 {background:linear-gradient(to bottom, #fffaf4, #fff 568px);
            //일러스트(모션)
            > .visual:before {background:linear-gradient(to bottom, #fff5e6, #fff 225px);}
        }
    }

	/* 타입36 : 이미지영역 + 정보 + 그래프 */
	.data-area36 {position:relative;display:flex;align-items:center;padding:15px 50px 15px 25px;border-radius:4px;background:#fff;box-shadow:0 2px 5px 0 rgba(219, 228, 235, 0.71);
		ul {flex:1;display:inline-flex;align-items:center;padding-left:135px;;
			li {position:relative;display:flex;flex-wrap:wrap;justify-content:center;align-items:center;align-content:center;flex:1;min-height:60px;padding:0 20px;text-align:center;
				&:before {content:'';position:absolute;left:0;top:0;bottom:0;width:1px;height:100%;background:#e6e6e6;}
                //상태
				> span {display:flex;align-items:center;justify-content:center;width:100%;font-size:13px;letter-spacing:-0.33px;color:#4a4a4a;}
                //건수
				strong {width:100%;font-size:20px;letter-spacing:-0.5px;
					> i {font-size:16px;letter-spacing:-0.4px;font-weight:normal;}//단위
				}
                //전체
                &.type0 > div {display:inline-flex;flex-direction:column;text-align:left;
                    //버튼
                    > [data-orbit-component="OBTButton"] > button {height:19px;
                        [class*="OBTButton_labelText"] {letter-spacing:-0.33px;}
                    }
                }
                //블릿
				&[class*="type"] > span:before {content:'';display:inline-block;width:8px;height:8px;margin-right:5px;border-radius:50%;}
				&.type0 > span:before {background:#436ebd;}//전체
				&.type1 > span:before {background:#46a3f0;}//진행중
				&.type2 > span:before {background:#20c997;}//작성완료
				&.type3 > span:before {background:#9da3aa;}//마감완료
				&.type4 > span:before {background:#ff8787;}//마감취소요청
			}
		}
		//아이콘
		[class*="info"] {
            &:before {content: '';position:absolute;top:50%;left:25px;margin-top:-55px;display:inline-block;width:110px;height:110px;background-repeat:no-repeat;background-position:0 0;}
        }
		.info1 {//연말정산현황
            &::before {background-image:url('../imgs/ico/img_tax_dashboard.png');}
        }
	}

    /*----------------------------------------------------
        [배너]
    ----------------------------------------------------*/
    /* 공통 */
    [class*="banner-area"] {display:flex;align-items:center;width:100%;padding:15px 33px;border:1px solid #e2eaf8;border-radius:4px;background-image:linear-gradient(to right, #f2f6ff 2%, #e5eeff 97%);box-sizing:border-box;
        > div {align-self:center;
            &:first-child {display:inline-flex;align-items:center;flex:1;padding-right:30px;font-size:13px;color:#5c7198;letter-spacing:-0.65px;}
            &:last-child button:not(.img-btn2) {height:30px;padding:0 17px;border:none;border-radius:15px;background:rgba(255, 255, 255, 0.8) !important;box-shadow:0 3px 6px 0 rgba(48, 103, 193, 0.2);
                &:hover {border:none !important;}
                span {font-size:11px !important;font-weight:bold;
                    &:after {content:'';display:inline-block;margin-left:11px;border:4px solid transparent;border-right:none;border-left-color:#62666c;}
                }
            }
        }
    }

    /* 타입1 */
    .banner-area1 {height:70px;padding-left:173px;padding-right:32px;background-color:#ebf1ff;background-image:url('../imgs/bg/img_certificate_request_left.png'), url('../imgs/bg/img_certificate_request_right.png');background-position:left top, right top;background-repeat:no-repeat;
        > div:first-child {
            > strong {font-size:18px;color:#0c2b65;letter-spacing:-0.9px;}
            > span {padding-left:20px;}
        }
    }
    
    /* 타입2 */
    .banner-area2 {height:50px;padding-left:249px;padding-right:32px;background-image:url('../imgs/bg/img_wehago_login_left.png'), url('../imgs/bg/img_wehago_login_right.png'), linear-gradient(to right, #e4f4ff, #e6f6ff 64%, #ebf9ff);background-position:left top, right top;background-repeat:no-repeat;
        > div {
            &:first-child {padding-right:0;width:calc(100% - 249px);
                > div {font-size:13px;color:#0c2b65;font-weight:bold;letter-spacing:-0.65px;
                    &.cont {width:calc(100% - 102px);text-align:right;
                        > div {display:inline-block;margin:0 20px;max-width:calc(100% / 3 - 41px);vertical-align:top;
                            > div {vertical-align:top;}
                            &:nth-child(1), &:nth-child(2), &:nth-child(3), &[class*="ico"] {/* 아이콘별 클래스명 추가 */
                                &:only-child {max-width:calc(100% - 41px);}
                                &:before {content:'';margin-right:8px;display:inline-block;width:26px;height:26px;border-radius:50%;background-color:rgba(163, 211, 255, 0.9);background-repeat:no-repeat;background-position:50% 50%;vertical-align:middle;}
                                [data-orbit-component="OBTTooltip"] {overflow:hidden;width:auto;max-width:calc(100% - 34px);white-space:nowrap;text-overflow:ellipsis;line-height:25px;text-align:left;}
                                em {margin-left:2px;font-size:12px;color:#5c7198;letter-spacing:-0.6px;font-weight:normal;}
                            }
                            //회사명
                            &.ico-comp {
                                &:before {background-image:url('../imgs/ico/ic_comp02_white_m_normal.png');}
                                [data-orbit-component="OBTTooltip"] {max-width:calc(100% - 59px);}
                            }
                            //포인트
                            &.ico-point {
                                &:before {background-image:url('../imgs/ico/ic_point_white_m_normal.png');}
                                [data-orbit-component="OBTTooltip"] {max-width:calc(100% - 91px);
                                    > strong {padding-right:2px;}
                                }
                                > em {display:inline-block;width:32px;margin-left:0;}
                            }
                            //사용자
                            &.ico-user {
                                &:before {background-image:url('../imgs/ico/ic_user_white_m_normal.png');}
                                [data-orbit-component="OBTTooltip"] {max-width:calc(100% - 34px);}
                            }
                        }
                    }
                }
            }
            &:last-child {display:flex;align-items:center;
                p {margin-right:16px;font-size:13px;color:#5c7198;letter-spacing:-0.65px;}
                button {
                    span {display:flex;align-items:center;
                        &:before {content:'';display:inline-block;width:14px;height:14px;margin-right:6px;background:url('../imgs/ico/ic_onoff_s_normal.png') no-repeat 0 0;}
                        &:after {display:none;}
                    }
                    &:hover span {color:#1c90fb;
                        &:before {background-image:url('../imgs/ico/ic_onoff_s_over.png');}
                    }
                }
            }
        }
    }

    /* 타입3 */
    .banner-area3 {width:738px;height:60px;padding-left:62px;padding-right:32px;border:none;border-radius:0;background:url('../imgs/bg/img_mail_resend.png') no-repeat 0 0;
        > div:first-child {
            > strong {font-size:18px;color:#0c2b65;letter-spacing:-0.9px;}
            > span {padding-left:9px;font-size:12px;}
        }
    }

	/* 타입4 */
    .banner-area4 {justify-content:center;padding:16px 119px 15px 119px;border:1px solid #ccdfe8;background-image:linear-gradient(to right, #fff 3%, #f0faff 98%);background-position:right top;
		> div:first-child {flex-direction:column;align-items:flex-start;justify-content:center;min-width:754px;min-height:114px;padding-right:0;padding-left:180px;background:url('../imgs/bg/img_custom_board.png') no-repeat left center;box-sizing:border-box;
			> strong {font-size:18px;color:#1c90fb;letter-spacing:-0.9px;}
			> span {margin-top:2px;font-size:13px;color:#000;letter-spacing:-0.65px;opacity:0.7;}
		}
		.img-btn2 {margin-top:14px;}//버튼
	}

    /*----------------------------------------------------
        [STEP]
    ----------------------------------------------------*/
    /* 타입1(공통) */
    [class*="step-list"] {display:flex;text-align:center;
        //big
        > li {position:relative;flex:1;height:48px;padding:0 21px 0 26px;border:1px solid #e0e0e0;line-height:46px;color:#000;box-sizing:border-box;
            &:not(:first-child).on:before, &:not(:last-child):after {content:'';position:absolute;top:50%;width:27px;height:23px;margin-top:-12px;border:solid #ccc;border-width:1px 1px 0 0;background:#fff;transform:rotate(60deg) skewX(28deg);z-index:1;}
            &:before {left:-15px;}
            &:after {right:-15px;}
            &:not(:first-child) {margin-left:-1px;padding-left:39px;}
            &:last-child {padding-right:34px;}
            //step
            > b {display:inline-block;padding-right:8px;color:#1c90fb;}
            //선택버튼
            [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
                > button {width:100%;height:100%;border:none !important;background:transparent !important;opacity:0;
                    [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}
                    &:focus {position:relative;opacity:1;z-index:2;}
                }
            }
            &.on {border-color:#1c90fb;background:#1c90fb;color:#fff;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);z-index:1;
                &:before {border-color:#e1e4e7 !important;}
                &:after {border-color:#1c90fb !important;background:#1c90fb;}
                & ~ li, & ~ li:after {border-color:#ccc;}
                > b {color:#fff;}
            }
        }
        //Small
        &.t1 {display:inline-block;text-align:left;vertical-align:top;
            > li {display:inline-block;min-width:110px;height:32px;border-radius:2px;line-height:30px;text-align:center;vertical-align:top;
                &:not(:first-child).on:before, &:not(:last-child):after {width:18px;height:15px;margin-top:-8px;}
                &:before {left:-10px;}
                &:after {right:-10px;}
                &:not(:first-child) {padding-left:30px;}
                &:last-child {padding-right:26px;
                    .count {right:7px;}
                }
                //카운트
                .count {position:absolute;right:2px;top:3px;min-width:22px;height:14px;padding:0 3px;border-radius:7px;background:#fc5356;font-size:10px;line-height:14px;color:#fff;text-align:center;box-sizing:border-box;z-index:2;}
            }
        }
    }

    /* 타입2 */
    .step-list2 {
        //big
        > li {border-color:#e6e6e6;color:#a6a6a6;
            &:not(:first-child).on:before, &:not(:last-child):after {border-color:#e6e6e6;}
            //step
            > b {display:inline-block;height:18px;border-radius:10px;background-color:#e6e6e6;margin-right:4px;padding:0 7px;font-size:9px;line-height:18px;color:#a6a6a6;font-weight:normal;vertical-align:text-bottom;
                > i {padding-left:1px;font-size:11px;font-weight:bold;}
            }
            &.on {border-color:#1c90fb;background:#e9f5ff;color:#1c90fb;font-weight:bold;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.2);
                &:before {border-color:#1c90fb !important;}
                &:after {border-color:#1c90fb !important;background:#e9f5ff;}
                & ~ li, & ~ li:after {border-color:#e6e6e6;}
                > b {background-color:#1c90fb;color:#fff;}
                .count {font-weight:normal;}
            }
        }
        //Small
        &.t1 {
            > li {font-size:13px;letter-spacing:-0.33px;}
        }
    }

    /*----------------------------------------------------
        [지도]
    ----------------------------------------------------*/
    .map-area {width:100%;border:1px solid #d9d9d9;box-sizing:border-box;
        //타입1
        &.t1 {border-color:#e6e6e6;}
    }

    /*----------------------------------------------------
        [도면]
    ----------------------------------------------------*/
    .drawing-area {overflow:hidden;position:relative;width:100%;min-height:50px;border:1px solid #e6e6e6;text-align:center;box-sizing:border-box;
        img {width:100%;height:100%;}
        //상세보기 버튼
        > [data-orbit-component="OBTButton"] {position:absolute;left:0;right:0;top:0;bottom:0;
            > button {width:100%;height:100%;padding:0;border:none !important;background:none !important;
                [class*="OBTButton_labelText"] {font-size:0;line-height:0;opacity:0;}//labelText
                &:hover, &:focus {background:url('../imgs/ico/icon_preview.png') no-repeat 50% 50% !important;}
            }
        }
    }

    /*----------------------------------------------------
        [저장매체선택]
    ----------------------------------------------------*/
    .save-choice {
        > div {display:inline-block;width:50%;
            button {width:100%;height:56px;border-color:#dbdbdb;border-radius:0;background:#fff;background-image:none !important;font-size:15px;color:#4a4a4a;letter-spacing:-0.38px;font-weight:bold;
                span {line-height:54px;
                    &:before, &:after {content:'';display:inline-block;vertical-align:middle;}
                    &:before {width:30px;height:30px;margin-right:4px;background-repeat:no-repeat;}
                    &:after {width:18px;height:18px;margin-left:10px;background-image:url('../imgs/ico/ic_arrow_down_01_m_normal.png');}
                }
                &.type1 span:before {background-image:url('../imgs/erp/ic_drive_normal.png');}
                &.type2 span:before {background-image:url('../imgs/erp/ic_usb.png');}
                &.on {position:relative;border-color:#1c90fb !important;background:#e9f5ff;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);color:#1c90fb;z-index:1;
                    &.type1 span:before {background-image:url('../imgs/erp/ic_hard.png')}
                    &.type2 span:before {background-image:url('../imgs/erp/ic_usb_selected.png')}
                    span:after {background-image:url('../imgs/ico/ic_arrow_down_01_m_over.png');}
                }
            }
            &:not(:first-child) {width:calc(50% + 1px);margin-left:-1px;}
        }
    }

    /*----------------------------------------------------
        [데이터 불러오기]
    ----------------------------------------------------*/
    .data-load {margin-top:15px;height:154px;
        dl {padding:11px 19px 19px;height:100%;border:1px solid #e5e5e5;border-radius:3px;background-color:#fafafa;color:#000;letter-spacing:-0.7px;box-sizing:border-box;
            dt {position:relative;padding-bottom:12px;border-bottom:1px solid #d9d9d9;font-weight:900;
                //라디오버튼(ON/OFF 스위치)
                .toggle {position:absolute;right:0;top:1px;}
            }
            dd {padding-top:11px;line-height:1.67;
                em {color:#1c90fb;text-decoration:underline;}
            }
            &:not(:only-child) {display:inline-block;width:calc(50% - 10px);vertical-align:top;}
            &:not(:first-child) {margin-left:20px;}
        }
    }

    /*----------------------------------------------------
        [공통댓글]
    ----------------------------------------------------*/
    .reply_area {height:100%;
        .heading {position:relative;min-height:32px !important;border-bottom:1px solid #dfe7ed !important;background:#dfe7ed !important;
            //show / hide X
            > strong:first-child {height:32px !important;margin-top:0 !important;padding:0 10px;font-size:13px !important;line-height:32px;
                &:before {display:none !important;}
            }
            //카운트
            > .count {flex:1 1 auto;align-self:center;padding-left:15px;background:url('../imgs/ico/ic_comment_s_normal.png') no-repeat 0 2px;}
        }
        .heading ~ div {overflow:auto;}
    }

    /*----------------------------------------------------
        [계산서]
    ----------------------------------------------------*/
    /* 공통 / 상단 정보 */
    .top-area {display:flex;justify-content:space-between;align-items:center;margin-bottom:5px;
        > div:first-child {display:flex;align-items:center;
            > span {color:#1c90fb;margin-left:10px;}
            .btn {margin-left:10px;}
            [data-orbit-component="OBTRadioButtonGroup"] + [data-orbit-component="OBTCheckBox"] {margin-left:20px;}
        }
        > div:last-child, > p {flex:1;display:flex;justify-content:flex-end;}
    }

    /* 타입1 */
    //공통(기본 : 지출)
    .tax-invoice {box-sizing:border-box;
        > table,
        .list > div > table {width:100%;table-layout:fixed;border:solid #828282;border-width:0 2px 1px;border-collapse:collapse;
            > thead, > tbody, > tfoot {
                > tr {
                    > th, > td {height:30px;padding:0 5px;border:1px solid #828282;line-height:30px;letter-spacing:-0.12px;text-align:center;}
                    > th {background:#f4f4f4;}
                    > td {overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
                }
            }
            &:not([class^="info"]) > thead > tr:first-child > th {border-top:none;}
        }
        .info {border:2px solid #3368cd;
            th, td {border-color:#3368cd;}
            th {background:#eef4fd;color:#3368cd;}
            td {background:#fff;text-align:left;}
            tr:first-child th:first-child {padding:0 8px;}
            tr:nth-child(2) th {line-height:1.25;}
            .ti {font-size:18px!important;text-align:left;background:#fff!important;
                span {padding-left:4px;font-size:16px;font-weight:normal;}
            }
            //타이틀, 정보영역 분리
            & + .info {margin-top:-2px;border-top:1px;
                tr:nth-child(1) th {line-height:1.25;}
            }
        }
        .list {position:relative;box-sizing:border-box;
            table {border:none !important;}
            &:before, &:after {content:'';position:absolute;left:0;top:0;bottom:0;width:2px;height:100%;background:#828282;z-index:1;}
            &:after {left:auto;right:0;}
        }
        .total {margin-top:-1px;border-top-width:2px;border-bottom-width:2px;
            .ti {
                th, td {border-top:none;}
                th {background:#f4f4f4;}
                td:last-child {line-height:1.33;font-size:15px;}
            }
        }
        //수입
        &.t1 {
            [class*="info"] {border-color:#e62324;
                th, td {border-color:#e62324;}
                th {background:#fff1f1;color:#e62324;}
            }
        }
    }

    /* 타입2 */
    //공통(기본 : 매입) : 뷰/등록
    [class^="tax-invoice-"] {box-sizing:border-box;
        > p {margin-bottom:5px;}
        table {position:relative;width:100%;table-layout:fixed;border-color:#3368cd;border-style:solid;border-width:2px 2px 0 2px;margin-top:-1px;border-collapse:collapse;
            th {background-color:#eaf2fd;text-align:center;}
            > thead, > tbody, > tfoot {
                > tr {
                    > th, > td {height:30px;line-height:18px;padding:0 5px;border:1px solid #3368cd;white-space:normal;letter-spacing:-0.12px;}
                }
            }
            &[data-orbit-component="OBTFormPanel"] th {text-align:center;}
            &:not([class^="info"]) > thead > tr:first-child > th {border-top:none;}
        }
        [class*="info"] {margin-top:0;
            .ti, .ti > div {font-size:24px;padding-left:25px;text-align:left;background:#fff !important;
                span {font-size:12px;font-weight:normal;padding-left:4px;}
            }
            + [class*="info"] {border-top-width:0;margin-top:-1px;
                .ti2 {font-size:20px;text-align:center;font-weight:bold;}
                & + table {
                    td {text-align:center;}
                }
            }
            [class^="bg"] {height:40px;line-height:40px;background-image:url('../imgs/erp/bg_blue.png'), url('../imgs/erp/bg_blue.png');background-repeat:repeat-x, repeat-y;
                & > div {height:calc(100% - 1px);}
            }

            .bglt {background-position:0 0, 0 0;}
            .bgrt {background-position:0 0 , 100% 0px;
                &:last-child {background-repeat:repeat-x;}
            }
            .bglb {background-position:0 100%, 0 100%;}
            .bgrb {background-position:100% 100%, 100% 100%;
                &:last-child {background-repeat:repeat-x;}
            }
            .bgb {background-repeat:repeat-x;background-position:100% 100%;}
            .bgt {background-repeat:repeat-x;background-position:0 0;}
        }
        .list {position:relative;border-top:1px solid #3368cd;box-sizing:border-box;
            table {border:none !important;}
            &:before, &:after {content:'';position:absolute;left:0;top:0;bottom:0;width:2px;height:100%;background:#3368cd;z-index:1;}
            &:after {left:auto;right:0;}
            //그리드
            [id^="gridWrapper"] {border-top:none;}
        }
        .total {border-bottom-width:2px;
            .ti {
                td:last-child {line-height:1.33;font-size:15px;text-align:center;}
            }
        }
        &.t1 {
            table {
                th {background-color:#fff1f1;}
            }
            [class^="bg"] {height:40px;line-height:40px;background-image:url('../imgs/erp/bg_red.png'), url('../imgs/erp/bg_red.png');}
        }
    }
    //뷰(매출)
    .tax-invoice-out.t1 {
        table {border-color:#e62324;
            > thead, > tbody, > tfoot {
                > tr {
                    > th, > td {border-color:#e62324;}
                }
            }
        }
        .list {border-color:#e62324;
            &:before, &:after {background:#e62324 !important;}
        }
    }
    //등록(기본 : 매입)
    .tax-invoice-regi {
        table {
            > thead, > tbody, > tfoot {
                > tr {height:auto;
                    > th, > td {border-color:#3368cd !important;
                        > div {justify-content:center !important;}
                    }
                    > td {padding:0;
                        //input 컴포넌트 readonly
                        .readonly > div:first-child {background:#fff !important;}
                    }
                }
            }
            &:not(.total) td, &.total tr:not(:first-child) td {
                > div > div:not([data-orbit-component="OBTButton"]), 
                > div > div > div:first-child, 
                > div > div > div:first-child > div {height:100% !important;}
                > div  > div {
                    > div:first-child > div {display:block;padding:0 !important;border:none !important;background:transparent !important;
                        > input {width:calc(100% - 10px) !important;height:100%;padding:0 5px !important;}
                    }
                    .img-btn1 {
                        img {margin:0;}
                    }
                }
                //input + button : input 내부 오른쪽끝 고정
                &.t2 {
                    > div > div {
                        > div > div > input {padding-right:27px !important;}
                    }
                }
            }
        }
        [class*="info"] {
            &[data-orbit-component="OBTFormPanel"] {
                td {
                    &.bglt, &.bgrt, &.bgt {vertical-align:bottom;}
                }
            }
            th, td {padding:0 5px;}
            + [class*="info"] {
                td {padding:0 !important;vertical-align:top;}
                .ti2 {
                    > div {
                        > div:first-child div {
                            input {font-size:20px !important;font-weight:bold;}
                            svg {top:50% !important;margin-top:-7px;}
                        }
                    }
                }
                & ~ table:not(.total) {
                    td:nth-child(3) ~ td [data-orbit-component^="OBT"] > div:first-child {background:#fff1d6 !important;}
                    td:nth-child(3) ~ td [data-orbit-component="OBTTextField"] > div input {font-weight:bold;}
                }
            }
        }
        .list {
            table {
                tr.on {background-color: rgba(28, 144, 251, 0.07);
                    td:before {background:none;}
                }
                input:focus {background-color: rgba(28, 144, 251, 0.07) !important;}
            }
        }
        .total {
            .ti {
                th > div {justify-content:center !important;}
                td:last-child {
                    > div > div {vertical-align:middle;margin:0 10px;
                        label {line-height:1.33 !important;font-size:15px !important;}
                    }
                }
            }
            td:first-child, td:first-child [data-orbit-component="OBTNumberField"] > div:first-child {background:#fff1d6 !important;}
            td {
                [data-orbit-component="OBTRadioButtonGroup"] {
                    span {margin-bottom:0 !important;}
                }
            }
        }
        //매출
        &.t1 {
            table {border-color:#e62324;
                th {background-image:url('../imgs/erp/bg_light_red.png');}
                > thead, > tbody, > tfoot {
                    > tr {
                        > th, > td {border-color:#e62324 !important;}
                    }
                }
            }
            .list {border-color:#e62324;
                &:before, &:after {background:#e62324 !important;}
            }
        }
    }

    /*----------------------------------------------------
        [영수증]
    ----------------------------------------------------*/
    /* 영수증 그룹 사용 */
    .receipt-area {display:flex;flex-wrap:wrap;
        > div:not(.receipt):first-child {display:flex;flex-wrap:wrap;align-content:flex-start;}//심플스크롤
        .receipt {margin:0 0 20px 20px;}//영수증
    }

    /* 영수증 단일 사용 */
    .receipt {position:relative;width:382px;padding:32px 43px 33px 58px;border:1px solid #b8c9db;background:#deeaf7 url('../imgs/erp/img_bill_blue.png') repeat-y;box-sizing:border-box;
        &:before {content:'';position:absolute;left:26px;right:26px;top:11px;bottom:11px;width:calc(100% - 52px);height:calc(100% - 22px);border:1px solid #cad8e5;border-radius:4px;background:#fff;box-sizing:border-box;}
        &:after {content:'';position:absolute;right:0;top:0;bottom:0;width:26px;height:100%;background:url('../imgs/erp/img_bill_blue.png') repeat-y;}
        &:not(.no_data) > div:not([class*="info"]):not([class*="btn"]), table:not([class*="info"]) {height:100%;}//OBTScrollbar
        .info1 {position:relative;margin-right:15px;border-bottom:2px solid #666;
            > div:not(.nolabel) {margin-right:4px;vertical-align:top;}//입력방법 + 버튼(지도)
            > .in-type {display:inline-block;height:25px;line-height:25px;vertical-align:top;//입력방법
                > em {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
                > img {vertical-align:middle;}
            }
            > strong {display:inline-block;max-width:calc(100% - 80px);font-size:17px;font-weight:normal;letter-spacing:-0.85px;}//사용처
            > span {display:block;padding:2px 0;font-size:18px;letter-spacing:-0.9px;text-align:right;//금액
                > strong {margin-right:4px;font-size:30px;letter-spacing:-1.5px;}
            }
            .nolabel {position:absolute;right:0;top:6px;}//선택
        }
        .info2 {position:relative;margin-right:15px;padding-top:19px;letter-spacing:-0.2px;
            ul {
                li {display:flex;align-items:center;min-height:20px;line-height:20px;
                    > span {width:33%;color:#8c8c8c;letter-spacing:-0.6px;}
                    > em {flex:1;overflow:hidden;font-size:13px;text-align:right;}
                }
                &:not(:first-child) {margin-top:12px;padding-top:11px;border-top:1px dashed #ccc;}
            }
        }
        .info3 {position:relative;width:calc(100% - 15px) !important;margin:15px 15px 0 0;border-top:1px solid #888;
            tr {height:35px;}
            th {padding:0 10px;}
            td {padding:0 0 0 3px;}
            //input
            [data-orbit-component="OBTTextField"] > div {background:none !important;
                > div {border:none !important;background:none !important;}
            }
            //OBTDropDownList
            [data-orbit-component="OBTDropDownList"] {width:100%;
                > div {width:100% !important;background:none !important;
                    div > span {border:none !important;background:none !important;text-align:left;
                        > span {background:none !important;color:#000 !important;}
                    }
                }
                &.dis span {color:#8d8d8d !important;}
            }
        }
        .btn {position:relative;margin-right:15px;padding-top:7px;text-align:right;}
        //type small
        &.t1 {display:inline-block;width:calc(100% / 3 - 6.1px);margin-left:9px;padding:27px 27px 28px 42px;vertical-align:top;
            .info2, em {font-size:10px !important;}
            .info1 {
                > strong, > span {font-size:12px !important;}
                > span > strong {font-size:15px !important;letter-spacing:0;}
                > strong {max-width:100%;}//사용처
            }
            .info2 {padding-top:10px;
                ul {
                    li {min-height:16px;line-height:16px;
                        > span {width:38%;}
                        > em {white-space:nowrap;text-overflow:ellipsis;
                            &.ellipsis-no {height:32px;white-space:normal;text-overflow:initial;}
                        }
                    }
                    &:not(:first-child) {margin-top:7px;padding-top:6px;}
                }
            }
        }
        //emptyData
        &.no_data {flex-direction:column;height:auto;border-color:#e5e5e5;background-color:#f2f3f4;background-image:url('../imgs/erp/img_bill_gray.png');
            &:before {border-color:#e5e5e5;}
            &:after {background-image:url('../imgs/erp/img_bill_gray.png');}
            > div {position:relative;}
            > [data-orbit-component="OBTButton"] {margin-top:12px;}
        }
    }

    /*----------------------------------------------------
        [설정영역]
    ----------------------------------------------------*/
    /* 설정(top) + 설명문구(bottom) */
    .setting-type1:not(.area1):not(.area2)  {width:100%;box-sizing:border-box;
        > div:first-child + div {height:50%;margin-top:0 !important;}
        //상세설정
        .area1 {position:relative;border:solid #e6e6e6;border-width:0 1px;border-top:2px solid #666;box-sizing:border-box;
            &:after {content:'';position:absolute;left:0;bottom:0;width:100%;height:1px;background:#e6e6e6;}
            table {border-top:none;}
            > div:first-child > [id^="gridWrapper"] {border:none;}
        }
        //설명문구
        .area2 {height:100%;border:solid #e6e6e6;border-width:0 1px 1px;background:#fafafa;box-sizing:border-box;
            .reference-area3 {
                > div {border:none !important;background:none !important;
                    dt, .text-black {color:#000;}
                }
            }
        }
        /* 타입1 */
        &.t1 > div:first-child + div {height:30%;}
    }
    //only 상세설정
    .setting-type1.area1 {position:relative;border:solid #e6e6e6;border-width:0 1px;border-top:2px solid #666;box-sizing:border-box;
        &:after {content:'';position:absolute;left:0;bottom:0;width:100%;height:1px;background:#e6e6e6;}
        table {border-top:none;}
    }
	//only 설명문구
	.setting-type1.area2 {height:100%;border:solid #e6e6e6;border-top-color:#666;border-width:2px 1px 1px;background:#fafafa;box-sizing:border-box;
		.reference-area3 {
			> div {border:none !important;background:none !important;
				dt, .text-black {color:#000;}
			}
		}
	}

    /*----------------------------------------------------
        [선택/해제이동]
    ----------------------------------------------------*/
    //좌 / 우 / 상 / 하 영역 균등
    [class*="choice-release"] {display:flex;
        > div:not(.btn) {flex:1;}
		> .btn {display:flex;justify-content:center;align-items:center;
            > div {display:block;text-align:center;}
        }
        //가로형
        &:not(.col) {
            > .btn {flex-direction:column;width:47px;
                > div + div {margin-top:4px;}
            }
            > .data-heading + .btn {padding-top:33px;}//버튼위치
            //타이틀 + 검색(1줄) + 리스트
            &.t1 {
                > .data-heading + .btn {padding-top:89px;}//버튼위치
            }
        }
        //세로형
        &.col {flex-direction:column;
            > .btn {padding:20px 0;
                > div + div {margin-left:4px;}
            }
            > .btn + .data-heading {margin-top:-9px;}
        }
	}
    //우측 / 하단 영역 고정사이즈
	.choice-release2 {
		> div:last-child {flex:none;}
	}

    /*----------------------------------------------------
        [OBTDockPanel_right(show / hide)]
    ----------------------------------------------------*/
    /* 타입1 */
    .dock-collapsed {
        > [class*="OBTDockPanel_middle"] > [class*="OBTDockPanel_wrapper"] [class*="OBTDockPanel_fill"] > [class*="OBTDockPanel_wrapper"] {overflow:inherit;}//컴포넌트 초기화
        &.data-heading,
        .data-heading {
            .heading {min-width:370px;}
            .heading ~ div {min-width:370px;}
        }
    }

    /* 타입2 */
    .dock-collapsed1 {border:solid #d9d9d9;border-top-color:#666;border-width:2px 1px 1px 0;color:#8c8c8c;box-sizing:border-box;
        .heading {position:relative;min-width:368px;min-height:32px !important;border-bottom:1px solid #d9d9d9;background:#f8f8f8;
            > strong:first-child {height:32px !important;margin-top:0 !important;padding:0 10px;font-size:13px !important;line-height:32px;
                &:before {display:none !important;}
            }
            > .btn {position:absolute;right:0;top:0;
                button {width:32px;height:32px;border:none !important;
                    & {background:url('../imgs/ico/ic_close_01_m_normal.png') no-repeat 50% 50% !important;}
                    &:hover, &:active {background-image:url('../imgs/ico/ic_close_01_m_over.png') !important;}
                    > div {display:none;}
                }
            }
        }
        .heading ~ div {overflow:auto;min-width:368px;height:calc(100% - 33px) !important;}
    }

    /*----------------------------------------------------
        [서식] 
    ----------------------------------------------------*/
    /* 타입1 : 표 서식 */
    .format-type1 {
        .heading .ti {font-size:20px;margin:10px 0;text-align:center;}
        [class*="data-tbl"] {
            th, td {white-space:normal !important;text-overflow:initial !important;}
        }
        .data-heading + .data-heading {margin-top:10px;}
        p {margin:10px 0;}
        .bottom {
            .date {text-align:right;margin:10px 0;
                span {width:60px;display:inline-block;}
                &:last-child {margin-bottom:0;}
            }
            .source {text-align:center;
                > div {width:50%;text-align:left;
                    &:last-child {width:calc(50% - 10px);margin-left:10px;}
                }
            }
            .info {position:relative;left:42%;width:58%;margin:20px 0 20px;}
            .esquire {font-size:14px;}
        }
        .bullet-square {position:relative;display:inline-block;margin-right:10px;padding-left:16px;
			&:before {content:'';display:block;position:absolute;top:50%;left:0;transform:translateY(-50%);width:10px;height:10px;border:1px solid #000;}
			&.check {position:relative;
				&:after {content:'\2713';display:inline-block;position:absolute;top:calc(50% - 1px);left:0;transform:translateY(-50%);}
			}
		}
    }

    /*----------------------------------------------------
        [SMS발송 : 선택]
    ----------------------------------------------------*/
    .sms-choice {display:flex;flex-wrap:wrap;justify-content:space-between;
        > div {display:flex;flex-direction:column;flex:1;padding:188px 18px 18px;border:1px solid #e6e6e6;border-radius:10px;box-sizing:border-box;
            &:not(:first-child) {margin-left:10px;}
            > ul {flex:1 1 auto;
                > li {padding-left:7px;line-height:1.34;color:#4a4a4a;
                    &:before {content:'';float:left;width:2px;height:2px;margin:7px 0 0 -7px;background:#4f4f4f;}
                    &:not(:first-child) {margin-top:4px;}
                }
            }
            &.sms {background:url('../imgs/ico/img_sms_01.png') no-repeat 43% 19px;}
            &.lms {background:url('../imgs/ico/img_sms_02.png') no-repeat 43% 19px;}
            &.mms {background:url('../imgs/ico/img_sms_03.png') no-repeat 43% 19px;}
            //radio
            > div {margin:25px 11px 0;padding:24px 0 4px;border-top:1px solid #e6e6e6;text-align:center;}
        }
        .guide {width:100%;margin-top:10px;font-size:13px;line-height:1.34;
            > strong:before {content:'';display:inline-block;width:1px;height:10px;margin:-2px 7px 0;background:#e5e5e5;vertical-align:middle;}
        }
    }

    /*----------------------------------------------------
        [근무시간 / 근무패턴]
    ----------------------------------------------------*/
    .pattern-area {border:1px solid #d9d9d9;box-sizing:border-box;
        //공통
        [class*="area"] {position:relative;
            //타이틀
            > .heading2 > strong:first-child > span {color:#666;
                &:before {content:'';display:inline-block;width:1px;height:12px;margin:-2px 9px 0 2px;background:#e6e6e6;vertical-align:middle;}
            }
            //근무시간색상 기본값
            .type i {background:#d9d9d9;}//기본
            .type1 i {background:#46a3f0;}//오전근무
            .type2 i {background:#20c997;}//오후근무
            .type3 i {background:#748ffc;}//주간정상근무
            .type4 i {background:#ac91f5;}//야간근무
            .type5 i {background:#868e96;}//심야근무
            .type6 i {background:#ff8787;}//휴일
            .type7 i {background:#f0c325;}//잔업
            //emptyData
            > .cont .no_data {position:absolute;left:0;top:0;width:100%;height:100%;z-index:0;}
            > .heading2 + .cont .no_data {top:35px;height:calc(100% - 35px);}
        }
        //근무시간
        .area1 {height:145px;
            //리스트
            > .cont {height:100%;text-align:center;
                .pattern-type {height:100%;vertical-align:middle;
                    &:before {content:'';display:inline-block;width:1px;height:100%;vertical-align:middle;}
                    ul {display:inline-flex;flex-wrap:wrap;align-items:center;width:calc(100% - 1px);padding:15px 69px 15px 71px;vertical-align:middle;box-sizing:border-box;
                        > li {position:relative;width:130px;min-height:30px;margin:5px 4px;border:1px solid #d9d9d9;border-radius:4px;background:#fff;vertical-align:middle;box-sizing:border-box;
                            > button, > button:focus, > button:active {padding:0;border:none;outline:none;background:none;}//IE클릭효과제거
                            > button {width:100%;
                                > span {position:relative;display:block;padding:6px 23px 8px 27px;font-size:12px;line-height:1.2;color:#4a4a4a;letter-spacing:-0.33px;text-align:left;box-sizing:border-box;//IE클릭효과제거(position:relative;)
                                    //근무시간색상
                                    i {overflow:hidden;position:absolute;left:10px;top:8px;display:block;width:12px;height:0;padding-top:12px;}
                                }
                            }
                            &:before, &:after {content:'';position:absolute;top:50%;background:#a6a6a6;z-index:1;}
                            &:before {right:11px;width:1px;height:11px;margin-top:-6px;}
                            &:after {right:6px;width:11px;height:1px;margin-top:-1px;}
                        }
                        > li:hover {border-color:#1c90fb;
                            &:before, &:after {background:#1c90fb;}
                        }
                        //드래그/on
                        > li.drag, > li.on {border-style:solid;border-color:#1c90fb;box-shadow:3px 3px 3px 0 rgba(0, 0, 0, 0.16);
                            &:before, &:after {background:#1c90fb;}
                        }
                    }
                }
                //emptyData
                .no_data > .notime {margin-top:-43px;}
            }
        }
        //근무패턴
        .area2 {height:100%;
            //리스트
            > .cont {
                .pattern-set {width:100%;height:100%;
                    //OBTChips(컴포넌트)
                    .btn-chips {display:block;border:none;
                        > div > div:first-child {
                            &:before {content:'';display:inline-block;width:1px;height:calc(100% - 3px);vertical-align:middle;}
                            > div {display:inline-flex;width:calc(100% - 1px);margin:0;padding:14px 88px 14px 87px;vertical-align:middle;box-sizing:border-box;}
                        }
                    }
                    //버튼타입
                    > div:first-child:not(.btn-chips):before {content:'';display:inline-block;width:1px;height:calc(100% - 3px);vertical-align:middle;}
                    .btn-type {display:inline-flex;flex-wrap:wrap;width:calc(100% - 1px);padding:14px 88px 14px 87px;vertical-align:middle;box-sizing:border-box;
                        > div {margin:5px;
                            button {width:100%;height:29px;border-color:#d9d9d9;background:#fff;
                                //hover/on
                                &:hover, &.on {border-color:#1c90fb;background:#fff;box-shadow:0px 3px 5px 0 rgba(0, 0, 0, 0.18);}
                                [class*="type"] {position:relative;display:block;padding-left:17px;box-sizing:border-box;
                                    //근무시간색상
                                    i {overflow:hidden;position:absolute;left:0;top:50%;display:block;width:12px;height:0;margin-top:-6px;padding-top:12px;}
                                }
                            }
                        }
                    }
                }
            }
        }
        .area1 + .area2 {height:calc(100% - 146px);border-top:1px solid #d9d9d9;}
    }

    /*----------------------------------------------------
        [계산식등록]
    ----------------------------------------------------*/
    .calculator-area {display:flex;flex-direction:column;width:426px !important;
        &:not(.t2) > [class*="area"]:not(:first-child) {margin-top:10px;}

        /* 세로형 */
        /* 타입1(계산기 large(기본) : width:426px) */
        //계산식
        .area1 {border:1px solid #e6e6e6;box-sizing:border-box;
            tr {height:32px;border-left:none;border-right:none;
                th, td {border-bottom:none !important;}
                th {background:#f2f5f9;
                    > div {justify-content:flex-start !important;}
                }
                td {padding:0;
                    &.noti {border-top:1px solid #e6e6e6;}//타이틀이 없는 컨텐츠
                    textarea {border:none !important;font-size:16px !important;line-height:1.5 !important;}
                }
            }
        }
        //계산기
        .area2 {border:1px solid #e6e6e6;box-sizing:border-box;
            .btn-num {display:flex;flex-wrap:wrap;padding:6px 8px;box-sizing:border-box;
                > [data-orbit-component="OBTButton"] {margin:2px;
                    button {position:relative;width:64px;height:40px;border:none !important;border-radius:4px;background-color:#e5e9f0 !important;background-image:none !important;font-weight:bold;color:#4a4a4a;
                        &[class*="num"], &.dot {background-color:#f2f5f9 !important;}
                        &:hover {background-color:#99a9c3 !important;color:#fff;}
                        &:disabled {background-color:#f5f5f5 !important;color:#a6a6a6;}
                        &.or, &.num0, &.and {width:132px;}
                        //num bg
                        &:after {content:'';position:absolute;left:0;right:0;top:0;bottom:0;border-radius:4px;background-position:50% !important;background-repeat:no-repeat;}
                        &.c {
                            &:after{background-image:url('../imgs/erp/c_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/c_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/c_wide_disable.png') !important;}
                        }
                        &.back {
                            &:after{background-image:url('../imgs/erp/back_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/back_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/back_wide_disable.png') !important;}
                        }
                        &.bracket1 {
                            &:after{background-image:url('../imgs/erp/bracket1_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/bracket1_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/bracket1_wide_disable.png') !important;}
                        }
                        &.bracket2 {
                            &:after{background-image:url('../imgs/erp/bracket2_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/bracket2_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/bracket2_wide_disable.png') !important;}
                        }
                        &.large {
                            &:after{background-image:url('../imgs/erp/large_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/large_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/large_wide_disable.png') !important;}
                        }
                        &.small {
                            &:after{background-image:url('../imgs/erp/small_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/small_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/small_wide_disable.png') !important;}
                        }
                        &.num7 {
                            &:after{background-image:url('../imgs/erp/7_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/7_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/7_wide_disable.png') !important;}
                        }
                        &.num8 {
                            &:after{background-image:url('../imgs/erp/8_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/8_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/8_wide_disable.png') !important;}
                        }
                        &.num9 {
                            &:after{background-image:url('../imgs/erp/9_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/9_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/9_wide_disable.png') !important;}
                        }
                        &.division {
                            &:after{background-image:url('../imgs/erp/division_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/division_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/division_wide_disable.png') !important;}
                        }
                        &.large2 {
                            &:after{background-image:url('../imgs/erp/large2_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/large2_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/large2_wide_disable.png') !important;}
                        }
                        &.small2 {
                            &:after{background-image:url('../imgs/erp/small2_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/small2_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/small2_wide_disable.png') !important;}
                        }
                        &.num4 {
                            &:after{background-image:url('../imgs/erp/4_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/4_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/4_wide_disable.png') !important;}
                        }
                        &.num5 {
                            &:after{background-image:url('../imgs/erp/5_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/5_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/5_wide_disable.png') !important;}
                        }
                        &.num6 {
                            &:after{background-image:url('../imgs/erp/6_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/6_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/6_wide_disable.png') !important;}
                        }
                        &.x {
                            &:after{background-image:url('../imgs/erp/x_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/x_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/x_wide_disable.png') !important;}
                        }
                        &.equal {
                            &:after{background-image:url('../imgs/erp/equal_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/equal_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/equal_wide_disable.png') !important;}
                        }
                        &.notequal {
                            &:after{background-image:url('../imgs/erp/notequal_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/notequal_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/notequal_wide_disable.png') !important;}
                        }
                        &.num1 {
                            &:after{background-image:url('../imgs/erp/1_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/1_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/1_wide_disable.png') !important;}
                        }
                        &.num2 {
                            &:after{background-image:url('../imgs/erp/2_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/2_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/2_wide_disable.png') !important;}
                        }
                        &.num3 {
                            &:after{background-image:url('../imgs/erp/3_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/3_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/3_wide_disable.png') !important;}
                        }
                        &.minus {
                            &:after{background-image:url('../imgs/erp/minus_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/minus_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/minus_wide_disable.png') !important;}
                        }
                        &.or {
                            &:after{background-image:url('../imgs/erp/or_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/or_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/or_wide_disable.png') !important;}
                        }
                        &.num0 {
                            &:after{background-image:url('../imgs/erp/0_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/0_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/0_wide_disable.png') !important;}
                        }
                        &.dot {
                            &:after{background-image:url('../imgs/erp/dot_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/dot_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/dot_wide_disable.png') !important;}
                        }
                        &.plus {
                            &:after{background-image:url('../imgs/erp/plus_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/plus_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/plus_wide_disable.png') !important;}
                        }
                        &.and {
                            &:after{background-image:url('../imgs/erp/and_wide_normal.png') !important;}
                            &:hover:after {background-image:url('../imgs/erp/and_wide_select.png') !important;}
                            &:disabled:after {background-image:url('../imgs/erp/and_wide_disable.png') !important;}
                        }
                    }
                }
            }
        }
        //최종계산식 / 실제계산내역
        .area3 {flex:1;min-height:73px;padding:12px 15px 15px;border-radius:3px;border:1px solid #d4dfe7;background:#f3f7fa;box-sizing:border-box;
            //타이틀
            > strong:first-child {display:block;color:#000;}
            //내용
            > div {height:calc(100% - 26px);margin-top:8px;color:#4a4a4a;}
        }

        /* 타입2(계산기 small : width:290px) */
        &.t1 {width:290px !important;
            //계산기
            .area2 {
                .btn-num {padding:6px;
                    > [data-orbit-component="OBTButton"] button {width:42px;height:42px;
                        &.or, &.num0, &.and {width:88px;}
                    }
                }
            }
        }

        /* 가로형 */
        &.t2 {display:block;width:100% !important;height:234px;
            > [class*="area"]:not(:nth-child(1)):not(:nth-child(2)) {margin-top:10px;}

            /* 타입1(계산기 large(기본) : width:426px) */
            //계산식 / 최종계산식 / 실제계산내역
            .area1, .area3 {float:right;width:calc(100% - 436px) !important;}
            //계산기
            .area2 {float:left;width:426px;}
            //최종계산식 / 실제계산내역
            .area3 {height:112px;}

            /* 타입2(계산기 small : width:290px) */
            &.t1 {height:244px;
                //계산식 / 최종계산식 / 실제계산내역
                .area1, .area3 {width:calc(100% - 300px) !important;}
                //계산기
                .area2 {width:290px;}
                //최종계산식 / 실제계산내역
                .area3 {height:117px;}
            }
        }
    }

    /*----------------------------------------------------
        [진행이력 / 프로세스]
    ----------------------------------------------------*/
    /* 타입1 */
    .progress-area {position:relative;height:auto !important;padding:20px 0;border:1px solid #e7e7e7;background:#f5f8fa;box-sizing:border-box;
        &:before {content:'';position:absolute;top:25px;bottom:25px;left:33px;width:2px;background:#e3e5e7;}
        li {position:relative;display:flex;flex-wrap:wrap;align-items:center;height:32px;padding:0 100px 0 57px;color:#a6a6a6;line-height:1.3;
            &:not(:first-child) {margin-top:21px;}
            > strong {width:100%;padding-bottom:2px;}//타이틀
            > span:before {content:'';display:inline-block;width:1px;height:9px;margin:-1px 6px 0;background:#ccc;vertical-align:middle;}//이름/날짜
            > [class*="state-type"] {position:absolute;right:20px;top:50%;margin-top:-9px;}//상태
            //이미지
            //지남
            &:before {content:'';position:absolute;left:20px;top:50%;width:28px;height:28px;margin-top:-14px;border:1px solid #cdd5e2;border-radius:50%;background-color:#cdd5e2;background-repeat:no-repeat;background-position:50% 50%;box-sizing:border-box;}
            &:nth-child(1):before {background-image:url('../imgs/ico/ic_label_announcement_white.png');}
            &:nth-child(2):before {background-image:url('../imgs/ico/ic_label_document_white.png');}
            &:nth-child(3):before {background-image:url('../imgs/ico/ic_label_documentsend_white.png');}
            &:nth-child(4):before {background-image:url('../imgs/ico/ic_label_confirm_white.png');}
            &:nth-child(5):before {background-image:url('../imgs/ico/ic_label_sign_white.png');}
            //현재
            &.on {color:#000;
                &:before {border-color:#748ffc;background-color:#748ffc;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.12);}
            }
            //남음
            &.on ~ li {
                &:before {border-color:#c0c5d8;background-color:#f5f8fa;}
                &:nth-child(1):before {background-image:url('../imgs/ico/ic_label_announcement_gray.png');}
                &:nth-child(2):before {background-image:url('../imgs/ico/ic_label_document_gray.png');}
                &:nth-child(3):before {background-image:url('../imgs/ico/ic_label_documentsend_gray.png');}
                &:nth-child(4):before {background-image:url('../imgs/ico/ic_label_confirm_gray.png');}
                &:nth-child(5):before {background-image:url('../imgs/ico/ic_label_sign_gray.png');}
            }
        }
    }
	//심플스크롤
	div.progress-area {height:100% !important;
		> div {height:100%;}
	}

    /* 타입2 */
    .progress-area2 {position:relative;display:flex;justify-content:space-between;padding:29px 34px 27px;border:1px solid #e6e6e6;border-radius:10px;
        /* 공통 */
        //진행전(기본)
        li {position:relative;color:#a6a6a6;letter-spacing:-0.6px;text-align:center;
            &:first-child, &:last-child {overflow:hidden;width:189px;height:0;padding-top:140px;background-repeat:no-repeat;}
            &:not(:first-child):not(:last-child) {flex:1;width:108px;min-height:140px;padding:27px 0 25px;box-sizing:border-box;}
            &:first-child {background-image:url('../imgs/ico/img_amaranth.png');}//Amaranth 10
            &:last-child {background-image:url('../imgs/ico/img_hometax.png');background-position:100% 0;}//Hometax
            & + li ~ li:not(:last-child) {margin-left:34px;}
            &:not(:first-child):not(:last-child):before {content:'';display:block;width:64px;height:64px;margin:0 auto 4px;background-color:#fff;background-position:50% 50%;background-repeat:no-repeat;box-sizing:border-box;}
            &:nth-child(2):before {background-image:url('../imgs/ico/ico_login_gray.png');}//홈택스 로그인
            &:nth-child(3):before {background-image:url('../imgs/ico/ico_folder_gray.png');}//파일형식 검증
            &:nth-child(4):before {background-image:url('../imgs/ico/ico_contents_gray.png');}//내용 검증
            &:nth-child(5):before {background-image:url('../imgs/ico/ico_confirm_gray.png');}//제출 및 신고내역 확인
            //>아이콘
            &:not(:first-child):not(:nth-child(2)):not(:last-child):after {content:'';position:absolute;top:50px;left:-27px;width:20px;height:20px;background:url('../imgs/ico/ico_arrow_off.png') no-repeat;}
        }
        //진행중
        .ing {color:#4a4a4a;font-weight:bold;
            &:before {-webkit-animation-duration:1.5s;animation-duration:1.5s;-webkit-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-name:flash;animation-name:flash;}
            &:nth-child(2):before {background-image:url('../imgs/ico/ico_login_blue.png');}//홈택스 로그인
            &:nth-child(3):before {background-image:url('../imgs/ico/ico_folder_blue.png');}//파일형식 검증
            &:nth-child(4):before {background-image:url('../imgs/ico/ico_contents_blue.png');}//내용 검증
            &:nth-child(5):before {background-image:url('../imgs/ico/ico_confirm_blue.png');}//제출 및 신고내역 확인
        }
        //완료
        .on {color:#4a4a4a;font-weight:bold;
            &:before {-webkit-animation:none;animation:none;}
            &:nth-child(2):before {background-image:url('../imgs/ico/ico_login_white.png');}//홈택스 로그인
            &:nth-child(3):before {background-image:url('../imgs/ico/ico_folder_white.png');}//파일형식 검증
            &:nth-child(4):before {background-image:url('../imgs/ico/ico_contents_white.png');}//내용 검증
            &:nth-child(5):before {background-image:url('../imgs/ico/ico_confirm_white.png');}//제출 및 신고내역 확인
            //>아이콘
            & + li:after {background-image:url('../imgs/ico/ico_arrow_on.png') !important;}
        }
        //오류
        .error{color:#4a4a4a;font-weight:bold;
            &:before {-webkit-animation:none;animation:none;}
            &:nth-child(2):before {background-image:url('../imgs/ico/ico_login_red.png');}//홈택스 로그인
            &:nth-child(3):before {background-image:url('../imgs/ico/ico_folder_red.png');}//파일형식 검증
            &:nth-child(4):before {background-image:url('../imgs/ico/ico_contents_red.png');}//내용 검증
            &:nth-child(5):before {background-image:url('../imgs/ico/ico_confirm_red.png');}//제출 및 신고내역 확인
        }

        /* 타입1 */
        &.t1 {
            //진행전(기본)
            li {
                &:last-child {background-image:url('../imgs/ico/img_report_financial.png');}//재무보고서
                &:nth-child(2):before {background-image:url('../imgs/ico/ico_com_financial_gray.png');}//기업 재무제표 생성
                &:nth-child(3):before {background-image:url('../imgs/ico/ico_esti_financial_gray.png');}//추정 재무제표 생성
                &:nth-child(4):before {background-image:url('../imgs/ico/ico_write_financial_gray.png');}//재무보고서 작성
            }
            //진행중
            .ing {
                &:nth-child(2):before {background-image:url('../imgs/ico/ico_com_financial_blue.png');}//기업 재무제표 생성
                &:nth-child(3):before {background-image:url('../imgs/ico/ico_esti_financial_blue.png');}//추정 재무제표 생성
                &:nth-child(4):before {background-image:url('../imgs/ico/ico_write_financial_blue.png');}//재무보고서 작성
            }
            //완료
            .on {
                &:nth-child(2):before {background-image:url('../imgs/ico/ico_com_financial_white.png');}//기업 재무제표 생성
                &:nth-child(3):before {background-image:url('../imgs/ico/ico_esti_financial_white.png');}//추정 재무제표 생성
                &:nth-child(4):before {background-image:url('../imgs/ico/ico_write_financial_white.png');}//재무보고서 작성
            }
        }
    }

    /* 타입3 */
    .progress-area3 {padding:26px 10px;color:#8c8c8c;text-align:center;
        > p {font-size:16px;line-height:1.25;color:#010101;}
        .state {position:relative;display:flex;justify-content:space-between;margin-top:15px;
            //완료(기본)
            li {position:relative;width:50px;letter-spacing:-0.6px;z-index:2;
                &:not(:last-child) {flex:1;}
                &:not(:last-child):after {content:'';position:absolute;top:22px;right:calc(50% - 26px);width:5px;height:5px;border:solid #ddd;border-width:1px 1px 0 0;transform:rotate(45deg);z-index:2;}//>아이콘
                &:before {content:'';display:block;width:50px;height:50px;margin-bottom:4px;border:1px solid #ccc;border-radius:50%;box-sizing:border-box;background-color:#fff;background-position:50% 50%;background-repeat:no-repeat;}
                &:nth-child(1):before{background-image:url('../imgs/ico/icon_order_request_fin.png');}
                &:nth-child(2):before {background-image:url('../imgs/ico/icon_order_receive_fin.png');}
                &:nth-child(3):before {background-image:url('../imgs/ico/icon_release_standby_fin.png');}
                &:nth-child(4):before {background-image:url('../imgs/ico/icon_release_complete_fin.png');}
                &:nth-child(5):before {background-image:url('../imgs/ico/icon_receive_complete_fin.png');}
                > span {display:block;width:50px;}
            }
            //진행중
            .on {color:#1c90fb;font-weight:bold;
                &:before {border-color:#1c90fb;background-color:#e9f5ff;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);}
                &:nth-child(1):before {background-image:url('../imgs/ico/icon_order_request_ing.png');}
                &:nth-child(2):before {background-image:url('../imgs/ico/icon_order_receive_ing.png');}
                &:nth-child(3):before {background-image:url('../imgs/ico/icon_release_standby_ing.png');}
                &:nth-child(4):before {background-image:url('../imgs/ico/icon_release_complete_ing.png');}
                &:nth-child(5):before {background-image:url('../imgs/ico/icon_receive_complete_ing.png');}
            }
            //진행전
            .on ~ li {color:#d0d0d0;
                &:before {border-color:#f5f5f5;background-color:#f5f5f5;}
                &:nth-child(1):before {background-image:url('../imgs/ico/icon_order_request_pre.png');}
                &:nth-child(2):before {background-image:url('../imgs/ico/icon_order_receive_pre.png');}
                &:nth-child(3):before {background-image:url('../imgs/ico/icon_release_standby_pre.png');}
                &:nth-child(4):before {background-image:url('../imgs/ico/icon_release_complete_pre.png');}
                &:nth-child(5):before {background-image:url('../imgs/ico/icon_receive_complete_pre.png');}
            }
        }
    }
    .progress-area3.t1 {position:relative;
        &:before {content:'';position:absolute;left:15px;right:15px;top:85px;display:block;height:2px;background-color:#ededed;background-image:repeating-linear-gradient(to right, #ededed, #ededed 2px, #fff 2px, #fff 4px);z-index:1;}//진행점라인
        .state {
            //완료(기본)
            li {
                &:nth-child(1):before{background-image:url('../imgs/ico/icon_delivery_standby_fin.png');}
                &:nth-child(2):before {background-image:url('../imgs/ico/icon_shipping_fin.png');}
                &:nth-child(3):before {background-image:url('../imgs/ico/icon_delivery_complete_fin.png');}
            }
            //진행중
            .on {
                &:nth-child(1):before {background-image:url('../imgs/ico/icon_delivery_standby_ing.png');}
                &:nth-child(2):before {background-image:url('../imgs/ico/icon_shipping_ing.png');}
                &:nth-child(3):before {background-image:url('../imgs/ico/icon_delivery_complete_ing.png');}
            }
            //진행전
            .on ~ li {
                &:nth-child(1):before {background-image:url('../imgs/ico/icon_delivery_standby_pre.png');}
                &:nth-child(2):before {background-image:url('../imgs/ico/icon_shipping_pre.png');}
                &:nth-child(3):before {background-image:url('../imgs/ico/icon_delivery_complete_pre.png');}
            }
        }
        .info {margin-top:17px;padding:10px 0;border-radius:4px;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.1);border:1px solid #ccc;
            li {display:inline-block;padding:0 18px;
                & + li {border-left:1px solid #ccc;}
                > em {display:block;padding-top:3px;font-size:13px;letter-spacing:-0.65px;color:#000;font-weight:bold;}
            }
        }
    }

    /* 타입4 */
    .progress-area4 {position:relative;padding:4px 6px 5px;font-size:12px;line-height:1.5;color:#a6a6a6;box-sizing:border-box;
        &:before {content:'';position:absolute;top:20px;bottom:20px;left:8px;width:1px;background:#d9d9d9;}
        li {position:relative;display:flex;align-items:center;
            &:not(:first-child) {margin-top:7px;}
            //기본(지남)
            &:before {content:'';width:5px;height:13px;margin-right:10px;background:#fff;}
            &:after {content:'';position:absolute;left:0;top:50%;width:5px;height:5px;margin-top:-2.5px;border-radius:50%;background:#e2e2e2;}
            //현재
            &.on {
                &:nth-child(1) {color:#f0c325;
                    &:after {background:#f0c325;}
                }
                &:nth-child(2), &:nth-child(3) {color:#46a3f0;
                    &:after {background:#46a3f0;}
                }
            }
            //남음
            &.on ~ li {color:#4a4a4a;
                &:after {background:#7b7b7b;}
            }
        }
    }
	//심플스크롤
	div.progress-area4 {height:100% !important;
		> div {height:100%;}
	}

    /*----------------------------------------------------
        [타임라인]
    ----------------------------------------------------*/
    .timeline-area {color:#222;
        /* 기본 */
        //날짜/상태
        .today, .today ~ div {min-height:18px;padding:1px 0;line-height:1.2;
            &:not(:first-child) {margin-top:21px;}
            > em {margin-left:8px;vertical-align:baseline;}
        }
        //정보
        ul {margin-top:15px;
            > li {position:relative;padding-left:38px;line-height:1.3;
                &:not(:only-child):not(:last-child) {padding-bottom:19px;}
                //아이콘/라인
                &:not(:only-child):not(:last-child):before {content:'';position:absolute;left:14px;top:0;bottom:0;width:1px;background:#e5e5e5;}//라인
                &:after {content:'';position:absolute;left:0;top:0;width:28px;height:28px;background-repeat:no-repeat;}//아이콘
                &.in:after {background-image:url('../imgs/ico/ic_notice_write_read.png');}//입력
                &.edit:after {background-image:url('../imgs/ico/ic_notice_modify_read.png');}//수정
                &.del:after {background-image:url('../imgs/ico/ic_notice_del_read.png');}//삭제
                //내용
                [class*='area'] {opacity:0.5;
                    > i {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}//상세 타이틀
                }
                .area2, .area3 {margin-top:2px;font-size:11px;}
                .area2 {color:#000;}//품목
                .area3 {color:#8e8e8e;//작업자/작성시간
                    > span + span:before {content:'';display:inline-block;width:1px;height:8px;margin:0 6px 0 7px;background:#e5e5e5;}//구분라인
                }
            }
        }

        /* 오늘 */
        .today + ul > li {
            //아이콘/라인
            &.in:after {background-image:url('../imgs/ico/ic_notice_write.png');}//입력
            &.edit:after {background-image:url('../imgs/ico/ic_notice_modify.png');}//수정
            &.del:after {background-image:url('../imgs/ico/ic_notice_del.png');}//삭제
            //내용
            [class*='area'] {opacity:1;}
        }

        /* 전체테두리추가 + 여백 + 내부심플스크롤 */
        &.t1 {padding:15px;border:1px solid #e1e1e1;border-top:2px solid #666;box-sizing:border-box;
            > div:first-child {height:100%;}
        }
    }

    /*----------------------------------------------------
        [1일근로시간편성표] 미사용
    ----------------------------------------------------*/
    .work-schedule {margin-top:20px !important;padding:25px 19px 50px;border:1px solid #eaedf3;border-radius:4px;box-sizing:border-box;
        .heading {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
        .schedule {position:relative;height:44px !important;border-radius:4px;background:#d8dee3;
            .area {position:absolute;top:0px;bottom:0px;display:flex;align-items:center;justify-content:center;border-radius:4px;
                //타이틀
                .ti {color:#fff;}
                //시간
                [class*="time"] {position:absolute;top:49px;display:flex;align-items:center;justify-content:center;width:20px;height:auto;background:#fff;
                    span {font-size:10px;line-height:1.3;color:#9ea0a5;letter-spacing:-0.5px;text-align:center;}
                }
                .time1 {left:-10px;}
                .time2 {right:-10px;}
            }
        }
    }

    /*----------------------------------------------------
        [통장]
    ----------------------------------------------------*/
    .bankbook-area {position:relative;min-width:344px;width:100%;padding:16px 0;box-sizing:border-box;
        &:before, &:after {content:'';position:absolute;left:0;width:100%;height:16px;}
        &:before {top:0;border-radius:9px 9px 0 0;background:url('../imgs/erp/bg_bankbook_top_left.png'), url('../imgs/erp/bg_bankbook_top_right.png'), url('../imgs/erp/bg_bankbook_top_middle.png');background-repeat:no-repeat, no-repeat, repeat-x;background-position:left top, right top, left top;}
        &:after {bottom:0;border-radius:0 0 9px 9px;background:url('../imgs/erp/bg_bankbook_bottom_left.png'), url('../imgs/erp/bg_bankbook_bottom_right.png'), url('../imgs/erp/bg_bankbook_bottom_middle.png');background-repeat:no-repeat, no-repeat, repeat-x;background-position:left bottom, right bottom, left bottom;}
        > div {height:100%;padding:0 19px;border-left:1px solid #d2d2d2;border-right:1px solid #d2d2d2;background:#fffff2;
            //은행명+계좌번호
            .heading {display:flex;justify-content:space-between;padding:1px 0 9px;line-height:18px;
                [class*="banklogos"], [class*="card"] {display:flex;width:55%;font-size:14px;letter-spacing:-0.7px;//-----------------card(num) 운영서버 반영 후 삭제
                    &:before {margin-right:4px;}
                    i {flex:1;}
                }
                em {flex:1;text-align:right;}
            }
            //그리드
            .heading ~ div {border-left:none;height:calc(100% - 28px) !important;
                [data-orbit-component="OBTDataGrid"]:after {border-right:none;}
            }
            //타이틀
            .heading ~ .ti {display:flex;border-top:2px solid #666;border-bottom:1px solid #e6e6e6;background:#f3f2e1;padding:6px 0 8px;height:35px !important;box-sizing:border-box;
                strong {display:block;text-align:center;line-height:18px;
                    &:nth-child(1) {width:24%;}
                    &:nth-child(2), &:nth-child(3) {width:38%;}
                }
            }
            //리스트
            .heading ~ .list {height:calc(100% - 70px) !important;
                ul li {display:flex;padding:7px 8px;border-bottom:1px solid #e6e6e6;
                    > div {display:flex;flex-direction:column;line-height:18px;
                        &:nth-child(1) {width:24%;
                            > div {overflow:hidden;white-space:nowrap;text-overflow:ellipsis;line-height:18px;
                                strong {font-weight:normal;font-size:12px;}
                            }
                        }
                        &:nth-child(2), &:nth-child(3) {width:calc(38% - 5px);text-align:right;}
                        &:nth-child(2) {padding:0 5px;
                            &.in {//입금
                                > em, > strong {color:#fc5356;}
                            }
                            &.out {//출금
                                > em, > strong {color:#1c90fb;}
                            }
                        }
                        &:nth-child(3) {
                            strong {font-weight:normal;}
                        }
                        > em {color:#8c8c8c;}
                        > strong {max-width:100%;font-size:13px;}
                        
                    }
                }
            }
        }
    }

    /*----------------------------------------------------
        [신청서등록 레이아웃(인사 공통)]
    ----------------------------------------------------*/
    .form-content {position:relative;display:flex;flex:1 0 auto;box-sizing:border-box;
        > div:first-child {width:300px;}//카드리스트
        > div.cont {flex:1;padding-left:20px;box-sizing:border-box;//컨텐츠
            > div:not(:first-child):not(.btn) {margin-top:20px;padding-top:20px;border-top:1px solid #f4f4f4;box-sizing:border-box;}
            .btn {text-align:center;
                > div:not(:first-child) {margin-left:4px;}
            }
            > .data-heading > .heading + div {
                .data-tbl6 {
                    tfoot, .total {
                        span + span {display:inline-block;margin-left:10px;padding-left:10px;border-left:1px solid #e0e0e0;}
                    }
                }
                > .btn {margin-top:20px;}
            }
            > .btn {margin-top:20px;}
        }
    }

    /*----------------------------------------------------
        [신고서 레이아웃]
    ----------------------------------------------------*/
    .report-form {position:relative;height:100%;
        &:before, &:after {content:'';position:absolute;left:0;top:0;bottom:0;height:100%;}
        &:before {width:1px;background:#e6e6e6;}//왼쪽라인(고정)
        &:after {right:0;width:100%;border:solid #e6e6e6;border-width:2px 1px 1px 0;border-top-color:#666;box-sizing:border-box;pointer-events:none;}//상단, 오른쪽, 하단라인(고정)
        /* 스크롤 O(기본) */
        .area-wrap {overflow-y:auto;position:relative;height:100%;
            //상단영역(고정영역)
            .area0 {position:-webkit-sticky;position:sticky;left:0;top:0;margin-bottom:-1px;z-index:1;}
            //본문영역
            .area1 {
                .report-tbl1 {border-top:1px solid #e6e6e6;}
            }
            //하단영역(고정영역)
            .area2 {position:-webkit-sticky;position:sticky;left:0;bottom:0;margin-top:-1px;
                //테이블
                .report-tbl1 {border-top:1px solid #e6e6e6;}
            }
        }

        /* 스크롤 X */
        .area-wrap.scroll-none {
            //하단영역(고정영역)
            .area2 {position:absolute;}
        }
    }

    /*----------------------------------------------------
        [원천징수영수증 레이아웃]
    ----------------------------------------------------*/
    .withholding-form {height:100%;border:1px solid #e6e6e6;box-sizing:border-box;
        //원번호
        .num {display:inline-block;width:11px;height:12px;padding-right:1px;border-radius:50%;border:1px solid #858889;font-size:10px;line-height:12px;vertical-align:baseline;text-align:center;}
        //영수증
        .area {padding:10px;
            & + .area {padding-top:0;}
            //정보
            .info-area1 {padding-bottom:5px;font-size:11px;line-height:1;}//시행규칙 개정일
            .info-area2 {display:flex;padding:7px;border:1px solid #e6e6e6;line-height:1.33;color:#4a4a4a;letter-spacing:-0.6px;//안내문구(박스)
                span:not(:first-child) {flex:1;padding-left:3px;}
            }
            ul.info-area2 {flex-direction:column;
                li {display:flex;
                    & + li {padding-top:3px;}
                }
            }
            //heading
            .heading-area {display:flex;align-items:center;min-height:90px;padding:10px 0;box-sizing:border-box;
                //관리번호
                .info1 {display:flex;flex-wrap:wrap;width:160px;border:1px solid #e6e6e6;
                    > dt, > dd {display:flex;justify-content:center;align-items:center;width:50%;min-height:53px;padding:4px 8px;line-height:15px;box-sizing:border-box;
                        &:nth-child(2) ~ dt, &:nth-child(2) ~ dd {height:54px;border-top:1px solid #e6e6e6;}
                    }
                    > dt {background:#f2f6f8;}
                }
                //타이틀
                .ti {flex:1;text-align:center;
                    .check {display:inline-block;padding:0 2px;}
                    > span {display:block;font-size:18px;}
                    > i {font-weight:normal;}
                }
                //소득자구분
                .data-tbl9 {width:340px;}
            }
            //타이틀(테이블X)
            div.ti-type1 {height:18px;padding:4px 8px;border:solid #e6e6e6;border-width:1px 1px 0;background:#649cc6;color:#fff;
                > i {padding-left:8px;font-size:11px;}//보조문구
                & + table {border-top:none;
                    th, td {border-top:none;}
                }
            }
            //table
            > table {
                & + table, & + .info-area2, & + div.ti-type1 {margin-top:-1px;}
            }
            //작성방법
            [data-orbit-component="OBTReferencePanel"] {margin-top:10px;}
        }
    }

    /*----------------------------------------------------
        [급여명세서 : mail(editor) 아닐 경우]//----------------- 운영서버 반영 후 삭제
    ----------------------------------------------------*/
    .payslip-form {
        /* 헤딩 */
        .heading-area {padding:58px 93px 60px;box-sizing:border-box;
            //타이틀
            > strong {display:block;font-size:36px;text-align:center;
                > i {font-weight:normal;}
            }
            //기본정보
            .info {display:flex;flex-wrap:wrap;margin-top:41px;padding:21px 0;border:1px solid #dee4ed;border-radius:4px;background:#f2f5fa;
                > li {display:inline-flex;width:50%;padding:0 29px;box-sizing:border-box;
                    &:nth-child(2n + 1) {border-right:1px solid #d5deeb;}
                    &:nth-child(2) ~ li {padding-top:15px;}
                    > strong {width:30%;letter-spacing:-0.6px;}
                    > span {flex:1;}
                }
            }
        }

        /* 컨텐츠 */
        .content-area {padding:0 93px 60px;box-sizing:border-box;
            //공통
            .data, .notice {display:flex;flex-wrap:wrap;justify-content:flex-end;
                > strong {width:82px;margin-top:-4px;padding-right:10px;font-size:14px;letter-spacing:-0.7px;box-sizing:border-box;}
				> [class*="data-list"], > .total, > .cont, > table {width:calc(100% - 82px);}
                .total {display:flex;align-items:center;padding:13px 15px;border-bottom:1px solid #cad4e5;background:#f8fafc;box-sizing:border-box;
                    > em {width:50%;font-size:13px;}
                    > strong {flex:1;padding-left:15px;font-size:15px;text-align:right;}
                }
            }
            .data {
                & + .data {margin-top:14px;}
                & + .notice {margin-top:74px;}
            }
            //영역 구분
            .area1 {padding-top:14px;}//지급 / 공제내역
            .area2 {margin-top:49px;padding-top:53px;border-top:2px dotted #b7b7b7;}//근태내역 / 공지사항
            //리스트 타입1
            .data-list1 {display:flex;flex-wrap:wrap;border:solid #cad4e5;border-width:2px 0 1px;box-sizing:border-box;
                > li {display:inline-flex;align-items:center;width:calc(100% / 3 - 15px);margin-left:22px;padding:14px 15px;box-sizing:border-box;
                    &:nth-child(3n + 1) {margin-left:0;}
                    &:nth-child(3) ~ li {border-top:1px solid #f0f0f0;}
                    > em {width:50%;color:#8c8c8c;letter-spacing:-0.6px;}
                    > strong {flex:1;padding-left:15px;text-align:right;}
                }
            }
            //리스트 타입2
            .data-list2 {overflow:hidden;display:flex;flex-wrap:wrap;border:1px solid #d6dce5;border-radius:4px;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.06);box-sizing:border-box;
                > li {display:inline-flex;align-items:center;width:calc(100% / 3);
                    &:nth-child(3) ~ li {border-top:1px solid #f0f0f0;}
                    > em {display:flex;align-items:center;width:60%;height:100%;padding:14px 15px;color:#8c8c8c;letter-spacing:-0.6px;background:#fafbfc;box-sizing:border-box;}
                    > strong {flex:1;padding:14px 15px 14px 10px;box-sizing:border-box;}
                }
            }
            //차인지급금
            .pay {display:flex;align-items:center;width:calc(100% - 82px);margin:20px 0 0 82px;padding:15px 15px 16px;border:1px solid #1c90fb;border-radius:4px;background:#eff7ff;box-shadow:0 2px 4px 0 rgba(0, 0, 0, 0.06);box-sizing:border-box;
                > em {display:flex;width:50%;font-size:14px;}
                > strong {flex:1;padding-left:15px;font-size:18px;color:#1c90fb;text-align:right;}
            }
            //공지사항
            .notice {
				> div {overflow:auto;height:182px;padding:10px;border:1px solid #e6e6e6;box-sizing:border-box;}
            }
        }

        /* footer */
        .footer-area {display:flex;align-items:center;height:114px;margin-top:-20px;padding:0 93px !important;background-color:#f8fafc;font-size:14px;text-align:center;box-sizing:border-box;
            > p {width:60%;text-align:left;}
            > div {display:inline-flex;align-items:center;flex:1;justify-content:flex-end;
                > strong {text-align:right;}
                img {width:71px;height:71px;margin-left:15px;}
            }
        }
    }

    /*----------------------------------------------------
        [급여명세서 : PDF변환용 HTML / Web화면도 동일한 마크업 사용(iframe)]
    ----------------------------------------------------*/
    .payslip-form1 {
        /* 정보타입1 */
        .pay-data-info1 {overflow:auto;height:182px;}/* Web화면용 */
    }

    /*----------------------------------------------------
        [공지사항 : 삽입용 HTML]
    ----------------------------------------------------*/
    .notice-form1 {
        /* 헤딩 */
        .heading-area {padding:15px 0 20px;text-align:center;
            > strong {font-size:24px;}
        }

        /* 컨텐츠 */
        .content-area {
            > div, > ul, > ol, > dl, > p, > table {
                &:not(:first-child) {margin-top:20px;}
            }
        }
    }
}

/*----------------------------------------------------
    [인포메이션바 : 물류공통]
----------------------------------------------------*/
[class*="informationBarContents"] > div:first-child > div:first-child {height:100%;}
[class*="wrap-content"].info-bar {width:100%;height:100%;padding:16px 20px 20px;box-sizing:border-box;
    //심플스크롤
    [data-orbit-component="OBTTabs"] [class*="OBTTabs_template"] > div {height:100%;}
    //지도영역
    .map-area {height:190px;margin-top:20px;}
    //도면영역
    .drawing-area {height:266px;}
    //검색영역
    [data-orbit-component="OBTConditionPanel"] [data-orbit-component="OBTConditionItem"] {width:240px !important;
        [class*="OBTConditionItem_label"] {min-width:62px;}//labelText
    }
}


/*====================================================
    [레이어팝업 : 컴포넌트 수정]
====================================================*/
.ly-popup {
    /*----------------------------------------------------
        [콘텐츠 FULL 사용 팝업1 : alret / OBTConfirm 스타일 팝업]
    ----------------------------------------------------*/
    &.t1 {
        > ._isDialog > div:nth-child(2) {border-color:#bcb5b9 !important;
            .dialog_content {
                .dialog_data {
                    //타이틀영역(컴포넌트)
                    > div:nth-child(1) {display:none;}
                    //컨텐츠영역(컴포넌트)
                    > div:nth-child(2) {margin:30px 0 2px;border-top:none;
                        > div {top:0;bottom:0;
                            /* 삽입컨텐츠 */
                            //헤딩/상단텍스트
                            .pop-heading {font-size:15px;text-align:center;box-sizing:border-box;}
                            //컨텐츠
                            .pop-content {box-sizing:border-box;}
                        }
                    }
                    //버튼영역
                    > div:nth-child(3) {margin:0 -30px 2px;padding:18px 0 30px 0;background:#fff;}
                }
            }
        }
    }

    /*----------------------------------------------------
        [콘텐츠 FULL 사용 팝업2]
    ----------------------------------------------------*/
    /* .t2 : 닫기버튼 검정 / .t2w : 닫기버튼 흰색 */
    &.t2, &.t2w {
        > ._isDialog > div:nth-child(2) {padding:0 !important;border-color:#bcb5b9 !important;
            .dialog_content {
                .dialog_data {
                    //타이틀영역(컴포넌트)
                    > div:nth-child(1) {
                        > div:first-child {display:none;}
                        > button {right:22px !important;z-index:1;}
                    }
                    //컨텐츠영역(컴포넌트)
                    > div:nth-child(2) {border-top:none;
                        > div {top:0;bottom:0;
                            /* 삽입컨텐츠 */
                            //헤딩
                            .pop-heading {padding:30px;background-color:#eee;font-size:15px;box-sizing:border-box;//background 가이드용
                                > strong {display:block;letter-spacing:-1.5px;}
                                > p {letter-spacing:-0.75px;}
                            }
                            //컨텐츠
                            .pop-content {padding:20px 30px 0;font-size:15px;box-sizing:border-box;}
                            //경비청구 서비스(임시팝업 2월 한달)
                            .login-link {letter-spacing:-0.3px;
                                .heading {
                                    &.t1 {background-image:url('../imgs/bg/img_expense_claim.png'), linear-gradient(-90deg, #d9f0ff, #ebf7ff);}
                                }
                                .content {padding:24px 0 0;}
                            }
                        }
                    }
                    //버튼영역
                    > div:nth-child(3) {margin:0 0 2px;padding:27px 0 30px;background:#fff;}
                }
            }
        }
    }
    &.t2w {
        > ._isDialog > div:nth-child(2) {
            .dialog_content {
                .dialog_data {
                    //타이틀영역(컴포넌트)
                    > div:nth-child(1) {
                        > button > span {background-position:-74px -151px !important;opacity:0.5;}
                    }
                }
            }
        }
    }

    /*----------------------------------------------------
        [콘텐츠 FULL 사용 팝업3]
    ----------------------------------------------------*/
    &.t3 {
        > ._isDialog > div:nth-child(2) {border-color:#bcb5b9 !important;
            .dialog_content {
                .dialog_data {
                    //타이틀영역(컴포넌트)
                    > div:nth-child(1) {
                        > div:first-child {display:none;}
                        > button {z-index:1;}
                    }
                    //컨텐츠영역(컴포넌트)
                    > div:nth-child(2) {border-top:none;
                        > div {top:0;bottom:0;
                            /* 삽입컨텐츠 */
                            //헤딩
                            .pop-heading {padding:39px 0 20px;text-align:center;box-sizing:border-box;
                                //타이틀
                                > strong {display:block;font-size:30px;color:#1c90fb;letter-spacing:-1.5px;
                                    &:only-child {margin-top:-8px;}
                                }
                            }
                            //컨텐츠
                            .pop-content {box-sizing:border-box;}
                        }
                    }
                    //버튼영역
                    > div:nth-child(3) {margin:0 0 2px;padding:30px 0;background:#fff;}
                }
            }
        }
    }

    /*----------------------------------------------------
        [콘텐츠 FULL 사용 팝업4 : 페이퍼 타입]
    ----------------------------------------------------*/
    &.t4 {
        > ._isDialog > div:nth-child(2) {padding:0 !important;border-color:#bcb5b9 !important;
            .dialog_content {
                .dialog_data {
                    //타이틀영역(컴포넌트)
                    > div:nth-child(1) {padding-left:30px;background:#50596c;
                        > div:first-child {
                            > h1 {padding:18px 0px 14px !important;color:#fff;}//타이틀
                            > span {padding-top:21px !important;}//서브타이틀
                        }
                        > button {top:14px !important;right:22px !important;
                            > span {background-position:-74px -151px !important;}
                        }
                    }
                    //컨텐츠영역(컴포넌트)
                    > div:nth-child(2) {border-color:#e6e6e6;background-image:url('../imgs/erp/bg_docu_01.png'), url('../imgs/erp/bg_docu_03.png'), url('../imgs/erp/bg_docu_02.png'), url('../imgs/erp/bg_docu_06.png'), url('../imgs/erp/bg_docu_08.png'), url('../imgs/erp/bg_docu_07.png');background-position:0 0, 100% 0, 0 0, 0 100%, 100% 100%, 0 100%;background-repeat:no-repeat, no-repeat, repeat-x, no-repeat, no-repeat, repeat-x;
                        &:before, &:after {content:'';position:absolute;top:80px;bottom:80px;background-repeat:repeat-y;}
                        &:before {left:0;width:39px;background-image:url('../imgs/erp/bg_docu_04.png');}
                        &:after {right:0;width:41px;background-image:url('../imgs/erp/bg_docu_05.png');}
                        > div {left:39px;right:41px;top:42px;bottom:40px;}
                    }
                    //버튼영역
                    > div:nth-child(3) {display:none;margin:0;}
                }
            }
        }
    }

    /*----------------------------------------------------
        [콘텐츠 FULL 사용 팝업5]
    ----------------------------------------------------*/
    &.t5 {
        > ._isDialog > div:nth-child(2) {padding:0 !important;border-color:#bcb5b9 !important;
            .dialog_content {
                .dialog_data {
                    //타이틀영역(컴포넌트)
                    > div:nth-child(1) {padding-left:30px;background:#50596c;
                        > div:first-child {
                            > h1 {padding:18px 0px 14px !important;color:#fff;}//타이틀
                            > span {padding-top:21px !important;}//서브타이틀
                        }
                        > button {top:14px !important;right:22px !important;
                            > span {background-position:-74px -151px !important;}
                        }
                    }
                    //컨텐츠영역(컴포넌트)
                    > div:nth-child(2) > div {top:0;bottom:0;}
                    //버튼영역
                    > div:nth-child(3) {display:none;margin:0;}
                }
            }
        }
    }

    /*####################################################
        [공통컨텐츠]
    ####################################################*/
    /*----------------------------------------------------
        [상단 가이드문구(고정)]
    ----------------------------------------------------*/
    .pop-guide {padding-bottom:10px;color:#a6a6a6;}

    /*----------------------------------------------------
        [OBTMultiLineTextField : 컴포넌트 수정]
    ----------------------------------------------------*/
    //컴포넌트 display:inline-block; 설정으로 생기는 여백 초기화
    .data-heading > div > [data-orbit-component="OBTMultiLineTextField"],
    [data-orbit-component="OBTAccordion"] > div > div > [data-orbit-component="OBTMultiLineTextField"],
    [class*="wrap-content"] > [class*="OBTDockPanel_middle"] > div > div > div > div > [data-orbit-component="OBTMultiLineTextField"],
    .notice-area .cont > [data-orbit-component="OBTMultiLineTextField"],
    .message-send .cont > [data-orbit-component="OBTMultiLineTextField"],
    .message-send .cont1 > [data-orbit-component="OBTMultiLineTextField"] {display:block;}

    /*----------------------------------------------------
        [비밀번호 입력]
    ----------------------------------------------------*/
    .password-area {display:flex;flex-direction:column;align-items:center;padding-top:58px;background:url('../imgs/ico/ic_alert_password.png') no-repeat 50% 0%;
        > p {min-height:40px;margin-bottom:10px;font-size:15px;text-align:center;}
    }

    /*----------------------------------------------------
        [달력]
    ----------------------------------------------------*/
    .pop-calendar {
        //view
        [class*="data-tbl"] {
            [data-orbit-component="OBTButton"] {margin-left:4px;vertical-align:middle;}
            //휴가구분 색상
            .type {display:inline-block;width:12px;height:12px;margin-right:3px;vertical-align:middle;}
        }
    }

    /*----------------------------------------------------
        [사내매뉴얼 등록]
    ----------------------------------------------------*/
    .manual-area {
        .content > div > div {
            //뷰
            .cont {border:1px solid #e6e6e6;border-radius:3px;box-sizing:border-box;
                //이미지
                .file {height:252px;text-align:center;
                    > div:first-child:before {content:'';display:inline-block;width:1px;height:100%;vertical-align:middle;}
                    img {max-width:calc(100% - 1px);vertical-align:middle;}
                }
            }
            //참고박스
            > div + div {margin-left:10px !important;
                .reference-area1 > div {align-items:flex-start;height:100%;box-sizing:border-box;}
            }
        }
    }

    /*----------------------------------------------------
        [공지사항 작성]
    ----------------------------------------------------*/
    .notice-area {
        .content > div > div {
            //본문 작성폼
            .cont > div > div {height:100%;
                > div {height:100%;}
            }
            //버튼
            .btn > div > div {width:100%;
                & + div {margin-top:4px;}
                button {width:100%;height:40px;padding:0 15px;border-color:#ccc;background:#fafafa !important;
                    span {font-size:12px;}
                }
            }
        }
    }

    /*----------------------------------------------------
        [양식 설정]
    ----------------------------------------------------*/
    .document-area {
        //버튼
        .btn > div > div {width:100%;vertical-align:top;
            & + div {margin-top:4px;}
            button {width:100%;height:40px;border-color:#ccc;background:#fafafa !important;
                span {font-size:12px;}
            }
        }
        //Editor 영역
        [class*="OBTDockPanel_fill"] {margin-left:10px !important;}
    }

    /*----------------------------------------------------
        [쪽지 보내기(POC용)]
    ----------------------------------------------------*/
    .message-send {
        //받는사람(OBTFormPanel)
        .cont {
            border:1px solid #d9d9d9;
            th {background:#fff;}
            td .list {display:block;width:100%;height:27px;padding:0 8px;border:1px solid #ccc;box-sizing:border-box;
                > i {display:inline-block;padding-left:19px;background:url('../imgs/erp/icon_message_online.png') no-repeat 0 50%;line-height:27px;
                    &:not(:first-child) {margin-left:8px;}
                }
            }
        }
        //작성폼
        .cont1 > div > div {height:100%;
            > div {height:100%;}
        }
    }

    /*----------------------------------------------------
        [근태신청목록]
    ----------------------------------------------------*/
    .com-area1 {
        //카드리스트
        .card-list1 > div > div {
            .tbl-tdc {align-items:flex-end;width:90px;font-size:12px;color:#a6a6a6;}
            .tbl-tdr {width:80px;}
        }
    }

    /*----------------------------------------------------
        [정보안내 : .ly-popup.t2 전용 컨텐츠]
    ----------------------------------------------------*/
    /* 타입1 */
    .pdata-area1 {
        //헤딩
        .pop-heading {display:flex;flex-direction:column;justify-content:center;height:270px;background-repeat:no-repeat;color:#fff;text-align:center;
            > em {line-height:0;}//logo
            > strong {font-size:30px;}//타이틀
            > p {margin-top:5px;opacity:0.7;}//보조문구
            //이미지설정
            &.type1 {background-image:url('../imgs/bg/img_top.png');}
        }
        //컨텐츠
        .pop-content {text-align:center;
            > em {display:block;margin-top:20px;font-size:20px;letter-spacing:-1px;}
        }
    }

    /*----------------------------------------------------
        [재무예측컨설팅서비스 > 재무리포트]
    ----------------------------------------------------*/
    .financial-report {
        /* 헤딩 */
        .heading-area {padding:58px 93px 52px;box-sizing:border-box;
            //타이틀
            > strong {display:block;font-size:36px;text-align:center;}
        }

        /* 컨텐츠 */
        .content-area {padding:0 93px;box-sizing:border-box;
            > div + div {margin-top:21px;}
        }

        /* footer */
        .footer-area {padding:40px 93px 60px;text-align:center;box-sizing:border-box;
            > [data-orbit-component="OBTButton"] {margin:0 2px;}
        }
    }
}


/*====================================================
    [팝업패널]
====================================================*/
.ly-panel {padding-top:25px !important;}


/*====================================================
    [윈도우팝업]
====================================================*/
/*----------------------------------------------------
    [출력]
----------------------------------------------------*/
/* 공통 */
.wrap-print, .wrap-print2 {
    //컨텐츠 헤딩영역
    .heading-area {
        //타이틀
        .ti {display:block;font-size:20px;color:#000;letter-spacing:-1px;text-align:center;}
    }
}

/* 타입1 */
.wrap-print {padding:20px;box-sizing:border-box;}

/* 타입2 */
.wrap-print2 {
    //heading
    > [class*="OBTDockPanel_top"] > .heading {display:flex;align-items:center;height:60px;padding:0 30px;background:#50596c;box-sizing:border-box;
        > strong {flex:1;padding-right:20px;font-size:18px;color:#fff;letter-spacing:-0.9px;}
    }
    //컨텐츠
    > [class*="OBTDockPanel_middle"] > div > div > div {padding:10px 20px 20px;}
}
@media print {
    .wrap-print, .wrap-print2 {
        -webkit-print-color-adjust:exact;//Chrome 인쇄 시 background 노출
        print-color-adjust:exact;//Firefox 인쇄 시 background 노출
    }
}

/*----------------------------------------------------
    [로그인 연동 팝업]
----------------------------------------------------*/
.pubWindowPopup .login-link {min-width:738px !important;}
.login-link {
    p {font-size:15px;line-height:1.5;}
    .heading {min-height:180px;padding:43px 200px 43px 30px;box-sizing:border-box;
        > strong {display:inline-block;margin-bottom:12px;font-size:24px;line-height:36px;letter-spacing:-1.2px;}
        &.t1, &.t2, &.t3 {background-repeat:no-repeat;background-position:100% 100%;}
        &.t1 {background-image:url('../imgs/bg/img_taxinvoice.png'), linear-gradient(-90deg, #d9f0ff, #ebf7ff);}
        &.t2 {background-image:url('../imgs/bg/img_buliding.png'), linear-gradient(-90deg, #e3f4ff, #f0f9ff);}
        &.t3 {background-image:url('../imgs/bg/img_financialservice.png'), linear-gradient(-90deg, #d9f0ff, #ebf7ff);}
    }
    .content {padding:20px 30px 30px;text-align:center;
        > p + [class*="choice-area"] {margin-top:12px;}
        .btn button {margin-top:20px;padding:0 16px;height:32px;font-size:15px;border-color:#1c90fb;color:#fff;background-image:linear-gradient(to top, #1c90fb, #5eb5fd);border-radius:2px;border:1px solid #1c90fb;outline:none;
            &:hover {border:1px solid #136ad5;background-image:linear-gradient(0deg, #3280e0, #3ba1ff);}
            &:active {background-color:#1c90fb;background-image:linear-gradient(180deg, #3280e0, #3ba1ff);}
        }
    }
}


/******************************************************
    스마트생산실적
******************************************************/
/*====================================================
    생산실적등록(MTM0200)
====================================================*/
.MTM0200 {
    //공정설정정보
    .data-heading.search-area1 {position:relative;color:#000;letter-spacing:-0.6px;
        .heading {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
        p, li {
            span {display:inline-block;padding-left:7px;line-height:38px;
                &:before {content:'';float:left;width:2px;height:2px;margin:18px 0 0 -7px;background:#4e4e4e;vertical-align:middle;}
                &:not(:last-child):after {content:'';display:inline-block;width:1px;height:10px;margin:-2px 20px 0;background:#e0e0e0;vertical-align:middle;}
            }
        }
        p {padding:0 90px 0 23px;border:1px solid #d4dfe7;border-radius:3px;background:#f3f7fa;box-sizing:border-box;}
        ul {position:absolute;left:0;top:38px;width:100%;padding:10px 0;border:1px solid #d0d7dd;border-top-color:#d4dfe7;border-radius:0 0 3px 3px;background:#eff5f9;box-sizing:border-box;z-index:2;
            li {padding:0 23px;cursor:pointer;}
            li:hover {background:#d9ebfa;}
        }
        div[class^="btn"] {position:absolute;top:0;right:0;height:39px !important;
            [class*="btn-"] {border:none;background:none;
                > div {visibility:hidden;overflow:hidden;position:absolute;left:-1px !important;top:-1px;width:1px;height:1px;opacity:0;z-index:-1;}
            }
        }
        div.btn1 {right:53px;
            [class*="btn-"] {width:14px;height:39px;padding:0;
                &:before {content:'';display:block;width:14px;height:14px;background-repeat:no-repeat;}
            }
            .btn-prev {
                &:before {background-image:url('../imgs/ico/ic_arrow_left_02_s_normal.png');}
                &:hover:before {background-image:url('../imgs/ico/ic_arrow_left_02_s_over.png');}
                &.dis:before {background-image:url('../imgs/ico/ic_arrow_left_02_s_disable.png');}
            }
            .btn-next {
                &:before {margin-left:2px;background-image:url('../imgs/ico/ic_arrow_right_02_s_normal.png');}
                &:hover:before {background-image:url('../imgs/ico/ic_arrow_right_02_s_over.png');}
                &.dis:before {background-image:url('../imgs/ico/ic_arrow_right_02_s_disable.png');}
            }
        }
        div.btn2 {
            &:before {content:'';position:absolute;left:0;top:50%;width:1px;height:16px;margin-top:-8px;background:#e6e6e6;}
            [class*="btn-"] {width:41px;height:39px;padding:0 16px 0 11px;
                &:after {content:'';display:block;width:14px;height:14px;background-repeat:no-repeat;}
            }
            .btn-open {
                &:after {background-image:url('../imgs/ico/ic_arrow_up_01_s_normal.png');}
                &:hover:after {background-image:url('../imgs/ico/ic_arrow_up_01_s_over.png');}
            }
            .btn-close {
                &:after {background-image:url('../imgs/ico/ic_arrow_down_01_s_normal.png');}
                &:hover:after {background-image:url('../imgs/ico/ic_arrow_down_01_s_over.png');}
            }
        }

        
    }
    //카드리스트
    .card-list1 > div {
        > [class*="OBTCardList_headLine"] {display:none;}
        > div:last-child > div > div > div > ul > li > div {align-items:center;
            > div {text-align:center;
                &:nth-last-child(8) {width:31px;}
                &:nth-last-child(7) {width:55px;}
                &:nth-last-child(6) {width:55px;}
                &:nth-last-child(5) {width:55px;}
                &:nth-last-child(4) {width:24%;}
                &:nth-last-child(3) {width:24%;}
                &:nth-last-child(2) {width:24%;}
                &:nth-last-child(1) {flex:1;}
                //버튼
                button {position:relative;z-index:1;}
                //상세리스트
                > ul {padding:0 10px;
                    > li > span {display:inline-block;width:68%;text-align:left;vertical-align:top;
                        &.ti {width:32%;padding-right:10px;color:#4a4a4a;text-align:right;box-sizing:border-box;}
                    }
                }
                &:nth-last-child(4) > ul > li > span {width:76%;
                    &.ti {width:24%;}
                }
                //그래프(진행률)
                .graph {position:relative;transform:rotate(-90deg);width:56px;height:56px;margin:0 auto;
                    .back {stroke:#e9e9e9;}
                    &.on .back {stroke:#fff;}
                    .fill {stroke:#e9e9e9;animation:chart-fill 2s reverse;transform-origin:center;}
                    .info {position:absolute;left:0;top:19px;width:56px;color:#ccc;text-align:center;animation:chart-info 2s forwards;transform:rotate(90deg);opacity:0;}
                    &.type1 {
                        .fill {stroke:#ff8787;}
                        .info {color:#ff8787;}
                    }
                    &.type2 {
                        .fill {stroke:#f8a457;}
                        .info {color:#f8a457;}
                    }
                    &.type3 {
                        .fill {stroke:#20c997;}
                        .info {color:#20c997;}
                    } 
                    @keyframes chart-fill {
                        to {stroke-dasharray:0 100;}
                    }
                    @keyframes chart-info {
                        to {opacity:1;}
                    }
                }
            }
        }
    }
}

/*====================================================
    사용자재보고(MTM0300)
====================================================*/
.MTM0300 {
    //카드리스트
    .card-list1 > div {
        > [class*="OBTCardList_headLine"] {display:none;}
        > div:last-child > div > div > div > ul > li > div {align-items:center;
            > div {text-align:center;
                &:nth-last-child(4) {width:31px;}
                &:nth-last-child(3) {width:33%;}
                &:nth-last-child(2) {width:33%;}
                &:nth-last-child(1) {flex:1;}
                //상세리스트
                > ul {padding:0 10px;
                    > li > span {display:inline-block;width:77%;text-align:left;vertical-align:top;
                        &.ti {width:23%;padding-right:10px;color:#4a4a4a;text-align:right;box-sizing:border-box;}
                    }
                }
                &:nth-last-child(3) > ul > li > span {width:82%;
                    &.ti {width:18%;}
                }
            }
        }
    }
}


/******************************************************
    웹수발주
******************************************************/
/*====================================================
    주문접수처리(MWE0030)
====================================================*/
.MWE0030 {
    //검색영역
    [class*="OBTConditionItem_tooltipWrapper"] > div:not(:only-child):first-child {width:70px !important;}
    //폼패널(input 고정사이즈)
    [data-orbit-component="OBTFormPanel"] td > div {//OBTFormPanel > OBTFormPanel_contentsWrapper
        .ti {width:100px !important;}
        .ti + div {width:calc(100% - 100px) !important;}
        > div:not(:only-child):first-child {width:70px !important;}
        > div:not(:only-child) + div {width:calc(100% - 70px) !important;}
    }
    //DockPanel(Bottom)
    [class*="OBTDockPanel_bottom"] > [dock="bottom"] {margin-top:0 !important;}
}

/*====================================================
    주문총괄진행현황(MWE0220)
====================================================*/
.MWE0220 {
    //진행현황
    .progress {
        [class*="progress-area"] {margin:0 20px;
            & + [class*="progress-area"] {border-top:1px solid #d9d9d9;}
        }
        //배송현황
        .progress-area3.t1 {padding-bottom:29px;}
    }
}

/*====================================================
    상품목록(MWB0010)
====================================================*/
.MWB0010 {
    //타이틀(OBTDockPanel:top)
    .data-heading {
        //heading
        [class^="heading"] {overflow:hidden;
            .btn-chips {width:calc(100% - 110px);margin:3px 0 3px 15px;font-size:0;
                > div {border:none;
                    > div {margin:0;
                        > div {margin:0 0 4px 5px;
                            > div {height:22px;border-color:#ccc;border-radius:14px;box-shadow:none;
                                > div > div {font-size:11px;line-height:22px;color:#4a4a4a;letter-spacing:-0.28px;}
                                > button {height:23px !important;margin-top:-1px;background:transparent !important;
                                    &:before, &:after {content:'';display:inline-block;width:1px;height:10px;margin-top:-1px;background:#7c7c7c;}
                                    &:before {transform:rotate(45deg);}
                                    &:after {margin-left:-1px;transform:rotate(-45deg);}
                                    > img {display:none !important;}
                                }
                                &:hover {border-color:#1c90fb;box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.16);
                                    [class*="OBTChip_labelStyle"] {color:#1d90fb;}
                                    > button {
                                        &:before, &:after {background:#2e99fb;}
                                    }
                                }
                                &:active {background:#e9f5ff;}
                            }
                        }
                    }
                }
            }
        }
    }
    //카테고리목록
    > [class*="OBTDockPanel_middle"] {margin-top:0 !important;}
    //장바구니/상품문의 
    [class*="OBTDockPanel_right"] {position:relative;
        .dock-collapsed1 {position:absolute;left:0;top:0;background:#fff;
            //장바구니(주문금액)
            .order-sum2 {margin-top:-10px;height:194px;}
        }
        .isShow {z-index:2;}
    }
}


/******************************************************
    기업금융관리(POC)  =>  POC 시연 후 삭제
******************************************************/
/*====================================================
    기업금융관리(ERPPoc01)
====================================================*/
.ERPPoc01 {
    &.wrap-content0 {background:#e2e8f2;}
}

/*====================================================
    자금이체/전표처리(ERPPoc04)
====================================================*/
.ERPPoc04 {
    &.wrap-content0:before {content:'';position:absolute;left:0;right:0;top:54px;width:100%;height:1px;background:#e5e5e5;}
}

/*====================================================
    손익계산서(ERPPoc11)
====================================================*/
.ERPPoc11 {
    /* 팝업 */
    &.ly-popup {
        /* 재무 예측 컨설팅 서비스 : 로딩 */
        &.pop1 {
            [class*="OBTDialog_bottomButton"] {display:none;}
        }
    }
}

/*====================================================
    [W/P]
    - 자금현황(ERPPoc02) > 결재작성(ERPPoc02P)
    - 자금이체/전표처리(ERPPoc04) > 결재작성(ERPPoc04P)
    - 손익계산서(ERPPoc11) > 재무보고서(ERPPoc11P / ERPPoc11P_01)
====================================================*/
/* 공통 */
.ERPPoc02P, .ERPPoc04P, .ERPPoc11P, .ERPPoc11P_01 {
    &.EAAppDocPop {
        .cont {position:relative;padding:70px !important;background:url('../imgs/erp/poc/bg02.png') repeat-y;text-align:center;box-sizing:border-box;
            &:before, &:after {content:'';position:absolute;left:0;width:100%;height:70px;background-repeat:no-repeat;}
            &:before {top:0;background-image:url('../imgs/erp/poc/bg01.png');}
            &:after {bottom:0;background-image:url('../imgs/erp/poc/bg03.png');}
            > div {height:100%;}
        }
    }
}
/* 자금이체/전표처리(ERPPoc04) > 결재작성(ERPPoc04P) */
.ERPPoc04P {
    &.EAAppDocPop {
        .cont {background-image:url('../imgs/erp/poc/bg02_01.png');
            &:before {background-image:url('../imgs/erp/poc/bg01_01.png');}
            &:after {background-image:url('../imgs/erp/poc/bg03_01.png');}
        }
    }
}

/*====================================================
    클릭 유도(네모box)
====================================================*/
.tt:after {
    content:'';
    position:absolute;
    top: -5px;
    left: -5px;
    width: 986px;
    height: 44px;
    background: transparent;
    border: 2px solid red;
    opacity: 0.1;
    box-sizing:border-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both;
    -webkit-animation-name: tt-zoomIn;
    animation-name: tt-zoomIn;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: tt-zoomIn;
    animation-name: tt-zoomIn;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes tt-zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.99, 0.8, 1);
        transform: scale3d(0.99, 0.8, 1);
    }

    50% {
        opacity: 1;
    }
}

@keyframes tt-zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.99, 0.8, 1);
        transform: scale3d(0.99, 0.8, 1);
    }

    50% {
        opacity: 1;
    }
}