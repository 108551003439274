.calendar {
    transition: opacity 0.3s ease-out;
    opacity: 1;

    &:focus {
        outline: none;
    }

    &:not(.visible) {
        opacity: 0;
    }

    .calendarTable > * {
        min-height: 207px;
    }
}
