.root {
    box-sizing: border-box;
    border-top: 2px solid #666666;
    border-bottom: 1px solid rgb(225, 225, 225);
    border-left: 1px solid rgb(225, 225, 225);
    position: relative;

    & > .grid {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
            content: "";
            position: absolute;
            display: inline-block;
            top: 0px;
            right: 0px;
            bottom: 0px;
            width: 1px;
            box-sizing: border-box;
            border-right: 1px solid rgb(225, 225, 225);
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: -2px;
        left: 50%;
        right: 0px;
        height: 2px;
        width: 0%;
        background-color: #1C90FB;
        pointer-events: none;
        transition: left, width;
        transition-duration: 200ms;
        transition-timing-function: ease-in;
    }

    &:focus-within {
        &::after {
            left: 0%;
            width: 100%;
        }
    }
}

.emptyData {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.emptyDataTiny {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.emptyTextNormal {
    margin-top: 10px;
    font-size: 12px;
    color: #8c8c8c;
}

.emptyTextSmall {
    margin-top: 10px;
    font-size: 12px;
    color: #8c8c8c;
}

.emptyTextTiny {
    margin-left: 5px;
    font-size: 12px;
    color: #8c8c8c;
}

// .dateFieldDialog {
//     z-index: 2000;
// }

.dateFieldDialog > div {
    z-index: 2000;
}

.hoverWrapper {
    position: absolute;
    pointer-events: none;
    left: -1px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;

    .hoverRoot {
        box-sizing: border-box;
        position: absolute;
        // border: 1px solid #1c90fb;
        transition:
            top 100ms ease-out,
            opacity 100ms ease-out;
        opacity: 0;
        max-width: 100%;
        overflow: hidden;

        &.visible {
            opacity: 1;
        }

        .hoverActionButtonRoot {
            box-sizing: border-box;
            position: absolute;
            top: 0px;
            right: 0px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding-right: 10px;
            padding-left: 20px;
            background: linear-gradient(to right, #ffffff00 0px, #ffffff 20px, #ffffff 100%);

            & > * {
                pointer-events: all;
            }

            & > *:not(:last-child) {
                margin-right: 5px;
            }
        }

        .reorderRoot {
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            overflow: hidden;

            .moveThumb {
                flex: 0 0 auto;
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0px;
                width: 20px;
                opacity: 0;
                pointer-events: all;
                cursor: pointer;
                transition: opacity 100ms ease-out;

                &:hover,
                &.on {
                    background-color: #2c98fb;
                    background-image: url("../OBTTreeView//img/icon-con-change.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    opacity: 1;
                }
            }

            .reorderImageRoot {
                flex: 1 1 auto;
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0px;
                transition: left 100ms ease-out;

                &.on {
                    left: 20px;
                }

                .reorderImage {
                    position: absolute;
                }
            }
        }
    }
}

.privacyLoadingRoot {
    position: absolute;
    right: 20px;
    bottom: 20px;
    pointer-events: none;

    .privacyLoading {
        background-color: #ffffff;
    }
}

.searchBoxContainer {
    position: absolute;
    // top: 70px;
    // right: 20px;
    background: #fff;
    border: 1px solid #d1d3d4;
    box-sizing: border-box;
    box-shadow:
        rgba(0, 0, 0, 0.12) 0px 1px 6px,
        rgba(0, 0, 0, 0.12) 0px 1px 4px;
    outline: none;

    * {
        text-align: left !important;
        box-sizing: border-box;
        user-select: none;
    }

    & > .titleWrapper {
        line-height: 34px;
        background: #fafafa;
        display: flex;
        align-items: center;
        padding: 0 10px;
        cursor: move;

        & > .titleText {
            font-size: 14px;
            font-weight: bold;
            flex: 1 1 auto;
        }

        & > .closeBtn {
            outline: none;
            border: none;
            width: 20px;
            height: 20px;
            background: url("../Images/ic_close_01_m_disable@2x.png");
            background-size: cover;
            margin-right: -2px;
        }
    }

    & > .searchAreaContainer {
        padding: 10px;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        gap: 5px;

        & > .searchInputRoot {
            width: 100%;
            height: 27px;
            position: relative;
            flex: 1 1 auto;

            & > .inputContainer {
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                border: 1px solid #ccc;
                outline: none;

                &:focus {
                    border: 1px solid #1c90fb;
                }

                &:focus-within {
                    border: 1px solid #1c90fb;
                }

                & > input {
                    width: calc(100% - 27px);
                    height: 100%;
                    padding: 0 5px;
                    outline: none;
                    border: none;
                    flex: 1 1 auto;
                }

                & > .inputArrowBtn {
                    display: inline-block;
                    width: 27px;
                    height: 27px;
                    cursor: pointer;
                    background-image: url("../Images/icon-btn-arrow-down-normal.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: transform 0.2s ease;
                    transform-origin: center;

                    &.arrowUp {
                        transform: rotate(0.5turn);
                    }
                }
            }

            & > .inputDropDownContainer {
                transition: height 0.2s ease-out;
                background: #fff;
                height: 0;
                max-height: 224px;
                position: absolute;
                top: 27px;
                left: 0px;
                border: 1px solid #ccc;
                opacity: 0;
                box-shadow:
                    rgba(0, 0, 0, 0.12) 0px 1px 6px,
                    rgba(0, 0, 0, 0.12) 0px 1px 4px;
                z-index: 2000;
                display: flex;
                flex-direction: column;

                &.dropdownOpen {
                    height: 224px;
                    opacity: 1;
                    border-top: none;
                }

                & > .scrollWrapper {
                    height: 189px;
                    overflow-y: scroll;

                    & > li {
                        height: 27px;
                        padding: 0 5px;
                        display: flex;
                        align-items: center;

                        &:hover {
                            background-color: #e9f5ff;
                            color: #1c90fb;
                            cursor: pointer;
                        }

                        & > .keywordText {
                            flex: 1 1 auto;
                            line-height: 27px;
                        }

                        & > .removeBtn {
                            width: 15px;
                            height: 15px;
                            background-image: url("../Images/ic_close_01_m_disable@2x.png");
                            background-size: cover;
                            cursor: pointer;
                            margin-right: 5px;
                        }
                    }

                    & > .emptyMessage {
                        color: #d1d3d4;
                    }
                }

                .allClearWrapper {
                    padding: 0 5px;
                    background: #fafafa;
                    width: 100%;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    font-size: 0.9em;

                    & > .allClearTitle {
                        flex: 1 1 auto;
                        font-weight: bold;
                    }

                    & > .allClearBtn {
                        cursor: pointer;
                        color: #a1a3a4;

                        &:hover {
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    & > .searchDetailContainer {
        padding: 10px;

        .smallType {
            & > tbody > tr > td > div {
                flex-wrap: wrap;

                & > div {
                    margin: 5px 0;
                }
            }
        }

        .detailDataWrap {
            display: flex;
            align-items: center;
            width: 125px;

            .dropdownRadioButton {
                margin-right: 0;
            }
        }

        .detailCheck {
            margin-left: 0px;
        }
    }
}

.multiLangDimmer {
    position: absolute;
    inset: 0px;
}

.multiLangRoot {
    position: absolute;
}

.multiLangPanelRoot {
    margin: 5px 0px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    padding: 10px;
    .multiLangPanelTable {
        width: max-content;
        tr {
            td:first-child {
                width: 1%;
                white-space: nowrap;
                font-weight: bold;
                text-align: right;
                padding-right: 5px;
            }
            &:not(:last-child) {
                td {
                    padding-bottom: 5px;
                }
            }
        }
    }
}
