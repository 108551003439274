.tabsRoot {
  display: inline-block;
  position: relative;

  .tabRoot {
    & > li:first-child > button:first-child > span:first-child {
      top: 6px !important;
      bottom: 6px !important;
      font-family: inherit;
    }

    &:first-child > li:first-child > button:first-child > span:first-child {
      display: none;
    }
  }

  .buttonGroup {
    position: absolute;
    top: 4px;
    right: 0px;
    
    .btnLeft {
      margin-right: 2px;
    }

    &.hideTabs {
      display: none;
    }
  }

  &.hideTabs {
    div:first-child > div:first-child > div:first-child {
      border-bottom: none !important;
    
      & > div:first-child {
        display: none !important;
      }
    }
  }
}

.template {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: initial;
  overflow: auto;

  &:not(.templateSelected) {
    display: none;
    overflow: hidden;
  }
}
