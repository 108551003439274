@charset "utf-8";   
/**************************************************  
   layout  
**************************************************/  
#wrap {width: 100%;height: 100%;position:relative;z-index:1;}  
#klagoWrap{position: absolute;top: 0;right: 0;left: 0;bottom: 0;width: 100%;height: 100%;/*min-width: 1280px;min-height: 840px;*/overflow: hidden;}  

// 고객사공지  
#customerNoticeBox{display:none;position:relative;width:100%;height:70px;z-index:9999;
   &.on{display:block}
   // customerNoticeBox가 보여지면 보여지는 높이만큼 영향을 받는 요소에 보정값 처리  
   &.on + #klagoWrap{top:70px;height:calc(100% - 70px);}  
   &.on + #klagoWrap #containerWrap #headerWrap{top:70px;}  
   &.on + #klagoWrap .newPortalWrap .newPortalHeaderWrap {top:70px;}  
   &.on + #klagoWrap .newPortalWrap .userPortletControll {top:70px;}  
   &.on + #klagoWrap .newPortalWrap.mode-SRT .searchCategory {top:120px;}  
   &.on + #klagoWrap .newPortalWrap.mode-SRT .relationKeyword {top:172px;}  
}

// 크롬브라우저 최적화 안내  
.ieChrome{position:relative;width:100%;height:100px;background-color: #303c52;box-sizing:border-box;justify-content:center;  
   .textBox{padding:0 200px 0 70px;  
      .text01{font-size:22px;font-weight:bold;line-height:26px;color:#ffffff;letter-spacing: -0.55px;}  
      .text02{margin:8px 20px 0 0;font-size:13px;line-height:20px;color:#ffffff;opacity: 0.65;letter-spacing: -0.65px;}  
   }  
   .chromeBtn{  
      position:absolute;top:27px;right:50px;width:146px;height:46px;  
      padding:7px 0 0 40px;font-size:12px;line-height:14px;color:#37b9fc;letter-spacing: -1px;  
      border:2px solid #40b2ed;border-radius:10px;  
      background: rgba(64, 178, 237, 0) url('../imgs/main/img_download_chrome.png') no-repeat left 11px center;background-size: 22px;  
      box-sizing: border-box;cursor: pointer;  
      &:hover{color:#ffffff;background: rgba(58, 188, 255, 0.4) url('../imgs/main/img_download_chrome.png') no-repeat left 11px center;background-size: 22px;}  
   }  
   .closeBtn{position:absolute;top:10px;right:10px;width:14px;height:14px;background: url('../imgs/ico/ic_close_01_s_normal.png') no-repeat center;cursor: pointer;  
      &:hover{background: url('../imgs/ico/ic_close_01_s_disable.png') no-repeat center;}  
   }  
}
  
// ie -> Chrome 지원환경 브라우저 설정안내  
.popIeChromeGuide{position:relative;  
   .tit_p{margin:70px 0 0 0;}  
   .chromeDownBtn{position:absolute;top:7px;right:10px;  
      > div > img{width:16px;}  
   }  
   .borderBox{margin:0 0 30px 0;border:1px solid #cccccc;border-radius:4px;background-color: #ffffff;overflow:hidden;  
      img{width:100%}  
   }  
}  
  
/*window 팝업*/  
.pubWindowPopup {width: 100%;height: 100%;  
   >div {min-width:auto !important;}  
}  
  
.pop_wrap {height:100%;box-sizing: border-box;  
   .pop_head {padding:20px 20px 0 20px;box-sizing: border-box;}  
   .pop_head_submit { position:relative;  
         .layer_title{height:32px; padding:10px 20px; background-color: #394456; box-sizing:content-box;}  
    
         .left_div{float:left;  
             .layer_tit{font-size: 18px; line-height:32px; letter-spacing:-1px; font-weight: bold; color:#fff;}  
         }  
         .right_div{float:right;
            .line{width: 1px; height: 16px; margin: 0 6px !important; background-color: #b5b5b5;}
         }  
   }  
   /* [class*="OBTDockPanel_middle"] {margin-top:0 !important;} */  
   .pop_con {padding:0px 20px 10px 20px;box-sizing: border-box;overflow-y:auto;}  
   .pop_foot {  
      .cen_div {padding-top: 15px;width: 100%; height: 62px; box-sizing: border-box; background-color: #f2f2f2;text-align: center;  
     
          > div {margin-left: 4px;}  
          > div:first-child{margin-left: 0;}  
      }   
   }  
  
   .layer_title {margin-right: 0;}  
   [class*="OBTDockPanel_bottom"] {height:62px;}  
   &.pop_notice {  
      [class*="OBTDockPanel_bottom"] {height:35px;}        
   }  
   &.pop_admin {  
      [class*="OBTDockPanel_bottom"] {height:35px;}  
      .cen_div {display: none;}  
   }  
}  
.pop_closeall{height:35px; background:#767676; line-height:35px; color:#fff; padding:0 15px;width:100%;box-sizing: border-box;}  
.pop_close{color:#fff; background:url('../imgs/ico/ic_close_01_m_normal.png') no-repeat right 3px; padding-right:20px; cursor:pointer;}  
  
// 리뉴얼 공지팝업  
.newPop_wrap {position: relative; height:100%; text-align: left; 
   .pop_head {box-sizing: border-box; height: 100px; background:url('../imgs/bg/img_notice_bg.png') no-repeat right bottom, linear-gradient(to right, #d9f0ff, #e3f4ff 35%, #ebf7ff); display: flex; align-items: center; padding: 0 0 0 30px;  
      &::after{content:''; position:absolute; right:20px; width:160px; height:100px; background:url('../imgs/bg/img_notice@2x.png') no-repeat right center; background-size:160px 100px; z-index:1;}  
      .layer_tit{font-size: 22px;line-height: 1.05; letter-spacing: -1.1px; color: #0c2b65; font-weight: bold; min-width: fit-content;}  
      .left_div_in{padding:0 190px 0 12px; font-size: 13px; line-height:20px; color: #5c7198;}  
   }
   .pop_title{border-bottom: 1px solid #e6e6e6; margin: 20px 30px 0; padding-bottom: 20px; box-sizing:border-box; 
      .titleTooltip{max-width: 100%;}
      .title{font-size: 16px;font-weight: bold;line-height: 1.38; letter-spacing: -0.8px;margin-right: 30px;}  
      .sub{color:#8c8c8c; padding-top:2px;}  
   }  
   .pop_con{padding:20px 30px 30px 30px; 
      .conBox{line-height:20px; 
         img{max-width:100%;}  
      }  
   }  
   .pop_foot {height:62px; padding: 15px 0; background-color: #f7f7f7; box-sizing: border-box;}     
}  
  
/* side */  
#sideWrap{position: absolute;top: 0;left: 0;bottom: 0;background: #333948;z-index: 9999;transition: all 0s;}  
#sideWrap .bmMenuBtn{display: none;}
#sideWrap.on {transition: all 0.2s;}  
#sideWrap.on .bmMenuBtn{display: block;}  
#sideWrap .module-item{position: relative;height: 44px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;-webkit-touch-callout: none;box-sizing: border-box;z-index: 5;}  
#sideWrap .module-item.selected{background-color: rgba(0,0,0,0.5);}  
#sideWrap .module-item.selected .module-link{opacity: 1 !important;}  
#sideWrap #sideGnb .module-item.selected .module-name:before {color:#fff;}  
  
#sideWrap .module-link{position: relative;display: block;width: 48px;height: 50px;opacity: .4;transition: opacity 0.5s;text-indent: -9999px;cursor: pointer;background: no-repeat center;z-index: 5;}  
  
#sideWrap .fixed_top{position: absolute;top: 0;left: 0;right: 0;height: 50px;background: #333948;z-index: 6;}  
#sideWrap .fixed_top .module-name{opacity: 0;position: absolute;top: 0;left: 47px;transform: translateX(-150%);z-index: 5;}  
#sideWrap .fixed_top .module-name:before{content:attr(data-name);display: block;width: 0px;height: 50px;line-height: 50px;padding: 0 0 0 20px;font-size: 16px;color: #ffffff;letter-spacing: -0.5px;border: 1px solid #13141a;background: #2b2e37;box-sizing: border-box;transition: 0.3s;overflow: hidden;}  
#sideWrap .fixed_top .module-name.sub-item ul{display:none;}  
#sideWrap .fixed_top .module-link.allMenu{background-image:url('../imgs/ico/ic_allmemu_open.png');background-size: 48px 44px;} /*전체메뉴*/  
#sideWrap .fixed_top .module-link.personalMenu{background-image:url('../imgs/ico/ico_mymenu.png');background-size: 48px 44px;} /*개인메뉴*/  
#sideWrap .fixed_top > ul li  {float: left;}  
#sideWrap .fixed_top .allM {height:50px; width: 48px;float: left;background-color: #333948;z-index: 10;position:relative;}  
#sideWrap .fixed_top .allM .ico {width: 100%;height:100%;opacity:0.2;background:url('../imgs/ico/ic_allmemu_open.png') no-repeat center center;background-size: 48px 44px;}  
#sideWrap .fixed_top .allM:hover .ico {opacity:1;cursor:pointer;}  
#sideWrap .fixed_top .allM.selected .ico {background:url('../imgs/ico/ic_allmemu_close.png') no-repeat center center;background-size: 48px 44px;opacity:1;}  
#sideWrap.on .fixed_top .allM .ico {background:url('../imgs/ico/ic_allmemu_close.png') no-repeat center center;background-size: 48px 44px;}  
#sideWrap .fixed_top .fixed_top_in {height:50px;box-sizing: border-box; overflow: hidden; border-bottom:1px solid #333948}  
#sideWrap .fixed_top .fixed_top_ul {overflow: hidden;padding:0 12px;display:flex;}  
#sideWrap.on .fixed_top .fixed_top_ul li div.ico{width:38px;}  
#sideWrap.on .fixed_top .fixed_top_in { border-bottom:1px solid rgba(255,255,255,0.2);}  
#sideWrap .fixed_top .fixed_top_ul li {flex:1;text-align: center;}  
#sideWrap .fixed_top .fixed_top_ul li div.ico {height:49px; width:0px;margin:0 auto;opacity:0.2;transition:all 0.3s;cursor:pointer;box-sizing: border-box; position: absolute;top:1px;border-bottom:2px solid transparent;}  
  
#sideWrap .fixed_top .fixed_top_ul li:hover div.ico {opacity:1;border-bottom:2px solid #fff;}   
#sideWrap.on .fixed_top .fixed_top_ul li.selected div.ico {opacity: 1;border-bottom:2px solid #fff;}  
#sideWrap .fixed_top .fixed_top_ul li.snbTabM .ico{background:url('../imgs/ico/ic_snb_tab_menu.png') no-repeat center center;background-size: 22px;}  
// #sideWrap .fixed_top .fixed_top_ul li.snbTabF {}  
#sideWrap .fixed_top .fixed_top_ul li.snbTabF .ico{background:url('../imgs/ico/ic_snb_tab_favorite.png') no-repeat center center;background-size: 22px;}  
// #sideWrap .fixed_top .fixed_top_ul li.snbTabR {}  
#sideWrap .fixed_top .fixed_top_ul li.snbTabR .ico{background:url('../imgs/ico/ic_snb_tab_recent.png') no-repeat center center;background-size: 22px;}  
  
#sideWrap #sideGnb.mini li {width:48px !important;}  
// 개발 완료 후 삭제해야할 부분 [CSA10-19094]
#sideWrap .snbSc .stab {display:none;}  
#sideWrap .snbSc .stab.on {display:block;position:absolute;left:48px;right:0;color:#fff;}  
#sideWrap .snbSc .stR_div .intoMenu .dep1 .dep2 li{margin-left: 14px;}
// 개발 완료 후 살려둘 부분 [CSA10-19094]
#sideWrap .snbScWrap .stab {display:none;}  
#sideWrap .snbScWrap .stab.on {display:block;position:absolute;top:56px;bottom:0;left:48px;right:0;color:#fff;}  
#sideWrap .snbScWrap .stR_div .intoMenu .dep1 .dep2 li{margin-left: 14px;}
// #sideWrap .snbSc .stM_div {}  
// #sideWrap .snbSc .stF_div {height:100%;background-color: #2d323c;}  
// #sideWrap .snbSc .stR_div {height:100%;background-color: #2d323c;}  

// [CSA10-19086] 맥 > 환경설정 > 스크롤 막대보기 여부에 따라 하단 아이콘 짤림 현상 발생 (2024.08.08)
// #sideWrap .snbSc > div:first-child{overflow:hidden !important; margin-right: 0 !important; margin-bottom: 0 !important;} 
// #sideWrap:hover .snbSc > div:first-child{overflow:scroll !important; margin-right: -15px !important; margin-bottom: -15px !important;}
  
.intoMenu {
   .dep1 {  
      > li {  
         > .tit_div {height:44px; line-height: 44px;font-size:15px;font-weight:normal;padding-left: 16px;box-sizing: border-box; color:#858891; background:url('../imgs/ico/btn_snb_arrow_down@2x.png') no-repeat right 20px center; background-size: 11px; cursor:pointer;}  
      }  
      > li.open {  
         > .tit_div {font-weight:bold;color:#fff;background: #354064 url('../imgs/ico/btn_snb_arrow_up@2x.png') no-repeat right 20px center; background-size: 11px;}  
         .dep2 {height:auto;padding:0px 0 8px 0px;  
           > li:first-child {margin-top:14px;}
         }  
      }  
      .dep2 {background:#2d313e;box-sizing:border-box;height:0px;padding:0px 0 0px 10px;overflow: hidden;  
         li {margin-left: 14px;position:relative;  
            .tit_div {height:18px; line-height: 18px;font-size:13px; box-sizing: border-box; color:#fff; background:url('../imgs/ico/ic_menu.png') no-repeat left center;cursor:pointer;padding-left:24px;margin-bottom: 8px;
               &:hover .tit{color: #1c90fb;}
            }  
            .tit_div.folder {background:url('../imgs/ico/ic_folder_close.png') no-repeat left center;}   
            .clo {width: 16px; height: 16px;vertical-align: top; background: url("../imgs/ico/ic_close_01_s_normal@2x.png") no-repeat center; background-size: 14px; cursor: pointer; display: none;position:absolute;top:1px;right:10px;}  
            .tit_div:hover  > .clo {display: inline-block;}  
            .tit {white-space:nowrap; overflow: hidden;text-overflow: ellipsis;width:calc(100% - 30px);display:block;}  
            .tit_clone {visibility: hidden; white-space:nowrap;z-index:-10;text-indent:-50000px;font-size:13px;}  
         }  
         li.open {  
            .tit_div.folder {background:url('../imgs/ico/ic_folder_open.png') no-repeat left center; color:#fff; }  
            .dep3 {height:auto;}  
          }  
          .on {  
           > .tit_div {color:#1c90fb;}  
         }  
         .open {  
            > .tit_div {color:#1c90fb;}  
          }  
      }  
      .dep3 {box-sizing:border-box;height:0px;transition: all 0.3s ease;overflow: hidden;  
         li {margin-left: 20px;}  
      }  
        
   }  
  
}  

// side 즐겨찾기탭
.gnbTreeViewWrap{position: relative;
   .bmMenu{position: relative; display: flex; flex-direction: row; align-items: center; width: 100%; height: 44px; padding: 0 20px 0 16px; background-color: #354064; box-sizing: border-box; cursor: pointer;
      &.close{background-color: transparent;
         p{font-weight: normal; color: #858891;}
      }
      p{max-width: calc(100% - 30px); text-align: left; font-size: 15px; font-weight: bold; color: #ffffff;}
      .icoArrowUp{width: 11px; height: 11px; padding: 0 !important; border: none !important; background: url('../imgs/ico/btn_snb_arrow_up@2x.png') no-repeat center center !important; background-size: 11px !important;}
      .icoArrowDown{width: 11px; height: 11px; padding: 0 !important; border: none !important; background: url('../imgs/ico/btn_snb_arrow_down@2x.png') no-repeat center center !important; background-size: 11px !important;}
   }
   .bmMenuBtn{position: absolute; right: 40px; top: 13px; align-items: center;
      .icoFolderAdd{width: 18px; height: 18px; padding: 0 !important; border: none !important; background: url('../imgs/ico/ic_folder_new_m_normal@2x.png') no-repeat center center !important; background-size: 18px !important;
         &:hover{background: url('../imgs/ico/ic_folder_new_m_over@2x.png') no-repeat center center !important; background-size: 18px !important;}
      }
   }
   .bmCon{padding: 12px 0; background-color: #2d313e;
      .bmTooltip{line-height: 18px; max-width: 100%;}
      .itemDiv{position: relative; height: 27px; padding: 0 20px 0 13px; align-items: center; box-sizing: border-box; cursor: pointer;
         &::before{content: ''; position: absolute; left: 0; top: 50%; transform: translateY(-50%); display: none; width: 14px; height: 14px; background: url('../imgs/ico/ic_view_list_02_s_normal@2x.png') no-repeat center center; background-size: 14px;}
         &:hover{
            &::before{display: block;}
            .titleBox .txt{color: #1c90fb;}
            .conBtnBox{display: flex; align-items: center;}
         }
         &.dep2{padding: 0 20px 0 23px;
            &::before{left: 11px;}
         }
         &.dep3{padding: 0 20px 0 33px;
            &::before{left: 21px;}
         }
         &.dep4{padding: 0 20px 0 43px;
            &::before{left: 31px;}
         }
         &.dep5{padding: 0 20px 0 53px;
            &::before{left: 41px;}
         }
         .titleBox{padding: 0 5px; align-items: center;
            .txt{font-size: 13px; color: #ffffff; white-space: nowrap; text-overflow: ellipsis; word-break: break-all; overflow: hidden;}
         }
         .textFieldBox{padding: 0 0 0 6px; align-items: center;
            .textField{margin-right: 4px;
               > div > div{height: 17px !important;}
            } 
         }
         .conBtnBox{display: none;
            > div{display: block; margin-right: 4px;
               &:last-child{margin-right: 0;}
               button{padding: 0 !important;}
            }
         }
         .checkBtn{width: 14px; height: 14px; padding: 0 !important; border: none !important; background: url("../imgs/ico/ic_check_01_s_normal@2x.png") no-repeat center center !important; background-size: 14px !important;}
         .editBtn{width: 14px; height: 14px; border: none !important; background: url("../imgs/ico/ic_edit_s_normal@2x.png") no-repeat center center !important; background-size: 14px !important;}
         .deleteBtn{width: 14px; height: 14px; border: none !important; background: url("../imgs/ico/ic_close_01_s_normal@2x.png") no-repeat center center !important; background-size: 14px !important;}  
         .ico-folder-open{width: 18px; height: 18px; background: url('../imgs/ico/ic_snb_folder_open@2x.png') no-repeat center center; background-size: 18px;}
         .ico-folder-close{width: 18px; height: 18px; background: url('../imgs/ico/ic_snb_folder_close@2x.png') no-repeat center center; background-size: 18px;}
         .ico-menu{width: 18px; height: 18px; background: url('../imgs/ico/ic_snb_menu@2x.png') no-repeat center center; background-size: 18px;}
      }
   }
}
  
#sideWrap .fixed-bottom{display: block;position: relative;left: 0;right: 0;bottom: 0; top:6px; height: 40px; box-sizing: border-box;z-index: 6;}  
#sideWrap .fixed-bottom .arrow-item{height: 17px; font-size: 1px; line-height: 1px; text-indent: -9999px; cursor: pointer; background: transparent no-repeat center; opacity: .4;width: 48px;}  
#sideWrap .fixed-bottom .arrow-item:hover{opacity:1;}  
#sideWrap .fixed-bottom .arrow-item.up{background-image: url('../imgs/ico/ico_arrowItem_up.png');}  
#sideWrap .fixed-bottom .arrow-item.down{background-image: url('../imgs/ico/ico_arrowItem_down.png');}  
#sideWrap .fixed-bottom .arrow-item.disabled{cursor: inherit;opacity: .1 !important;}  
  
/* side gnb */  
#sideWrap #sideGnb{position: absolute;top: 0px;bottom: 0px;margin: 0;padding: 0;width: 48px;background: #333948;transition: top 0.5s;width:100%;}  
// #sideWrap #sideGnb .module-item:first-child{position: relative;width: 48px;height: 44px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;-webkit-touch-callout: none;box-sizing: border-box;z-index: 5;}  
// #sideWrap #sideGnb .module-item:first-child .module-link{height: 44px;}  
// #sideWrap #sideGnb .module-item:first-child .module-name:before{content:attr(data-name);position: relative;display: block;width: 0px;height: 44px;line-height: 44px;padding: 0 0 0 20px;font-size: 16px;color: #ffffff;letter-spacing: -0.5px;border: 1px solid #13141a;background: #2b2e37;box-sizing: border-box;z-index: 1;transition: 0.3s;overflow: hidden;}  
#sideWrap #sideGnb .module-link{position: relative;display: block;width: 48px;height: 44px;opacity: .4;transition: opacity 0.5s;text-indent: -9999px;cursor: pointer;background: no-repeat center;z-index: 5;}  
#sideWrap #sideGnb .module-name{cursor:pointer; width: calc(100% - 48px); position: absolute;top: 0;left: 47px;z-index: 5;}  
#sideWrap #sideGnb .module-name:before{content:attr(data-name);position: relative;display: block;height: 44px;line-height: 44px;margin: 0 0 0 20px;font-size: 15px;color: #858891;letter-spacing: -0.5px;box-sizing: border-box;z-index: 1;transition: 0.3s;overflow: hidden;}  
#sideWrap #sideGnb .module-name.sub-item:before{background: #2b2e37;}  
#sideWrap #sideGnb .module-name ul{position: relative;width: 100%;padding: 3px 0;font-size: 14px;color: #000000;letter-spacing: -0.5px;border: 1px solid #13141a;background: #ffffff;box-sizing: border-box;z-index: 1;}  
#sideWrap #sideGnb .module-name ul li{height: 36px;line-height: 36px;padding: 0 0 0 20px;transition: all 0.3s;}  
#sideWrap #sideGnb .module-name ul li span{cursor: pointer;padding: 0 0 0 8px;background: url('../imgs/ico/ico_subItem_bullet.png') no-repeat left;}  
#sideWrap #sideGnb .module-name.selected {font-weight: bold;}  
#sideWrap #sideGnb li.module-item:hover {background-color: rgba(0, 0, 0, 0.4);}  
#sideWrap #sideGnb li.module-item:hover .module-name:before {color:#fff;}  
#sideWrap #sideGnb li.module-item:hover .module-link {opacity: 1;}  
#sideWrap .sideWrapIn {position: relative;height: 100%;overflow: hidden;}  
// 개발 완료 후 삭제해야할 부분 [CSA10-19094]
.snbSc {margin-top: 56px;}  
.snbSc > div:last-child {right:6px !important;z-index: 10;}  
.snbSc > div:last-child > div {background-color: #fff !important;visibility: hidden;}  
#sideWrap:hover .snbSc > div:last-child > div {visibility: visible;}  
// 개발 완료 후 살려둘 부분 [CSA10-19094]
.snbScWrap {margin-top: 56px;}  
.snbScroll.scrollNo > div:first-child{overflow: hidden !important; margin-bottom: 0 !important;}
.snbScroll > div:last-child {right:6px !important;z-index: 10;}  
.snbScroll > div:last-child > div {background-color: #fff !important;visibility: hidden;}  
#sideWrap:hover .snbScroll > div:last-child > div {visibility: visible;}  
/* gnb icon */    
   
// uc    
#sideWrap #sideGnb .module-link.EA{background-image:url('../imgs/ico/gnb/ic_gnb_ea@2x.png');background-size: 48px 44px;}          // 전자결재    
#sideWrap #sideGnb .module-link.EC{background-image:url('../imgs/ico/gnb/ic_gnb_ec@2x.png');background-size: 48px 44px;}          // 전자계약    
#sideWrap #sideGnb .module-link.ML{background-image:url('../imgs/ico/gnb/ic_gnb_ml@2x.png');background-size: 48px 44px;}          // 메일    
#sideWrap #sideGnb .module-link.CL{background-image:url('../imgs/ico/gnb/ic_gnb_cl@2x.png');background-size: 48px 44px;}          // 일정    
#sideWrap #sideGnb .module-link.RM{background-image:url('../imgs/ico/gnb/ic_gnb_rm@2x.png');background-size: 48px 44px;}          // 자원    
#sideWrap #sideGnb .module-link.BD{background-image:url('../imgs/ico/gnb/ic_gnb_bd@2x.png');background-size: 48px 44px;}          // 게시판    
#sideWrap #sideGnb .module-link.GSM{background-image:url('../imgs/ico/gnb/ic_gnb_management@2x.png');background-size: 48px 44px;} // 총무관리    
#sideWrap #sideGnb .module-link.OF{background-image:url('../imgs/ico/gnb/ic_gnb_of@2x.png');background-size: 48px 44px;}          // 원피스    
#sideWrap #sideGnb .module-link.OC{background-image:url('../imgs/ico/gnb/ic_gnb_oc@2x.png');background-size: 48px 44px;}          // 원챔버    
#sideWrap #sideGnb .module-link.KS{background-image:url('../imgs/ico/gnb/ic_gnb_ks@2x.png');background-size: 48px 44px;}          // KISS    
#sideWrap #sideGnb .module-link.FAX{background-image:url('../imgs/ico/gnb/ic_gnb_fax@2x.png');background-size: 48px 44px;}        // 팩스    
#sideWrap #sideGnb .module-link.SMS{background-image:url('../imgs/ico/gnb/ic_gnb_sms@2x.png');background-size: 48px 44px;}        // 문자    
#sideWrap #sideGnb .module-link.UMS{background-image:url('../imgs/ico/gnb/ic_gnb_fax_sms@2x.png');background-size: 48px 44px;}    // 팩스문자    
#sideWrap #sideGnb .module-link.ADDR{background-image:url('../imgs/ico/gnb/ic_gnb_address@2x.png');background-size: 48px 44px;}   // 주소록    
#sideWrap #sideGnb .module-link.CU{background-image:url('../imgs/ico/gnb/ic_gnb_cr@2x.png');background-size: 48px 44px;}          // CRM    
#sideWrap #sideGnb .module-link.OFB{background-image:url('../imgs/ico/gnb/ic_gnb_onebook@2x.png');background-size: 48px 44px;}    // ONE-BOOK    
   
// ERP    
#sideWrap #sideGnb .module-link.MA{background-image:url('../imgs/ico/gnb/ic_gnb_ma@2x.png');background-size: 48px 44px;}          // 회사기준    
#sideWrap #sideGnb .module-link.FI{background-image:url('../imgs/ico/gnb/ic_gnb_fi@2x.png');background-size: 48px 44px;}          // 회계관리,재무회계    
#sideWrap #sideGnb .module-link.CO{background-image:url('../imgs/ico/gnb/ic_gnb_co@2x.png');background-size: 48px 44px;}          // 원가관리,관리회계    
#sideWrap #sideGnb .module-link.IA{background-image:url('../imgs/ico/gnb/ic_gnb_ia@2x.png');background-size: 48px 44px;}          // 내부회계    
#sideWrap #sideGnb .module-link.CA{background-image:url('../imgs/ico/gnb/ic_gnb_ca@2x.png');background-size: 48px 44px;}          // 연결회계    
#sideWrap #sideGnb .module-link.TR{background-image:url('../imgs/ico/gnb/ic_gnb_tr@2x.png');background-size: 48px 44px;}          // 자금    
#sideWrap #sideGnb .module-link.BM{background-image:url('../imgs/ico/gnb/ic_gnb_bm@2x.png');background-size: 48px 44px;}          // 예산    
#sideWrap #sideGnb .module-link.TX{background-image:url('../imgs/ico/gnb/ic_gnb_tx@2x.png');background-size: 48px 44px;}          // 세무    
#sideWrap #sideGnb .module-link.AU{background-image:url('../imgs/ico/gnb/ic_gnb_au@2x.png');background-size: 48px 44px;}          // 감사    
#sideWrap #sideGnb .module-link.OSS{background-image:url('../imgs/ico/gnb/ic_gnb_os@2x.png');background-size: 48px 44px;}         // 운영    
#sideWrap #sideGnb .module-link.HR{background-image:url('../imgs/ico/gnb/ic_gnb_hr@2x.png');background-size: 48px 44px;}          // For me    
#sideWrap #sideGnb .module-link.HM{background-image:url('../imgs/ico/gnb/ic_gnb_hm@2x.png');background-size: 48px 44px;}          // 인사관리    
#sideWrap #sideGnb .module-link.BPM{background-image:url('../imgs/ico/gnb/ic_gnb_bpm@2x.png');background-size: 48px 44px;}        // 비즈니스플랜관리    
#sideWrap #sideGnb .module-link.BI{background-image:url('../imgs/ico/gnb/ic_gnb_bi@2x.png');background-size: 48px 44px;}          // 지능형 비즈니스    
#sideWrap #sideGnb .module-link.LE{background-image:url('../imgs/ico/gnb/ic_gnb_le@2x.png');background-size: 48px 44px;}          // 물류    
#sideWrap #sideGnb .module-link.LO{background-image:url('../imgs/ico/gnb/ic_gnb_lo@2x.png');background-size: 48px 44px;}          // 물류공통,물류일반    
#sideWrap #sideGnb .module-link.PU{background-image:url('../imgs/ico/gnb/ic_gnb_pu@2x.png');background-size: 48px 44px;}          // 구매    
#sideWrap #sideGnb .module-link.PM{background-image:url('../imgs/ico/gnb/ic_gnb_pm@2x.png');background-size: 48px 44px;}          // 설비    
#sideWrap #sideGnb .module-link.PP{background-image:url('../imgs/ico/gnb/ic_gnb_pp@2x.png');background-size: 48px 44px;}          // 생산    
#sideWrap #sideGnb .module-link.ME{background-image:url('../imgs/ico/gnb/ic_gnb_me@2x.png');background-size: 48px 44px;}          // MES    
#sideWrap #sideGnb .module-link.IM{background-image:url('../imgs/ico/gnb/ic_gnb_im@2x.png');background-size: 48px 44px;}          // 재고    
#sideWrap #sideGnb .module-link.IE{background-image:url('../imgs/ico/gnb/ic_gnb_ie@2x.png');background-size: 48px 44px;}          // 무역    
#sideWrap #sideGnb .module-link.SD{background-image:url('../imgs/ico/gnb/ic_gnb_sd@2x.png');background-size: 48px 44px;}          // 영업    
#sideWrap #sideGnb .module-link.BP{background-image:url('../imgs/ico/gnb/ic_gnb_bp@2x.png');background-size: 48px 44px;}          // 사업계획    
#sideWrap #sideGnb .module-link.PS{background-image:url('../imgs/ico/gnb/ic_gnb_ps@2x.png');background-size: 48px 44px;}          // 프로젝트    
#sideWrap #sideGnb .module-link.SF{background-image:url('../imgs/ico/gnb/ic_gnb_sf@2x.png');background-size: 48px 44px;}          // 영업자동화    
#sideWrap #sideGnb .module-link.SR{background-image:url('../imgs/ico/gnb/ic_gnb_sr@2x.png');background-size: 48px 44px;}          // 공급망    
#sideWrap #sideGnb .module-link.QM{background-image:url('../imgs/ico/gnb/ic_gnb_qm@2x.png');background-size: 48px 44px;}          // 품질    
#sideWrap #sideGnb .module-link.CI{background-image:url('../imgs/ico/gnb/ic_gnb_ci@2x.png');background-size: 48px 44px;}          // 그룹정보    
#sideWrap #sideGnb .module-link.MP{background-image:url('../imgs/ico/gnb/ic_gnb_mp@2x.png');background-size: 48px 44px;}          // 마이페이지    
#sideWrap #sideGnb .module-link.SET{background-image:url('../imgs/ico/gnb/ic_gnb_setting@2x.png');background-size: 48px 44px;}    // 시스템설정    
#sideWrap #sideGnb .module-link.SC{background-image:url('../imgs/ico/gnb/ic_gnb_sc@2x.png');background-size: 48px 44px;}          // 고객센터    
#sideWrap #sideGnb .module-link.EX{background-image:url('../imgs/ico/gnb/ic_gnb_ex@2x.png');background-size: 48px 44px;}          // 확장기능 
#sideWrap #sideGnb .module-link.AM{background-image:url('../imgs/ico/gnb/ic_gnb_AM@2x.png');background-size: 48px 44px;}          // 자산관리  
#sideWrap #sideGnb .module-link.OS{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_subcontractor@2x.png');background-size: 48px 44px;}  // 외주관리       
#sideWrap #sideGnb .module-link.SM{background-image:url('../imgs/ico/gnb/ic_gnb_smart_manufacturing@2x.png');background-size: 48px 44px;}  // 스마트제조시스템  
          
   
// ERP10    
#sideWrap #sideGnb .module-link.ERP10CI{background-image:url('../imgs/ico/gnb/ic_gnb_ci@2x.png');background-size: 48px 44px;}          // 그룹정보   
#sideWrap #sideGnb .module-link.ERP10MA{background-image:url('../imgs/ico/gnb/ic_gnb_ma@2x.png');background-size: 48px 44px;}          // 회사기준    
#sideWrap #sideGnb .module-link.ERP10BPM{background-image:url('../imgs/ico/gnb/ic_gnb_bpm@2x.png');background-size: 48px 44px;}        // 비즈니스플랜관리    
#sideWrap #sideGnb .module-link.ERP10BI{background-image:url('../imgs/ico/gnb/ic_gnb_bi@2x.png');background-size: 48px 44px;}          // 지능형 비즈니스    
#sideWrap #sideGnb .module-link.ERP10MP{background-image:url('../imgs/ico/gnb/ic_gnb_mp@2x.png');background-size: 48px 44px;}          // 마이페이지    
#sideWrap #sideGnb .module-link.ERP10AU{background-image:url('../imgs/ico/gnb/ic_gnb_au@2x.png');background-size: 48px 44px;}          // 감사    
#sideWrap #sideGnb .module-link.ERP10OS{background-image:url('../imgs/ico/gnb/ic_gnb_os@2x.png');background-size: 48px 44px;}         // 운영    
#sideWrap #sideGnb .module-link.ERP10FI{background-image:url('../imgs/ico/gnb/ic_gnb_fi@2x.png');background-size: 48px 44px;}          // 회계관리,재무회계    
#sideWrap #sideGnb .module-link.ERP10CO{background-image:url('../imgs/ico/gnb/ic_gnb_co@2x.png');background-size: 48px 44px;}          // 원가관리,관리회계    
#sideWrap #sideGnb .module-link.ERP10IA{background-image:url('../imgs/ico/gnb/ic_gnb_ia@2x.png');background-size: 48px 44px;}          // 내부회계    
#sideWrap #sideGnb .module-link.ERP10CA{background-image:url('../imgs/ico/gnb/ic_gnb_ca@2x.png');background-size: 48px 44px;}          // 연결회계    
#sideWrap #sideGnb .module-link.ERP10TR{background-image:url('../imgs/ico/gnb/ic_gnb_tr@2x.png');background-size: 48px 44px;}          // 자금    
#sideWrap #sideGnb .module-link.ERP10BM{background-image:url('../imgs/ico/gnb/ic_gnb_bm@2x.png');background-size: 48px 44px;}          // 예산    
#sideWrap #sideGnb .module-link.ERP10TX{background-image:url('../imgs/ico/gnb/ic_gnb_tx@2x.png');background-size: 48px 44px;}          // 세무    
#sideWrap #sideGnb .module-link.ERP10LR{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_lr@2x.png');background-size: 48px 44px;}    // 임대차   
#sideWrap #sideGnb .module-link.ERP10RE{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_re@2x.png');background-size: 48px 44px;}    // 리스    
#sideWrap #sideGnb .module-link.ERP10LE{background-image:url('../imgs/ico/gnb/ic_gnb_le@2x.png');background-size: 48px 44px;}          // 물류    
#sideWrap #sideGnb .module-link.ERP10LO{background-image:url('../imgs/ico/gnb/ic_gnb_lo@2x.png');background-size: 48px 44px;}          // 물류공통,물류일반    
#sideWrap #sideGnb .module-link.ERP10PU{background-image:url('../imgs/ico/gnb/ic_gnb_pu@2x.png');background-size: 48px 44px;}          // 구매    
#sideWrap #sideGnb .module-link.ERP10PM{background-image:url('../imgs/ico/gnb/ic_gnb_pm@2x.png');background-size: 48px 44px;}          // 설비    
#sideWrap #sideGnb .module-link.ERP10PP{background-image:url('../imgs/ico/gnb/ic_gnb_pp@2x.png');background-size: 48px 44px;}          // 생산    
#sideWrap #sideGnb .module-link.ERP10IM{background-image:url('../imgs/ico/gnb/ic_gnb_im@2x.png');background-size: 48px 44px;}          // 재고    
#sideWrap #sideGnb .module-link.ERP10IE{background-image:url('../imgs/ico/gnb/ic_gnb_ie@2x.png');background-size: 48px 44px;}          // 무역    
#sideWrap #sideGnb .module-link.ERP10SD{background-image:url('../imgs/ico/gnb/ic_gnb_sd@2x.png');background-size: 48px 44px;}          // 영업    
#sideWrap #sideGnb .module-link.ERP10PS{background-image:url('../imgs/ico/gnb/ic_gnb_ps@2x.png');background-size: 48px 44px;}          // 프로젝트    
#sideWrap #sideGnb .module-link.ERP10SF{background-image:url('../imgs/ico/gnb/ic_gnb_sf@2x.png');background-size: 48px 44px;}          // 영업자동화    
#sideWrap #sideGnb .module-link.ERP10SR{background-image:url('../imgs/ico/gnb/ic_gnb_sr@2x.png');background-size: 48px 44px;}          // 공급망    
#sideWrap #sideGnb .module-link.ERP10QM{background-image:url('../imgs/ico/gnb/ic_gnb_qm@2x.png');background-size: 48px 44px;}          // 품질    
#sideWrap #sideGnb .module-link.ERP10BP{background-image:url('../imgs/ico/gnb/ic_gnb_bp@2x.png');background-size: 48px 44px;}          // 사업계획    
#sideWrap #sideGnb .module-link.ERP10CR{background-image:url('../imgs/ico/gnb/ic_cr@2x.png');background-size: 48px 44px;}              // 고객관계    
#sideWrap #sideGnb .module-link.ERP10ME{background-image:url('../imgs/ico/gnb/ic_gnb_me@2x.png');background-size: 48px 44px;}          // MES   
#sideWrap #sideGnb .module-link.ERP10SC{background-image:url('../imgs/ico/gnb/ic_gnb_sc@2x.png');background-size: 48px 44px;}          // 고객센터    
#sideWrap #sideGnb .module-link.ERP10MB{background-image:url('../imgs/ico/gnb/ic_gnb_sms@2x.png');background-size: 48px 44px;}         // 모바일    
#sideWrap #sideGnb .module-link.ERP10MF{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_mf@2x.png');background-size: 48px 44px;}    // 연료관리   
#sideWrap #sideGnb .module-link.ERP10EC{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_ec@2x.png');background-size: 48px 44px;}    // 환경화학   
#sideWrap #sideGnb .module-link.ERP10PX{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_px@2x.png');background-size: 48px 44px;}    // 전력거래   
#sideWrap #sideGnb .module-link.ERP10EH{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_eh@2x.png');background-size: 48px 44px;}    // EHS   
#sideWrap #sideGnb .module-link.ERP10HR{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_hr@2x.png');background-size: 48px 44px;}    // 인사   
#sideWrap #sideGnb .module-link.ERP10WF{background-image:url('../imgs/ico/gnb/ic_gnb_erp10_wf@2x.png');background-size: 48px 44px;}    // 워크플로우   
#sideWrap #sideGnb .module-link.ERP10SM{background-image:url('../imgs/ico/gnb/ic_gnb_sm@2x.png');background-size: 48px 44px;}          // 시스템모니터링   

// HIS    
#sideWrap #sideGnb .module-link.HCZ{background-image:url('../imgs/ico/gnb/ic_snb_HCZ@3x.png');background-size: 48px 44px;}            // HIS 기초코드관리    
#sideWrap #sideGnb .module-link.HCM{background-image:url('../imgs/ico/gnb/ic_snb_HCM@2x.png');background-size: 48px 44px;}            // HIS 문자    
#sideWrap #sideGnb .module-link.HCB{background-image:url('../imgs/ico/gnb/ic_snb_HCB@3x.png');background-size: 48px 44px;}            // HIS 원무    
#sideWrap #sideGnb .module-link.HCR{background-image:url('../imgs/ico/gnb/ic_snb_HCR@2x.png');background-size: 48px 44px;}            // HIS 진료    
#sideWrap #sideGnb .module-link.HCS{background-image:url('../imgs/ico/gnb/ic_snb_HCS@2x.png');background-size: 48px 44px;}            // HIS 진료지원    
#sideWrap #sideGnb .module-link.HCC{background-image:url('../imgs/ico/gnb/ic_snb_HCC@2x.png');background-size: 48px 44px;}            // HIS 보험회사    
#sideWrap #sideGnb .module-link.HCI{background-image:url('../imgs/ico/gnb/ic_snb_HCI@2x.png');background-size: 48px 44px;}            // HIS 통계    
#sideWrap #sideGnb .module-link.HCF{background-image:url('../imgs/ico/gnb/ic_snb_HCF@2x.png');background-size: 48px 44px;}            // HIS 서식관리    
#sideWrap #sideGnb .module-link.HCN{background-image:url('../imgs/ico/gnb/ic_snb_HCN@2x.png');background-size: 48px 44px;}            // HIS 재고관리    
#sideWrap #sideGnb .module-link.HCA{background-image:url('../imgs/ico/gnb/ic_snb_HCA@3x.png');background-size: 48px 44px;}            // HIS 서비스관리    
#sideWrap #sideGnb .module-link.HEXCE{background-image:url('../imgs/ico/gnb/ic_snb_HEXCE@3x.png');background-size: 48px 44px;}        // HIS 서비스관리    
#sideWrap #sideGnb .module-link.HCL{background-image:url('../imgs/ico/gnb/ic_education@3x.png');background-size: 48px 44px;}          // HIS 법정교육    
#sideWrap #sideGnb .module-link.HEXCL{background-image:url('../imgs/ico/gnb/ic_labor@3x.png');background-size: 48px 44px;}            // HIS 법무노무
#sideWrap #sideGnb .module-link.HEXCP{background-image:url('../imgs/ico/gnb/ic_theporterzone@3x.png');background-size: 48px 44px;}    // HIS 더포터존    
#sideWrap #sideGnb .module-link.HCH{background-image:url('../imgs/ico/gnb/ic_industrial@2x.png');background-size: 48px 44px;}         // HIS 공단검진    
#sideWrap #sideGnb .module-link.HCK{background-image:url('../imgs/ico/gnb/ic_notice@2x.png');background-size: 48px 44px;}             // HIS 고시/공지    
// 기타    
#sideWrap #sideGnb .module-link.WK{background-image:url('../imgs/ico/gnb/ic_wk.png');background-size: 48px 44px;}                      // 업무관리    
#sideWrap #sideGnb .module-link.DC{background-image:url('../imgs/ico/gnb/ic_dc.png');background-size: 48px 44px;}                      // 문서    
#sideWrap #sideGnb .module-link.ECM{background-image:url('../imgs/ico/gnb/ic_ecm.png');background-size: 48px 44px;}                    // ECM    
#sideWrap #sideGnb .module-link.WR{background-image:url('../imgs/ico/gnb/ic_wr.png');background-size: 48px 44px;}                      // 업무보고    
#sideWrap #sideGnb .module-link.CR{background-image:url('../imgs/ico/gnb/ic_cr@2x.png');background-size: 48px 44px;}                   // 고객관계    
#sideWrap #sideGnb .module-link.TADDR{background-image:url('../imgs/ico/gnb/ic_gnb_total_address@2x.png');background-size: 48px 44px;} // 통합주소록    
#sideWrap #sideGnb .module-link.LT{background-image:url('../imgs/ico/gnb/ic_gnb_lt@2x.png');background-size: 48px 44px;}               // 로테크    
#sideWrap #sideGnb .module-link.UT{background-image:url('../imgs/ico/gnb/ic_gnb_gift@2x.png');background-size: 48px 44px;}               // 로테크    
 
    
// 고객사 전용    
#sideWrap #sideGnb .module-link.BuminEdu{background-image:url('../imgs/ico/gnb/ic_bumin_edu@2x.png');background-size: 48px 44px;}  // 부민에듀    
#sideWrap #sideGnb .module-link.DongaMla{background-image:url('../imgs/ico/gnb/ic_gnb_ml_archiving@2x.png');background-size: 48px 44px;}  // 동아일보 메일아카이빙  
  
/* 헤더영역 */  
// #headerWrap{position: absolute;top: 0;left: 0;right: 0;height: 94px;margin: 0;box-sizing: border-box; z-index: 101; min-width:1232px;}  
#headerWrap{position: fixed; top: 0; left: 48px; right: 0px; height: 94px; z-index: 12;}  
  
/* 로고영역 */  
#headerWrap #main-ci{position: relative;width: 200px;height: 50px;margin: 0;padding: 5px 20px;background-color: #fff;box-sizing: border-box;z-index: 101;}  
#headerWrap #main-ci h1{overflow: hidden; margin: 0; font-size: 1px; height:40px; line-height:40px;}  
#headerWrap #main-ci h1 img{max-width: 150px;max-height: 38px;vertical-align: middle;cursor: pointer;}  
  
/* 헤더 공통기능 */  
#headerWrap #headFnc{position: relative;width: 100%;height: 50px;background: #ffffff;z-index: 1;}  
#headerWrap #headFnc .headfnc{padding:0 20px 0 20px;height: 50px;}  
#headerWrap #headFnc .headfnc::after {content:"";display:block;clear:both;height:0;visibility:hidden;}  
  
/* 헤더 검색 */  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap{position:absolute;top:10px;right:0;z-index: 1;width:0%;transition: all 0.1s; display: none;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap.on{width:calc(100% - 45px); display: block;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar{position: relative;z-index: 1;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar .searchInput{width:0px;height: 32px;padding: 0 10px 0 10px;border-radius: 16px;background-color: #fff;box-sizing: border-box;outline: none;transition:all 0.2s;border: solid 1px #fff;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap.on .searchBar{position: relative;z-index: 1;margin-left:0px;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap.on .searchBar .searchInput {width:100%;border: solid 1px #e6e6e6;padding:0 60px 0 10px;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap.on .searchBar .searchInput:focus{border: solid 1px #1c90fb;background-color: #ffffff;box-sizing: border-box;outline: none;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap.on .searchBar .searchInput::placeholder {font-weight:normal !important;color:#a6a6a6;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar .deleteBtn{position: absolute;top:7px;right:75px;width:18px;height:18px;background: url('../imgs/ico/icon_tabon_close_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar .searchBtn{position: absolute;top:7px;right:50px;width:18px;height:18px;background: url('../imgs/ico/icon_misc_search@2x.png') no-repeat center;background-size:30px;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar .closeBtn{width:40px;font-size:12px;line-height:32px;color:#000000;text-align: center;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar .aiBtn{display:none;}
// one ai 있을때
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar.oneAi .deleteBtn{position: absolute;top:7px;right:115px;width:18px;height:18px;background: url('../imgs/ico/icon_tabon_close_normal@2x.png') no-repeat center;background-size:14px;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar.oneAi .searchBtn{position: absolute;top:7px;right:90px;width:18px;height:18px;background: url('../imgs/ico/icon_misc_search@2x.png') no-repeat center;background-size:30px;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar.oneAi .aiBtn{display:block;margin:-3px 0 0 0;width:40px;height:40px;background: url('../imgs/oneAi/img_oneai_40_normal@2x.png') no-repeat center;background-size:40px;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .searchBar.oneAi .aiBtn:hover{background: url('../imgs/oneAi/img_oneai_40_over@2x.png') no-repeat center;background-size:40px;}  

#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText{display: none;position:absolute;top: 35px;right: 5px;margin: 0;box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);background: #ffffff;border-radius: 15px;overflow: hidden;z-index: 10;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn{max-width: 500px;height: auto;max-height: 460px;padding: 0 0 20px 0;overflow: auto;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl{padding: 20px 20px 0px 0px;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl dt{font-size:14px;font-weight:bold;color: #000000;padding: 0 0 7px 15px;background: url('../imgs/main/ico_autotext_arrow.png') no-repeat left 10px;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl dd{padding: 0 15px;line-height: 22px;white-space: nowrap;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl dd .link{position: relative;display: inline-block;font-size:12px;color: #000000;outline:none;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl dd .link::after{content:"";position: absolute;left: 0;bottom: 0;width: 0%;height: 1px;background: #000000;transition: all 0.5s;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl dd .link:hover::after{width:100%;transition: all 0.5s;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl dd .link:focus::after{width:100%;transition: all 0.5s;}  
#headerWrap #headFnc .headfnc .head-misc .directSearchWrap .autoText .autoTextIn dl dd .match{color:#1c90fb;}  
  
/* 헤더 기능버튼 */  
#headerWrap #headFnc .headfnc .head-misc{position:relative;height: 50px;}  
#headerWrap #headFnc .headfnc .head-misc ul li{position: relative;margin: 10px 0 0 6px;min-width:30px;min-height:30px;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.btn{display: block;width: 30px;height: 30px;background: transparent no-repeat center;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.btnCnt{position: absolute;top: 0;right: 0;min-width: 14px;height: 14px;line-height: 1;padding: 1px 3px 0 3px;font-family: '돋움',Dotum,Helvetica,'Apple SD Gothic Neo',sans-serif;font-size: 11px;color: #ffffff;text-align: center;border-radius: 10px;background: #fc5356;box-sizing: border-box;cursor: pointer;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.alert{background-image: url('../imgs/ico/icon_misc_alert@2x.png');background-size: 30px;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.org{background-image: url('../imgs/ico/icon_misc_org@2x.png');background-size: 30px;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.search{background-image: url('../imgs/ico/icon_misc_search@2x.png');background-size: 30px;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.more{background-image: url('../imgs/ico/icon_misc_more@2x.png');background-size: 30px;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.manual{background-image: url('../imgs/ico/ic_misc_manual@2x.png');background-size: 30px;}  
#headerWrap #headFnc .headfnc .head-misc ul li span.notice{background-image: url('../imgs/ico/ic_misc_notice@2x.png');background-size: 30px;}  
  
#headerWrap #headFnc .headfnc .user-info{float: left;position: relative;cursor: pointer;padding:0 14px 0 0;}  
#headerWrap #headFnc .headfnc .user-info.on:hover{background-color: transparent !important;}  
#headerWrap #headFnc .headfnc .user-info::after {content:"";display:block;clear:both;height:0;visibility:hidden;}  
#headerWrap #headFnc .headfnc .user-info::before {content:"";position:absolute;top:8px;right:0;width:1px;height:16px;background-color: #e6e6e6;}  
#headerWrap #headFnc .headfnc .user-info.on::before {display: none;}  
#headerWrap #headFnc .headfnc .user-info .divi_pic{float:left;border-radius: 50%;width: 32px;height: 32px;color: #ffffff;background: transparent no-repeat center;cursor: pointer;overflow: hidden;}  
#headerWrap #headFnc .headfnc .user-info .divi_pic img{width: 100%;min-height:32px;vertical-align: top;}  
#headerWrap #headFnc .headfnc .user-info .name_txt{position: relative;display: inline-block;margin: 1px 0 0 10px;line-height: 14px;font-size: 11px;color: #000000;letter-spacing: -0.5px;transition: all 0.2s; min-width:52px;box-sizing:border-box;}  
#headerWrap #headFnc .headfnc .user-info .divi_txt{position: relative;display: inline-block;padding-right:18px;margin: 0 0 0 10px;line-height: 14px;font-size: 10px;color: #000000;background: url('../imgs/ico/ic_arrow_down_01_s_normal.png') no-repeat right center;letter-spacing: -0.5px;transition: all 0.2s; min-width:52px;box-sizing:border-box;}  

// 자동 로그아웃 타이머
#headerWrap #headFnc .headfnc .logoutTimer{position: relative;align-items:center;
   &::after{content:'';margin:1px 5px 0 10px;width:1px;height:16px;background-color:#e6e6e6;}
   .clockIcon{margin:0 5px 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_time_s_normal.png') no-repeat center;}
   .timeText{margin:0 5px 0 0;font-size:12px;line-height:18px;color:#000000;}
}
  
/* 컨텐츠 탭 */  
#headerWrap #headTabs{position: relative;width:100%;height: 50px;overflow:hidden;}  
#headerWrap #headTabs .homePortal{position: absolute;top: 0;left: 0;width: 52px;height: 44px;background: url('../imgs/ico/ico_home_portal.png') no-repeat center;cursor: pointer;border-right:1px solid rgba(7, 102, 190, 0.41);box-sizing: border-box;background-color: #1c90fb;}  
#headerWrap #headTabs .homePortal:hover{background-color: #067add;transition:all 0.3s;}  
  
#headerWrap #headTabs .tabs{margin:10px 30px 0 0;font-size: 0;white-space: nowrap;overflow:hidden;}  
#headerWrap #headTabs .tabs::after {content:"";display:block;clear:both;height:0;visibility:hidden;}  
#headerWrap #headTabs .tabs .tab-item{position: relative;display: inline-block;width: 138px;height: 32px;padding: 0 23px 0 0px;border-style: solid;border-radius: 3px;border: solid 1px #e6e6e6;box-sizing: border-box;vertical-align: top;margin-right:2px;}  
#headerWrap #headTabs .tabs .tab-item .react-contextmenu-wrapper {padding-left: 30px;}  
#headerWrap #headTabs .tabs .tab-item:hover{border: solid 1px #9f9f9f;background-color:#eff7ff;transition:all 0.3s;}  
#headerWrap #headTabs .tabs .tab-item.rightOn{border: solid 1px #8c8c8c;transition:all 0.3s;}  
#headerWrap #headTabs .tabs .tab-item .new-window{position: absolute;top:0;left: 8px;width: 18px;height: 30px;background: url('../imgs/ico/icon_tabon_new_normal@2x.png') no-repeat center;background-size: 11px;cursor: pointer;}  
#headerWrap #headTabs .tabs .tab-item .module-name{display: block;font-size: 12px;color: #8c8c8c;line-height:30px;letter-spacing: -0.5px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;}  
#headerWrap #headTabs .tabs .tab-item .item-close{position: absolute;top:0;right: 8px;width: 18px;height: 30px;background: url('../imgs/ico/icon_tabon_close_normal@2x.png') no-repeat center;background-size: 11px;cursor: pointer;}  
#headerWrap #headTabs .tabs .tab-item.on .module-name {color:#1c90fb;}  
#headerWrap #headTabs .tabs .tab-item:active {background-color: #eff7ff;}  
#headerWrap #headTabs .tabs .tab-item.on{ border: solid 1px #0e7add;background-color:#eff7ff;box-sizing: border-box;}  
#headerWrap #headTabs .tabs .tab-item.on .new-window{background: url('../imgs/ico/icon_tabon_new_select@2x.png') no-repeat center;background-size: 11px;}  
#headerWrap #headTabs .tabs .tab-item.on .item-close{background: url('../imgs/ico/icon_tabon_close_select@2x.png') no-repeat center;background-size: 11px;}  
#headerWrap #headTabs .tab-more-out {position: absolute;top: 10px;right: 0;bottom: 0;width: 26px;height: 32px;box-sizing: border-box;background:#fff}  
#headerWrap #headTabs .tab-more{width: 26px;height: 32px;box-sizing: border-box;background:#fff url('../imgs/ico/dot_more_over@2x.png') no-repeat center;background-size:12px 4px;cursor: pointer;border: solid 1px #e6e6e6;  border-radius: 3px;}  
#headerWrap #headTabs .tab-more:hover{border: solid 1px #8c8c8c;background-color:#eff7ff;transition:all 0.3s;}  
  
#headerWrap #headerTitle {height: 44px; width: 100%; background: #1c90fb; position: absolute; top: 50px;overflow: hidden;}  
#headerWrap #headerTitle .tit {font-size:18px; line-height: 44px;color:#fff;float: left;padding-left: 10px;}  
#headerWrap #headerTitle .menuLnb {width:44px; height:44px;float: left;background:rgba(0,0,0,0.2) url('../imgs/ico/icon_menu.png') no-repeat center center;cursor:pointer;}  
#headerWrap #headerTitle .menuLnb:hover {background:rgba(0,0,0,0.4) url('../imgs/ico/icon_menu.png') no-repeat center center;}  
#headerWrap #headerTitle .menuLnb.folding {background:rgba(0,0,0,0.2) url('../imgs/ico/icon_menu_open.png') no-repeat center center;}  
#headerWrap #headerTitle .menuLnb.folding:hover {background:rgba(0,0,0,0.4) url('../imgs/ico/icon_menu_open.png') no-repeat center center;}
#headerWrap #headerTitle .onlineBtn{display: flex; align-items: center; border-radius: 2px; box-sizing: border-box; border: solid 1px #b4dbff; background: url('../imgs/ico/ic_pc_m_w_normal@2x.png') no-repeat left 8px center; background-size: 18px; cursor: pointer; color: #fff; margin:8px 20px 0 0; padding: 0 10px 0 28px; height: 27px; line-height: 27px;
   &:hover, &:active{border: solid 1px #7cc0ff; box-sizing: border-box; background:#1081e8 url('../imgs/ico/ic_pc_m_w_normal@2x.png') no-repeat left 8px center; background-size: 18px;}
}  
 
/* 공통 레이아웃 팝업 */  
.commonPopup{position: absolute;box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1);border: solid 1px #a6a6a6;background-color: #ffffff;z-index:10;}  
.commonPopup::after{content: "";position: absolute;top:-5px;right:10px;width: 7px;height: 7px;border: solid 1px #a6a6a6;border-width: 1px 0px 0px 1px;background: #ffffff;transform: rotate(45deg);z-index: 15;}  
  
// 프로필정보 팝업  
.newPortalHeader .userInfoPop{position:absolute;top:40px;left:50%;width:550px;transform: translateX(-50%);} // 포털헤더 프로필 팝업  
.userInfoPop{position:absolute;top:40px;left:50%;width:550px;transform: translateX(-50%);cursor: initial;}  
.userInfoPop::after{right: calc(50% - 34px);}  
.userInfoPop .infoTop{position: relative;margin: 0 16px;padding: 40px 0 20px 0;border-bottom: 1px solid #e6e6e6;}  
.userInfoPop .infoTop .userInfoBtn-setting{position: absolute;top:13px;right:21px;width:18px;height:18px;background: url('../imgs/ico/ic_settine_01_m_normal@2x.png') no-repeat center center; background-size: 18px; cursor: pointer;
   &:hover{background: url('../imgs/ico/ic_settine_01_m_over@2x.png') no-repeat center center;background-size: 18px;}
}  
.userInfoPop .infoTop .userInfoBtn-logout{position: absolute;top:12px;right:-4px;width:18px;height:18px;background: url('../imgs/ico/ic_onoff_m_normal@2x.png') no-repeat center center; background-size: 18px; cursor: pointer;
   &:hover{background: url('../imgs/ico/ic_onoff_m_over@2x.png') no-repeat center center;background-size: 18px;}
}
// 자동 로그아웃 타이머
.userInfoPop .infoTop .logoutTimerPopIn{position: absolute;top:11px;right:42px;align-items:center;
   &::after{content:'';margin:1px 5px 0 10px;width:1px;height:16px;background-color:#e6e6e6;}
   .clockIcon{margin:0 5px 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_time_s_normal.png') no-repeat center;}
   .timeText{margin:0 5px 0 0;font-size:12px;line-height:18px;color:#000000;}
}  
.userInfoPop .infoTop .user_pic{width: 60px;height: 60px;margin: 0 20px 0 0;border-radius: 60px;box-sizing: border-box;overflow: hidden;}  
.userInfoPop .infoTop .user_pic img{width:100%;}  
.userInfoPop .infoTop .user_name{font-size:14px;font-weight:bold;line-height:20px;text-align: left;margin:2px 0 2px 0;}  
.userInfoPop .infoTop .user_name .cls{font-size:14px;font-weight:bold;line-height:20px;}  
.userInfoPop .infoTop .user_org{font-size:11px;line-height:15px;margin:0 0 2px 0;text-align: left;color:#4a4a4a;}  
.userInfoPop .infoTop .user_ip{font-size:11px;line-height:15px;text-align: left;color:#a6a6a6;}  
.userInfoPop .infoBot{margin:0 16px;height:210px;}  
.userInfoPop .infoBtnBox{margin:22px 0 0 0;height:62px;background-color:#f7f7f7;justify-content: center;align-items: center;}  
  
// ERP10 프로필정보 팝업   
.userInfoErp10Pop{position:absolute;top:40px;right:30%;width:290px;border-radius:12px;box-shadow:0 8px 20px 0 rgba(0,0,0,16%);cursor: initial;   
   &::before{content: '';display: block;position: absolute;right: 20px;top: -7px;width: 13px;height: 8px;background:transparent url('../imgs/bg/box_tail_blue@2x.png') no-repeat center;background-size:contain;}   
   .user-info-box{border-top-left-radius: 12px;border-top-right-radius: 12px;background-image: url('../imgs/bg/bg_top_profile@2x.png');background-position: left top;background-size: 100% 147px;background-repeat: no-repeat;   
      .user-info-detail{display: flex;justify-content: space-between;align-items: flex-start;margin: 0 20px;padding: 24px 0 13px;border-bottom: 1px solid rgba(255,255,255,0.1);   
         .user-info-text{flex: 0 0 auto;width: 190px;margin-right: 12px;   
            .user-name{width: 100%;margin-bottom: 0;padding: 0;color: #ffffff;font-size: 17px;line-height: 23px;text-align: left;letter-spacing: -0.43px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;   
               .name{font-weight:bold;}   
               .grade{margin:0 0 0 5px;}   
            }   
            .user-group{width: 100%;margin-bottom: 7px;color: #ffffff;opacity: 0.8;font-size: 11px;line-height: 23px;text-align: left;letter-spacing: -0.28px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;   
               .company{padding-right:6px;}   
               .group{position: relative;padding-left: 7px;   
                  &::before{content: '';position: absolute;top: 50%;left: 0;display: block;width: 1px;height: 10px;margin-top: -5px;opacity: 0.5;background-color: #ffffff;}   
               }   
            }   
         }   
      }  
      .user-buttons{display: flex;justify-content: space-between;align-items: center;flex-flow: nowrap;margin-bottom: 0;padding: 5px 12px 8px;  
         .userBtn{padding:0 8px 0 34px;height:25px;line-height:24px;font-size:12px;letter-spacing:-0.3px;color:#ffffff;border-radius:20px;cursor: pointer;  
            &.mySet{background:transparent url('../imgs/ico/ic_myinfo_setting@2x.png') no-repeat left 8px center;background-size:24px;  
               &:hover{background:rgba(0, 0, 0, 0.16) url('../imgs/ico/ic_myinfo_setting@2x.png') no-repeat left 8px center;background-size:24px;}  
            }  
            &.logout{background:transparent url('../imgs/ico/ic_logout@2x.png') no-repeat left 8px center;background-size:24px;  
               &:hover{background:rgba(0, 0, 0, 0.16) url('../imgs/ico/ic_logout@2x.png') no-repeat left 8px center;background-size:24px;}  
            }  
         }  
      }   
      .user-pic{flex: 0 0 auto;width: 48px;height: 48px;margin: 0;   
         img{width: 48px;height: 48px;box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16); border-radius: 50%;}   
      }   
   }   
   .user-info-change{padding: 10px 20px 14px;background-color: #ffffff;border-bottom-left-radius: 12px;border-bottom-right-radius: 12px;   
      .user-status-change{height: 47px;padding:9px 7px 0 34px;border-top: none;border-bottom: 1px solid #f3f3f3;box-sizing: border-box;   
         &.change-company{background:url('../imgs/ico/ic_comp_02_l_normal@2x.png') no-repeat top 11px left;background-size:24px;}   
         &.change-authority{background:url('../imgs/ico/ic_department_l_normal@2x.png') no-repeat top 11px left;background-size:24px;}   
         &.change-group{background:url('../imgs/ico/ic_authority_l_normal@2x.png') no-repeat top 11px left;background-size:24px;}   
         &.change-language{background:url('../imgs/ico/ic_web_l_normal@2x.png') no-repeat top 11px left;background-size:24px;}   
         &.change-timezone{background:url('../imgs/ico/ic_time_l_normal@2x.png') no-repeat top 11px left;background-size:24px;}   
         [class*="OBTDropDownList_default"]{   
            div div>span:first-child{border:1px solid transparent !important; }   
            &:hover div div>span:first-child{border:1px solid rgb(219, 219, 219) !important; }   
            &.on div div>span:first-child{border:1px solid rgb(88, 173, 252) !important; }   
         }    
      }   
      .user-bottom-buttons{padding: 10px 0 0;   
         .apply{width: 100%;height: 36px;line-height: 36px;color: #ffffff;box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);border: 1px solid transparent;border-radius: 6px;-webkit-tap-highlight-color: transparent;text-align: center;background-color: #1c90fb;font-size: 13px;letter-spacing: -0.33px;cursor: pointer;   
            &:hover{background-color:#036ddd;}   
            &.disabled:hover{color: rgba(0,0,0,0.4);box-shadow: none;border-color: #ebebeb;background-color: #ffffff;cursor:default;}   
            &.disabled{color: rgba(0,0,0,0.4);box-shadow: none;border-color: #ebebeb;background-color: #ffffff;cursor:default;}   
         }   
      }   
   }   
}  
  
// 회사선택 팝업  
.companySelectWrap{width: 100%;height:100%;box-sizing: border-box;}  
.companySelectWrap .joinText{display:inline-block;padding:0 0 0 20px;font-size:12px;line-height:20px;color:#20c997;background: url('../imgs/ico/icon_confirm_posi.png') no-repeat left 0 center;}  
.companySelectWrap .pubFormStyle.header table{border-bottom:none;}  
.companySelectWrap .pubFormStyle.body table{border-top:none;}  
  
// 통합알림 팝업    
.newPortalHeader{
   .integratedNotification{top:40px;} //  (이전소스)   
   .newIntegratedNotification{top:40px;} //  (신규소스) 
} 
   
// 이전소스(고도화 개발 후 제거)  
.integratedNotification{top:40px;right:calc(100% - 50px);width:380px;height:620px;  
   &::after{right: 30px;}    
   &.alert::after{right: 30px;background:#f7f7f7;}    
   .tab{background:#f7f7f7;  
      &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}    
      li{float:left;width:50%;margin:0 !important;padding:0;text-align:center;cursor:pointer;  
         &:first-child{border-radius: 0 10px 0 0;}    
         &:last-child{border-radius: 10px 0 0 0;}    
         span{position:relative;display: inline-block;font-size:14px;line-height:46px;color:#a6a6a6;vertical-align: middle;  
            &.tab01 .ico{width:20px;height:20px;margin:0 2px 0 0;background:url('../imgs/ico/ic_noti_off.png') no-repeat left 0 center;}    
            &.tab02 .ico{width:20px;height:20px;margin:0 2px 0 0;background:url('../imgs/ico/ic_mention_off.png') no-repeat left 0 center;}    
            .txt{padding: 0;line-height: 20px;}    
         }    
         &.on{background-color: #ffffff;  
            span{  
               .txt{font-size:14px;font-weight:bold;line-height:46px;color:#000000;}    
               &.tab01 .ico{background:url('../imgs/ico/ic_noti_on.png') no-repeat left 0 center;}    
               &.tab02 .ico{background:url('../imgs/ico/ic_mention_on.png') no-repeat left 0 center;}    
            }   
         }   
         .cnt{margin:0 0 0 10px;padding: 0 5px;width: auto;height: 14px;font-size: 10px;line-height: 13px;color: #ffffff;text-align: right;border-radius: 14px;background-color: #fc5356;}  
      }    
   }    
   .tabCon{  
      .tabFn{width: 100%;text-align: right;line-height: 21px;padding: 8px 20px;box-sizing: border-box;  
         & + div > div{z-index:1;}    
         .allRead{margin:0 0 0 5px;}
         .line{margin:0 10px;width:1px;height:16px;background-color:#e6e6e6;}
         .settingBtn{width:18px;height:27px;background:url('../imgs/ico/ic_settine_01_m_normal.png') no-repeat center;cursor: pointer;    
            &:hover{background:url('../imgs/ico/ic_settine_01_m_over.png') no-repeat center;}    
         }
         .filterWrap{
            .filterList01{top: 20px;right: -35px;min-width: 72px;
               ul{margin:0 !important;
                  &::before{display:none;}
                  li{margin:0 !important;padding:0 0 0 10px !important;
                     span{padding:0;text-align:left;line-height:30px;}
                  }
               }
            }
         }    
      }    
      .dayline{position:sticky;top:-1px;font-size:12px;font-weight:bold;line-height:30px;color: #222222;margin: 0;padding: 0 0 0 24px;overflow: hidden;background: #f2f6f8;z-index:1;  
         .today{display: inline-block;margin: 0 0 0 10px;width: 37px;height: 18px;font-size: 11px;font-weight: bold;line-height: 18px;text-align: center;color: #ffffff;border-radius: 10px;background-color: #1c90fb;box-sizing: border-box;}
      }    
      ul{position: relative;margin:10px 0;z-index:0;  
         &:before{content: '';position: absolute;top: 20px;left: 33px;bottom: 20px;width: 1px;background-color: rgba(0, 0, 0, 0.1);}    
         li{position: relative;float:unset !important;width:100%;margin:0 !important;padding:10px 10px 10px 20px;box-sizing:border-box;cursor: pointer;  
            &:hover{background: #eef7ff;transition: 0.2s;}    
            .list_con{overflow: hidden;color:#a6a6a6;  
               .topline{margin-right:15px;  
                  dl{font-size:12px;line-height:18px;white-space: nowrap;  
                     dt{font-size:12px;line-height:18px;}    
                     dd{font-size:12px;line-height:18px;text-overflow: ellipsis;overflow: hidden;  
                        &.name{position: relative;padding:0 0 0 5px;}    
                     }    
                  }    
               }    
               .botline{margin:4px 15px 0 0;  
                  .text{font-size:11px;line-height:18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;  
                     .mentionGreen{color:#09b481;}    
                     .mentionBlue{color:#1c90fb;}    
                  }    
               }    
            }    
            .time{font-size:11px;line-height:18px;color:#a6a6a6;padding:0 20px 0 0;vertical-align: top;  
               &.fold{background:url('../imgs/ico/ic_arrow_down_01_s_normal.png') no-repeat top 2px right;}    
               &.unfold{background:url('../imgs/ico/ic_arrow_up_01_s_normal.png') no-repeat top 2px right;}    
            }    
            .icon{width: 28px;height: 28px;margin: 4px 9px 0 0;border-radius: 100px;overflow: hidden;  
               &.profile{background:url('../imgs/main/ic_notice_profile.png') no-repeat center;  
                  img{width:100%;}    
               }    
               &.of{background:url('../imgs/main/ic_notice_oneffice_read.png') no-repeat center;}      // 원피스    
               &.sc{background:url('../imgs/main/ic_notice_schedule_read.png') no-repeat center;}      // 일정    
               &.an{background:url('../imgs/main/ic_notice_announcement_read.png') no-repeat center;}  // 공지    
               &.ea{background:url('../imgs/main/ic_notice_approval_read.png') no-repeat center;}      // 결재    
               &.ma{background:url('../imgs/main/ic_notice_mail_read.png') no-repeat center;}          // 메일    
               &.mg{background:url('../imgs/main/ic_notice_message_read.png') no-repeat center;}       // 쪽지    
               &.fx{background:url('../imgs/main/ic_notice_fax_read.png') no-repeat center;}           // 팩스    
               &.ks{background:url('../imgs/main/ic_notice_kiss_read.png') no-repeat center;}          // kiss    
               &.bd{background:url('../imgs/main/ic_notice_board_read.png') no-repeat center;}         // 게시판    
               &.dc{background:url('../imgs/main/ic_notice_doc_read.png') no-repeat center;}           // 문서    
               &.wr{background:url('../imgs/main/ic_notice_projectreport_read.png') no-repeat center;} // 업무보고    
               &.nt{background:url('../imgs/main/ic_notice_note_read.png') no-repeat center;}          // 노트    
               &.fl{background:url('../imgs/main/ic_notice_file_read.png') no-repeat center;}          // 첨부파일    
               &.hr{background:url('../imgs/main/ic_notice_hr_read.png') no-repeat center;}            // 인사    
               &.ac{background:url('../imgs/main/ic_notice_accounting_read.png') no-repeat center;}    // 회계    
               &.rs{background:url('../imgs/main/ic_notice_resource_read.png') no-repeat center;}      // 자원    
               &.oc{background:url('../imgs/main/ic_notice_oc_read.png') no-repeat center;}            // 원챔버    
               &.st{background:url('../imgs/main/ic_notice_sign_read.png') no-repeat center;}          // 사인    
               &.pw{background:url('../imgs/main/ic-notice-pw-reset-read.png') no-repeat center;}      // 비밀번호    
               &.crm{background:url('../imgs/main/ic-notice-crm-read.png') no-repeat center;}          // CRM    
               &.gsm{background:url('../imgs/main/ic_notice_management_read.png') no-repeat center;}   // 총무관리    
               &.vote{background:url('../imgs/main/ic_notice_vote_read.png') no-repeat center;}        // 투표    
               &.clinic{background:url('../imgs/main/ic_notice_clinic_read.png') no-repeat center;}    // 헬스케어   
               &.taddr{background:url('../imgs/main/ic_notice_total_address_read.png') no-repeat center;} // 통합주소록
               &.fundingPrevent{background:url('../imgs/main/ic_notice_funding_prevent_read.png') no-repeat center;} // 자금사고예방
               &.vc{background:url('../imgs/main/ic_notice_video_conference_read.png') no-repeat center;} // 화상회의
               &.set{background:url('../imgs/main/ic_notice_setting_read.png') no-repeat center;}      // 시스템설정
            }    
            &.unread{  
               .icon{  
                  &.of{background:url('../imgs/main/ic_notice_oneffice.png') no-repeat center;}      // 원피스    
                  &.sc{background:url('../imgs/main/ic_notice_schedule.png') no-repeat center;}      // 일정    
                  &.an{background:url('../imgs/main/ic_notice_announcement.png') no-repeat center;}  // 공지    
                  &.ea{background:url('../imgs/main/ic_notice_approval.png') no-repeat center;}      // 결재    
                  &.ma{background:url('../imgs/main/ic_notice_mail.png') no-repeat center;}          // 메일    
                  &.mg{background:url('../imgs/main/ic_notice_message.png') no-repeat center;}       // 쪽지    
                  &.fx{background:url('../imgs/main/ic_notice_fax.png') no-repeat center;}           // 팩스    
                  &.ks{background:url('../imgs/main/ic_notice_kiss.png') no-repeat center;}          // kiss    
                  &.bd{background:url('../imgs/main/ic_notice_board.png') no-repeat center;}         // 게시판    
                  &.dc{background:url('../imgs/main/ic_notice_doc.png') no-repeat center;}           // 문서    
                  &.wr{background:url('../imgs/main/ic_notice_projectreport.png') no-repeat center;} // 업무보고    
                  &.nt{background:url('../imgs/main/ic_notice_note.png') no-repeat center;}          // 노트    
                  &.fl{background:url('../imgs/main/ic_notice_file.png') no-repeat center;}          // 첨부파일    
                  &.hr{background:url('../imgs/main/ic_notice_hr.png') no-repeat center;}            // 인사    
                  &.ac{background:url('../imgs/main/ic_notice_accounting.png') no-repeat center;}    // 회계    
                  &.rs{background:url('../imgs/main/ic_notice_resource.png') no-repeat center;}      // 자원    
                  &.oc{background:url('../imgs/main/ic_notice_oc.png') no-repeat center;}            // 원챔버    
                  &.st{background:url('../imgs/main/ic_notice_sign.png') no-repeat center;}          // 사인    
                  &.pw{background:url('../imgs/main/ic-notice-pw-reset.png') no-repeat center;}      // 비밀번호    
                  &.crm{background:url('../imgs/main/ic-notice-crm.png') no-repeat center;}          // CRM    
                  &.gsm{background:url('../imgs/main/ic_notice_management.png') no-repeat center;}   // 총무관리    
                  &.vote{background:url('../imgs/main/ic_notice_vote.png') no-repeat center;}        // 투표    
                  &.clinic{background:url('../imgs/main/ic_notice_clinic.png') no-repeat center;}    // 헬스케어  
                  &.taddr{background:url('../imgs/main/ic_notice_total_address.png') no-repeat center;}    // 통합주소록  
                  &.fundingPrevent{background:url('../imgs/main/ic_notice_funding_prevent.png') no-repeat center;} // 자금사고예방
                  &.vc{background:url('../imgs/main/ic_notice_video_conference.png') no-repeat center;} // 화상회의
                  &.set{background:url('../imgs/main/ic_notice_setting.png') no-repeat center;} // 시스템설정
               }  
               .list_con{overflow: hidden;color:#222222;}    
            }  
         }    
      }    
   }   
   .categoryFn {padding:0 20px;height:44px;border-top:1px solid #e6e6e6;border-bottom:1px solid #e6e6e6;background-color:#ffffff;box-sizing:border-box;    
      .itemDiv{white-space:nowrap;overflow:hidden;scroll-behavior: smooth;    
         .item{margin:10px 0 0 4px;padding:0 8px;height:24px;border:1px solid #e6e6e6;border-radius:24px;font-size:12px;line-height:22px;color:#000000;background-color:#ffffff;box-sizing:border-box;cursor: pointer;    
            &:first-child{margin:10px 0 0 0;}    
            &.on{color:#1c90fb;border:1px solid #1c90fb;background-color:#eff7ff;}    
         }    
      }    
      .arrDiv{margin:0 0 0 10px;justify-content: center;align-items: center;    
         .prevBtn{margin:0 2px 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_arrow_left_01_s_normal.png') no-repeat center;background-size: cover;cursor: pointer;    
            &:hover{background:url('../imgs/ico/ic_arrow_left_01_s_over.png') no-repeat center;background-size: cover;}    
            &.disabled{background:url('../imgs/ico/ic_arrow_left_01_s_disable.png') no-repeat center;background-size: cover;cursor: not-allowed;}    
         }    
         .nextBtn{margin:0 0 0 2px;width:14px;height:14px;background:url('../imgs/ico/ic_arrow_right_01_s_normal.png') no-repeat center;background-size: cover;cursor: pointer;    
            &:hover{background:url('../imgs/ico/ic_arrow_right_01_s_over.png') no-repeat center;background-size: cover;}    
            &.disabled{background:url('../imgs/ico/ic_arrow_right_01_s_disable.png') no-repeat center;background-size: cover;cursor: not-allowed;}    
         }    
      }    
   }    
}  
// 신규소스 (고도화)  
.newIntegratedNotification{top:40px;right:calc(100% - 80px);width:320px;height:655px; 
   &::after{right: 60px;}    
   &.alert::after{right: 30px;background:#f7f7f7;}    
   .tab{width:100%;height:70px;border-bottom:1px solid #e6e6e6; 
      li{width: 100%;margin: 0 24px !important;padding: 0;text-align: center;box-sizing: border-box;cursor: pointer; 
         .tab01 {position:relative;background:url('../imgs/ico/ic_noti_off@2x.png') no-repeat top 13px center;background-size:20px;}    
         .tab02 {position:relative;background:url('../imgs/ico/ic_mention_off@2x.png') no-repeat top 13px center;background-size:20px;}    
         .tab03 {position:relative;background:url('../imgs/ico/ic_dday_off@2x.png') no-repeat top 13px center;background-size:20px;}    
         .txt{padding: 38px 0 0 0;font-size: 13px;line-height: 20px;letter-spacing: -0.2px;color: #a6a6a6;} 
         .cnt{position: absolute;top: 12px;left: 30px;margin: 0;padding: 0 5px;width: auto;height: 14px;font-size: 10px;line-height: 13px;color: #ffffff;text-align: right;border-radius: 14px;background-color: #fc5356;} 
         &.on{border-bottom:2px solid #1c90fb; 
            .tab01 {position:relative;background:url('../imgs/ico/ic_noti_on@2x.png') no-repeat top 13px center;background-size:20px;}    
            .tab02 {position:relative;background:url('../imgs/ico/ic_mention_on@2x.png') no-repeat top 13px center;background-size:20px;}    
            .tab03 {position:relative;background:url('../imgs/ico/ic_dday_on@2x.png') no-repeat top 13px center;background-size:20px;}    
            .txt{padding: 38px 0 0 0;font-size: 13px;font-weight:bold;line-height: 20px;letter-spacing: -0.2px;color: #1c90fb;} 
         }    
      }    
   }    
   .tabCon{background-color: #f2f6fa;  
      .tabFn{position:relative;width: 100%;text-align: right;line-height: 21px;padding: 10px 20px 11px 20px;box-sizing: border-box;border-bottom:1px solid #e6e6e6;background-color:#ffffff;  
         & + div > div{z-index:1;}    
         .allRead{margin:0 0 0 5px;}
         .line{margin:0 10px;width:1px;height:16px;background-color:#e6e6e6;}
         .settingBtn{width:18px;height:27px;background:url('../imgs/ico/ic_settine_01_m_normal.png') no-repeat center;cursor: pointer;    
            &:hover{background:url('../imgs/ico/ic_settine_01_m_over.png') no-repeat center;}    
         }
         .filterWrap{
            .filterList01{top: 20px;right: -35px;min-width: 72px;
               ul li{margin:0 !important;padding:0 0 0 10px !important;
                  span{padding:0;text-align:left;line-height:30px;}
               }
            }
         }    
      }    
      .dayline{position:sticky;top:0;font-size:12px;font-weight:bold;line-height:30px;color: #222222;margin: 0;padding: 0 0 0 24px;overflow: hidden;background: #ffffff;z-index:1;  
         .today{display: inline-block;margin: 6px 0 0 10px;width: 37px;height: 18px;font-size:11px;font-weight:bold;line-height:17px;text-align: center;color: #ffffff;background-color: #319df6;border-radius: 10px;box-sizing: border-box;vertical-align: top;} 
      }    
      .notiUl{position: relative;margin:10px 0;z-index:0;  
         &:before{content: '';position: absolute;top: 20px;left: 33px;bottom: 20px;width: 1px;background-color: rgba(0, 0, 0, 0.1);}    
         li{position: relative;float:unset !important;width:100%;margin:0 !important;padding:10px 10px 10px 20px;box-sizing:border-box;cursor: pointer;  
            &:hover{background: rgba(228, 242, 255, 0.5);transition: 0.2s;}    
            .list_con{overflow: hidden;color:#a6a6a6;  
               .topline{margin-right:15px;  
                  dl{font-size:12px;line-height:18px;white-space: nowrap;  
                     dt{font-size:12px;line-height:18px;}    
                     dd{font-size:12px;line-height:18px;text-overflow: ellipsis;overflow: hidden;  
                        &.name{position: relative;padding:0 0 0 5px;}    
                     }    
                  }    
               }    
               .botline{margin:4px 15px 0 0;  
                  .text{font-size:11px;line-height:18px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;  
                     .mentionGreen{color:#09b481;}    
                     .mentionBlue{color:#1c90fb;}    
                  }    
               }    
            }    
            .time{font-size:11px;line-height:18px;color:#a6a6a6;padding:0 20px 0 0;vertical-align: top;  
               &.fold{background:url('../imgs/ico/ic_arrow_down_01_s_normal.png') no-repeat top 2px right;}    
               &.unfold{background:url('../imgs/ico/ic_arrow_up_01_s_normal.png') no-repeat top 2px right;}    
            }    
            .icon{width: 28px;height: 28px;margin: 4px 9px 0 0;border-radius: 100px;overflow: hidden;  
               &.profile{background:url('../imgs/main/ic_notice_profile.png') no-repeat center;  
                  img{width:100%;}    
               }    
               &.of{background:url('../imgs/main/ic_notice_oneffice_read.png') no-repeat center;}      // 원피스    
               &.sc{background:url('../imgs/main/ic_notice_schedule_read.png') no-repeat center;}      // 일정    
               &.an{background:url('../imgs/main/ic_notice_announcement_read.png') no-repeat center;}  // 공지    
               &.ea{background:url('../imgs/main/ic_notice_approval_read.png') no-repeat center;}      // 결재    
               &.ma{background:url('../imgs/main/ic_notice_mail_read.png') no-repeat center;}          // 메일    
               &.mg{background:url('../imgs/main/ic_notice_message_read.png') no-repeat center;}       // 쪽지    
               &.fx{background:url('../imgs/main/ic_notice_fax_read.png') no-repeat center;}           // 팩스    
               &.ks{background:url('../imgs/main/ic_notice_kiss_read.png') no-repeat center;}          // kiss    
               &.bd{background:url('../imgs/main/ic_notice_board_read.png') no-repeat center;}         // 게시판    
               &.dc{background:url('../imgs/main/ic_notice_doc_read.png') no-repeat center;}           // 문서    
               &.wr{background:url('../imgs/main/ic_notice_projectreport_read.png') no-repeat center;} // 업무보고    
               &.nt{background:url('../imgs/main/ic_notice_note_read.png') no-repeat center;}          // 노트    
               &.fl{background:url('../imgs/main/ic_notice_file_read.png') no-repeat center;}          // 첨부파일    
               &.hr{background:url('../imgs/main/ic_notice_hr_read.png') no-repeat center;}            // 인사    
               &.ac{background:url('../imgs/main/ic_notice_accounting_read.png') no-repeat center;}    // 회계    
               &.rs{background:url('../imgs/main/ic_notice_resource_read.png') no-repeat center;}      // 자원    
               &.oc{background:url('../imgs/main/ic_notice_oc_read.png') no-repeat center;}            // 원챔버    
               &.st{background:url('../imgs/main/ic_notice_sign_read.png') no-repeat center;}          // 사인 
               &.pw{background:url('../imgs/main/ic-notice-pw-reset-read.png') no-repeat center;}      // 비밀번호    
               &.crm{background:url('../imgs/main/ic-notice-crm-read.png') no-repeat center;}          // CRM    
               &.gsm{background:url('../imgs/main/ic_notice_management_read.png') no-repeat center;}   // 총무관리    
               &.vote{background:url('../imgs/main/ic_notice_vote_read.png') no-repeat center;}        // 투표    
               &.clinic{background:url('../imgs/main/ic_notice_clinic_read.png') no-repeat center;}    // 헬스케어   
               &.taddr{background:url('../imgs/main/ic_notice_total_address_read.png') no-repeat center;} // 통합주소록(연락처)   
               &.fundingPrevent{background:url('../imgs/main/ic_notice_funding_prevent_read.png') no-repeat center;}  // 자금사고예방   
               &.vc{background:url('../imgs/main/ic_notice_video_conference_read.png') no-repeat center;} // 화상회의 
            }    
            &.unread{  
               .icon{  
                  &.of{background:url('../imgs/main/ic_notice_oneffice.png') no-repeat center;}      // 원피스    
                  &.sc{background:url('../imgs/main/ic_notice_schedule.png') no-repeat center;}      // 일정    
                  &.an{background:url('../imgs/main/ic_notice_announcement.png') no-repeat center;}  // 공지    
                  &.ea{background:url('../imgs/main/ic_notice_approval.png') no-repeat center;}      // 결재    
                  &.ma{background:url('../imgs/main/ic_notice_mail.png') no-repeat center;}          // 메일    
                  &.mg{background:url('../imgs/main/ic_notice_message.png') no-repeat center;}       // 쪽지    
                  &.fx{background:url('../imgs/main/ic_notice_fax.png') no-repeat center;}           // 팩스    
                  &.ks{background:url('../imgs/main/ic_notice_kiss.png') no-repeat center;}          // kiss    
                  &.bd{background:url('../imgs/main/ic_notice_board.png') no-repeat center;}         // 게시판    
                  &.dc{background:url('../imgs/main/ic_notice_doc.png') no-repeat center;}           // 문서    
                  &.wr{background:url('../imgs/main/ic_notice_projectreport.png') no-repeat center;} // 업무보고    
                  &.nt{background:url('../imgs/main/ic_notice_note.png') no-repeat center;}          // 노트    
                  &.fl{background:url('../imgs/main/ic_notice_file.png') no-repeat center;}          // 첨부파일    
                  &.hr{background:url('../imgs/main/ic_notice_hr.png') no-repeat center;}            // 인사    
                  &.ac{background:url('../imgs/main/ic_notice_accounting.png') no-repeat center;}    // 회계    
                  &.rs{background:url('../imgs/main/ic_notice_resource.png') no-repeat center;}      // 자원    
                  &.oc{background:url('../imgs/main/ic_notice_oc.png') no-repeat center;}            // 원챔버    
                  &.st{background:url('../imgs/main/ic_notice_sign.png') no-repeat center;}          // 사인    
                  &.pw{background:url('../imgs/main/ic-notice-pw-reset.png') no-repeat center;}      // 비밀번호    
                  &.crm{background:url('../imgs/main/ic-notice-crm.png') no-repeat center;}          // CRM    
                  &.gsm{background:url('../imgs/main/ic_notice_management.png') no-repeat center;}   // 총무관리    
                  &.vote{background:url('../imgs/main/ic_notice_vote.png') no-repeat center;}        // 투표    
                  &.clinic{background:url('../imgs/main/ic_notice_clinic.png') no-repeat center;}    // 헬스케어  
                  &.taddr{background:url('../imgs/main/ic_notice_total_address.png') no-repeat center;} // 통합주소록(연락처)
                  &.fundingPrevent{background:url('../imgs/main/ic_notice_funding_prevent.png') no-repeat center;}  // 자금사고예방   
                  &.vc{background:url('../imgs/main/ic_notice_video_conference.png') no-repeat center;} // 화상회의   
               }  
               .list_con{overflow: hidden;color:#222222;}    
            }  
         }    
      } 
      .dDayList{padding:2px 20px 10px 20px; 
         &::before{display:none;} 
         li{margin:8px 0 0 0 !important;} 
      }    
   }   
   .categoryFn {padding:0 20px;height:44px;border-bottom:1px solid #e6e6e6;background-color:#ffffff;box-sizing:border-box;    
      & + div > div{z-index:1;} 
      .itemDiv{white-space:nowrap;overflow:hidden;scroll-behavior: smooth;    
         .item{margin:10px 0 0 4px;padding:0 8px;height:24px;border:1px solid #e6e6e6;border-radius:24px;font-size:12px;line-height:22px;color:#000000;background-color:#ffffff;box-sizing:border-box;cursor: pointer;    
            &:first-child{margin:10px 0 0 0;}    
            &.on{color:#1c90fb;border:1px solid #1c90fb;background-color:#eff7ff;}    
         }    
      }    
      .arrDiv{margin:0 0 0 10px;justify-content: center;align-items: center;    
         .prevBtn{margin:0 2px 0 0;width:14px;height:14px;background:url('../imgs/ico/ic_arrow_left_01_s_normal.png') no-repeat center;background-size: cover;cursor: pointer;    
            &:hover{background:url('../imgs/ico/ic_arrow_left_01_s_over.png') no-repeat center;background-size: cover;}    
            &.disabled{background:url('../imgs/ico/ic_arrow_left_01_s_disable.png') no-repeat center;background-size: cover;cursor: not-allowed;}    
         }    
         .nextBtn{margin:0 0 0 2px;width:14px;height:14px;background:url('../imgs/ico/ic_arrow_right_01_s_normal.png') no-repeat center;background-size: cover;cursor: pointer;    
            &:hover{background:url('../imgs/ico/ic_arrow_right_01_s_over.png') no-repeat center;background-size: cover;}    
            &.disabled{background:url('../imgs/ico/ic_arrow_right_01_s_disable.png') no-repeat center;background-size: cover;cursor: not-allowed;}    
         }    
      }    
   } 
} 

//통합알림 상세보기 팝업
.UJAP003{
   .layer_title{padding: 8px 20px 7px !important;
      .layer_tit{font-size: 16px !important;}
   }
   .pop_con{padding-top:15px;
      .mentionGreen{color:#09b481;}  
      .mentionBlue{color:#1c90fb;}  
      .icon{width:30px; height:30px; margin: 4px 9px 0 0;border-radius: 100px;overflow: hidden;
         &.profile{background:url('../imgs/main/ic_notice_profile.png') no-repeat center;
            img{width:100%;} 
         }  
      }    
   }  
}
  
/*- 툴팁*/  
.pubTooltip{z-index:10; margin-top:3px;position:fixed;display: none;  
   .box{position: absolute; border-radius: 3px; border: solid 1px #a6a6a6; background-color: #ffffff; font-size: 12px; line-height: 12px; padding: 7px 10px 7px 12px; color:#000; box-sizing: border-box; text-align: left;white-space:nowrap;}  
   .arrow{position: relative; top:1px; width:11px; height:7px; left: 10px; background:url("../imgs/bg/bg_tooltip_arrow.png") left top 1px no-repeat; z-index: 10;}  
   &.hover {display: block;}  
}  
#headerWrap #headTabs .tabs .tab-item .module-name.toolTipOn + .module-name-clone + .pubTooltip {display: block;}  
#headerWrap .tab-item .module-name-clone {position: fixed;left:-50000px; display: block; font-size: 12px; font-weight: 200; color: #8c8c8c; line-height: 2; letter-spacing: -0.5px; padding: 3px 0;}  
  
  
.cmrDiv {z-index: 101;outline:none;}  
.cmrList:before{content:'';position: absolute;top: 13px;left: 5%;display: block;width: 18px;height: 9px;background: url('../imgs/bg/bg_popup_up_arrow.png') no-repeat center;}  
.cmrList {border:1px solid #a6a6a6;width: 150px;margin-top: 20px;margin-left: -10px;}  
.cmrList ul{ box-sizing: border-box;box-shadow: 0 2px 6px rgba(0,0,0,0.12);max-height:271px;overflow-y:auto;}  
.cmrList ul li{float:inherit !important;margin:0 !important; min-width:auto !important;min-height:auto !important;position: relative;display: block;box-sizing: border-box;width: 100%;height: 24px;padding: 0 15px 0 10px;background-color: #ffffff;cursor:pointer;}  
.cmrList ul li:hover {background: #eff7ff;}  
.cmrList span {line-height: 24px;float: left;color:#000;font-size:12px;}  
.cmrList .react-contextmenu-item {outline:none;}  
  
  
  
/*메뉴더보기*/  
.miscMore{display: none;position: absolute;top: 48px !important;right: -9px !important;z-index: 100;border: 1px solid #a6a6a6;
   &::before{content:'';position: absolute;top: -8px;right:14px;margin-left:-9px;display: block;width: 18px;height: 9px;background: url('../imgs/bg/bg_popup_up_arrow.png') no-repeat center;} 
   ul{min-width: 128px; box-sizing: border-box;box-shadow: 0 2px 6px rgba(0,0,0,0.12);max-height:271px;overflow-y:auto;
      li{float:inherit !important;margin:0 !important; min-width:auto !important;min-height:auto !important;position: relative;display: block;box-sizing: border-box;width: 100%;height: 27px;padding: 0 20px 0 11px;background-color: #ffffff;cursor:pointer;
         &:hover {color:#1c90fb;background: #eff7ff;
            span{
               &.as {background: url('../imgs/ico/ic_pc_m_over.png') no-repeat top 1px center;}  
               &.down {background: url('../imgs/ico/ic_download_m_over.png') no-repeat center;}  
               &.help {background: url('../imgs/ico/ic_help_m_over.png') no-repeat center;}  
               &.update {background: url('../imgs/ico/ic_happy_m_over.png') no-repeat center;}  
               &.online{background: url('../imgs/ico/ic_online_center_m_over.png') no-repeat top 1px center;}
               &.foex{background: url('../imgs/ico/ic_foex_m_over.png') no-repeat center;}
               &.login{background: url('../imgs/ico/ic_remote_login_m_over.png') no-repeat center;}
            }
         }  
         span{
            &.ico{width: 17px;height: 17px;float: left;margin-top: 5px;margin-right: 4px; }  
            &.as {background: url('../imgs/ico/ic_pc_m_normal.png') no-repeat top 1px center;}  
            &.down {background: url('../imgs/ico/ic_download_m_normal.png') no-repeat center;}  
            &.help {background: url('../imgs/ico/ic_help_m_normal.png') no-repeat center;}  
            &.update {background: url('../imgs/ico/ic_happy_m_normal.png') no-repeat center;}  
            &.online{background: url('../imgs/ico/ic_online_center_m_normal.png') no-repeat top 1px center;}
            &.foex {background: url('../imgs/ico/ic_foex_m_normal.png') no-repeat center;}  
            &.login {background: url('../imgs/ico/ic_remote_login_m_normal.png') no-repeat center;}  
            &.txt {height:27px;line-height: 27px;float: left;}             
         }        
      }  
   }   
}  


/* 탭 더보기 팝업 */  
.tab-more-popup{display:none;position: absolute;z-index: 100;border: 1px solid #a6a6a6;}  
.tab-more-popup:before{content:'';position: absolute;top: -8px;left: 50%;margin-left:-9px;display: block;width: 18px;height: 9px;background: url('../imgs/bg/bg_popup_up_arrow.png') no-repeat center;}  
.tab-more-popup > ul{width: 228px;box-sizing: border-box;box-shadow: 0 2px 6px rgba(0,0,0,0.12);max-height:271px;overflow-y:auto;}  
.tab-more-popup > ul .tab-item{position: relative;display: block;box-sizing: border-box;width: 100%;height: 27px;padding: 0 30px 0 36px;background-color: #ffffff;}  
.tab-more-popup > ul .tab-item:first-child{border-width: 0px;}  
.tab-more-popup > ul .tab-item:hover{background-color: #eff7ff;transition: all 0.3s;}  
.tab-more-popup > ul .tab-item.rightOn{background-color: #f5f5f5;transition: all 0.3s;}  
.tab-more-popup > ul .tab-item.on{background-color: #eff7ff;}  
.tab-more-popup > ul .tab-item .new-window{position: absolute;top: 0px;left: 10px;width: 26px;height: 27px;background: url('../imgs/ico/icon_tabon_new_normal.png') no-repeat center;background-size:11px;cursor: pointer;}  
.tab-more-popup > ul .tab-item .module-name{display: block;font-size: 12px;font-weight: 300;color: #000000;line-height: 27px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;}  
.tab-more-popup > ul .tab-item .item-close{position: absolute;top: 0px;right: 10px;width: 16px;height: 27px;background: url('../imgs/ico/icon_tabon_close_normal@2x.png') no-repeat center;background-size:11px;cursor: pointer;}  
.tmp-foot {height:40px; background:#f7f7f7;text-align: center;box-sizing: border-box; padding-top:8px;}  
  

/* side lnb */  
#containerWrap{position: absolute;top: 0;left: 48px;right: 0;bottom: 0;margin: 0;/*min-width: 1232px;min-height: 840px;*/overflow: hidden;z-index: 1;overflow-x:auto;
   #sideLnb{position: absolute;top: 94px;left: 0;bottom: 0;min-width: 194px;border: solid #e5e5e5;border-width: 0 1px 0 0;box-sizing: border-box;background: #f6f8fa;transform: translateX(0%);transition: transform 0.5s;/* z-index: 3; */ z-index:11;
      [class*="OBTPageContainer_root"]>[class*="OBTPageContainer_section"]>[class*="OBTPageContainer_contentsWrapper"]{z-index:auto !important;}
      &.onLnb {z-index:12;}  
      &.folding{transform: translateX(-100%);transition: transform 0.5s;}  
      &.hide{transform: translateX(-150%);transition: transform 0.5s;}  
      
      .lnbTab{width:100%;height:45px;padding:0 15px;box-sizing: border-box;border-top:1px solid #dedede;border-bottom:1px solid #dedede;
         .menu{cursor: pointer;background: url('../imgs/ico/ico_lnbTab_menu.png') no-repeat center;
            &:hover{position: relative;background: url('../imgs/ico/ico_lnbTab_menu_on.png') no-repeat center;}  
            &.on{position: relative;background: url('../imgs/ico/ico_lnbTab_menu_on.png') no-repeat center;}  
            &.on::after{content:'';position: absolute;bottom: -1px;width:100%;height:3px;background: #0c89fb;}  
         }  
         .favorite{cursor: pointer;background: url('../imgs/ico/ico_lnbTab_favorite.png') no-repeat center;
            &:hover{position: relative;background: url('../imgs/ico/ico_lnbTab_favorite_on.png') no-repeat center;}  
            &.on{position: relative;background: url('../imgs/ico/ico_lnbTab_favorite_on.png') no-repeat center;}  
            &.on::after{content:'';position: absolute;bottom: -1px;width:100%;height:3px;background: #0c89fb;}  
         }  
         .history{cursor: pointer;background: url('../imgs/ico/ico_lnbTab_history.png') no-repeat center;
            &:hover{position: relative;background: url('../imgs/ico/ico_lnbTab_history_on.png') no-repeat center;}  
            &.on{position: relative;background: url('../imgs/ico/ico_lnbTab_history_on.png') no-repeat center;}  
            &.on::after{content:'';position: absolute;bottom: -1px;width:100%;height:3px;background: #0c89fb;}  
         }  
         .process{cursor: pointer;background: url('../imgs/ico/ico_lnbTab_process.png') no-repeat center;
            &:hover{position: relative;background: url('../imgs/ico/ico_lnbTab_process_on.png') no-repeat center;}  
            &.on{position: relative;background: url('../imgs/ico/ico_lnbTab_process_on.png') no-repeat center;}  
            &.on::after{content:'';position: absolute;bottom: -1px;width:100%;height:3px;background: #0c89fb;}  
         }  
      } 
      
      .lnb-container{position: absolute;top: 0;left: 0;right: 0;bottom: 0;overflow: auto;
         .nav-item{padding-left: 20px;box-sizing: border-box;cursor: pointer;
            &.selected{
               > .extend{color: #1c90fb; font-weight:bold;
                  .nav-text-cnt{color:#1c90fb;}  
               }
            }
            
            &.step-1{padding:0px;border: solid #e1e6ec;border-width: 0 0 1px 0;
               > .extend{padding:0;padding: 0px 15px 0 12px;
                  .nav-icon{display:none !important;}  
                  .nav-text {flex:1 1;display: inline-block;font-size: 13px;line-height: 2;letter-spacing: -0.5px;padding: 5px 30px 5px 12px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
                     &:hover {color: #1c90fb;}  
                  }
                  .nav-text-cnt{margin-top:13px;}
                  .item-close {margin-top: 7px;;} 
               }
            }   
            &.step-2{
               > .extend{padding-right: 15px;}
               &.nav-item-close:first-child::after {content: '';width:25px;height:13px; background: #fff;position: absolute;left:0px;top:0px;}  
               &.nav-item-open:first-child::after {content: '';width:25px;height:13px; background: #fff;position: absolute;left:0px;top:0px;}  
            }   
            &.step-3 {padding-left: 15px; background: url('../imgs/bg/bg_dat.png') repeat-y 14px 0px;
               &::after {content: ''; position: absolute; top: -4px; left: 14px; width: 1px; height: 4px; background: url('../imgs/bg/bg_dat.png') repeat-y; opacity: 0.4;}  
               > .extend{padding-right: 15px;}
            }

            &.item-folder{
               > .extend .nav-icon{display: inline-block;width: 15px;height: 16px;background: url('../imgs/ico/ic_arrow_right_02_m_normal.png') no-repeat center;}  
               &.nav-item-open > .extend .nav-icon{display: inline-block;width: 15px;height: 16px;background: url('../imgs/ico/ic_arrow_left_02_m_normal.png') no-repeat center;}  
            } 
            &.nav-item-open{
               > .extend .nav-icon {background: #fff url('../imgs/bg/bg_datL2.png') no-repeat 3px 0px; height: 20px;
                  &::after {content: ''; position: absolute; top: 10px; left: -1px; width: 9px; height: 9px; background:url('../imgs/ico/btn_tree_open.png') no-repeat center center;}  
               } 
               > .extend + .sub-container{height: auto;transform: rotateX(0deg);transition: 0.5s;}
            } 
            &.nav-item-close{
               > .extend .nav-icon {background: #fff url('../imgs/bg/bg_datL2.png') no-repeat 3px 0px; height: 20px;
                  &::after {content: ''; position: absolute; top: 10px; left: -1px; width: 9px; height: 9px; background:url('../imgs/ico/btn_tree_close.png') no-repeat center center;}  
               } 
            }  

            .extend{position: relative; display: flex; align-items: center; height: 28px; font-size: 0; box-sizing: border-box;
               + .sub-container{height: 0;transform: rotateX(-90deg);}  
               .nav-icon{position: absolute; top: 0; left: 0; width: 13px; height: 15px; background:#fff url('../imgs/bg/bg_datL2.png') no-repeat 3px 0px;}  
               .nav-icon-favorite{display: inline-block;width: 15px;height: 16px;background: url('../imgs/ico/ic_favorite_off.png') no-repeat center;
                  &.on{background: url('../imgs/ico/ic_favorite_on.png') no-repeat center;}  
               }  
               .nav-text{display: block;font-size: 13px;letter-spacing: -0.5px;padding: 0 0 0 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
                  &:hover {color: #1c90fb;
                     + .nav-text-cnt{color: #1c90fb;}
                  }  
               }  
               .nav-text-cnt{display: block;height: 17px;font-size: 11px;text-align: right;margin-left: 4px;color:#A6A6A6;}  
            }
            > .extend .item-close {width: 16px; height: 16px;vertical-align: top; background: url("../imgs/ico/ic_close_01_s_normal.png") no-repeat center; cursor: pointer; display: inline-block;margin-left:2px;}  
            > .extend .item-close:hover {background: url("../imgs/ico/ic_close_01_s_over.png") no-repeat center;}  
         }  
         .sub-container{background: url('../imgs/bg/bg_dat.png') repeat-y 19px 0px;
            li {background: url('../imgs/bg/bg_dat.png') repeat-y 19px 0px;
               &:last-child{background: none;
                  .nav-icon {width: 13px; height:15px; background:#fff url('../imgs/bg/bg_datL2.png') no-repeat 3px 0px;}  
               }
               .extend {margin-left:-4px;}  
            }
         }

         &.snbCon{
            .nav-item{
               &.step-1 {padding:0;border-bottom: 1px solid #d9d9d9;
                  > .extend {height:35px;border-bottom: 1px solid #d9d9d9;padding:0;
                     // 2뎁스에 게시물 숫자 필요시 추가   
                     &.depthCnt{flex-direction: row; align-items: center;
                        .nav-text{padding-right: 14px;}
                        .nav-text-cnt{margin: 0 14px 0 0; font-weight: bold; font-family: 'NotoSans';}
                     }
                     .nav-text {font-weight: 700;}  
                     .nav-text-cnt{margin-left: 15px;}  
                     .nav-setting{position:absolute;top:10px;right:30px;width:14px;height:14px;background: url('../imgs/ico/ic_settine_02_s_normal.png') no-repeat center;
                        &:hover{background: url('../imgs/ico/ic_settine_02_s_over.png') no-repeat center;}  
                     }  
                  }  
                  &:last-child > .extend {border-bottom:none;}  
                  &.nav-item{
                     > .extend {border-bottom:none}
                     &.nav-item-open{
                        > .extend {border-bottom:1px solid #d9d9d9;}  
                        &:last-child > .extend {border-bottom:1px solid #d9d9d9;}  
                     }
                  }   
                  &.nav-item-open{
                     > .extend{background: url('../imgs/ico/ic_arrow_up_01_s_normal.png') no-repeat right 14px center;
                        &:hover{background: url('../imgs/ico/ic_arrow_up_01_s_over.png') no-repeat right 14px center;}
                     }  
                  } 
                  &.nav-item-close{
                     > .extend{background: url('../imgs/ico/ic_arrow_down_01_s_normal.png') no-repeat right 14px center;
                        &:hover{background: url('../imgs/ico/ic_arrow_down_01_s_over.png') no-repeat right 14px center;}  
                     }  
                     .extend + .sub-container {padding:0;margin-top:-1px;}  
                  }
                  &.nav-item-hidden{
                     .extend {background:none;}
                     .extend + .sub-container {padding:0;margin-top:-1px;}
                  }   
               }
               &.step-2{
                  .extend{
                     + .sub-container {padding: 0px;}
                     .nav-text {font-size:13px;}
                  }
               }
               .extend + .sub-container {padding: 4px 5px 4px 0px;background: #fff;}  
            }
         }   
      }  
   }  
} 

/*특화 LNB*/  
.sideRegi {overflow: hidden;text-align: center;display:flex;padding:12px;box-sizing:border-box;  
   > div {flex:1 1 auto;}  
   .btn_sideRegi {background: #1c90fb !important; color:#fff !important; border:none !important;height:34px; padding:0 !important;width:100%; border-radius: 2px;}  
   .btn_sideRegi:hover {background: #034ca6 !important;}  
   .btn_sideRegi:hover span::after {background: none !important;}  
   div + div{margin-left:1px;}  
   button span{font-size:13px !important;}  
   button img{margin-left:-18px; margin-right:-2px !important; width:34px; height:34px;}  
}  
  
.sideTab { height:100%;}  
  
.sideRegi + .lnb-container{top: 61px !important;left: 0;right: 0;bottom: 0;overflow: auto;}  
  
.sideLnbMenu {background:#fafafa;position:relative;border-top:1px solid #e6e6e6;  
  .lnb-container {overflow:initial !important;}  
}  
.sideBtm{text-align: center;background: #ecf1f5;;position:absolute;bottom:0;left:0;right:0; z-index: 10;  
   .calSetting {cursor:pointer;padding-left:22px; background:url("../imgs/ico/ic_settine_02_m_normal.png") no-repeat left top 2px;font-size:14px; line-height: 16px;margin-top: 12px;display: inline-block;}  
   .txt{cursor:pointer;font-size:14px;margin-top: 12px;display: inline-block;line-height: 16px;}  
  
   .sbDiv{height:36px;justify-content: center;box-sizing:border-box;display:flex;text-align: center;  
      &:hover{background: #e6f5ff;}  
      &.hover_no:hover{background: inherit !important;}  
      .sbBtn {flex:1 1 auto; cursor:pointer;position:relative;  
         &.on {  
            .tit {color:#1c90fb;}  
            .sbTxtUser {border-radius: 3px;box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);background:#fff;  
               .ico{background:url("../imgs/ico/ic_user_select@2x.png") no-repeat center center; background-size: 18px;}   
               }   
            .sbTxtAdmin {border-radius: 3px;box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);background:#fff;  
               .ico{background:url("../imgs/ico/ic_administrator_select@2x.png") no-repeat center center; background-size: 18px;}   
               }   
            .sbTxtMaster {border-radius: 3px;box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);background:#fff;  
               .ico{background:url("../imgs/ico/ic_master_select@2x.png") no-repeat center center; background-size: 18px;}   
               }      
         }  
  
         &:hover{  
            .sbTxtUser {  
               .ico{background:url("../imgs/ico/ic_user_select@2x.png") no-repeat center center; background-size: 18px;}   
               }   
            .sbTxtAdmin {  
               .ico{background:url("../imgs/ico/ic_administrator_select@2x.png") no-repeat center center; background-size: 18px;}   
               }   
            .sbTxtMaster {  
               .ico{background:url("../imgs/ico/ic_master_select@2x.png") no-repeat center center; background-size: 18px;}   
               }      
         }  
  
         > span {width:22px; height:28px;display:inline-block;margin: 4px;}  
         &.flex { display:flex;}  
         .sbTxtUser {flex:1;  
            .ico {background:url("../imgs/ico/ic_user_normal@2x.png") no-repeat center center;display:inline-block;height:28px;width: 22px; background-size: 18px;}  
         }  
         .sbTxtAdmin {flex:1;  
            .ico {background:url("../imgs/ico/ic_administrator_normal@2x.png") no-repeat center center;display:inline-block;height:28px;width: 22px; background-size: 18px;}  
         }  
         .sbTxtMaster {flex:1;  
            .ico {background:url("../imgs/ico/ic_master_normal@2x.png") no-repeat center center;display:inline-block;height:28px;width: 22px; background-size: 18px;}  
         }  

         & > [class*="OBTFloatingPanel_root"] {position: absolute;left: 50% !important;top: 50% !important;transform: translate(-50%, -150%);visibility: visible !important;}           
      }  
   }  
}  

/* 사이즈 툴 */  
#containerWrap #sideLnb .sizeTool{position: absolute;top: 0;right: -7px;bottom: 0px;width: 6px;border: solid #e6e6e6;border-width: 1px 1px 0 0;background: #fafafa;box-sizing: border-box;cursor: ew-resize;}  
#containerWrap #sideLnb .sizeTool .sizeToolBtn{position: absolute;top: 50%;background-color: #b4bcc5;width: 3px;height: 15px;cursor: pointer;transition: all 0.2s;transform: translate(1px, -50%);}  
#containerWrap #sideLnb .sizeTool:hover .sizeToolBtn{background-color: #333948;}  
#containerWrap #sideLnb .sizeTool .sizeToolBtn:hover{background-color: #333948;width: 5px;height: 50px;transform: translate(0px, -50%);}  
  
/* 페이지 컨텐츠 타이틀*/  
#containerWrap #titleFnc{position: absolute;top: 0;left: 0;right: 0px;height: 50px;border-bottom: 1px solid #e5e5e5;box-sizing: border-box;}  
#containerWrap #titleFnc .content-title{position: absolute;top: 0;left: 0;width: 50%;height: 50px;padding: 0 0 0 50px;box-sizing: border-box;}  
#containerWrap #titleFnc .content-title .title-fnc{position: absolute;top: 16px;left: 19px;width: 18px;height: 18px;background: url('../imgs/ico/ic_smallthumb_m_normal.png') no-repeat center;cursor: pointer;}  
#containerWrap #titleFnc .content-title .title-text{display: block;font-size: 18px;line-height: 2;letter-spacing: -0.5px;padding: 7px 0;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}  
  
/* 페이지 컨텐츠 기능 */  
#containerWrap #titleFnc .content-title-fnc{position: absolute;top: 0;right: 0;width: 50%;height: 50px;overflow: hidden;}  
#containerWrap #titleFnc .content-title-fnc::after {content:"";display:block;clear:both;height:0;visibility:hidden;}  
#containerWrap #titleFnc .content-title-fnc ul{float: right;margin: 13px 15px 0 0;}  
#containerWrap #titleFnc .content-title-fnc ul::after {content:"";display:block;clear:both;height:0;visibility:hidden;}  
#containerWrap #titleFnc .content-title-fnc ul li{float: right;margin: 0 0 0 5px;}  
#containerWrap #titleFnc .content-title-fnc ul li span{display:block;width: 24px;height: 24px;cursor: pointer;}  
#containerWrap #titleFnc .content-title-fnc ul li .favorites{background: url('../imgs/ico/ic_favorite_off.png') no-repeat center;}  
#containerWrap #titleFnc .content-title-fnc ul li .favorites.on{background: url('../imgs/ico/ic_favorite_on.png') no-repeat center;}  
#containerWrap #titleFnc .content-title-fnc ul li .help{background: url('../imgs/ico/ic_question_01_m_normal.png') no-repeat center;}  
  
/* 페이지 컨텐츠 :: 타이틀과 lnb를 사용하는 컨텐츠*/  
#containerWrap #contents{position: absolute;top: 94px;left: 0;right: 0;bottom: 0;overflow-y: auto;overflow-x: auto;z-index: 11;} /* 툴팀 짤림으로 인해 사이드와 같이11로 변경*/  
#containerWrap #contents > div{min-height:536px;}  
#contents {/* min-width:1024px; */min-width:1032px;  
   //온라인 메뉴얼 (특화 타이틀 영역 커스텀 있는 경우)  
   .onlineManualBtn{display: inline-flex; align-items: center;}  
}  
#contents > div {height:100%;}  
  
  
#contents{  
   [class*="OBTPageContainer_root"] {  
      [class*="OBTPageContainer_header"] {  
         [class*="OBTPageContainer_title"] {  
            [class*="OBTPageContainer_titleButton"] {  
               display:none;              
            }      
         }    
      }  
   }  
}  
  
/* 풀 컨텐츠 :: 탭아래 전체 영역 사용 컨텐츠박스 */  
#containerWrap #contentsFull{display: none;position: absolute;top: 0;left: 0px;right: 0px;bottom: 0px;background: #ffffff;}  
  
/* 포털뷰 컨테이너 */  
#portalviewContainer{position: fixed;top: 0;left: 0;right: 0;bottom: 0;z-index: 1000;transition: all 0.5s;}  
  
/**************************************************  
   Login A / B  
**************************************************/  
.logintype-A{position: absolute;top: 0;left: 0;right: 0;bottom: 0;width: 100%;height: 100%;background: url('../imgs/bg/img_login.jpg') no-repeat center top;background-size: 1920px 1080px;overflow:hidden;}  
@media screen and (min-width:1921px), screen and (min-height:1081px) {  
      .logintype-A{background: url('../imgs/bg/img_login.jpg') no-repeat left top;background-size: cover;transition:background-size 1s linear;}  
   }  
@media screen and (min-width:1921px){  
   .logintype-A .userCustomBox .loginLogo img{width:fit-content !important;max-width:12.9vw !important;transition: 0.5s;}  
   .logintype-A .userCustomBox .loginTxt .bigText{font-size:1.65vw !important;line-height:1.9vw !important;font-weight:normal !important;transition: 0.5s;}  
   .logintype-A .userCustomBox .loginTxt .subText{font-size:0.83vw !important;line-height:1vw !important;font-weight:normal !important;transition: 0.5s;}  
}  
.logintype-A .userCustomBox{position: absolute;top:150px;left:70px;right:515px;width:auto;height:auto;}  
.logintype-A .userCustomBox .loginLogo{width:auto;height:auto;}  
.logintype-A .userCustomBox .loginLogo img{width:fit-content;max-width:247px;}  
.logintype-A .userCustomBox .loginTxt{margin:45px 0 0 0;min-width:475px;}  
.logintype-A .userCustomBox .loginTxt .bigText{font-size:32px;line-height:36px;letter-spacing: -0.58px;color:#ffffff;}  
.logintype-A .userCustomBox .loginTxt .subText{font-size:16px;line-height:20px;letter-spacing: -0.16px;color:rgba(255, 255, 255, 0.8);margin:20px 0 0 0;}  
.logintype-A .corpArea{position: absolute; left: 70px; bottom: 54px;
   ul li{display: flex; flex-direction: row; align-items: center; 
      .txt{font-size: 12px; letter-spacing: -0.3px; color: #ffffff; cursor: pointer;}
      &::after{content: ''; display: inline-block; width: 1px; height: 10px; margin: 0 10px; background-color: rgba(255, 255, 255, 0.3);}
      &:last-child::after{display: none;}
  }
}
.logintype-A .copy{position: absolute;left: 70px;bottom: 30px;text-align: left;font-size:12px;line-height:20px;color: rgba(255,255,255,0.7);letter-spacing: -0.12px;}  
.logintype-A .loginBox{position: fixed;top: 0;bottom: 0;right: 0;width: 445px;background-color: rgba(255,255,255, 0.8);}  
.logintype-A .loginBox .loginForm{position: absolute;top: 50%;left: 50px;right: 50px;height: auto;transform: translateY(-50%);}  
.logintype-A .loginBox .loginForm .loginTitle{margin-bottom: 70px;color: #2e3645;font-size:24px;line-height:1.33;font-weight:bold;}  
.logintype-A .loginBox .loginForm .loginTitle b{font-size:26px;font-weight:bold;}  

// 특정상황에 따라 폰로그인 형태로 전환
.logintype-A.modePhone{ 
   .loginBox{position: fixed;top: 0;bottom: 0;right: 0;width: 100%;background-color: rgba(255, 255, 255, 0.8);
      .loginForm{width: 346px;margin: 0 auto;top: 40%;transform: scale(2.5) translateY(0%);}
      // 다국어 기능 선택
      .lanagageLayer{transform: scale(3) translateX(-30%);}
   }
}

// 로그인 input form  
.logintype-A .loginBox .loginForm .inputStyle{width: 100%;height: 50px;margin: 0 0 14px;background: rgba(255,255,255,.5);border-radius: 50px;}  
.logintype-A .loginBox .loginForm .inputStyle input{height:50px !important;padding: 0 25px !important;transition: all 0.3s !important;box-sizing: border-box !important;border: 1px solid #c4c4c4 !important;border-radius: 50px !important;font-size:16px !important;font-weight:normal !important;color:#000000 !important;}  
.logintype-A .loginBox .loginForm .inputStyle input:focus{border:1px solid #1c90fb !important}  
.logintype-A .loginBox .loginForm .inputStyle.disabled input{color:#7b7b7b;border:1px solid rgba(207, 209, 215, 0) !important;background-color:rgba(207, 209, 215, 0.5) !important;}  
.logintype-A .loginBox .loginForm .inputStyle.disabled input:focus{border:1px solid rgba(207, 209, 215, 0.5) !important;}  
.logintype-A .loginBox .loginForm .inputStyle input::placeholder{color:#a6a6a6}  
// 로그인영역 버튼들  
.logintype-A .loginBox .loginForm .loginBtn{margin: 35px 0 0 0;}  
.logintype-A .loginBox .loginForm .wBox .loginBtn{margin: 40px 0 0 0;}  
.logintype-A .loginBox .loginForm .loginBtn button{display: block !important;width: 100% !important;height: 54px !important;}  
.logintype-A .loginBox .loginForm .loginBtn .loginBtnFlex{padding:0 10px 0 0;border: 0px !important;border-radius: 10px !important;box-shadow:0 3px 15px 0 rgba(61, 69, 86, 0.27) !important;background-image: linear-gradient(to right, #00aaff 4%, #8168ff 95%) !important;outline:none;}  
.logintype-A .loginBox .loginForm .loginBtn .loginBtnFlex span{font-size: 18px !important;font-weight:bold;color: #ffffff !important;}  
.logintype-A .loginBox .loginForm .loginBtn .loginBtnFlex.poc span{font-size: 16px !important;font-weight:bold;color: #ffffff !important;}  
.logintype-A .loginBox .loginForm .loginBtn .loginBtnFlex:hover{background-image: linear-gradient(to right, #0084f4 4%, #654ae8 95%) !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex{border: 1px solid transparent !important;border-radius: 10px !important;box-shadow:0 3px 15px 0 rgba(61, 69, 86, 0.27) !important;background: #ffffff !important;outline:none;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex span{font-size:18px !important;line-height:54px !important;font-weight:bold !important;color: #000000 !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex:hover{border:1px solid #1c90fb !important;background: #ffffff !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex:active{border:1px solid #1c90fb !important;background: #ffffff !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue{border: 0px solid transparent !important;border-radius: 10px !important;box-shadow:0 3px 15px 0 rgba(61, 69, 86, 0.27) !important;background-image: linear-gradient(to right, #00aaff 4%, #8168ff 95%) !important;outline:none;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue span{font-size:18px !important;line-height:54px !important;font-weight:bold !important;color: #ffffff !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue:hover{background-image: linear-gradient(to right, #0084f4 4%, #654ae8 95%) !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue:active{background-image: linear-gradient(to right, #0084f4 4%, #654ae8 95%) !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.disabled{border: 1px solid transparent !important;border-radius: 10px !important;box-shadow:0 3px 15px 0 rgba(61, 69, 86, 0.27) !important;background: #dddddd !important;outline:none;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.disabled span{font-size:18px !important;line-height:54px !important;font-weight:bold !important;color: #a6a6a6 !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.disabled:hover{border:1px solid #dddddd !important;background: #dddddd !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.disabled:active{border:1px solid #dddddd !important;background: #dddddd !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue.disabled{border: 1px solid transparent !important;border-radius: 10px !important;box-shadow:0 3px 15px 0 rgba(61, 69, 86, 0.27) !important;background: #b4b9bc !important;outline:none;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue.disabled span{font-size:18px !important;line-height:54px !important;font-weight:bold !important;color: rgba(255,255,255, 0.5) !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue.disabled:hover{border:1px solid #b4b9bc !important;background: #b4b9bc !important;}  
.logintype-A .loginBox .loginForm .loginBtn .btnFlex.blue.disabled:active{border:1px solid #b4b9bc !important;background: #b4b9bc !important;}  
.logintype-A .loginBox .loginForm .loginBtn > div{flex:1;}  
.logintype-A .loginBox .loginForm .loginBtn > div:nth-child(1){margin:0;}  
.logintype-A .loginBox .loginForm .loginBtn > div:nth-child(2){margin:0 8px;}  
.logintype-A .loginBox .loginForm .loginBtn > div:nth-child(3){margin:0;}  
.logintype-A .loginBox .loginForm .loginBtn > div:last-child:nth-child(1){margin:0 !important;}  
.logintype-A .loginBox .loginForm .loginBtn > div:last-child:nth-child(2){margin:0 0 0 8px !important;}  
.logintype-A .loginBox .loginForm .loginBtn > div:last-child:nth-child(3){margin:0 !important;}  
.logintype-A .loginBox .loginForm .loginPrevText{margin:15px 0 0 0 !important;font-size:14px;line-height:20px;text-align:center;letter-spacing: -0.7px;}  
.logintype-A .loginBox .loginForm .orLine{position: relative;margin: 20px 0 22px 0 !important;font-size: 14px;color:#8c8c8c;text-align: center;letter-spacing: -0.7px;
   &::before{content:'';position:absolute;top:10px;left:0px;width:130px;height:1px;background-color: #dddddd;}
   &::after{content:'';position:absolute;top:10px;right:0px;width:130px;height:1px;background-color: #dddddd;}
}
// 고객사 커스텀 (메가존)
.logintype-A .loginBox .loginForm .loginBtn{
   .loginBtnFlex.megazone{padding:0 10px 0 0;border: 0px !important;border-radius: 10px !important;box-shadow:0 3px 15px 0 rgba(90, 90, 90, 0.15) !important;background-color:#000000;background-image:none !important;outline:none;
      span{font-size: 16px !important;color: #ffffff !important;letter-spacing: -0.4px;}
   }
}  

// 인증공통  
.logintype-A .loginBox .loginForm .wBox{position: relative;width: 100%;height: 360px;box-sizing: border-box;}  
.logintype-A .loginBox .loginForm .wBox .title{font-size:24px;font-weight:bold;line-height:32px;text-align:center;letter-spacing: -0.8px;color:#2e3645;}  
.logintype-A .loginBox .loginForm .btnDiv{position:absolute;width: 100%;bottom:40px;text-align: center;}  
.logintype-A .loginBox .loginForm .btnDiv2{position:absolute;width: 100%;bottom:80px;text-align: center;}  
.logintype-A .loginBox .loginForm .txtDiv{width: 100%;margin:10px 0 0 0;}  
.logintype-A .loginBox .loginForm .txtDiv::after{content:"";display:block;clear:both;height:0;visibility:hidden;}  
.logintype-A .loginBox .loginForm .txtDiv .linkTxt{font-size:14px;line-height:20px;letter-spacing: -0.7px;}  
.logintype-A .loginBox .loginForm .qrBoxStyle{padding:150px 0 0 0;}  
.logintype-A .loginBox .loginForm .qrBoxStyle .qr_view{position:absolute;width: 100%;top:60px;text-align: center;}  
.logintype-A .loginBox .loginForm .qrBoxStyle .text{margin:0;font-size:13px;line-height:20px;text-align: center;color:#000000;}  
// 인증단계  
.logintype-A .loginBox .loginForm .stepDiv{position:relative;padding:20px 20px 0 20px;}  
.logintype-A .loginBox .loginForm .stepDiv::before{content: '';position: absolute;top: 35px;left: 20px;right: 20px;height: 1px;background: #a6a6a6;z-index: 1;}  
.logintype-A .loginBox .loginForm .stepDiv .stepUnit{position: relative;border: 1px solid #a6a6a6;border-radius: 16px;text-align: center;background: #ffffff;z-index: 2;}  
.logintype-A .loginBox .loginForm .stepDiv .stepUnit:nth-child(2){margin:0 12px;}  
.logintype-A .loginBox .loginForm .stepDiv .stepUnit .num{display: inline-block;width: 14px;height: 14px;border-radius: 20px;font-size:8px;font-weight:bold;line-height:14px;color: #ffffff;background: #a6a6a6;}  
.logintype-A .loginBox .loginForm .stepDiv .stepUnit .txt{padding:0 0 0 2px;font-size:11px;line-height:30px;color:#a6a6a6;letter-spacing: -0.28px;}  
.logintype-A .loginBox .loginForm .stepDiv .stepUnit.on{border: 1px solid #1c90fb;background: #e9f5ff;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);}  
.logintype-A .loginBox .loginForm .stepDiv .stepUnit.on .num{background: #1c90fb;}  
.logintype-A .loginBox .loginForm .stepDiv .stepUnit.on .txt{color:#1c90fb;}  
// 인증수단 선택  
.logintype-A .loginBox .loginForm .sertiType{position:relative;}  
.logintype-A .loginBox .loginForm .sertiType .radio{width:14px;height:14px;margin:0 auto;background: url('../imgs/main/login_radio.png') no-repeat center;  
   &.on{width:14px;height:14px;background: url('../imgs/main/login_radio_checked.png') no-repeat center;}  
}  
.logintype-A .loginBox .loginForm .sertiType .txt{font-size:12px;line-height:1.3;text-align: center;color: #4a4a4a;}  
.logintype-A .loginBox .loginForm .sertiType .text{font-size:13px;line-height:20px;text-align: center;color: #4a4a4a;margin: 20px 0 -20px 0;}  
.logintype-A .loginBox .loginForm .sertiType .unit01{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_mobile.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
.logintype-A .loginBox .loginForm .sertiType .unit02{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_otp.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
.logintype-A .loginBox .loginForm .sertiType .unit03{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_device.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
.logintype-A .loginBox .loginForm .sertiType .unit04{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_password.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
.logintype-A .loginBox .loginForm .textBox{position: absolute;bottom: -50px;left: 0;right: 0;text-align: center;}  
.logintype-A .loginBox .loginForm .textBox .sertiHelpBtn{position:relative;display:inline-block;margin:0 0 0 20px;font-size:14px;line-height:20px;letter-spacing: -0.7px;color:#000000;}  
.logintype-A .loginBox .loginForm .textBox .sertiHelpBtn::before{content:'';position: absolute;top:0;left:-20px;width:18px;height:20px;background: url('../imgs/ico/ic_question_02_m_normal.png') no-repeat left center;}  
.logintype-A .loginBox .loginForm .textBox .text{font-size:13px;line-height:20px;text-align: center;color:#000000;}  
// 이미지박스  
.logintype-A .loginBox .loginForm .imgBoxStyle{position:relative;}  
.logintype-A .loginBox .loginForm .imgBoxStyle .text{margin:0;font-size:13px;line-height:20px;text-align: center;color:#000000;letter-spacing: -0.65px;}  
.logintype-A .loginBox .loginForm .imgBoxStyle .radio{text-align: center;}  
.logintype-A .loginBox .loginForm .imgBoxStyle.certi-notallowed{padding:150px 0 0 0;background: url('../imgs/main/img_certification.png') no-repeat top 30px center;}  
.logintype-A .loginBox .loginForm .imgBoxStyle.app-install{padding:150px 0 0 0;background: url('../imgs/main/img_app.png') no-repeat top 30px center;}  
.logintype-A .loginBox .loginForm .imgBoxStyle.complete{padding:150px 0 0 0;background: url('../imgs/main/ic_complete.png') no-repeat top 30px center;}  
.logintype-A .loginBox .loginForm .imgBoxStyle.device{padding:150px 0 0 0;background: url('../imgs/main/img_device.png') no-repeat top 30px center;}  
.logintype-A .loginBox .loginForm .imgBoxStyle.device-rerun{padding:150px 0 0 0;background: url('../imgs/main/img_device-rerun.png') no-repeat top 30px center;}  
// pin입력  
.logintype-A .loginBox .loginForm .pin_area{padding:50px 0 0 0;}  
.logintype-A .loginBox .loginForm .pin_input{height: 50px;margin: 0 4px;border-radius: 4px;border: 1px solid #e6e6e6;background: #ffffff;box-sizing: border-box;}  
.logintype-A .loginBox .loginForm .pin_input .number{width: 100%;padding: 0;font-size:25px;line-height:50px;color: #4c9cff;border: none;text-indent: 0;border-radius: 4px;text-align: center;background: transparent;outline: none;}  
.logintype-A .loginBox .loginForm .text{margin:30px 0 0 0;font-size:13px;line-height:18px;text-align: center;color:#000000;  
   .menuIco{display: inline-block;width:18px;height:18px;vertical-align:bottom;background: url('../imgs/ico/ic_view_list_02_m_normal.png') no-repeat center;}  
}  
// 장치인증  
.logintype-A .loginBox .loginForm .imgDevice{position:relative;}  
.logintype-A .loginBox .loginForm .imgDevice.device-connected{padding:110px 0 0 0;background: url('../imgs/main/img_device.png') no-repeat top -5px center;}  
.logintype-A .loginBox .loginForm .imgDevice.device-notconnected{padding:180px 0 0 0;background: url('../imgs/main/img_device_notconnected.png') no-repeat top 30px center;}  
.logintype-A .loginBox .loginForm .imgDevice .text{font-size:13px;line-height:41px;letter-spacing: -0.65px;text-align: center;}  
.logintype-A .loginBox .loginForm .imgDevice .text span{font-size:13px;font-weight:bold;line-height:41px;}  
// 로그인 체크박스  
.logintype-A .loginBox .loginForm .checkboxItem{margin: 15px 0 0 0;}  
.logintype-A .loginBox .loginForm .checkboxItem span > span > span{background-image: url('../imgs/temp/klago_sp_lux.png') !important;margin-top:-8px !important;}  
.logintype-A .loginBox .loginForm .checkboxItem::after{content:"";display:block;clear:both;height:0;visibility:hidden;}  
.logintype-A .loginBox .loginForm .checkboxItem input[type="checkbox"] + svg{border-radius:3px;background:#dddee0;margin-top:-8px !important;}  
.logintype-A .loginBox .loginForm .checkboxItem input[type="checkbox"] + svg path{fill:#babcc3}  
.logintype-A .loginBox .loginForm .checkboxItem input[type="checkbox"]:checked + svg{border-radius:3px;background:#1c90fb;margin-top:-8px !important;}  
.logintype-A .loginBox .loginForm .checkboxItem input[type="checkbox"]:checked + svg path{fill:#1c90fb}  
.logintype-A .loginBox .loginForm .checkboxItem input[type="checkbox"]:checked + svg polygon{fill:#ffffff}  
.logintype-A .loginBox .loginForm .checkboxItem label{font-size:14px !important;font-weight:normal !important;font-family:'NotoSans' !important;margin-left:5px;}  
.logintype-A .loginBox .loginForm .checkboxItem label:hover::after{width:100%;transition: all 0.5s;}  
.logintype-A .loginBox .loginForm .checkboxItem label:focus::after{width:100%;transition: all 0.5s;}  
// 비밀번호찾기  
.logintype-A .loginBox .loginForm .findPwItem{margin:15px 10px 0 10px;}  
.logintype-A .loginBox .loginForm .findPwItem .findPw{font-size:14px;}  
// 지원 환경 및 브라우저 업그레이드 안내 팝업  
.certiBrowserBox{margin:10px 0 0 0;border:1px solid #dbdbdb;border-radius: 4px;justify-content: center;}  
.certiBrowserBox .unit01{max-width:90px;padding: 105px 24px 0 24px;text-align: center;background: url('../imgs/main/img_download_edge.png') no-repeat top 33px center;}  
.certiBrowserBox .unit02{max-width:90px;padding: 105px 24px 0 24px;text-align: center;background: url('../imgs/main/img_download_chrome.png') no-repeat top 33px center;}  
.certiBrowserBox .unit03{max-width:90px;padding: 105px 24px 0 24px;text-align: center;background: url('../imgs/main/img_download_safari.png') no-repeat top 33px center;}  
// 인증수단 안내 팝업  
.certiGuideText .tit_p{font-size:12px;font-weight:bold;line-height:16px;color:#000000;}  
.certiGuideText li{font-size:12px;line-height:16px;color:#4a4a4a;}  
// FIDO 장치인증이란  
.certiFidoGuideText{width:678px;height:100px;margin:0 0 10px 0;background:url('../imgs/main/img_mail_resend.png') no-repeat;  
   .tit_p{margin:18px 0 0 20px;font-size:18px;font-weight:bold;line-height:27px;color:#0c2b65;}  
   .text{margin:3px 0 0 20px;font-size:12px;line-height:16px;color:#5c7198;}  
}  


// 다국어 기능 선택
.lanagageLayer{position:fixed; bottom:56px; right:51px;
   .filterText{min-width: 59px; color:#000; padding:0 27px 0 0; background: url("../imgs/ico/btn_arrow_down_normal.png") no-repeat right 5px center; background-size: 12px; cursor: pointer;     
      span{background: url('../imgs/ico/ic_web_s_normal@2x.png') no-repeat left top 3px; background-size: 14px; padding:0 0 0 18px}
      &.on{background: url("../imgs/ico/btn_arrow_up_normal.png") no-repeat right 5px center; background-size: 12px;}
  }

   .layerBox{position:absolute; bottom:28px; right:0; box-shadow: 0px 1px 1px 0 rgba(6, 6, 6, 0.1); border: solid 1px #a6a6a6; background-color: #fff; box-sizing: border-box;
       ul{
           li{font-size:12px;line-height:27px;color: #000;padding: 0 12px 0 26px;box-sizing: border-box;cursor: pointer;   
               &:hover{background-color:#e6f5ff ;}   
               &.chk{font-size:12px;line-height:27px;color: #1c90fb;background: url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 14px;}   
               &.chk:hover{background:#e6f5ff url('../imgs/ico/ic_check_01_m_over@2x.png') no-repeat left 6px center; background-size: 14px;}  
           }   
       }   
   }   
}  
  
/**************************************************  
   심플로그인  
**************************************************/  
// 심플로그인 다이얼로그  
.simpleLoginDialog {  
   ._isDialog{  
      > div:nth-child(2) {border-radius:8px !important;}  
   }  
   .dialog_content .dialog_data {  
      > div:first-child {display:none;}  
      > div:nth-child(2) {border-top:none;  
         [class*="OBTDialog_dataWrapper"]{top:0;left:0;right:0;bottom:0;}  
      }  
      > div:nth-child(3) {display:none;}  
   }  
}  
  
.logintype-simple{padding:130px 0 0 0;  
   .loginForm{  
      .titleImg{width:140px;height:140px;margin:0 auto;background: url('../imgs/main/img_logout.png') no-repeat center;}  
      .text01{margin:20px 0 0 0;font-size:24px;font-weight:bold;line-height:36px;letter-spacing:-0.24px;text-align:center;color:#2e3645;}  
      .text02{margin:8px 0 25.5px 0;font-size:13px;line-height:18px;text-align:center;color:#000000;}  
      .inputDiv{position:relative;margin:14.5px auto 0 auto;  
         .inputStyle{  
            > div{border-radius: 25px !important;}  
            > div > div{height:48px !important;padding:0 30px 0 25px !important;border-radius: 25px !important;}  
            > div > div > input{font-size:16px !important;letter-spacing:-0.4px !important;}  
            > div > div > svg{top:16px !important;right:15px !important;}  
         }  
         .validateText{position: absolute;top:57px;left:25px;font-size:12px;line-height:20px;text-align:left;color:#e33e35;}  
      }  
  
      .loginBtn{width:344px;margin: 60px auto 0 auto;  
         button{display: block !important;width: 100% !important;height: 54px !important;}  
         .btnFlex{border: 1px solid transparent !important;border-radius: 10px !important;box-shadow: 0 3px 15px 0 rgba(61, 69, 86, 0.15) !important;background: #ffffff !important;outline:none;  
            span{font-size:18px !important;line-height:54px !important;font-weight:normal !important;color: #000000 !important;}  
            &:hover{border:1px solid #1c90fb !important;background: #ffffff !important;}  
            &:active{border:1px solid #1c90fb !important;background: #ffffff !important;}  
            &.blue{border:none !important;border-radius: 10px !important;box-shadow: 0 3px 15px 0 rgba(61, 69, 86, 0.15) !important;background-image: linear-gradient(to right, #00aaff 4%, #8168ff 95%) !important;outline:none;}  
            &.blue span{font-size:18px !important;line-height:54px !important;font-weight:normal !important;color: #ffffff !important;}  
            &.blue:hover{background-image: linear-gradient(to right, #0084f4 4%, #654ae8 95%) !important;}  
            &.blue:active{background-image: linear-gradient(to right, #0084f4 4%, #654ae8 95%) !important;}  
            &.disabled{border:none !important;border-radius: 10px !important;box-shadow: 0 3px 15px 0 rgba(61, 69, 86, 0.15) !important;background: #dddddd !important;outline:none;}  
            &.disabled span{font-size:18px !important;line-height:54px !important;font-weight:normal !important;color: #a6a6a6 !important;}  
            &.disabled:hover{border:1px solid #dddddd !important;background: #dddddd !important;}  
            &.disabled:active{border:1px solid #dddddd !important;background: #dddddd !important;}  
            &.blue.disabled{border:none !important;border-radius: 10px !important;box-shadow: 0 3px 15px 0 rgba(61, 69, 86, 0.15) !important;background: #b4b9bc !important;outline:none;}  
            &.blue.disabled span{font-size:18px !important;line-height:54px !important;font-weight:normal !important;color: rgba(255,255,255, 0.5) !important;}  
            &.blue.disabled:hover{border:1px solid #b4b9bc !important;background: #b4b9bc !important;}  
            &.blue.disabled:active{border:1px solid #b4b9bc !important;background: #b4b9bc !important;}  
         }  
         > div{flex:1;}  
         > div:nth-child(1){margin:0 4px 0 0;}  
         > div:nth-child(2){margin:0 0 0 4px;}  
         > div:last-child:nth-child(1){margin:0 !important;}
         
         // 고객사 버튼
         .megazone{padding:0 10px 0 0;border: 0px !important;border-radius: 10px !important;box-shadow:0 3px 15px 0 rgba(90, 90, 90, 0.15) !important;background-color:#000000;background-image:none !important;outline:none;
            span{font-size: 16px !important;color: #ffffff !important;letter-spacing: -0.4px;}
         }
      }  
  
      // 비밀번호찾기  
      .findPwItem{margin:12px auto 0 auto;  
         div{font-size:14px;line-height:20px;text-align:center;color:#000000;}  
      }  
      // 다른계정으로 로그인  
      .loginPrevText{margin:12px auto 0 auto;  
         div{font-size:14px;line-height:20px;text-align:center;color:#000000;}  
      }  
  
      .wBox{position: relative;width: 100%;height: 360px;box-sizing: border-box;  
         // 인증공통  
         .title{font-size:24px;font-weight:bold;line-height:32px;text-align:center;letter-spacing: -0.24px;color:#2e3645;}  
         .topText{font-size:13px;line-height:20px;text-align: center;color: #4a4a4a;margin:8px 0 0 0;}  
         .cBox{width:425px;height:362px;margin:0 auto;justify-content: center;  
            .botText{font-size:13px;line-height:20px;text-align: center;color: #4a4a4a;margin:30px 0 0 0;  
               .menuIco{display: inline-block;width:18px;height:18px;vertical-align:bottom;background: url('../imgs/ico/ic_view_list_02_m_normal.png') no-repeat center;}  
            }  
         }  
         .txtDiv{width:344px;margin:12px auto 0 auto;  
            &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}  
            .linkTxt{font-size:14px;line-height:20px;letter-spacing: -0.7px;}  
         }  
         .qrBoxStyle{margin:40px 0 0 0;  
            .qr_view{width:140px;height:140px;margin:0 auto;border-radius: 4px;box-shadow: 0 1px 10px 0 rgba(61, 69, 86, 0.14);  
               img{border-radius: 4px;box-shadow: 0 1px 10px 0 rgba(61, 69, 86, 0.14);}  
            }  
            .count{width:fit-content;height:26px;margin:20px auto 0 auto;padding:0 8px;border-radius:4px;box-sizing: border-box;background-color:#1c90fb;font-size:12px;line-height:26px;color:#ffffff;text-align:center;  
               .num{margin:0 0 0 4px;}  
            }  
            .text{margin:30px 0 0 0;font-size:13px;line-height:20px;text-align: center;color:#000000;}  
         }  
         // 인증단계  
         .stepDiv{position:relative;padding:20px 20px 0 20px;  
            &::before{content: '';position: absolute;top: 35px;left: 20px;right: 20px;height: 1px;background: #a6a6a6;z-index: 1;}  
            .stepUnit{position: relative;border: 1px solid #a6a6a6;border-radius: 16px;text-align: center;background: #ffffff;z-index: 2;  
               &:nth-child(2){margin:0 12px;}  
               .num{display: inline-block;width: 14px;height: 14px;border-radius: 20px;font-size:8px;font-weight:bold;line-height:14px;color: #ffffff;background: #a6a6a6;}  
               .txt{padding:0 0 0 2px;font-size:11px;line-height:30px;color:#a6a6a6;letter-spacing: -0.28px;}  
               &.on{border: 1px solid #1c90fb;background: #e9f5ff;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);  
                  .num{background: #1c90fb;}  
                  .txt{color:#1c90fb;}  
               }  
            }  
         }  
         // 인증수단 선택  
         .sertiType{position:relative;align-items:center;  
            .radio{width:14px;height:14px;margin:0 auto;background: url('../imgs/main/login_radio.png') no-repeat center;  
               &.on{width:14px;height:14px;background: url('../imgs/main/login_radio_checked.png') no-repeat center;}  
            }  
            .txt{font-size:12px;line-height:1.3;text-align: center;color: #4a4a4a;}  
            .text{font-size:13px;line-height:20px;text-align: center;color: #4a4a4a;margin: 20px 0 -20px 0;}  
            .unit01{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_mobile.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
            .unit02{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_otp.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
            .unit03{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_device.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
            .unit04{text-align:center;padding:150px 0 0 0;background: url('../imgs/main/img_password.png') no-repeat top 30px center;cursor: pointer;justify-content: center;}  
         }  
         .textBox{margin:30px 0 0 0;text-align: center;  
            .sertiHelpBtn{position:relative;display:inline-block;margin:0 0 0 20px;font-size:14px;line-height:20px;letter-spacing: -0.7px;color:#000000;  
               &::before{content:'';position: absolute;top:0;left:-20px;width:18px;height:20px;background: url('../imgs/ico/ic_question_02_m_normal.png') no-repeat left center;}  
            }  
            .text{font-size:13px;line-height:20px;text-align: center;color:#000000;}  
         }  
         // 이미지박스  
         .imgBoxStyle{position:relative;  
            .text{margin:0;font-size:13px;line-height:20px;text-align: center;color:#000000;letter-spacing: -0.65px;}  
            .radio{text-align: center;}  
            &.certi-notallowed{padding:150px 0 0 0;background: url('../imgs/main/img_certification.png') no-repeat top 30px center;}  
            &.app-install{padding:150px 0 0 0;background: url('../imgs/main/img_app.png') no-repeat top 30px center;}  
            &.complete{padding:150px 0 0 0;background: url('../imgs/main/ic_complete.png') no-repeat top 30px center;}  
            &.device{padding:150px 0 0 0;background: url('../imgs/main/img_device.png') no-repeat top 30px center;}  
            &.device-rerun{padding:150px 0 0 0;background: url('../imgs/main/img_device-rerun.png') no-repeat top 30px center;}  
         }  
         // pin입력  
         .pin_area{margin:40px 0 0 0;justify-content: center;  
            .pin_input{max-width:40px;height: 50px;margin: 0 4px;border-radius: 4px;border: 1px solid #e6e6e6;background: #ffffff;box-sizing: border-box;  
               .number{width: 100%;padding: 0;font-size:25px;line-height:48px;color: #4c9cff;border: none;text-indent: 0;border-radius: 4px;text-align: center;background: transparent;outline: none;}  
            }  
            .text{margin:30px 0 0 0;font-size:13px;line-height:18px;text-align: center;color:#000000;  
               .menuIco{display: inline-block;width:18px;height:18px;vertical-align:bottom;background: url('../imgs/ico/ic_view_list_02_m_normal.png') no-repeat center;}  
            }  
         }  
         .pin_table{width:344px;margin:30px auto 0 auto;  
            > div:first-child .pin_btn{margin:0;  
               &:nth-child(2){margin:0 6px 0 6px;}  
            }  
            .pin_btn{max-width:110px;height:48px;margin:6px 0 0 0;  
               &:nth-child(2){margin:6px 6px 0 6px;}  
               .btn{width: 100%;height: 100%;font-size: 16px;line-height:48px;font-weight:bold;text-align:center;outline: 0;border: none;border-radius: 4px;color:#2e3645;background:#f2f5f9;cursor: pointer;  
                  &:active {background: #e6ecf4;}  
               }  
            }  
         }  
         // 장치인증  
         .imgDevice{position:relative;  
            &.device-connected{padding:110px 0 0 0;background: url('../imgs/main/img_device.png') no-repeat top -5px center;}  
            &.device-notconnected{padding:180px 0 0 0;background: url('../imgs/main/img_device_notconnected.png') no-repeat top 30px center;}  
            .text{font-size:13px;line-height:41px;letter-spacing: -0.65px;text-align: center;  
               span{font-size:13px;font-weight:bold;line-height:41px;}  
            }  
         }  
         // 로그인 체크박스  
         .checkboxItem{margin: 15px 0 0 0;  
            span > span > span{background-image: url('../imgs/temp/klago_sp_lux.png') !important;margin-top:-8px !important;}  
            &::after{content:"";display:block;clear:both;height:0;visibility:hidden;}  
            input[type="checkbox"] + svg{border-radius:3px;background:#dddee0;margin-top:-8px !important;}  
            input[type="checkbox"] + svg path{fill:#babcc3}  
            input[type="checkbox"]:checked + svg{border-radius:3px;background:#1c90fb;margin-top:-8px !important;}  
            input[type="checkbox"]:checked + svg path{fill:#1c90fb}  
            input[type="checkbox"]:checked + svg polygon{fill:#ffffff}  
            label{font-size:14px !important;font-weight:normal !important;margin-left:5px;}  
            label:hover::after{width:100%;transition: all 0.5s;}  
            label:focus::after{width:100%;transition: all 0.5s;}  
         }  
      }  
   }  
}  
  
/**************************************************  
   Login v2 (이차인증)  
**************************************************/  
  
.login_v2 .login_wrap{overflow:hidden;background:#fff;box-sizing:border-box;}  
.login_v2 .login_wrap h2{padding:20px 80px 15px;font-size: 30px;text-align: center;}  
.login_v2 .login_wrap h2 img {vertical-align: middle; margin-top: -3px;}  
.login_v2 .login_wrap h2::after{content:"";display:block;clear:both;height:0;visibility:hidden;}  
.login_v2 .login_wrap .info_txt{padding-bottom: 20px;font-size: 15px;line-height: 23px;letter-spacing: -1px;text-align: center;}  
.login_v2 .login_wrap .qr_area{width: 146px;margin: 0 auto;}  
.login_v2 .login_wrap .qr_area .qr_img{padding: 14px 14px 9px;border-width: 4px 4px 0px;border:solid #1c90fb;display: block;}  
.login_v2 .login_wrap .qr_area .qr_time{height: 30px;background-color: #1c90fb;line-height: 30px;font-size: 13px;color: #fff;text-align: center;}  
.login_v2 .login_wrap .qr_view{width:112px;height:112px;}  
.login_v2 .login_wrap .qr_view img{width:100%;}  
.login_v2 .login_wrap .sub_info_txt{padding: 20px 0;font-size: 13px;line-height: 20px;letter-spacing: -1px;text-align: center;}  
.login_v2 .login_wrap .btn_area{margin: 0 auto;text-align: center;}  
  
.login_v2 .login_wrap .pin_area{padding: 0px 60px;}  
.login_v2 .login_wrap .pin_area .pin_input{padding: 0 0 10px 0;border-bottom: 1px solid #d9d9d9;}  
.login_v2 .login_wrap .pin_area .pin_input table{width:100%;table-layout:fixed;border-collapse: separate;border-spacing: 5px;}  
.login_v2 .login_wrap .pin_area .pin_input table td{border:1px solid #d9d9d9;height: 50px;border-radius: 3px;}  
.login_v2 .login_wrap .pin_area .pin_input table td .number{width: 100%;height: 100%;font-size: 22px;outline: 0;border: none;text-indent: 0;border-radius: 3px;}  
.login_v2 .login_wrap .pin_area .pin_board{padding: 10px 0 0 0;}  
.login_v2 .login_wrap .pin_area .pin_board table{width:100%;table-layout:fixed;border-collapse: separate;border-spacing: 5px;}  
.login_v2 .login_wrap .pin_area .pin_board table td{border:1px solid #d9d9d9;height: 50px;border-radius: 3px;}  
.login_v2 .login_wrap .pin_area .pin_board table td .btn{width: 100%;height: 100%;font-size: 15px;font-weight: bold;outline: 0;border: none;border-radius: 10px;}  
.login_v2 .login_wrap .pin_area .pin_board table td  input[type='button']:active {background: #f4f9fe;}  
  
.simpleDialog {  
   .dialog_content .dialog_data {  
      > div:first-child {display:none;}  
      > div:nth-child(2) {border-top:none;}  
      > div:nth-child(3) {background: #fff;padding:20px 0;}  
   }  
}  
  
/*렌더링 오류*/  
.RError_div {text-align: center;display:flex;justify-content: center;align-items: center;height:100%;  
   .RError_div_in {margin-top:-50px;  
      .txt1 {font-size: 20px; font-weight: bold; line-height: 29px; letter-spacing: -1px;color:#000;margin-bottom: 4px;}  
      .txt2 {font-size: 16px; line-height: 1.44; letter-spacing: -0.8px;color:#a6a6a6;}  
   }  
}

/**************************************************  
   원격 로그인  
**************************************************/  
.loginConfirmPopup{
   .confirmImg{padding: 180px 0 30px 0; font-size: 15px; font-weight: bold; text-align: center; background: url('../imgs/ico/img_login_num.png') no-repeat top 60px center;}
   .confirmNum{padding: 0 30px; justify-content: space-between; width: 300px; height: 50px; border-radius: 6px; border: 1px solid #e6e6e6; box-sizing: border-box;
      p{font-size: 12px; letter-spacing: -0.6px; color: #8c8c8c;}
      .num{font-size: 24px; font-weight: bold;}
   }
   .confirmRefresh{margin: 10px 0 0 0; font-size: 12px; color: #a6a6a6;
      .refresh{width: 18px; height: 18px; background: url('../imgs/ico/ic_refresh_m_normal@2x.png') no-repeat center center; background-size: 18px; cursor: pointer;
         &:hover{background: url('../imgs/ico/ic_refresh_m_over@2x.png') no-repeat center center; background-size: 18px;}
      }
   }
}